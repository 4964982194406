.recipient-table {
    height: fit-content;
    border: 1px solid var(--off-white);
    border-bottom: none;
    max-width: 100%;
    overflow-x: hidden;
}

.time-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.recipient-table table th:last-child {
    border-right: none;
}

.missing-email {
    font-style: italic;
    color: var(--rose);
    display: flex;
    align-items: center;
    height: 17px;
}
