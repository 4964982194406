.tab-container {
    position: relative;
    display: inline-block;
}

.refreshing {
    position: absolute;
    top: -5px;
    right: -15px;
}

.refreshing svg path,
.refreshing svg fill {
    fill: var(--orange);
}
