.row {
    display: flex;
    align-items: center;
}

.left-content {
    display: flex;
    min-width: 100px;
}

.picker-container {
    display: flex;
    width: 100%;
}

.left-picker,
.right-picker {
    flex-grow: 1;
    min-width: 120px;
}

.hyphen {
    display: flex;
    align-items: center;
    padding: 0 8px 0 4px;
}

.unavailable {
    display: flex;
    align-items: center;
    color: var(--grey-lt);
}

.timezone-label {
    margin-bottom: 2px;
    font-weight: 500;
}

@media screen and (max-width: 1380px) {
    .picker-container {
        flex-direction: column;
    }
    .left-picker {
        min-width: 100px;
    }
    .right-picker {
        margin-top: 8px;
        margin-bottom: 8px;
        min-width: 0;
        width: 115px;
    }
}
