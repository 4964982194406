.first-cell {
    display: flex;
    align-items: center;
    width: 100%;
}

.first-cell--deleting {
    composes: first-cell;
    position: relative;
    right: 19.5px; /* prevents cell content from jumping a little when delete button appears */
}

.name {
    line-height: 21px; /* prevent resizing row when hovered */
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.name--clickable {
    composes: name;
    cursor: pointer;
}

.title {
    margin-right: 4px;
}

.action-button > button {
    padding: 0;
}

.spacer {
    height: 17px;
}

.help-button {
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.report-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.report-link > *:first-child {
    /* vertically center icon and text */
    display: flex;
}

.no-sources-empty-state {
    text-align: center;
    padding: 16px 0;
}

.loading {
    display: block;
    padding: 32px 0;
}

/* Make 'see more' button container look like part of the table */
.button-container {
    text-align: center;
    padding: 8px;
    border-left: 1px solid var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
    border-right: 1px solid var(--off-white-dk);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
