.location-result {
    color: var(--grey);
    background-color: #fff;
}

.location-result:nth-child(2n) {
    background-color: var(--off-white);
}

.location-result:hover,
.location-result:focus,
.location-result.selected {
    color: var(--grey-dk);
    background-color: var(--color-list-hovered);
}

.location-result > a {
    display: block;
    padding: 10px;
}
