.preview-wrapper {
    margin-top: 12px;
}

.preview-card {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px;
    border: 1px solid var(--off-white-dk);
    border-radius: 0.2em;
}

.preview-card-header {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 16px;
}

.preview-card-header h3 {
    font-size: 16px;
    margin-bottom: 4px;
    color: var(--grey-dk);
}

.preview-card-logo {
    width: 40px;
    height: 40px;
    content: url('/include/images/nutshell-logo-orange.png');
    border-radius: 3px;
}

.preview-card-content {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    flex-grow: 1;
}

.preview-card-form-submission {
    display: flex;
    padding-top: 8px;
    font-size: 15px;
}

.preview-card-lead {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.preview-card-lead-info {
    padding: 4px 12px;
    flex-grow: 1;
}

.preview-card-lead-info h4 {
    /* this is the blue that Slack uses */
    color: #08c;
    font-size: 16px;
}

.preview-card-lead-info h5 {
    margin-bottom: 8px;
    color: var(--grey-dk);
    font-size: 16px;
}

.preview-card-attributes {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 16px;
}

.preview-card-attributes > div:first-child {
    margin-top: 8px;
}

.preview-card-attributes > div:not(:first-child) {
    margin-top: 12px;
}

.preview-card-attributes div:not(:last-child) {
    margin-right: 56px;
}

.accent-bar {
    width: 4px;
    border-radius: 0.2em;
}

.accent-bar-won {
    background-color: var(--green);
}

.accent-bar-lost {
    background-color: var(--rose-dk);
}

.accent-bar-cancelled {
    background-color: var(--off-white-dk);
}

.accent-bar-open {
    background-color: var(--off-white-dk);
}
