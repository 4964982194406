.message {
    display: flex;
    align-items: center;
}

.message > *:first-child {
    margin-right: 16px;
}

.icon {
    flex-shrink: 0;
}

.text {
    user-select: none;
}

.text > *:first-child {
    margin-bottom: 4px;
}
