.container {
    height: 100%;
    width: 100%;
    position: relative;
    opacity: 0;
    animation: fade-in 1000ms var(--custom-easing) 0s forwards;
}

.title {
    height: 17px;
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: var(--font-big);
    line-height: 17px;
    font-weight: 500;
}

.chart-actions {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 16px;
    right: 16px;
}

.tooltip-primary-item {
    font-weight: 500;
}

.tooltip-secondary-item {
    color: var(--off-white);
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
