@import 'include.less';
@import '_avatar.less';

body#notification {
	margin: 0;

	//these are necessary defaults that the web-app uses as well
	font-family: @body-font;
	font-size: 13px;
	line-height: 17px;

	ul,
	li {
		margin: 0;
		padding: 0;
	}
}

.notifications-message {
	display: none;
	width: 80%;
	margin: 0 auto;
	padding: 40px 0;
	h1 {
		font-family: @body-font;
		margin: 0;
		text-align: center;
		font-size: @normal;
	}
	p {
		font-family: @body-font;
		font-size: @small;
		text-align: center;
		color: @grey-lt;
	}
}

.notifications-list {
	a {
		color: @blue;
		text-decoration: none;
		&:visited {
			color: @blue;
			text-decoration: none;
		}
		&:hover {
			text-decoration: underline;
			color: @blue-dk;
		}
	}
}

//default styles (mobile, /notification)
body {
	@mark-as-read-size: 4px;
	@subject-font-size: 17px;
	@subject-line-height: 21px;
	@subject-min-lines: 2;
	@subject-max-lines: 4;
	@preview-font-size: 15px;
	@preview-line-height: 19px;
	@gutter-margin-right: 5px;
	@notification-margin-x: 10px;
	@notification-margin-y: 6px;
	@avatar-size: 40px;
	@initial-size: 20px;
	@avatar-margin-top: 3px; //necessary on mobile

	.notification-css();
}

//web styles
body #notifications-popup {
	@mark-as-read-size: 4px;
	@subject-font-size: 13px;
	@subject-line-height: 17px;
	@subject-min-lines: 2;
	@subject-max-lines: 2;
	@preview-font-size: 12px;
	@preview-line-height: 17px;
	@gutter-margin-right: 3px;
	@notification-margin-x: 6px;
	@notification-margin-y: 5px;
	@avatar-size: 30px;
	@initial-size: 16px;
	@avatar-margin-top: 0;

	.notification-css();
}

body #sidebar {
	@mark-as-read-size: 4px;
	@subject-font-size: 13px;
	@subject-line-height: 17px;
	@subject-min-lines: 2;
	@subject-max-lines: 2;
	@preview-font-size: 12px;
	@preview-line-height: 17px;
	@gutter-margin-right: 3px;
	@notification-margin-x: 6px;
	@notification-margin-y: 5px;
	@avatar-size: 30px;
	@initial-size: 16px;
	@avatar-margin-top: 0;

	.notification-css();
}

.notification-css() {
	.notification-list-container {
		height: 100%;
		overflow: auto;

		.notifications-list {
			overflow-y: auto;
			height: 100%;
			&.state-unloaded .notifications-message-unloaded {
				display: block;
			}
			&.state-empty .notifications-message-empty {
				display: block;
			}
		}

		.empty-state-graphic {
			width: 70px;
			margin: 0 auto 20px auto;
			display: block;
		}

		li {
			position: relative;
			background-color: @off-white-sidebar;
			border-bottom: 1px solid @off-white-dk;

			&.unread {
				background-color: @timeline-email-background;
				.mark-as-read-container {
					display: block;
				}
			}

			&:hover {
				background-color: @color-list-hovered;
			}

			&.type-notification-generic {
				padding: 5px 0;

				.avatar {
					background: none;
					box-shadow: none;
					&:before {
						border: none;
					}
				}
				.notification-content {
					.subject {
						display: inline;
						background: rgba(250, 242, 160, 0.5);
						color: @grey;
						letter-spacing: 1px;
						font-weight: 500;
						margin-right: 5px;
						margin-bottom: 5px;
					}

					.preview {
						color: @grey;
						height: auto;
						overflow: auto;
						white-space: normal;
						padding-right: 30px;
						padding-top: 5px;
					}

					.timestamp {
						display: none;
					}
				}
			}

			&:last-child {
				border-bottom: none;
			}

			.notification-container {
				position: relative;
			}

			.notification-gutter {
				position: absolute;
				top: 0;
				left: 0;

				.avatar {
					margin-top: @avatar-margin-top;
					height: @avatar-size;
					width: @avatar-size;
					border-radius: 30px;

					img {
						width: @avatar-size;
						height: @avatar-size;
					}

					.initials {
						font-size: @initial-size !important;
						line-height: @avatar-size !important;
						letter-spacing: 0.5px;
						border-radius: 15px;
					}
				}
			}

			.link-notification {
				display: block;
				padding: @notification-margin-y @notification-margin-x;
				text-decoration: none;
				-webkit-touch-callout: none;
			}

			.notification-content {
				margin-left: @avatar-size + @notification-margin-x + @gutter-margin-right;

				.subject {
					font-size: @subject-font-size;
					line-height: @subject-line-height;
					min-height: @subject-line-height * @subject-min-lines;
					max-height: @subject-line-height * @subject-max-lines;
					overflow: hidden;
					padding-right: 15px;
					margin-bottom: 5px;

					color: @grey;
					.type-users {
						color: @orange-dk;
					}
					.type-contacts {
						color: @navy-dk;
					}
					.type-leads {
						color: @green;
					}
					.type-accounts {
						color: @plum;
					}
				}

				.preview {
					color: lighten(@grey-lt, 20%);
					font-size: @preview-font-size;
					line-height: @preview-line-height;
					height: @preview-line-height;
					padding-right: 111px;
					#util > .excerpt-crop > .ellipsis;

					.type-leads {
						color: @green;
					}
					.type-contacts {
						color: @blue-dk;
					}
					.type-accounts {
						color: @plum;
					}
				}
				.timestamp {
					color: lighten(@grey-lt, 20%);
					font-size: 11px;
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}

			.mark-as-read-container {
				display: none;
				position: absolute;
				right: 3px;
				top: 4px;
				width: @mark-as-read-size + 4px;
				height: @mark-as-read-size + 4px;

				a {
					display: block;
					border: 1px solid @blue;
					border-radius: @mark-as-read-size;
					width: @mark-as-read-size;
					height: @mark-as-read-size;
					background-color: @blue;

					&:hover {
						border: 1px solid @blue-dk;
						background-color: @blue-dk;
					}
				}
			}
		}
	}
}

/*
 * Custom lead pies
 */

.pie {
	background-repeat: no-repeat;
}

//web resize - scaled down from 40px to 30px
body #notifications-popup,
#sidebar.sidebar-react-mount {
	background-color: @off-white;
	.pie-size-medium {
		margin-top: 0; //overrides the mobile visual 3px margin-top

		width: 30px;
		height: 30px;
	}
}

.pie-size-medium {
	margin-top: 3px;

	width: 40px;
	height: 40px;
}
