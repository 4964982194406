.file-upload-wrapper {
    width: 100%;
    box-sizing: border-box;
    height: 152px;
    color: var(--blue);
    font-size: var(--font-big);
    background-color: var(--blue-bg);
    border: 2px dashed var(--blue);
    border-radius: 3px;
    cursor: pointer;
}

.contents {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon-container {
    margin-bottom: 16px;
}

.file-upload-wrapper:hover {
    color: var(--blue-dk);
    border: 2px solid var(--blue-dk);
}

.file-upload-wrapper input[type='file'] {
    display: none;
}

.error {
    color: var(--rose);
    font-size: var(--font-size-normal);
    margin-top: 8px;
}

.short-button {
    height: 70px;
}

.white-background {
    background-color: var(--white);
}
