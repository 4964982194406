.quote-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    padding: 8px;
    position: relative;
}

.quote-row:hover {
    border-color: var(--placeholder);
    cursor: pointer;
}

.quote-row-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 4px;
}

.dog-ear {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 10px;
    height: 10px;
    overflow: hidden;
    background-color: var(--white);
}

.dog-ear::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12.2px 12.2px 0 0;
    border-color: transparent var(--off-white-dk) transparent transparent;
}

.quote-row:hover .dog-ear::before {
    border-color: transparent var(--placeholder) transparent transparent;
}
