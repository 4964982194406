
// summary boxes are somtimes contained in a row .summary-boxes
.summary-boxes {
	#util > .clearfix;
	margin: 0 0 20px 0;
	padding: 0 20px;

	tr td {
		padding-left: 10px;
		&:first-child {
			padding-left: 0;
		}
	}
}
// summary boxes are sometimes contain in a .placement-row
.placement-row {
	&.two-up {
		float: right;
		width: 49%;

		&:first-child {
			float: left;
		}
	}
}

.summary-box {
	position: relative;
	background-color: @peach-lt;
	border: 2px solid @peach;
	border-radius: 4px;
	#util > .border-box;
	padding: 12px 14px;

	// shared typography styles
	.value,
	.label {
		color: @grey-dk;
	}

	> .value {
		font-family: @headline-font;
		font-size: 28px;
		line-height: 34px;
		letter-spacing: 2px;
		margin: 0 0 2px 0;
	}

	.label {
		font-size: @tiny;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	&.variant-large-header .label {
		font-family: @headline-font;
		font-size: @big;
		font-weight: 400;
		margin: 0 0 6px 0;
		text-transform: none;
	}


	&.variant-lines {
		.indicator-percent {
			height: 5px;
			margin-top: 2px;
		}

		.summary-box-line {
			#util > .clearfix;
			margin: 10px 0 8px 0;

			.line-label {
				font-weight: 500;
				color: @grey;
			}
			.line-value {
				float: right;
				font-size: 15px;
				font-weight: 700;
				color: @grey-dk;
				float: right;
			}
		}
	}

	// .summary-box color variations, mainly features within reports section
	&.tint-green {
		background-color: @green-lt;
		border-color: darken(@green-lt, 7%);
		.value { color: @green-dk; }
	}
	&.tint-red {
		background-color: @rose-lt;
		border-color: darken(@rose-lt, 4%);
		.value { color: @rose-dk; }
	}
	&.tint-blue {
		background-color: @navy-lt;
		border-color: darken(@navy-lt, 5%);
		.value { color: @navy-dk; }
	}
	&.tint-grey {
		background-color: @off-white;
		border-color: @off-white-md;
		.value { color: @grey-dk; }
	}
}
