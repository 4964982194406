.link {
    position: relative;
    cursor: pointer;
    font-weight: 400;
}

.prevent-underline {
}

.full-width {
    width: 100%;
}

.link:not(.prevent-underline):hover {
    text-decoration: underline;
}

.link:not(.prevent-underline):focus-visible {
    text-decoration: underline;
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}

.primary {
    composes: link;
    color: var(--blue);
}

.primary:focus,
.primary:hover {
    color: var(--blue-dk);
}

.secondary {
    composes: link;
    /* TODO: this probably shouldn't set a color, let it be whatever the body or parent is */
    color: var(--grey);
}

.secondary:focus,
.secondary:hover {
    color: var(--grey-dk);
}

.tiny {
    font-size: 11px;
}

.small {
    font-size: var(--font-small);
}

.normal {
    font-size: var(--font-normal);
}

.big {
    font-size: var(--font-big);
}

.xlarge {
    font-size: var(--font-huge);
}

.bold {
    font-weight: 500;
}

/* Button variant styles */

.button {
    composes: primary from '../button/button.css';
    cursor: pointer;
}

.button-primary {
    composes: primary from '../button/button.css';
    cursor: pointer;
}

.button-secondary {
    composes: secondary from '../button/button.css';
    cursor: pointer;
}

.button-ai-blue {
    composes: ai-blue from '../button/button.css';
    cursor: pointer;
}

.button-save {
    composes: save from '../button/button.css';
    cursor: pointer;
}

.button-upgrade {
    composes: upgrade from '../button/button.css';
    cursor: pointer;
}

.button:hover,
.button:focus {
    text-decoration: none;
}

/* Should match `small` from '../button/button.css' */
.button.small,
.button-primary.small,
.button-secondary.small,
.button-ai-blue.small,
.button-save.small,
.button-upgrade.small {
    padding: 4px 10px 3px 10px;
}

/* Should match `normal` from '../button/button.css' */
.button.normal,
.button-primary.normal,
.button-secondary.normal,
.button-ai-blue.normal,
.button-save.normal,
.button-upgrade.normal {
    padding: 4px 10px;
    font-size: var(--font-small);
}

/* Should match `big` from '../button/button.css' */
.button.big,
.button-primary.big,
.button-secondary.big,
.button-ai-blue.big,
.button-save.big,
.button-upgrade.big {
    padding: 7px 12px;
    font-size: var(--font-small);
}

/* Should match `xlarge` from '../button/button.css' */
.button.xlarge,
.button-primary.xlarge,
.button-secondary.xlarge,
.button-ai-blue.xlarge,
.button-save.xlarge,
.button-upgrade.xlarge {
    padding: 12px 24px;
    font-size: var(--font-normal);
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
