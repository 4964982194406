.review-messages-container {
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

/* Main modal header */
.review-messages-container > :first-child {
    position: absolute;
    top: 32px;
    left: 32px;
}

/* Container with list of messages */
.left-container {
    flex: 0 361px;
    padding: 96px 32px;
    position: relative;
}

.left-container > :first-child {
    margin-bottom: 32px;
}

.message-list {
    margin-top: 12px;
    height: 100%;
    overflow-y: auto;
}

/* Shadow at bottom of list in case there are many items */
.left-container::after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 60px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.001), #fff);
}

.message-list > div > :last-child {
    z-index: 2;
}

/* Container with message details */
.right-container {
    flex: 0 639px;
    padding: 96px 32px 32px 32px;
    background-color: var(--off-white);
}

.has-error {
    color: var(--rose);
}

/* If wide modal shrinks, main header at the top needs shrink */
@media screen and (max-width: 1024px) {
    .review-messages-container > :first-child {
        width: 250px;
    }
}
