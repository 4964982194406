.line {
    margin-bottom: 4px;
    font-size: var(--font-normal);
}

.line--empty {
    composes: line;
    /* 13px font-size - 4px margin */
    height: 9px;
}
