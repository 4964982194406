.form-content {
    height: 100%;
}

.scroll-container {
    height: calc(100% - 82px); /* Footer is 50px + 32px top/bottom padding */
    overflow-y: auto;
    overflow-x: hidden;
    /* Let's get the scroll-bar all the way to the right */
    padding-right: 33px;
    margin-right: -32px;
    /* Needed for react-select to expand when menus are opened */
    position: relative;
    /* Needed for react-select not to be cut my overflow */
    padding-left: 1px;
}

.form-body {
    position: relative;
    padding-bottom: 64px;
    transform-origin: left top;
    transform: translateX(0) rotateY(0deg);
    transition: transform 400ms var(--custom-easing-bounce-short);
}

.form-body--full-height {
    composes: form-body;
    padding: 16px 0;
}

.icon:global(.svg-tick-circle),
.icon:global(.svg-tick-circle-outline) {
    fill: var(--task);
}

.icon:global(.svg-accounts-circle) {
    fill: var(--plum);
}

.icon:global(.svg-activities-circle) {
    fill: var(--activity);
}

.icon:global(.svg-contacts-circle) {
    fill: var(--navy);
}

.icon:global(.svg-leads-circle) {
    fill: var(--green);
}

/* custom field styles */
.activity-important-checkbox {
    cursor: default;
    position: absolute;
    bottom: 22px;
    right: 0;
    width: 90px;
    font-size: var(--font-normal);
    text-align: right;
    color: var(--grey-lt);
}
/* end custom field styles */

.cell-container {
    position: relative;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.name-field {
    display: flex;
    align-items: center;
}

.name-field > *:first-child {
    flex-grow: 1;
}

.flex-row:last-child {
    margin-bottom: 0;
}

.flex-row > * {
    min-height: 75px;
    width: 100%;
}

.icon-container {
    width: 75px;
    margin-right: 20px;
}

.label {
    display: block;
    cursor: pointer;
}
.label-disabled {
    composes: label;
    cursor: default;
}

.option {
    padding: 10px;
    border-bottom: 1px solid color(var(--grey-lt) alpha(30%));
}
.option:last-child {
    border: 0;
}

.checkbox {
    display: inline-block;
    margin-right: 10px;
}
/* End pref pane styles. */
