.thumbnail-card-wrapper {
    position: relative;
    width: 100%;
    height: 183px;
    margin-bottom: 8px;
}

/* ButtonCard */
.thumbnail-card-wrapper > :first-child {
    width: 100%;
}

.image-container {
    width: 100%;
    height: 183px;
    overflow: hidden;
}

/* For images that contain a source, show them */
.image-container > img[src] {
    visibility: visible;
    border: none;
}

.name-container {
    position: absolute;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid var(--off-white-dk);
    width: 100%;
    max-height: 60px;
}

.name {
    text-align: left;
    margin: 0;
    padding: 8px;
    line-height: 15px;
    color: var(--grey);
    font-size: 11px;
}

.badge-container {
    position: absolute;
    top: 8px;
    left: 8px;
}
