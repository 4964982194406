.pulsate(@animation-name; @start-size: 0.75; @end-size: 1; @duration: 1.5s) {
	@keyframes ~"@{animation-name}" {
		0%, 100% {
			transform: scale(@start-size);
		}
		50% {
			transform: scale(@end-size);
		}
	}
	animation: @animation-name @duration ease 0s infinite;
}

.fill-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 220px;
}

.fade-enter, .fade-appear {
	opacity: 0.01;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
	transition: opacity .25s linear;
	opacity: 1;
}
.fade-leave {
	opacity: 1;
}
.fade-leave.fade-leave-active {
	transition: opacity .25s linear;
	opacity: 0.01;
}

.ui-entity-list-row--loading .fade-enter,
.ui-entity-list-row--loading .fade-appear {
	opacity: .99;
}

.ui-entity-list-row--loading .fade-leave { opacity: 0; }
.ui-entity-list-row--loading .fade-leave.fade-leave-active {
	opacity: 0.01;
	transition: opacity .1s linear;
}

.ui-entity-list-row:not(.ui-entity-row--loading) .fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity .1s linear;
}

.ui-entity-list-row:not(.ui-entity-row--loading) .fade-leave.fade-leave-active {
	opacity: 0.01;
	transition: opacity .1s linear;
}
