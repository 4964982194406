.button {
    width: 100%;
    height: 100%;
    padding: 0;
}

.button svg {
    fill: var(--icon-secondary);
}

.button:hover svg {
    fill: var(--icon-secondary-dk);
}
