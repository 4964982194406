.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label {
    font-size: var(--font-normal);
    color: var(--grey-lt);
    margin-bottom: 10px;
}

.selectable-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.selectable-filters > div {
    margin-bottom: 4px;
}

.filter :global(.ui-btn) {
    min-width: 220px;
}
