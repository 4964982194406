.upgrade-button {
    background-color: var(--secondary-sidebar-hover-blue);
    border-radius: 3px;
    padding: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.upgrade-button:hover {
    background-color: var(--navy-bg-active-new);
}
