.edition-engagement-report {
    position: relative;
    width: 100%;
}

.edition-engagement-report > div:first-child {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 10;
}
