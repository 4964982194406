.container {
    display: flex;
    flex-direction: row;
    position: relative;
    list-style-type: none;
    padding: 8px 16px;
    box-sizing: border-box;
    min-height: 50px;
}

.left-column {
    flex: 1 1 0%;
    min-width: 0;
    display: flex;
    flex-direction: row;
}

.right-column {
    width: 40px;
    display: flex;
}

.left-container {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
}

.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: var(--off-white-dk);
}

.item-container {
    flex: 1;
    padding-right: 16px;
}

.top-line {
    height: 12px;
    margin-bottom: 5px;
    background-color: var(--off-white-dk);
}

.bottom-line {
    height: 12px;
    background-color: var(--off-white-md);
}

.timestamp {
    height: 12px;
    background-color: var(--off-white-md);
    width: 100%;
}
