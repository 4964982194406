.list-view-paginated {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.overlay {
    position: absolute; /* Sit on top of the page content */
    display: flex; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    justify-content: center;
    align-items: center;
}
