.container {
    display: flex;
    flex-direction: column;
}

.week {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 8px;
}
