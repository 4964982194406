.column-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    position: relative;
    width: 100%;
}

.icon {
    margin-right: 8px;
    transform: translateY(1px);
}
