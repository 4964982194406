.loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    fill: var(--orange-lt);
    opacity: 0;
    animation: fade-in 2000ms var(--custom-easing) 0s forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
