.group-configuration-table {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    font-size: var(--font-small);
    color: var(--grey-dk);
}

.table-title {
    height: 39px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    font-size: var(--font-normal);
    line-height: 19px;
    font-weight: 500;
    background-color: var(--off-white-dk);
}

.table-content {
    display: flex;
}

/* shared by <ConfigurationColumn /> and <GroupConfigurationsColumn /> */
.column-cell {
    height: 42px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--off-white-dk);
    box-sizing: border-box;
    width: 100%;
}

.column-cell:last-child {
    border-color: transparent;
}

.group-configurations-container {
    /* needs to scroll horizontally */
    overflow-x: auto;
    display: flex;
    flex: 1;
}
