.card-border {
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    width: 100%;
    opacity: 0.5;
    background-color: var(--blue);
}

.title {
    composes: title from './sales-automation-card.css';
    margin-right: 4px;
    max-height: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 2;
}

.top-line-container {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-bottom: 3px;
    color: var(--grey-lt);
}

.status {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-shrink: 1;
}

.status-badge-container {
    margin-left: 4px;
}

.delay-container {
    /* The top margin needs to be slightly larger to make the text look centered */
    margin-bottom: 2px;
    color: var(--grey-lt);
}

.delay-container > span {
    margin-right: 4px;
}

.goal-container {
    display: flex;
    color: var(--grey-lt);
}

.goal-container svg {
    margin-right: 4px;
}

.tick-circle-icon {
    /* This icon is slightly smaller than the clock icon, so we add a little
    padding to make everything align */
    margin-right: 1px;
    fill: var(--grey-lt);
}
