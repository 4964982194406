.avatar {
    flex-shrink: 0;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Width is needed when a number is included, and the icon itself is smaller */
    width: 32px;
}

.number {
    display: block;
    font-size: 10px;
    margin-top: -2px;
    text-align: center;
    width: 100%;
    color: var(--grey-lt);
    min-height: 14px;
}
