.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
}

.buttons {
    display: flex;
    align-items: center;
}

.buttons > *:last-child {
    margin-top: 2px;
    padding-right: 0;
}
