.content-container-flexible {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content-container-fixed {
    composes: content-container-flexible;
    min-height: 164px;
}

.content-container-fixed img,
.content-container-flexible img {
    height: 64px;
}

.empty-text {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
