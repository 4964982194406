.container {
    flex: 1;
    flex-direction: column;
}

.error-container {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    opacity: 0.6;
    background-color: #fff;
}

.error-message {
    position: relative;
    background-color: #fff;
    color: var(--rose);
    font-weight: 500;
    padding: 10px 15px;
}
