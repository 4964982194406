.container {
    border-bottom: 1px solid var(--off-white-dk);
}

:global(.inline-block-link) {
    display: inline-block;
    width: 100%;
}

.row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shared-by {
    display: inline;
}

.shared-by * {
    display: inline;
}
