.container {
    background-color: white;
    height: 45px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.container > *:not(:first-child) {
    margin-right: 8px;
}

.selected-count {
    font-size: var(--font-normal);
    margin-right: 16px !important;
}
