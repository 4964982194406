.error-details {
    margin-top: 8px;
    color: var(--grey);
}

.error-details button {
    /* account for button padding */
    margin-left: -12px;
    margin-top: -7px;
    padding-bottom: 0;
}

.error-actions > * + * {
    margin-left: 8px;
}
