.grid-container {
    padding: 16px 0 8px 0;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
}

.search {
    margin-top: 16px;
    z-index: 2; /* make sure this stays in front of the list of fields */
}

.selected-card {
    margin: 16px 0;
}

.custom-fields-container {
    height: calc(100% - 136px); /* full height minus header and search bar and footer */
    overflow: auto;
}

.details {
    margin-bottom: 16px;
}

.fallback-container,
.fallback-container > *:not(:last-child) {
    margin-bottom: 8px;
}

.button {
    text-align: left;
}

.footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 64px);
}

.footer > *:first-child {
    padding-top: 0;
}
