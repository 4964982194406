.card-content {
    background-color: var(--off-white-md);
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 16px 4px;
    height: calc(100% - 32px); /* This is full height minus top and bottom padding */
}

.card-content:hover {
    background-color: var(--button-secondary-dk);
    color: var(--grey-dk);
    text-decoration: underline;
}

.card-content--disabled {
    background-color: var(--off-white);
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 16px 7px; /* 7px LR padding allows 'Company name' to fit on one line */
    height: calc(100% - 32px); /* This is full height minus top and bottom padding */
}

.card-text {
    margin: 0;
}

.card-content--disabled .card-icon * {
    fill: var(--grey-lt);
}

.card-content--disabled .card-text {
    color: var(--grey-lt);
}
