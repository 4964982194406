.subdomain-select-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.select-label {
    font-size: var(--font-normal);
    color: var(--grey-dk);
}

.subdomain-option {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: var(--font-small);
    color: var(--grey-dk);
}

.subdomain-option:hover {
    cursor: pointer;
}

.subdomain-option--disabled {
    composes: subdomain-option;
    color: var(--grey-lt);
    cursor: default;
}

.subdomain-option-sublabel {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: var(--font-tiny);
    color: var(--grey);
}

.subdomain-option--disabled .subdomain-option-sublabel {
    color: var(--grey-lt);
}

.add-new-option {
    padding: 12px;
}

.unverified-warning-text {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--grey-dk);
}

.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 24px;
    color: var(--grey);
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.empty-state-text {
    width: 270px;
}
