.attachment-count button {
    width: auto !important;
}

.attachment-count > .single-file {
    align-items: center;
}

.single-file {
    display: flex;
    align-items: baseline;
}

.file-card {
    position: absolute;
    bottom: 45px;
    overflow: hidden;
    background-color: white;
    transition: all 200ms ease-in-out;
}

.file-list {
    padding: 16px;
    min-width: 150px;
}
