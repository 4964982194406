.container {
    height: 100%;
}

.fields-section {
    padding-top: 24px;
}

.description {
    font-size: var(--font-normal);
    padding-bottom: 12px;
}

.connected-fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card {
    padding-bottom: 12px;
}

.empty-card {
    width: 359px; /* match width of two line cards */
    height: 70px; /* match height of two line cards */
    background-color: var(--off-white);
    display: flex;
    justify-content: center;
    align-items: center;
}
