@import 'include';

// indicates multiple choice
.region-picker-area {
	.region-picker {
		.icon.action-remove,
		.or {
			display: block;
		}

		&.first {
			.icon.action-remove {
				visibility: hidden;
			}
		}

		&.last {
			.or {
				display: none;
			}
		}
		&.template {
			display: none;
		}
	}
}

.region-picker {
	height: 22px;
	line-height: 22px;

	input,
	select {
		float: left;
		margin-right: 5px;
		display: block;
	}
	label {
		position: relative;
		float: left;
		margin-right: 6px;
		padding-left: 18px;
	}

	// regions have markup to exist in sets
	.icon.action-remove {
		cursor: pointer;
		float: left;
		margin-top: 3px;
		margin-right: 3px;
		background: url('@{img-root}/buttons/icon-subtract-link.svg') no-repeat left center;
		width: 16px;
		height: 16px;
		opacity: 0.9;
		&:hover {
			opacity: 1;
		}
	}
	.or {
		float: left;
		margin-left: 10px;
		font-style: italic;
	}

	// specific inputs
	.select-country {
		width: 110px;
	}
	.input-areaCode {
		width: 60px;
	}
	.input-postalCode {
		width: 70px;
	}
	.input-city {
		width: 95px;
	}
	.select-range {
		width: 95px;
	} // this one can get wider for non-city

	select {
		margin-top: 2px;
	}
	.type-checkbox {
		position: absolute;
		left: -4px;
		margin-top: 6px;
	}

	.fuzzy {
		color: @grey-lt;
	}
	&.is-fuzzy {
		.fuzzy {
			color: black;
		}
	}

	.fuzzy-description {
		color: @grey; /* TODO: standardize color */
		font-style: italic;
	}

	.country,
	.state,
	.postalCode,
	.areaCode,
	.city {
		display: none;
	}

	// types from Region model
	&.region-type-0 {
		// country
		.country {
			display: block;
		}
		.fuzzy {
			display: none;
		}
	}
	&.region-type-1 {
		// state
		.country {
			display: block;
		}
		.fuzzy {
			display: none;
		}
	}
	&.region-type-2 {
		// postal code
		.country,
		.postalCode {
			display: block;
		}
		.select-range {
			width: 125px;
		} // this one can get wider for non-city
	}
	&.region-type-3 {
		// area code
		.areaCode {
			display: block;
		}
		.fuzzy {
			display: none;
		}
	}
	&.region-type-4 {
		// city (default)
		.country,
		.city {
			display: block;
		}
	}
	&.region-type-5 {
		// shouldn't be possible until we have a latlong picker
	}

	&.region-country-US.region-type-4,
	&.region-country-US.region-type-1,
	&.region-country-null.region-type-1,
	&.region-country-null.region-type-4 {
		.state-US {
			display: block;
		}
	}

	&.region-country-CA.region-type-4,
	&.region-country-CA.region-type-1 {
		.state-CA {
			display: block;
		}
	}

	&.region-country-AU.region-type-4,
	&.region-country-AU.region-type-1 {
		.state-AU {
			display: block;
		}
	}

	&.region-country-BR.region-type-4,
	&.region-country-BR.region-type-1 {
		.state-BR {
			display: block;
		}
	}

	&.region-country-IT.region-type-4,
	&.region-country-IT.region-type-1 {
		.state-IT {
			display: block;
		}
	}
}
