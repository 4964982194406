.page-title-bar {
    padding: 16px;
    flex-shrink: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    border-bottom: 1px solid var(--off-white-dk);
}

.page-title-bar-with-navbar {
    composes: page-title-bar;
    border-radius: 8px 0 0 0;
}

.app-flash-banner {
    padding: 0 16px;
    margin-top: 12px;
}
