.circle {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid var(--grey);

    text-decoration: none;
}

.circle:hover {
    text-decoration: none !important;
}

.circle-selected {
    border: 2px solid var(--blue);
    color: var(--blue);
}

.circle-filled {
    border: 2px solid var(--blue);
    background-color: var(--blue);
    color: var(--blue);
}
