.select-container {
    margin-left: 22px;
    margin-top: 12px;
}

.select-container--disabled {
    composes: select-container;
    opacity: 0.6;
}

.radio-label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
    color: var(--grey);
    font-size: var(--font-normal);
    line-height: 19px;
}

.radio-sublabel {
    color: var(--grey-lt);
    font-size: var(--font-small);
    line-height: 17px;
}
