.header {
    color: #9397ba;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    padding: 0 8px;
}

.popover {
    background-color: var(--sidebar-secondary);
    width: 209px;
    height: 400px;
    border-radius: 8px;
    padding: 8px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 2px;
    box-sizing: border-box;
}
