@import 'include';

#list-sender-address {
	.dns-setup-block {
		.dns-block-header {
			font-size: @big;
			color: @grey-dk;
			margin-bottom: 8px;
			font-weight: normal;
		}

		.copyable-value-container {
			display: flex;
			align-items: center;

			.copyable-value {
				max-width: ~'calc(100% - 32px)';
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				border: none;
				outline: none;
				width: 100%;
			}
		}

		a.action-copy-to-clipboard {
			margin-left: 5px;
		}

		.table-header {
			display: flex;
			align-items: center;
			margin-bottom: 4px;

			h4 {
				margin-right: 8px;
			}

			span {
				margin-right: 4px;
			}
		}

		.status-verified {
			color: @green;
		}

		.status-verifying {
			color: @warning;
		}

		.status-failed {
			color: @red;
		}
	}

	.action-resend-verification {
		padding: 0;
	}
}

.dns-setup-block table.table-slim {
	table-layout: fixed;
	border: 1px solid @off-white-dk;
	border-collapse: collapse;

	thead {
		background-color: @off-white-dk;

		th {
			padding: 4px 8px;
		}
	}

	td {
		border: 1px solid @off-white-dk;
		padding: 4px 8px;
	}
}
