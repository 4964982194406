.container {
    position: relative;
    display: flex;
    flex-grow: 1;
}

/* Modal footer */
.container > :last-child {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.form-container {
    overflow-y: auto;
    height: 550px;
}

/* Shadow at bottom of container in case there are many items */
.form-container::after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 60px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.001), #fff);
}

/* Preview links */
.preview-links-container {
    margin: 16px 0;
    /* Seems to be best solution for long links */
    overflow-wrap: anywhere;
}
.preview-links-container > * {
    margin-bottom: 8px;
}

.fake-link {
    color: var(--blue);
}

.utm-campaign-field {
    margin-bottom: 16px;
}

.utm-campaign-field > .edition-name {
    display: block;
    margin-bottom: 4px;
}

.edition-name {
    font-style: italic;
    color: var(--grey-lt);
}

/* Help text that appears when there are no editions */
.help-text {
    font-style: italic;
    color: var(--grey-lt);
}

.help-text > span {
    font-weight: bold;
}
