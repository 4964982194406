.select-two-line-icon-option {
    display: flex;
    padding: 4px;
    align-items: center;
}

.select-two-line-icon-option--selected {
    composes: select-two-line-icon-option;
}

/* Seems to be the only way to grab the select > container > control (NOT menu) > value-container */
.select-two-line-icon > div > div:first-of-type .select-two-line-icon-option--selected .sublabel {
    display: none;
}

.select-two-line-icon-option--disabled {
    opacity: 0.5;
}

.icon-container {
    text-align: center;
    width: 19px;
    height: 19px;
    margin-right: 8px;
}

.label {
    font-size: var(--font-normal);
    font-weight: 500;
}

.sublabel {
    color: var(--grey-lt);
}

/* we only want to show the remove button on hover */
.remove-button {
    display: none;
    position: absolute;
    right: 12px;
}

.select-two-line-icon-option:hover .remove-button {
    display: block;
}
