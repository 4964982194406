.drawer-section {
    margin-bottom: 24px;
}

.section-title {
    color: var(--grey-lt);
    padding-bottom: 6px;
    font-weight: 500;
    font-size: var(--font-small);
    border-bottom: 1px solid var(--off-white-dk);
    margin-bottom: 6px;
}

.selected-filter-section {
    padding-right: 16px;
    margin-bottom: 16px;
}

.selected-filter-header {
    color: var(--grey-dk);
    font-weight: 500;
    font-size: var(--font-small);
}

.selected-filter-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 8px 0;
    box-sizing: border-box;
}

.selected-filter-container > div:not(:last-child) {
    margin-bottom: 4px;
}

.show-all-link {
    color: var(--blue);
    padding: 6px 0;
}

.show-all-link:hover {
    text-decoration: underline;
    color: var(--blue-dk);
}
