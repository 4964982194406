.container {
    height: calc(100% - 107px); /* full height minus header and search bar */
    overflow: auto;
}

.container--with-footer {
    composes: container;
    height: calc(100% - 156px); /* full height minus header and search bar and footer */
}

.search {
    padding: 0 16px 16px 16px;
    box-sizing: border-box;
    background-color: white;
    position: sticky;
    top: 54px; /* make this sticky to the bottom of the header */
    z-index: 2; /* make sure this stays in front of the list of fields */
}

.select-container {
    padding: 0 16px;
}

.sublabel {
    display: flex;
    padding-top: 4px;
}

.icon {
    padding-right: 4px;
}

.create-button-container {
    padding: 0 16px;
}

.footer {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    background-color: var(--white);
    border-top: 1px solid var(--off-white-dk);
}

.empty-state {
    padding: 0 16px;
    color: var(--grey-lt);
    font-size: var(--font-normal);
    line-height: 19px;
}
