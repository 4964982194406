.form-container {
    overflow: auto;
    padding-bottom: 64px;
    text-align: left;
    width: 100%;
}

.form-fields-container {
    margin-bottom: 20px;
}

.container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.masthead-content {
    max-width: 640px;
    margin-bottom: 32px;
}

.masthead-maintitle {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #211712;
}

.masthead-maintitle-medium {
    font-size: 28px;
}

.masthead-description {
    font-size: 16px;
    line-height: 1.5;
    font-style: italic;
    color: #4d4540;
}

.typical-costs {
    background-color: #f5f9ff;
    padding: 20px;
    margin-bottom: 32px;
    max-width: 600px;
}

.typical-costs h2 {
    font-size: 20px;
    margin-bottom: 18px;
    color: #211712;
}

.typical-costs ul {
    padding-left: 20px;
    list-style-type: disc;
}

.typical-costs ul li {
    font-size: 16px;
    margin-bottom: 10px;
    color: #4d4540;
    line-height: 1.5;
}

.item::marker {
    color: #fe5800;
}
