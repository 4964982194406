.file-section {
    margin-bottom: 32px;
}

.file-upload-button {
    font-weight: normal;
    margin: 8px 0;
    text-align: center;
    display: block;
    padding: 16px;
    color: var(--blue);
    font-size: var(--font-normal);
    line-height: 17px;
    border: 1px dashed var(--blue);
    border-radius: 4px;
    width: 100%;
    user-select: none;
}

.file-upload-button:hover {
    background-color: var(--blue-lt);
    color: var(--blue);
    border-style: solid;
}
