.art-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.art-container > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.learn-even-more {
    align-self: center;
}

.learn-even-more a {
    display: flex;
    align-items: center;
    gap: 6px;
}
