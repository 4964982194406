.option-button,
.option-button--hovered,
.option {
    padding: 0 16px 0 0;
}

.value {
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 17px;
    max-height: 34px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: left;
    align-items: flex-start;
}

.option-button--hovered .value {
    text-decoration: underline;
}

.option {
    cursor: default;
    opacity: 0.6;
}

.option--selected {
    composes: option;
    opacity: 1;
}

.circle-container {
    margin-right: 8px;
}
