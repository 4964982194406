.title-bar-limits {
    cursor: pointer;
}

.title-bar-limits > *,
.title-bar-warning > * {
    display: inline;
    font-size: 11px;
    padding: 0 4px;
}

.title-bar-warning {
    display: inline-block;
    cursor: pointer;
    transform: translateY(2px);
}
