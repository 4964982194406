.global-blocklist-container {
    width: 500px;
}

.collapse-button {
    display: block;
    font-style: italic;
    margin-bottom: 8px;
}

.manage-button {
    display: block;
    margin-top: 8px;
}
