.container {
    min-width: 200px; /* default width, we can pass in a different width to replace this inline */
    width: 200px; /* default width, we can pass in a different width to replace this inline */
    cursor: pointer;
    padding: 16px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    display: flex;
}

.container--grey {
    background-color: var(--off-white);
    border: 1px solid var(--off-white);
}

.container--with-shadow:hover {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.2s ease;
}

.container--grey:hover {
    background-color: var(--white);
    border: 1px solid var(--off-white-dk);
}

.container--no-hover {
    cursor: unset; /* override container's cursor style */
}

/* Style container--no-hover that is also container--grey to just match container--grey on hover */
.container--no-hover.container--grey:hover {
    background-color: var(--off-white);
    border: 1px solid var(--off-white);
}

.inner-container {
    width: 100%; /* This needs to be set for truncation to work */
}
