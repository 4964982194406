.avatar-list {
    position: relative;
}

.avatar-wrapper {
    position: absolute;
}

.avatar-wrapper--link {
    composes: avatar-wrapper;
}

.avatar-wrapper--link:hover {
    /* It is important to override the inline style z-index */
    z-index: 100 !important;
    filter: drop-shadow(0 0 4px var(--grey));
    cursor: pointer;
}

/* Needed to override default cursor style when avatar has no icon */
.avatar-wrapper--link:hover * {
    cursor: pointer;
}

/* Ensure a non-clickable avatar has a default cursor even if parent container has an onClick */
.avatar-wrapper:hover {
    cursor: default;
}

.hidden-avatars {
    position: absolute;
    border: 2px solid var(--white);
    background-color: var(--off-white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.hidden-avatars:hover {
    cursor: default;
}
