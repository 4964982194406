.main-container {
    display: flex;
    padding: 8px 16px 16px 16px;
}

.main-container--column {
    composes: main-container;
    flex-direction: column;
    gap: 16px;
}

.image-container {
    width: 180px;
    height: 180px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid var(--off-white-dk);
}

/* For images that contain a source, show them */
.image-container > img[src] {
    visibility: visible;
}

.large-font {
    font-size: var(--font-huge);
}

.two-line-container {
    display: flex;
    justify-content: space-evenly;
}

.two-line-container > *:not(:last-child) {
    margin-right: 16px;
}

.details-container {
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}

.details-container--drip-sequence {
    composes: details-container;
    justify-content: space-evenly;
}
