.message-container {
    display: flex;
    background-color: var(--onboarding-help);
    border-radius: 3px;
    color: white;
    margin-bottom: 16px;
}

.message {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    height: 51px;
    font-size: var(--font-small);
    line-height: 17px;
    padding-right: 16px; /* Matches outside container, equal distance from 'X' button */
    color: white;
}

.message-text > a {
    color: white;
    text-decoration: underline;
}

.message-text > a:hover {
    color: white;
    text-decoration: underline;
    font-weight: 500;
}

.icon {
    padding: 4px 16px 0 16px;
}

.button-close {
    opacity: 0.35;
    padding: 8px 16px 8px 8px;
    color: white;
}

.button-close:hover,
.button-close:focus {
    opacity: 0.8;
}

.button-close svg {
    /* This is needed to center the close button */
    display: block;
}
