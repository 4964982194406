.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;
    background-color: var(--grey);
    user-select: none;
}

.container-light {
    composes: container;
    background-color: var(--off-white);
}

.month {
    display: flex;
    flex-grow: 1;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.container-light .month {
    color: var(--grey-dk);
    font-weight: 500;
}

.button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    color: var(--off-white);
}

.container-light .button {
    color: var(--grey);
}

.button:hover {
    color: #fff;
}

.container-light .button:hover {
    color: var(--grey-dk);
}

.button-control {
    composes: button;
    cursor: pointer;
}
