.action-title-default {
    font-size: var(--font-small);
}

.action-title-accounts {
    composes: action-title-default;
    color: var(--plum-dk);
}

.action-title-contacts {
    composes: action-title-default;
    color: var(--navy-dk);
}
