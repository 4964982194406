.company-people-container {
    background-color: var(--off-white);
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0 0 4px 0 rgba(33, 23, 18, 0.15) inset;
    overflow-y: auto;
}

.list-container > :first-child:not(:last-child) {
    margin-bottom: 32px;
}
