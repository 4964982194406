.lead-value {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-width: 120px;
    height: 62px;
    background: url('/include/images/lead/ribbon-house.svg');
    padding: 14px 8px 16px 8px;
    margin-top: -16px;
    background-repeat: repeat-x;
    background-size: 15px 14px;
    background-position: 0 54px;
    box-sizing: border-box;
    background-color: var(--grey-lt);
}

.lead-value span {
    font-size: var(--font-huge);
    color: white;
}

.won {
    background-color: var(--lead);
}

.open {
    background-color: var(--blue);
}

.open.hot {
    background-color: var(--orange);
}
