.checkbox-container {
    font-size: var(--font-small);
    margin-bottom: 2px;
}

.checkbox-container label {
    padding: 2px 16px 2px 0;
    display: flex;
    user-select: none;
    line-height: 17px;
}

.checkbox-container label:hover {
    color: var(--grey-dk);
}

.checkbox-container input[type='checkbox'] {
    height: 19px;
    flex-shrink: 0;
}
