.preview-container {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    border: 1px solid var(--form-border);
    background: var(--off-white);
    border-radius: 3px;
}

.host-section-container {
    background-color: var(--off-white);
    padding: 16px;
    border-radius: 3px;
    color: var(--grey-lt);
    font-size: 13px;
}

.section-container {
    background-color: var(--off-white);
    margin-bottom: 16px;
    padding: 16px;
    box-sizing: border-box;
}

.section-container--no-margin {
    composes: section-container;
    margin: 0;
}

.availability-container {
    background: var(--off-white);
    padding: 16px;
    border-radius: 3px;
}

.label-warning {
    display: flex;
    align-items: center;
}

.label-warning > :first-child {
    margin-right: 4px;
}
