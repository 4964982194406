.container {
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    opacity: 0.95;
    background-color: var(--grey-dk);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 21px;
    padding: 0 16px;
    text-align: center;
    color: var(--off-white);
}

.feature-pro-badge {
    width: 35px;
    margin-bottom: 8px;
}

.message {
    font-size: 17px;
}
