@import "../../../include";
@import "../../global/_colors";

// main header bar styles
#phone-call-overlay {
	position: fixed;
	top:0;
	left: 0;
	width: 100%;
	z-index: 1000;

	.ui-phone-call--on-open-animate-in .header-bar {
		@keyframes animate-in-phone-call {
			0% { transform: translateY(-60px); }
			100% { transform: translateY(0px); }
		}
		animation: animate-in-phone-call 600ms @customEasing 0s forwards;
		transform: translateY(-60px);
	}

	.ui-phone-call--on-close-animate-out .header-bar {
		@keyframes animate-out-phone-call {
			0% { transform: translateY(0px); }
			100% { transform: translateY(-60px); }
		}
		animation: animate-out-phone-call 600ms @customEasing 0s forwards;
	}

	.header-bar {
		height: 51px;
		position: relative;
		z-index: 1;
		background-color: @green;
		text-align: center;
		font-size: 20px;
		line-height: 20px;
		color: white;
		transition: background-color 400ms @customEasing;

		&--call-is-muted {
			background-color: @orange;
		}

		&--call-ended {
			background-color: @grey;
			color: @off-white;
		}

		&--call-is-connecting {
			color: fade(@off-white, 50%);
			transition: color 600ms @customEasing;

			.hang-up-button {
				animation: none;
			}
		}

		.hang-up-button__loading-svg {
			display: inline-block;
			rect { fill: @rose-dk; }

			// Fix janky svg alignment in FF.
			.browser-ff & { margin-top: -17px; }
		}
	}

	.column {
		position: absolute;
		padding-top: 15px;
		top: 0;

		&--left {
			right: ~'calc(50% + 55px)';
			text-align: right;
		}

		&--right {
			left: ~'calc(50% + 55px)';
		}
	}

	.hang-up-button {
		@keyframes animate-hang-up-color {
			0% { box-shadow: inset 0 0 0 @rose-dk; }
			50% { box-shadow: inset 0 0 30px @rose-dk; }
			100% { box-shadow: inset 0 0 0 @rose-dk; }
		}
		animation: animate-hang-up-color 2s infinite linear;

		position: absolute;
		left: 50%;
		top: -12px;
		z-index: 1;
		height: 71px;
		width: 71px;
		margin: 0 0 0 -36px;
		padding-top: 5px;
		border: 4px solid white;
		box-sizing: border-box;
		border-radius: 40px;
		background-color: @rose;
		color: white;
		font-weight: 500;
		transition: background-color 400ms @customEasing;

		&:hover, &:focus { background-color: @rose-dk; }

		span {
			animation: fadeIn 600ms @customEasing 0s forwards;
		}
	}

	.call-error-message {
		position: absolute;
		left: 50%;
		top: 25%;
		height: 20px;
		width: 710px;
		margin: -10px 0 0 -355px;
		text-align: center;
		animation: fadeIn 600ms @customEasing 0s forwards;

		a {
			text-decoration: underline;
		}
	}

	.call-completed-message {
		position: absolute;
		left: 50%;
		top: 50%;
		height: 20px;
		width: 200px;
		margin: -10px 0 0 -100px;
		text-align: center;
		font-weight: 500;
		animation: fadeIn 600ms @customEasing 0s forwards;
	}

	.doppler-effect-animation {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;

		@keyframes animate-doppler-effect {
			0% {
				transform: scale(1);
				border: 1px solid fade(white, 50%);
			}
			50% {
				transform: scale(1.3);
				border: 1px solid fade(white, 25%);
			}
			100% {
				transform: scale(1.5);
				border: 1px solid fade(white, 0%);
			}
		}

		&:after {
			content: '';
			position: absolute;
			left: 50%;
			top: -12px;

			height: 71px;
			width: 71px;
			display: block;
			margin: 0 0 0 -36px;
			background-color: transparent;
			border: 4px solid white;
			box-sizing: border-box;
			border-radius: 40px;
			transform-origin: center center;
			animation: animate-doppler-effect 2s infinite linear;
		}
	}
}
// action buttons
#phone-call-overlay {
	.action-buttons {
		position: absolute;
		top: 6px;
		right: 25px;
		z-index: 1;
		display: flex;
		align-items: center;
		text-align: right;

		animation: fadeIn 600ms @customEasing 0s forwards;
		opacity: 0;

		.call-header-button {
			cursor: pointer;
			display: flex;
			align-items: center;

			&--keypad {
				margin-right: 20px;
			}

			&:hover, &:active, &:focus {
				.call-header-button__icon {
					background-color: white;
					color: @green;
					fill: @green;
				}
				.call-header-button__label {
					color: white;
				}
			}
		}

		.call-header-button__icon {
			display: inline-block;
			height: 40px;
			width: 40px;
			border-radius: 25px;
			margin-right: 10px;
			background-color: fade(white, 20%);
			font-size: 17px;
			color: fade(white, 70%);
			fill: fade(white, 70%);
			transition: all 400ms @customEasing;
			text-align: center;

			&--activity {
				animation: fadeIn 600ms @customEasing 0s forwards;

				.react-icon {
					padding: 9px 0 0 0;
				}
			}

			&--keypad .react-icon {
				padding-top: 10px;
			}

			&--mute span {
				padding-top: 9px;
			}
		}

		.call-header-button__label {
			display: inline-block;
			font-size: 17px;
			font-weight: 500;
			color: fade(white, 70%);
			transition: all 400ms @customEasing;

			&--mute {
				width: 55px;
				text-align: left;
			}
		}
	}

	.header-bar--keypad-is-active {
		.call-header-button--keypad {
			.call-header-button__icon {
				background-color: fade(white, 80%);
				color: @green;
				fill: @green;
			}

			.call-header-button__label {
				color: fade(white, 80%);
			}
		}

		&.header-bar--call-is-muted {
			.call-header-button--keypad .call-header-button__icon {
				color: @orange;
				fill: @orange;
			}
		}
	}

	.header-bar--call-is-muted {
		.call-header-button--mute {
			.call-header-button__icon {
				background-color: fade(white, 80%);
				color: @orange;
				fill: @orange;
			}

			.call-header-button__label {
				color: fade(white, 80%);
			}
		}

		.call-header-button {
			&:hover, &:active, &:focus {
				.call-header-button__icon {
					background-color: white;
					color: @orange;
					fill: @orange;
				}
			}
		}
	}
}

// activity form
#phone-call-overlay {
	.ui-phone-call--on-open-animate-in .activity-form {
		@keyframes animate-in-activity-form {
			0% {
				transform: translateY(-60px);
				opacity: 0;
			}
			50% { opacity: 1; }
			100% { transform: translateY(0px); }
		}
		animation: animate-in-activity-form 600ms @customEasing 0s forwards;
	}

	.ui-phone-call--activity-form-is-minimized .activity-form {
		@keyframes animate-minimize-activity-form {
			0% { transform: translateY(0px); }
			100% { transform: translateY(-250px); }
		}
		animation: animate-minimize-activity-form 600ms @customEasing 0s forwards;
	}

	.ui-phone-call--on-close-animate-out .activity-form {
		@keyframes animate-out-activity-form {
			0% {
				transform: translateY(0px);
				opacity: 1;
			}
			100% {
				transform: translateY(-60px);
				opacity: 0;
			}
		}
		animation: animate-out-activity-form 600ms @customEasing 0s forwards;
	}

	.activity-form {
		position: absolute;
		top: 51px;
		left: 50%;
		z-index: 0;
		width: 505px;
		margin-left: -260px;
		padding: 15px;
		background-color: @off-white;
		border-radius:  0 0 4px 4px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
		font-size: @big;
		line-height: 20px;

		.minimize-activity-form-button {
			display: inline-block;
			padding: 0;
			margin-left: 15px;
			fill: @off-white-dk;
			transition: all 400ms @customEasing;

			rect {
				transition: transform 400ms @customEasing;
			}

			&:not(:disabled):hover {
				fill: @grey-lt;
				rect {
					transform: translateY(-8px);
				}
			}

			&:disabled {
				opacity: .5;
			}
		}

		.section-top {
			width: 100%;
			margin-bottom: 15px;
			color: @grey-lt;
			display: flex;
			justify-content: space-between;
			align-items: center;

			> div, select { display: inline-block; }

			.flex-column-left {
				max-width: 320px;
				#util > .excerpt-crop > .ellipsis;
			}

			.flex-column-right {
				display: flex;
				align-items: center;
			}
		}

		textarea {
			height: 100px;
			// hack because of crappy inheritance in mentionsInput
			border: 2px solid @off-white-dk;
			// hack because .orange-outline wasn't meant to be a mentions-input
			resize: vertical;
			&:focus {
				border-color: @grey;
				background-color: transparent;
			}
			&::-webkit-input-placeholder {
				font-style: italic;
				font-weight: normal;
				color: @off-white-dk;
			}
			&::-moz-placeholder          {
				font-style: italic;
				font-weight: normal;
				color: @off-white-dk;
			}
			&:-ms-input-placeholder      {
				font-style: italic;
				font-weight: normal;
				color: @off-white-dk;
			}
		}

		// properly align @mentions within textarea
		.mentions-input-box .mentions {
			padding: 10px !important;
		}

		.section-bottom {
			margin-top: 10px;
			display: flex;
			justify-content: space-between;

			.do-not-log {
				display: inline-block;
				padding-top: 8px;
				font-size: 14px;
				text-decoration: underline;
				transition: opacity 400ms @customEasing;

				&:disabled {
					opacity: .25;
				}
			}

			.ui-btn--big {
				min-width: 100px;
				transition: all 400ms @customEasing;
			}
		}
	}
}

.ui-phone-call-keypad {
	width: 160px;
	padding: 18px;

	.row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		&:last-child { margin-bottom: 0; }
	}

	.keypad-button {
		height: 45px;
		width: 45px;
		border: 1px solid @off-white-dk;
		border-radius: 25px;
		color: @grey;
		transition: all 400ms @customEasing;

		&:hover,
		&:focus {
			background-color: white;
			color: @grey-dk;
			border-color: @grey-dk;
		}

		&.animate-button-press {
			@keyframes animate-press-keypad-button {
				0% {
					background-color: @grey;
					color: @off-white;
					border-color: @grey-dk;
				}
				25% {
					background-color: @grey;
					color: @off-white;
					border-color: @grey-dk;
				}
				100% {
					background-color: white;
					color: @grey-dk;
					border-color: @grey-dk;
				}
			}

			animation: animate-press-keypad-button 400ms @customEasing 0s;
		}

		.keypad-button__digit {
			font-size: 22px;
			line-height: 22px;

			.fa-hashtag, .fa-asterisk {
				font-size: 16px;
				line-height: 16px;
			}
		}

		.keypad-button__letters {
			font-size: 8px;
			line-height: 8px;
		}
	}
}

.ui-modal.ui-modal--activity-is-pending {
	height: 600px;

	.ui-modal-body {
		padding: 40px;
		width: 330px;
		height: auto;
	}
}
