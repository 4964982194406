.drip-sequence-mini-map-sidebar {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 168px;
    background-color: #fff;
    border: 1px solid var(--off-white-md);
}

.border-top-left-8 {
    border-top-left-radius: 8px;
}

.drip-sequence-mini-map-sidebar > label {
    padding-top: 16px;
    padding-left: 16px;
}

.mini-map-container {
    overflow-y: auto;
    padding: 8px 16px 16px 16px;
}

.drip-sequence-mini-map-sidebar::after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.001), #fff);
}

.preview {
    padding: 8px;
    /* Subtract the height of thumbnail card name */
    height: calc(100% - 32px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
