.dash-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
}

.dash-grid--no-scroll {
    composes: dash-grid;
    overflow: hidden;
}

.info-header-container {
    min-width: 300px;
}

.title-bar-container {
    display: flex;
    flex-shrink: 0;
    padding: 16px 16px 8px 16px;
    background-color: #fff;
    align-items: center;
    border-bottom: 1px solid var(--off-white-dk);
}

.site-logo {
    margin-right: 16px;
    height: 40px;
    width: 140px;
}

.full-width-cards {
    margin: 16px;
}

.timeline-banner {
    width: 100%;
    margin: 0 8px;
}
