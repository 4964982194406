/* ============================ */
/* = image path = */
/* ============================ */

@import "_icons.less";
@import "ui/global/_colors";
@import 'ui/shared/popover/_popover-nub.less';
@img-root: '/include/images';

/* ============================ */


/* ============================ */
/* = grid = */
/* ============================ */

@page-width: 940px;
@page-content-width: 680px;
@page-sidebar-width: 220px;

@page-alternate-sidebar-width: 260px;
@page-alternate-content-width: 640px;

@sidebar-width: 200px;
@header-height: 50px;
@onboard-progress-height-lg: 60px;
@onboard-progress-height-md: 55px;
@onboard-progress-height-sm: 34px;

@peep-right-sidebar-width: 385px;

@screen-xs-max: 767px;
@screen-sm-min: 768px;
@screen-sm-max: 991px;
@screen-md-min: 992px;
@screen-md-max: 1199px;
@screen-lg-min: 1200px;

//yes this is terrible
@dashboard-filter-breakpoint: 1200px;
@show-expanded-sidebar-width: 1200px;

/* ============================ */


/* ============================ */
/* = font = */
/* ============================ */

@headline-font: 'IBM Plex Sans', "Helvetica Neue", sans-serif; // 400, 700, 400italic, 700italic
@body-font: 'IBM Plex Sans', "Helvetica Neue", sans-serif; // 400, 700, 400italic, 700italic
@big: 17px; // line-height: 22px;
@normal: 15px; // line-height: 20px;
@small: 13px; // this ensures for consistency "Did I use 12px or 11px last time?
@tiny: 12px; // line-height: 15px;

/* ============================ */

/*--- Retina stuff --- */

// Retina device specification
@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

// Retina sprite mixin. Individual sprite mixins should call this with appropriate params.
// Retina versions should use the same name as the base version with _2x appended and be saved in the same directory
// e.g. /images/sprites/my-sprite.png and /images/sprites/my-sprite_2x.png
.sprite-helper(@path, @width, @height) {
	@media @highdpi {
		@at2x_path: ~`@{path}.replace(/\.[\w\?=]+$/, function(match) { return "_2x" + match; })`; // Get image path minus file type, append _2x to it
		background-image: url("@{img-root}/@{at2x_path}");
		background-size: @width @height;
	}
	background-image: url("@{img-root}/@{path}");
	background-position: left top;
	background-repeat: no-repeat;
}

// retina mixin
// inspired by retina.js, with stuff borrowed from some open pull requests
.2x(@path, @position, @w, @h) {
	background-image: url("@{path}");
	background-repeat: ~"@{position}";
	@at2x_path: ~`@{path}.split('.').slice(0, "@{path}".split('.').length - 1).join(".") + "_2x" + "." + @{path}.split('.')["@{path}".split('.').length - 1]`;

	@media @highdpi {
		background-image: url("@{at2x_path}");
		background-repeat: ~"@{position}";
		background-size: @w @h;
	}
}
// variation to allow background sizes like "cover" and "contain"
.2x(@path, @position, @size: auto) {
	background-image: url("@{path}");
	background-repeat: ~"@{position}";
	background-size: @size;
	@at2x_path: ~`@{path}.split('.').slice(0, "@{path}".split('.').length - 1).join(".") + "_2x" + "." + @{path}.split('.')["@{path}".split('.').length - 1]`;

	@media @highdpi {
		background-image: url("@{at2x_path}");
		background-repeat: ~"@{position}";
		background-size: @size;
	}
}

.littlecaps() {
	font-style: normal;
	margin-bottom: 6px;
	color: @grey;
	font-family: @body-font;
	font-size: @tiny;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.highlighter() {
	display: inline;
	padding: 0 3px;
	background: rgba(250,242,160,0.5);
}
.layout-sprite() {
	.sprite-helper("sprites/layout-sprite.png?v=2", 245px, 255px);
}
.layout-sprite-help-box() {
	.sprite-helper("sprites/help-box-sprite.png", 100px, 100px);
}
.timeline-sprite() {
	.sprite-helper("timeline2/timeline-sprite.png", 300px, 50px);
}

/* ============================ */
/* = utilities = */
/* ============================ */

// This easing equation provides a nicer easing animation when compared
// with the standard linear/ease-in
// ex: use this `@customEasing` var in place of linear, ease-in, ease-in-out etc.
@customEasing: cubic-bezier(0.230, 1.000, 0.320, 1.000);

#util() {
	.opacity(@factor: 0.5) {
		opacity: @factor;
		@iefactor: @factor * 100;
	}

	.gradient() {
		// simple gradient
		.vertical(@from: #ffffff, @to: #000000) {
			background-color: @from;
			background-image: -webkit-linear-gradient(top, @from 0%, @to 100%); /* Chrome10-25,Safari5.1+ */
			background-image: -moz-linear-gradient(top, @from 0%, @to 100%); /* FF3.6-15 */
			background-image: -o-linear-gradient(top, @from 0%, @to 100%); /* Opera 11.10 */
			background-image: linear-gradient(to bottom, @from 0%, @to 100%); /* W3C */
		}
		.verticalNofallback(@from: #ffffff, @to: #000000) {
			// no background-color, for cases where we're doing an rgba gradient
			background-image: -webkit-linear-gradient(top, @from 0%, @to 100%); /* Chrome10-25,Safari5.1+ */
			background-image: -moz-linear-gradient(top, @from 0%, @to 100%); /* FF3.6-15 */
			background-image: -o-linear-gradient(top, @from 0%, @to 100%); /* Opera 11.10 */
			background-image: linear-gradient(to bottom, @from 0%, @to 100%); /* W3C */
		}
		// multiple color stops
		.verticalStops(@from: #ffffff, @to: #000000, @colorstops) {
			background-color: @from;
			background-image: -webkit-linear-gradient(top, ~"@{colorstops}"); /* Chrome10+,Safari5.1+ */
			background-image: -moz-linear-gradient(top, ~"@{colorstops}"); /* FF3.6+ */
			background-image: -o-linear-gradient(top, ~"@{colorstops}"); /* Opera 11.10+ */
			background-image: linear-gradient(to bottom, ~"@{colorstops}"); /* W3C */
		}
		// vertical or horizontal
		.oriented(@direction, @startColor: #ffffff, @start: 0%, @stopColor: #000000, @stop: 100%) {
			background-color: @startColor;
			background-image: -webkit-linear-gradient(@direction, @startColor @start, @stopColor @stop); /* Chrome10-25,Safari5.1+ */
			background-image: -moz-linear-gradient(@direction, @startColor @start, @stopColor @stop); /* FF3.6-15 */
			background-image: -o-linear-gradient(@direction, @startColor @start, @stopColor @stop); /* Opera 11.10 */
		}
		.directionTop(@startColor, @start, @stopColor, @stop) {
			#util .gradient .oriented(top, @startColor, @start, @stopColor, @stop);
			background-image: linear-gradient(to bottom, @startColor @start, @stopColor @stop);
		}
		.directionBottom(@startColor, @start, @stopColor, @stop) {
			#util .gradient .oriented(bottom, @startColor, @start, @stopColor, @stop);
			background-image: linear-gradient(to top, @startColor @start, @stopColor @stop);
		}
		.directionLeft(@startColor, @start, @stopColor, @stop) {
			#util .gradient .oriented(left, @startColor, @start, @stopColor, @stop);
			background-image: linear-gradient(to right, @startColor @start, @stopColor @stop);
		}
		.directionRight(@startColor, @start, @stopColor, @stop) {
			#util .gradient .oriented(right, @startColor, @start, @stopColor, @stop);
			background-image: linear-gradient(to left, @startColor @start, @stopColor @stop);
		}
		.no-ie(@from: #ffffff, @to: #000000) {
			background-color: @from;
			background-image: -webkit-linear-gradient(top, @from 0%, @to 100%); /* Chrome10+,Safari5.1+ */
			background-image: -moz-linear-gradient(top, @from 0%, @to 100%); /* FF3.6+ */
			background-image: -o-linear-gradient(top, @from 0%, @to 100%); /* Opera 11.10+ */
			background-image: linear-gradient(to bottom, @from 0%, @to 100%); /* W3C */
		}
	}

	// for IE7 compatibility
	.inline-block() {
		display: inline-block;
	}

	.clearfix() {
		&:before,
		&:after {
			content:"";
			display:table;
		}
		&:after {
			clear:both;
		}
	}

	.no-select() {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.rounded-corners(@radius: 3px) {
		border-radius: @radius;
	}
	.rounded-corners(@radius: 3px, top) {
		border-top-left-radius: @radius;
		border-top-right-radius: @radius;
	}
	.rounded-corners(@radius: 3px, bottom) {
		border-bottom-left-radius: @radius;
		border-bottom-right-radius: @radius;
	}

	.shadow {
		// @DEPRECATED. no need for this anymore, we don't support firefox 3.6 and safari 5.0 is too tiny to care about
		.box(@args) {
			box-shadow: ~"@{args}";
		}
		// @DEPRECATED. no need for this anymore, we don't support firefox 3.6 and safari 5.0 is too tiny to care about
		.inset() {
			#util > .shadow > .box("inset 0 3px 4px -2px rgba(0,0,0,0.5)");
		}
	}

	.border-box() {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.blocked-content() {
		content: '';
		position: absolute;
		background: transparent;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
	}

	.animation(@keyframes, @duration: 1s, @easing: linear, @delay: 0s, @iteration: 1) {
		-webkit-animation:@keyframes @duration @easing @delay @iteration;
		-moz-animation:@keyframes @duration @easing @delay @iteration;
		animation:@keyframes @duration @easing @delay @iteration;
	}

	.transition(@property, @duration: 1s, @easing: ease-in-out, @delay: 0s) {
		-webkit-transition: @property @duration @easing @delay;
		-moz-transition: @property @duration @easing @delay;
		transition: @property @duration @easing @delay;
	}

	.transitionSets(@propertySet1: '', @propertySet2: '', @propertySet3: '') {
		-webkit-transition: @propertySet1, @propertySet2, @propertySet3;
		-moz-transition: @propertySet1, @propertySet2, @propertySet3;
		transition: @propertySet1, @propertySet2, @propertySet3;
	}

	.transform(@properties) {
		-webkit-transform: @properties;
		-moz-transform: @properties;
		-ms-transform: @properties;
		-o-transform: @properties;
		transform: @properties;
	}

	.transform-180() {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	.transition-none() {
		-webkit-transition: none;
		-moz-transition: none;
		transition: none;
	}

	.placeholder(@color) {
		  &::-webkit-input-placeholder { color: @color; }
		  &:-moz-placeholder { color: @color; }
		  &::-moz-placeholder { color: @color; }
		  &:-ms-input-placeholder { color: @color; }
	}

	.placeholder-transition(@property, @duration: 1s, @easing: linear) {
		&::-webkit-input-placeholder { transition: @property @duration @easing; }
	  	&:-moz-placeholder { transition: @property @duration @easing; }
	  	&::-moz-placeholder { transition: @property @duration @easing; }
	  	&:-ms-input-placeholder { transition: @property @duration @easing; }
	}

	// see https://redmine.nutshell.com:447/issues/730
	.text-shadow() {
		.dark-bg-light {
			-webkit-font-smoothing: antialiased;
			text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.66);
		}
		.dark-bg-grey {
			-webkit-font-smoothing: antialiased;
			text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.33);
		}
		.light-bg { text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5); }
		.very-light-bg { text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.8); }
		.shadowed { text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5); }
	}

	.excerpt-crop() {
		.ellipsis {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	// we use this a few unique places, so it's just a mixin
	// clean me into .input-rounded :(
	.table-form() {
		.input {
			border-radius: 5px;

			float: right;
			width: 70px;

			border: 1px solid gray;
			padding: 3px;
			margin: 0px;
			font-size: 13px;
			font-family: arial;
			text-align: right;
		}
	}
	.input-rounded() {
		#util > .rounded-corners(3px);
		border: 1px solid @off-white-dk;
		margin: 0;
		padding: 3px;
	}

	.hide-safari-autofill() {
		input::-webkit-contacts-auto-fill-button {
			visibility: hidden;
			display: none !important;
			pointer-events: none;
			position: absolute;
			right: 0;
		}
	}
}

.note-edit-buttons() {
	.action-delete {
		position: absolute;
		right: 3px;
		top: 4px;
		width: 12px;
		height: 12px;
		text-shadow: none;
		line-height: 12px;
		text-align: center;
		font-size: 15px;
		font-weight: 500;
		color: @grey-lt;
		&:hover {
			#util > .gradient > .vertical(#f22c3d, #f85e7d); /* TODO: standardize color */
			border: 1px solid rgba(175,23,45,0.6);
			border-radius: 8px;
			box-shadow: 0 1px 1px white;
			color: @off-white;
			text-shadow: 0 1px 0 rgba(0,0,0,0.3);
			opacity: 0.85;
		}
	}
	.action-edit {
		display: none; // display used for if iseditable, opacity used for hover state
		position: absolute;
		opacity: 0.4;
		.timeline-sprite();
		background-position: -250px -13px;
		width: 13px;
		height: 13px;
		top: 4px;
		right: 19px;
		&:hover {
			opacity: 0.8;
		}
	}

	.state-iseditable& {
		min-height: 28px;
		.action-edit { display: block; }
	}

	&.state-editing {
		.action-delete, .action-edit { display: none !important; }
	}
}

.action-hide-link() {
	a.action-hide {
		font-size: @tiny;
		position: absolute;
		right: 10px;
		top: 5px;
		padding: 3px 0;
		color: @blue;

		&:hover {
			color: @blue-dk;
			text-decoration: underline;
		}
	}
}
