@import 'form/_inputs.less';
@import 'form/_select.less';
@import 'form/_sliders.less';
@import 'form/_radio-groups';
@import 'form/_searchable';

.ui-form {
	margin-bottom: 10px;

	&.ui-form--inline {
		display: inline-block;
		margin-bottom: 0;
	}

	&.ui-form--enum {
		max-height: 200px;
		overflow-y: scroll;
		padding: 0 5px;
	}
}
