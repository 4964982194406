/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Defines the different entity type colors which are shared among all the
 * other section components.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.person {
    background-color: var(--blue-xlt);
    color: var(--blue);
}

.person:hover {
    background-color: var(--blue-lt);
}

.company {
    background-color: var(--plum-xlt);
    color: var(--plum);
}

.company:hover {
    background-color: var(--plum-lt);
}

.product {
    background-color: var(--brown-lt);
}

.product:hover {
    background-color: var(--brown);
}

.competitor {
    background-color: var(--rose-lt);
}

.competitor:hover {
    background-color: #ffc7cb; /* Doesn't have a var */
}

.source {
    background-color: var(--off-white-dk);
}

.source:hover {
    background-color: var(--button-secondary-dk);
}

.quote {
    background-color: var(--green-xlt);
}

.quote:hover {
    background-color: var(--green-lt);
}
