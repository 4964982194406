/*
Our custom styling to select2 elements
There are lots of !important declarations in this file, they are mainly
used to override the external _select2.less file
*/

.select2-results {
	font-size: @small;
	padding: 0;
	margin: 0;

	.select2-result {
		&.select2-highlighted {
			background-color: @color-list-selected;
			color: @grey-dk;
		}
		&:last-child {
			border-radius: 0 0 3px 3px;
		}
		.icon {
			width: 16px;
			height: 16px;
			display: inline-block;
			.2x('@{img-root}/icons/select2-sprite.png?v=1', "no-repeat", 80px, 16px);
			margin-right: 8px;
			position: relative;
			top: 3px;
		}
		&.type-territories .icon   { background-position: 0 0; }
		&.type-users .icon         { background-position:  -16px 0; }
		&.type-sources .icon       { background-position: -32px 0;  }
		&.type-teams .icon         { background-position: -48px 0;  }
		&.type-products .icon      { background-position: -64px 0;  }
		&.type-accountTypes .icon,
		&.type-industries .icon,
		&.type-create-new .icon {
			display: none;
		}
		&.type-stagesets .icon {
			// Hide sprite and use fontawesome
			background: none;
			&:before {
				content: "\f0b0";
				font-size: 16px;
				font-family: "FontAwesome";
				color: @grey;
				position: relative;
			    top: -2px;
			    left: 2px;
			}
		}
	}
	.select2-no-results {
		background-color: white;
		padding: 5px 8px;
		a.needs-admin-inline {
			color: @blue;
			margin-left: 4px;
			&:hover { color: @blue-dk; }
		}
	}
	.select2-result-label {
		padding: 6px 8px;
		position: relative;
		span.tags-create-new {
			color: @blue;
			padding-right: 4px;
		}
	}
}

.select2-spartan { // when we wanna put a multi-select2 in something that's already a container (timeline2, soon tags)
	ul.select2-choices {
		border: transparent !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	input {
		padding-left: 0px !important; // so the text / placeholder is flush left
	}

	&.variant-minimal {
		.select2-choices .select2-search-choice {
			margin: 0;
			padding: 0;
			border: none;
			box-shadow: none;

			> div {
				padding-left: 0;
				border-left: none;
			}

			> a {
				right: -15px;
				left: auto;
			}
		}
	}
}

.select2-search {
	padding: 0;
	input.select2-input {
		border: none;
		border-bottom: 2px solid @off-white-md;
		margin-top: 0 !important;
		&.select2-focused {
			border-color: @orange-lt;
		}
	}
}

.select2-drop {
	font-size: @small;
	color: @grey;
	background: white;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); // Same as .tselect
	border: 1px solid @off-white-dk;
	overflow: hidden; // Stops bg color from bleeding over border-radius
	&.select2-drop-above {
		border: 1px solid @off-white-dk !important;
		box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
		.select2-choices {
			background: none;
			border: none;
		}
	}
}

.select2-container {
	font-size: @small;
	&.select2-container-active ul.select2-choices {
		background: none;
		border: none;
	}
	.select2-choice {
		font-size: @small;
		line-height: 17px;
		font-weight: 500;
		text-decoration: none !important;
		color: @grey !important;
		background: @off-white-md !important;
		box-shadow: none;
		border: none;
		border-radius: 3px;
		height: auto;
		padding: 4px 10px;

		.select2-chosen {
			margin-right: 30px;
		}

		.select2-search-choice-close {
			top: 7px;
		}

		.select2-arrow {
			#util > .transition-none;
			border: none;
			background: none;
			width: 25px;
			&:before {
				content: "\f078";
				font-size: 11px;
				font-family: FontAwesome;
				color: @grey-lt;
				position: absolute;
				top: 4px;
				right: 8px;
			}
			b {
				display: none;
			}
		}
		&:hover {
			color: @grey-dk !important;
			background: @off-white-dk !important;
			.select2-arrow::before {
				color: @grey;
			}
		}
	}

	&.select2-dropdown-open {
		.select2-choice {
			color: @grey-dk !important;
			border: none;
			border-radius: 3px 3px 0 0;
			background: @off-white-dk !important;
			.select2-arrow::before {
				color: @grey;
			}
		}
		&.select2-drop-above {
			.select2-choice {
				border-radius: 0 0 3px 3px;
			}
		}
	}

	&.blink-success .select2-choice {
		background: @green-lt !important;
	}
	&.blink-error .select2-choice {
		background: @rose-lt !important;
	}
}

.select2-container-multi {
	font-size: @small;

	.select2-choices {
		min-height: auto;
		background: none;
		border: none;
		box-shadow: none !important;
		padding: 8px 0 5px 0;

		.select2-search-choice {
			font-size: @small;
			color: @grey;
			background: @off-white-md;
			box-shadow: none;
			border: none;
			margin: 0 5px 5px 0;
			padding: 0 5px 0 20px;
			line-height: 21px;
		}

		.select2-search-choice-close {
			top: 5px;
			left: 5px;
		}
		.select2-search-field {
			input.select2-input {
				font-family: @body-font;
				font-style: italic;
				padding: 2px 5px 5px 5px;
				&.select2-focused {
					font-style: normal;
				}
			}
		}
	}
}
