.table-container {
    border-left: 1px solid var(--off-white-dk);
    border-right: 1px solid var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    max-width: 100%;
    overflow: auto;
}

.table-container--with-more {
    border-bottom: none;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}

.table-container--show-overflow {
    overflow: visible;
}

.table-container--hide-overflow {
    overflow: hidden;
}
