@import 'include.less';
@import '_internal.less';

.demio-events {
	.demio-log-participant-activity {
		.logged {
			display: none;
		}

		.unlogged {
			display: block;
		}

		&.status-logged {
			.logged {
				display: block;
			}
			.unlogged {
				display: none;
			}
		}
	}

	.date {
		color: @grey-dk;
		margin-bottom: 8px;
	}

	.event-name {
		color: @grey-lt;
		font-weight: normal;
		margin-bottom: 16px;
	}

	.attendee {
		display: flex;
		margin-bottom: 4px;
		justify-content: space-between;
		align-items: center;
		width: 600px;
		padding: 4px 0 4px 16px;
		margin-left: -16px;
		.type-contacts {
			color: @blue;
		}
		.type-leads {
			color: @green;
		}
		&:nth-child(2n) {
			background-color: @off-white-bg;
		}
		&:first-child {
			margin-top: 8px;
		}
	}
}
