.item-type {
    width: 90px;
}

.price {
    display: flex;
    align-items: center;
    height: 34px;
    width: 125px;
    justify-content: flex-end;
    word-break: break-word;
}

.delete-icon {
    position: absolute;
    right: 10px;
    top: 2px;
}

.dropdown {
    width: 60px;
}

.value {
    width: 68px;
}
