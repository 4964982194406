/* Used in component to raise anchor */
@value overlay-z-index from '../popover/popover.css';
@value buffer-size: 30px;

.hover-popover {
    position: relative;
    z-index: 0;
}

.hover-buffer {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    /* Buffer centered around popover */
    top: calc(buffer-size * -1);
    left: calc(buffer-size * -1);
    padding: buffer-size;
}
