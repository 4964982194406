.title-container {
    display: flex;
    align-items: center;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
}

.delta {
    display: flex;
    align-items: center;
}

.delta > *:first-child {
    display: flex;
    align-items: center;
    padding-right: 4px;
}
