.goal-type-value {
    display: flex;
    align-items: center;
}

.goal-type-option {
    margin: 0; /* Overrides parent margin left/right styling */
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 4px 0;
}

.goal-type-option-icon {
    text-align: center;
    width: 18px; /* maximum width of every icon */
    height: 18px;
    /* By default, react-select has a 10px margin-left, so this makes it 16px */
    margin-left: 6px;
    margin-right: 16px;
    flex-shrink: 0;
}

.goal-type-option-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.goal-type-option-title {
    color: var(--grey);
    font-size: var(--font-normal);
    line-height: 18px;
}

.goal-type-option-subtitle {
    font-size: var(--font-small);
    color: var(--grey-lt);
    margin-top: 4px;
}
