.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.product-name {
    display: inline;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 16px;
    font-size: var(--font-small);
}

.product-quantity,
.product-price {
    margin: 0 2px;
}

.product-quantity {
    width: 40px;
    text-align: right;
    line-height: 33px;
}

.product-price {
    width: 80px;
    text-align: right;
    line-height: 33px;
}
