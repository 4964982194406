.notify-processing {
    composes: notify-item from './notify-complete.css';
}

.text-container {
    composes: text-container from './notify-complete.css';
    position: relative;
    background-color: var(--off-white-dk);
    height: 21px; /* text is absolutely positioned for z-index */
}

.completion-background {
    z-index: 1;
    position: absolute;
    background-color: var(--grey);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
}

.notify-text {
    composes: notify-text from './notify-complete.css';
    position: absolute;
    z-index: 10;
    font-style: italic;
    color: #fff;
    user-select: none;
}

.icon {
    float: right;
    line-height: 27px;
}
