@import '../../../../include';
@import '../../../global/_colors';

.signup-thank-you .content-wrapper {
	text-align: center;

	h1 {
		margin: 10px 0 20px 0;
		color: @green;
	}

	h2 {
		margin-bottom: 40px;
		> span {
			color: @orange;
		}
	}

	p {
		margin: 20px 0;
	}

	.splash-container {
		display: inline-block;
		width: 300px;
		height: 120px;
		.check-mark-container {
			width: 80px;
			height: 80px;
			line-height: 80px;
			font-size: 50px;
			box-shadow: inset 0 0 0 3px @green-lt, 0 0 0 3px @grey-lt, 0 0 0 6px white;
			border-radius: 50%;
			background-color: white;
			color: @green;
			margin: 20px auto;
		}
	}

	.cta-buttons {
		> a {
			margin: 0;
			&:nth-child(2) {
				margin-left: 17px;
			}
		}
	}

	.cta-mobile-apps {
		> a {
			width: 160px;
			display: inline-block;
			&:nth-of-type(2) {
				margin-left: 10px;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		> p {
			margin: 40px 0 15px 0;
		}
	}
}

.svg-loading--signup-forms svg {
	fill: @off-white-dk;
}

[data-is-ie11] #signup-form {
	left: 234px;
}

.signup-goodbye .content-wrapper {
	text-align: center;

	h1 {
		margin: 10px 0 20px 0;
		color: @green;
	}

	h2 {
		margin-bottom: 40px;
	}

	p {
		margin: 20px 0;
	}

	.splash-container {
		display: inline-block;
		width: 300px;
		height: 120px;
		.check-mark-container {
			width: 80px;
			height: 80px;
			line-height: 80px;
			font-size: 50px;
			box-shadow: inset 0 0 0 3px @orange-lt, 0 0 0 3px @grey-lt, 0 0 0 6px white;
			border-radius: 50%;
			background-color: white;
			color: @sidefire;
			margin: 20px auto;
		}
	}
}

#signup-form {
	position: absolute;
	clear: both;
	padding-bottom: 50px;
	margin: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	:not([data-is-signup='1']) {
		&.state-is-loading {
			height: 460px;
			position: relative;

			.billing-form-wrapper {
				height: 610px;
			}

			.loading-overlay {
				animation: fadeIn 2s @customEasing 0s forwards;
				opacity: 0;

				width: 100%;
				height: 100%;

				// loading state empty boxes
				&:before,
				&:after {
					content: '';
					display: inline-block;
					height: ~'calc(100% - 45px)';
					margin-top: 45px;
					border: 2px solid fade(@off-white-md, 50%);
					border-radius: 3px;
					box-sizing: border-box;
				}
				&:before {
					margin-right: 40px;
					width: 490px;
				}
				&:after {
					width: 350px;
				}
			}

			.svg-loading--signup-forms {
				height: 40px;
				width: 40px;
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -20px 0 0 -20px;
			}
		}

		.billing-form-wrapper.state-is-loading {
			height: 555px;

			.loading-overlay {
				overflow: hidden;

				// loading state empty boxes
				&:before,
				&:after {
					margin-top: 45px;
				}
				&:before {
					width: 350px;
					height: 510px;
				}
				&:after {
					width: 310px;
					height: 210px;
					float: right;
				}
			}
		}
	}

	.tabbed-navigation {
		clear: both;
		background-color: @off-white;
		border-bottom: 1px solid @off-white-dk;
		padding: 16px 0 0 16px;

		button {
			margin: 0 8px -1px 0;
			display: inline-block;
			border: 0;
			border-radius: 3px 3px 0 0;
			background-color: @off-white-md;
			border: 1px solid transparent;
			border-bottom: none;
			padding: 10px 16px;
			color: @grey;
			font-size: @big;
			font-weight: normal;
			letter-spacing: 0.5px;
			outline: none;
			transition: all 250ms @customEasing;
			&:hover,
			&:focus {
				color: @grey-dk;
			}

			&[disabled] {
				opacity: 1;
				color: @grey-dk;
				font-weight: 500;
				background: white;
				border-color: @off-white-dk;
			}
		}
	}

	.billing-form-wrapper {
		// This div is floating, so we need to push the footer down a little.
		margin-bottom: 10px;
	}

	.billing-form-wrapper,
	.plan-form-wrapper {
		max-width: 930px;
		padding: 0 16px;
		h2 {
			height: 31px;
			margin-bottom: 20px;
			font-size: 24px;
			line-height: 31px;
			color: @grey-dk;
		}

		.radio-group-container {
			.ui-radio-group {
				display: block;
				height: 34px;
				margin: 0 3px;
			}
		}
	}

	.plan-form-wrapper {
		padding-right: 0 !important;
	}

	.footer-section {
		clear: both;
		padding: 15px 0;
		margin-bottom: 50px;
		border: 2px solid @off-white-md;
		border-width: 2px 0;
		text-align: right;
		max-width: 880px;

		button {
			min-width: 80px;
			width: 100%;
			height: auto;
			margin: 16px;
			justify-content: center;
			font-size: 21px;
			font-weight: 500;
			padding: 12px;
		}
	}
}
