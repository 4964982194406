.tooltip-container {
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 3px;
    color: var(--white);
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
}
