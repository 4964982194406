.container {
    border-left: 2px solid var(--off-white-dk);
    box-sizing: border-box;
    width: 184px;

    flex-grow: 1;

    font-size: 20px;
    color: var(--grey-lt);
}

.type-creator-modal {
}

.type-popover-picker {
    font-size: var(--font-big);
}

.type-popover-picker .canned-option:last-child {
    border-bottom: 0;
}

.scroll-container {
    height: calc(100% - 51.25px);
    /* Relative position is required for properly calculating offsetTop of
     * its children. */
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;

    padding-bottom: 50.25px;
}

.option {
    height: 52px;
    width: 100%;
    box-sizing: border-box;

    background-color: var(--off-white);
    border-bottom: 2px solid var(--off-white-dk);

    transition: all 100ms ease;
}

.option:hover {
    background-color: white;
}

.option:hover .label:not(.label--disabled),
.option:active .label:not(.label--disabled),
.option--selected .label {
    color: var(--grey-dk);
}

.option--selected {
    composes: option;

    background-color: white;
    border-color: var(--orange-lt);
}

.radio {
    display: none;
}

.label {
    height: 100%;
    /* Account for padding. */
    width: calc(100% - 14px);

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 0 0 14px;

    cursor: pointer;
    transition: color 100ms ease;
}

.label--disabled {
    composes: label;
    color: var(--off-white-dk);
    cursor: default;
}

.check-icon {
    fill: var(--orange-lt);
    margin-right: 15px;
}

/* Lean styling for standalone time-picker, outside of the normal
 * date-time picker. */
.container.type-lean {
    border-left: none;
    height: 140px;
    width: 100px;
    font-size: 15px;
}

.container.type-lean .option {
    height: 27px;
    border-bottom: none;
}

.container.type-lean .option--selected {
    background-color: var(--color-list-selected);
}

.container.type-lean .option:hover,
.container.type-lean .option--selected:hover {
    background-color: var(--color-list-hovered);
}

.container.type-lean .option--selected .check-icon {
    display: none;
}

.container.type-lean .scroll-container {
    height: 100%;
    padding-bottom: 0;
}

.container.type-lean .label {
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
}
