.lead-card-preview-wrapper {
    background-color: var(--off-white-dk);
    border-radius: 3px;
    /* HACK: needed to prevent empty drag preview (https://github.com/gaearon/react-dnd/issues/454) */
    transform: translate3d(0, 0, 0);
}

.lead-card-preview-wrapper-draggable {
    composes: lead-card-preview-wrapper;
    cursor: grab;
}
