.spam-prevention-form {
    padding: 16px;
    box-sizing: border-box;
}

.form-description {
    composes: form-description from '../../../core/form/fields/forms.css';
    font-size: var(--font-normal);
}

.form-label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: var(--font-normal);
    font-weight: 500;
    line-height: 18px;
}

.form-label > input {
    margin-right: 8px;
}

.radio-row {
    display: flex;
    align-items: center;
}
