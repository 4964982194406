.container {
    width: 100%;
}

.option-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.option-title {
    display: flex;
    align-items: center;
    font-size: var(--font-normal);
    color: var(--grey-dk);
    margin-right: 16px;
    min-width: 200px;
}

.option-subtitle {
    font-size: var(--font-small);
    color: var(--grey-lt);
}

.dot {
    composes: color-name-option-dot from '../select-option-components/color-named-option.css';
}
