.tab {
    height: 40px;
    display: flex;
    align-items: center;
}

.tab-container {
    position: sticky;
    top: 0;
    z-index: 1;
}

.count {
    font-weight: normal;
    margin-left: 4px;
}

.count--over-limit {
    color: var(--rose);
}
