.popover-container {
    padding: 24px;
}

.action-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}
