.preview-container {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    min-height: 480px;
}

.column-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}

.preview-container-right {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    display: flex;
    align-self: stretch;
    padding: 24px;
    height: 100%;
}

.thumbnail-container {
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    width: 100%;
}
