@import '../_avatar.less';

.timeline3-entry,
.timeline-comment {
	.action-delete {
		font-size: 22px;
		position: absolute;
		top: 0;
		right: 2px;
		opacity: 0.1;
		padding: 4px;
		cursor: pointer;
	}
	&:hover .action-delete {
		opacity: 1;
		&:hover {
			color: @rose;
		}
	}

	&.type-emails + .timeline3-entry-files,
	&.type-emailsQueued + .timeline3-entry-files {
		display: none;

		.variant-full & {
			display: block;
		}
	}

	&.actor-type-users {
		.avatar {
			background-color: @orange-dk;
		}
	}
	&.actor-type-contacts {
		.avatar {
			background-color: @navy;
		}
	}
}
