.help-content {
    position: relative;
    padding: 24px 12px 8px 48px;
}

.bookmark {
    position: absolute;
    top: 0;
    left: 12px;
    width: 20px;
    height: 36px;
    background: url('/include/images/layout/help-bookmark.svg');
}

.help-header {
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 24px;
}

.section {
    margin-bottom: 18px;
}

.header {
    font-size: var(--font-normal);
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 4px;
}

.content {
    margin-bottom: 8px;
}
