.empty-state {
    color: var(--grey-lt);
}

.note {
    position: absolute;
    bottom: 0;
    left: 32px;
    font-style: italic;
}
