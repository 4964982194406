@value gradient-height: 64px;

.bookmark-container {
    height: 64px;
    width: 100%;
    position: relative;
}

.title {
    color: var(--grey-dk);
    font-family:
        doyle,
        IBM Plex Sans;
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
    padding: 0 36px 24px 36px;
}

.cue-cards-container {
    padding: 0 36px 16px 36px;
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
}

.cue-cards-container > div > :first-child,
.cue-cards-container > div > :last-child {
    /* Raise above scroll shadow */
    position: relative;
    z-index: 2;
}

.cue-cards-container::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: calc(100% - 17px); /* Somewhat-standard width of browser scrollbars */
    height: gradient-height;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 0) 100%
    );
}

.cue-cards-container::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 170px;
    left: 0;
    width: calc(100% - 17px); /* Somewhat-standard width of browser scrollbars */
    height: gradient-height;
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 0) 100%
    );
}
