.drag-drop-overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.7);
	z-index: 10;

	&.active {
		display: block;
	}
}

.drag-drop-target-container {
	position: absolute;
	top: 0;
	left: 0;
	display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox; /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
	display: flex;
	-webkit-justify-content: space-around;
	justify-content: space-around;
	-webkit-align-items: center;
	align-items: center;
	-webkit-content: center;
	align-content: center;
	width: 100%;
	height: 100%;

	.drop-target {
		position: relative;
		display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
		display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
		display: -ms-flexbox; /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Chrome */
		display: flex;
		width: 280px;
		height: 280px;
		color: white;
		background: rgba(0, 0, 0, 0.58);
		border-style: dashed;
		border-width: 6px;
		border-color: @grey-dk;
		border-radius: 20px;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		-webkit-align-content: center;
		align-content: center;
		-webkit-flex-direction: column;
		flex-direction: column;
		&.drop-ready {
			background: rgba(27, 160, 224, 0.58);
			border: 6px solid transparent;
		}

		> div:not(.drop-overlay) {
			margin: 8px 0 8px 0;
		}

		.drop-icon {
			font-size: 60px;
		}

		.drop-description {
			font-size: 23px;
		}

		.upload-progress {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 48px;
			text-align: center;
		}

		.drop-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 280px;
			height: 280px;
			background: rgba(0, 0, 0, 0);
		}
	}
}
