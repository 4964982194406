@value gap, max-detail-width, detail--full, detail-name from '../../details-list/details-list.css';

.company-lead {
    margin-bottom: gap;
}

.company-lead > a {
    display: flex;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    /* Needs a set width for text truncation */
    width: calc(max-detail-width + 24px);
}

.company-lead > a:hover,
.company-lead > a:focus {
    background-color: var(--off-white);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
}

.lead-content {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: space-between;
}

.lead-content strong {
    color: var(--grey-dk);
}

.avatar-wrapper > :first-child {
    margin-right: 0;
}
