.create-rules-container {
    background-color: var(--off-white);
    height: 100%;
}

.create-rules-container > button {
    margin-bottom: 8px;
}

.add-create-rule-container {
    width: 100%;
    height: 61px; /* matches height of start trigger card */
}
