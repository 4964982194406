.bookmark--small {
    position: absolute;
    top: -8px;
    left: 24px;
}

.bookmark--expand {
    position: absolute;
    top: -1px;
    left: 24px;
    animation: expand 500ms;
}

.bookmark--collapse {
    position: absolute;
    top: -8px;
    left: 24px;
    animation: collapse 500ms;
}

@keyframes expand {
    from {
        top: -8px;
    }

    to {
        top: -1px;
    }
}

@keyframes collapse {
    from {
        top: -1px;
    }

    to {
        top: -8px;
    }
}
