.typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 24px;
    background-color: var(--off-white-dk);
    padding: 6px;
    border-radius: 8px;
}

/********************************************/
/* Typing dot stuff */
/********************************************/

@keyframes dot-blink-bounce {
    0%,
    80%,
    100% {
        opacity: 0;
        transform: translateY(0);
    }
    40% {
        opacity: 1;
        transform: translateY(-5px);
    }
}

.typing-dot {
    width: 6px;
    height: 6px;
    background-color: var(--grey-lt);
    border-radius: 50%;
    animation: dot-blink-bounce 1.2s infinite both;
}

.typing-dot--with-margin {
    margin-left: 4px;
}
