.pause-page {
    width: 685px;
    margin: 32px auto;
}

.body {
    align-items: flex-start;
}

.copy {
    margin-right: 32px;
}

.copy p {
    margin-bottom: 16px;
}

.body svg {
    flex-shrink: 0;
}

.buttons {
    margin-top: 24px;
}

.buttons > *:not(:last-child) {
    margin-right: 16px;
}
