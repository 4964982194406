.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    color: white;
    border-radius: 2px;
    margin-right: 8px;
    margin-bottom: 5px;
}

.container-small {
    composes: container;
    margin-bottom: 4px;
    margin-right: 4px;
}

.container-unclickable {
    composes: container;
    pointer-events: none;
}

.container-linkable {
    composes: container;
}

.container-users,
.container-teams {
    composes: container;
    background-color: var(--orange-dk);
}
.container-linkable.container-users:hover,
.container-linkable.container-teams:hover {
    background-color: var(--orange-dk);
}

.container-contacts {
    composes: container;
    background-color: var(--navy);
}

.container-linkable.container-contacts:hover {
    background-color: var(--navy-dk);
}

.container-accounts {
    composes: container;
    background-color: var(--plum);
}

.container-linkable.container-accounts:hover {
    background-color: var(--plum-dk);
}

.container-leads {
    composes: container;
    background-color: var(--green);
}

.container-linkable.container-leads:hover {
    background-color: var(--green-dk);
}

.container-light {
    composes: container;
    color: var(--grey-dk);
    background-color: var(--off-white-md);
}

.container-linkable.container-light:hover {
    background-color: var(--off-white-dk);
}

.container-dark,
.container-default {
    composes: container;
    background-color: var(--grey-lt);
}
.container-linkable.container-dark:hover .container-linkable.container-default:hover {
    background-color: var(--grey);
}

.container-colorName {
    composes: container;
    color: var(--grey-dk);
}

.entity-container {
    padding: 1px 8px 3px 8px;
    line-height: normal;
    font-size: var(--font-big);
    user-select: none;
}

.container-small .entity-container {
    padding: 2px 8px;
    font-size: var(--font-small);
}

.container button {
    padding: 0 8px 2px 2px;
}

.container button:hover svg,
.container button:focus svg {
    fill: #fff;
}

.container-light button svg,
.container-colorName button svg {
    fill: var(--grey);
}

.container-light button:hover svg,
.container-light button:focus svg,
.container-colorName button:hover svg,
.container-colorName button:focus svg {
    fill: var(--grey-dk);
}

.nameless {
    font-style: italic;
}

.container > div:last-child {
    padding-right: 8px;
}

.container-small > div:last-child {
    padding-right: 4px;
}

.sublabel {
    font-size: var(--font-tiny);
}
