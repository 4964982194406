.flash-banner {
    border-radius: 3px 3px 0 0;
    z-index: 3;
}

.error-retry-button {
    position: absolute;
    top: 15px;
    right: 15px;
}
