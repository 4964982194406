.add-reaction-button {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 3px;
    height: 27px;
}

.add-reaction-button > :first-child {
    display: flex;
    align-items: center;
    color: var(--grey-lt);
}

.add-reaction-button:hover {
    background-color: var(--off-white);
    cursor: pointer;
}

.add-reaction-button--active {
    composes: add-reaction-button;
}

.add-reaction-button:hover > :first-child,
.add-reaction-button--active > :first-child {
    color: var(--grey);
}

.reaction-selection-popover {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 17px;
}

.reaction-button {
    position: relative;
    width: 28px;
    height: 28px;
    margin: 2px;
    padding: 0;
}

.reaction-button > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.reaction-button:hover {
    cursor: pointer;
    font-size: 19px;
    transition: font-size 200ms ease-in-out;
}
