.container {
    display: flex;
    flex-direction: column;
}

.timespan-input {
    width: 50px;
    margin-right: 8px;
}

.timespan-input > input {
    text-align: right;
}

.date-picker {
    display: flex;
    flex-direction: row;
}

.selector-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
}

.due-from {
    padding-left: 14px;
    color: var(--grey-lt);
}
