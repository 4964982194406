.container {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: var(--font-small);
    margin-bottom: 8px;
}

.select-individuals {
    margin-top: 16px;
    max-height: 300px;
    overflow-y: auto;
}

@media screen and (max-height: 520px) {
    .select-individuals {
        max-height: 200px;
    }
}
