.upcoming-webcasts {
    width: 400px;
}

.header {
    padding-bottom: 8px;
    width: 400px;
    position: fixed;
    background-color: var(--white);
    z-index: 1;
}

.list {
    padding-top: 64px; /* position under header */
}
