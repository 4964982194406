.container {
    display: flex;
    position: relative;
    flex-direction: row;
    padding: 5px 15px;
    height: 44px;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
}

.list-item-funnel-row:nth-child(even) .container {
    background-color: var(--zebra-stripe);
}

.list-item-funnel-row .container:hover {
    background-color: var(--color-list-hovered);
}

.name,
.count,
.value {
    font-size: var(--font-normal);
}

.name {
    flex: 2;
    color: var(--grey-dk);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.count {
    display: flex;
    flex: 1;
    color: var(--grey-lt);
    justify-content: flex-end;
}

.value {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.funnel-bar-container {
    position: absolute;
    width: 100%;
    z-index: 1;
    bottom: 0;
    left: 0;
}
