.view-tab {
    margin: 0 0 0 10px;
    padding-left: 6px;
}

.view-tab:hover {
    border-bottom: 2px solid #ebe8e8;
}

.view-tab:hover .label {
    color: var(--grey-dk);
}

.view-tab a:focus-visible {
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}

.view-tab-active {
    composes: view-tab;
    color: black;
    border-bottom: 2px solid #ebe8e8;
}

.icon {
    margin-top: 9px;
}

.label {
    padding: 9px 8px 8px 7px;
}

.view-tab .label {
    color: var(--blue);
}

.view-tab-active .label {
    color: black;
    font-weight: 500;
}
