@value cell-width: 300px;
@value cell-user-width: 250px;

.container {
    width: 100%;
    height: 100%;
}

.grid-container {
    height: 100%;
    width: 100%;
}

.main-grid-container {
    width: 100%;
}

.header {
    height: 34px;
    width: cell-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    font-weight: 500;
    color: var(--grey-dk);
    padding: 8px 16px;
    box-sizing: border-box;
    border-right: 1px solid var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
}

.static-header-row {
    position: fixed;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.static-header-activities {
    display: flex;
    position: absolute;
}

.activity-type {
    composes: header;
    cursor: pointer;
    user-select: none;
}
.activity-type:hover,
.activity-type:focus {
    background-color: var(--off-white);
}

.avatar {
    margin-right: 10px;
    flex-shrink: 0;
}

.username {
    font-size: var(--font-normal);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.title {
    composes: header;
    font-weight: 500;
    border-top: none;
}

.cell {
    padding: 10px 16px 12px 16px; /*purposefully adding more at the top, the quota bars are bottom-heavy */
    box-sizing: border-box;
    height: 100%;
    width: cell-width;
    display: flex;
    justify-content: space-between;
    border-right: 1px solid var(--off-white-dk);
    background-color: white;
}

.cell-table {
    composes: cell;
    padding-right: 24px; /* extra padding for the star, just looks better */
}

.cell-table-striped {
    composes: cell-table;
    background-color: var(--zebra-stripe);
}

.cell-totals {
    composes: cell-table;
    border-right-color: var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
}

.user {
    composes: cell;
    align-items: center;
    justify-content: flex-start;
    color: var(--grey-dk);
}

.user-striped {
    composes: user;
    background-color: var(--zebra-stripe);
}

.quota-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.quota-cell {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
}

.quota-cell-top {
    display: flex;
    justify-content: space-between;
}

.quota-percent {
    color: var(--grey-dk);
    font-size: var(--font-normal);
}

.quota-raw {
    color: var(--grey-dk);
    font-size: var(--font-normal);
}

.quota-totals-cell {
    composes: quota-cell;
}

.quota-totals-cell .quota-percent {
    color: var(--grey-dk);
}

.quota-totals-cell .quota-raw {
    color: var(--grey-dk);
}

.quota-cell-previous {
    composes: quota-cell;
    opacity: 0.65;
}

.quota-totals-cell-previous {
    composes: quota-totals-cell;
    opacity: 0.65;
}

.delta-container {
    display: flex;
    align-items: center;
}

.cell-table .delta-container {
    color: var(--grey-dk);
}
