.setup-special-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 64px;
}

.setup-special-state svg,
.setup-special-state img {
    margin-top: 32px;
}

.setup-special-state img {
    width: 100px;
}
