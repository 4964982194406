.popover-activity {
	.activity-quickcreate {
		.action-submit { display: none; }
	}
}

.sidebar-activities {
	.sidebar-activity-overdue, 
	.sidebar-activity-overdue .box-meta {
		color: @rose-dk;
	}
	
	.sidebar-activity {
		.icon {
			float: right;
			margin-top: 6px;
		}
		
		.relationship { 
			display: none;
			float: right;
			font-size: 10px;
		}
		
		&.state-flagged {
			.icon {
				#icon > .activity.size-small;
				#icon > .activity.flag-red;
			}
		}
	}
	
	.box-header .icon { #icon > .box-header-activities; }
	
	.box-schedule {
		h3 {
			font-size: @small;
			margin: -6px -10px;
			padding: 6px 8px;
			background: @off-white-md;
		}
	}
}

.activity-quickcreate {
	overflow: hidden;
	position: relative;
	font-size: 12px;
	
	input.name,
	.placeholder_wrapper {
		width: 98%;
	}
	select {
		width: 100%;
	}
	div.type-date {
		width: 196px * 0.60; // 40%
		float: left;
	}
	.date, .time { height: 28px; clear: both; }
	.time {
		height: 36px;
		margin-top: 4px;
		
		div.type-time {
			width: 196px * 0.5;
			float: left;
			height: 18px;
			input { width: 60px; }
		}
	}
	
	.whom { margin-top: 8px }
	
	.action-submit {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	label {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		margin-top: 6px;
		display: block;
		clear: both;
		&.name { margin-top: 4px; }
	}
	
	.action-flag {
		cursor: pointer;
		margin-top: 8px;
		float: left;
		clear: left;
		font-size: @small;
		font-weight: 500;
		color: @grey-lt;

		div.icon {
			margin: -4px 0;
			#util > .inline-block;
			
			#icon > .activity.size-small;
			#icon > .activity.flag-dim;
		}
	}
	
	&.state-flagged {
		.action-flag {
			color: black;
			.icon { #icon > .activity.flag-red; }
		}
	}
}
