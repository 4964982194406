.lead-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 57px;
}

.lead-icon img {
    margin-top: -5px;
    width: 40px;
    height: 40px;
}

.number {
    font-size: 12px;
    text-align: center;
}
