.ui-bulk-email-modal {
	display: flex;
	flex-direction: column;
	height: 100%;

	.ui-bulk-email-preview {
		box-sizing: border-box;
		padding-top: 10px;
		float: left;
		width: 57%;
		height: 100%;
		overflow-y: auto;

		.placeholder-suggestion {
			color: @orange-dk;
		}

		.ui-bulk-email-modal__empty-selection {
			.ui-bulk-email-modal__empty-selection-title {
				font-size: 15px;
				font-style: italic;
				margin-bottom: 25px;
			}
			.ui-bulk-email-modal__empty-selection-subject-line {
				float: left;
				height: 17px;
				width: 50%;
				margin-bottom: 25px;
				background-color: @off-white-dk;
			}
			.ui-bulk-email-modal__empty-selection-body {
				#util > .clearfix();
				margin-bottom: 20px;

				.ui-bulk-email-modal__empty-selection-body-line {
					float: left;
					height: 15px;
					width: 70%;
					margin-bottom: 5px;
					background-color: @off-white-md;
				}
			}
		}

		.ui-bulk-email-selected-preview {
			.ui-bulk-email-selected-preview__preview {
				font-size: 15px;
				font-style: italic;
				margin-bottom: 25px;
			}
			.ui-bulk-email-selected-preview__subject {
				font-size: 17px;
				margin-bottom: 25px;
			}

			.ui-bulk-email-selected-preview__body {
				margin-bottom: 25px;
				padding-right: 20px;
				font-size: 15px;
				line-height: 22px;
			}
		}
	}

	.ui-bulk-email-modal__no-emails-configured {
		#util > .clearfix;
		text-align: center;
		width: 560px;
		margin: 100px auto 0 auto;
		padding: 0 50px;

		.ui-bulk-email-modal__no-emails-configured__frowny {
			float: left;
			margin-right: 15px;

			svg {
				height: 50px;
				width: 50px;
				fill: @orange;
			}
		}

		h1 {
			float: left;
			font-size: 23px;
			color: @grey-dk;
			font-weight: normal;
			margin-top: 15px;
		}

		.ui-bulk-email-modal__no-emails-configured__links {
			margin-top: 100px;
			display: block;
			font-size: 17px;
		}
	}
}
