.invoices > div:nth-child(even) {
    background-color: var(--zebra-stripe);
}

.invoices > div {
    margin: 0 -8px;
}

.table-container {
    height: calc(100% - 48px); /* Full height minus modal header */
    overflow: auto;
}
