.container {
    display: flex;
    flex-wrap: nowrap;
}

.active {
    color: var(--blue);
}

.inactive {
    color: var(--blue-bg);
}
