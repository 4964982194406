.success-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--green);
}

.error-icon {
    composes: success-icon;
    color: var(--rose);
}

.warning-icon {
    color: var(--yellow);
    margin-top: -5px;
}
