.email-composer-form {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.composer-fields {
    display: flex;
    flex-grow: 1;
    min-height: 0; /* For firefox */
    position: relative;
}

.email-form-section {
    border-bottom: 1px solid var(--off-white-md);
}
