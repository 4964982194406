.ui-btn:not(.ui-btn--icon) {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	min-width: 60px;
	margin-right: 4px;
	padding: 4px 10px;
	border-radius: 3px;
	background-color: @off-white-md;
	color: @grey;
	font-size: @small;
	font-weight: 400;
	text-align: center;
	outline: none;
	user-select: none;

	&:focus,
	&:hover,
	&.ui-btn--active {
		background-color: @off-white-dk;
		color: @grey-dk;
	}

	&.ui-btn--grey {
		background-color: @off-white-md;
		color: @grey;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @off-white-dk;
			color: @grey-dk;
		}
	}

	&.ui-btn--grey-lt {
		background-color: @off-white-md;
		color: @grey;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @off-white-md;
			color: @grey-dk;
		}
	}

	&.ui-btn--grey-dk {
		background-color: @grey-lt;
		color: white;
		border-color: transparent;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @grey-dk;
			color: white;
		}
	}

	&.ui-btn--blue {
		background-color: @blue;
		color: white;
		border-color: transparent;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @blue-dk;
		}
	}

	&.ui-btn--blue-lt {
		background-color: @blue-bg;
		color: @blue;
		border-color: @blue;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @blue;
			color: white;
		}
	}

	&.ui-btn--green {
		background-color: @green;
		color: white;
		border-color: transparent;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @green-dk;
		}
	}

	&.ui-btn--red {
		background-color: @rose;
		color: white;
		border-color: transparent;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @rose-dk;
		}
	}

	&.ui-btn--purple {
		background-color: @info;
		color: @plum-lt;
		border-color: transparent;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @plum-dk;
			color: white;
		}
	}

	&.ui-btn--warn {
		background-color: @rose;
		color: white;
		border-color: transparent;
		&:focus,
		&:hover,
		&.ui-btn--active {
			background-color: @rose-dk;
		}
	}

	&.ui-btn--pill {
		padding-left: 18px;
		padding-right: 18px;
		border-radius: 20px;
	}

	&.ui-btn--block {
		display: block;
		min-width: 210px;
		margin: 15px auto;
	}

	&.ui-btn--big {
		padding: 8px 12px;
		font-size: 15px;

		&.ui-btn--link {
			padding: 0;
			> a {
				display: inline-block;
				padding: 8px 12px;
			}
		}
	}

	&.ui-btn--xlarge {
		padding: 14px 40px;
		font-size: @big;
	}

	&.ui-btn--small {
		padding: 4px 10px 3px 10px;
		.ui-btn__close {
			line-height: 24px;
		}

		&.ui-btn--link {
			padding: 0;
			> a {
				display: inline-block;
				padding: 4px 10px 3px 10px;
			}
		}
	}

	&.ui-btn--link {
		padding: 0;
		> a {
			display: inline-block;
			padding: 5px 10px 4px 10px;
		}
	}

	&.ui-btn--no-margin {
		margin-right: 0;
	}

	&.ui-btn--stacked {
		margin-bottom: 4px;
	}

	.ui-btn__close {
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 25px;
		line-height: 26px;
		text-align: center;
		border-radius: 3px 0 0 3px;
	}

	&[disabled]:not(.ui-btn--type-saving) {
		color: white;
		background-color: @off-white-md;
		cursor: default;
		&:focus,
		&:hover {
			color: white;
			background-color: @off-white-md;
		}
	}
}

.ui-btn:not(.ui-btn--icon).ui-btn--type-saving {
	&.ui-btn--state-is-saving {
		padding-right: 30px;
		#util.animation(250ms, @customEasing);

		.ui-btn__loading-svg {
			transform: scale(0.5);
			position: absolute;
			top: -8px;
			right: -5px;
		}

		&.ui-btn--big {
			padding-right: 35px;
			.ui-btn__loading-svg {
				top: -4px;
				right: -2px;
			}
		}

		&.ui-btn--xlarge {
			padding-right: 35px;
			.ui-btn__loading-svg {
				transform: scale(0.75);
				top: 3px;
				right: 3px;
			}
		}

		&.ui-btn--green,
		&.ui-btn--blue,
		&.ui-btn--grey-dk {
			.ui-btn__loading-svg svg {
				fill: white;
			}
		}
	}

	&[disabled]:not(.ui-btn--state-is-saving),
	&.ui-btn--state-is-disabled:not(.ui-btn--state-is-saving) {
		color: white;
		background-color: @off-white-md;
		cursor: default;
		&:focus,
		&:hover {
			color: white;
			background-color: @off-white-md;
		}
	}
}

// .ui-btn--block override: for anchor tags wanting to use the --block modifier
a.ui-btn:not(.ui-btn--icon).ui-btn--block {
	width: auto;
	display: inline-block;
}

.ui-btn {
	&.ui-btn:not(.ui-btn__checkmark-submit, .ui-btn--state-is-saving):disabled {
		cursor: default;
		color: @off-white-dk;
		background-color: @off-white-md;

		&:hover {
			cursor: default;
			color: @off-white-dk;
			background-color: @off-white-md;
		}
	}

	&.ui-btn__checkmark-submit {
		&.ui-btn:disabled {
			svg {
				fill: @off-white-dk;
			}
		}
	}
}

.ui-btn.ui-btn--icon {
	user-select: none;
	padding: 5px 10px; //#clicktarget

	&.ui-btn--icon-back {
		color: @off-white-dk;
		&:focus,
		&:hover {
			color: darken(@off-white-dk, 10%);
		}
	}

	&.ui-btn--icon-slim {
		padding: 0;
	}
}

.ui-btn-group {
	display: inline-block;
	.ui-btn {
		margin: 0 2px 0 0;
		border-radius: 0;
		&:first-of-type {
			border-radius: 3px 0 0 3px;
		}
		&:last-of-type {
			margin-right: 0;
			border-radius: 0 3px 3px 0;
			border-right-width: 0;
		}
	}
	&.ui-btn-group--pill .ui-btn {
		&:first-of-type {
			border-radius: 20px 0 0 20px;
			padding-left: 14px;
		}
		&:last-of-type {
			border-radius: 0 20px 20px 0;
			padding-right: 14px;
		}
	}
	&.ui-btn-group--pill .ui-btn--big {
		&:first-of-type {
			padding-left: 17px;
		}
		&:last-of-type {
			padding-right: 17px;
		}
	}
}

[role='button'] {
	outline: none;
}
