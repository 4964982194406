.save-icon {
    margin-left: 8px;
    /* Keeps the save icon from making the button bigger */
    height: 0;
}

/*
    The Button component adds the '+' before the contents within the button - so in this case
    we need to add the '+' from within the button content that is wrapped with the save icon.
*/
.text-add > span:before {
    content: '+ ';
}
