.large-icon {
    width: 260px;
}

.art {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80%;
    margin-left: 10%;
}

.art svg {
    width: 100%;
    height: 256px;
}
