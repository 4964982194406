.view-submission-data {
    padding: 0;
    width: 100%;
    font-size: var(--font-normal);
}

/* Form fields in the datalist */
.view-submission-data > *:not(:last-child) {
    margin-bottom: 24px;
}

/* Field label */
.view-submission-data dt {
    margin-bottom: 8px;
    color: var(--grey);
}

/* Field isRequired star */
.view-submission-data dt span {
    margin-left: 4px;
    color: var(--rose);
}

/* Field value */
.view-submission-data dd {
    margin: 0;
    white-space: pre-wrap;
    color: var(--grey-dk);
}

/* Field no value submitted */
.view-submission-data dd span {
    font-style: italic;
    color: var(--grey-lt);
}
