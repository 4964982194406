.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email-container {
    border: 1px solid var(--off-white-dk);
    margin-bottom: 32px;
}
