.button-content {
    display: flex;
    align-items: center;
    line-height: 21px; /* Line height needed for icon and text to align */
}

.icon {
    margin-right: 16px;
}

.icon > * {
    position: relative;
    top: 2px;
}
