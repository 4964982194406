.container {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
}

.left-container {
    width: 100%;
    padding: 16px 0 16px 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    min-height: 736px;
}

.right-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-basis: auto;
    min-width: 200px;
    max-width: 600px;
}

.empty-state-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: var(--off-white);
}

.title {
    font-size: var(--font-normal);
    color: var(--grey-dk);
    line-height: 21px;
    padding-right: 16px;
}
