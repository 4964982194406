.quill-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 100%;
}

.editor {
    height: 100%;
    min-height: 0; /* For firefox smh */
    overflow-y: auto;
}

.editor--bottom-padding-for-toolbar {
    padding-bottom: 32px;
}

/* Allow quill editor to display overflow, used for mentions dropdown */
.editor--overflow-visible {
    overflow: visible;
}

.editor--overflow-hidden {
    overflow: hidden;
}

.editor--text-align-center :global(.ql-editor) {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

/* Allow quill editor to grow to full height */
.editor--full-height :global(.quill) {
    height: 100%;
}

/* Allow quill editor to display overflow, used for mentions dropdown */
.editor--overflow-visible :global(.quill) {
    overflow: visible;
}

.quill-container :global(.quill .ql-container) {
    /* Their default font is Helvetica, we want to use IBM Plex Sans. */
    font-family: IBM Plex Sans;
    border: none;
    /* Darker text is much easier on the eyes */
    color: var(--grey-dk);
    font-size: var(--font-small);
    width: 100%;
    height: 100%;
}

.quill-container :global(.ql-editor) {
    /*
     *  This unifies with text boxes on the left & gives us a bit extra on the right
     */
    padding: 8px 16px 8px 8px;

    /* Sane default for mega screens */
    max-width: 1200px;
    height: 100%;
    width: 100%;
}

/* Renders the container, but without padding */
.quill-container--no-padding {
    composes: quill-container;
}

.quill-container--no-padding :global(.ql-editor) {
    padding: 0;
}

.quill-container--wide-padding :global(.ql-editor) {
    padding: 16px;
}

.quill-container--with-transcription :global(.ql-editor) {
    padding-bottom: 48px; /* Give space for the floating transcription button */
}

.quill-container--wide-padding :global(.ql-editor.ql-blank::before) {
    padding: 0 2px; /* puts the placeholder next to the cursor, not under it */
}

.quill-container--larger-text :global(.ql-editor) {
    font-size: 15px;
}

/* Placeholder styles */
.quill-container :global(.ql-editor.ql-blank::before) {
    font-style: normal;
    font-size: var(--font-small);
    color: var(--placeholder);
}

.quill-container--no-placeholder-spacing :global(.ql-editor.ql-blank::before) {
    left: 0;
    right: 0;
}

.quill-container--larger-text :global(.ql-editor.ql-blank::before) {
    font-size: 15px;
}

/* Override toolbar icon stroke/fill */
.quill-container :global(.quill .ql-snow .ql-stroke) {
    stroke: var(--grey);
}

.quill-container :global(.quill .ql-snow .ql-fill) {
    fill: var(--grey);
}

.quill-container :global(.quill .ql-tooltip.ql-flip) {
    /* We need to translate our link tooltip popup so
    it's not behind our sidebar */
    transform: translate3d(78px, -10px, 0);
}

.notification {
    margin: 16px 8px 8px 8px;
    user-select: none;
    background-color: var(--off-white);
}

.reply-notification {
    composes: notification;
    padding: 8px 16px;
    border-left: 9px solid var(--off-white-md);
}

.reply-notification p {
    max-width: 85%;
    opacity: 0.6;
}

.reply-notification p:first-child {
    margin-bottom: 15px;
}

.signature-notification {
    composes: notification;
    background-color: #fff;
    padding: 8px 16px;
    border: 2px dashed var(--off-white-dk);
    display: flex;
    border-radius: 3px;
    flex-direction: row;
    justify-content: space-between;
}

.signature-notification a {
    flex-shrink: 0;
    align-self: center;
}

.signature-message {
    padding-right: 16px;
    max-width: 100%;
}

.signature-message img {
    max-width: 100%;
}

/* Error styles */
.quill-container--error {
    composes: quill-container;
}

.quill-container--error :global(.ql-editor.ql-blank::before) {
    color: var(--rose);
}

/*
TOOLBAR POSITION TOP
 */
.quill-container--toolbar-top {
    composes: quill-container;
}

.quill-container--toolbar-top :global(.ql-editor) {
    height: calc(100% - 40px);
}

.quill-container--toolbar-top.quill-container--toolbar-floating-box :global(.ql-editor) {
    height: calc(100% - 56px);
}

/*
TOOLBAR POSITION BOTTOM
 */
.quill-container--toolbar-bottom {
    composes: quill-container;
}

.quill-container--toolbar-bottom :global(.ql-editor) {
    height: 100%;
}

.quill-container--toolbar-bottom :global(.quill .ql-container) {
    /* Toolbar is absolutely positioned, so we don't want
    the text allowed under it. 40px toolbar, 8px padding top/bottom */
    padding-bottom: 56px;
}

.quill-container--toolbar-bottom.quill-container--toolbar-grey-bar :global(.quill .ql-container) {
    /* Toolbar is absolutely positioned, so we don't want
    the text allowed under it. 40px toolbar, no padding */
    padding-bottom: 40px;
}

/* Placeholder blot styles */
.quill-container :global(.ql-placeholder-blot) {
    background-color: var(--blue);
    color: white;
    border-radius: 3px;
}

.quill-container :global(.ql-placeholder-blot-custom) {
    background-color: var(--report-orange);
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.quill-container :global(.ql-placeholder-blot) > span,
.quill-container :global(.ql-placeholder-blot-custom) > span {
    padding: 0 4px;
}

.quill-container :global(.ql-placeholder-blot-custom-placeholder) {
    background-color: rgba(234, 144, 51, 0.5);
    color: white;
    padding: 0 4px;
    border-radius: 3px;
}

.quill-container :global(.quill .ql-container blockquote) {
    margin: 0;
}

.quill-container :global(.ql-mention-list-container) {
    border-radius: 3px;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
    width: 100%; /* full width in the dashboard timeline */
    max-width: 500px; /* but also a sane max width */
    /* the below styles override the position set by quill-mention which is sometimes just wrong */
    position: absolute;
    left: 8px !important;
    top: unset !important;
}

.quill-container :global(.ql-mention-list-item) {
    padding: 4px 16px;
}

.quill-container :global(.ql-mention-list-item.selected) {
    background-color: var(--blue-lt);
}

/* Placeholder blot styles */
.quill-container :global(.ql-mention-blot) {
    color: var(--grey-dk);
    border-radius: 3px;
    padding: 2px 4px;
}

.quill-container :global(.ql-mention-blot)[data-entity-name='Users'],
.quill-container :global(.ql-mention-blot)[data-entity-name='Teams'] {
    background-color: var(--orange-xlt);
}

.quill-container :global(.ql-mention-blot)[data-entity-name='Contacts'] {
    background-color: var(--blue-xlt);
}

.quill-container :global(.ql-mention-blot)[data-entity-name='Accounts'] {
    background-color: var(--plum-xlt);
}

.quill-container :global(.ql-mention-blot)[data-entity-name='Leads'] {
    background-color: var(--green-xlt);
}

/* Loading state */
/* Mention empty state and error state styles */
.quill-container :global(.ql-mention-loading),
.quill-container :global(.ql-mention-list-item.disabled) {
    padding: 32px 64px;
}

/* Mention blot styles */
.quill-container :global(.ql-mention-empty-state) {
    color: var(--grey-dk);
    border-radius: 3px;
    padding: 2px 4px;
}

.quill-container :global(.ql-mention-entity) {
    display: flex;
    align-items: center;
    height: 55px;
}

.quill-container :global(.ql-mention-entity-avatar) {
    position: relative;
}

.quill-container :global(.ql-mention-entity-avatar) > * {
    position: absolute;
    top: -17px; /* centers item vertically in row */
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    font-size: 15px;
    line-height: 32px;
    text-transform: uppercase;
    color: white;
    cursor: default;
}

.quill-container :global(.ql-mention-entity-info) {
    position: relative;
    left: 48px;
    display: flex;
    flex-direction: column;
}

.quill-container :global(.ql-mention-entity-name) {
    font-size: 15px;
    margin-bottom: 2px;
    color: var(--grey-dk);
}

.quill-container :global(.ql-mention-entity-detail) {
    font-size: 13px;
    color: var(--grey-lt);
}
