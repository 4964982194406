.ie-deprecation {
    position: absolute;
    margin: 16px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.ie-deprecation a {
    text-decoration: underline;
    cursor: pointer;
    padding-left: 4px;
}

.ie-deprecation a:hover,
.ie-deprecation a:focus {
    color: var(--off-white-dk);
}
