.option-title {
    font-size: var(--font-normal);
    margin-bottom: 4px;
}

.option-subtitle {
    font-size: var(--font-small);
    display: flex;
    align-items: center;
}

.option-subtitle > *:first-child {
    margin-right: 4px;
}

.option--disabled {
    opacity: 0.6;
}

.manage-templates {
    font-size: var(--font-normal);
}

.manage-templates a {
    cursor: default;
}
