.container {
    margin-bottom: 80px;
    min-height: fit-content;
}

.right-side-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 32px;
    align-items: flex-end;
}

.marketing-container {
    background-color: var(--blue-bg-lt);
    padding: 16px;
}
