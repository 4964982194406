.email-row-item {
    display: flex;
    padding: 0 16px;
    background-color: #fff;
    cursor: pointer;
    font-size: var(--font-normal);
    border-bottom: 1px solid var(--off-white);
}

.email-row-item--expanded {
    composes: email-row-item;
    flex-direction: column;
    cursor: default;
    box-shadow: 0 1px 8px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: none;
    padding-left: 32px;
    padding-top: 16px;
    box-sizing: border-box;
    min-height: 50px;

    /* Inset the email*/
    margin-left: 4px;
    margin-right: 4px;

    /* Push next email down a bit, so shadow has room to breath */
    margin-bottom: 8px;

    /* Overlap previous item's border */
    margin-top: -1px;

    /* Reduce right padding to 12px from 16px, so right edge is consisent */
    padding-right: 12px;
}

.email-row-item--expanded img {
    max-width: 70%;
}

.email-row-item--hidden {
    composes: email-row-item;
    background-color: var(--off-white);
    opacity: 0.9;
}

.email-row-item--collapsed {
    composes: email-row-item;
    align-items: center;
    cursor: pointer;
}

.email-row-item--collapsed:hover {
    background-color: var(--color-list-hovered);
}

.email-row-item--first-of-date {
    composes: email-row-item;
    border-top: 1px solid var(--off-white);
}

.email-row-item--first-of-date.email-row-item--expanded {
    border-top: none;
}

.header-left,
.header-right {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 4px 0;
}

.expanded-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.expanded-content-container {
    display: flex;
    flex-direction: column;
}

.to-container {
    width: 144px;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 32px;
    min-width: 144px;
}

.to-container--expanded {
    composes: to-container;
    width: auto;
}

.expanded-content-container .subject-container {
    margin: 0 0 16px 0;
    font-size: 17px;
    font-weight: normal;
}

.expanded-content-container .body-container {
    max-width: 600px;
    line-height: 19px;
    margin-bottom: 32px;
    overflow-wrap: break-word;
}

.body-container ul {
    list-style-type: disc;
    padding-left: 1.5em;
}

.email-contents-container {
    /* Ensures the email contents section shrinks around everything else */
    flex: 1;
    min-width: 0;
}

.email-contents {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 16px;
}

.email-row-item--collapsed .email-content-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.email-row-item--expanded .email-content-text {
    display: flex;
    flex-direction: column;
    color: var(--grey-dk);
}

.email-row-item--expanded .email-content-text a {
    color: var(--blue);
}

.email-row-item--expanded .email-content-text a:hover,
.email-row-item--expanded .email-content-text a:focus {
    color: var(--blue-dk);
    text-decoration: underline;
}

.subject-container {
    color: var(--grey-dk);
    margin-right: 8px;
    font-weight: 500;
}

.tracked-view-count {
    font-size: var(--font-small);
}

.inline-icon-container > svg:first-child {
    height: 15px;
}

.inline-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.inline-icon-container:hover,
.inline-icon-container:focus {
    /* off-white-dk with opacity of 50% */
    background-color: hsla(0, 0%, 88%, 0.5);
    color: var(--grey-dk);
}

.status-badge-collapsed {
    display: flex;
    align-items: center;
    min-width: 112px;
    margin-right: 16px;
}

.status-badge-expanded {
    margin-right: 8px;
    display: flex;
    flex-shrink: 0;
}

.timestamp-container {
    text-align: right;
    padding-right: 16px;
    flex-shrink: 0;
    width: 67px; /* All same width, prevents 4-digits from wrapping */
}

.inline-icon {
    display: flex;
    margin-left: 16px;
    margin-right: 8px;
}

.details-link {
    margin-bottom: 16px;
}

.file-list {
    margin: 0 -16px 32px -16px;
}

.recipients-list {
    display: flex;
    flex-direction: column;
    margin: 16px 0 32px 0;
}

.sender-row,
.recipients-row {
    display: flex;
}

.recipients-list > *:not(:last-child) {
    margin-bottom: 8px;
}

.recipients-row {
    flex-wrap: wrap;
}

.subject-header {
    font-size: var(--font-big);
    margin-right: 16px;
    flex: 1;
    min-width: 0;
    color: var(--grey-dk);
    font-weight: normal;
}

.email-row-item blockquote {
    border-left: 4px solid var(--off-white-dk);
    padding-left: 16px;
    margin: 0;
}
