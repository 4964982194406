.content-container {
    overflow-x: hidden;
    position: relative;
}

.content-container::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.001), #fff);
}
