.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label-container {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
}

.required-label {
    margin-bottom: 10px;
    color: var(--grey-lt);
    position: relative;
    font-weight: normal;
}

.required-label--invalid {
    composes: required-label;
    color: var(--rose);
}

.required-label:before,
.required-label--invalid:before {
    content: '*';
    position: absolute;
    left: -7px;
    color: var(--rose);
}

.list-container {
    border: solid 1px var(--off-white-md);
}

.list-container ul {
    height: 219px; /* this accounts for header and error container */
}
