.visitor-content {
    height: 100%;
}

.visitor-details-container {
    padding: 24px 24px 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
