.container {
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin-bottom: 64px;
}

.image {
    width: 200px;
}

.header-info {
    flex-grow: 1;
    width: 600px;
    padding: 16px;
    box-sizing: border-box;
}
