.notification {
    padding: 16px;
    background-color: var(--white);
    display: flex;
    border-bottom: 1px solid var(--off-white-md);
}

.notification:hover {
    background-color: var(--color-list-hovered);
}

.notification--unread {
    composes: notification;
    background-color: var(--color-list-selected);
}

.first {
    border-top: 1px solid var(--off-white-md);
}

.content {
    max-lines: 2;
    padding-right: 16px;
}

.notification-container {
    position: relative;
    display: block;
}

.notification-container:hover {
    cursor: pointer;
}

.preview {
    max-lines: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.createdTime {
    font-size: 11px;
    color: var(--grey-lt);
    max-lines: 1;
}

.profile-picture {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: var(--grey);
}

.profile-picture-container {
    margin-right: 12px;
    min-width: 30px;
}

.unread-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    padding: 16px;
}

.unread-indicator {
    border: 1px solid var(--blue);
    border-radius: 4px;
    width: 4px;
    height: 4px;
    background-color: var(--blue);
}

.notification-content-container {
    overflow: hidden;
}

.notification-content-container :global(.type-leads) {
    color: var(--lead);
    font-weight: 500;
}

.notification-content-container :global(.type-users) {
    color: var(--orange-dk);
    font-weight: 500;
}

.notification-content-container :global(.type-contacts) {
    color: var(--navy-dk);
    font-weight: 500;
}

.notification-content-container :global(.type-accounts) {
    color: var(--plum);
    font-weight: 500;
}

.notification-content-container :global(.type-activities) {
    color: var(--grey-dk);
    font-weight: 500;
}
