/* Makes the modal fixed size so that the modal itself does not scroll */
.select-marketing-outreach-list-page {
    height: 100%;
}

.search-container {
    display: flex;
    margin-bottom: 8px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

/* Makes the list container scrollable within itself */
.outreach-list-container {
    /* Offset height with searchbar + footer */
    height: calc(100% - 126px);
}

.select-marketing-outreach-list-page > :last-child {
    padding: 16px 0 32px 0;
    height: unset;
}
