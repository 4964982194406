.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    margin-top: 100px;
}

.icon-trash {
    fill: var(--icon-secondary);
}

.empty-text {
    font-weight: 500;
    font-size: 19px;
    color: var(--grey);
    margin-top: 24px;
}
