.container {
    composes: border from '../border/border.css';
    display: flex;
    align-items: center;
    cursor: pointer;
}

.color-picker-container {
    position: absolute;
    z-index: 1;
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--form-border);
    box-shadow: rgb(0, 0, 0, 0.15) 0 0 16px 1px;
}

/* This selects the color-picker wrapping div to remove the styles that are
added by the component */
.color-picker-container > :first-child {
    background: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
}

.preview-swatch {
    width: 16px;
    height: 16px;
    margin: 8px;
    border: 1px solid var(--form-border);
    border-radius: 2px;
}

.transparent-button {
    width: calc(100% - 16px);
    padding: 4px;
    border-radius: 6px;
    border: 1px solid var(--form-border);
    margin: 4px 8px 8px;
    color: var(--grey-lt);
}

.transparent-button:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
