.empty-state-container {
    padding: 32px;
}

.email-health-popover {
    width: 416px;
}

.body {
    padding: 32px 32px 16px 32px;
}
