:global(#swoop-arrow) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10001; /* Above modal overlay */
    pointer-events: none; /* Let clicks pass through */
}

:global(#arrow-path) {
    animation: dash 500ms ease-in 1;
    animation-fill-mode: forwards;
}

:global(#arrowhead) {
    stroke-dasharray: 95;
    stroke-dashoffset: 95;
    /* delay matches arrow-path animation duration */
    animation: dash 200ms ease-in 500ms 1;
    animation-fill-mode: forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
