.cta-container {
    padding: 32px;
    width: 320px;
    display: flex;
    flex-direction: column;
}

.cta-container > * + * {
    margin-top: 16px;
}

.logo-title {
    display: flex;
    align-items: center;
}

.logo-title svg {
    margin-right: 8px;
    fill: var(--grey-dk);
    color: var(--grey-dk);
    flex-shrink: 0;
}

.form-container {
    height: 90px;
}
