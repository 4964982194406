.bulleted-list {
    margin-bottom: 32px;
}

.bulleted-list--small {
    composes: bulleted-list;
    font-size: var(--font-small);
    margin-bottom: 0;
}

.bulleted-list--normal {
    composes: bulleted-list;
    font-size: var(--font-normal);
}

.bulleted-list--big {
    composes: bulleted-list;
    font-size: var(--font-big);
}

.list-item {
    position: relative;
    display: block;
    padding: 0 6px 0 12px;
    margin: 0 0 16px 4px; /* This aligns the bullets with the left-edge */
    color: var(--grey);
    line-height: 1.4;
}

.list-item--normal {
    composes: list-item;
}

.list-item--big {
    composes: list-item;
}

.list-item--small {
    composes: list-item;
    margin-bottom: 6px;
}

.list-item--less-spacing {
    composes: list-item;
    margin-bottom: 4px;
}

.list-item:last-child {
    margin-bottom: 0;
}

.list-item:before {
    position: absolute;
    left: -4px;
    top: -2px;
    content: '•';
    font-size: 18px;
    color: var(--orange);
}

.list-item--small:before {
    top: -4px;
}

.list-item--grey:before {
    color: var(--grey);
}

.list-item--errors {
    color: var(--color-error);
    margin-bottom: 0;
}

.list-item--errors:before {
    color: var(--color-error);
}
