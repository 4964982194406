.container {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--orange-dk);
    width: fit-content;
    padding: 4px;
}

.container:hover {
    background-color: var(--off-white);
    cursor: pointer;
    border-radius: 3px;
}
.form-body {
    width: 250px;
    overflow-y: auto;
}

.form-body ul {
    height: auto;
    max-height: 300px;
}
