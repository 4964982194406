.facet-picker .icon {
    margin-right: 4px;
}

.facet-picker .icon-label {
    display: inline-block;
}

/* Reduce width of segmented control in reports section of app */
@media (max-width: 1200px) {
    .facet-picker .icon {
        margin-right: 0;
    }

    .facet-picker .icon-label {
        display: none;
    }

    .facet-picker label {
        min-width: 22px !important;
    }
}
