@value thumbnailCardWidth: 300px;
@value thumbnailHeight: 196px;

.ready-for-sending-page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Have the footer of at the very bottom of the modal */
.ready-for-sending-page > :last-child {
    padding: 16px 0 32px 0;
    height: unset;
}

.dark-text {
    color: var(--grey-dk);
    font-weight: 500;
    margin-bottom: 4px;
}

.light-text {
    font-size: var(--font-small);
    color: var(--grey-lt);
}

.warning-text {
    font-size: var(--font-small);
    color: var(--color-warning);
}

.ready-for-sending-message-preview {
    display: flex;
    height: 384px;
    overflow: auto;
    padding: 32px;
    box-sizing: border-box;
    margin-top: 16px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

/* The message preview (left) side */
.ready-for-sending-message-preview > :first-child {
    flex: 0 50%;
    padding-right: 32px;
}

.message-thumbnail-container {
    position: relative;
    overflow: hidden;
    height: thumbnailHeight;
    width: thumbnailCardWidth;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

.drip-sequence-message-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    height: 70px;
    box-sizing: border-box;
}

/* Text within the card needs an explicit width for truncate property to work */
.drip-sequence-message-card > * {
    max-width: 300px;
}

.detail-section-item {
    font-size: var(--font-normal);
}

.detail-section-item:not(:last-child) {
    margin-bottom: 20px;
}

.detail-section-item > * {
    margin-bottom: 4px;
}

.footer-right-container {
    display: flex;
    align-items: center;
    /* The footer bar component adds a lot of padding to elements causing them to get cut off */
    margin-left: 0 !important;
}

.footer-right-container > :first-child:not(:only-child) {
    margin-right: 16px;
}

.error-banner-container {
    position: absolute;
    bottom: 75px;
    width: 100%;
}
