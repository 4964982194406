.show-more {
    color: var(--grey-lt);
}

.show-more--tokenized {
    composes: show-more;
    margin-left: 4px;
}

.truncated-list {
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.color-named-value {
    padding: 2px 4px;
    border-radius: 3px;
    margin-bottom: 2px;
}

.color-named-value:first-child {
    /* aligns with list padding */
    margin-top: 2px;
}
