.page-content-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 16px;
    overflow: auto;
}

.no-padding {
    padding: 0;
}
