.trigger-lead-field {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

.trigger-lead-field > *:not(:first-child) {
    margin-top: 8px;
}

.enum-option {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.enum-option > label:first-child {
    margin-top: 8px;
}

.enum-option > div:last-child {
    width: 100%;
}

.card-container {
    margin-top: 8px;
    padding-bottom: 16px;
}
