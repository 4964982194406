.container {
    position: absolute;
    display: flex;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 3px;
}

.container-selected {
    composes: container;
}

.quick-view {
    width: 2px;
    height: 3px;
}

.quick-view-active {
    composes: quick-view;
    background-color: var(--blue);
}

.container-selected .quick-view-active {
    background-color: #fff;
}
