.issue-list {
    margin-left: 40px;
}

.issue-list ul {
    margin-bottom: 16px;
}

.warning-line {
    font-size: var(--font-normal);
}

.warning-line a {
    margin-left: 4px;
}
