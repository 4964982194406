.report-chart {
    margin-top: 0;
    padding: 0 16px 32px 16px;
    height: 16px; /* needed for safari */
    min-height: 165px; /* needed for safari */
    background-color: var(--off-white);
}

.card-contents {
    position: relative;
}

.header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding: 16px;
}

.summary-bar {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    height: 32px;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
}

.summary-bar-section {
    height: 32px;
}

.summary-bar-section-wins {
    composes: summary-bar-section;
    background-color: var(--report-green);
}

.summary-bar-section-losses {
    composes: summary-bar-section;
    background-color: var(--report-red);
}

.summary-bar-section-other {
    composes: summary-bar-section;
    background-color: var(--off-white-dk);
    flex-grow: 1;
}

.stats {
    margin-top: 16px;
    width: 75%;
}
