.details-container {
    margin-top: 16px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    display: flex;
}

.details-container-left {
    padding: 32px;
    width: calc(50% - 64px);
}

.details-container-right {
    width: 50%;
    padding: 32px 0;
}
