.container {
    display: flex;
    height: 100%;
}

.left-container {
    width: 50%;
    background-color: var(--plum-bg);
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    flex-grow: 1;
    padding: 24px;
    justify-content: space-between;
}

.calendar-form-container {
    display: flex;
    margin: 16px 0;
}

.calendar-container {
    width: 50%;
    max-width: 350px;
    border: 1px solid var(--off-white-dk);
}

.calendar-details {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.form-container > * {
    flex-grow: 1;
    padding: 8px;
    margin-left: 8px;
    max-width: 300px;
}

.form-container > div:first-child {
    margin-bottom: 8px;
}

.calendar-details > button {
    margin-left: 16px;
    width: fit-content;
}

.date-container {
    border: 1px solid var(--form-border);
    border-radius: 3px;
    padding: 6px;
    height: 17px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    cursor: pointer;
}

.send-immediately-container {
    height: 48px;
}

.two-line {
    margin-top: 22px;
}
