.inline-button {
    display: inline-block;
    outline: none;
    user-select: none;
    cursor: pointer;
    color: var(--blue);
    padding: 0;
}

.inline-button:focus:not([disabled]),
.inline-button:hover:not([disabled]) {
    text-decoration: underline;
    color: var(--blue-dk);
}
