.status button {
    height: 31px;
}

.last-updated button {
    height: 31px;
    width: max-content;
}

.expanded-filter-checkbox {
    height: 31px;
    width: max-content;
    margin-right: 10px;
}

.expanded-filter-checkbox:last-child {
    margin-right: 0;
}

.expanded-filter-checkbox * {
    height: 100%;
}

.search {
    margin-left: 10px;
    min-width: 190px;
}

.text-items {
    display: flex;
    align-items: center;
}

.count-text {
    min-width: fit-content;
    margin-left: 10px;
    letter-spacing: 0.5px;
    color: var(--grey-lt);
}
