.container {
    padding: 32px 16px 0 16px;
}

.left {
    background-color: var(--plum-lt);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: fadeIn fadeDuration ease-in forwards;
}

.footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 32px);
}

.confirmation-content {
    width: 300px;
    height: 100px;
    padding: 32px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--grey-dk);
    font-size: var(--font-huge);
    line-height: 1.5;
    text-align: center;
}
