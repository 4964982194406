.stage-cell {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
}

.stage-cell > div:first-child {
    margin-right: 8px;
}

.stage-name {
    text-overflow: ellipsis;
    overflow: hidden;
}
