.pipeline-checkboxes {
    padding: 0 16px;
    overflow-y: auto;
    max-height: 456px;
}

@media (max-height: 900px) {
    .pipeline-checkboxes {
        max-height: 266px;
    }
}

.sidebar-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 200px;
}

.pipeline-bucket-content {
    display: flex;
    flex-direction: column;
    min-height: 100px;
}

.pipeline-bucket-content-center {
    display: flex;
    flex-direction: column;
    min-height: 240px;
}

.table-empty-section {
    padding: 48px 24px;
    text-align: center;
    border: 1px solid var(--off-white-dk);
    border-top: none;
    border-radius: 3px;
}
