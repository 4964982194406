.error {
    color: var(--color-error);
    display: inline-block;
}

.error > span {
    margin-left: 4px;
}

.warning {
    color: var(--color-warning);
}

.warning button {
    padding: 0 4px;
    color: var(--grey);
}
