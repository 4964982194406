.truncated-session-content,
.info-bar {
    padding: 0 8px;
}

.truncated-session-content > :not(:last-child) {
    margin-bottom: 8px;
}

.hidden-pages {
    border-left: 2px solid var(--off-white-dk);
    padding: 8px;
    color: var(--grey-lt);
    width: fit-content;
}

.info-bar {
    margin-top: 16px;
    color: var(--grey-lt);
}
