.preview-container {
    display: flex;
    width: 300px;
    height: 316px;
    flex-direction: column;
}

.preview-container :global(.ui-entity-info) {
    width: 160px;
}

.error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 274px; /* 306px dash height - 42px header height */
}
