.form-wrapper {
    margin: 8px 0;
    width: 100%;
}

.labels {
    min-height: 64px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 16px;
}

.buttons {
    margin-top: 27px;
    align-self: flex-end;
}
