@value transitionDuration: 300ms;

.pipeline {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 140%;
    margin-left: -20%;
    transition: margin-left 300ms ease;
}

.pipeline svg {
    width: 100%;
    height: 256px;
    transition: transform transitionDuration;
}

.stage {
    composes: pipeline;
}

.welcome {
    composes: pipeline;
    margin-left: 25%;
}

.automations {
    composes: pipeline;
}

/* Fade non-center stage elements */
.pipeline svg :global(#center-header) {
    transition: opacity transitionDuration;
}
.pipeline svg :global(.pipeline-only),
.pipeline.stage svg :global(#center-header) {
    opacity: 1;
    transition: opacity transitionDuration;
}
.pipeline.welcome svg :global(.pipeline-only),
.pipeline.stage svg :global(.pipeline-only),
.pipeline.automations svg :global(.welcome-and-pipeline-only),
.pipeline.stage svg :global(.welcome-and-pipeline-only) {
    opacity: 0;
    transition: opacity transitionDuration;
}

.pipeline.stage svg,
.pipeline.automations svg {
    transform: scale(1.25);
}

.pipeline svg :global(.lead-card) {
    opacity: 1;
    transition: opacity transitionDuration;
}
.pipeline.welcome svg :global(.lead-card),
.pipeline.stage svg :global(.lead-card) {
    opacity: 0.4;
}

.pipeline.automations svg :global(.lead-card) {
    opacity: 0;
}
.pipeline:not(.automations) svg :global(.automations) {
    opacity: 0;
}
.pipeline.automations svg :global(.card-background:first-of-type) {
    opacity: 1;
    transition: opacity transitionDuration;
}
