.color-picker-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border-color: transparent;
    transition: transform 0.2s ease;
}

.icon-picker-button {
    composes: color-picker-button;
    width: 50px;
    height: 50px;
}

.color-picker-button:hover,
.icon-picker-button:hover {
    transform: scale(1.1);
    opacity: 0.5;
}

.custom-image {
    composes: icon-picker-button;
    margin-right: -8px;
    object-fit: cover;
}

.custom-image-selected {
    composes: custom-image;
    border: 2px solid var(--blue);
}

.custom-image:hover,
.custom-image-selected:hover {
    transform: scale(1.1);
    opacity: 0.5;
}
