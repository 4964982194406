.activate-sequence-modal {
    position: relative;
    height: 100%;
    min-width: 100%;
}

/* Last child is the footer of the modal */
.activate-sequence-modal > :last-child {
    position: absolute;
    left: 32px;
    right: 32px;
    bottom: 0;
}
