.buttons {
    position: absolute !important;
    top: 10px;
    right: 10px;
    font-size: 15px;
    z-index: 10;
}

.buttons > *:not(:first-child) {
    margin-left: 8px;
}
