.email-list {
    display: flex;
    /* This constrains the list to the page height inside flex parent */
    min-height: 0;
    width: 100%;
    justify-content: center;
    background-color: #fff;
    flex-direction: column;
}

.large-icon {
    width: 240px;
}

.empty-state-container {
    margin-top: 64px;
}

.date-header {
    font-size: var(--font-small);
    color: var(--grey);
    margin-top: 16px;
    padding: 16px 16px 8px 16px;
    width: 100%;
    box-sizing: border-box;
}

.filter-text-container {
    display: flex;
    align-items: center;
    font-size: var(--font-normal);
}

.filter-text-container strong {
    color: var(--grey-dk);
}

.link {
    position: relative;
    cursor: pointer;
    color: var(--grey-lt);
}

.link:hover,
.link:focus {
    text-decoration: underline;
    color: var(--grey-dk);
}
