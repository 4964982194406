.timeline-comment {
    display: flex;
    background-color: var(--white);
    padding: 12px 16px 16px 12px;
    border-top: 1px solid var(--off-white-dk);
}

.avatar-container {
    margin-top: 6px;
    margin-right: 12px;
}

.commenter-content-container {
    flex: 1 1 100%;
}

.commenter-content-container > :first-child {
    font-weight: 500;
}

.timestamp-delete-button-container {
    display: flex;
    flex: 1 0 fit-content;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 8px;
    margin-left: 12px;
}

.timestamp-delete-button-container > :first-child {
    color: var(--grey-lt);
    font-size: var(--font-small);
    margin-right: 8px;
}

.content > div > span[data-entity-name='Users'],
.content > div > span[data-entity-name='Teams'] {
    background-color: var(--orange-xlt);
}

.content > div > span[data-entity-name='Contacts'] {
    background-color: var(--blue-xlt);
}

.content > div > span[data-entity-name='Accounts'] {
    background-color: var(--plum-xlt);
}

.content > div > span[data-entity-name='Leads'] {
    background-color: var(--green-xlt);
}

.remove-button > :first-child {
    padding-top: 0; /* Remove remove button padding */
}

.add-reaction-container {
    /* to align reaction button with other buttons */
    position: relative;
    top: -5px;
}

.reaction-bar-container {
    /* to align reaction bar with comment text */
    position: relative;
    top: 4px;
    left: -2px;
}
