.normal-font {
    font-size: var(--font-normal);
    line-height: 19px;
}

.small-font {
    font-size: var(--font-small);
    line-height: var(--font-big);
}

.text-container {
    width: 30%;
    max-height: 150px;
    min-height: 80px;
    overflow: hidden;
    word-break: break-word;
}

.approval-container {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    background-color: var(--quote-signature-orange);
    text-align: center;
}

.line-container {
    border-top: 1px solid black;
    text-align: center;
    width: 40%;
}

.logo-container {
    width: 200px;
    max-height: 180px;
}

.logo-container img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.subtotal-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100% - 54px); /* 54px is the height of the total container */
    padding: 16px;
}

.notes-container {
    width: 40%;
}

.pricing-container {
    width: 60%;
}

.total-container {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: var(--off-white-dk);
    display: flex;
    justify-content: space-between;
    height: 54px;
    align-items: center;
    padding: 0 16px;
}

.quote-info-container {
    min-height: 88px;
    width: 100%;
    height: 100%;
}

.header-container {
    min-height: 100px;
}

.body-subtotal-container {
    min-height: 200px;
    display: flex;
    gap: 32px;
    width: 100%;
}

.body-subtotal-container-template-height {
    min-height: 200px;
}

.prepared-container {
    display: flex;
    gap: 32px;
    width: 100%;
}

.half-section {
    width: 100%;
    /* 16px is the gap between the sections */
    max-width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    overflow-wrap: break-word;
}

.add-row-button {
    margin-top: auto;
}

.subtotal-row-label {
    width: 55%;
    word-wrap: break-word;
}

.subtotal-row-percent {
    width: 15%;
    display: flex;
    justify-content: flex-end;
}

.subtotal-row-price {
    width: 38%;
    display: flex;
    justify-content: flex-end;
    word-break: break-word;
}

.terms-container {
    min-height: 160px;
    text-align: center;
}

.quote-overview-container {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--off-white);
    padding: 16px 16px 16px 32px;
    align-items: center;
    border-radius: 4px 0;
    border-bottom: 1px solid var(--off-white-dk);
}

/* This div is just used as a ref, but some peanut styling is forcing 100% width
and height, so we wrap it in 0px by 0px div */
.quote-script-container {
    height: 0%;
    width: 0%;
}

.header-controls-container {
    width: 116px;
    display: flex;
    align-self: center;
    margin-left: auto;
}

.editable-text-wrapper {
    width: 100%;
    height: 100%;
    max-height: 274px;
    overflow-y: auto;
    word-break: break-word;
}

.read-only-text-wrapper {
    width: 100%;
    height: 100%;
    max-height: 233px;
    word-break: break-word;
}

.editable-footer-wrapper {
    width: 100%;
    height: 208px;
    overflow-y: auto;
    word-break: break-word;
}

.read-only-footer-wrapper {
    width: 100%;
    height: 100%;
    max-height: 170px;
    min-height: 100px;
    word-break: break-word;
}
