@value headerHeight: 69px;

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--form-border);
    padding-right: 8px;
    height: headerHeight;
}
