@import '../../../include';
@import '../../global/_colors';

.ui-notifications__list {
	z-index: 10001;
	width: 360px;
	position: fixed;
	right: 0;
	top: 0;
	margin: 0;
	padding: 0 20px 0 0;
	overflow-y: scroll;
	// Hide horizontal scroll bars on FF :|.
	overflow-x: hidden;

	// WTF Firefox why do you give height to empty stuff
	&:empty {
		height: 0;
	}
}

.ui-notifications__notification {
	// Account for 20px padding
	width: ~'calc(100% - 20px)';
	height: 80px;
	margin-top: 20px;
	padding-left: 20px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: fade(@grey-dk, 95%);
	border-radius: 8px;

	cursor: pointer;

	// Prevent mouseout events from firing inside the notification.
	// This completely prevents the notification from auto-closing when you
	// are mouseover'ing the notification.
	* {
		pointer-events: none;
	}

	&:hover,
	&:active {
		background-color: @grey-dk;
	}

	&--on-animate-in {
		@keyframes animate-in-notification {
			0% {
				transform: translateX(340px);
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			100% {
				transform: translateX(0px);
				opacity: 1;
			}
		}

		animation: animate-in-notification 600ms @customEasing 1s forwards;

		transform: translateX(340px);
		opacity: 0;
	}

	&--on-animate-out {
		@keyframes animate-out-notification {
			0% {
				transform: translateY(0px);
				opacity: 1;
			}
			100% {
				transform: translateY(-80px);
				opacity: 0;
			}
		}

		animation: animate-out-notification 400ms @customEasing 0s forwards;

		& ~ li {
			@keyframes animate-up-notification {
				0% {
					transform: translateY(0px);
				}
				100% {
					transform: translateY(-100px);
				}
			}

			animation: animate-up-notification 400ms @customEasing 0s forwards;
		}
	}
}

.ui-notification__icon {
	// Accounting for the 20px padding of the li.
	margin: 10px 10px 10px -5px;

	.svg-tick-circle svg {
		fill: fade(@off-white, 70%);
		fill-rule: evenodd;
	}

	.svg-frowny svg {
		fill: @rose;
		fill-rule: evenodd;
	}
}

.ui-notification__content {
	width: 240px;

	font-size: @normal;
	color: white;
	text-overflow: ellipsis;
}

.ui-notification__title {
	font-size: @small;
	color: fade(@off-white, 80%);
	line-height: 4px;
}

.ui-notification__body {
	line-height: 18px;
	margin-top: 7px;
	max-height: 38px;
	overflow: hidden;
}

.ui-notification__close {
	align-self: flex-start;
	width: 26px;
	height: 26px;
	padding: 0 !important;
	margin: 7px;
	border-radius: 50%;
	line-height: 26px;
	text-align: center;
	background-color: @off-white-dk;
	cursor: pointer;

	&:hover {
		background-color: @off-white;
	}

	&:active {
		background-color: white;
	}

	.svg-close {
		pointer-events: all;

		svg {
			width: 10px;
			height: 10px;
			fill: @grey-dk !important;
		}
	}
}
