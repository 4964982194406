.title {
	.email-template-editor &,
	.email-template-list-container & {
		font-size: @normal;
	}
}

.email-template-list-header {
	margin-bottom: 10px;
}

.email-template-editor {
	float: left;
	width: 50%;
	margin-bottom: 40px;
	margin-left: 16px;

	.title { word-wrap: break-word; }
}

.email-template-editor-field,
.email-template-editor-buttons,
.input {
	width: 100%;
	margin-top: 5px;
	resize: none;
	font-size: @normal;
}

.email-template-editor-buttons {
	display: flex;
	justify-content: flex-end;

	button {
		margin-left: 8px;
	}
}

.email-template-list-container {
	float: right;
	width: 45%;
}

ul.email-template-list {
	overflow-y: scroll;
	margin-top: 10px;
}

.empty-list-view {
	position: relative;
	font-size: @normal;
	line-height: 19px;
	color: @blue;
	border-top: 1px solid @off-white-dk;
	padding: 6px 0 7px 7px;
	overflow: hidden;
	font-size: @small;
	color: @grey-lt;

	&:last-child {
		border-bottom: 1px solid @off-white-dk;
	}
}

.email-template-summary {
	position: relative;
	font-size: @normal;
	line-height: 19px;
	color: @blue;
	border-top: 1px solid @off-white-dk;
	padding: 6px 0 7px 7px;
	overflow: hidden;
	&:hover {
		cursor: pointer;
		color: @blue-dk;
		background: @blue-bg;
	}
	&.selected {
		color: @blue-dk;
		background: @blue-bg;
	}

	.name {
		word-wrap: break-word;
		padding-right: 25px;
	}

	.ui-btn__delete {
		position: absolute;
		top: 2px;
		right: 0;
	}

	&:last-child {
		border-bottom: 1px solid @off-white-dk;
	}
}
