.slot {
    box-sizing: border-box;
    height: 316px;
    min-width: 300px;
    width: calc(50% - 16px); /* 8px margin left and right */
    margin: 8px;
}

.slot-hovered {
    border: dashed 2px var(--grey-lt);
}

.slot-wide {
    width: calc(100% - 10px); /* 5px padding left and right */
}
