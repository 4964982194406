.header {
    display: grid;
    grid-template-columns: 300px 1fr 150px;
}

.options {
    display: grid;
    justify-content: center;
    grid-template-columns: 256px 370px;
    gap: 32px;
}
