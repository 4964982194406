.label {
    display: flex;
    color: var(--label);
    font-size: var(--font-normal);
    margin-bottom: 8px;
    font-weight: normal;
}

.label--required {
    composes: label;
    right: -7px;
}

.label--inline {
    composes: label;
    margin-bottom: 0;
    margin-right: 8px;
}

.label--inline-block {
    composes: label;
    display: inline-block;
}

.label--no-bottom-margin {
    composes: label;
    margin-bottom: 0;
}

.label--large-size {
    composes: label;
    font-size: 16px;
}

.label--required::after {
    content: '*';
    color: var(--rose);
    padding-left: 2px;
}

.pseudo-label {
    composes: label;
}
