.todo-scroll-container {
    height: 100%;
    margin-top: -40px;
}

.todo-scroll-container div:focus {
    outline: none;
}

.empty-loading {
    height: 100%;
    background-color: white;
    padding-top: 15px;
}
