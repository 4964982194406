.table-footer-cell {
    composes: cell from '../styles.css';
    position: relative;
    color: var(--grey-dk);
    white-space: nowrap;
    font-weight: 500;
}

.compact {
    padding: 8px;
}

.bordered,
.border--right {
    border-right: var(--border-hairline);
}

.data-type--integer {
    text-align: right;
    justify-content: flex-end;
}
