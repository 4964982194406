.section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 90px;
    background-color: var(--off-white);
    padding: 8px 16px;
    box-sizing: border-box;
    overflow: auto;
    white-space: pre-wrap;
    border-radius: 3px;
}

.section-wrapper.disabled {
    opacity: 0.5;
    pointer-events: none;
}
