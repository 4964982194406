.peep-container .peep-subheader-container {
	border-bottom: 1px solid @off-white;
	margin-bottom: 12px;
	padding: 0;
}

.peep-container .peep-subheader {
	position: relative;
	#util > .clearfix();
	color: @grey-lt;

	p {
		// recent, created by
		margin: 3px 0;
		em {
			font-style: normal;
		}
	}

	.avatar-container.action-file-pick {
		.avatar {
			position: absolute;
			top: -30px;
			left: 20px;
		}
	}

	.icon {
		display: inline-block;
		vertical-align: top;
		width: 30px;
		height: 25px;
		padding-right: 2px;
		.peep-sprite();
	}

	.send-to-button {
		// allows space for the Unsubscribed badge if is exists
		min-height: 54px;
	}

	.delete-links {
		a {
			cursor: pointer;
		}
		.behavior-dupecheck {
			color: @rose;
			visibility: hidden;
			opacity: 0;
			#util > .transition(opacity, 0.3s);
		}
		.action-undelete {
			display: none;
		}
	}

	.margin-left,
	.info-left {
		float: left;
	}

	.margin-left,
	.info-left {
		a {
			text-decoration: underline;
			&:hover {
				color: @grey-dk;
			}
		}
		p.created-by a {
			color: @grey;
			&:hover {
				color: @grey-dk;
			}
		}
		p.msg-merged a {
			color: @rose;
		}
	}

	.margin-left {
		width: 90px;
		padding: 82px 0 10px 20px;

		.button-action {
			// buttons are fixed to the width of the avatar
			#util > .border-box();
			display: block;
		}
	}

	.info-left {
		width: ~'calc(100% - 144px)'; // this is width of avatar
		padding: 5px 0 10px 15px;

		.eip-field {
			cursor: text;
			color: @grey-dk;
			min-height: 34px;
			margin: 0 0 10px 0;
			padding-right: 50px; // Allow space for eip-buttons
			border-bottom: 2px solid @off-white-md;
			input[type='text'] {
				background: none;
				box-shadow: none;
				border: none;
				border-radius: 0;
				display: block;
				width: 95%; // To allow for eip confirm buttons
				margin: 0;
				padding: 0;
			}
			.eip-buttons {
				position: absolute;
				top: auto;
				bottom: 9px;
				right: 0;
			}
			.action-save {
				font-size: 12px !important;
				line-height: 19px;
			}
			.action-cancel {
				margin-left: 4px;
			}

			&.type-name {
				h1,
				span.empty,
				input[type='text'] {
					font-size: 28px;
					line-height: 32px;
					font-weight: 500;
					color: @grey-dk;
					padding: 7px 0 3px 0;
				}
				span.empty {
					color: @grey !important;
				}
			}

			&.type-description {
				min-height: 30px;
				margin-bottom: 0;
				h2,
				span.empty,
				input[type='text'] {
					font-size: 17px;
					line-height: 24px;
					font-weight: normal;
					color: @grey-dk !important; // Need this guy cos of jank eip important styles
					padding: 4px 0;
				}
				span.empty {
					color: @grey !important;
				}
			}

			&:hover {
				&.type-name,
				&.type-description {
					border-color: @off-white-dk;
					h1,
					h2,
					span.empty,
					input[type='text'] {
						color: black !important;
					}
					span.empty {
						color: @grey-dk !important; // Need this guy cos of jank eip important styles
					}
				}
			}

			&.state-editing {
				background: none; // this removes the white background when hovering over an EIP field when in edit mode
				border-color: @orange-lt;
			}

			&.state-empty {
				h1,
				h2 {
					display: none !important;
				} // this is required, if the h1 is present it pushes the span.empty down
			}
		}

		.deleted-notices p {
			display: none;
			color: @rose;
		}

		.recent-changes,
		.deleted-notices {
			display: flex;
			flex-wrap: wrap;
			position: relative;

			.msg-deleted,
			.msg-merged {
				margin-right: 8px;
			}

			.action-hard-delete {
				display: block;
				cursor: pointer;
				margin: 3px 0;
			}
		}

		.peep-tags {
			margin-bottom: 30px;
		}
	}
}

.peep-container {
	&.entity-accounts .peep-subheader {
		min-height: 218px;
		.account-avatar {
			background: @plum url('@{img-root}/layout/avatar-company.svg') no-repeat 50% 50%;
			background-size: 46px 46px;
		}
		.parent-account-avatar {
			background: @plum url('@{img-root}/icons/avatars/icon-account-parent-count.svg') no-repeat 50% 50%;
			background-size: 80px 80px;
		}
	}

	&.entity-contacts .peep-subheader {
		.avatar {
			background: @navy url('@{img-root}/layout/avatar-person.svg') no-repeat 50% 105%;
			background-size: 66px 66px;
		}
	}

	&.state-merged .peep-subheader {
		.delete-links .action-undelete {
			display: none;
		}
		.deleted-notices {
			display: flex;

			.msg-deleted {
				display: none;
			}
			.msg-merged {
				display: block;
			}
		}
	}

	//for the merged but undeleted edge case -- acting as a band-aid currently
	&.state-merged:not(.state-deleted) .peep-subheader {
		.recent-changes {
			display: none;
		}
	}
	&.state-deleted .peep-subheader {
		.recent-changes {
			display: none;
		}
		.deleted-notices .msg-deleted {
			display: block;
		}
		.eip-field.type-name .value {
			text-decoration: line-through;
		}
		.delete-links {
			.action-delete,
			.behavior-dupecheck {
				visibility: hidden;
			}

			.action-undelete {
				display: block;
			}
		}
	}

	// don't show deleted message if peep was merged
	&.state-merged.state-deleted .peep-subheader .msg-deleted {
		display: none;
	}

	&.state-duplicate .peep-subheader {
		.behavior-dupecheck {
			visibility: visible;
			opacity: 1;
		}
	}
}
