.drip-sequence-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-width: 700px;
}

.message-count {
    padding: 0 8px;
}

.left-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: calc(100% - 400px);
}

.right-container {
    flex-grow: 1;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    width: var(--nm-sidebar-width);
}

.add-message-container {
    width: 100%;
    height: 150px;
}

.add-message-container:not(:only-child) {
    margin-top: 16px;
}

.drip-edition-list-container {
    overflow-y: auto;
}

.loading-page-container {
    height: 50vh;
}
