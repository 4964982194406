/*
This is for detail text on the preview page. If there isn't an actual value,
we still need to show _something_, so we should indicate to the user that the
value there isn't what is actually entered (their preview text isn't "none",
just that there is no preview text)
*/
.detail-text-secondary {
    font-style: italic;
    color: var(--grey-lt) !important;
}

.detail-text-secondary span {
    color: var(--grey-lt) !important;
}

.recipient-count {
    margin-top: 4px;
}
