.shortcuts-grid {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px 48px;
    width: 100%;
}

.shortcut-key {
    background: var(--off-white-dk);
    padding: 2px 6px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid var(--border);
}

.shortcut-key-multi {
    composes: shortcut-key;
    margin-right: -4px;
}
