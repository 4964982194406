.mark-all {
    display: inline-block;
    font-size: var(--font-small);
    color: var(--blue);
    cursor: pointer;
    margin-bottom: 2px;
}

.mark-all:hover {
    color: var(--blue-dk);
    text-decoration: underline;
}

.top-container-single {
    position: absolute;
    top: 31px;
    right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-container {
    composes: top-container-single;
    width: 174px;
    top: 18px;
}

.top-container svg {
    fill: var(--icon-secondary);
}
