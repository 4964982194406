.selectable-list-row {
    list-style-type: none;
}

.item-container {
    display: flex;
    padding: 8px 10px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--off-white-dk);
    cursor: pointer;
    color: var(--grey);
}

.item-container-selected {
    composes: item-container;
    color: var(--grey-dk);
    background-color: var(--color-list-selected);
}

.item-container-borderless {
    composes: item-container;
    padding: 8px 16px;
    border-bottom: none;
    margin-bottom: 4px;
}

.item-container-disabled {
    composes: item-container;
    cursor: default;
}

.item-container-disabled .name-disabled--ghost,
.item-container-disabled .icon:not(.icon-right) {
    opacity: 0.5;
}

.item-container:not(.item-container-disabled):hover,
.item-container-selected:not(.item-container-disabled):hover {
    color: var(--grey-dk);
    background-color: var(--color-list-hovered);
}

.checkbox-container {
    margin-right: 5px;
}

.name {
    position: relative;
    display: flex;
    flex-grow: 1;
}

.name > *:not(:last-child) {
    margin-bottom: 4px;
}

.name-disabled--line-through {
    composes: name;
    text-decoration: line-through;
}

.name-disabled--ghost {
    composes: name;
    text-decoration: none;
}

.subtitle {
    font-size: 12px;
    color: var(--grey-lt);
}

.num-selected {
    border-radius: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: var(--off-white-md);
    color: var(--grey);
    text-align: center;
    font-size: var(--font-tiny);
}

.count {
    font-size: var(--font-tiny);
}

.icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.icon-right {
    composes: icon;
    margin-right: 0;
}

.icon-teams {
    composes: icon;
    background-image: url('/include/images/icons/capsule-sprite_2x.png');
    background-repeat: no-repeat;
    background-size: 176px 16px;
    background-position: -112px 0;
}
