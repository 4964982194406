.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
}

.right-container {
    background-color: var(--plum-bg);
    display: flex;
    align-items: center;
    justify-content: center;
}
