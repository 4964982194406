@import 'include.less';
@import 'timeline/_timeline-components.less';
@import 'timeline/_timeline-comments.less';
@import 'timeline/_timeline-ribbons.less';

.timeline3-list {
	transition: padding-top .5s ease;

	.action-loadprevious {
		width: 110px;
		margin: 10px auto 20px auto;
		line-height: 24px;
		background-color: @off-white-dk;
		color: @grey-lt;
		border: 1px solid @off-white-dk;
		border-radius: 2px;
		font-style: italic;
		text-align: center;

		.loading { display: none; }
	}

	.loading-container {
		margin-top: 10px;
		width: 100%;
		text-align: center;
		svg path,
		svg rect {
			fill: @orange;
		}
	}

	.timeline3-end {
		display: none;
		position: relative;
		padding-bottom: 30px;
		.line {
			position: absolute;
			height: 30px;
			width: 2px;
			background-color: @timeline3-bordercolor;
			bottom: 27px;
			left: 35px;

			&.dashboard-bottom { bottom: 27px;	}

			&:after {
				position: absolute;
				content: '•';
				font-size: 26px;
				bottom: -9px;
				right: -7px;
				color: @timeline3-bordercolor;
			}
		}
	}

	.timeline3-empty-state {
		display: none;
		width: 100%;
		font-size: @normal;
		color: @grey;
		text-align: center;
		margin-top: 40px;
		p { margin: 0 0 10px 0; }
		a { font-size: @small; }
	}

	&.state-empty {
		.loading-container { display: none; }
		.timeline3-empty-state { display: block; }
	}

	&.state-loading {
		.action-loadprevious {
			.loading { display: block; }
			.more { display: none; }
		}
	}
	&.variant-full {
		width: 650px;
		margin: 0 auto;

		.timeline3-entry {
			border-left: 1px solid @off-white-dk;
			border-right: 1px solid @off-white-dk;
			border-radius: 2px;
			min-height: 120px; // increase min-height of timeline entry, utilise the space of full page view
			margin: 24px 0;
			padding: 24px;
			&.type-notes {
				background-position: 6px 0; // re-position background img, nudging red margins in from the left, this just looks betters
			}
			.top-info {
				font-size: 19px;
				line-height: 25px;
				margin: 0 0 0 75px;
				.verb {
					font-size: @big;
				}
				.right-side {
					clear: left;
					margin-bottom: 8px;
				}
				.left-side { white-space: normal; }
			}
			.content {
				font-size: @normal;
				line-height: 21px;
				margin: 10px 0 18px 75px;
			}
			ul.bulleted-links {
				.permalink,
				.action-view-files,
				.action-expand,
				.action-collapse {
					display: none;
				}
			}

			&.state-iseditable {
				ul.bulleted-links {
					.action-edit a:after { content: ''; }
				}
			}
		}

		// We want the line in the full timeline view, but not on an individual email page (variant-full)
		.email-container > .line {
			display: none;
		}

		.line {
			height: 24px;
			top: -25px;
			left: 48px;
			&.bottom { //add .line element after/below timeline entry
				height: 25px;
				top: auto;
				bottom: -25px;
			}
		}

		.gutter { // increase width of gutter and elements inside the gutter, utilise the space of full page view
			font-size: @small;
			line-height: 17px;
			width: 105px; // obscure width that centers the time stamp text with the avatar
			.avatar {
				border-radius: 26px;
				width: 52px;
				height: 52px;
				margin: 20px 0 8px 25px;
				img {
					width: 52px;
					height: 52px;
				}
			}
			.initials {
				font-size: 22px !important;
				line-height: 52px !important;
			}
		}

		.timeline3-end { display: block; } // Display timeline end line and finishing .dot

	}

	&.no-more-entries {
		.action-loadprevious { display: none; }
		.loading-container { display: none; }
		.entries { padding-bottom: 30px; }
		.timeline3-end { display: block; }

		&.dashboard-feed {
			.entries { padding-bottom: 15px; }
		}

		&.state-empty {
			.timeline3-end { display: none; }
			.entries { padding-bottom: 0; }
		}
	}
}

@timeline3-distance: 12px;
@timeline3-bordercolor: @off-white-dk;

.timeline3-entry,
.timeline3-ribbon {
	position: relative;
	color: @grey-dk;

	margin-top: @timeline3-distance;

	.line {
		position: absolute;
		height: @timeline3-distance;
		width: 2px;
		background-color: @timeline3-bordercolor;
		top: -1px - @timeline3-distance;
		left: 35px;
	}

	a,
	span {
		&.type-users {
			color: @orange-dk;
			&:hover { text-decoration: none; }
		}
		&.type-origins {
			cursor: default;
			&:hover { text-decoration: none; }
		}
		&.type-contacts {
			color: @navy-dk;
		}
		&.type-accounts {
			color: @plum-dk;
		}
		&.type-leads {
			color: @green-dk;
		}
		&.type-ticketMessages {
			color: @blue;
		}
		&.type-link {
			color: @blue;
		}
	}
	a:hover { text-decoration: underline; }
}

.timeline3-ribbon {
	// Only change to cursor default on timeline ribbons and not timeline entries
	// as the entire timeline entry is clickable
	a,
	span {
		&.type-users {
			cursor: default;
		}
		&.type-origins {
			cursor: default;
		}
		&.type-link {
			// Need to set the cursor to pointer for links for some reason
			cursor: pointer;
		}
	}
}

.timeline3-entry {
	box-shadow: inset 0 0 0 1px rgba(255,255,255,1), 0 1px 1px rgba(0,0,0,0.03);

	padding: 8px 0 25px 0; // bottom padding contains ul.bulleted-links
	// hold up the gutter w/ min-height minus my own padding
	min-height: 84px - 12 - 24;

	border-top: 1px solid @timeline3-bordercolor;
	border-bottom: 1px solid darken(@timeline3-bordercolor, 3%);

	// pseudo element for inner white border
	&:before {
		content: '';
		position: absolute;
		top: 0; bottom: 0; left: 0; right: 0;
		border-top: 1px solid rgba(255, 255, 255, 0.4);
		border-bottom: 1px solid rgba(255, 255, 255, 0.4);
		pointer-events: none; // @todo fixme for IE
	}

	.gutter {
		position: absolute;
		top: 0;
		left: 0;
		width: 74px;
		font-size: @tiny;
		line-height: 14px;
		color: @grey;

		.avatar {
			margin-top: 8px;
			margin-left: 16px;
			margin-bottom: 3px;
			width: 40px;
			height: 40px;
			border-radius: 20px;
			.initials {
				line-height: 40px;
				font-size: 17px;
			}
			img {
				width: 40px;
				height: 40px;
			}
		}

		.time, .year, .numViews {
			text-align: center;
		}

		.date, .interactions
		&.today .time {
			font-weight: 500;
			text-align: center;
			text-transform: uppercase;
			&.date { letter-spacing: 1px; }
		}
		&.today {
			.date, .year { display: none }
		}
	}
	&.state-overdue {
		.gutter .moment {
			color: @rose;
		}
	}
	a:hover { text-decoration: underline; }
	.top-info,
	.content {
		margin-left: 68px;
		margin-right: 10px;
	}
	.top-info {
		font-size: 14px;
		line-height: 20px;
		color: @grey-dk;
		#util > .excerpt-crop > .ellipsis;

		.left-side,
		.right-side {
			float: left;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.left-side {
			width: 100%;
			min-width: 310px;
		}
		.right-side {
			width: 100%;
			margin-bottom: 2px;
		}

		.email-error {
			font-size: @small;
			color: @rose-dk;
		}
		.verb,
		.extras {
			font-size: @small;
			color: @grey;
		}
		.subject { padding-right: 4px; }
		.recording-indicator {
			color: @grey;
			font-size: 15px;
			padding: 0px 2px 0 3px;
		}
	}

	.content {
		clear: both;
		line-height: 19px;

		position: relative;
		word-wrap: break-word;

		max-height: 55px;
		overflow: hidden;
		text-overflow: ellipsis;

		// p - empty placeholder text
		p { margin: 0 0 8px 0; }
		a {
			color: @blue;
			&:hover { color: @blue-dk; }
		}
		.no-description-placeholder {
			font-style: italic;
			color: @grey-lt;
			margin: 0; // remove margin as we know they will only be one paragraph
		}
		.chat-table { display: none; }
		.eip-buttons {
			text-align: right;
			margin-top: 4px;
			a.button {
				color: white;
				margin-left: 4px;
			}
		}
	}

	ul.bulleted-links {
		font-size: @small;
		position: absolute;
		right: 0;
		bottom: 0;
		a.indicator-loading {
			display: none;
			text-decoration: none !important;
			color: @grey-lt !important;
		}
	}

	&.state-collapsed {
		.content {
			.body { display: none; }
		}
		a.action-collapse { display: none;}
	}
	&.state-loading {
		a.action-expand { display: none; }
		ul.bulleted-links li a.indicator-loading { display: block; }
	}
	&.state-expanded {
		.content {
			max-height: none;
			overflow: visible;
			.excerpt { display: none; }
		}
		a.action-expand { display: none; }
	}

	&.type-activities,
	&.type-notes {
		overflow: visible;
		.content {
			&.state-editing {
				overflow: visible;
				max-height: none;
			}
			span.mention-rendered {
				background: @peach;
				border-bottom: none;
			}
			span.mention-rendered-self {
				background: @blue-bg;
				border-bottom: none;
			}
			span.mention-rendered a,
			span.mention-rendered-self a {
				color: @grey-dk;
				text-decoration: none;
			}
		}

		.mentions-input-box {
			background: white;
			z-index: 10;
		}
	}

	&.type-activities { background-color: @timeline-activity-background; }
	&.type-emails,
	&.type-emailsQueued {
		background-color: @timeline-email-background;

		.content .body {
			img {
				max-width: 70%;
			}
		}
	}
	&.type-ticketMessages { background-color: @timeline-ticket-background;
	
	}
	&.type-ticketMessages,
	&.type-emails,
	&.type-emailsQueued {
		.right-side { width: 100%; }
		.left-side {
			min-width: initial;
			width: 80%;
		 }
	}
	&.type-notes {
		background-color: @timeline-note-background;
		.content {
			textarea {
				#util > .input-rounded;
				outline: 0;
				width: 100%;
				resize: vertical;
			}

			div.mentions {
				margin: 1px;
				padding: 3px;
				outline: 0;
			}
		}
	}
	&.type-chats { background-color: @timeline-chat-background;
	}
	&.variant-clickable {
		cursor: pointer;
		&:hover {
			.will-click-on-entry-click { text-decoration: underline; }
		}
	}
	&.variant-clickable.state-expanded { cursor: inherit; }
	&.state-action-required {
		background-image: none;
		border: 3px dashed @grey-lt !important;
		margin-left: 1px;
		margin-bottom: 1px;
		background-color: @off-white;

		.action-share-email { color: @green; }
	}
}

.timeline3-list.dashboard-feed {
	.content .chat-table {
		display: block;
		.column-message {
			font-size: @small;
			min-width: 0px;
			padding: 0px;
		}
		.column-person,
		.column-time { display: none; }
	}
}

.timeline3-entry-files {
	border-left: 1px solid @off-white-md;
	border-right: 1px solid @off-white-md;
	background-color: white;
	min-height: 42px;
	margin: -24px 0 24px 0;
	position: relative;

	#myaccount .type-emails + &,
	#myaccount .type-emailsQueued + &,
	.accounts .type-emails + &,
	.accounts .type-emailsQueued + &,
	.contacts .type-emails + &,
	.contacts .type-emailsQueued + &,
	.leads .type-emailsQueued + &,
	.leads .type-emails + &,{
		margin: 0;
	}
	ul {
		#util > .clearfix;
	}
	li {
		float: left;
		width: 100%/3;
		a {
			background-color: white;
			border-left: 1px solid @off-white-md;
			border-bottom: 1px solid @off-white-md;
			box-sizing: border-box;
			padding: 12px;
			display: block;
			&:hover {
				background-color: @yellow-lt;
				span {
					text-decoration: underline;
					color: @blue-dk;
				}
			}
			span.fa {
				float: left;
				margin-top: 1px;
			}
			span.link-blue-standard {
				display: block;;
				margin-left: 18px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		&:first-child a {
			border-left: none;
		}
	}
}

.email-attachments .creator-email-file-section {
	h2.section-label {
		display: none;
	}
	.file-upload-button a {
		font-size: @normal;
		color: @blue;
		text-decoration: underline;
		&:hover {
			color: @blue-dk;
		}
	}
	ol.file-list li:first-child {
		margin-top: 8px;
	}
}

#peep, #lead {
	.timeline3-list { min-height: 400px; }
}
