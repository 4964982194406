.code-block {
    position: relative;
}

.code-block textarea {
    background-color: var(--grey);
    border-radius: 3px;
    height: 200px;
    width: 100%;
    color: white;
    font-family: monospace;
    resize: none;
    padding: 16px;
    box-sizing: border-box;
}

.copy-button {
    position: absolute;
    right: 16px;
    bottom: 16px;
}
