.toggle-container {
    display: flex;
    align-items: center;
    gap: 12px;
}

.compare-content-container {
    height: 65px;
    border-bottom: 1px solid var(--off-white-dk);
    /* place 1px top, right, left padding to mimic border space & let bottom border stretch fully across */
    padding: 1px 17px 0 17px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.compare-content-container--active {
    composes: compare-content-container;
    padding: 0 16px;
    border: 1px solid var(--blue);
}
