.stage-add-button {
    display: flex;
    padding-right: 100px; /* The width of this button is odd, roll with it */
}

.button-contents {
    color: var(--blue);
    font-weight: normal;
    font-size: var(--font-normal);
    padding: 0 16px;
    white-space: nowrap;
}

.button-contents-empty {
    composes: button-contents;
    padding: 0 36px 0 24px;
}

.right-pad {
    width: 24px;
    background-color: #fff;
}
