@import "include";

.builder {
	margin-right: 40px;

	.block {
		position: relative;
		background: white;
		border: 1px solid @off-white-dk;
		border-top: none;

		&.builder-empty-placeholder {
			color: @blue;
			padding: 8px 0;
			font-size: @small;
			text-align: center;
			text-decoration: underline;
			cursor: pointer;
			background-color: white;
			&:hover {
				background-color: @blue-bg;
				color: @blue-dk;
			}
			.adder { display: none; }
		}

		&.template { display: none }

		.control-external {
			display: none;
			position: absolute;
			background-color: white;
			cursor: pointer;
			span { display: none; }
		}
		.control-external.behavior-remove {
			width: 19px;
			height: 19px;
			top: -2px;
			left: -22px;
			border: 1px solid @off-white-dk;
			background-color: white;
			.2x('@{img-root}/process-builder/icon-remove.png', "repeat", 19px, 19px);
			#util > .opacity(0.7);
			&:hover { #util > .opacity(1); }
		}

		.icon.action-handlebar {
			cursor: move;
			#icon > .pbuilder-handlebar;
			position: absolute;
			top: 14px;
			right: 4px;
			#util > .opacity(0);
		}

		.rule {
			line-height: 20px;
			padding: 8px 10px 8px 15px;
		}

		input.name {
			width: 570px;
			font-size: 15px;
			font-weight: 500;
		}

		input.type-text, textarea {
			-webkit-transition-property: all;
			#util > .rounded-corners(4px);

			padding: 3px !important;

			background-color: @off-white !important;
			border: 1px solid @off-white-dk !important;
		}

		.block-wrapper:hover, .block-wrapper.state-hover-delay {
			.control-external { display: block !important; }
			.icon.action-handlebar { #util > .opacity(1); }

			input.styled, textarea {
				background-color: white !important;
				border: 1px solid @grey-lt !important;
			}
		}

		&.ui-sortable-helper {
			.adder { display: none; }
		}
	} // .block

	.adder {
		width: 100%;
		height: 1px;
		float: left;
		background-color: transparent;
		opacity: 0.5;
		z-index: 2;
		position: absolute;
		bottom: auto;

		a {
			position: absolute;
			cursor: pointer;
			top: -12px;
			right: -45px;
			#util > .inline-block;
			background: url("@{img-root}/process-builder/icon-adder.svg") top left no-repeat;
			width: 40px;
			height: 32px;
			opacity: 0.75;
		}
		&.hover,
		&:hover {
			background-color: @blue;
			opacity: 1;
			height: 3px;
		}
	}

	.builder-empty-placeholder {
		display: none;
		position: relative;
	}
}

.layout-alternate .builder {
	input.name { width: 530px; }
	.adder { width: 599px; }
}
