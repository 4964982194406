.help-icon {
    line-height: 1;
    cursor: pointer;
    color: var(--onboarding-help);
    display: flex;
    align-items: center;
}

.help-icon:hover,
.help-icon:focus {
    color: var(--onboarding-help-dk);
}

.help-icon--error {
    composes: help-icon;
    color: var(--rose);
}

.help-icon--error:hover,
.help-icon--error:focus {
    color: var(--rose-dk);
}

.help-icon--green {
    composes: help-icon;
    color: var(--success);
}

.help-icon--green:hover,
.help-icon--green:focus {
    color: var(--green-dk);
}
