.container {
    position: relative;
    text-align: center;
    background: #3f3488; /* Color matches image below. Cannot standardize with our current colors */
    height: 100%;
}

.img-container {
    background-image: url('/include/images/dashboard/onboarding-welcome-card-bg_2x.png');
    background-size: 4px 147px;
    background-position: 0 0;
    background-repeat: repeat-x;
}

.image {
    width: 397px;
    height: 147px;
    margin: 0 auto;
}

.header-title {
    font-size: 19px;
    letter-spacing: 0.5px;
    color: #fff;
    margin-top: -30px;
}

.header-text {
    font-size: var(--font-big);
    line-height: 24px;
    color: #fff;
    margin: 8px auto;
    padding: 0 16px;
    max-width: 450px; /* Allows for nicer wrapping */
}

.button-wrapper {
    text-align: center; /* Hack to center buttons */
    z-index: 1;
    transition: 250ms opacity var(--custom-easing);
}

.learn-more-button {
    display: block;
    margin: 4px auto 0 auto;
    width: 150px;
    font-weight: 500;
    font-size: var(--font-big);
    letter-spacing: 0.5px;
    color: #3f3488; /* Color matches image. Cannot standardize with our current colors */
    background-color: var(--off-white-md);
    border-radius: 3px;
    user-select: none;
    padding: 10px 20px 11px;
}

.learn-more-button:hover {
    background-color: #fff;
}

.dismiss-link {
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--font-small);
    color: rgba(255, 255, 255, 0.7);
}

.dismiss-link a {
    text-decoration: underline;
}

.dismiss-link a:hover {
    color: #fff;
}
