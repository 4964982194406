.settings-container,
.container {
    position: relative;
    display: flex;
    flex-shrink: 0;
    min-height: 100%;
    /* + 16px right & left padding */
    width: calc(var(--report-sidebar-width) + 32px);
    background-color: white;
    border-right: 1px solid var(--off-white-dk);
    justify-content: space-between;
    flex-direction: column;
}

.sidebar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.sidebar-container--full-height {
    height: 100%;
    width: 100%;
}

.sidebar-container > div,
.sidebar-container--full-height > div {
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
}

.search {
    /* -2px on right & left to align with rest of content */
    margin: 16px -2px 0 -2px;
}

.search input {
    color: var(--grey-lt);
}

.heading-container {
    margin-top: 24px;
}

.heading-container--small {
    margin-top: 12px;
}

.icon {
    height: 15px;
    width: 15px;
    padding-right: 6px;
    fill: var(--grey);
    vertical-align: middle;
}

.link {
}
.link--active {
    composes: link;
}

.link a {
    display: block;
    position: relative;
    padding: 4px 0;
    color: var(--grey);
    margin-left: -17px;
    padding-left: 14px;
    font-size: var(--font-normal);
    border-left: 3px solid transparent;
}

.link--active a {
    color: var(--grey-dk);
    font-weight: 500;
    border-left: 3px solid var(--grey-dk);
}

.link--active .icon {
    fill: var(--grey-dk);
}

.link a:hover:not([disabled]) {
    color: var(--grey-dk);
    border-left: 3px solid var(--grey-dk);
    fill: var(--grey-dk);
}

.link a:focus-visible:not([disabled]) {
    color: var(--grey-dk);
    border-left: 3px solid var(--grey-dk);
    fill: var(--grey-dk);
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}

.link a:hover:not([disabled]) .icon {
    fill: var(--grey-dk);
}

.link a[disabled] {
    pointer-events: none;
}

.link-text {
    vertical-align: middle;
    font-size: var(--font-normal);
}

.container section:first-child .heading {
    padding-top: 16px; /* put first header up at the top of the sidebar */
}

.container section:first-of-type .heading-container {
    margin-top: 27px;
}

.nav-items-scrollable {
    overflow-y: auto;
    margin-top: 16px;
}

/* add padding to sidebar-container's last child */
.nav-items-scrollable > :last-child {
    padding-bottom: 16px;
}

.container section:first-of-type:not(:first-child) .heading {
    padding-top: 56px; /* Extra padding when there's a search box */
}

.upgrade-arrow-container {
    position: absolute;
    display: flex;
    align-items: center;
    top: 1px;
    right: -8px;
    height: 100%;
    width: 24px;
    box-sizing: border-box;
    padding-top: 2px; /* centers better with text */
}

.warning-icon-container {
    padding-left: 8px;
    display: inline-flex;
    transform: translateY(2px);
    color: var(--yellow);
}

.new-container {
    margin-left: 5px;
    display: inline-flex;
    transform: translateY(0);
    color: white;
    background-color: var(--orange);
    font-weight: normal;
    font-size: var(--font-small);
    height: 16px;
    line-height: 15px;
    padding: 1px 2px 0 2px; /* Weird #s to perfectly center */
    border-radius: 2px;
}

.count-container {
    composes: upgrade-arrow-container;
    padding-top: 0;
    width: auto;
    color: var(--grey-lt);
    font-weight: normal;
}

.requires-upgrade-section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.requires-upgrade-link {
    position: relative;
    display: block;
    color: var(--grey-lt);
}

.requires-upgrade-link > svg {
    fill: var(--grey-lt);
}

.footer {
    background-color: white;
    width: 100%;
}

.upgrade-arrow {
    color: var(--blue);
    fill: var(--blue);
    cursor: pointer;
}

.upgrade-arrow:hover,
.upgrade-arrow:focus {
    color: var(--blue-dk);
    fill: var(--blue-dk);
}

.upgrade-arrow-popover {
    width: 200px;
    font-size: var(--font-small);
    padding: 16px;
}
