.container {
    padding: 16px 8px;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
}
