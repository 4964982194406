.account-calendar-container {
	.row {
		margin-top: 20px;
		padding-top: 10px;
		border-top: 1px dashed @off-white-dk;
		&:first-child {
			border: 0;
			margin-top: 0;
			padding-top: 0;
		}
		.content {
			padding: 8px 16px;
		}
		h2 {
			font-size: @normal;
			color: @grey;
			margin: 4px 0;
		}
		input {
			cursor: text;
		}
	}
}
