.radio {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.radio--inline {
    display: inline;
    margin-right: 16px;
    cursor: pointer;
}

.radio--inline > *:first-child {
    transform: translateY(2px);
}

.radio--top-align {
    composes: radio;
    align-items: flex-start;
}

.radio--width-fit-content {
    composes: radio;
    width: fit-content;
}

.radio input[type='radio'],
.radio--inline input[type='radio'] {
    margin-right: 6px;
    cursor: pointer;
}

.radio--top-align input[type='radio'] {
    margin-top: 2px;
    margin-right: 8px;
}
