/* Style this to look like the add button in other setup page tables */
.add-button-container {
    display: flex;
    align-items: center;
    opacity: 0.9;
    background-color: var(--color-list-selected);
    color: var(--blue);
    cursor: pointer;
    padding: 6px 8px;
    border-top: 1px solid var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
    box-sizing: border-box;
}

.add-button-container:hover {
    opacity: 1;
    background-color: var(--color-list-hovered);
    color: var(--blue-dk);
    text-decoration: underline;
}

.add-button-container:focus,
.add-button-container:active {
    outline: 2px solid var(--focus-blue);
    outline-offset: -2px;
}

.add-button {
    background: url(/include/images/buttons/icon-add-link.svg) no-repeat left center;
    background-size: 16px 16px;
    padding-left: 22px !important; /* Allows for width of icon */
}
