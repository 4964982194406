.input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 10px 0 8px 0;
}

.popover {
    position: absolute;
    width: 1px;
    height: 1px;
    bottom: 25px;
    background: blue;
}

.label {
    font-size: 16px;
    color: var(--label);
}

.value {
    font-size: 20px;
    color: var(--grey-dk);
    float: right;
    width: 64px;
}

.slider {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. Allows space for value */
    height: 30px;
    background: transparent; /* Otherwise white in Chrome */
}

/* Slider thumb is the pull on the range slider */
/* These 3 selectors can't be combined, because the browser will drop them all if it doesn't understand one*/
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    background-color: var(--blue);
    border: 5px solid #fff;
    cursor: pointer;
    margin-top: -15px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

    transition: all 250ms var(--custom-easing-bounce);
}

.slider::-webkit-slider-thumb:hover {
    background-color: var(--blue-dk);
}

.slider:focus::-webkit-slider-thumb,
.slider:active::-webkit-slider-thumb {
    background-color: var(--blue-dk);
    transform: scale(1.15);
}

.slider::-moz-range-thumb {
    height: 36px;
    width: 36px;
    border-radius: 18px;
    background-color: var(--blue);
    box-sizing: border-box;
    border: 5px solid #fff;
    cursor: pointer;
}
.slider::-moz-range-thumb:hover {
    background-color: var(--blue-dk);
}

.slider::-ms-thumb {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: var(--blue);
    box-sizing: border-box;
    border: 5px solid #fff;
    cursor: pointer;
}
/* End Slider thumb */

.slider:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

/* Slider track is the line under the pull */
.slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: var(--off-white-dk);
    border-radius: 4px;
}

.slider::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: var(--off-white-dk);
    border-radius: 4px;
}

.slider::-ms-track {
    width: 100%;
    cursor: pointer;

    background: var(--off-white-dk);
    border-radius: 4px;
    border-color: transparent;
    color: transparent;
}

.slider::-ms-fill-lower {
    background: var(--off-white-dk);
    border: 0;
}

.slider::-ms-fill-upper {
    background: var(--off-white-dk);
    border: 0;
}

.custom-range {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* End Slider track */
