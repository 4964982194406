.bar-container {
    position: relative;
    height: 4px;
    width: 100%;
    float: left;
    margin-top: 4px;
    background-color: var(--icon-secondary);
}

.empty-bar {
    composes: bar-container;
    box-sizing: border-box;
    background-color: var(--icon-secondary);
}

.current-bar {
    height: 100%;
    transition: background-color 500ms;
}

.goal-line-container {
    display: flex;
    justify-content: center;
    position: absolute;
}

.goal-line {
    position: absolute;
    top: -7px;
    background-color: var(--grey-dk);
    box-sizing: border-box;
    height: 10px;
    width: 6px;
    border-left: 2px solid white;
    border-right: 2px solid white;
}

.goal-line-short {
    composes: goal-line;
    height: 9px;
}

.today-label-container {
    position: relative;
}

.today-label {
    position: absolute;
    top: 1px;
    font-size: 11px;
    transform: translate(-50%, 0);
}

.today-label-left {
    composes: today-label;
    transform: initial;
}

.today-label-right {
    composes: today-label;
    transform: translate(-100%, 0);
}

.quota-reached-icon {
    position: absolute;
    top: -7px;
    right: -14px;
    z-index: 1;
}
