@value gap: 12px;

.settings-group-list {
    display: flex;
    flex-wrap: wrap;
    gap: gap;
}

.settings-group-list > div {
    flex: 1 0 calc(50% - gap);
}
