.reply-button-container {
    padding: 0 8px;
    align-items: center;
}

.email-buttons-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.email-buttons-container svg {
    /* This helps the reply icon appear more aligned */
    margin-top: 1px;
}
