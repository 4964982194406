@import 'include';

.page.distribution {
	.builder {
		.block {
			&.template {
				display: none;
			}

			&.default {
				.rule {
					input.name {
						display: none;
					}
					div.default-name {
						display: block;
					}
					.then b.label {
						display: none;
					}
				}
			}
			.rule {
				div.default-name {
					display: none;
					font-weight: 500;
					font-size: 15px;
				}

				.ifthen-wrapper {
					position: relative;
					padding: 0 16px;
					.if,
					.then {
						float: left;
						.default-templates {
							border-collapse: separate;
							border-spacing: 0 1px;
						}
						.spacing-row {
							height: 15px;
						}
					}

					.if {
						width: 325px;
						.criterion {
							margin: 3px 0;

							.entity {
								display: none;
							}

							&.criterion-entity-none .is {
								display: none;
							}
							&.criterion-entity-markets .entity-markets {
								display: inline;
							}
							&.criterion-entity-account_types .entity-account_types {
								display: inline;
							}
							&.criterion-entity-industries .entity-industries {
								display: inline;
							}
							&.criterion-entity-products .entity-products {
								display: inline;
							}
							&.criterion-entity-competitors .entity-competitors {
								display: inline;
							}
							&.criterion-entity-sources .entity-sources {
								display: inline;
							}
							&.criterion-entity-territories .entity-territories {
								display: inline;
							}
						}
					}
					.vr {
						position: absolute;
						left: 325px;
						border-left: 1px solid @off-white-dk;
						border-right: 1px solid white;
						width: 0;
						height: 100%;
					}
					.then {
						width: 205px;
						padding-left: 15px;

						.capsule-add-container {
							margin: 3px 0;
						}
					}
					.default-salesprocess {
						font-size: @normal;
						padding: 16px 0;
						.section-subheader {
							font-size: @big;
							font-weight: 500;
							color: @grey-dk;
							margin-bottom: 16px;
						}
						.default-funnel {
							margin-bottom: 16px;
						}
						b {
							text-align: right;
							width: 160px;
							display: inline-block;
							padding-right: 12px;
							float: left;
							box-sizing: border-box;
						}
						select {
							cursor: pointer;
							color: @grey;
							background: @off-white-md;
							border: none;
							height: 28px;
							border-radius: 3px;
							position: relative;
							top: -1px;
							&:hover {
								color: @grey-dk;
								background: @off-white-dk;
							}
							&:focus {
								outline: none;
							}
						}
						.default-stageset-select,
						.funnel-process-select {
							min-width: 224px; // Random width but matches the width of .stage-set-name
						}
						.stage-set-wrapper {
							float: left;
							width: calc(~'100% - 160px');
							> div {
								margin: -4px 0 12px 0;
							}
						}
						.stage-set-name {
							color: @grey-lt;
							display: inline-block;
							min-width: 110px;
							padding: 4px 12px 4px 1px;
						}
					}
				}
			}
		}
	}
}

.page.distribution-overlay {
	table.overlays {
		tr.capsule-add-wrapper {
			display: none;
		}
	}
}
