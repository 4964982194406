.activities {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.activities svg {
    width: 100%;
}

.fade {
    height: 100%;
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgba(255, 201, 3, 1), rgba(255, 201, 3, 0));
}

.fade:last-child {
    right: 0;
    left: auto;
    background: linear-gradient(to left, rgba(255, 201, 3, 1), rgba(255, 201, 3, 0));
}

.activities :global(.puzzle-piece) {
    animation: slideDown 300ms;
}

.activities :global(.puzzle-shadow) {
    animation: slideUp 300ms;
}

@keyframes slideDown {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}
