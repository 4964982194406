.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 16px;
    box-sizing: border-box;
    overflow: hidden;
}

.empty-stages-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: var(--grey-lt);
}

.no-search-results {
    width: 500px;
}

.scroll-container {
    height: 100%;
    position: relative;
    overflow-y: auto;
    border-left: 1px solid var(--off-white-dk);
}

.placeholder-container {
    display: flex;
    height: 100%;
    overflow: hidden;
}

.column-table {
    display: table;
    table-layout: fixed;
    height: 100%;
}

.column-table > div:first-child {
    padding-left: 0;
}

.column-table > div:last-child,
.column-table > div:nth-last-child(2) {
    padding-right: 0;
}

.empty-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error-image {
    max-width: 100px;
    margin: 16px 0;
}

.error-text {
    font-size: 15px;
    color: var(--grey);
    margin-bottom: 16px;
}

.headers-container {
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    border-top-left-radius: 3px;
    overflow: hidden;
    border-left: 1px solid var(--off-white-dk); /* outside scroll container for orientation on scoll */
}

.headers-scroll-container {
    display: flex;
    overflow-x: hidden;
    margin-left: -1px; /* Removes double border on left side */
    padding-right: 4px; /* This is to give some breathing room for the sort button */
}

/* This gives us a little more width so that on narrow screens you can scroll far enough
   to see the value in the header of the final column */
.sort-by-spacer {
    width: 91px;
    height: 100%;
    background-color: var(--off-white);
}

.sort-by-spacer--with-customize {
    composes: sort-by-spacer;
    width: 178px;
}

.default-pipeline-edit-cta {
    position: fixed;
    bottom: 48px;
    left: 287px;
    width: 800px;
}

.sort-button-container svg {
    margin-top: 3px;
}

.sort-button-container:hover {
    background-color: var(--off-white);
}

.empty-state {
    width: 40%;
    padding: 32px;
    margin: 0 auto;
    background-color: white;
    text-align: center;
}

.empty-state p {
    font-size: 17px;
    padding: 8px 0;
}

.action-buttons {
    display: flex;
    justify-content: center;
}

.action-buttons a:first-child {
    margin: 0 10px;
}
