.draggable-row {
    composes: draggable-row from './draggable-row.css';
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.drag-handle {
    composes: drag-handle from './draggable-row.css';
}

.drag-handle:hover,
.drag-handle:focus {
    color: var(--grey-dk);
}

.content--hovered {
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-left: none;
    background-color: var(--off-white);
    overflow: hidden;
    padding-left: 1px; /* super minor adjustments to make this look stable when web form field is hovered */
}

.content {
    margin-left: 29px; /* This lines up the unhovered content with its hovered neighbors */
}
