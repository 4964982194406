.container {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
}

.container > *:not(:first-child) {
    margin-left: 16px;
}

.text-container {
    /* Required to ellipsize text */
    width: calc(100% - 48px);
}

.icon {
    font-size: 24px;
    width: 24px;
    text-align: center;
}

.title {
    composes: title from './sales-automation-card.css';
    display: flex;
    line-height: 21px;
}

.title-icon {
    color: var(--grey-lt);
    margin-left: 6px;
}

.subtitle {
    composes: second-line from './sales-automation-card.css';
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.subtitle--disabled {
    composes: subtitle;
    text-decoration: line-through;
}
