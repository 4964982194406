.Resizer {
	background: @off-white-dk;
	z-index: 3;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

 .Resizer:hover {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

 .Resizer.horizontal {
	height: 11px;
	margin: -5px 0;
	border-top: 5px solid rgba(255, 255, 255, 0);
	border-bottom: 5px solid rgba(255, 255, 255, 0);
	cursor: row-resize;
	width: 100%;
}

.Resizer.horizontal:hover {
	border-top: 5px solid @off-white-md;
	border-bottom: 5px solid @off-white-md;
}

.Resizer.horizontal::before {
	content: '';
	position: absolute;
	background-image: url(/include/images/dashboard/pane-resizer-handles-horizontal.svg);
	width: 30px;
	height: 5px;
	left: 50%;
	margin-top: -2.5px;
	transform: translateX(-50%);
}

.Resizer.vertical {
	width: 11px;
	margin: 0 -5px;
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.Resizer.vertical:hover {
	border-left: 5px solid @off-white-md;
	border-right: 5px solid @off-white-md;
}

.Resizer.vertical::before {
	content: '';
	position: absolute;
	background-image: url(/include/images/dashboard/pane-resizer-handles-vertical.svg);
	width: 5px;
	height: 30px;
	top: 50%;
	margin-left: -2.5px;
}

Resizer.disabled {
  cursor: not-allowed;
}

Resizer.disabled:hover {
  border-color: transparent;
}
