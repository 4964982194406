.ui-searchable-list,
.ui-entity-searchable-list {
	.found {
		#util > .clearfix();
		margin-bottom: 10px;
		max-height: 76px;
		overflow-y: auto;

		> div { margin-bottom: 4px; }
	}



	.searchable {
		.ui-input-ajax-search {
			margin-bottom: 10px;
			display: flex;
			align-items: center;
		}
		ul { height: 190px; overflow-y: auto; }
		.ui-searchable-list-item {
			#util > .clearfix();
			cursor: pointer;
			padding: 8px 6px;
			border-bottom: 1px solid @off-white-md;
			color: @grey-lt;

			&:first-child {
				border-top: 1px solid @off-white-md;
			}
			.ui-searchable-list-item__icon {
				float: left;
				margin-right: 10px;
				width: 16px;
				height: 16px;
				.2x('@{img-root}/icons/capsule-sprite.png', "no-repeat", 176px, 16px);
			}

			&.ui-searchable-list-item--type-enum {
				.ui-searchable-list-item__icon { display: none; }
			}

			.ui-searchable-list-item__name {
				#util > .excerpt-crop > .ellipsis;
				width: ~"calc(100% - 30px)";
				float: left;
			}
			
			&.ui-searchable-list-item--selected {
				background-color: @color-list-selected;
				color: @grey-dk;
			}

			&:hover,
			&.ui-searchable-list-item--selected:hover {
				background-color: @color-list-hovered;
				color: @grey-dk;
			}

			&.ui-searchable-list-item--disabled {
				opacity: .5;
				.ui-searchable-list-item__name {
					text-decoration: line-through;
				}
			}

			&.ui-searchable-list-item--type-products {
				.ui-searchable-list-item__icon { background-position: -128px 0; }
			}

			&.ui-searchable-list-item--type-users {
				.ui-searchable-list-item__icon { background-position: -16px 0; }
			}

			&.ui-searchable-list-item--type-teams {
				.ui-searchable-list-item__icon { background-position: -112px 0; }
			}

			&.ui-searchable-list-item--type-sources {
				.ui-searchable-list-item__icon { background-position: -48px 0; }
			}

			&.ui-searchable-list-item--type-territories {
				.ui-searchable-list-item__icon { background-position: 0 0; }
			}

			&.ui-searchable-list-item--type-competitors,
			&.ui-searchable-list-item--type-tags,
			&.ui-searchable-list-item--type-industries,
			&.ui-searchable-list-item--type-accountTypes,
			&.ui-searchable-list-item--type-undefined,
			&.ui-searchable-list-item--type-milestones,
			&.ui-searchable-list-item--type-markets,
			&.ui-searchable-list-item--type-activityTypes {
				.ui-searchable-list-item__icon { display: none; }
			}
		}
	}

	.ui-searchable-list__loading-view,
	.ui-searchable-list__empty-view {
		width: 100%;
		text-align: center;
		margin-top: 20px;

		svg path,
		svg rect {
			fill: @grey-lt;
		}
	}
}

.ui-popover-form__header-filter {
	.ui-searchable-list {
		.searchable ul {
			height: 200px;
			overflow-y: auto;
		}
	}
}
