.container {
    padding: 24px;
    flex-grow: 1;
    border: 1px solid var(--off-white-dk);
    background-color: var(--white);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}
