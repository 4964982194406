.list-item {
    display: flex;
    flex-direction: row;
    padding: 12px 0;
}

.actions {
    display: flex;
    align-items: center;
    padding: 0 16px;
}
