.integration-header-container {
	a {
		color: @blue;
		&:hover {
			color: @blue-dk;
		}
	}
	p {
		font-size: @normal;
		line-height: 21px;
	}
}
.account-phone-container {
	padding: 16px;

	input,
	label {
		cursor: pointer;
	}

	.state-saved-msg {
		opacity: 0;
		pointer-events: none;
		color: @green;
		#util.transition(opacity, 400ms, @customEasing);
		clear: left;
		float: right;
		margin: 10px 5px 0 0;
		&.active {
			opacity: 1;
		}
	}

	.row {
		margin-top: 20px;
		padding-top: 10px;
		border-top: 1px dashed @off-white-dk;
		&:first-child {
			border: 0;
			margin-top: 0;
			padding-top: 0;
		}

		> .col {
			display: inline-block;
			width: 245px;
			float: left;
			position: relative;
			&:nth-child(2) {
				width: 300px;
			}
		}
	}

	#registerPhoneType {
		.sub-setting {
			opacity: 0;
			pointer-events: none;
			line-height: 24px;
			padding-left: 0px;
			height: 0px;
			#util.transition(all, 400ms, @customEasing);
			#util.transform(scale(0.75));
			-webkit-transform-origin: 0 0;
			transform-origin: 0 0;
		}

		> ul li {
			margin-bottom: 10px;
			> label {
				cursor: pointer;
				font-size: @big;
				text-align: left;
				color: @blue;
				display: inline-block;
				width: 80%; // Allows for little green save link
				padding: 9px;
				transition: all 250ms @customEasing;

				&:hover {
					color: white;
					background-color: @grey-dk;
					.feature-pro-badge {
						opacity: 0;
						&.feature-pro-badge--light {
							opacity: 1;
						}
					}
				}

				.feature-pro-badge {
					opacity: 1;
					transition: opacity 250ms @customEasing;
					&.feature-pro-badge--light {
						opacity: 0;
					}
				}
			}
			> input[type='radio'] {
				display: none;

				&:checked + label {
					color: white;
					background-color: @grey-dk;

					.feature-pro-badge {
						opacity: 0;
						&.feature-pro-badge--light {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.ctc-logo {
		height: 150px;
		width: 400px;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		#util.transition(all, 650ms, @customEasing);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		background-repeat: no-repeat;
		&.skype-logo {
			background-image: url('@{img-root}/my-account/ctc-logo-skype.png');
			#util.transform(translateX(0px) scale(0.5));
		}
		&.ringcentral-logo {
			background-image: url('@{img-root}/my-account/ctc-logo-ringcentral.png');
			#util.transform(translateX(200px) scale(0.5));
			> span {
				position: absolute;
				left: 0;
				bottom: 0;
				opacity: 0;
				padding: 0 6px;
				#util.transition(opacity, 650ms, @customEasing);
				em {
					color: @blue;
					font-style: normal;
				}
			}
		}
		&.kixie-logo {
			background-image: url('@{img-root}/my-account/ctc-logo-kixie.png');
			#util.transform(translateX(120px) translateY(100px));
			> span {
				position: absolute;
				left: 10px;
				top: 60px;
				opacity: 0;
				padding: 0 6px;
				#util.transition(opacity, 650ms, @customEasing);
				em {
					color: @blue;
					font-style: normal;
				}
			}
		}
	}

	&.phone-type-default {
		.skype-logo,
		.ringcentral-logo,
		.kixie-logo {
			opacity: 1;
			visibility: visible;
		}
	}

	&.phone-type-skype .skype-logo {
		opacity: 1;
		visibility: visible;
		#util.transform(translateX(0px) scale(1));
	}

	&.phone-type-ringcentral .ringcentral-logo {
		opacity: 1;
		visibility: visible;
		#util.transform(translateX(20px) translateY(-25px) scale(1));
		> span {
			opacity: 1;
		}
	}

	&.phone-type-kixie .kixie-logo {
		opacity: 1;
		visibility: visible;
		#util.transform(translateX(20px) translateY(5px) scale(1.1));
		> span {
			opacity: 1;
		}
	}

	#registerNutshellPhone {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		pointer-events: none;
		#util.transform(translateX(60px));
		#util.transition(all, 650ms, @customEasing);
		padding: 0 0 0 20px;
		> ul li {
			line-height: 28px;
		}
		label {
			display: block;
		}
		input {
			display: inline-block;
			width: inherit;
			&:focus {
				outline: none;
			}
		}
		p {
			margin-bottom: 0;
		}

		.state-enabled-msg,
		.state-validating-msg,
		.confirmation-code {
			display: none;
			pointer-events: none;
		}
		.confirmation-code {
			font-size: 28px;
		}
		.invalid-msg {
			font-weight: 500;
			color: @rose;
			display: none;
			padding-top: 20px;
		}
	}

	&.phone-type-phone,
	&.phone-type-clicktocall {
		.skype-logo,
		.ringcentral-logo,
		.kixie-logo {
			visibility: hidden;
		}

		#registerNutshellPhone {
			opacity: 1;
			pointer-events: all;
			#util.transform(translateX(0px));
		}
	}

	&.state-phone-enabled #registerNutshellPhone {
		.action-enable,
		.validate-msg {
			display: none;
		}
		.state-enabled-msg {
			display: inline-block;
		}
	}

	&.state-phone-validating #registerNutshellPhone {
		.action-enable {
			display: none;
		}
		.state-validating-msg {
			display: inline-block;
		}
	}

	&.state-phone-confirmation #registerNutshellPhone {
		.action-enable {
			display: none;
		}
		.confirmation-code {
			display: inline-block;
		}
	}

	&.state-phone-invalid #registerNutshellPhone {
		#telephoneNumber {
			border-color: @rose;
		}
	}

	&.state-phone-confirmation #registerNutshellPhone,
	&.state-phone-validating #registerNutshellPhone {
		input {
			pointer-events: none;
		}
	}
}
