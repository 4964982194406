.two-line {
    min-width: 0; /* this is necessary for container ellipsis overflow behavior */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-small);
    box-sizing: border-box;
    text-align: left;
    max-width: 100%;
}

.two-line--large {
    composes: two-line;
    font-size: var(--font-normal);
}

.two-line--disabled {
    composes: two-line;
    opacity: 0.6;
}

.two-line--light-colors {
    composes: two-line;
    color: var(--grey-lt);
}

.icon {
    display: flex;
    flex-shrink: 0;
    margin-right: 12px;
}

.two-line-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 8px;
    min-height: 32px; /* This height ensures that bottom row g's and y's aren't cut off */
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.two-line-info--large {
    composes: two-line-info;
    height: 36px;
}

.top-row {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    color: var(--grey-dk);
}

.top-row--light {
    composes: top-row;
    font-weight: normal;
}

.top-row--align-center {
    composes: top-row;
    align-items: center;
}

.row-truncated {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* Don't want special "visited" styling in Google inbox extension */
.top-row:visited {
    color: inherit;
}

.top-row > a {
    min-width: 0;
    margin-right: 8px;
}

.top-row > a > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    font-size: var(--font-normal, 15px);
}

.bottom-row {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    justify-content: space-between;
}

.bottom-row > * {
    overflow: hidden;
    text-overflow: ellipsis;
}

.bottom-row-small {
    composes: bottom-row;
    font-size: var(--font-small);
}
