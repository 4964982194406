.help-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    padding: 8px 0;
    margin-left: 8px;
    user-select: none;
}

.help-container--collapsible {
    composes: help-container;
    margin-left: 0;
}

.help-header {
    font-size: var(--font-small);
    color: var(--grey-dk);
    margin-right: 8px;
    cursor: pointer;
}

.help-subtitle {
    font-size: var(--font-small);
    color: var(--grey-lt);
}

.icon {
    width: 18px;
    margin-right: 8px;
    color: var(--grey-dk);
}

.icon:focus {
    outline: rgb(59, 153, 252) auto 5px;
}
