.ui-header {
	font-weight: 500;
	color: @grey-dk;
	font-family: @body-font;

	&.ui-header--sm { font-size: 13px; }
	&.ui-header--md { font-size: 15px; }
	&.ui-header--lg { font-size: 17px; }
}

.ui-title {
	color: @grey-dk;
	font-family: @body-font;

	&.ui-title--sm { font-size: 13px; }
	&.ui-title--md { font-size: 15px; }
	&.ui-title--lg { font-size: 17px; }
}
