.closed {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.closed svg {
    height: 320px; /* Same height as stages SVG after scaling */
}

.closed :global(.slide-up) {
    animation: slideUp 300ms;
}

.closed :global(.slide-down) {
    animation: slideDown 300ms;
}

@keyframes slideDown {
    0% {
        transform: translate(2px, -5px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes slideUp {
    0% {
        transform: translate(16px, 10px);
    }
    100% {
        transform: translate(16px, 0);
    }
}
