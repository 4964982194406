.report-card-container {
    height: 306px;
    min-height: 306px;
    width: calc(100% - 32px);
    margin: 0 16px 16px 16px;
    position: relative;
    display: inline-block;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.absolute-card-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.container {
    width: 100%;
    right: 0;
    z-index: 11;
    transition: width 200ms var(--custom-easing);
}

.container--narrow {
    composes: container;
    width: calc(100% - var(--filter-drawer-width));
}
