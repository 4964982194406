ul.tasks {
	margin-bottom: 20px;

	.task {
		border-top: 1px solid @off-white-dk;
		border-bottom: none;
		min-height: 32px;
		h1.name {
			.value {
				&:hover {
					background: white;
				}
			}
			input {
				padding: 3px 6px !important; // Stops jumping when entering edit mode
			}
			&:hover {
				color: @grey-dk;
			}
			&.state-editing .value {
				background: none;
			}
			span.placeholder_overlay {
				font-weight: normal !important;
			}
		}

		&.state-new {
			border: none;
			border-radius: 3px;
			margin-top: 8px;
			padding: 3px 12px 3px 12px;

			#util > .placeholder(@grey);

			input {
				font-weight: normal !important;
				#util > .placeholder(darken(@off-white-dk, 5%));
			}

			h1.name {
				.plus {
					display: none;
					float: left;
					margin: 0 5px;
					line-height: 25px;
					font-size: 13px;
				}
			}

			&.state-collapsed {
				padding: 3px 10px;
				background-color: @off-white-md;
				min-height: 0;

				input {
					font-size: 13px;
					line-height: 25px;
					#util > .placeholder(@grey);
					margin: 0 !important;
					padding: 0 0 0 1px !important;
					font-weight: 500 !important;
				}

				h1.name {
					.plus {
						display: block;
					}
				}
				.top-row {
					margin-top: 0;
				}

				&:hover {
					cursor: pointer;
					background-color: darken(@off-white-md, 5%);
				}
			}
		}

		&:first-child {
			margin-top: 8px;
			border-top: none;
		}
	}
}

/* ========= */
/* = tasks = */
/* ========= */
ul.dashboardable-list li.task {
	.date {
		color: @grey-lt;
		width: auto;
		.button-eip {
			// this fucking button needs to exist for now, due to stupid jquery datepicker
			display: none;
		}
		.value {
			color: @blue;
			width: 63px;
			&:hover {
				text-decoration: underline;
				color: @blue-dk;
			}
		}
		.type-time .value {
			display: inline-block;
		}
	}
	.buttons-wrapper {
		color: @grey-lt;
		float: left;
		margin: 0 0 0 10px;
	}
	.top-row .buttons-wrapper {
		float: right;
		margin: 0;
		width: inherit;
	}
	.dashboardable-inset {
		clear: both;
		&.state-focus,
		&:hover {
			background: white;
			border: 2px solid @off-white-dk;
		}
	}
	.assigner {
		font-size: @small;
		span.type-users {
			color: @orange-dk;
		}
	}

	.buttons-wrapper {
		width: 200px;
	}

	.icon.action-delete-task {
		#icon > .box-header-delete;
		#icon > .box-header-delete.state-active;
		opacity: 0;
		position: absolute;
		top: 11px;
		right: 2px;
		-webkit-transition: opacity 0.2s linear;
		-moz-transition: opacity 0.2s linear;
	}
	&:hover.state-workable,
	&:hover.state-completed {
		.icon.action-delete-task {
			opacity: 0.3;
		}
		.state-editing {
			// hide delete icon when EIP title
			.icon.action-delete-task {
				opacity: 0;
			}
		}
	}

	&.state-overdue {
		.eip-container.type-date {
			.value {
				color: @rose;
			}
		}
	}
	&.state-new {
		.action-delete {
			display: none;
		}
		.assigner {
			display: none;
		}
		.icon-main.dashboardable-checkbox {
			background: none;
			top: 7px;
		}
		h1.name input {
			// full-width in empty state
			#util > .clearfix();
			width: ~'calc(100% - 100px)';

			.plus {
				float: left;
				margin: 0 5px;
			}
		}

		&.state-collapsed {
			.date {
				display: none;
			}
			h1.name input {
				// full-width in empty state
				cursor: pointer;
			}

			&:hover {
				.icon-main.dashboardable-checkbox {
					.plus-button {
						background: lighten(@blue, 10%);
					}
					.plus-sign {
						background-color: white;
						&:before {
							background-color: white;
						}
					}
				}
			}
		}
	}
	&.state-workable {
		.icon-main.dashboardable-checkbox {
			&:hover {
				background-position: 0 -26px;
			}
		}
	}
	&.state-collapsed {
		.dashboardable-inset {
			display: none;
		}
		.buttons-wrapper {
			display: none;
		}
	}
	&.state-completed {
		cursor: pointer;
		background: white;
		border: none;
		border-bottom: 1px solid @off-white-dk;

		.icon-main.dashboardable-checkbox {
			background-position: 0 -78px;

			opacity: 0.5;
			&:hover {
				opacity: 1;
			}
		}
		h1.name,
		div.date {
			color: @grey-lt;
		}
		h1.name {
			cursor: pointer;
			font-size: 13px;
			font-weight: normal;
			text-decoration: line-through;
			&:hover {
				background: none;
			}
			.value {
				max-width: 55%;
				#util > .excerpt-crop > .ellipsis;
				&:hover {
					cursor: pointer;
					background: none;
				}
			}
		}
		div.date {
			position: absolute;
			right: 26px;
			top: 10px;
		}
		.assigner {
			display: none;
		}
		.buttons-wrapper {
			display: none;
		}
		.dashboardable-inset:hover {
			cursor: default;
			background: none;
			border: 1px solid @off-white-dk;
		}
		&.state-openable.state-collapsed {
			cursor: pointer;
			background: @off-white;
			&:hover {
				background: white;
			}
		}
	}
}

// don't show task relationships on lead or peep page
#lead,
#peep {
	ul.dashboardable-list li.task {
		.meta {
			display: none;
		}
	}
}

#peep ul.dashboardable-list li.task {
	h1.name input {
		width: 440px;
	}
	&.state-new.state-collapsed {
		h1.name input {
			width: 90%;
		}
	}
}
