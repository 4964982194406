.cell-content {
    display: flex;
    align-content: center;
    min-width: 70px; /* This ensures that 'Add users' stays on one line */
}

.names {
    align-self: center;
    color: var(--orange-dk);
    max-width: 260px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cell-content button:first-child {
    padding-left: 0;
}
