.content-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.events-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;
}

.message-input-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
}
