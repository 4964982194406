.bold-text {
    font-weight: bold;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
