.label-field-container button {
    text-align: unset;
}

/* Styles for the dropdown field */
.dropdown-chevron-outer-container {
    position: relative;
    /* Without a set height, this will be given a height of 0, which will cause
    it to not get centered correctly in the parent container. 17px matches the
    default height of the child when it doesn't have absolute positioning */
    height: 17px;
}

.dropdown-chevron-inner-container {
    position: absolute;
    /* This value just makes the fake selection chevron look like its a part of
    the text field */
    left: -22px;
    /* This keeps the chevron from resizing with the input font size */
    font-size: var(--font-small);
}

.textfield-with-help-icon-container {
    position: relative;
    display: flex;
    align-items: center;
}

.textfield-with-help-icon-container input {
    height: unset;
    padding: 8px;
}

.help-icon-container {
    position: absolute;
    margin-top: 8px;
    font-size: 0;
    right: 4px;
}

.options-container {
    margin-top: 8px;
}

.option-container {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.dropdown-option {
    background-color: #fff;
    padding: 4px;
    border-radius: 3px;
    width: 100%;
}

/* Styles for the fake radio button */
.radio {
    border: 1px solid var(--form-border);
    border-radius: 20px;
    background-color: #fff;
    margin-right: 8px;
}

/* Styles for the fake checkbox */
.checkbox {
    border: 1px solid var(--form-border);
    border-radius: 3px;
    background-color: #fff;
    margin-right: 8px;
}

.radio-option {
    line-height: 1.2em;
}

.checkbox-option {
    line-height: 1.2em;
}

.file-upload {
    color: var(--grey-lt);
    font-family: IBM Plex Sans; /* this overrides the user's selected font with our standard one for menu items */
}

.upload-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
}

.upload-icon {
    margin-top: 4px;
}

.upload-text {
    margin-left: 8px;
}

.upload-max {
    margin-top: 8px;
    text-align: center;
}

.image-field-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}

.image-styles {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.upload {
    background-color: var(--off-white);
    border: 2px dashed var(--form-border);
    text-align: center;
    padding: 48px 32px;
    margin: 32px 16px;
}

.icon:not(:last-child) {
    padding-bottom: 8px;
}

/* Overlay + edit button is hidden by default */
.image-field-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 15, 51, 0.08);
    display: none;
    visibility: hidden;
    cursor: pointer;
}

/* When the preview container is hovered over, show the overlay + edit button */
.image-field-container:hover > .image-field-overlay {
    display: initial;
    visibility: visible;
}

/* The edit button */
.image-field-overlay > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
