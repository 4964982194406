.container {
    top: -12px;
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.badge {
    padding: 2px 5px;
    border-radius: 4px;
}

.badge-selected {
    background-color: var(--blue);
    color: var(--white);
}

.badge-unselected {
    background-color: var(--icon-secondary);
    color: var(--black);
}
