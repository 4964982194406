.stage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.stage-info {
    width: 100%;
    font-size: 17px;
    line-height: 19px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 0;
}

.secondary-text {
    font-size: var(--font-small);
    line-height: 15px;
    color: white;
    text-align: center;
    font-weight: normal;
    display: flex;
    justify-content: center;
}

.stage-confirm {
    position: absolute;
    bottom: -38px;
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
