.preview-container {
    position: relative;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid var(--form-border);
    background: var(--off-white);
    border-radius: 3px;
    box-sizing: border-box;
}

.preview {
    width: 100%;
    height: 100%;
}

.preview:focus-visible {
    outline: 2px solid var(--focus-blue);
    outline-offset: -2px;
}

/* Overlay + edit button is hidden by default */
.preview-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: none;
    visibility: hidden;
    cursor: pointer;
}

/* When the preview container is hovered over, show the overlay + edit button */
.preview-container:hover > .preview-overlay {
    display: initial;
    visibility: visible;
}

/* When the preview container is hovered over, blur the preview */
.preview-container:hover > .preview {
    filter: blur(8px);
}

/* The edit button */
.preview-overlay > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
