.status-icon {
    margin-right: 8px;
}

.body-container {
    padding: 24px;
}

.description {
    border-radius: 4px;
    border: 1px solid var(--off-white-dk);
}

.title {
    border-radius: 4px;
    border: 1px solid var(--off-white-dk);
    margin-bottom: 8px;
}

.calendar-icon {
    margin-bottom: 4px;
}
