.search-result {
    display: block;
    cursor: pointer;
    padding-left: 4px;
    background-color: #fff;
    border-radius: 3px;
}

.search-result:hover {
    background-color: var(--color-list-hovered);
}

.search-result:focus {
    background-color: var(--color-list-hovered);
}

.search-result:focus-visible {
    background-color: var(--color-list-hovered);
}

.search-result--fake-focus {
    composes: search-result;
    background-color: var(--color-list-hovered);
}

.secondary-info {
    margin: -10px 16px 0 56px;
    padding-bottom: 12px;
    color: var(--grey);
    overflow: hidden;
    text-overflow: ellipsis;
}
