.table-row {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
}

.table-row:hover {
    background-color: var(--off-white);
}

.progress-container {
    width: 40%;
}

.custom-limit {
    background-color: var(--orange-lt);
    margin-left: 4px;
}

.feature-name {
    display: flex;
    align-items: center;
    min-width: 100px;
    max-width: 150px;
    text-align: left;
    margin-right: 8px;
}

@media screen and (min-width: 1250px) {
    .feature-name {
        max-width: 500px;
    }
}
