.textfield-container {
    margin: 4px 0 4px -2px; /* -2px offsets border, looks better */
    padding-right: 10px; /* this container has 12px padding, minus 2px border */
}

.textfield-group-container {
    margin: 3px 0 3px -2px; /* -2px offsets border, looks better */
}

.textfield-group-container > div > input {
    margin: 1px 0;
}

.textfield-container--linked-select {
    composes: textfield-container;
}

.textfield-container--linked-select > input {
    width: 50px;
    margin-right: 4px;
}

.textfield-container--horizontal {
    composes: textfield-container;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
}

.between-divider {
    color: var(--grey);
    font-weight: 500;
    padding: 0 8px;
    background-color: var(--off-white);
    position: absolute;
    top: 28px; /* centered vertically between textfields */
    border-radius: 3px;
    user-select: no-select;
}

.textfield-container--horizontal > *:first-child {
    margin-bottom: 6px;
}
