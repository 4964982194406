.modal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 900px;
    height: 90vh;
}

.message-preview {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    min-height: 700px;
}

.message-content {
    overflow-y: auto;
    height: 100%;
    min-height: 700px;
    background-color: white;
}

.loading,
.error {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
}
