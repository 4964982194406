.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Guarantee this is above everything else. */
    z-index: 10000;

    opacity: 1;
    animation: container-animate-in 300ms ease 0s forwards;
}
.container--animate-out {
    composes: container;
    animation: container-animate-out 300ms ease 0s forwards;
}

.container--animate-out .body {
    animation: body-animate-out 250ms cubic-bezier(0.175, 0.885, 0.32, 1) 0s forwards;
}

.body {
    position: relative;
    z-index: 1; /* Above overlay. */
    box-sizing: border-box;

    width: 400px;
    height: 100%;

    background-color: #fff;
    overflow: hidden; /* Hide border-radius overflow. */

    transform-origin: top center 0;
    animation: body-animate-in 250ms cubic-bezier(0.175, 0.885, 0.32, 1) 0s forwards;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    /* Below modal body. */
    z-index: 0;
    background-color: var(--color-modal-overlay);
}

/* Rare case: this is used when we have multiple sidebars open
on top of each other and don't want double overlay backgrounds */
.overlay--none {
    composes: overlay;
    background-color: transparent;
}

@keyframes body-animate-in {
    0% {
        transform: translateX(400px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes body-animate-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(400px);
    }
}

@keyframes container-animate-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes container-animate-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
