.container {
    transition: background-color 200ms ease-in-out 0s;
    position: relative;
    background-color: var(--grey);
    float: right;
    margin-right: 10px;
    border-radius: 3px;
    padding: 0 20px 0 15px;
    cursor: pointer;
    font-size: var(--font-normal);
    overflow: hidden;
    color: white;
}

.container:hover {
    background-color: var(--grey-dk);
}

.container-ready {
    composes: container;
    background-color: var(--green);
}

.container-ready:hover {
    background-color: var(--green-dk);
}

.notifies-icon {
    float: left;
    box-sizing: border-box;
    width: 30px;
    height: 32px;
    line-height: 36px; /* centers better */
    text-align: center;
}

.notifies-icon span {
    font-size: 18px;
    color: white;
}

.notifies-icon svg {
    width: 15px;
    height: 15px;
}

.notifies-icon svg path,
.notifies-icon svg rect {
    fill: white;
}

.notifies-text {
    float: left;
    line-height: 32px;
}

.popover {
    min-height: 200px;
    width: 375px;
}
