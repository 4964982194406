.container {
    width: calc(100% - 32px);
    height: 50px;
    box-sizing: border-box;
    background-color: #fff;
    margin: 16px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.back-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
}
