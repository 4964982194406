.slideshow-arrow {
    color: var(--off-white-dk);
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.slideshow-arrow:hover {
    color: var(--grey-lt);
    background-color: var(--off-white);
}

.slideshow-arrow--hidden {
    composes: slideshow-arrow;
    visibility: hidden;
}

.slideshow-arrow--left,
.slideshow-arrow--right {
    composes: slideshow-arrow;
}

.slideshow-arrow--left > :first-child {
    position: relative;
    right: 1px;
}

.slideshow-arrow--right > :first-child {
    position: relative;
    left: 1px;
}
