.recovery-codes-container {
    background-color: var(--off-white);
    width: 100%;
    text-align: center;
    line-height: 16px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 48px;
    font-family: 'IBM Plex Mono', Courier, monospace;
    display: flex;
    justify-content: center;
}

.recovery-codes-inner-container {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 50%;
    font-size: 15px;
}

.loading-container {
    composes: recovery-codes-container;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons-container {
    margin-left: 0;
    margin-right: 0;
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: space-between;
}

.button {
    margin-left: auto;
}

.generate-button {
    margin-right: auto;
    align-self: left;
}

.code {
    margin-top: auto;
    margin-bottom: auto;
    align-self: right;
}

.usedCode {
    composes: code;
    text-decoration: line-through;
}

.recovery-button-group {
    margin-left: auto;
    align-items: flex-end;
}
