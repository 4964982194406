.container {
    margin-top: 32px; /* Matches margin from .peep-sidebar-blocks */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header > button {
    font-weight: normal;
}

.buttons {
    display: flex;
    gap: 8px;
}
