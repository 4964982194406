.description-container {
    width: 85%;
}

.cue-card-container {
    width: 250px;
}

.integrations-main-content-wrapper {
    max-width: 1400px;
    min-width: 670px;
}

.integrations-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 16px;
}

@media (min-width: 2000px) {
    .description-container {
        width: 60%;
    }
}
