.notification-container {
    position: absolute;
    right: 0;
    top: 0;
}

.notification {
    position: relative;
}

.content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding: 16px;
}

.content--clickable {
    composes: content;
}

.content--clickable:hover {
    cursor: pointer;
}

/* The Icon */
.content > :first-child {
    flex-shrink: 0;
}

.message {
    margin-left: 12px;
}

/* The body */
.message > :last-child {
    margin-top: 4px;
}

.action-button {
    position: relative;
    right: -8px;
    bottom: -4px;
    align-self: flex-end;
}

.close-button {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;
}
