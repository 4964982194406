.ranked-stat-bar-container {
    display: flex;
}

.rank-label {
    color: var(--grey-lt);
    margin-right: 12px;
    font-size: var(--font-big);
}

.stat-container {
    width: 100%;
}

.ranked-stat-bar-container a:hover {
    opacity: 0.8;
}

.stat-label {
    margin-bottom: 8px;
    font-size: var(--font-normal);
}

.stat-label span {
    margin-left: 4px;
    color: var(--grey-lt);
}

.placeholder {
    height: 17px; /* match line-height of label text */
    width: 50%;
    background-color: var(--off-white-dk);
    border-radius: 3px;
}
