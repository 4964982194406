.container {
    position: relative;
    width: fit-content;
}

.child-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
}

.child-overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
}

.child-count-overlay {
    background-color: white;
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    border: 1px solid var(--company);
    color: var(--company);
}
