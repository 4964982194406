.tiny-intro-layout {
    display: grid;
    grid-template-columns: minmax(400px, 1fr) 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
}

.tiny-intro-layout--full-width {
    width: 100%;
}

.left-column {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: hidden; /* Hides some strange 5px white bottom section */

    display: flex;
    align-items: center;
    justify-content: center;
}

.right-column,
.single-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 64px 0;
}

.right-column > *:first-child {
    box-sizing: border-box;
    width: 100%;
    padding: 0 64px;
}

@media (max-width: 928px) {
    .left-column {
        display: none;
    }

    .right-column {
        height: 100%;
    }

    .right-column > *:first-child {
        padding: 0 32px;
    }

    .tiny-intro-layout {
        grid-template-columns: 1fr;
        height: 100%;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: scale(1.1);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
