@import 'include.less';

#sidebar {
    .empty-section {
        height: 35px;
        background-color: @off-white-md;
        margin: 16px;
    }

    .empty-icon-container {
        position: absolute;
        width: 100%;
        bottom: 100px;
        text-align: center;

        .icon {
            background-size: 40px 56px !important;
            width: 40px;
            height: 56px;
            fill: @off-white-md;
        }
    }
}

#loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    h1 {
        color: var(--grey-dk);
        font-size: 26px;
        margin-bottom: 20px;
    }

    p {
        color: @grey;
        max-width: 600px;
        line-height: 28px;
        font-size: 19px;
        text-align: center;
        margin: 0 0 22px 0;
    }

    .ui-btn {
        display: block;
        width: 200px;
        margin: 0 auto;
        height: 45px;
        font-size: 17px;
    }

    .icon-explore {
        width: 75px;
        height: 75px;
        margin-bottom: 32px;
        fill: @plum;
    }

    .pulse-ring {
        border: 3px solid @plum;
        border-radius: 50%;
        height: 220px;
        width: 220px;
        -webkit-animation: pulsate 2.1s ease-out;
        -webkit-animation-iteration-count: infinite;
        opacity: 0;
        margin: 0 auto;
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
    }
    @-webkit-keyframes pulsate {
        0%    {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
        80%   {opacity: 0.08;}
        100%  {-webkit-transform: scale(1.1, 1.1); opacity: 0.0;}
    }

    .loading-icon-container {
        height: 45px;
        margin-top: -6px; //centers loading icon vertically
        text-align: center;
        svg path,
        svg rect {
            fill: @grey;
        }
    }
}

#let-me-in a {
    padding: 12px 56px; // HACK: Fill up most of the button (areas on the ends still aren't covered)
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 200ms linear;
}

#let-me-in a.is-hidden {
    visibility: hidden;
    opacity: 0;
}

#demo-error {
    color: @rose;
    font-size: @big;

    &:hover {
        text-decoration: underline;
    }
}

body.loading {
    #page-content {
        background-color: @off-white-bg;
    }
}
