.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 0;
    max-width: 100%;
}

.left-container {
    margin-right: 10px;
    min-width: 18px;
}

.avatar-container {
    display: flex;
    flex-shrink: 0;
    margin: 2px 0;
}

.type-icon {
    width: 32px;
    display: flex;
    justify-content: center;
    fill: var(--rose);
}

.name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
    font-size: var(--font-normal);
    line-height: 20px;
    color: var(--grey-dk);
    margin-bottom: 1px;
    font-weight: 500;
    max-width: 100%;
}

.participants-container {
    line-height: 21px;
    font-size: var(--font-small);
    min-width: 0;
    width: 100%;
}

.participants-row {
    display: flex;
    align-items: center;
}

.participant {
    margin-right: 7px;
    display: inline-block;
    min-width: 0;
}

.participant > * {
    display: inline;
}

.description {
    font-size: var(--font-small);
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
}
