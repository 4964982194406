.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    text-align: left;
    border-radius: 3px;
    transform: translateX(-8px);
}

.container:hover {
    background-color: var(--off-white);
}

.form-body {
    width: 250px;
    overflow-y: auto;
}

.form-body ul {
    height: auto;
    max-height: 300px;
}
