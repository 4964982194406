.merge-items-modal-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    padding-bottom: 32px;
    box-sizing: border-box;
}

.heading > :first-child {
    margin-bottom: 8px;
}

.heading > :last-child {
    margin-bottom: 0;
}

.items-container {
    height: 100%;
    overflow-y: auto;
    display: flex;
    align-items: center;
}

.items-container > :first-child {
    width: 100%;
}
