.message-performance-list-item {
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: var(--white);
}

.left-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image-container {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    overflow: hidden;
    margin-right: 32px;
    width: 200px;
    height: 175px;
}

.image-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.message-info {
    width: 100%;
    box-sizing: border-box;
    padding-right: 16px;
}

.stats-container {
    display: flex;
    margin-top: 16px;
}

.stats-container > div {
    margin-right: 32px;
}

.right-container {
    padding-left: 16px;
    border-left: 1px solid var(--off-white-dk);
    height: 100%;
}

.message-performance-bars {
    margin: 16px 0;
}

.message-performance-bars--draft {
    composes: message-performance-bars;
}

.message-performance-bars--draft * {
    color: var(--off-white-dk);
    cursor: default;
}
