.error-state-small {
    padding: 12px;
    font-size: var(--font-normal);
    display: flex;
    align-items: center;
}

/* The plug error icon container */
.error-state-small > :first-child {
    width: 80px;
    margin-right: 16px;
}

.subtitle {
    font-size: var(--font-small);
    margin-top: 4px;
}

.subtitle button {
    padding: 0;
}
