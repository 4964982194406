.container {
    padding: 8px 16px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    z-index: 10;
    min-height: 46px;
    border-radius: 8px 0 0 0;
}

.title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    user-select: none;
    text-align: center;
}

.title {
    color: var(--grey-dk);
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
}

.subtitle {
    font-size: var(--font-small);
    line-height: 19px;
    color: var(--grey-lt);
    display: flex;
    justify-content: center;
}

.left {
    width: 400px;
    display: flex;
    justify-content: flex-start;
}

.right {
    width: 400px;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1300px) {
    .right,
    .left {
        width: 230px;
    }
}
