.status-text {
    color: var(--grey-dk);
    font-size: var(--font-normal);
    margin-bottom: 2px;
}

.secondary-text {
    color: var(--grey-lt);
    font-size: var(--font-small);
}
