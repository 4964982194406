.container {
    position: relative;
    background-color: var(--white);
    padding: 12px 16px;
    border-radius: 3px;
}

.option-container {
    width: 300px;
    display: flex;
    align-items: center;
}

.option-container > *:first-child {
    margin-right: 8px;
}
