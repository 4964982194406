.table-container {
    border-left: 1px solid var(--off-white-dk);
    border-right: 1px solid var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow-y: auto;
}

.table-container--with-more {
    composes: table-container;
    border-bottom: none;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}

.title {
    composes: title from '../../../../client_modules/shells/react/components/table/legacy-setup-table-wrapper.css';
}

.count {
    composes: count from '../../../../client_modules/shells/react/components/table/legacy-setup-table-wrapper.css';
}

.description {
    font-weight: 400;
}

.help-button {
    composes: help-button from '../../../../client_modules/shells/react/components/table/legacy-setup-table-wrapper.css';
}

.help-button > *:first-child {
    padding: 0 0 0 6px;
}

/* Make 'see more' button container look like part of the table */
.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    border-left: 1px solid var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
    border-right: 1px solid var(--off-white-dk);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
}

.button-container > *:first-child {
    height: 100%;
}
