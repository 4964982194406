.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% + 16px);
    margin-left: -16px;
}

.container--no-templates {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-left: unset;
}

.form-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 425px;
    border: 1px solid var(--off-white-dk);
    height: 100%;
    box-sizing: border-box;
}

.container-horizontal {
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
    min-height: 0; /* For firefox */
}

.form-body {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
}

.create-template {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}
