.wrapper--editable {
    opacity: 0.6;
    display: flex;
    justify-content: space-between;
}

.container {
    display: flex;
    height: 16px;
    gap: 48px;
}
