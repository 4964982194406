.unsubscribed-pill-label {
    color: var(--grey);
}

.audience-info-outer-container {
    position: relative;
}

.audience-info-inner-container {
    position: absolute;
    right: 0;
    /* This value just work */
    top: -38px;
}

.support-resubscribe-link {
    display: block;
    margin-top: 16px;
}
