@-webkit-keyframes fadeIn {
	from { opacity: 0; }
	  to { opacity: 1; }
}
@keyframes fadeIn {
	from { opacity: 0; }
	  to { opacity: 1; }
}

@-webkit-keyframes fadeOut {
	from { opacity: 1; }
	  to { opacity: 0; }
}
@keyframes fadeOut {
	from { opacity: 1; }
	  to { opacity: 0; }
}
