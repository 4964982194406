.container {
    margin: 32px;
}

.empty-text {
    max-width: 720px;
    margin-bottom: 32px;
}

.empty-text > *:first-child {
    margin-bottom: 8px;
}

.details-container {
    width: 100%;
    margin-bottom: 32px;
}

.details-container--with-text {
    composes: details-container;
}

.details-container--with-text > *:first-child {
    display: flex;
    justify-content: flex-end;
    flex-basis: calc(50% - 16px);
    margin-right: 32px;
}

.details-container--with-text > *:last-child {
    flex-basis: calc(50% - 16px);
}

.details-container ul {
    max-width: 360px;
}

.action-container {
    width: 100%;
}

.action-container > :nth-child(2) {
    display: block;
    margin: 0 auto;
}

.cta-container {
    margin-bottom: 16px;
}

.cta-container--two-column {
    composes: cta-container;
}

.cta-container--two-column > *:first-child {
    display: flex;
    justify-content: flex-end;
    flex-basis: calc(50% - 16px);
    margin-right: 32px;
}

.cta-container--two-column > *:last-child {
    flex-basis: calc(50% - 16px);
}
