.industries-wrapper {
    display: flex;
    position: relative;
    gap: 32px;
    padding: 32px;
    align-items: center;
    min-height: 300px;
}

.donut-chart-container {
    display: flex;
    flex: 25%;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
}

.table-container {
    flex: 75%;
    border: 1px solid var(--off-white-dk);
    width: 100%;
}

.table-container > *:first-child {
    width: 100%; /* override table style's width: fit-content */
}

.tooltip-container {
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    color: var(--white);
    max-width: 150px;
}

.tooltip-container > :first-child {
    margin-bottom: 4px;
}

.pie-slice {
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .industries-wrapper {
        flex-flow: column-reverse;
    }
}
