.container {
    flex-grow: 1;
}

.header-container {
    display: flex;
}

.subheader-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    box-sizing: border-box;
}

.subheader-container > * {
    padding-right: 32px;
    margin-bottom: 16px;
    min-width: 150px;
}

/* Opened by is not editable, so we're ok if it's smaller
because it won't grow once the lead is created */
.subheader-container > :first-child {
    min-width: 100px;
}

/* Confidence is the 4th child, and it's always going to be this small */
.subheader-container > :nth-child(4) {
    min-width: 100px;
}

.title-container {
    margin: 0 16px;
    width: 100%;
    min-height: 61px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name-container {
    display: flex;
    font-size: var(--font-huge);
    font-weight: 500;
    line-height: 29px;
}

.name-container > * {
    flex-grow: 1;
}

.name-container--deleted {
    composes: name-container;
}

.name-container--deleted button {
    text-decoration: line-through;
}

.name-container--deleted > * {
    /* We want our deleted text to float next to our name */
    flex-grow: 0;
}

.deleted-text {
    /* Keeps the text from wrapping */
    display: flex;
    min-width: 140px;

    padding-left: 8px;
    color: var(--rose);
    font-size: var(--font-small);
    font-weight: normal;

    /* Aligns with baseline of name text, works with wrapping too */
    line-height: 15px;
    align-self: flex-start;
    margin-top: 11px;
}

.tag-container {
    border-bottom: 1px solid transparent;
}

.tag-container:hover {
    border-bottom: 1px solid var(--off-white-dk);
}
