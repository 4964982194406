.email-container {
	main& {
		padding: 16px;
	}
	label {
		position: relative;
		float: left;
		clear: both;
		cursor: pointer;
		margin-bottom: 20px;
		font-size: @big;
		margin-right: 15px;
		color: @grey;
		&:hover { color: @grey-dk; }
	}
	h3 {
		margin-top: 5px;
		margin-bottom: 15px;
	}
	h4 {
		position: relative;
		display: inline-block;
		font-size: @normal;
		letter-spacing: 1px;
		color: @grey-lt;
		margin: 12px 0;
	}
	p { margin: 0; }
	input[type="checkbox"],
	input[type="radio"] { margin-right: 5px; }
	.button-row {
		.content-wrapper {
			position: relative;
			display: inline-block;
		}
		.ui-btn {
			float: left;
			width: 49%;
			margin: 0 0 12px 0;
			padding: 10px;
		}
		.icon-container {
			margin-right: 8px;
			position: absolute;
			top: -4px;
			left: -32px;
		}
		.connect-to-google {
			float: right;
			.icon-container {
				width: 23px;
				height: 23px;
			}
		}
		.connect-to-office {
			.icon-container {
				width: 21px;
				height: 25px;
			}
		}
		.icon-container {
			img {

			}
		}
	}
	.email-row-container {
		border-bottom: 2px solid @off-white-dk;
		margin-top: 12px;
		margin-bottom: 30px;
	}
	.email-row {
		clear: both; // Required for floated buttons above
		overflow: hidden;
		height: 53px;
		position: relative;

		.integration-logo {
			display: none;
			background-size: 100% !important;
			margin: 5px 30px 0 0;
			width: 100px;
			height: 21px;
		}

		&.state-always-expanded {
			height: auto;
			overflow: visible;
			.section-configure { display: none; }
		}

		&.type-google-apps {
			.email-header { background-color: white; }
			.address { background: @green-lt; }
			.integration-logo {
				display: inline-block;
				.2x('@{img-root}/integration/google-apps-no-bg.png', "no-repeat");
			}
		}

		&.type-office365 {

			.email-header { background-color: white; }
			.address { background: @green-lt; }
			.integration-logo {
				display: inline-block;
				.2x('@{img-root}/integration/office365-transparent.png', "no-repeat");
			}
		}

		&.smtp-state-needs-configuration {
			.status.needs-configuration {
				display: inline;
				.highlighter();
			}
		}

		&.smtp-state-verified {
			.status.verified { display: inline; }
			.address { background: @green-lt; }
		}

		&.smtp-state-unverified {
			.status.unverified { display: inline; }
		}
		&.smtp-state-failed {
			.status.failed { display: inline; }
		}
		&.state-expanded {
			svg {
				#util > .transform-180();
			}
		}

		.email-header {
			border-top: 2px solid @off-white-dk;
			font-size: @big;
			padding: 10px 18px;
			height: 32px;
			line-height: 30px;

			.status {
				font-weight: normal;
				display: none;
				font-size: @small;
				padding-left: 5px;
				&.verified { color: @green; }
				&.unverified { color: @orange; }
				&.failed { color: @rose; }
			}

			svg {
				fill: @grey-lt;
				margin: 5px 0 0 5px;
				width: 20px;
				height: 20px;
			}

			a {
				outline: none;
				font-size: @normal;
				line-height: 30px;
			}
		}
		.email-options {
			padding: 20px 20px 35px 20px;

			i {
				margin-left: -1px; //to align with radio buttons
				width: 31px;
				&.fa-clock-o { color: @orange; }
				&.fa-envelope { color: @blue-dk; }
			}

			.row-setting {
				&.state-enabled {
					.state-inactive-container {
						&:after { display: none; }
						opacity: 1;
					}
				}
			}

			.email-sync-last-check {
				margin: 15px 0;
				color: @grey;
				font-size: @big;

				p {
					margin: 10px 0;
					color: @grey-lt;
				}
			}

			.state-inactive-container {
				position: relative;
				padding: 5px 20px 15px 20px;
				opacity: .3;

				&:after { #util > .blocked-content(); }

			}

			.smtp-settings {
				.form-row {
					#util > .border-box;
					float: left;
					width: 50%;
					padding: 0 20px 0 0;
					height: 90px;
				}
				.form-row-submit {
					float: right;
					margin-right: 20px;
					margin-top: -28px;
					#util > .border-box;
				}
				dd, dt { margin: 0; }
				dt {
					font-weight: 500;
					margin-bottom: 2px;
				}
				label { margin-right: 20px; }
				dd.radio-buttons { margin: 8px 0 0 4px; }
				.action-resubmit-smtp-test { display: none; }
			}
		}
	}

	.global-email-setting {
		padding: 0 20px;

		&.signature {
			margin-top: 32px;

			label {
				cursor: auto;
			}

			label:hover {
				color: @grey;
			}
		}
	}

	.type-textarea {
		position: relative;
		textarea {
			#util > .border-box;
			width: 100%;
			resize: vertical;
			margin-top: 8px;
			min-height: 60px;
		}

		&.state-focused.state-enabled {
			.textarea-save { display: block; }
		}

		.textarea-save {
			display: none;
			position: absolute;
			right: 30px;
			bottom: 10px;
		}

		&.blacklist { padding-left: 0; }
		&.signature {
			background-color: white;
			label { margin-bottom: 5px; }
			textarea {
				font-size: @big;
				height: 100px;
				opacity: 0;
			}

			#react-mount-email-signature {
				opacity: 0;
				user-select: none;
				pointer-events: none;
			}

			&.state-enabled {
				textarea {
					opacity: 1;
				}

				#react-mount-email-signature {
					opacity: 1;
					user-select: auto;
					pointer-events: auto;
				}
			}

			input[type="checkbox"] { margin-right: 5px; }
		}
	}

	.icon-help {
		position: absolute;
		right: -35px;
		top: -4px;
	}
}

#myaccount.account-integrations,
#myaccount.account-settings,
#myaccount.account-email,
#myaccount.account-notifications {
	.tab-navigation-container {
		background: @off-white;
		border-bottom: 1px solid @off-white-dk;
		border-radius: 3px 0 0 0;
		padding-top: 16px;
		ul {
			> li {
				margin-left: 8px;
				margin-bottom: -1px;
				display: inline-block;
				background: @off-white-md;
				border: 1px solid @off-white-md;
				border-radius: 3px 3px 0 0;

				> a {
					display: block;
					padding: 10px 16px;
					color: @grey;
					font-size: @big;
					letter-spacing: 0.5px;
					outline: none;

					span {
						color: @grey-lt;
						font-size: @small;
						font-weight: normal;
						line-height: 14px;
						letter-spacing: 0;
						padding-left: 3px;
					}
				}

				&:hover {
					a { color: @grey-dk; }
				}

				&.active {
					font-weight: 500;
					background: white;
					border: 1px solid @off-white-dk;
					border-bottom: 1px solid white;
					a { color: @grey-dk; }
					span { color: @grey; }
				}
				&:first-child {
					margin-left: 16px;
				}
			}
		}
	}
	.tabbed-navigation {
		.tab-pane-container {
			box-sizing: border-box;
			#inbox {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.tab-pane:not(.templates) {
			margin: 16px;
		}

		.tab-pane {
			display: none;
			&.active {
				display: block;
			}
		}
	}
}

.timeline3-list {
	.timeline3-empty-state {
		font-family: @body-font;
		font-weight: 500;
		font-size: @big;
		letter-spacing: 0.5px;
		color: @grey-lt;
		margin: 20px 0;
	}
}

.notify-message {
	margin: 20px;
}
