

.modal-tab-activity {
	.modal-card-header-upblock { // stateful buttons on right in header
		.activity-header-logged {
			display: none; // only displayed for .status-logged
			overflow: hidden;
			padding: 9px 5px;
			div {
				float: left;
				text-align: left;
			}
			.logged-by {
				display: flex;
				flex-direction: column;
				max-width: 100px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: @grey-dk;
			}
			.logged {
				color: @grey-lt;
			}
			.icon {
				margin: 0 8px;
				#icon > .modal-tab-activity-logged;
				float: left;
			}
		}
	}

	.activity-name {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.media-controls {
		.action-play,
		.action-pause {
			float: left;
			width: 18px;
			height: 20px;
			.2x('@{img-root}/media-controls-sprite.png', "no-repeat", 18px, 61px);
			margin-top: 4px;
			margin-right: 18px;
		}

		.media-not-ready { margin-top: 6px; }

		.action-play { display: block; background-position: left top; }
		.action-pause { display: none; background-position: left -20px;}

		.audio-container-wrapper {
			display: flex;
			align-items: center;
			position: relative;
			padding-top: 16px;
			padding-bottom: 12px;
			min-height: 40px;

			.audio-container {
			position: absolute;
			left: 50%;
				transform: translateX(-50%);
			}
			.transcribe-button-container {
				margin-left: auto;
			}
		}
	}

	.activity-header-booking {
		display: flex;
		align-items: center;
		padding-top: 4px;
		.icon {
			margin: 0 4px;
			#icon > .modal-tab-activity-booking;
			margin-left: 0;
		}
		.text {
			color: @grey;
			display: flex;
			div {
				display: flex;
				align-items: center;
			}
			.link {
				color: @blue;
				margin-left: 4px;
				// The following overrides the modal-close styles
				position: unset;
				font-weight: unset;
				font-size: unset;
				line-height: unset;
				text-align: unset;

				&:hover {
					color: @blue-dk;
				}
			}

		}
	}


	.activity-header-followup {
		display: none;
		background: @peach-lt;
		border: 1px solid @off-white-dk;
		#util > .rounded-corners (2px, bottom);
		margin: -16px 0 8px 0;
		font-size: @tiny;

		&:hover { background: white; }

		a {
			display: block;
			padding: 5px 6px 4px;
			#util > .excerpt-crop > .ellipsis;
		}
		span { font-weight: 500; }
	}
	&.state-hasfollowup .activity-header-followup { display: block; }

	.modal-card-body {
		.video-overlay {
			visibility: hidden;
			z-index: 400;
			background: black; /* TODO: standardize color */
			background: rgba(0,0,0,0.8);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			.action-close {
				cursor: pointer;
				width: 19px;
				height: 19px;
				background: url("@{img-root}/media-controls-sprite.png") left bottom;
				position: absolute;
				top: 5px;
				right: 14px;
			}

			.video-container {
				z-index: 401;

				video, object, embed {
					z-index: 402;
					margin: 0 auto;
					display: block;
				}
			}
		}

		.label {
			font-weight: 500;
			&.activity-type-label {
				float: left; // Allows for the select dropdown to be positioned next to the label
			}
		}

		.eip-container {
			cursor: pointer;

			&.type-date {
				margin-bottom: 0;
			}

			&:hover {
				color: @grey-dk;
			}
		}

		.time-boxes {
			&.untimed .label { height: 36px; }
			label {
				display: block;
				line-height: 24px;
			}
		}

		.action-flag {
			font-weight: 500;
			color: @grey-lt;
			cursor: pointer;

			.icon {
				margin: -4px 0 -4px 2px;
				#util > .inline-block;

				#icon > .activity.size-small;
				#icon > .activity.flag-dim;
			}
		}

		.activity-location .action-add-location {
			color: @grey-lt;
			font-style: italic;
		}

		.activity-assignees,
		.activity-description {
			clear: both;
			label {
				float: left;
				width: 100px;
				color: @grey;
				font-weight: 500;
				text-align: right;
			}
			> div {
				margin-left: 108px;
			}
			.following-up {
				margin-bottom: 10px;
				.label {
					float: left;
					padding: 0 4px 0 0;
				}
				.value {
					float: left;
					width: auto;
					line-height: 22px;
				}
				a { text-decoration: underline; }
			}
		}
		.activity-assignees {
			label {
				margin-top: 3px;
				font-size: 11px;
			}
			> div {
				margin-bottom: 10px;
			}
			.capsule-add-container {
				display: none;
				width: 140px;
				z-index: 100;
				top: -6px;
			}
			.capsule-container {
				margin-right: 6px;
			}
			.action-add-participant {
				display: inline-block;
				vertical-align: top;
				margin: 3px 0;
			}
		}
		.activity-leads, .activity-leads-label { display: none }
		.activity-description {
			.eip-container {
				cursor: text;
				&:hover {
					background-color: @yellow-lt;
				}
			}
			.value {
				// override excerpt-crop
				white-space: normal;
				width: 100%;
				line-height: 18px;
			}
			textarea {
				margin-top: 2px !important;
				height: 60px;
				line-height: 18px;
			}
		}
		.activity-log-container {
			display: none;
			margin: -15px -25px 15px -25px;
			.2x('@{img-root}/timeline3/bg-note.png', "repeat", 20px, 20px);
			background-position: 0 -6px;
			line-height: 17px; // make it slightly larger so that mentions don't bleed into one another
			border-bottom: 1px solid @off-white-dk;
			box-shadow: 0 1px 4px @off-white-dk; /* TODO: standardize color */

			.activity-log-note {
				padding: 10px 20px;
				.note-edit-buttons();
				.action-edit {
					top: 12px;
				}
				.action-delete {
					display: none;
				}
			}
			.empty { font-style: italic; }
			.eip-buttons {
				text-align: right;
				overflow: hidden;
				margin-top: 10px;
				.button {
					display: inline-block;
					margin-left: 4px;
				}
			}

			textarea {
				#util > .input-rounded;
				min-height: 60px;
				outline: 0;
				width: 100%;
				resize: vertical;
				overflow: hidden;
			}

			div.mentions {
				margin: 1px;
				padding: 3px;
				outline: 0;
			}

			#summarize-button-mount {
				width: 100%;
				height: fit-content;
				display: flex;
				justify-content: flex-end;
				box-sizing: border-box;
				padding: 0 16px 16px 16px;
			}
		}

		.chat-transcript {
			position: relative;

			.bg-edge {
				.2x('@{img-root}/timeline3/bg-chat-edge.png', "no-repeat repeat");
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 7px;
				background-size: 100% !important;
			}

			margin: 10px -25px 0 -25px;
			border-top: 1px solid @grey-lt;
			text-shadow: none;
			padding-bottom: 5px;
			.2x('@{img-root}/timeline3/bg-chat.png', "repeat", 15px, 15px);

			table {
				td.column-time { padding-right: 30px; }
			}
		}

	}

	&.media-visible,
	&.media-initializing {
		.video-overlay {
			visibility: visible;
		}
	}

	&.media-playing {
		.action-play { display: none; }
		.action-pause { display: block;}
	}

	.action-save {
		display: none;
	}

	&.status-overdue {
		.modal-card-title span { color: @rose; }
	}
	&.status-logged {
		.activity-header-logged {  display: flex; align-items: center;}
		.activity-log-container { display: block; }
		.action-log { display: none; }
	}
	&.state-logged-audio {
		.activity-header-logged .icon { #icon > .modal-tab-activity-logged.type-audio }
	}
	&.state-logged-video {
		.activity-header-logged .icon { #icon > .modal-tab-activity-logged.type-video }
	}
	&.state-hasleads {
		.activity-leads, .activity-leads-label { display: block; }
	}

	&.state-unloggable {

	}
	&.state-transient {
		.action-log,
		.action-followup { display: none; }
	}
	&.state-isallday, &.state-isuntimed {
		.fieldrow.type-time { display: none; }
	}
	&.state-isuntimed {
		.fieldrow.activity-date-to { display: none; }
	}
	&.state-isflagged {
		.action-flag {
			color: black;
			.icon { #icon > .activity.flag-red; }
		}
	}
	&.state-dirty {
		.action-save {
			display: inline-block;
		}
		.action-followup {
			display: none;
		}
	}
	&.state-hasfollowup {
		.action-followup { display: none; }
	}

	&.type-chat {
		.time-boxes,
		.modal-tilearea-right,
		.activity-hr-description,
		.activity-description,
		.activity-log-container {
			display: none;
		}
		.activity-time-to,
		.activity-date-to {
			position: absolute;
			top: 15px;
			left: 280px;
		}
		.activity-time-to {
			top: 37px;
		}
	}

	.button-eip { display: none; }
	.activity-locked-notify { margin-bottom: 2em !important; }
	&.state-iseditable {
		.button-eip { display: block; }
		.activity-locked-notify { display: none; }
	}
}

.browser-ie .modal-card-body .activity-log-container {
	border-top: 1px solid @brown;
	border-bottom: 1px solid @brown;
	.activity-log-note {
		width: 671px;
	}
}

.speech-to-text-button {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 16px;
}

.mentions-input-box {
	padding: 0px !important;
	line-height: normal !important;
	.mentions {
		padding: 0px !important;
		& > div {
			line-height: normal;
			& > strong {
				background: @peach-lt !important;
				border-bottom: 1px solid @peach;
			}
		}
	}
}

span.mention-rendered {
	background: @peach-lt;
}
