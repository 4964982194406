.header {
    margin-bottom: 16px;
}

.header > *:first-child {
    align-items: baseline;
}

.title {
    color: var(--grey-dk);
    font-weight: 500;
    font-size: var(--font-normal);
    line-height: 19px;
    margin-bottom: 4px;
}

.subtitle {
    color: var(--grey-lt);
    font-size: var(--font-small);
    line-height: 17px;
}
