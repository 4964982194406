@import "../../../../include";
@import "../../../global/_colors";

.invoices-wrapper {
    font-size: @normal;
    // On super big screens add a max-width so reading the table remains easy
    @media (min-width: 1700px) {
        max-width: 880px; // same as the my plan and payment detail tabs
    }
    &.state-is-loading {
        > div {
            text-align: center;
            margin: 16px;
        }
    }
    tr {
        &:nth-child(odd) {
            background: @off-white;
        }
        &:first-child {
            font-weight: 500;
            color: @grey-dk;
            background: none;
            td {
                border-bottom: 2px solid @off-white-bg;
            }
        }
    }
    td {
        padding: 8px;
        &:first-child {
            padding-left: 16px;
        }
    }
    a {
        text-decoration: underline;
        color: @blue;
        &:hover {
            color: @blue-dk;
        }
    }
}
