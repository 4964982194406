.list-dot-sequence {
    display: flex;
    position: relative;
    overflow: hidden;
}

.bar {
    width: 16px;
}

.list-dot-sequence ol {
    position: absolute;
    left: 0;
    width: 100%;
}

.list-dot-sequence li {
    display: flex;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
}

.list-dot-sequence li:not(:first-child) {
    margin-top: 16px;
}

.list-dot-sequence li button {
    width: 100%;
    text-align: left;
}

.selection-dot {
    position: absolute;
    width: 16px;
    height: 16px;
    /* This allows hover events to pass through to the remove svg */
    pointer-events: none;
}
