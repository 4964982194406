.container {
    display: grid;
    grid-template-columns: auto 40%;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.left {
    background-color: var(--plum-xlt);
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-message-style {
    white-space: pre-line;
}
