.dashboardable {
	position: relative;
	padding: 6px 12px 6px 40px;
	border-bottom: 1px solid @off-white-dk;

	&:after {
		content: ".";
		display: block;
		height: 0;
		clear: both;
		visibility: hidden;
	}

	.icon-main {
		position: absolute;
		top: 8px;
		left: 8px;
		width: 22px;
		height: 26px;

		&.dashboardable-checkbox {
			cursor: pointer;
			.2x('@{img-root}/process-step-status-sprite.png', "no-repeat", 22px, 234px);

			.loading-spinner {
				width: 16px;
				height: 16px;
				margin: 5px 0 0 3px;
				background: url("@{img-root}/process-status-loading-spinner.gif") no-repeat;
				#util > .opacity(0);
			}
		}
	}

	div.top-row {
		min-height: 24px; // for when in EIP mode
		margin-top: 3px;

		h1.name {
			display: inline;
			font-size: 15px;
			line-height: 24px;
			min-height: 24px;

			.value {
				display: block;
				max-width: 90%;
				&:hover {
					cursor: text;
					background: @peach-lt;
				}
			}

			input,
			.placeholder_overlay {
				font-weight: 500;
				width: 478px; // @todo unify this width w/ description text image
				#util > .rounded-corners(3px);
				margin-left: -6px !important; // move back so there's no jump when moving from read-only to editing
				padding: 2px 6px !important;
				float: left;
			}
			.placeholder_wrapper.state-focus {
				span.placeholder_overlay { opacity: 0.75; }
			}
			&.state-editing {
				display: block;
				.value {
					background: @peach-lt;
				}
			}

		}
		.eip-buttons {
			float: right;
			.button-interaction {
				margin-left: 4px;
			}
		}
	}

	.date {
		float: left;
		line-height: 25px; // to leave room for buttons
		margin-bottom: 5px;
	}
	.meta {
		color: @grey-lt;
		font-size: 12px;
		line-height: 12px;
		float: right;
		margin-bottom: 3px;

		.lead-description {
			font-size: 11px;
			line-height: 12px;
			margin-top: 1px;
			padding-left: 2px;
			color: @grey;
		}

		a.entity-relationship {
			float: left;
			padding-right: 10px;
		}
	}
	.clearer.pre-detail {
		clear: both;
		margin-top: 12px;
	}

	.dashboardable-inset {
		position: relative;
		padding: 3px;
		margin-top: 10px;
		margin-bottom: 5px;
		background-color: @off-white-md;
		border: 2px solid @off-white-md;
		border-radius: 3px;
		.icon.flipdown {
			cursor: pointer;
			position: absolute;
			top: 4px;
			left: 4px;
			z-index: 2;
		}
		textarea {
			width: 100%;
		}
		.placeholder_wrapper {
			width: 100%;
		}
		.eip-buttons {
			text-align: right;
			.button {
				margin-left: 4px;
			}
		}
		&.behavior-openable {
			padding: 3px 3px 3px 28px;
		}
		&.behavior-openable.state-closed {
			cursor: pointer;
			&:hover {
				color: @grey-dk;
				background-color: @off-white-dk;
				border: 2px solid @off-white-dk;
				.icon.flipdown:before {
					color: @grey-dk;
				}
			}
		}
		.icon.flipdown {
			&:before {
				// fa-chevron-up
				content: "\f077";
				font-family: FontAwesome;
				font-size: 12px;
				width: 26px;
				height: 20px;
				position: absolute;
				top: 0;
				left: 3px;
				color: @grey;
			}
			&:hover:before {
				color: @grey-dk;
			}
		}
		&.state-open {
			background-color: white;
			.closed { display: none; }
			.open { display: block;}
			.label {
				cursor: default;
				span.mode-read {
					font-size: @tiny;
					font-weight: 500;
					display: block;
				}
			}
			div.value {
				margin: 5px 0;
				&:hover {
					cursor: text;
					background: @yellow-lt;
				}
			}
		}
		&.state-closed {
			.closed { display: block; }
			.open { display: none; }
			.icon.flipdown:before {
				// fa-chevron-down
				content: "\f078";
			}
		}
	}
	.buttons-wrapper {
		float: right;
	}
	.button-custom {
		color: @grey-lt;
		cursor: pointer;
		&:hover {
			color: @grey;
		}
		.icon {
			display: inline-block;
			vertical-align: top;
			margin: 1px 1px 0 8px;
		}
	}
}
/* ================= */
/* = process steps and tasks = */
/* ================= */

.dashboardable-list .step,
.dashboardable-list .task {
	z-index: 0;
	min-height: 40px;

	a.action-skip {
		display: block;
		position: absolute;
		left: 10px;
		top: 35px;
		color: @rose;
		font-size: 10px;
	}

	.capsule-add-container {
		float: right;
		clear: right;
	}
	.capsule-add-container {
		display: none;
		width: 150px;
		.icon {
			#icon > .button-assign;
		}
	}
	.action-delay .icon {
		&:before {
			// fa-clock-o
			content: "\f017";
		    font-family: FontAwesome;
		}
	}
	.action-assign .icon { #icon > .button-assign }
	.action-assign:active .icon { #icon > .button-assign-active }
	.assigned {
		width:200px;
		float: right;
		clear: both;
	}
	.delays {
		font-size: 11px;
		line-height: 16px;
		color: @grey-lt;
	}

	.button-custom {
	} // stateful

	.button-custom {
		line-height: 22px;
	}

	@inset-leftcol-width: 210px;
	@inset-rightcol-width: 400px; // probably a way to avoid this

	.dashboardable-inset {
		max-height: 100px;
		overflow-y: scroll;

		.vertical-rule {
			display: none;
			position: absolute;
			left: @inset-leftcol-width - 2;
			height: 100%;
			z-index: 100;
			width: 0;
			border-left: 2px solid @off-white-md;
		}

		.picker {
			.icon.action-choose {
				width: 26px;
				height: 20px;
				background: url("@{img-root}/buttons/choose-sprite.png") no-repeat left top;
				&:hover {
					cursor: pointer;
					background-position: 0 -20px;
				}
			}
			.state-chosen .action-choose {
				background-position: 0 -40px;
			}
		}

		.entity-field-picker {
			.col-left li {
				color: @grey;
				padding-left: 10px;
				line-height: 20px;
				font-weight: 500;
				cursor: pointer;
				height: 20px;
				&:hover,
				&.state-selected {
					background: @yellow-lt;
					color: @grey-dk;
				}

				.name {
					#util > .inline-block;
					width: 80%;
					#util > .excerpt-crop > .ellipsis;
				}

				.icon {
					margin-right: 8px;
					margin-top: 1px;
					vertical-align: top;
					opacity: .5;
					#util > .inline-block;
					#icon > .entitypicker-modal;
					&:hover { opacity: 1; }
				}
			}
			.col-right > li {
				display: none;
				&.state-selected { display: block; }
				.fieldrow {
					clear: both;
				}
				.label,
				.value {
					font-size: @small;
					line-height: 20px;
					float: left;
					margin: 0 0 5px 0;
					padding: 0;
					&:hover {
						background: none;
					}
				}
				.label {
					width: 25%;
				}
				.value {
					width: 75%;
				}
			}
		}

		ul.entity-picker,
		ul.enum-picker {
			li {
				cursor: pointer;
				line-height: 20px;
				margin: 3px 0;

				.action-choose {
					float: left;
					margin: 0 10px;
				}
				.name {
					font-weight: 500;
				}
				.subname {
					position: absolute;
					left: 240px;
				}

				&:hover,
				&.state-chosen {
					background-color: @off-white-dk;
				}
			}
		}

		.fieldblock {
			.fieldrow {
				line-height: inherit;
				min-height: 0;
				margin: 0;
				.value {
					width: 100%;
				}
			}
		}

		&.variant-twocol.state-open {
			padding: 0;

			.vertical-rule { display: block; }

			.col-left,
			.col-right {
				padding: 5px 0;
			}

			.col-left {
				width: @inset-leftcol-width - 20px; // Minus the margin-left below
				float: left;
				margin-left: 20px;
			}
			.col-right {
				width: @inset-rightcol-width;
				#util > .inline-block;
				margin-left: 20px;
			}
		}
		&.state-satisfied {
			padding-left: 28px;
			.icon.indicator-satisfied {
				&:before {
					// fa-check-square
					content: "\f14a";
					font-family: FontAwesome;
					font-size: 20px;
					width: 26px;
					height: 20px;
					position: absolute;
				    top: 4px;
					left: 4px;
					color: @green;
				}
			}
		}
	}

	&.status-assigned,
	&.status-assigning {
		.action-assign { display: none !important; }
		.assigned, .action-reassign { visibility: visible; }
	}
	&.status-assigning {
		div.assigned { display: none; }
		z-index: 51;
	}
}

.dashboardable-list .step.state-popover-opened {
	.action-delay {
		color: @grey;
	}
}
// step statuses
.state-active .dashboardable-list {
	.step.status-completed {
		cursor: default;
		color: @grey-lt;
		background-color: @green-bg;
		.icon.dashboardable-checkbox {
			background-position: 0 -78px;
			&:hover { background-position: 0 -26px; }
		}
		.action-skip { visibility: hidden; }
	}
	.step.status-workable {
		.action-assign, .action-delay, .action-skip { visibility: visible; }
		&.state-loading .icon.dashboardable-checkbox .loading-spinner { #util > .opacity(1); }
		&.state-loading .icon.dashboardable-checkbox {
			background-position: 0 -104px !important;
		}

		.icon.dashboardable-checkbox {
			background-position: 0 0;
			&:hover { background-position: 0 -26px; }
			&:active { background-position: 0 -52px; }
		}
	}
	.step.status-unworkable {
		.icon.dashboardable-checkbox { background-position: 0 -130px; cursor: default; }
		.action-skip { visibility: hidden; }
	}
	.step.status-assigned,
	.step.status-assigning {
		.action-assign { display: none !important; }
		.assigned, .action-reassign { visibility: visible; }
	}

	.step.status-assigning {
		div.assigned { display: none; };
		z-index: 51;
	}
	.step.status-overdue {
		> .date { color: @rose; }
		&.state-loading .icon.dashboardable-checkbox .loading-spinner { #util > .opacity(1); }
		&.state-loading:hover .icon.dashboardable-checkbox { background-position: 0 -175px; }
		&.state-loading:active .icon.dashboardable-checkbox { background-position: 0 -175px; }

		.icon.dashboardable-checkbox {
			background-position: 0 -130px;
			&:hover { background-position: 0 -156px; }
			&:active { background-position: 0 -52px; }
		}
	}
	.step.status-skipped {
		opacity: .60;
		.icon.dashboardable-checkbox {
			background-position: 0 -182px;
			&:hover { background-position: 0 -26px; }
			&:active { background-position: 0 -52px; }
		}
		h1.name { text-decoration: line-through;}
		.action-skip { visibility: hidden; }
		.date {
			font-weight: 500;
			font-style: italic;
			color: @grey;
			.completer {
				font-weight: normal;
				font-style: normal;
				color: @grey;
			}
		}
	}
}

/* ============== */
/* = activities = */
/* ============== */
.browser-ie li.activity {
	background: url("@{img-root}/dashboard-activity-background-ie.png") repeat-x !important;
}

ul.dashboardable-list li.activity {
	&.status-overdue {
		.date { color: @rose; }
	}
	&.state-flagged {
		.icon-main {
			left: 16px;
			top: 10px;
			background-position: -376px -16px !important;
		}
	}
}

ul.dashboardable-list li.activity .icon-main {
	#icon > .activity.size-medium;
	#util > .inline-block;
	left: 15px;
	top: 8px;
}
ul.dashboardable-list li.type-default .icon-main { #icon > .activity.type-default; }
ul.dashboardable-list li.type-meeting .icon-main { #icon > .activity.type-meeting; }
ul.dashboardable-list li.type-virtual .icon-main { #icon > .activity.type-virtual; }
ul.dashboardable-list li.type-phone .icon-main { #icon > .activity.type-phone; }
ul.dashboardable-list li.type-chat .icon-main { #icon > .activity.type-chat; }
ul.dashboardable-list li.type-default.status-overdue .icon-main { #icon > .activity.type-default.status-overdue; }
ul.dashboardable-list li.type-meeting.status-overdue .icon-main { #icon > .activity.type-meeting.status-overdue; }
ul.dashboardable-list li.type-virtual.status-overdue .icon-main { #icon > .activity.type-virtual.status-overdue; }
ul.dashboardable-list li.type-phone.status-overdue .icon-main { #icon > .activity.type-phone.status-overdue; }
