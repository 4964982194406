@value loading-state-overlay-z-index from '../../client_modules/shells/react/components/list-view/list-view.css';

.sidebar-calendar {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.calendar-dates {
    padding: 0 8px;
}

.controls {
    margin: 16px 0 4px 0;
    padding: 0 16px;
}

.day-of-week-bar-container {
    background-color: var(--off-white);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 4px;
    padding-bottom: 4px;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-date {
    display: flex;
    flex-grow: 1;
}

.header-controls {
    display: flex;
}

.control {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    margin-left: 8px;
    height: 32px;
    width: 32px;
    font-size: 13px;
    background-color: #fff;
    color: var(--grey-dk);
    transition: all 250ms linear;
    cursor: pointer;
}

.control:hover {
    background-color: var(--off-white-dk);
}

.btn-today {
    border-top: 5px solid var(--blue);
    width: 32px;
    height: 32px;
    border-radius: 3px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    margin-right: 8px;
    box-sizing: border-box;
    transition: all 250ms linear;
    color: var(--grey-dk);
    background-color: #fff;
    cursor: pointer;
}

.btn-today:hover {
    background-color: var(--off-white-dk);
}

.control-left {
    composes: control;
}

.control-right {
    composes: control;
}

.list {
    min-height: 0;
    overflow-y: auto;
    flex-grow: 1;
}

.scheduler-icon {
    margin-right: 8px;
    margin-top: 1px;
}
