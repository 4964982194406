.agents-table-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 8px;
    width: 100%;
    padding: 16px;
    overflow: auto;
}

.agent-status-button:hover {
    background-color: var(--color-list-hovered);
}
