.footer-bar {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-top: 1px solid var(--off-white-md);
}

.left-container > button {
    margin-left: -16px; /* This gives the button the same spacing as the 'Remove filters' button just below it */
}

.label {
    align-self: center;
    margin-right: 8px;
}

.picker {
    align-self: center;
    margin-right: 24px;
}

.save {
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Custom styles look nice! */
.save button {
    font-size: 15px !important;
    padding: 8px 12px !important;
}

/* Stack dropdowns on small screens */
@media (max-width: 1100px) {
    .footer-bar {
        height: 80px;
    }
    .left-container {
        flex-direction: column;
    }
    .picker {
        margin-right: 8px;
    }
    .left-container > *:last-child {
        margin-top: 4px;
    }
}

@media (max-width: 1200px) {
    .label {
        display: none;
    }
}
