.upgrade-arrow {
    color: var(--blue);
    fill: var(--blue);
    cursor: pointer;
}

.upgrade-arrow:hover,
.upgrade-arrow:focus {
    color: var(--blue-dk);
    fill: var(--blue-dk);
}

.upgrade-arrow-popover {
    width: 200px;
    font-size: var(--font-small);
    padding: 16px;
}
