.summary-header {
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.summary-header-title {
    font-size: var(--font-normal);
    font-weight: 500;
    color: var(--grey-dk);
}

/* adjustment for weird height of remove button */
.summary-header button {
    margin-top: 2px;
}
