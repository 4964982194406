@icon-root: '/include/images/icons';

#icon-util() {
	.square12 { width: 12px; height: 12px; }
	.square14 { width: 14px; height: 14px; }
	.square16 { width: 16px; height: 16px; }
	.square20 { width: 20px; height: 20px; }
	.square24 { width: 24px; height: 24px; }
	.square32 { width: 32px; height: 32px; }
	.square48 { width: 48px; height: 48px; }
}

// iconmaps are not designed to be used as classes, only mixins
#icon() {

	// autocomplete
	.autocomplete-add		{ background-image: url("@{icon-root}/autocomplete/add.png");      #icon-util > .square16 }

	// cards
	.card-note                  { background: url("@{icon-root}/card/icon-sprite.png") -32px -16px; #icon-util > .square16 }
	.card-account-account       { background: url("@{icon-root}/card/icon-sprite.png") -56px -38px; #icon-util > .square24 }
	.card-account-industry      { background: url("@{icon-root}/card/icon-sprite.png") 0px 0px;     #icon-util > .square16 }
	.card-account-link          { background: url("@{icon-root}/card/icon-sprite.png") 0px -16px;   #icon-util > .square16 }
	.card-account-location      { background: url("@{icon-root}/card/icon-sprite.png") 0px -32px;   #icon-util > .square16 }
	.card-account-phone         { background: url("@{icon-root}/card/icon-sprite.png") -32px -48px; #icon-util > .square16 }
	.card-account-star          { background: url("@{icon-root}/card/icon-sprite.png") 0px -48px;   #icon-util > .square16 }

	.card-contact-contact       { background: url("@{icon-root}/card/icon-sprite.png") -16px 0px;   #icon-util > .square16 }
	.card-contact-email         { background: url("@{icon-root}/card/icon-sprite.png") -16px -16px; #icon-util > .square16 }
	.card-contact-jobtitle      { background: url("@{icon-root}/card/icon-sprite.png") -16px -32px; #icon-util > .square16 }
	.card-contact-phone         { background: url("@{icon-root}/card/icon-sprite.png") -16px -48px; #icon-util > .square16 }

	.card-product-product       { background: url("@{icon-root}/card/icon-sprite.png") -32px 0px;   #icon-util > .square16 }
	.card-competitor-competitor { background: url("@{icon-root}/card/icon-sprite.png") -48px -1px;   #icon-util > .square16 }
	.card-lead-account          { background: url("@{icon-root}/card/icon-sprite.png") -64px 0px;   #icon-util > .square16 }

	.card-account-deleted       { background: url("@{icon-root}/card/icon-sprite.png") -56px -64px;   #icon-util > .square24 }
	.card-contact-deleted       { background: url("@{icon-root}/card/icon-sprite.png") -16px -64px;   #icon-util > .square16 }
	.card-product-deleted       { background: url("@{icon-root}/card/icon-sprite.png") -31px -65px;   #icon-util > .square16 }
	.card-competitor-deleted    { background: url("@{icon-root}/card/icon-sprite.png") -48px -17px;   #icon-util > .square16 }



	// box-headers == sidebar boxes, etc.
	.box-header-accounts    { background: url("@{icon-root}/box-header/icon-sprite.png") left 0px;    #icon-util > .square16 }
	.box-header-activities  {
		.2x('@{icon-root}/box-header/icon-sprite.png', "no-repeat", 16px, 176px);
		background-position: 0 -16px;
		#icon-util > .square16;
	}
	.box-header-assignment  {
		.2x('@{icon-root}/box-header/icon-sprite.png', "no-repeat", 16px, 176px);
		background-position: 0 -32px;
		#icon-util > .square16;
	}
	.box-header-competitors {
		.2x('@{icon-root}/box-header/icon-sprite.png', "no-repeat", 16px, 176px);
		background-position: 0 -48px;
		#icon-util > .square16;
	}
	.box-header-contacts    { background: url("@{icon-root}/box-header/icon-sprite.png") left -64px;  #icon-util > .square16 }
	.box-header-files       {
		.2x('@{icon-root}/box-header/icon-sprite.png', "no-repeat", 16px, 176px);
		background-position: 0 -80px;
		#icon-util > .square16;
	}
	.box-header-notes       { background: url("@{icon-root}/box-header/icon-sprite.png") left -96px;  #icon-util > .square16 }
	.box-header-products {
		.2x('@{icon-root}/box-header/icon-sprite.png', "no-repeat", 16px, 176px);
		background-position: 0 -112px;
		#icon-util > .square16;
	}
	.box-header-reports     { background: url("@{icon-root}/box-header/icon-sprite.png") left -128px; #icon-util > .square16 }
	.box-header-sources     {
		.2x('@{icon-root}/box-header/icon-sprite.png', "no-repeat", 16px, 176px);
		background-position: 0 -144px;
		#icon-util > .square16;
	}
	.box-header-flagged     {
		.2x('@{icon-root}/box-header/icon-sprite.png', "no-repeat", 16px, 176px);
		background-position: 0 -160px;
		#icon-util > .square16;
	}

	.box-header-edit,
	.box-header-delete {
		.2x('@{icon-root}/box-header/edit-delete-sprite.png', "no-repeat", 40px, 20px);
		width: 18px; height: 18px;
		background-position: 0 -2px;
		opacity: 0.5;
		&.state-active,
		&:hover  {
			opacity: 1;
		}
	}
	.box-header-delete      {
		background-position: 100% -2px;
		&:hover {
			background: none;
			.2x('@{icon-root}/box-header/edit-delete-sprite.png', "no-repeat", 40px, 20px);
			background-position: 100% -2px;
			box-shadow: none;
			border: none;
		}
	}

	// capsules (most are sprited, these are borrowed from cards)
	.capsule-type-account    { background: url("@{icon-root}/card/icon-sprite.png") -64px 0px;		#icon-util > .square16 }
	.capsule-type-product    { background: url("@{icon-root}/card/icon-sprite.png") -32px 0px;		#icon-util > .square16 }
	.capsule-type-competitor { background: url("@{icon-root}/card/icon-sprite.png") -48px -1px;		#icon-util > .square16 }
	.capsule-type-contact    { /* background: url("@{icon-root}/card/icon-sprite.png") -48px -1px; */		#icon-util > .square16 }
	.capsule-type-team       { /* background: url("@{icon-root}/card/icon-sprite.png") -48px -1px; */		#icon-util > .square16 }
	.capsule-type-assignment { /* background: url("@{icon-root}/card/icon-sprite.png") -48px -1px; */		#icon-util > .square16 }

	// buttons
	.button-assign,
	.button-assign-active {
		#icon-util > .square16;
		.2x('@{icon-root}/buttons/assign-sprite.png', "no-repeat", 16px, 32px);
		background-position: 0 0;
	}
	.button-assign-active { background-position: 0 -16px; }
	.button-log           { background-image: url("@{icon-root}/buttons/log.png");            #icon-util > .square16 }
	.button-log-disabled  { background-image: url("@{icon-root}/buttons/log-disabled.png");   #icon-util > .square16 }
	.button-delete        { background-image: url("@{icon-root}/buttons/delete.png");         #icon-util > .square16 }
	.button-report        { background-image: url("@{icon-root}/buttons/report.png");         #icon-util > .square16 }

	// specific modal tabs
	.modal-tab-activity-logged {
		background-image: url("@{icon-root}/pen-icon.svg");
		width: 20px;
		height: 20px;
	}
	.modal-tab-activity-logged.type-audio { background-position: 50% 50%; }
	.modal-tab-activity-logged.type-video { background-position: 50% 100%; }

	.modal-tab-activity-booking {
		background-image: url("@{icon-root}/scheduler-icon.svg");
		width: 14px;
		height: 14px;
		background-position: 0 0;
		color: @grey;
	}

	// all modals
	.modal-field-primary            { background: url("@{icon-root}/modals/field-primary-sprite.png") left   0px; opacity:  1;  #icon-util > .square12 }
	.modal-field-primary-set        { background: url("@{icon-root}/modals/field-primary-sprite.png") left -12px; opacity: .8;  #icon-util > .square12 }
	.modal-field-primary-set-hover  { background: url("@{icon-root}/modals/field-primary-sprite.png") left -12px; opacity:  1;  #icon-util > .square12 }
	.modal-field-primary-set-active { background: url("@{icon-root}/modals/field-primary-sprite.png") left -24px; opacity:  1;  #icon-util > .square12 }

	.modal-field-warning            { background: url("@{icon-root}/modals/field-primary-sprite.png") left   -36px; opacity:  1;  #icon-util > .square12 }

	.modal-name-edit {
		background: url('@{icon-root}/modals/edit-delete-sprite.png');
		background-size: 32px 16px;
		background-repeat: no-repeat;
		background-position: left top;
		#icon-util > .square20;
		opacity: 0.6;
	}
	.modal-name-delete      {
		background: url('@{icon-root}/modals/edit-delete-sprite.png');
		background-size: 40px 20px;
		background-repeat: no-repeat;
		background-position: right top;
		#icon-util > .square20;
		opacity: 0.6;
	}

	.modal-name-edit.state-hover    { opacity: 0.8; }
	.modal-name-edit.state-active   { opacity: 1; }
	.modal-name-delete.state-hover  { opacity: 0.8; }
	.modal-name-delete.state-active { opacity: 1; }

	.modal-card-remove	{ background-image: url("@{img-root}/icons/modals/card-remove.png");			#icon-util > .square16 }
	.modal-email-sent		{ background-image: url("@{img-root}/icons/modals/email/sent.png");		#icon-util > .square16 }
	.modal-email-received	{ background-image: url("@{img-root}/icons/modals/email/received.png");	#icon-util > .square16 }
	.modal-email-next		{ background-image: url("@{img-root}/icons/modals/email/next.png");		#icon-util > .square16 }
	.modal-email-previous	{ background-image: url("@{img-root}/icons/modals/email/previous.png");	#icon-util > .square16 }
	.modal-email-sent-active		{ background-image: url("@{img-root}/icons/modals/email/sent-active.png");		#icon-util > .square16 }
	.modal-email-received-active	{ background-image: url("@{img-root}/icons/modals/email/received-active.png");	#icon-util > .square16 }
	.modal-email-delete { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -22px -2px; opacity: .5; #icon-util > .square16  }
	.modal-email-delete-hover { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -22px -22px; opacity: .5; #icon-util > .square16  }
	.modal-email-delete-active { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -22px -42px; opacity: .5; #icon-util > .square16  }

	// avatars
	.avatar-account    { background-image: url("@{icon-root}/avatars/account.png");        #icon-util > .square48 }
	.avatar-contact    { background-image: url("@{icon-root}/avatars/contact.png");        #icon-util > .square48 }
	.avatar-activities { background-image: url("@{icon-root}/avatars/activities.png");     #icon-util > .square48 }
	.avatar-assignee   {
		.2x('@{icon-root}/avatars/assignee.png', "no-repeat", 48px, 48px);
	}
	.avatar-competitor { background-image: url("@{icon-root}/avatars/competitor.png");     #icon-util > .square48 }
	.avatar-email      { background-image: url("@{icon-root}/avatars/email.png");          #icon-util > .square48 }
	.avatar-lead       { background-image: url("@{icon-root}/avatars/lead.png");           #icon-util > .square48 }
	.avatar-source     { background-image: url("@{icon-root}/avatars/source.png");         #icon-util > .square48 }
	.avatar-product    { background-image: url("@{icon-root}/avatars/product.png");        #icon-util > .square48 }
	.avatar-user       { background-image: url("@{icon-root}/avatars/user.png");           #icon-util > .square48 }
	.avatar-team       { background-image: url("@{icon-root}/avatars/team.png");           #icon-util > .square48 }
	.avatar-history    { background-image: url("@{icon-root}/avatars/history.png");        #icon-util > .square48 }

	// forms / edit-in-place
	.eip-undo          { background-image: url("@{icon-root}/forms/eip-undo.png"); opacity: .6; #icon-util > .square16 }
	.eip-undo-hover    { opacity: .8; }
	.eip-undo-active   { opacity: 1; }

	.eip-date          { background-image: url("@{icon-root}/forms/eip-date.png"); opacity: .8; #icon-util > .square14 }
	.eip-date-hover   { background-image: url("@{icon-root}/forms/eip-date.png"); opacity: 1; #icon-util > .square14 }

	// generic
	.generic-lead        { background-image: url("@{icon-root}/generic/lead.png");     #icon-util > .square16 }
	.generic-contact     { background-image: url("@{icon-root}/generic/contact.png");  #icon-util > .square16 }
	.generic-account     { background-image: url("@{icon-root}/generic/account.png");  #icon-util > .square16 }
	.generic-view        { background-image: url("@{icon-root}/generic/view-all.png"); #icon-util > .square16 }
	.generic-new         { background-image: url("@{icon-root}/generic/new.png"); #icon-util > .square16 }

	// filterbar icons
	.filter-bar-notes               { background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px -8px;    #icon-util > .square16 }
	.filter-bar-notes-active        { background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -8px;   #icon-util > .square16 }
	.filter-bar-activities          { background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -40px;  #icon-util > .square16 }
	.filter-bar-activities-active   { background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -40px;  #icon-util > .square16 }
	.filter-bar-emails              { background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -72px;  #icon-util > .square16 }
	.filter-bar-emails-active       { background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -72px;  #icon-util > .square16 }
	.filter-bar-changes             { background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -104px; #icon-util > .square16 }
	.filter-bar-changes-active      { background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -104px; #icon-util > .square16 }
	.filter-bar-contacts            { background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -136px; #icon-util > .square16 }
	.filter-bar-contacts-active     { background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -136px; #icon-util > .square16 }
	.filter-bar-accounts            { background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -168px; #icon-util > .square16 }
	.filter-bar-accounts-active     { background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -168px; #icon-util > .square16 }

	.filter-bar-steps                { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -168px; */ #icon-util > .square16 }
	.filter-bar-steps-active         { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -168px; */ #icon-util > .square16 }
	.filter-bar-sent                 { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -168px; */ #icon-util > .square16 }
	.filter-bar-sent-active          { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -168px; */ #icon-util > .square16 }
	.filter-bar-received             { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -168px; */ #icon-util > .square16 }
	.filter-bar-received-active      { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -168px; */ #icon-util > .square16 }
	.filter-bar-logged               { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -168px; */ #icon-util > .square16 }
	.filter-bar-logged-active        { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -168px; */ #icon-util > .square16 }
	.filter-bar-scheduled            { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -8px  -168px; */ #icon-util > .square16 }
	.filter-bar-scheduled-active     { /* background: url("@{icon-root}/filter-bar/icon-sprite.png") -40px -168px; */ #icon-util > .square16 }

	.filter-bar-nonmodal-notes      { background: url("@{icon-root}/filter-bar/icon-sprite.png") -72px -8px;   #icon-util > .square16 }
	.filter-bar-nonmodal-activities { background: url("@{icon-root}/filter-bar/icon-sprite.png") -72px -40px;  #icon-util > .square16 }
	.filter-bar-nonmodal-emails     { background: url("@{icon-root}/filter-bar/icon-sprite.png") -72px -72px;  #icon-util > .square16 }
	.filter-bar-nonmodal-changes    { background: url("@{icon-root}/filter-bar/icon-sprite.png") -72px -104px; #icon-util > .square16 }

	// reports
	.reports-my-saved { background-image: url("@{icon-root}/reports/my.png"); #icon-util > .square24 }
	.reports-shared { background-image: url("@{icon-root}/reports/team.png"); #icon-util > .square24 }
	.custom-expand { background-image: url("@{icon-root}/reports/custom-expand.png"); #icon-util > .square16 }
	.custom-contract { background-image: url("@{icon-root}/reports/custom-contract.png"); #icon-util > .square16 }
	.custom-cog-menu-orange { background-image: url("@{icon-root}/reports/custom-cog-menu-orange.png"); #icon-util > .square32 }

	// lead homepage
	.lead-nav-team-leads        { background: url("@{icon-root}/lead-homepage/tab-icon-sprite.png") top left; #icon-util > .square24 }
	.lead-nav-team-leads-active { background: url("@{icon-root}/lead-homepage/tab-icon-sprite.png") top right; #icon-util > .square24 }
	.lead-nav-my-leads          { background: url("@{icon-root}/lead-homepage/tab-icon-sprite.png") bottom left; #icon-util > .square24 }
	.lead-nav-my-leads-active   { background: url("@{icon-root}/lead-homepage/tab-icon-sprite.png") bottom right; #icon-util > .square24 }
	.lead-filter-owner          { background-image: url("@{icon-root}/lead-homepage/filter-owner.png"); #icon-util > .square16 }

	// activity icon sprites
	.activity.size-small { // activities modal tab
		background-image: url("@{icon-root}/activities/activity-icons-small-v2.png");
		#icon-util > .square16;
	}
	.activity.size-medium { // modal tab, dashboard
		background-image: url("@{icon-root}/activities/activity-icons-medium-v2.png");
		#icon-util > .square24;
	}
	.activity.size-large { // titles in the modal (both the activity tab and the activity detail view)
		background-image: url("@{icon-root}/activities/activity-icons-large-v2.png");
		#icon-util > .square48;
	}

	.activity.type-default		{ background-position: 0 top; }
	.activity.type-meeting		{ background-position: -100px top; }
	.activity.type-virtual		{ background-position: -200px top; }
	.activity.type-phone		{ background-position: -300px top; }
	.activity.type-chat			{ background-position: -400px top; }

	.activity.flag-black      { background-position: -383px top; opacity: 1; }
	.activity.flag-dim        { background-position: -383px top; opacity: 0.15; }
	.activity.flag-red        { background-position: -383px -16px; opacity: 1; }

	.activity.type-default.status-overdue	{ background-position: 0 bottom; }
	.activity.type-meeting.status-overdue	{ background-position: -100px bottom; }
	.activity.type-virtual.status-overdue	{ background-position: -200px bottom; }
	.activity.type-phone.status-overdue		{ background-position: -300px bottom; }
	.activity.type-chat.status-overdue		{ background-position: -400px bottom; }

	.activity-type-email { background-image: url("@{img-root}/icons/modals/email/received.png");	#icon-util > .square16 }

	// modal activity tab
	.activity-status-overdue	{ background-image: url("@{icon-root}/modals/activity/status-overdue.png");	#icon-util > .square16 }
	.activity-status-logged	{ background-image: url("@{icon-root}/modals/activity/status-logged.png");	#icon-util > .square16 }
	.activity-status-scheduled	{ background-image: url("@{icon-root}/modals/activity/status-scheduled.png");	#icon-util > .square16 }
	.activity-status-cancelled	{ background-image: url("@{icon-root}/modals/activity/status-cancelled.png");	#icon-util > .square16 }

	// process builder
	// sprite for all of the sideline/popover buttons
	.pbuilder-button {  background-image: url("@{icon-root}/process-builder/button-sprite.png"); #icon-util > .square16 }
	// this sprite has icons for non-hover, but i'm using opacity for animation potential
	.pbuilder-button-actions { background-position: center left;  opacity: 0.3; }
	.pbuilder-button-delays  { background-position: center center; opacity: 0.3; }
	.pbuilder-button-prereqs { background-position: center right; opacity: 0.3; }

	.pbuilder-button-actions.hover { background-position: center left; opacity: 1; }
	.pbuilder-button-delays.hover  { background-position: center center; opacity: 1; }
	.pbuilder-button-prereqs.hover { background-position: center right; opacity: 1; }

	.pbuilder-button-actions.selected { background-position: bottom left !important; opacity: 1 !important; }
	.pbuilder-button-delays.selected  { background-position: bottom center !important; opacity: 1 !important; }
	.pbuilder-button-prereqs.selected { background-position: bottom right !important; opacity: 1 !important; }

	.pbuilder-handlebar    {  background-image: url("@{icon-root}/process-builder/handlebar.png"); #icon-util > .square16 }
	.pbuilder-description  {  background-image: url("@{icon-root}/process-builder/description.png"); #icon-util > .square16 }
	.pbuilder-requirement  {  background-image: url("@{icon-root}/process-builder/requirements.png"); #icon-util > .square16 }

	// misc
	.disclosure { background-image: url("@{icon-root}/disclosure.svg"); #icon-util > .square12; opacity: 0.4; }
	.disclosure-selected { opacity: 1; }
	.spindown-closed,
	.spindown-open {
		.2x('@{icon-root}/generic/spindown-sprite.png', "no-repeat", 32px, 80px);
		background-position: left top;
		#icon-util > .square16;
	}
	.spindown-open { background-position: left bottom; }
	.spindown-white-closed { background-position: right top; background-image: url("@{icon-root}/generic/spindown-sprite.png"); #icon-util > .square16 }
	.spindown-white-open { background-position: right bottom; background-image: url("@{icon-root}/generic/spindown-sprite.png"); #icon-util > .square16 }

	.add-circle      { background-image: url("@{icon-root}/generic/add-circle.png"); #icon-util > .square16 }
	.subtract-circle { background-image: url("@{icon-root}/generic/subtract-circle.png"); #icon-util > .square16 }

	.entitypicker-modal { background-image: url("@{icon-root}/entity-picker/open-modal.png"); #icon-util > .square16 }

	// edit/delete icons for note modal tab
	// now moved into timeline, but waiting to integrate sprties until we decide on note editability
	.note-modal-tab-edit { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -2px -2px; #icon-util > .square16  }
	.note-modal-tab-delete { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -22px -2px; #icon-util > .square16  }
	.note-modal-tab-edit-hover { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -2px -22px; #icon-util > .square16  }
	.note-modal-tab-delete-hover { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -22px -22px; #icon-util > .square16  }
	.note-modal-tab-edit-active { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -2px -42px; #icon-util > .square16  }
	.note-modal-tab-delete-active { background: url("@{icon-root}/modals/note-edit-delete-sprite.png") -22px -42px; #icon-util > .square16  }

	.timeline          { background-image: url("@{icon-root}/timeline-sprite.png"); #icon-util > .square16 }
	.timeline.pencil   { background-position: left 0; }
	.timeline.activity { background-position: left -17px; }
	.timeline.email    { background-position: left -33px; }
	.timeline.modal    { background-position: left -49px; }
	.timeline.collapse { background-position: left -64px; }
	.timeline.expand   { background-position: left -80px; }
	.timeline.audio    { background-position: left -96px; }
	.timeline.video    { background-position: left -112px; }
	.timeline.zendesk  { background-position: left -128px; }

	// sprite for all of the history entry types
	.history          { background-image: url("@{icon-root}/history-sprite.png"); #icon-util > .square16 }
	.history.green-pencil     { background-position: left -112px; }
	.history.grey-pencil      { background-position: center -112px; }
	.history.red-pencil       { background-position: right -112px; }

	.help                { background-image: url("@{icon-root}/help/help.png");   #icon-util > .square16 }
	.help-setup          { background-image: url("@{icon-root}/help/setup.svg");  #icon-util > .square16 }
	.getting-started     { background-image: url("@{icon-root}/help/getting-started-sprite.png"); #icon-util > .square24 }
	.getting-started.products { background-position: left top; }
	.getting-started.process  { background-position: left -24px; }
	.getting-started.leads    { background-position: left -48px; }
	.getting-started.users    { background-position: left -72px; }
	.getting-started.google   { background-position: left -96px; }
	.getting-started.emails   { background-position: left -120px; }

	.close-x(@position-x: 5px, @position-y: 5px) {
		position: absolute;
		right: @position-x;
		top: @position-y;
		width: 20px;
		height: 20px;
		font-size: @normal;
		line-height: 19px;
		font-weight: 500;
		color: @grey-lt;
		cursor: pointer;

		&:before {
			content: '×';
		}

		&:hover {
			#util > .gradient > .vertical(@rose-dk, @rose);
			border: 1px solid rgba(175,23,45,0.6);
			border-radius: 20px;
			box-shadow: 0 1px 1px white;
			color: @off-white;
			text-shadow: 0 1px 0 rgba(0,0,0,0.3);
			opacity: 0.85;
		}

		.grey-circle() {
			background: @grey;
			border: 1px solid @grey;
			border-radius: 20px;
			color: @off-white;
			opacity: 0.5;
		}
	}
}
