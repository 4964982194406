


.behavior-repeatable {
	.empty-placeholder {
		display: none;
	}
	&.state-empty {
		.empty-placeholder { display: block; }
		.button-add-link { display: none; }
	}
}

// covers fields usually seen in modals, but also visible in step inputs, etc.

.fieldblock {
	.fieldrow {
		position: relative;
		#util > .rounded-corners(2px);
		min-height: 20px;
		line-height: 22px;
		margin: 0 0 5px 0;

		.empty { display: none; }
		&.state-empty .empty { display: inline-block; }
		&.state-editing .empty { display: none; }

		.action-delete {
			cursor: pointer;
			font-size: 20px;
			font-weight: 500;
			position: absolute;
			top: -1px;
			right: 4px;
			z-index: 2;
			display: none;
			opacity: 1;
			&:hover {
				color: @rose;
			}
		}

		.label {
			padding: 0 10px 0 0;
			.icon.star, .icon.warning {
				display: none;
				vertical-align: -2px;
				visibility: hidden;
				cursor: default; // only pointer when editable
			}
			.icon.star { #icon > .modal-field-primary-set; }

			// Hiding the read only label as it misleading and overly complicated
			// You can actually edit this UI in the hoverview so lets try make that obvious
			span.mode-read {
				display: none;
			}
			span.mode-edit { // .select-mini
				div.selector {
					background: none;
					margin: 0;
					padding: 0;
					span.uniformSpan {
						display: inline-block;
						width: 50px;
						#util > .excerpt-crop > .ellipsis;
						padding: 1px 0;
					}
				}
			}
		}

		.buttons { // under label, things like 'map'
			overflow: hidden;
			position: absolute;
			top: 19px;
			left: 0;
			width: 100px;
			padding-right: 8px;
			text-align: right;
			a.button-interaction {
				font-size: 11px;
			}
		}

		div.value {
			font-size: @small;
			width: 195px;
			white-space: nowrap;
			#util > .excerpt-crop > .ellipsis;

			select { // https://redmine.nutshell.com/issues/3329
				max-width: 192px;
			}

			a {
				color: inherit;
				text-decoration: underline;
				&:hover {
					background: @yellow-lt;
				}
			}
		}

		.icon.undo {
			#icon > .eip-undo;
			visibility: hidden;
			position: absolute;
			top: 2px;
			right: 2px;
		}
		.icon.undo:hover { #icon > .eip-undo-hover }
		.icon.undo:active { #icon > .eip-undo-active } // delete me soon. undo is bound to mousedown, so it's never seen.

		.action-choose,
		.button-eip {
			position: absolute;
			top: 0;
			right: 0;
			width: 26px;
			height: 20px;
			&:hover {
				background-position: 0 -20px;
			}
			&:active {
				background-position: 0 -40px;
			}
		}
		.action-choose {
			display: none;
		}
		.button-eip {
			visibility: hidden;
			background: url("@{img-root}/buttons/eip-sprite.png") no-repeat;
		}
		&.type-multiple {
			.label .icon.star { display: inline-block; }
			.action-edit { display: none; }
		}
		&.variant-readonly {
			border-top-left-radius: 3px;

			.label .icon.star { visibility: hidden; }

			.action-delete,
			.action-edit,
			.action-cancel {
				display: none !important;
			}
		}
		&.behavior-choosable {
			.action-choose { display: block; }
		}

		&:hover {
			.action-delete { display: block; }
			.button-eip { visibility: visible; }
			.icon.star { cursor: pointer; visibility: visible; }
			.icon.star:hover { #icon > .modal-field-primary-set-hover }
			.icon.star:active { #icon > .modal-field-primary-set-active }
		}
		&.state-editing {
			background-color: @yellow-lt;
			.button-eip { visibility: hidden !important; }
			.state-calling-msg { display: none !important; }
			.action-call-number { display: none; }
		}
		&.state-dirty {
			.icon.undo { visibility: visible; }
			.action-delete { display: none; }
		}
		&.state-transient {
			.icon.undo { visibility: hidden; }
			.action-call-number { display: none; }
		}
		&.state-invalid {
			background-color: @rose-lt;
		}
		&.state-warning {
			span.warning { display: none; }
			.icon.action-warning {
				display: inline-block;
				visibility: visible !important;
				#icon > .modal-field-warning;
				opacity: 1 !important;
				cursor: default;
				&:hover { opacity: 1; }
			}
		}
		&.type-primary {
			.label .icon.star {
				visibility: visible !important;
				#icon > .modal-field-primary;
				opacity: 1 !important;
				cursor: default;
				&:hover { opacity: 1; }
			}
		}
		&.blink-success {
			background-color: @green-lt;
		}
		&.blink-error {
			background-color: @rose-lt;
		}

		// attribute types
		&.type-location {
			line-height: 18px;
			&.type-multiple { margin-bottom: 15px; }

			.buttons { top: 17px; }
			div.value {
				#util > .inline-block;
				white-space: normal;
				overflow: visible;

				input.city { width: 80px; }
				input.state { width: 40px; }
				input.postalCode { width: 75px; }
			}
			&:hover {
				background: @yellow-lt;
			}
		}
		&.type-text,
		&.type-string,
		&.type-url {
			div.value {
			 	overflow: visible;
			 	span.value {
			 		word-wrap: break-word;
			 		white-space: pre-wrap;
			 		display: inline-block;
			 		width: inherit;
			 		overflow: hidden;
			 	}
			}
			.button-eip.action-edit {
				right: 15px;
			}
		}

		&.type-phone {
			div.value {
				.extension {
					padding-left: 15px;
					font-style: italic;
				}

				input.countryCodeAndNumber { width: 115px; }
				input.extension { width: 60px; }

				.action-call-number {
					position: relative;
					margin-left: 20px;
					padding: 1px 20px;
					border-radius: 10px;
					background-color: @green;
					color: white;
					opacity: 0.5;
					text-decoration: none;

					&:hover,
					&.state-call-prompt {
						opacity: 1;
						background-color: @green-dk;
					}
				}
				.state-calling-msg{
					display: none;
					padding-left: 10px;
					color: @green;
					.fa { margin-right: 2px; }
				}

				&:hover .action-call-number { opacity: 1; }

				&.state-calling-active {
					.action-call-number { display: none; }
					.state-calling-msg { display: inline-block; }
				}
			}
			&.state-empty .value .extension { display: none }
		}
		&.type-enum {
			.button.action-delete { display: none; }
			.label .icon.star { visibility: hidden; }
			div.bsmListItemRemove {
				cursor: pointer;
				#util > .inline-block;
				#icon > .subtract-circle;
				vertical-align: -20%;
				border: 0 none;
				margin-right: 5px;
			}
		}
	} // /li

	// Used in card hoverviews
	&.variant-narrow-label .fieldrow {
		.label {
			font-size: @tiny;
			font-weight: 500;
			text-align: right;
			float: left;
			width: 25%;
			height: 24px;
			padding: 0;
			clear: both;
		}
		.value {
			color: @grey-dk;
			width: 75%;
			> span:hover {
				background: @yellow-lt;
			}
		}
	}
} // /ul
