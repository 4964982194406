.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: scroll;
}

.loading-container {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 195px);
    padding-top: 64px;
    fill: var(--orange-lt);
    background-color: white;
}

.table {
    display: block;
    min-height: calc(100vh - 195px);
}

.table-header {
    top: 0;
    z-index: 2;
    height: 34px;
    background-color: var(--off-white);
    color: var(--grey-dk);
    font-weight: 500;
}

.analytics-table {
    composes: table;
    min-height: unset;
}

@media (min-width: 960px) {
    .table-header {
        position: sticky;
    }

    .container {
        overflow-x: initial;
    }
}

.table-header th {
    border-right: 1px solid var(--off-white-dk);
}

.table th,
.table td {
    text-align: right;
    padding: 8px 16px;
    box-sizing: border-box;
    min-width: 150px;
    white-space: nowrap;
}

.table thead th {
    border-bottom: 1px solid var(--off-white-dk);
}

.table-section > .table-section-row {
    background-color: #fff;
}
.table-section--no-segments > .table-section-row {
    background-color: #fff;
}

.table-section > .table-section-row:nth-child(2n + 1),
.table-section--no-segments:nth-child(2n + 1) > .table-section-row {
    background-color: var(--zebra-stripe);
}

.table-section--no-segments--totals {
    composes: table-section--no-segments;
}

.table-section--no-segments--totals td {
    font-weight: 500;
    color: var(--grey-dk);
    background-color: #fff;
    border-bottom: 1px solid var(--off-white-dk);
}

.table-section--totals {
    composes: table-section;
}

.table-section--totals tr:first-child {
    font-weight: 500;
}

.table .empty {
    min-width: 0;
    width: 100%;
    height: 34px; /* height of single table-cell */
    padding: 0; /* empty row has no background, messes up header if padding */
}

.table-header th:first-child,
.table-section-header td:first-child,
.table-section-row td:first-child {
    text-align: left;
    min-width: 150px;
}

.table-section-header {
    background-color: var(--off-white);
    color: var(--grey-dk);
}

.table-section-header td {
    font-weight: 500;
    background-color: #fff;
    border-bottom: 1px solid var(--off-white-dk);
    padding-top: 32px;
}

/* Removes the border from the first table-section-header, butting up against the table header */
.table-section:nth-child(2) .table-section-header td {
    padding-top: 8px;
}

/* Purposefully empty to comment here - our quota report doesn't have segment
data, but we need to render a table cell for it so our table layout doesn't
get messed up */
.quota-cell-hack {
}
