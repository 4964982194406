.container {
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: var(--grey-lt);
    background-color: var(--off-white-md);
    border-radius: 10px;
    vertical-align: top;
    display: inline-block;
    position: relative;
    text-align: center;
    cursor: pointer;

    /* 12px is more centered than 13px */
    font-size: 12px;

    /* Override button styles */
    padding: 0 !important;
}

.container:hover,
.container:focus,
.container:active {
    color: var(--grey);
    background-color: var(--form-border);
    outline: 2px solid var(--focus-blue);
}
