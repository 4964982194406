.empty-icons-sprite() {
	.sprite-helper("sprites/empty-icons-sprite.png?2", 47px, 282px);
}

.big-empty-placeholder {
	display: none;
	#util > .clearfix();
	margin: 15px 10px 15px 0;
	padding-left: 61px; // this == peep name distance from left
	position: relative;

	.icon {
		position: absolute;
		top: 1px;
		left: 3px; // matches peep icons better than left: 0;
		width: 47px;
		height: 47px;
		.empty-icons-sprite();
	}
	.title,
	.description {
		max-width: 370px;
		position: relative;
		font-size: @small;
		line-height: 17px;
		color: @grey;
	}
	.title {
		margin-bottom: 4px;
	}
	.description {
		color: @grey-lt;
		margin-bottom: 4px;
	}

	a {
		color: @blue;
		&:hover {
			text-decoration: underline;
			color: @blue-dk;
		}
	}
}
.state-empty .big-empty-placeholder { display: block; }

.contacts-container .big-empty-placeholder .icon { background-position: 0px -47px; }
.activities-container .big-empty-placeholder .icon { background-position: 0px -94px; }
.tasks-container .big-empty-placeholder .icon {
	background-position: 0px -141px;
}
.stages-carousel-wrapper .big-empty-placeholder .icon { background-position: 0px -188px; }
.goals-guidance .big-empty-placeholder .icon { background-position: 0px -235px; }
.fields-cta .big-empty-placeholder .icon { background-position: 0px -188px; }
