.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-width: 0;
}

.container-skipped {
}
.container-skipped .title {
    color: var(--grey);
}
.container-skipped .checkbox {
    background-position: 0 -54px;
}

.container-completed {
}

.container-completed .checkbox {
    background-position: 0 -36px;
}
.container-uncompleteable {
    composes: container;
}
.container-uncompleteable .title {
    color: var(--grey);
}
.container-uncompleteable .checkbox {
    background-position: 0 -54px;
}
.container-uncompleteable .checkbox:hover {
    background-position: 0 -54px;
}
.container-overdue .timestamp-container {
    color: var(--rose);
}

.left-container {
    margin-right: 10px;
    min-width: 18px;
}

.checkbox-container {
    display: flex;
    flex-shrink: 0;
    width: 18px;
    margin: 2px 0;
}

.checkbox-container--with-tooltip {
    composes: checkbox-container;
}

.type-icon {
    width: 18px;
    display: flex;
    justify-content: center;
    fill: var(--grey-lt);
}

.checkbox {
    width: 100%;
    height: 18px;
    background-repeat: no-repeat;
    background-image: url('/include/images/task-checkbox-sprite_2x.png');
    background-size: 18px 72px;
    background-position: 0 0;
    flex-shrink: 0;
    position: relative;
}

.checkbox:hover {
    background-position: 0 -18px;
}
.checkbox:active {
    background-position: 0 -36px;
}

.checkbox:after {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    cursor: pointer;
}

.content-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    padding-right: 16px;
}

.title-container {
    display: flex;
    align-items: baseline;
}

.title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
    font-size: var(--font-normal);
    line-height: 20px;
    color: var(--grey-dk);
    margin-bottom: 2px;
}

.related-entity-container {
    font-size: var(--font-small);
    line-height: 18px;
}

.description {
    color: var(--grey-lt);
    font-size: var(--font-tiny);
    margin-left: 4px;
}

.right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.timestamp-container,
.secondary-text {
    font-size: var(--font-small);
    line-height: 20px;
    color: var(--grey);
}

.secondary-text .fa-check {
    color: var(--grey-lt);
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.container-uncompleteable .checkbox-container--with-tooltip:hover {
    position: relative;
}

.container-uncompleteable .checkbox-container--with-tooltip:hover:after {
    animation: fadeIn 200ms ease-in 500ms forwards;
    opacity: 0;

    content: attr(data-tooltip);
    display: block;
    position: absolute;
    top: -4px;
    left: calc(100% + 4px);
    z-index: 11;
    width: 300px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    border-radius: 3px;
    color: var(--off-white);
    font-size: var(--font-small);
    font-weight: normal;
}

.container-uncompleteable .checkbox-container--with-tooltip:hover:before {
    animation: fadeIn 200ms ease-in 500ms forwards;
    opacity: 0;

    content: '';
    position: absolute;
    top: 4px;
    left: calc(100% - 10px);
    z-index: 11;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-right-color: rgba(0, 0, 0, 0.8);
    border-width: 7px;
}
