.confirm-box {
    position: relative;
    padding: 24px 16px 16px;
    background-color: #fff;
}

.confirm-buttons {
    display: flex;
    justify-content: space-between;
    margin: 0 -8px; /* Aligns button with text */
    align-items: center;
}

.confirm-buttons button {
    padding: 8px;
    font-size: var(--font-normal);
    cursor: pointer;
}

.save-button {
    margin-left: 4px;
}

.error-message {
    padding-bottom: 8px;
}
