.container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.container:last-child {
    margin-bottom: 32px;
}

.stages-container {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    height: auto;
    visibility: visible;
}

.stages-container--collapsed {
    composes: stages-container;
    height: 0;
    visibility: hidden;
}
