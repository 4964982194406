.group-configurations-column {
    display: flex;
    flex-direction: column;
    min-width: 150px;
    flex-shrink: 0;
    flex-grow: 1;
}

.group-configurations-column--fixed {
    composes: group-configurations-column;
    background-color: var(--off-white);
    border-right: 1px solid var(--off-white-dk);
}

.group-configurations-column-cell {
    composes: column-cell from './group-configuration-table.css';
    justify-content: center;
}

.group-configurations-column-cell input[type='checkbox'] {
    margin: 0;
}
