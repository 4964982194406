.review-sequence-container {
    padding: 32px;
}

.drip-sequence-name {
    font-style: italic;
    font-weight: 600;
}

.drip-requirements-container {
    margin-top: 24px;
}

.drip-requirements-container > * {
    margin-bottom: 12px;
}

.has-error {
    color: var(--rose);
}
