

.graph-group {
	display: inline-block;

	.graph-legend {
		clear: both;
		font-size: @small;
		color: @grey;
		text-align: left;
		width: 60%;
		margin: 0 0 0 15px;
		padding: 10px 0 30px 0;
		&.type-performance-line-legend {
			margin-left: 25px; // Hack to get legend to align with graph
		}

		table {
			td.legendColorBox {
				width: 20px;

				.legendColorBoxBorder {
					#util > .inline-block;
					border: 1px solid transparent;
					margin-right: 5px;
					width: 18px;
					height: 18px;
					margin-bottom: -5px;

					.legendColorBoxSwatch {
						width: 18px;
						height: 18px;
					}

				}
			}
			td.legendLabel {
				width: auto;
				padding-right: 30px;
			}
		}
	}
}
.graph-legend.type-forecast-line-legend,
.graph-legend.type-performance-line-legend {
	.legendColorBoxBorder {
		margin-right: 10px !important;

		.legendColorBoxSwatch {
			width: 24px !important;
			height: 18px;
			background: url("@{img-root}/graph-legend-overlay-point.png") center 40% no-repeat;
		}
	}

	.legend-quota,
	.legend-past {
		.legendColorBoxBorder {
			.legendColorBoxSwatch {
				background: url("@{img-root}/graph-legend-overlay-line.png") center 40% no-repeat;
			}
		}
	}
}

.graph-tooltip {
	display: none;
	position: absolute;
	z-index: 1000;
	padding: 3px 5px;
	line-height: 13px;
	font-size: 11px;
	background-color: @grey-dk;
	color: white;
	margin-top: -7px;
	#util > .opacity(0.9);
	filter: none; // no opacity filter, so that IE doesn't inexplicably hide .tip

	div:first-child {
		font-weight: 500;
	}

	.tip {
		position: absolute;
		top: 100%;
		right: 100%;
		margin-right: -8px;
		display: block;
		width: 0px;
		height: 0px;
		border: 4px solid @grey-dk;
		border-bottom-color: transparent;
		border-right-color: transparent;
	}
}
