.next-steps-container {
    background-color: var(--off-white);
    height: 100%;
}

.next-steps-container > button {
    margin-bottom: 8px;
}

.add-next-step-container {
    width: 100%;
    height: 61px; /* matches height of start trigger card */
}
