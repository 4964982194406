.list-item-container > button {
    width: 100%;
}

.list-item-container > button > div {
    border: none;
}

.title {
    text-align: left;
    font-weight: normal;
}

.subtitle {
    text-align: left;
}
