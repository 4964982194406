@import 'include';

.segmented-arrows {
	height: 31px;
	font-size: 14px;
	display: block;
	background: @off-white-dk url('@{img-root}/segmented-arrows/normal-bg.png') repeat-x; /* TODO: standardize color */
	position: relative;
	#util > .excerpt-crop > .ellipsis;

	li {
		position: relative;
		#util > .inline-block;
		margin-left: 4px;
		margin-right: 14px;
		height: 31px;
		padding: 0 6px;
		line-height: 31px;
		user-select: none;
		background: @off-white-dk url('@{img-root}/segmented-arrows/normal-bg.png') repeat-x; /* TODO: standardize color */
		vertical-align: middle;
		color: black;
		max-width: 120px;

		.left,
		.right {
			position: absolute;
			top: 0;
			width: 19px;
			height: 31px;
		}
		.left {
			left: -19px;
		}
		.right {
			background: url('@{img-root}/segmented-arrows/normal-right.png') no-repeat;
			right: -19px;
		}
	}
	li.state-clickable {
		cursor: pointer;
	}
	li.button {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		background: 0;
	}

	li.state-selected {
		color: @grey;
		background: @off-white-dk url('@{img-root}/segmented-arrows/active-bg.png') repeat-x;
		cursor: default;
		#util > .text-shadow > .shadowed;

		.left {
			background: url('@{img-root}/segmented-arrows/active-left.png') no-repeat;
		}
		.right {
			background: url('@{img-root}/segmented-arrows/active-right.png') no-repeat;
		}
	}

	.status-completed,
	.status-completed.state-selected {
		color: @green;
		font-weight: 500;
	}
	.status-inprogress {
		font-weight: 500;
	}
	.status-inprogress.state-selected {
		color: @blue;
	}
}
