.container {
    padding: 32px;
}

.swatch-container {
    margin: 12px 0 0 8px;
}

.footer {
    width: calc(100% - 64px);
    padding: 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
}
