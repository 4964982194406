.inline-form-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.form-controls {
    width: 100%;
    margin-top: 8px;
    text-align: right;
}

.form-controls > * {
    margin-left: 8px;
}
