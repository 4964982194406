.container {
    width: 100%;
    background-color: #fff;
    padding: 16px 16px 0;
    box-sizing: border-box;
    display: flex;
}

.container > * {
    width: 100%;
}

.text-container {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 16px;
}

.text-container > *:not(:last-child) {
    margin-bottom: 4px;
}

.error {
    color: var(--rose);
}
