.lead-contents {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    box-sizing: border-box;
}

.lead-column {
    position: relative;
    padding: 16px 16px 0 16px;
    background-color: #fff;
}

.lead-column-left {
    composes: lead-column;
    display: block;
    min-width: 600px;
    flex: 1;
    border-radius: 8px 0 0 0;
}

.lead-column-right {
    display: block;
    composes: lead-column;
    width: 381px;
    flex-shrink: 0;
    order: 2;
    background-color: white;
    border-left: 1px solid var(--off-white-dk);
}

/* Without this, our loading columns aren't full height. With it,
our column's border is only the height of the screen, and gets
cut off when the page is scrolled. TL;DR: we want height: 100% on load,
but not once the content is loaded in */
.lead-contents--loading {
    composes: lead-contents;
    height: 100vh;
}

/* 101% height so that a scrollbar is present, because
it will be when the content loads and we don't want jumping */
.lead-contents--loading .lead-column-right {
    height: 101%;
}

.error-container {
    padding-top: 64px;
    height: 100%;
    background-color: white;
}

.error-container img {
    width: 300px;
}
