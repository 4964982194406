.dash-content {
    width: 100%;
    height: calc(100% - 42px);
    position: relative;
}

.dash-component-wrapper {
    height: 100%;
    transform: translate3d(
        0,
        0,
        0
    ); /* HACK: needed to prevent empty drag preview (https://github.com/gaearon/react-dnd/issues/454) */
}

.error-container {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
}
