.ranked-stat-bar-list {
    display: flex;
    gap: 48px;
}

.ranked-stat-bar-list > div {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 50%;
}
