@entity-cell-gutter: 5px;
@name-cell-gutter: 87px;
@name-cell-content: 40px;
@name-cell-padding: 20px;

.ui-entity-list-row {
	#util > .clearfix();
	padding: 8px 10px;
	color: @grey-dk;

	&.ui-entity-list-row--string {
		&.ui-entity-list-row--string--owner,
		&.ui-entity-list-row--string--creator {
			.ui-entity-list-row--string__content {
				color: @orange-dk;
			}
		}
	}

	&.ui-entity-list-row--url,
	&.ui-entity-list-row--emails,
	&.ui-entity-list-row--email {
		.ui-entity-list-row--string__url,
		.ui-entity-list-row--emails__address,
		.ui-entity-list-row--email__address {
			color: @blue;
			cursor: pointer;
			&:hover {
				color: @blue-dk;
				text-decoration: underline;
			}
		}

		.ui-entity-list-row--emails__address {
			display: block;
		}
	}

	&.ui-entity-list-row--string,
	&.ui-entity-list-row--email,
	&.ui-entity-list-row--emails,
	&.ui-entity-list-row--url {
		.ui-entity-list-row--string__content {
			#util > .excerpt-crop > .ellipsis();
			display: block;
		}
	}

	&.ui-entity-list-row--right-aligned {
		text-align: right;
	}

	&.ui-entity-list-row--description {
		padding: 5px 10px;

		.ui-entity-list-row--description__value,
		.ui-entity-list-row--description__description {
			#util > .excerpt-crop > .ellipsis();
			display: block;
		}

		.ui-entity-list-row--description__description {
			margin-top: -2px;
			font-size: 11px;
		}
	}

	&.ui-entity-list-row--entities {
		padding: 2px 10px;

		.ui-entity-list-row--entities__entity {
			float: left;
			width: 100%;
			margin: 0;

			.ui-entity-list-row--entities__entity__entity-name {
				float: left;
				max-width: ~'calc(100% - @{entity-cell-gutter})';
				#util > .excerpt-crop > .ellipsis();
				margin: 0; //override linked-entity style

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.ui-entity-list-row--entities__entity__more-entities {
			position: relative;
			float: left;
			#util > .excerpt-crop > .ellipsis();
			color: @grey-lt;
		}
	}

	&.ui-entity-list-row--emails {
		padding: 2px 10px;
	}

	&.ui-entity-list-row--tokenized {
		padding: 2px 10px;

		.ui-entity-list-cell__tokenized-item {
			#util > .excerpt-crop > .ellipsis();
			width: 100%;
			color: @grey;
			float: left;
			margin: 1px 3px 1px 0;

			.ui-entity-list-cell__tokenized-item-wrapper {
				background-color: @off-white-md;
				padding: 0 7px;
				border-radius: 2px;
			}
		}

		.ui-entity-list-cell__more-tokenized-items {
			display: inline-block;
			padding-left: 7px;
			color: @grey-lt;
		}
	}

	&.ui-entity-list-row--name {
		box-sizing: border-box;
		padding: 2px 8px 2px 0;
		height: 41px;

		.ui-entity-info {
			float: left;
			height: 35px;
			width: ~'calc(100% - @{name-cell-content})';

			&.ui-entity-info--1-items .ui-entity-info__content {
				margin-top: 10px;
			}
			&.ui-entity-info--2-items .ui-entity-info__content {
				margin-top: 2px;
			}
			&.ui-entity-info--3-items .ui-entity-info__content {
				margin-top: 0;
				line-height: 15px; //default is 17, this looks ok though.
			}

			.ui-entity-info__avatar {
				height: 32px;
				width: 32px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			&.ui-entity-info--leads {
				.ui-entity-info__lead-number {
					bottom: -3px;
					width: 35px;
				}
				.ui-entity-info__avatar {
					justify-content: flex-start;
					margin-top: 1px;
				}
			}

			.ui-entity-info__avatar__placeholder {
				height: 32px;
				width: 32px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}

		.ui-entity-list-row--loading__loading-container {
			position: absolute;
			top: 5px;
			width: ~'calc(100% - @{name-cell-padding})';
		}

		.ui-entity-list-row--name--loading__checkbox {
			float: left;
			box-sizing: border-box;
			margin-right: 10px;
			margin-left: 10px;
			margin-top: 11px;
			height: 12px;
			width: 12px;
			border-radius: 2px;
			background-color: white;
			border: 2px solid @off-white-md;
		}

		.ui-entity-list-row--name--loading__avatar {
			float: left;
			margin-right: 10px;
			height: 35px;
			width: 35px;
			border-radius: 35px;
		}

		.ui-entity-list-row--name--loading__info {
			#util > .clearfix();
			float: left;
			width: ~'calc(100% - @{name-cell-gutter})';
			margin-top: 3px;

			.ui-entity-list-row--name--loading__bar-top,
			.ui-entity-list-row--name--loading__bar-bottom {
				float: left;
				height: 11px;
			}

			.ui-entity-list-row--name--loading__bar-top {
				width: 100%;
				margin-bottom: 2px;
			}

			.ui-entity-list-row--name--loading__bar-bottom {
				width: 90%;
			}
		}
	}

	.ui-entity-list-row--loading__bar-top {
		position: absolute;
		top: 15px;
		height: 12px;
		width: ~'calc(100% - 15px)';
		background-color: @off-white-md;
		margin-bottom: 3px;
	}
}

.ui-entity-list--leads {
	.ui-entity-list-row.ui-entity-list-row--name {
		.ui-entity-list-row--name--loading__avatar {
			width: 25px;
			height: 25px;
			border-radius: 25px;
			margin: 0 13px 0 7px; //centers loading icon
		}
	}
}

.fixedDataTableLayout_rowsContainer {
	.public_fixedDataTableRow_main:not(.fixedDataTableLayout_header) {
		&.public_fixedDataTableRow_main--deleted {
			.ui-entity-list-row {
				&.ui-entity-list-row--string,
				&.ui-entity-list-row--email,
				&.ui-entity-list-row--emails,
				&.ui-entity-list-row--url,
				&.ui-entity-list-row--description,
				&.ui-entity-list-row--entities {
					color: @grey-lt;
					text-decoration: line-through;
				}

				&.ui-entity-list-row--name .ui-entity-info__content {
					color: @grey-lt;
					text-decoration: line-through;
				}

				&.ui-entity-list-row--tokenized .ui-entity-list-cell__tokenized-item-wrapper {
					color: @grey-lt;
					text-decoration: line-through;
				}
			}
		}
	}
}
