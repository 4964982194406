.container {
    height: 400px;
    display: flex;
    position: relative;
}

.side-container {
    width: 300px;
}

.overlay {
    background-color: var(--grey-lt);
    opacity: 0.2;
    height: 100%;
    width: 100%;
    position: relative;
}
