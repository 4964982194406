.preview-container {
    composes: preview-container from '../meeting-page.css';
}

.minimum-notice {
    display: flex;
    flex-direction: row;
}

.minimum-notice-input {
    width: 40px;
    margin-right: 8px;
    height: 31px; /* match height of select */
}

.minimum-notice-select {
    width: 100%;
}

.buffer-row {
    display: flex;
    height: 31px; /* match height of pickers */
    padding-bottom: 8px;
}

.checkbox {
    display: flex;
    min-width: 110px;
}

.buffer-time-select,
.buffer-time-select--disabled {
    width: 100%;
}

.buffer-time-select--disabled > * {
    opacity: 50%;
}
