.container {
    padding-bottom: 8px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.text {
    margin: 0;
    max-width: 90%; /* Allows the 'Learn how' link to remain on one line */
}
