.info-banner {
    font-size: var(--font-normal);
    line-height: 21px;
    color: var(--grey-dk);
    background-color: var(--plum-lt);
    background-size: 36px 36px;
    background-position: 0 0;
    background-repeat: repeat-y;
    border-bottom: 2px solid var(--plum);
    padding: 16px 16px 16px 64px;
    position: relative;
}

.icon-container {
    background-size: 42px 42px;
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 42px;
    height: 42px;
    position: absolute;
    left: 16px;
    top: 50%;
    margin-top: -21px;
}

.content-wrapper {
    display: inline-block;
    /* Ensure line length doesn't get too long */
    width: 80%;
    max-width: 800px;
}

.content-wrapper a {
    text-decoration: underline;
    color: var(--blue);
}

.content-wrapper a:hover {
    text-decoration: none;
    color: var(--blue-dk);
}
