.container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
}

.backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff; /* should be --color-modal-overlay, but it's under sidefire and doesn't look good */
    opacity: 0.7;
    z-index: 1;
}

.input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid var(--off-white-dk);
    z-index: 10;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    padding: 8px 16px;
    min-width: 384px;
}
