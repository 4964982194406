.container {
    display: flex;
    margin-bottom: 8px;
}

.select-container {
    flex: 1;
}

.product-picker-label {
    margin-left: 10px;
    height: 31px; /* match height of product select */
}

.product-picker-label input {
    width: 60px;
    margin-left: 5px;
    display: inline-block;
    height: 100%;
}

.product-picker-label--price {
    composes: product-picker-label;
}

.product-picker-label--price input {
    width: 90px;
}

.cancel-button {
    display: flex;
    align-items: center;
}

.cancel-button > * {
    margin-left: 10px;
}
