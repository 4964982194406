@keyframes spin {
	from { transform: rotate(0deg); }
	  to { transform: rotate(360deg)}
}

.svg-loading svg {
	animation: spin 1s infinite linear;
	transform-origin: 50% 50%;
}

.svg-loading-lines {
	height: 24px;
	width: 30px;

	rect {
		@keyframes grow-shrink-loading-lines {
			0% { transform: scaleY(2)}
			50% { transform: scaleY(1)}
			100% { transform: scaleY(2)}
		}
		animation: grow-shrink-loading-lines 600ms infinite linear;
		transform-origin: 50% 50%;

		fill: @off-white-dk; /* TODO: standardize color */

		&:nth-child(2) { animation-delay: 150ms }
		&:nth-child(3) { animation-delay: 300ms }
	}
}

.ui-notification-icon {
	.2x('@{img-root}/entity-lists/icon-notifications.png', "no-repeat");
	width: 100%;
	height: 100%;

	//type
	&.ui-notification-icon--success { background-position: 0 0; }
	&.ui-notification-icon--warning { background-position: -30px 0;}
	&.ui-notification-icon--error { background-position: -60px 0;}
	&.ui-notification-icon--info { background-position: -90px 0; }

	//sizing
	&.ui-notification-icon--small { background-size: 60px 15px !important; }
	&.ui-notification-icon--medium { background-size: 120px 30px !important; }
}
