.quill-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 100%;
}

.editor {
    height: 100%;
    min-height: 0; /* For firefox smh */
    overflow-y: auto;
}

.editor--full-height {
    composes: editor;
}

/* Allow quill editor to grow to full height */
.editor--full-height :global(.quill) {
    height: 100%;
}

.quill-container :global(.quill .ql-container) {
    /* Their default font is Helvetica, we want to use IBM Plex Sans. */
    font-family: IBM Plex Sans;
    border: none;
    /* Darker text is much easier on the eyes */
    color: var(--grey-dk);
    font-size: var(--font-small);
    width: 100%;
    height: 100%;
}

.quill-container :global(.ql-editor) {
    /*
     *  This unifies with text boxes on the left & gives us a bit extra on the right
     */
    padding: 8px 16px;

    /* Sane default for mega screens */
    max-width: 1200px;
    height: 100%;
    width: 100%;
}

/* Placeholder styles */
.quill-container :global(.ql-editor.ql-blank::before) {
    font-style: normal;
    font-size: var(--font-small);
    color: var(--placeholder);
}

/* Override toolbar icon stroke/fill */
.quill-container :global(.quill .ql-snow .ql-stroke) {
    stroke: var(--grey);
}

.quill-container :global(.quill .ql-snow .ql-fill) {
    fill: var(--grey);
}

.quill-container :global(.quill .ql-tooltip.ql-flip) {
    /* We need to translate our link tooltip popup so
    it's not behind our sidebar */
    transform: translate3d(78px, -10px, 0);
}

.notification {
    margin: 16px 8px 8px 8px;
    user-select: none;
    background-color: var(--off-white);
}

.reply-notification {
    composes: notification;
    padding: 8px 16px;
    border-left: 9px solid var(--off-white-md);
}

.reply-notification p {
    max-width: 85%;
    opacity: 0.6;
}

.reply-notification p:first-child {
    margin-bottom: 15px;
}

.signature-notification {
    composes: notification;
    background-color: #fff;
    padding: 8px 16px;
    border: 2px dashed var(--off-white-dk);
    display: flex;
    border-radius: 3px;
    flex-direction: row;
    justify-content: space-between;
}

.signature-notification a {
    flex-shrink: 0;
    align-self: center;
}

.signature-message {
    padding-right: 16px;
    max-width: 100%;
}

.signature-message img {
    max-width: 100%;
}

/* Error styles */
.quill-container--error {
    composes: quill-container;
}

.quill-container--error :global(.ql-editor.ql-blank::before) {
    color: var(--rose);
}

/*
TOOLBAR POSITION TOP
 */
.quill-container--toolbar-top {
    composes: quill-container;
}

.quill-container--toolbar-top :global(.ql-editor) {
    height: calc(100% - 40px);
}

.quill-container--toolbar-top.quill-container--toolbar-floating-box :global(.ql-editor) {
    height: calc(100% - 56px);
}

/*
TOOLBAR POSITION BOTTOM
 */
.quill-container--toolbar-bottom {
    composes: quill-container;
}

.quill-container--toolbar-bottom :global(.ql-editor) {
    height: 100%;
}

.quill-container--toolbar-bottom :global(.quill .ql-container) {
    /* Toolbar is absolutely positioned, so we don't want
    the text allowed under it. 40px toolbar, 8px padding top/bottom */
    padding-bottom: 56px;
}

.quill-container--toolbar-bottom.quill-container--toolbar-grey-bar :global(.quill .ql-container) {
    /* Toolbar is absolutely positioned, so we don't want
    the text allowed under it. 40px toolbar, no padding */
    padding-bottom: 40px;
}

/* Placeholder blot styles */
.quill-container :global(.ql-placeholder-blot) {
    background-color: var(--blue);
    color: white;
    border-radius: 3px;
}

.quill-container :global(.ql-placeholder-blot-custom) {
    background-color: var(--report-orange);
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.quill-container :global(.ql-placeholder-blot) > span,
.quill-container :global(.ql-placeholder-blot-custom) > span {
    padding: 0 4px;
}

.quill-container :global(.ql-placeholder-blot-custom-placeholder) {
    background-color: rgba(234, 144, 51, 0.5);
    color: white;
    padding: 0 4px;
    border-radius: 3px;
}

.quill-container :global(.quill .ql-container blockquote) {
    margin: 0;
}

.loading {
    position: absolute;
    top: 60px;
    left: calc(50% - 24px);
}

.transcribe {
    position: absolute;
    bottom: 56px;
    right: 16px;
}
