.sidebar-drawer {
    height: 100%;
    z-index: 10;
}

.drawer-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 3px 15px rgba(120, 128, 142, 0.55);
    z-index: 300;
}

.header {
    color: var(--grey-dk);
    font-size: 17px;
    max-height: 87px;
    padding: 16px 16px 0 16px;
    box-sizing: border-box;
    font-weight: 500;
}

.header-text {
    padding-top: 16px;
    padding-bottom: 8px;
}

.bar {
    border-bottom: 1px solid var(--off-white-dk);
}

.drawer-contents {
    width: 100%;
    height: 100%;
}

.drawer-contents--with-header {
    composes: drawer-contents;
    height: calc(100% - 87px);
    padding-top: 32px;
}

.drawer-contents--small-header {
    height: calc(100% - 55px);
    padding-top: 0;
}

.drawer-contents--no-top-padding {
    height: calc(100% - 55px);
    padding-top: 0;
}
