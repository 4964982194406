.container {
    background-color: white;
    display: flex;
}

:global(#edition-engagement-scroll-container) {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 11;
    overflow-x: hidden;
}
