.email-selection {
    border-bottom: 1px solid var(--off-white-md);
}

.email-selection--scrollable {
    composes: email-selection;
    max-height: 64px;
    overflow-y: auto;
    flex-shrink: 0;
}

:global(#email-cc-field) {
    position: relative; /* Needed so this can be a react-select portal */
}
