.card {
    display: flex;
    position: relative;
    width: 100%;
    padding: 12px 16px;
    border-radius: 3px;
    margin: 8px 0;
    cursor: pointer;
    user-select: none;
    min-height: 64px;
    background-color: #fff;
    overflow: hidden;
}

.card:not(.card--create) {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.09);
}

.card:hover {
    background-color: var(--color-list-selected);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.09);
}

.card:focus {
    /* Default for FF, Edge, IE */
    outline: 1px dotted #212121;
    /* Default for Chrome, Safari, Opera */
    /* stylelint-disable declaration-block-no-duplicate-properties */
    outline: 5px auto -webkit-focus-ring-color;
    /* stylelint-enable */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.09);
}

.card--hidden {
    composes: card;
    visibility: hidden;
}

.card--create {
    composes: card;
    min-height: 64px;
    align-items: center;
    background-color: transparent;
}

.card--create:hover {
    background-color: white;
}

.card--create:focus {
    background-color: white;
}

.icon {
    font-size: 24px;
    width: 24px;
    text-align: center;
}

.container {
    display: flex;
    align-items: center;
    text-align: left;
    height: 30px;
    width: 100%;
}

.container > *:not(:first-child) {
    margin-left: 16px;
}

.text-container {
    /* Required to ellipsize text */
    width: calc(100% - 48px);
}

.title {
    color: var(--grey-dk);
    font-size: var(--font-normal);
    text-align: left;
    line-height: 17px;
    max-height: 34px;
    overflow: hidden;
}

.second-line {
    font-size: var(--font-small);
}

.missing-error {
    color: var(--rose);
}
