.action-menu-container {
    position: relative;
    right: -2px;
    display: flex;
    align-items: center;
    padding-left: 4px;
}

.action-menu-container button {
    border-radius: 3px;
    padding: 4px;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    justify-content: center;
}
