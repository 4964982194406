.container {
    height: 100%;
    padding-top: 32px;
    box-sizing: border-box;
}

.invite-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.form-inputs {
    overflow-y: auto;
    /* Hack to move scroll bar away from form inputs */
    margin-right: -16px;
    padding-right: 16px;
}

.form-input {
    flex: 1;
}

.label {
    display: block;
    color: var(--grey-dk);
    font-size: 14px;
    margin-bottom: 8px;
}

.add-another-container {
    margin: 4px 0 24px 0;
}

.divider {
    margin: 16px auto;
    background-color: var(--off-white-dk);
    width: 64px;
    height: 2px;
}

.input-group {
    position: relative;
}

.input-group:not(:first-child) {
    margin-top: 8px;
}

.input-group--removeable {
    composes: input-group;
    display: flex;
    align-items: center;
}

.btn-invite {
    position: absolute !important;
    bottom: 16px;
    right: 14px;
}

.sent-icon {
    margin: 32px auto;
    font-size: 36px;
    color: white;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 72px;
    background-color: var(--green);
}
