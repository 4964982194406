.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -32px;
}

.empty-state-container > * {
    margin: 0;
}

.security-message-container {
    display: flex;
    align-items: center;
}

.security-message-container > span {
    margin-right: 8px;
}
