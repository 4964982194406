.todo-header-date-bucket {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    padding: 3px 0;
    user-select: none;
    background-color: var(--off-white);
    cursor: pointer;
}

.todo-header-date-bucket:hover {
    background-color: #fff;
}

.todo-header-date-bucket--selected {
    composes: todo-header-date-bucket;
    background-color: #fff;
    cursor: default;
}

.todo-header-date-bucket--selected .text,
.todo-header-date-bucket--selected .count {
    color: var(--grey-dk);
}

.todo-header-date-bucket--disabled {
    composes: todo-header-date-bucket;
    cursor: default;
}

.todo-header-date-bucket--disabled:hover {
    background-color: var(--off-white);
}

.label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    color: var(--grey);
    margin-bottom: 1px;
}

.count {
    color: var(--grey);
    font-size: var(--font-normal);
}

.todo-header-date-bucket .count--overdue {
    font-size: var(--font-normal);
    color: var(--rose);
}
