.transparent-overlay {
	position: absolute;
	z-index: 1000;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 0.6;
	background-color: white;
}
