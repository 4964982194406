.modal {
    position: fixed;
    z-index: 9000;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.body {
    z-index: 1; /* Above overlay. */
}

.underlay {
    background-color: var(--color-modal-overlay);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}
