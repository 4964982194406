.container {
    padding: 10px 38px 3px 10px; /* Lots of padding right to allow for calendar icon */
    display: flex;
    flex-wrap: wrap;
}

.container > * {
    margin-bottom: 8px;
    margin-right: 10px;
}

.container-requesting {
    composes: container;
    position: relative;
}

.container-requesting:before {
    position: absolute;
    content: '';
    z-index: 1;
    background-color: #fff;
    opacity: 0.3;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
