.fsp-picker__brand-container {
    visibility: hidden;
}

/* A little extra specificity to override default filestack styles */
div.fsp-drop-pane__container {
	width: auto;
	border: none;
	background-color: transparent;
	padding: 0;
}

div.fsp-drop-pane__text {
	display: none;
}

.fsp-drop-pane__container progress {
	display: none;
}
