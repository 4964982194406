.file {
    display: flex;
    position: relative;
    padding: 8px 0 8px 8px;
    box-sizing: border-box;
    border-top: 1px solid var(--off-white-dk);
    align-items: center;
}

.file--no-border {
    border-top: none;
}

.file:last-child {
    border-bottom: 1px solid var(--off-white-dk);
}

.file--no-border:last-child {
    border-bottom: none;
}

.file-contents {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.file--removable .file-contents {
    max-width: calc(
        100% - 72px
    ); /* full width minus 32px for remove button and 40px for file icon, fixes file name truncation */
}

.file-row {
    display: flex;
    padding-right: 8px;
    margin-bottom: 4px;
}

.file-row a:only-child {
    width: fit-content;
    max-width: calc(100% - 8px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.file-name {
    font-size: var(--font-small);
    margin: 0;
}

.file-row > :first-child {
    flex-grow: 1;
    font-size: var(--font-small);
}

.file-row:last-child {
    margin-bottom: 0;
}

.timestamp {
    color: var(--off-white-dk);
    flex-shrink: 0;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.overlay-text {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-normal);
    color: var(--grey-dk);
}

.overlay-text .processing {
    margin-left: 8px;
    color: var(--grey);
}

.remove-button {
    display: flex;
    align-items: center;
    height: 100%;
}

.download-button {
    padding: 5px 10px 0 10px;
}

.download-button a {
    color: var(--grey-lt);
}

.download-button:hover a {
    color: var(--grey);
}
