@import '_form-uniform';

.eip-container {
	.empty {
		display: none;
	}

	&.type-textarea {
		.value {
			white-space: pre-wrap;
		}
	}
	&.state-empty {
		.empty {
			color: @grey;
			display: inline;
			font-style: italic;
		}
	}
	&.state-editing {
		.empty {
			display: none;
		}
	}

	&.type-string {
		.button-eip.action-edit {
			right: 15px;
		}
	}
}

input.new-minimal,
textarea.new-minimal,
.eip-field input[type='text'],
.eip-field textarea {
	display: inline-block;
	#util > .border-box();
	font-family: inherit;
	font-size: 13px;
	border: 1px solid @off-white-dk;
	border-radius: 2px;
	outline: none;
	padding: 2px 4px;
	margin: 0 0 3px 0;
	background: none;
	resize: none;
	&:hover {
		background: white;
		border: 1px solid @grey-lt;
	}
	&:focus {
		background: white;
		outline: none;
		border: 1px solid @blue;
	}
}

////////
//
//	Added 10/13/14 by @ccain, used on /my-account pages, /setup/integration pages.
//
////////
.orange-outline {
	#util > .border-box;
	background: white;
	border: 1px solid @form-border;
	border-radius: 3px;
	margin: 0;
	width: 100%;
	padding: 5px 8px;
	color: @grey;
	margin: 0;
	&:hover {
		border: 1px solid @grey-lt;
	}
	&:focus {
		outline: none;
		background: white;
		border: 1px solid @blue;
	}
}

// for placeholder.js
span.placeholder_wrapper {
	display: inline-block;
	position: relative;
	width: auto;

	span.placeholder_overlay {
		color: @grey-lt;
		display: block;
		cursor: text;
		position: absolute;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.placeholder_field {
		width: 100%;
	}

	&.state-focus {
		span.placeholder_overlay {
			opacity: 0.5;
		}
	}
}

// eip dates
.eip-container.type-date {
	.eip-input {
		position: absolute;
		z-index: 9101; // higher than modals and popovers (who invented this scale?)
	}
	.button-eip.action-edit {
		margin: 0 0 -2px 4px;
		#icon > .eip-date;
		&:hover {
			#icon > .eip-date-hover;
			background-position: 0 0;
		}
		&:active {
			background-position: 0 0;
		}
	}
}

// eip times (copied / modified from timePicker.css)
div.time-picker {
	position: absolute;
	height: 191px;
	width: 4em; /* needed for IE */
	background: white;
	border: 1px solid @grey-lt;
	z-index: 9800;
	margin: 0;
	.time-picker-container {
		overflow: auto;
		height: 191px;
		ul {
			height: inherit;
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				cursor: pointer;
				height: 10px;
				font-size: 12px;
				padding: 4px 3px;
				color: gray;

				&.selected {
					background: @off-white;
					color: black;
				}
			}
		}
	}

	.popover-nub {
		.timeline-sprite();
		background-position: -280px top !important;
	}
}
div.time-picker-12hours {
	width: 6em; /* needed for IE */
}

input.tiny {
	background-color: white;
	border: 1px solid @off-white-dk;
	padding: 2px;
	margin: 0;
}
input.tiny.validate-error {
	border: 1px solid @rose-dk !important;
	color: @rose-dk;
}
input.minimal,
input.minimal:focus,
textarea.minimal,
textarea.minimal:focus,
.input-minimal input,
.input-minimal input:focus,
.input-minimal textarea,
.input-minimal textarea:focus {
	font-family: inherit;
	font-size: inherit;
	border: none !important;
	outline: none;
	padding: 0 !important;
	margin: 0 !important;
	background: none;
	resize: none;
}
input.type-currency {
	text-align: right;
}
input.type-radio {
	border: none !important;
}
input.type-copyable {
	width: 100%;
	display: block;
}

// for a dl.form-standard in a normal body div
div.form-standard-wrapper {
	background: white;
	border: 1px solid @off-white-dk;
	border-top: none;
	#util > .rounded-corners(2px, bottom);
	padding: 14px;
	dl.form-standard dt {
		width: 110px;
	}
	dl.form-standard dd {
		margin-left: 118px;
	}
}

// .input-rounded, see include.less

// maybe these should be tables instead. they need a JS helper to adjust dt width
dl.form-standard {
	// i'd like to add a clearfix here, but i haven't confirmed it's okay everywhere, yet -- added for linkedin modal
	dt {
		float: left;
		clear: left;
		width: 100px;
		margin-bottom: 12px;
		text-align: right;
		font-weight: 500;
		line-height: 20px;

		span {
			color: lighten(@grey-lt, 10%);
			font-size: @small;
			font-weight: normal;
		}
	}
	dd {
		padding-left: 8px;
		margin-left: 108px;
		margin-bottom: 12px;
		line-height: 20px;

		&.variant-highlighted {
			input {
				background-color: @blue-accessible-bg;
			}
		}

		input, select {
			outline: none;
			#util > .input-rounded;
			&.state-disabled {
				color: @grey-lt;
				background-color: @off-white;
			}

			&:hover {
				outline: none;
				border: 1px solid @grey-lt;
			}

			&:focus {
				outline: none;
				border: 1px solid @blue;
			}
		}

		div > .flex-item {
			padding-right: 24px;
		}

		div > .flex-item > .flex-header {
			padding: 4px 0;
		}
	}
	&.variant-label-above {
		dt {
			float: none;
			text-align: left;
			width: auto !important;
		}
		dd {
			margin-left: 0 !important;
			padding-left: 0;
			margin-bottom: 6px;
		}
	}
	&.variant-wide-label {
		dt {
			width: 140px;
		}
		dd {
			margin-left: 148px;
		}
	}
}
