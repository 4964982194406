.input-column {
    padding: 16px 4px;
}

.input-column input {
    text-align: right;
}

.text-left input {
    text-align: left;
}

.discount-input {
    width: 60px;
}

/* This is to prevent shifting of the table when the toggle isn't visible */
.column-header {
    min-height: 21px;
}

.template-text {
    position: absolute;
    left: calc(50% - 320px);
    top: 80px;
    width: 640px;
}
