.checkbox-button {
    composes: button from '../button.css';
    cursor: pointer;
    font-size: var(--font-small);
}

.label {
    display: flex;
    align-items: center;
}

.label > :nth-child(2) {
    margin-left: 4px;
}

.small {
    composes: checkbox-button;
    padding: 4px 10px;
}

.normal {
    composes: checkbox-button;
    padding: 7px 12px;
}

.unchecked {
    composes: checkbox-button;
    color: var(--grey);
    background-color: var(--button-secondary);
}

.checked {
    composes: checkbox-button;
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
}

.disabled {
    composes: checkbox-button;
    color: var(--grey-lt);
    background-color: var(--off-white);
}

.checkbox-button:hover:not(.disabled),
.checkbox-button:focus:not(.disabled) {
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
}

.checkbox-container {
    padding-right: 8px;
    display: flex;
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
}
