.container {
    padding: 5px 10px;
    height: 100%;
}

.container:hover,
.container:focus {
    color: var(--grey-dk);
}

.container--minimize,
.container--maximize {
    composes: container;
}

/* Flipping minimize button to be an enlarge button */
.container--maximize {
    transform: rotate(180deg);
}

.container--light {
    composes: container;
}

/* Regular (Dark) variant */
.container span {
    color: var(--grey);
}

.container:hover span,
.container:focus span {
    color: var(--grey-dk);
}

/* Medium variant */
.container--medium span {
    color: var(--button-secondary-dk);
}

.container--medium:hover span,
.container--medium:focus span {
    color: var(--grey-lt);
}

/* Light variant*/
.container--light span {
    color: var(--white);
}

.container--light:hover span,
.container--light:focus span {
    color: var(--off-white-dk);
}
