.container {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 30px;
    color: var(--grey-lt);
    cursor: pointer;
    user-select: none;
}

.container:hover {
    color: var(--grey-dk);
}

.container-empty {
    composes: container;
    cursor: default;
}

.container-selected,
.container-comparison-selected,
.container-hovered,
.container-disabled,
.container-no-radius,
.container-other-month {
    composes: container;
}

.hover-container {
    font-size: 13px;
    color: var(--grey-dk);
    width: 100%;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-today {
    composes: container;
}

.container-today .hover-container {
    font-weight: 500;
}

.container-no-radius .hover-container {
    height: 100%;
}

.container-disabled {
    cursor: default;
}

.container-disabled .hover-container,
.container-other-month .hover-container {
    color: var(--off-white-dk);
}

.container-other-month:hover .hover-container {
    color: var(--grey-lt);
}

.container-selected .hover-container {
    background-color: var(--blue);
    color: #fff;
}

.container-comparison-selected .hover-container {
    background-color: var(--orange);
    color: #fff;
}

.container-selected .hover-container:hover {
    color: var(--grey-dk);
}

.container-comparison-selected .hover-container:hover {
    color: var(--orange-dk);
}

.container:hover:not(.container-disabled) .hover-container,
.container-selected:hover .hover-container,
.container-hovered.container-selected:hover .hover-container,
.container-hovered .hover-container {
    background-color: var(--blue-bg);
}

.container-hovered.container-selected:hover .hover-container {
    color: var(--grey-dk);
}

.container-hovered.container-selected .hover-container {
    background-color: var(--blue);
}
