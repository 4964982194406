.confidence-container {
    display: inline-block;
    cursor: pointer;
    /* Fixed width so the popover doesn't jump
    around when the number of digits changes */
    width: 40px;
}

.confidence-container:hover {
    text-decoration: underline;
}

.confidence-slider-container {
    padding: 8px 16px;
}
