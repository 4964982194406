.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    margin-top: 100px;
}

.loading {
    fill: var(--green);
}
