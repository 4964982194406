.container {
    padding: 0 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.container:first-child {
    padding-left: 0;
}

.container:hover {
    color: var(--grey-dk);
}

.lightbulb-container {
    padding: 0 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
