.container {
    display: flex;
    flex-direction: row;
    position: relative;
    list-style-type: none;
    padding: 0 16px;
    box-sizing: border-box;
    min-height: 50px;
    background-color: white;
}

.container-selected {
    composes: container;
    background-color: var(--color-list-selected);
}

.container-clickable {
    composes: container;
}

.container-clickable:hover,
.container-clickable:focus {
    cursor: pointer;
    background-color: var(--color-list-hovered);
}

.container-bordered {
    composes: container;
}

.border-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 0;
}

.container-bordered .border-container {
    padding: 12px 0;
    border-bottom: 1px solid #f1f1f1; /* TODO: standardize color */
}

.left-column {
    flex: 1 1 0%;
    min-width: 0;
}

.right-column {
    min-width: 0;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.action-menu-container {
    padding: 0 0 0 16px;
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    width: 37px; /* Width of action menu button, to keep aligned when no button */
    margin-right: -16px; /* Account for 16px padding of action menu button */
}

.error {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
}
