.fields-basic-info > *:not(:first-child) {
    margin-top: 8px;
}

.field-activity-types {
    padding-bottom: 100px;
}

.field-activity-types-list {
    padding-bottom: 8px;
}

.field-activity-types-list > li {
    position: relative;
}

.field-activity-types-list > li:not(:first-child) {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--off-white-dk);
}

.activity-type-field,
.custom-field-field {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

.activity-type-field-actions {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.card-container {
    padding-bottom: 100px;
}
