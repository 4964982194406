.field-list {
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 16px;
    background-color: var(--off-white);
}

.list-item:not(:first-child) {
    margin-top: 8px;
}

.ignored-card-container {
    margin-top: 8px;
}

.ignored-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--off-white);
    padding: 8px;
}
