.qr-image {
    width: auto;
    height: 110px;
}

.qr-container {
    border: var(--form-border);
    box-shadow: 0 1px 2px 1px rgba(28, 25, 23, 0.15);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    padding: 4px 0;
    height: 110px;
}

.mfa-code {
    margin-top: 12px;
}

.send-again-button {
    font-size: 15px;
}
