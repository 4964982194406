.textfield-with-checkbox {
    position: relative;
}

.checkbox-container {
    position: absolute;
    display: flex;
    align-items: center;
    right: 16px;
    top: 0;
    bottom: 0;
}
