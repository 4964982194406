.submission {
    display: flex;
    margin-bottom: 8px;
    flex-direction: column;
}

.submission button,
.form-submissions-section button {
    width: fit-content;
    padding: 0;
    text-align: left;
}

.more-submissions-container {
    color: var(--grey-lt);
}
