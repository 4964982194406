.header-container {
    display: flex;
    flex-shrink: 0;
    border-top: 1px solid var(--off-white-dk);
    border-left: 1px solid var(--off-white-dk);
    border-radius: 4px 0 0 0;
    margin-top: 16px;
    margin-left: 16px;
    padding: 8px 16px;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
}

.header-controls {
    display: flex;
    flex-direction: row;
}

.header-controls :global(.ui-btn) {
    /* Complete hack for now, I just think this padding looks better on the save button */
    padding: 6px 24px !important;
}

.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 21px;
    font-weight: 500;
    line-height: 26px;
    color: var(--grey-dk);
}

.date-range-picker {
    margin: 1px 16px;
}

.filter-section {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

:global(#report-content-scroll-container) {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    background-color: var(--off-white);
    height: 100%;
    position: absolute;
    top: 0;
}

.container {
    width: 100%;
    right: 0;
    z-index: 11;
    transition: width 200ms var(--custom-easing);
}

.container > div:first-child {
    padding: 0 16px 32px 16px;
}

.container--narrow {
    composes: container;
    width: calc(100% - var(--filter-drawer-width));
}
