.container {
    width: 300px;
}

.header,
.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 8px 16px;
}

.header {
    height: 53px; /* 53px is the height of the card header */
    box-sizing: border-box;
    border-bottom: 1px solid var(--off-white-dk);
}

.row:hover {
    background-color: var(--color-list-hovered);
}

.truncated {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 270px;
}
