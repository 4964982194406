.results {
    background-color: #fff;
    border: none;
    border-radius: 5px;
}

.results h2 {
    font-weight: normal;
}
.results h3 {
    font-size: 13px;
    letter-spacing: 0.5px;
    padding: 6px 10px;
}
.results ul {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 490px;
}

.results ul:last-child {
    border-radius: 5px;
}

.location-results {
    composes: results;
    width: 250px;
}

.location-results ul {
    height: 200px;
}

.entity-results {
    composes: results;
    width: 350px;
}

.entity-results ul {
    height: 210px;
}
