.audience-container:not(:last-child) {
    margin-bottom: 16px;
}

.audience-container a {
    font-weight: normal;
}

.archived {
    color: var(--grey-lt);
    font-weight: normal;
}

.action-element {
    display: flex;
    align-items: center;
}

.action-element > button {
    padding-right: 0; /* This places the button 16px from the right border of the card */
}
