.slideshow-container {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slide-container {
    width: 100%;
    height: 100%;
}

.slide-container:not(:only-child) {
    margin-bottom: 4px;
}
