.container {
    display: grid;
    grid-template-columns: 300px auto;
    grid-gap: 16px;
    height: 100%;
}

.button-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.subtitle {
    padding: 0 64px;
}

.list {
    text-align: left;
}
