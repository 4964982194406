

.capsule-container {
	position: relative;
	#util > .inline-block;

	.capsule {
		position: relative;
		font-size: @small;
		color: @grey-dk;
		margin-left: 0px;
		margin-right: 4px;
		white-space: nowrap;
		#util > .inline-block;
		background: @off-white-md;
		border-radius: 3px;
		cursor: pointer;
		z-index: 1;
		height: 21px;
		max-width: 194px;
		.capsule-button {
			z-index: 2;
			margin-right: 0px;
			height: 20px;
			margin-right: -10px;
			span {
				float: left;
				z-index: 100;
				padding-right: 15px;
				padding-left: 26px;
				line-height: 21px;
				max-width: 172px;
				#util > .excerpt-crop > .ellipsis;
			}
			a { text-decoration: none; }
		}

		.icon {
			position: absolute;
			top: 2px;
			left: 5px;
			line-height: 20px;
			width: 16px;
			height: 16px;
			.2x('@{img-root}/icons/capsule-sprite.png', "no-repeat", 176px, 16px);
		}

		&.capsule-type-territory .icon    { background-position: left center; }
		&.capsule-type-user .icon         { background-position: 16px * -1 center; }
		&.pending-user .icon              { filter: brightness(.7); }
		&.capsule-type-assignment .icon   { background-position: 16px * -2 center; }
		&.capsule-type-source .icon       { background-position: 16px * -3 center; }
		&.capsule-type-contact .icon      { background-position: 16px * -4 center; }
		&.capsule-type-account_type .icon { background-position: 16px * -5 center; }
		&.capsule-type-account .icon      { background-position: 16px * -5 center; }
		&.capsule-type-industry .icon     { background-position: 16px * -6 center; }
		&.capsule-type-team .icon         { background-position: 16px * -7 center; }
		&.capsule-type-product .icon      { background-position: 16px * -8 center; }
		&.capsule-type-competitor .icon   { background-position: 16px * -9 center; }
		&.capsule-type-lead {
			// Hide lead icon in capsules as its misleading
			// The primary lead icon is green and 2/3 full
			// Which is misleading to the user as a lead could be red (overdue) and at any stage in the sales process
			.icon {
				display: none;
			}
			span.name {
				padding-left: 6px;
			}
		}
	}
	.delete {
		position: absolute;
		top: 1px;
		left: 5px;
		width: 19px;
		height: 19px;
		cursor: pointer;
		.2x('@{img-root}/capsules/icon-remove.png', "no-repeat", 19px, 19px);
		z-index: 1;
	}
	.delete.state-visible {
		left: -18px;
		opacity: 0.8;
		&:hover { opacity: 1; }
	}

	&.state-deleted {
		.name {
			text-decoration: line-through;
		}
	}
}

.capsule-add-container {
	position: relative;
	#util > .inline-block;

	.capsule-add {
		#util > .rounded-corners(3px);
		border: 1px solid @grey-lt;
		padding: 2px 3px;
		z-index: 50;
		margin: -1px 3px 0px -1px;
		position: relative;
		&:hover { background: white; }

		.icon {
			display: block;
			float: left;
			margin: 1px 5px 0px 1px;
		}

		input { width: 105px; }
	}

	&.capsule-state-focus,
	&.autocomplete-state-focus {
		.capsule-add {
			background: white;
			border: 1px solid @blue;
			border-radius: 0;
			#util > .rounded-corners(3px, top);
			z-index: 60;
		}
	}
	&.autocomplete-state-empty {
		.capsule-add { #util > .rounded-corners(3px); }
		ul {
			display: none !important;
		}
	}


	ul {
		display: none;
		z-index: 59; // just lower than .builder .adder's focus state
		position: absolute;
		left: -1px;
		right: 3px;
		background-color: white;
		border: 1px solid @blue;
		border-top: 0;
		top: 22px;
		#util > .rounded-corners(3px, bottom);
		-webkit-background-clip: border-box;
		max-height: 220px;
		overflow-x: hidden;
		overflow-y: auto;

		li {
			padding: 4px 7px 2px 27px;
			line-height: 18px;
			min-height: 20px; // For when an entity has no name
			cursor: pointer;
			position: relative;
			#util > .excerpt-crop > .ellipsis;

			.text { #util > .excerpt-crop > .ellipsis; }

			.icon {
				display: block;
				position: absolute;
				left: 4px;
				top: 5px;
				width: 16px;
				height: 16px;
				.2x('@{img-root}/icons/capsule-sprite.png', "no-repeat", 176px, 16px);
			}
			&:hover {
				color: @grey;
				background: @blue-bg;
			}
		}
		li.autocomplete-state-selected {
			color: @blue;
			background-color: @blue-bg;
			font-weight: 500;
		}
		li.autocomplete-state-selected.autocomplete-result-first {
			border-top: 10px solid @off-white-dk; /* TODO: standardize color */
			margin-top: -10px;
		}
		li.autocomplete-state-selected.autocomplete-result-last {
			#util > .rounded-corners(9px, bottom);
			-webkit-background-clip: padding-box;
		}

		li.autocomplete-item-type-contact {
			color: @navy-dk;
			.icon {
				background-position: 16px * -4 center;
			}
		}
		li.autocomplete-item-type-source {
			color: @grey; /* TODO: standardize color */
			.icon {
				background-position: 16px * -3 center;
			}
		}
		li.autocomplete-item-type-user {
			.icon {
				background-position: 16px * -1 center;
			}
		}
		li.autocomplete-item-type-assignment {
			color: @orange-dk;
			.icon { #icon > .capsule-type-assignment }
		}
		li.autocomplete-item-type-team {
			.icon {
				background-position: 16px * -7 center;
			}
		}
		li.autocomplete-item-type-account {
			color: @plum-dk;
			.icon {
				background-position: 16px * -5 center;
			}
		}
		li.autocomplete-item-type-product {
			color: @brown-dk;
			.icon {
				background-position: 16px * -8 center;
			}
		}
		li.autocomplete-item-type-competitor {
			color: @rose-dk;
			.icon {
				background-position: 16px * -9 center;
			}
		}
		li.autocomplete-item-type-territory {
			color: @grey; /* TODO: standardize color */
			.icon {
				background-position: left center;;
			}
		}
	}
	&.hidden { display: none; } // hack for specificity :(
}
