.email-composer-toolbar {
    position: relative;
    display: flex;
    align-items: center;

    /* Remove borders, we'll manage this from the container, if we want it */
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-top: 1px solid var(--off-white) !important;
    flex-shrink: 0;
    z-index: 10;
    width: 100%;
}

.email-composer-toolbar :global(button) {
    fill: var(--grey-dk);
}

.email-composer-toolbar :global(button:not(:disabled):hover) {
    fill: var(--grey-lt);
}

.email-composer-toolbar :global(button.ql-active) {
    fill: var(--blue);
}

.email-composer-toolbar .use-signature {
    position: absolute;
    right: 20px;
    /* These next four properties needed for IE11 */
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}

.ql-placeholder {
    width: 140px;
}

.ql-placeholder :global(span.ql-picker-label::before) {
    content: 'Insert field…';
}

.ql-placeholder :global(span.ql-picker-options > span.ql-picker-item::before) {
    content: attr(data-label);
    padding: 0 8px;
    color: var(--blue);
}

.ql-placeholder :global(span.ql-picker-options > span.ql-picker-item:hover) {
    background-color: var(--blue);
}

.ql-placeholder:not(.ql-placeholder--without-custom)
    :global(span.ql-picker-options > span.ql-picker-item:last-child:hover) {
    background-color: var(--orange);
}

.ql-placeholder:not(.ql-placeholder--without-custom)
    :global(span.ql-picker-options > span.ql-picker-item:last-child::before) {
    color: var(--orange);
}

.ql-placeholder :global(span.ql-picker-options > span.ql-picker-item:hover::before) {
    color: white;
}

.ql-placeholder :global(span.ql-picker-options > span.ql-picker-item:last-child:hover::before) {
    color: white;
}

.ql-placeholder :global(span.ql-picker-options) {
    margin-top: -100% !important;
    padding: 4px 0;
}

.ql-placeholder-option {
    background-color: red;
}

/* The select popup needs different styles with no custom placeholders */
.ql-placeholder--no-custom {
    composes: ql-placeholder;
}

/* Fix the color of the last option in the placeholder menu */
.ql-placeholder--no-custom:not(.ql-placeholder--without-custom)
    :global(span.ql-picker-options > span.ql-picker-item:last-child:hover) {
    background-color: var(--blue);
}

.ql-placeholder--no-custom:not(.ql-placeholder--without-custom)
    :global(span.ql-picker-options > span.ql-picker-item:last-child::before) {
    color: var(--blue);
}

.ql-placeholder--no-custom
    :global(span.ql-picker-options > span.ql-picker-item:last-child:hover::before) {
    color: white;
}

.ql-placeholder--no-custom :global(span.ql-picker-options) {
    /* 82% is just right here :shrug: */
    margin-top: -82% !important;
    padding: 4px 0;
}
