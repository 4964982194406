#lead {
	.timeline-upcoming {
		.timeline-entry {
			cursor: pointer;
			height: 36px;
			padding-bottom: 0;
			border-bottom: 1px solid @off-white-dk;

			&:hover {
				background: white;
			}

			.first-line {
				position: absolute;
				top: 10px;
				// swap the right and left sides (lol semantics)
				.left-side {
					left: 300px;
					width: 270px; // a bit narrower, since we're not as wide as a full timeline
				}
				.right-side {
					left: 0;
					.subject {
						font-size: 16px;
					}
				}
			}

			.gutter {
				.dot,
				.line {
					display: none;
				}
				.gutter-content {
					margin-top: 6px;
					background-color: transparent;
				}
				.date,
				.time,
				.year {
					text-align: left;
					padding-left: 8px;
				}
			}

			.icon,
			.content {
				display: none;
			}
		}
	}
}

.activities-container {
	h2.section-label {
		margin-bottom: 1px !important; // Allows for better spacing of first activity
	}
	.ui-create-new {
		cursor: pointer;
		#util > .clearfix();
		margin-top: 10px;
		padding: 3px 10px;
		line-height: 25px;
		background-color: @off-white-md;
		border-radius: 3px;

		.ui-create-new__text {
			font-weight: 500;
			font-size: 13px;
			color: @grey;

			.plus {
				margin: 0 5px;
			}
		}

		&:hover {
			background-color: darken(@off-white-md, 5%);
		}
	}
}
