.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 15px;
    min-height: 44px;
    font-size: 15px;
}

.container:nth-child(2n) {
    background-color: var(--zebra-stripe);
}

.position {
    margin-right: 12px;
    display: flex;
    color: var(--grey-lt);
    justify-content: center;
    align-items: center;
}

.avatar-entity-container {
    display: flex;
    flex: 2;
}

.avatar {
    margin-right: 10px;
}

.entity {
    flex: 1;
    display: flex;
    align-items: center;
}

.disabled-entity {
    composes: entity;
    text-decoration: line-through;
    color: var(--orange-dk);
}

.entity :global(.entity.type-users) {
    color: var(--grey-dk);
}

.sales {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    box-sizing: border-box;
    color: var(--grey);
}

.totals {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    box-sizing: border-box;
    min-width: 50px;
    color: var(--grey-lt);
}
