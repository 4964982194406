.location-empty-view {
    height: 160px;
    text-align: center;
    margin-top: 40px;
}

.title {
    line-height: 25px;
    color: @grey;
    width: 75%;
    margin: 0 auto;
}

.image {
    width: 100px;
    margin-top: 15px;
}
