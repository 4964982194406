.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: white;
    border-bottom: 1px solid var(--off-white-dk);
    padding: 8px 16px;
    box-sizing: border-box;
    align-items: center;
}

.container > *:nth-child(1) {
    justify-self: start;
}

.container > *:nth-child(2) {
    justify-self: center;
}

.container > *:nth-child(3) {
    justify-self: end;
}
