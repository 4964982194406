.list-view-infinite {
    height: 100%;
    box-sizing: border-box;
    background-color: var(--off-white);
}

.list-view-infinite-scroll-container {
    flex: 1;
    display: flex;
    height: 100%;
}

.list-view-infinite-scroll-container div:focus {
    outline: none;
}

.list-view-infinite-special-state-container {
    flex: 1;
    padding-top: 24px;
    height: 100%;
    background-color: white;
}
