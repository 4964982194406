.engagement-stats-outer-container {
    position: relative;
}

.engagement-stats-inner-container {
    position: absolute;
    right: 0;
    /* This value just work */
    top: -38px;
}
