.accent-border {
    height: 100%;
    border-width: 3px;
    box-sizing: border-box;
}

.top {
    border-top-style: solid;
}
.left {
    border-left-style: solid;
}
.bottom {
    border-bottom-style: solid;
}
.right {
    border-right-style: solid;
}
