.header-text {
    width: 100%;
}

.container {
    margin: 0 -16px;
    height: 100%;
    overflow: hidden;
}

.saved-filter-list {
    height: calc(100% - 50px); /* 50px search header, 32px padding */
    position: relative;
}

.saved-filter-list > div {
    height: 100%;
}

.saved-filter-list ul {
    height: 100%;
    overflow: auto;
}

.saved-filter-row--header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 16px 0 8px;
    box-sizing: border-box;
    font-size: var(--font-normal);
}

.flash-banner {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 0;
}
