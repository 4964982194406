@value buttonHeight: 33px;
@value buttonWidth: 142px;

.column-picker-button {
    position: absolute;
    top: 0;
    right: 0;
    width: buttonWidth;
    height: buttonHeight;
    z-index: 1;
}

.column-picker-button button {
    height: buttonHeight;
    border-radius: 0 !important;
    min-width: buttonWidth !important;
    background-color: white !important;
    border-left: 1px solid var(--off-white-dk);
}

.column-picker-button button svg {
    fill: var(--grey);
}

.column-picker-button button:hover {
    background-color: var(--off-white) !important;
}

.column-picker-button button:hover svg {
    fill: var(--grey-dk);
}
