@import 'include';

body#report-analytics-2017 {
	.quickreports {
		height: ~'calc(100% - 66px)';
		width: 100%;
		overflow: auto;
		position: absolute;
	}
	.quickreport {
		clear: left;
		overflow: hidden;
		background-color: white;
		border-top: 1px solid @off-white-dk;
		padding: 32px 16px 48px 16px;
		position: relative;
		&:first-child {
			border-top: none;
		}

		// graph heading
		h4 {
			font-size: 19px;
			font-weight: 500;
			color: @grey-dk;
			display: inline-block;
			margin: 0 12px 6px 0;
		}
		&.quickreport-winrate h4,
		&.quickreport-effort h4 {
			display: inline-block; // allow label + checkbox to display inline with graph heading
			color: @grey-dk;
			margin: 0 12px 6px 0;
		}
		// hide loading... micro copy
		.loading-mask {
			display: none;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: white;
			#util > .opacity(0.8);
			text-align: center;
			padding: 60px;
		}

		// show loading... micro copy when container has a class of .state-loading
		&.state-loading .loading-mask {
			display: block;
		}
	}

	.report-description {
		display: block;
		font-size: @small;
		font-weight: normal;
		font-style: italic;
		color: @grey;
	}

	// shared
	.quickreport .legend {
		table {
			td {
				border: none;
				padding: 0;

				&.legendLabel {
					padding-left: 4px;
					width: 90px;
				}
			}
		}
	}

	.quickreport:not(.quickreport-winrate) .legend {
		position: absolute;
		left: 350px;

		table {
			width: 280px;
			.legendLabel {
				font-size: @small;
			}

			.legendColorBox {
				width: 18px;
			}
			.legendColorBox:first-child > div {
				background-color: #61b2de;
			}
			.legendColorBox > div {
				width: 13px;
				height: 13px;
				background-color: #ff7833;
				margin: 0;
				float: left;
				border-width: 0 !important;
			}
		}
	}

	.quickreport-winrate .legend {
		position: absolute;
		left: 412px;

		table {
			width: 280px;
			.legendLabel {
				font-size: @small;
			}

			.legendColorBox {
				width: 18px;
			}
			.legendColorBox:first-child > div {
				background-color: @off-white-dk;
			}
			.legendColorBox:nth-child(3) > div {
				background-color: #5cce73;
			}

			.legendColorBox > div {
				width: 13px;
				height: 13px;
				background-color: #61b2de;
				margin: 0;
				float: left;
				border-width: 0 !important;
			}
		}
	}

	.graph {
		float: none;
		width: auto;
		height: 200px;
		margin: 24px 0 24px -4px;
		padding: 0;
	}

	.stats {
		position: absolute;
		overflow: hidden;
		#util > .border-box;
		top: 32px;
		right: 16px;
		width: auto;
		float: none;
		margin: 0;
		padding: 0;
	}

	.stat {
		font-size: @normal;
		display: inline-block;
		margin-left: 24px;
		padding: 0 0 0 24px;
		border-left: 2px solid @off-white-dk;

		.value {
			font-size: 19px;
			font-weight: 500;
			color: @grey-dk;
			display: inline-block;
			margin: 0;
		}
		.label {
			font-size: @normal;
			display: inline-block;
			margin: 0;
		}

		abbr {
			// Remove _normalize.less styles
			border-bottom: none;
		}

		.percentage {
			font-size: 19px;
			font-weight: 500;
			display: inline-block;
			color: @grey-dk;
			margin-left: 12px;

			.negative {
				display: none;
			} // no negative signs, since we use arrows - see below

			&:before {
				content: '\f0de';
				font-size: 21px;
				font-family: FontAwesome;
				color: @green;
				display: inline-block;
				width: 17px;
				position: relative;
				top: 6px;
			}

			&.percentage-decrease {
				color: @grey-dk;
				&:before {
					content: '\f0dd';
					color: @rose;
					top: -3px;
				}
			}
			&.variant-bad {
				color: @grey-dk;
				&:before {
					color: @rose;
				}
			}
			&.variant-good {
				color: @grey-dk;
				&:before {
					color: @green;
				}
			}
		}
	}

	.option {
		input[type='checkbox'] {
			margin-right: 4px;
		}
		&:hover {
			cursor: pointer;
			color: @grey-dk;
		}
	}

	.quickreport-winrate {
		.win-rate-won-lost {
			// The only chart with two values so we need this additional margin
			.value {
				margin-left: 12px;
			}
		}
		.legend table {
			// The only chart with 3 items in the legend so we need a larger width
			width: 340px;
		}
	}
}
