.container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    animation: fade-in 2000ms var(--custom-easing) 0s forwards;
}

.report-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    animation: fade-in 2000ms var(--custom-easing) 0s forwards;
    background-color: var(--white);
}

.empty-state-wrapper {
    opacity: 0.5;
}

.vertical {
    text-align: center;
}

.vertical .empty-state-wrapper > svg {
    display: inline-block;
    width: 780px;
    height: 340px;
    position: relative;
    z-index: 2;
}

.vertical-axes-container {
    position: absolute;
    left: 60px;
    bottom: 68px;
    z-index: 1;
    height: 192px;
    width: calc(100% - 120px);
}

.report-axes-container {
    composes: vertical-axes-container;
    bottom: 30%;
}

.vertical-axes-container .axes {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.vertical-axes-container .axes div {
    width: 100%;
    height: 2px;
    background-color: var(--off-white-md);
}

.scribbles {
    width: 24px;
    height: 166px;
    position: absolute;
    top: -3px;
    right: calc(100% + 5px);
}

.scribbles--right {
    composes: scribbles;
    right: auto;
    left: calc(100% + 5px);
}

.horizontal .empty-state-wrapper > svg {
    width: 380px;
    position: relative;
    z-index: 2;
}

.horizontal-axes {
    position: absolute;
    left: 130px;
    top: 65px;
    z-index: 1;
    height: 206px;
    width: calc(100% - 180px);
    display: flex;
    justify-content: space-between;
}

.horizontal-axes div {
    width: 2px;
    height: 100%;
    background-color: var(--off-white-md);
}

.message {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 3;
    width: 100%;
    margin-top: -9px;
    text-align: center;
}

.message span {
    padding: 16px 22px;
    border-radius: 6px;
    background-color: color(white alpha(80%));
    border: 2px solid color(var(--off-white-dk) alpha(25%));
    color: var(--grey-lt);
    font-size: var(--font-normal);
}

.flash-banner {
    width: calc(100% - 32px); /* Internal horizontal padding is 16px. */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    border-radius: 3px 3px 0 0;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
