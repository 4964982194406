@import 'include.less';

#settings {
	.page-flow-area {
		border-top: none;
		border-left: none;
	}
}

#settings .page-flow-area .form-standard {
	dt {
		width: 180px;
	}
	dd {
		margin-left: 200px;
		input[type='text'] {
			width: 300px;
			padding-left: 5px;
		}
	}
	#fiscalYear {
		width: 100px;

		padding: 0 6px;
		margin-left: 208px; // This looks good, just go with it.
		box-sizing: border-box;
		border: 1px solid @off-white-dk;
		color: black;
		border-radius: 4px;
		background-color: @off-white;
	}

	.business-hours {
		line-height: 25px;
		.eip-container {
			display: inline-block;
		}
		.eip-input {
			width: 70px;
		}

		#businessHoursStart,
		#businessHoursEnd {
			padding: 0 6px;
			border: 1px solid @off-white-dk;
			border-radius: 4px;
			color: black;
			background-color: @off-white;
		}

		a {
			margin-left: 8px;
		}
	}

	#defaultMarketId {
		a {
			margin-left: 8px;
		}
	}

	.upload-btn,
	.remove-upload-btn {
		margin-right: 10px;
		margin-top: 12px;
		display: inline-block;
	}

	.behavior-upload.qq-upload-button-hover {
		.link-blue-standard {
			text-decoration: underline;
			color: @blue-dk;
		}

		.link-grey-standard {
			text-decoration: underline;
			color: @grey-dk;
		}
	}

	.image-upload {
		margin-top: 10px;

		&.upload-square {
			margin-top: 8px;
		}
	}
}
// for the fiscal year setting: don't show year because it's not important
.ui-datepicker-year {
	display: none;
}

.upload-logo {
	position: relative;
	cursor: pointer;
	float: left;

	img {
		float: left;
		margin-right: 10px;

		&.logo-placeholder {
			display: none;
		}
	}
}
