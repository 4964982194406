.ui-funnel-report__loading-state {
	z-index: 10;
	position: absolute;
	text-align: center;
	left: 50%;
	top: 100px;
	width: 600px;
	margin-left: -300px;
	.svg-loading svg {
		fill: @grey-lt;
	}
}

.ui-funnel-report {
	padding: 0 16px;
	background-color: white;
	margin: 0 !important;

	.ui-funnel-report__filters-container {
		padding: 15px 0 15px 0 !important; // Override #report-header-options styles
		display: flex;
		justify-content: space-between;

		.ui-funnel-report__filter-container {
			flex-grow: 1;
		}

		.ui-funnel-report__filter-container > div {
			margin-bottom: 8px;
		}
	}

	.ui-funnel-report__filters-container {
		.ui-funnel-report__meta {
			float: right;
		}
	}

	#report-header-options {
		#util > .clearfix();

		.ui-funnel-report__filter-button-container {
			display: inline-block;
			line-height: 16px;

			.ui-funnel-report__filter-button {
				font-weight: normal;
				padding-left: 10px;
				.ui-funnel-report__filter-button__title {
					margin-right: 5px;
				}
				.fa-chevron-down {
					font-size: 11px;
					position: relative;
					top: -1px;
				}
			}
		}
	}

	.ui-funnel-report__subheader {
		border-bottom: 2px solid @off-white-md;
		padding: 15px 0 15px 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.ui-funnel-report__controls-container {
			margin-top: 3px;
			display: flex;
			align-items: center;
			.stageset-selector {
				z-index: 20; // Needs to be above 15
				display: inline-block;
				min-width: 150px;
				margin-right: 10px;
			}
		}

		.ui-funnel-report__controls {
			display: block;
			margin-right: 20px;
			float: left;
		}
	}
}

.ui-funnel-report__meta {
	margin-top: 3px;
}

.ui-funnel-report {
	min-width: 900px;
	max-width: 1200px;

	.ui-funnel-report__funnel {
		margin: 0 0 -20px 0;

		.ui-funnel-report__funnel-section {
			display: flex;
			position: relative;
			height: 180px;
			border-top: 2px solid @off-white-md;

			.ui-funnel-section__transition {
				display: flex;
				justify-content: center;

				.ui-funnel-arrow-divider__arrow-container {
					position: relative;
					width: 140px;

					.ui-funnel-arrow-divider__inner-arrow-container {
						position: absolute;
						z-index: 10;
						left: 0;
						width: 140px;

						.ui-funnel-section__arrow {
							display: flex;
							background-color: #f7fcf7;
							align-items: center;
							padding-top: 8px;
							border: 2px solid #d6f2de;
							border-bottom-width: 0;

							.ui-funnel-section__arrow-values {
								display: flex;
								justify-content: space-around;
								align-items: baseline;
							}

							.ui-funnel-number-label__container {
								padding-left: 8px;
							}
						}
					}
				}

				&.ui-funnel-section__transition--added {
					.ui-funnel-arrow-divider__inner-arrow-container {
						top: -57px;
					}

					.ui-funnel-section__arrow {
						min-height: 25px;
						flex-direction: row;
						justify-content: center;

						:nth-child(1) {
							padding-left: 4px;
							.ui-funnel-number-label__container,
							.ui-funnel-number-label__primary-text {
								padding-left: 0;
								position: relative;
								top: -2px; // Hack to make added label align to baseline
							}
						}
					}
				}

				&.ui-funnel-section__transition--advanced {
					.ui-funnel-arrow-divider__inner-arrow-container {
						top: 15px;
					}

					.ui-funnel-section__arrow {
						min-height: 47px;
						flex-direction: column;
						justify-content: space-around;
					}
				}

				.ui-funnel-arrow-divider__chevron-container {
					position: relative;

					.ui-funnel-arrow-divider__chevron-background {
						position: absolute;
						top: -7px;
						height: 10px;
						background-color: #f7fcf7;
						border-bottom: 2px solid #d6f2de;
						width: 68px;

						&.ui-funnel-arrow-divider__chevron-background--left {
							left: 2px;
							transform: skewY(8deg);
						}

						&.ui-funnel-arrow-divider__chevron-background--right {
							right: 2px;
							transform: skewY(-8deg);
						}
					}
				}
			}

			.ui-funnel-section__header {
				position: absolute;
				top: 0;
				#util > .clearfix();
				width: 100%;
				height: 30px;

				.ui-funnel-section__header__stage-name {
					margin-top: 16px;
					float: left;
					width: ~'calc((100% - 450px) / 2 - 70px)'; // 450px -> width of close area, 70px -> half of arrow
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.ui-funnel-section__main {
				#util > .clearfix();
				flex: 2;
				box-sizing: border-box;
				align-items: center;
				height: 180px;
				padding-top: 55px;
				border-right: 2px solid @off-white-md;
			}

			.ui-funnel-section__lead-movement {
				padding-top: 50px;
				float: left;
			}

			.ui-funnel-section__lead-movement-item {
				display: block;
				padding: 10px 0;
			}
		}
	}
}

.ui-funnel-section__main__total-closed-container {
	display: flex;
	height: 180px;
	width: 130px;
	align-items: center;

	.ui-funnel-report__total-closed {
		flex: 1;
		flex-direction: row;

		.ui-funnel-report__total-closed__triangle {
			float: left;
			height: 0;
			width: 0;
			border-style: solid;
			border-width: 15px 0 15px 20px;
			border-color: transparent transparent transparent @off-white-md;
			margin-right: 10px;
		}
		.ui-funnel-report__total-closed__label-container {
			flex-direction: column;

			.ui-funnel-report__total-closed__percent {
				font-size: @big;
				font-weight: 500;
				color: @grey-dk;
			}

			.ui-funnel-report__total-closed__label {
				font-size: @normal;
			}
		}
	}
}

.ui-funnel-section__main__total-leads-container {
	display: flex;
	position: relative;
	// Allow tooltip to be visible above the arrow divider.
	z-index: 11;
	justify-content: center;
	min-width: 254px;

	.ui-funnel-section__rectangle__container {
		position: relative;
		float: left;
		text-align: center;
	}

	.ui-funnel-section__rectangle__rectangle {
		height: 80px;
		box-sizing: border-box;
		border-radius: 3px;
		border: 2px solid #cfcfd6;
		background-color: #f5f5f7;
		margin: 0 10px;

		#util > .transition(width, 500ms, ease-in-out, 400ms);
	}

	.ui-funnel-section__rectangle__label-container {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 80px;
		top: 0;
		left: 10px; // Not sure why we need this be center aligns the number in the bar

		#util > .transition(width, 500ms, ease-in-out, 400ms);

		span {
			line-height: 17px;
		}
	}

	.ui-funnel-section__meta {
		position: absolute;
		z-index: 1;
		color: @grey;
		font-size: @normal;
		left: 0;
		box-sizing: border-box;

		&.ui-funnel-section__meta--previous {
			top: 18px;
		}
		&.ui-funnel-section__meta--current {
			bottom: 18px;
		}
	}
}

.ui-funnel-section__average-time {
	font-size: @normal;
	color: @grey-lt;
	position: relative;
	top: 10px;
}

.ui-funnel-report__close__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 310px;
	height: 180px;
	padding-right: 10px;

	.ui-funnel-section__rectangle__rectangle {
		#util > .transition(width, 500ms, ease-in-out, 400ms);
	}
}

.ui-funnel-report__close__bar-container {
	display: flex;
}

.ui-funnel-report__close__bar-background {
	align-self: flex-start;
	height: 36px;
	background-color: #f5f5f7;
}

.ui-funnel-report__close-container {
	height: 43px;
	display: flex;
	align-items: center;
}

.ui-funnel-report__close-container--won {
	display: flex;
	justify-content: space-between;
	flex: 1;

	.ui-funnel-section__rectangle__rectangle {
		height: 36px;
		background-color: @report-green;
	}
}

.ui-funnel-report__close-container--lost {
	display: flex;
	justify-content: space-between;
	flex: 1;

	.ui-funnel-section__rectangle__rectangle {
		height: 36px;
		background-color: @report-red;
	}
}

.ui-funnel-report__close-container--cancelled {
	display: flex;
	justify-content: space-between;
	flex: 1;

	.ui-funnel-section__rectangle__rectangle {
		height: 36px;
		background-color: @grey-lt;
	}
}

.ui-funnel-report__close-container--reassigned {
	display: flex;
	justify-content: space-between;
	flex: 1;

	.ui-funnel-section__rectangle__rectangle {
		height: 36px;
		background-color: @report-orange;
	}
}

.ui-funnel-report__close__text-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	width: 130px;
}

.ui-funnel-report__close__count-name-container {
	width: 60px;
}

.ui-funnel-report__empty-container {
	display: flex;
	padding-top: 32px;
	justify-content: center;
	align-items: center;
}

.ui-funnel-report__total-regressed-container {
	position: absolute;
	bottom: 0;
	left: ~'calc((100% - 450px) * 0.75 - 40px)';
	display: flex;
}

.ui-funnel-report__total-regressed__triangle {
	float: left;
	height: 0;
	width: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 20px solid @off-white-md;
	margin-right: 4px;
}

.ui-funnel-report__help-btn-container {
	float: right;
	margin-top: 2px;
}

// Funnel tooltip styles.
@funnel-tooltip-duration: 250ms;
@funnel-tooltip-delay: 1s;

.ui-funnel-section-tooltip-rectangle:hover,
.ui-funnel-section-tooltip-status-bar:hover,
.ui-funnel-section-tooltip-meta:hover {
	position: relative;

	&:after {
		animation: fadeIn @funnel-tooltip-duration @customEasing @funnel-tooltip-delay forwards;
		opacity: 0;

		content: attr(data-title);
		display: block;
		position: absolute;
		top: 0;
		left: 100%;
		z-index: 11;
		width: 200px;
		padding: 10px;
		background-color: rgba(0, 0, 0, 0.8);
		box-sizing: border-box;
		color: @off-white;
		font-size: @small;
		font-weight: normal;
	}

	&:before {
		animation: fadeIn @funnel-tooltip-duration @customEasing @funnel-tooltip-delay forwards;
		opacity: 0;

		content: '';
		position: absolute;
		top: 8px;
		left: ~'calc(100% - 14px)';
		z-index: 11;
		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent;
		border-right-color: rgba(0, 0, 0, 0.8);
		border-width: 7px;
	}
}

.ui-funnel-section-tooltip-meta:hover {
	position: absolute;

	&:after {
		top: -4px;
		left: ~'calc(100% + 8px)';
	}

	&:before {
		top: 4px;
		left: ~'calc(100% - 6px)';
	}
}

.ui-funnel-section-tooltip-status-bar:hover {
	&:after {
		left: auto;
		right: ~'calc(100% + 5px)';
	}

	&:before {
		left: auto;
		right: ~'calc(100% - 9px)';
		transform-origin: center center;
		transform: rotate(180deg);
	}
}

// Hack for reports overhaul
// Feature flagging these styles and overriding existing
body#report-2017 {
	.ui-funnel-report {
		background-color: transparent;
		.ui-funnel-report__filters-container {
			margin-bottom: 0 !important; // Override #report-header-options styles
		}
		.ui-funnel-report__filter-button {
			background-color: @off-white-dk;
		}
	}
	.ui-funnel-report__subheader {
		border-bottom: none;
		padding: 16px 0;
	}
	.ui-funnel-report__funnel {
		background-color: white;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 3px;
		margin-bottom: 16px;
		.ui-funnel-report__funnel-section {
			border-top: 1px solid @off-white-md;
			padding: 16px;
			&:first-child {
				border-top: none;
			}
			&:last-child {
				border-radius: 0 0 3px 3px;
			}
			.ui-funnel-section__transition.ui-funnel-section__transition--added {
				.ui-funnel-arrow-divider__inner-arrow-container {
					top: -71px;
				}
				.ui-funnel-section__arrow {
					border-top: none !important;
				}
			}
			.ui-funnel-section__main {
				border-right: 1px solid @off-white-md;
			}
		}
	}
	.ui-funnel-section__main__total-closed-container {
		width: 100px;
		.ui-funnel-report__total-closed {
			.ui-funnel-report__total-closed__label-container {
				.ui-funnel-report__total-closed__label {
					font-size: @small;
				}
			}
		}
	}
	.ui-funnel-section__average-time {
		font-size: @small;
		color: @grey;
	}
	.ui-funnel-report__close__container {
		width: 260px;
	}
}
