@import '../ui/shared/popover/_popover-nub.less';
@peep-header-height: 200px;

.peep-container .peep-header {
	position: relative;
	left: -16px;
	top: 0;
	height: @peep-header-height;
	background-size: 980px @peep-header-height;

	#header-map-zoom-1 {
		opacity: 1;
	}
	&:hover {
		// show the medium-zoom map + fade out weather on header hover
		#header-map-zoom-2 {
			opacity: 1;
		}
	}
	&.state-superzoom {
		// triggered via JS on .map-zoom-target
		#header-map-zoom-3 {
			opacity: 1;
		}
	}

	// this is an invisible box in the middle of the map, which serves as a hover
	// and click target, for zooming and jumping to google maps
	.map-zoom-target {
		z-index: 2;
		position: absolute;
		left: 50%; // fuzzy to get it squared around the map marker
		top: 30px;
		width: 120px;
		height: 70px;
	}

	.map-container {
		position: absolute;
		height: @peep-header-height;
		width: 100%;
		#util > .transition(opacity, 0.3s);
		opacity: 0;

		.shadow-inset {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
		}
		.marker-icon {
			width: 40px;
			height: 55px;
			background-size: 100%;
			background-repeat: no-repeat;
			position: absolute;
			top: 40px;
			left: calc(~'50% - 20px');
			z-index: 1;
			&.type-contacts {
				background-image: url('/include/images/map/pin-blue.png');
			}
			&.type-accounts {
				background-image: url('/include/images/map/pin-purple.png');
			}
		}
	}

	// .entity-accounts is hoisted to .peep-container, and we only set a BG on header-map-zoom-1
	.entity-accounts& #header-map-zoom-1 {
		background-image: url('/include/images/peep/empty-map-account_2x.png');
		background-position: center;
		background-size: cover;
	}
	.entity-contacts& #header-map-zoom-1 {
		background-image: url('/include/images/peep/empty-map-contact_2x.png');
		background-position: center;
		background-size: cover;
	}

	.map-image {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	.weather {
		position: absolute;
		&.state-hasweather {
			opacity: 1;
		}
		opacity: 0;
		#util > .transition(opacity, 0.3s);
		top: 20px;
		left: 20px;
		width: 272x;
		height: 58px;
		#util > .border-box;
		padding: 12px 8px 12px 62px;
		color: white;
		background-color: rgba(0, 0, 0, 0.68);
		.icon {
			.weather-sprite();
			width: 40px;
			height: 40px;
			position: absolute;
			top: 8px;
			left: 15px;
		}

		&.state-weather-cloudy .icon {
			background-position: 0px 0px;
		}
		&.state-weather-cloudy-day .icon {
			background-position: -40px 0px;
		}
		&.state-weather-cloudy-night .icon {
			background-position: -80px 0px;
		}
		&.state-weather-wind .icon {
			background-position: -120px 0px;
		}
		&.state-weather-rain .icon {
			background-position: -160px 0px;
		}
		&.state-weather-sleet .icon {
			background-position: -200px 0px;
		}

		&.state-weather-snow .icon {
			background-position: 0px -40px;
		}
		&.state-weather-fog .icon {
			background-position: -40px -40px;
		}
		&.state-weather-storm .icon {
			background-position: -80px -40px;
		}
		&.state-weather-clear-day .icon {
			background-position: -120px -40px;
		}
		&.state-weather-clear-night .icon {
			background-position: -160px -40px;
		}

		.temperature {
			font-size: @big;
			font-weight: 500;
			display: block;
		}
		.time {
			font-size: @normal;
			margin-right: 5px;
		}
		.location {
			overflow: hidden; // if the location name is veyr long, this will prevent the content from overflowing into the map
			margin-top: 1px;
		}
	}

	.twitter {
		position: absolute;
		left: 0;
		bottom: -1px;
		right: @peep-right-sidebar-width;
		font-size: @small;
		font-weight: 500;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
		color: white;
		background-color: rgba(0, 0, 0, 0.58);
		#util > .border-box();
		padding: 3px 0 2px 126px;
		opacity: 0;
		#util > .transition(opacity, 0.3s);
		&.state-hastwitter {
			opacity: 1;
		}
		.label {
			cursor: pointer;
			font-size: @tiny;
			padding-right: 14px;
		}
		.handle {
			font-size: @tiny;
			font-weight: normal;
			text-transform: none;
		}
		a:hover {
			text-decoration: underline;
		}
	}

	.top-right-info {
		#util > .border-box;
		width: @peep-right-sidebar-width;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background-color: white;
	}
}
