.quote-link-button {
    min-width: 135px;
}

.quote-link-button:hover {
    cursor: pointer;
}

.quote-link-button-icon {
    position: absolute;
    left: 10px;
    top: 5px;
}

.quote-link-button-text {
    padding-left: 20px;
}

.quote-link-info-popover {
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 250px;
}

.quote-link-info-popover-close-button {
    margin-right: -4px;
}
