.jumping-off-card {
	display: flex;
	position: relative;
	flex: 1;
	height: 170px;
	border: 2px solid @off-white-dk;
	border-radius: 3px;
	margin: 8px;
	background-color: white;
	cursor: pointer;
	user-select: none;
	box-sizing: border-box;
	justify-content: center;

	.card-icon-container {
		width: 150px;
		flex-shrink: 0;
		border-right: 2px solid @off-white-dk;
		box-sizing: border-box;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 118px;
		}
	}

	h3 {
		display: inline-block;
		font-size: 19px;
		color: @blue;
		line-height: 23px;
	}

	.card-info-container {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		padding: 16px;

		h3 {
			margin-bottom: 8px;
		}

		p {
			margin: 0;
			color: @grey;
			font-size: 15px;
			line-height: 21px;
		}
	}

	&:hover:not(.type-unclickable) {
		border-color: @grey-lt;
		h3 {
			color: @blue-dk;
		}
	}

	&.type-icon-card {
		min-width: 300px;
		max-width: 300px;

		.icon-image-container {
			position: relative;
			width: 150px;
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				filter: grayscale(60%);
			}
		}
	}

	&.type-unclickable {
		cursor: default;

		h3 {
			color: @grey;
		}
		
		a {
			color: @blue;
			&:hover {
				text-decoration: underline;
				color: @blue-dk;
			}
		}
	}
}
