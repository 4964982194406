.container {
    height: 392.5px;
}

.empty-state-container {
    margin-top: -20px;
}

.grid-container {
    height: 100%;
}

.grid-container > * {
    height: 100%;
    grid-auto-rows: auto;
}
