.data-notice-popover-container {
    position: relative;
    z-index: 10;
    top: 12px;
    right: -16px;
}

.notice-content strong {
    color: var(--color-text-accounts);
}
