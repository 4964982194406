.empty-state {
    position: relative;
    height: 100%;
}

.empty-state-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    box-shadow: 0 0 3px var(--grey-lt);
    padding: 0 48px;
}

.no-padding {
    padding: 0;
}
