.button {
    position: absolute;
    z-index: 11;
    height: 50px;
    width: 35px;
    background-color: var(--off-white-dk);
    padding: 0;
    opacity: 0.4;
    font-size: 15px;
    transition: all 400ms var(--custom-easing);
}

.button-left {
    composes: button;
    border-radius: 0 35px 35px 0;
}

.button-right {
    composes: button;
    border-radius: 35px 0 0 35px;
}

.button-left:hover,
.button-right:hover {
    background-color: var(--blue);
    color: white;
    opacity: 0.7;
}
