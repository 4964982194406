.thumbnail-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid var(--off-white-dk);
}

.content-container {
    position: relative;
    text-align: left;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title-secondary-info-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.title {
    font-size: var(--font-normal);
    color: var(--grey-dk);
    line-height: 21px;
    padding-right: 16px;
}

.secondary-info {
    font-size: var(--font-small);
    color: var(--grey);
}

.tertiary-info {
    font-size: var(--font-small);
    color: var(--grey-lt);
}

.action-menu {
    position: absolute;
    top: 5px;
    right: 0;
}
