.button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--grey);
}

.button:hover {
    color: var(--grey-dk);
}

.logo {
    width: 24px;
    max-height: 24px;
    display: flex;
    justify-content: center;
}

.logo > svg {
    width: 24px;
    max-height: 24px;
}
