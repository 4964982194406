.container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    /* This height fits everything perfectly and prevents jumping */
    min-height: 439px;
}

.to-addresses-container {
    display: flex;
    flex-direction: column;
    min-height: 97px;
}

.to-addresses-container > *:not(:last-child) {
    margin-bottom: 8px;
}

.email-select-container {
    border: 1px var(--off-white-dk) solid;
    width: 100%;
}

.email-select-container > * {
    width: 100%;
}

.checkbox-button-containers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px 8px;
}

.checkbox-container {
    margin-top: 8px;
    /* This container isn't quite centered with the select dropdown */
    margin-left: 2px;
}

.checkbox-container > :last-child {
    margin-bottom: 0;
}

.flash-banner-cta-container {
    /* This makes sure out link won't wrap to another line */
    width: 126px;
    display: flex;
    align-items: center;
}
