.subtitle {
    line-height: 19px;
    margin-bottom: 8px;
}

.donut-wrapper {
    display: flex;
    gap: 48px;
    padding: 32px;
    min-height: 300px;
}

.donut-chart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
}

.table-container {
    border: 1px solid var(--off-white-dk);
    width: 100%;
    position: relative;
}

/* This is the header for the chart download, hidden in the UI but visible when downloaded */
.download-chart-header {
    display: none;
    text-align: center;
}

.logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 400px;
    height: 0;
    margin: 32px auto;
}

.logo-container :first-child {
    margin-right: 32px;
}

@media screen and (max-width: 1200px) {
    .insights {
        flex-direction: column;
    }
}

/* When printing, show the headers, not the UI titles */
@media print {
    .download-chart-header {
        display: block;
    }
    .report-chart-ui-header {
        display: none;
    }
}
