.page-container-fixed-sidebar {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
    display: flex;
    overflow: hidden;
}

.page-container-scrolling-sidebar {
    width: 100%;
    min-height: 100%;
    position: absolute;
    margin: 0;
    display: flex;
}

.page-container-no-sidebar {
    height: 100%;
    width: 100%;
}

.page-container {
    width: 100%;
    min-height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: 8px 0 0 0;
    background: white;
}

:global(body.fixed-width) .page-container {
    min-width: 1000px;
}

:global(body.settings) .page-container {
    background-color: #fff;
}

:global(body[data-is-trial]) .page-container {
    min-height: calc(100vh - 45px);
}

.page-container-fixed-sidebar .page-container {
    width: calc(100% - 169px);
    overflow-x: auto;
}

.main {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
}

.page-container-with-navbar {
    background: var(--sidebar-secondary);
    height: 100%;
}

.page-container-border-top-left-primary {
    background-color: var(--sidebar-primary);
}

.page-container-border-top-left-secondary {
    background-color: var(--sidebar-secondary);
}

:global([data-is-ie11]) .main {
    flex-basis: 100vh;
}

.page-container-embedded .main {
    margin-top: 0;
}
