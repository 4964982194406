.coupon-field {
    width: 300px;
}

.coupon-applied {
    background-color: var(--success-bg);
    padding: 8px 4px 8px 16px;
}

.coupon-applied button {
    transform: translateY(-2px);
}
