.delay-bar {
    display: flex;
    padding: 12px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: var(--off-white);
    height: 28px;
}

.delay-bar--first-delay {
    composes: delay-bar;
    border-radius: 3px;
    margin-bottom: 16px;
}

.flex-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delay-items {
    display: flex;
    align-items: center;
}

.clock-icon {
    color: var(--grey-lt);
    margin-right: 12px;
}

/* STYLES FOR SMALL BAR BELOW: */

.delay-button {
    padding: 8px;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 3px;
    background-color: var(--off-white);
    color: var(--grey-lt);
}

.delay-button:not([disabled]):hover {
    color: var(--grey);
}

.delay-container {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: var(--font-small);
}

/* The clock icon */
.delay-container > span {
    margin-right: 8px;
}

.explanatory-copy {
    width: 100%;
    margin-left: 8px;
}
