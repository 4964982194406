.email-delay {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    color: var(--grey-lt);
    font-size: 13px;
    padding-right: 16px;
    padding-left: 8px;
    padding-bottom: 6px;
    font-style: italic;
}

.configurable-delay {
    display: flex;
    align-items: baseline;
}

.unit {
    margin-left: 6px;
}
