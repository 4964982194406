.container {
    text-align: left;
}

.container > * {
    padding: 16px 8px 16px 16px;
}

.status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status {
    margin-left: 12px;
}

.title {
    font-size: var(--font-normal);
    color: var(--grey-dk);
}

.subtitle {
    margin-top: 2px;
    font-size: var(--font-small);
}

.submissions {
    padding: 16px;
    border-top: 1px solid var(--button-secondary);
}

.text-primary {
    composes: text-primary from '../button/button.css';
    padding-right: 8px;
}

.text-secondary {
    composes: text-secondary from '../button/button.css';
    padding-right: 8px;
}
