.cta-banner-with-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    text-align: left;
}

.cta-banner-with-buttons--centered {
    composes: cta-banner-with-buttons;
    /* Centers CTA content within card */
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* Style needed to control MessageContainer */
.cta-banner-with-buttons > :first-child {
    flex: 1;
}

.buttons-container {
    display: flex;
    align-items: center;
}

.buttons-container > * {
    margin-left: 16px;
    flex-shrink: 0;
}

.cta-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Style for secondary link under button */
.cta-buttons > *:last-child:not(:only-child) {
    padding: 4px 0 0 0;
}
