.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 32px;
    box-sizing: border-box;
    overflow-y: auto;
}

.user-section {
    display: flex;
    flex-direction: column;
}

.divider {
    height: 1px;
    margin: 16px;
    width: calc(100% - 32px);
    background-color: var(--off-white-dk);
}

.top-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
}

.profile-info {
    display: flex;
    height: 54px;
    padding: 0 16px;
}

.avatar {
    margin-right: 16px;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 64px);
}

.name {
    padding-bottom: 4px;
    font-weight: 500;
    font-size: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--grey-dk);
}

.email {
    font-size: 13px;
    color: var(--grey);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.slogan-link {
    composes: email;
    font-style: italic;
    cursor: pointer;
}

.slogan-link:hover {
    color: var(--grey);
    text-decoration: underline;
}

.menu {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.link-container {
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    fill: var(--grey);
    color: var(--grey);
}

.link-icon-settings {
    height: 32px;
    width: 48px;
    text-align: center;
    font-size: 32px;
    margin-right: 16px;
}

/* This icon is jank AF, need to position it differently */
.link-icon-logout {
    composes: link-icon-settings;
    height: 27px;
    margin-left: 7px;
    width: 41px;
}

.link-text {
    font-size: var(--font-normal);
}

.link-container:hover {
    background-color: var(--color-list-hovered);
    fill: var(--grey-dk);
    color: var(--grey-dk);
}

.trial-days {
    color: var(--green);
    font-size: 13px;
}

.trial-days-pro {
    composes: trial-days;
    color: var(--nutshell-pro);
}

.trial-days-warning {
    composes: trial-days;
    color: var(--rose);
}
