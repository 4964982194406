.popover-nub(@size, @border-color, @fill-color) {
	&:before,
	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent;
	}

	&:before { border-width: @size; }
	&:after { border-width: ~"calc(@{size} - 1px)"; }

	&.location-left {
		&:before, &:after {
			border-right: 0;
			top: 50%;
		}
		&:before {
			right: ~"calc(-@{size})";
			margin-top: ~"calc(-@{size})";
			border-left-color: @border-color;
		}
		&:after {
			right: ~"calc(-@{size} + 1px)";
			margin-top: ~"calc(-@{size} + 1px)";
			border-left-color: @fill-color;
		}
	}

	&.location-right {
		&:before, &:after {
			border-left: 0;
			top: 50%;
		}
		&:before {
			left: ~"calc(-@{size})";
			margin-top: ~"calc(-@{size})";
			border-right-color: @border-color;
		}
		&:after {
			left: ~"calc(-@{size} + 1px)";
			margin-top: ~"calc(-@{size} + 1px)";
			border-right-color: @fill-color;
		}
	}

	&.location-top {
		&:before, &:after {
			border-bottom: 0;
			left: 50%;
		}
		&:before {
			border-top-color: @border-color;
			bottom: ~"calc(-@{size})";
			margin-left: ~"calc(-@{size})";
		}
		&:after {
			border-top-color: @fill-color;
			bottom: ~"calc(-@{size} + 1px)";
			margin-left: ~"calc(-@{size} + 1px)";
		}
	}

	&.location-bottom {
		&:before, &:after {
			border-top: 0;
			left: 50%;
		}
		&:before {
			border-bottom-color: @border-color;
			top: ~"calc(-@{size})";
			margin-left: ~"calc(-@{size})";
		}
		&:after {
			border-bottom-color: @fill-color;
			top: ~"calc(-@{size} + 1px)";
			margin-left: ~"calc(-@{size} + 1px)";
		}
	}

	&.location-top-left {
		&:before, &:after {
			border-bottom: 0;
			left: auto;
			right: ~"calc(@{size} * 2)";
		}
		&:before {
			border-top-color: @border-color;
			bottom: ~"calc(-@{size})";
		}
		&:after {
			border-top-color: @fill-color;
			bottom: ~"calc(-@{size} + 1px)";
		}
	}

	&.location-top-right {
		&:before, &:after {
			border-bottom: 0;
			left: ~"calc(@{size} * 2)";
		}
		&:before {
			border-top-color: @border-color;
			bottom: ~"calc(-@{size})";
		}
		&:after {
			border-top-color: @fill-color;
			bottom: ~"calc(-@{size} + 1px)";
		}
	}

	&.location-bottom-left {
		&:before, &:after {
			border-top: 0;
			left: auto;
			right: ~"calc(@{size} * 2)";
		}
		&:before {
			border-bottom-color: @border-color;
			top: ~"calc(-@{size})";
		}
		&:after {
			border-bottom-color: @fill-color;
			top: ~"calc(-@{size} + 1px)";
			border-width: ~"calc(@{size})";
		}
	}

	&.location-bottom-right {
		&:before, &:after {
			border-top: 0;
			left: ~"calc(@{size} * 2)";
		}
		&:before {
			border-bottom-color: @border-color;
			top: ~"calc(-@{size})";
		}
		&:after {
			border-bottom-color: @fill-color;
			top: ~"calc(-@{size} + 1px)";
			border-width: ~"calc(@{size})";
		}
	}
}
