.row {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
}

/* No two column on smaller screens */
@media (max-width: 1334px) {
    .row {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
