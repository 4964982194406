.section-label {
    font-weight: 500;
    padding-bottom: 6px;
    margin-bottom: 8px;
    color: var(--grey);
    text-transform: uppercase;
    border-bottom: 1px solid var(--off-white-dk);
}

.section-label.no-bottom-border {
    border-bottom: none;
    padding-bottom: 0;
}

.section-label.no-bottom-margin {
    margin-bottom: 0;
}

.mini {
    font-size: 11px;
    line-height: 14px;
}

.small {
    font-size: var(--font-small);
    line-height: 17px;
}

.normal {
    font-size: 12px;
    line-height: 21px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Font colors and weights
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.light {
    color: var(--grey-lt);
}

.medium {
    color: var(--grey);
}

.dark {
    color: var(--grey-dk);
}

.person {
    color: var(--person);
}

.error {
    color: var(--rose);
}

.blue {
    color: var(--blue);
}

.white {
    color: var(--white);
}

.green {
    color: var(--success);
}

.blue-dk {
    color: var(--blue-dk);
}

.navy-dk {
    color: var(--sidebar-primary);
}

.warn {
    color: var(--warning);
}
