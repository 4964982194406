.container {
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: white;
    background-color: var(--green);
    border-radius: 10px;
    vertical-align: top;
    display: inline-block;
    position: relative;
    text-align: center;
    cursor: pointer;
    font-size: var(--font-small);

    /* Override button styles */
    padding: 0 !important;
}

.container:not(:disabled):hover,
.container:not(:disabled):focus,
.container:not(:disabled):active {
    background-color: var(--green-dk);
    outline: 2px solid var(--focus-blue);
}

.container:disabled {
    background-color: var(--off-white-md);
}
