.table {
    background-color: #fff;
    width: fit-content;
    position: relative;
}

.full-width {
    width: 100%;
}

.special-state-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.table--striped {
    composes: table;
}

.table--striped tbody tr:nth-child(odd) {
    background-color: var(--off-white);
}

.special-row > *:first-child {
    display: block;
    padding: 0;
    background-color: var(
        --white
    ); /* Ensure special state at the top of the table has white background */
}

.special-row:hover {
    background-color: unset;
}

/* Fix table striping in FixedSizeList where even/odd changes depending on scroll position */
:global(.fixed-size-list) tr {
    background-color: inherit !important;
}
