.container {
    width: 316px;
    flex-shrink: 0;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    margin: 0 16px;
    background-color: var(--off-white);
    padding: 16px;
}

.from-scratch {
    cursor: pointer;
    padding: 8px;
    border-radius: 3px;
}

.from-scratch:hover {
    background-color: var(--off-white-dk);
}

.from-scratch--active {
    composes: from-scratch;
    background-color: var(--off-white-dk);
}
