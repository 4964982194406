.container {
    display: flex;
    height: 100%;
    line-height: 1.4;
}

.button {
    width: 100%;
    height: fit-content;
    padding: 8px;
}
