.utm-data dt,
.utm-data dd {
    all: unset;
}

.utm-data-container,
.page-data-container {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

.utm-data:not(:last-child),
.page-data:not(:last-child) {
    border-bottom: 1px solid var(--off-white-dk);
}

.utm-data,
.page-data {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-data-container a:hover {
    background-color: var(--off-white);
}

.page-data:first-child {
    background-color: var(--off-white);
}

/* Wraps url or page title without squishing duration timestamp */
.page-data :first-child {
    flex: 0 0 90%;
}

.page-title {
    color: var(--grey-lt);
    margin-top: 4px;
}
