.container {
    display: flex;
    flex-direction: column;
}

.container > *:not(:last-child) {
    margin-bottom: 12px;
}

.card-body {
    padding: 16px;
    text-align: left;
}

.card-body > :first-child:not(:only-child) {
    margin-bottom: 2px;
}

.card-body--small {
    composes: card-body;
    padding: 16px;
}

.card-body--small > :first-child {
    font-size: var(--font-normal);
}

.card-body--small > :last-child:not(:only-child) {
    font-size: var(--font-small);
}
