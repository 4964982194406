.container {
    position: relative;
    margin: 0;
    overflow: hidden;
    flex-shrink: 0;
}

.container--absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
}
