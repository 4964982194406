.container {
    display: flex;
    flex-direction: column;
    height: 500px;
    align-items: center;
    justify-content: center;
}

.bars {
    margin-left: -12px;
    margin-bottom: 12px;
}

.text {
    font-size: 16px;
}
