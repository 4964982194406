.empty-state {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.info-card {
    padding: 36px;
    position: absolute;
    width: 580px;
    top: 15%;
    left: calc(50% - 326px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    text-align: center;
}

.description {
    width: 75%;
}
