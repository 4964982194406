.price,
.sticker-price {
    font-size: 27px;
    font-weight: 500;
    line-height: 35px;
    font-family: doyle, 'IBM Plex Sans';
    color: var(--grey-dk);
}

.sticker-price {
    font-weight: 400;
    text-decoration: line-through;
}

.discount .price {
    color: var(--green);
}
