.content {
    box-sizing: border-box;
    padding: 16px;
}

.message {
    height: fit-content;
    max-height: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 17px;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: left;
    align-items: flex-start;
}
