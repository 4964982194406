@import "include";

.page.unbounce-pages {
	.builder {
		.list-standard-header {
			.connect {
				width: 25%;
			}
		}

		.list-row {
			.configure-fields {
				display: none;
			}

			&.connected {
				.page-disconnect {
					display: inline-block !important;
				}
				.page-connect {
					display: none;
				}
				.configure-fields {
					display: inline;
				}
			}

			.column-connect {
				.loaded,
				.failed {
					display: none;
				}

				.button {
					margin-right: 10px;
				}

				&.loaded {
					.loading {
						display: none;
					}

					.loaded {
						display: block;
					}
				}

				&.failed {
					.loading {
						display: none;
					}

					.failed {
						display: block;
					}
				}

				.page-disconnect {
					display: none;
				}
			}
		}
	}

	.unbounce-auth-container {
		margin: 16px;
		display: flex;
		flex-direction: column;

		.unbounce-auth-container-columns {
			display: flex;
			margin-top: 32px;
		}

		.unbounce-auth-container-left-column {
			width: 40%;
			margin-right: 48px;

			li {
				color: @grey;
				font-size: @big;
				line-height: 25px;
				margin-bottom: 16px;

				strong {
					// Space after the bullet
					padding-left: 4px;
					color: @grey-dk;
				}

				// Bullets
				&:before {
					content: "• ";
					color: @sidefire;
				}
			}
		}

		.unbounce-auth-container-right-column {
			display: flex;
			flex-direction: column;
			width: ~"calc(60% - 48px)";

			img.unbounce-logo {
				margin: 0 auto;
				width: 200px;

				// Just looks centered this way. Technically 100px is centered
				margin-left: 90px;
			}

			img.unbounce-graphic {
				max-width: 400px;
			}
		}

		.unbounce-connect-container {
			margin: 0 auto;

			.unbounce-connect-btn {
				margin: 64px 0 16px 0;
				width: 300px;
				text-align: center;
			}
		}

		p {
			text-align: center;
			font-weight: normal;
			color: @grey;
		}
	}
}
