.timeline-footer-bar {
    padding: 0 24px 16px;
    flex-shrink: 0;
}

/* Aligns left content end with reaction bar end */
.left-container {
    position: relative;
    left: -16px;
}

.left-container > div > button,
.left-container > div > a {
    padding: 5px 12px; /* This makes the button the same height as the reaction button in the timeline entry */
}
