.container {
    width: 100%;
    min-height: 134px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow-x: auto;
    box-sizing: border-box;
    border: 1px solid var(--off-white-dk);
    border-radius: 4px;
}

.container-archived {
    background-color: var(--off-white);
    margin-bottom: 16px;
}

.container-draggable {
    composes: container;
    border: none;
    border-radius: 0;
}

.stages-container {
    display: flex;
    user-select: none;
    height: 64px;
    overflow: hidden;
    margin: 8px 0 8px 16px;
}

.stages-container::after {
    content: '';
    position: absolute;
    right: 0;
    width: 100px;
    height: 64px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.001), #fff);
    pointer-events: none;
}

.stages-container-archived::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.001), var(--off-white));
}
