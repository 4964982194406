.publish-changes-modal {
    height: 100%;
    position: relative;
}

.action-box-container {
    margin: 16px 0;
}

.action-box-container > * {
    margin-bottom: 16px;
}

/* Message preview box */
.message-preview-container {
    border: 1px var(--off-white-dk) solid;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    padding: 32px;
    margin: 16px 0;
}

.message-preview-container > :first-child {
    margin-right: 64px;
}

.preview-item {
    margin-bottom: 16px;
}

.preview-item > :first-child {
    margin-bottom: 0;
}

.thumbnail-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbnail-container {
    width: 250px;
    height: 225px;
    margin: 8px 0;
    border: 1px var(--off-white-dk) solid;
}

/* Modal footer */
.publish-changes-modal > :last-child {
    width: 100%;
    position: absolute;
    bottom: 0;
}
