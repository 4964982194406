.section-container {
    background-color: var(--off-white);
    margin-bottom: 16px;
    padding: 16px;
    box-sizing: border-box;
}

.section-container--no-margin {
    margin: 0;
}

.section-container--full-height {
    height: 100%;
}

.section-container--center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
