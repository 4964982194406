/* * * * * * * * * * * * * * * * * * * * *
 * TimelineEntry.js styles
 * * * * * * * * * * * * * * * * * * * * */
.timeline-entry {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.entry-header-container {
    display: flex;
    position: relative;
    margin-bottom: 4px;
}

.entry-header-container > :first-child {
    margin-right: 12px;
}

.entry-header-container--small {
    composes: entry-header-container;
    margin-bottom: 6px;
}

.entry-header-container--small > :first-child {
    margin-right: 12px;
}

.timeline-entry-container {
    /* Aligns entry card with icon */
    margin-left: 56px;
    position: relative;
}

.timeline-entry-container--small {
    composes: timeline-entry-container;
    margin-left: 0;
}

.timeline-entry-content {
    position: relative;
}

.timeline-entry-content--expandable {
    composes: timeline-entry-content;
    cursor: pointer;
}

/* * * * * * * * * * * * * * * * * * * * *
 * TimelineEntryCard.js styles
 * * * * * * * * * * * * * * * * * * * * */

/* Allows the @mention dropdown for comments to overflow the card */
.timeline-entry-content > *:first-child {
    overflow: visible;
}

.toolbar-container {
    position: absolute;
    top: -24px;
    right: 16px;
}
