.content-container {
    display: flex;
    padding: 16px;
}

.content-container--full-height {
    composes: content-container;
    height: 100%;
}

.content-container--full-height .text-container {
    justify-content: space-between;
}

.content-container > :first-child:not(:last-child) {
    margin-right: 16px;
    color: var(--blue);
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.text-container > *:first-child:not(:last-child) {
    margin-top: 2px;
    margin-bottom: 8px;
}

.text-container > *:nth-child(2) {
    flex-grow: 1;
    text-align: left;
    margin-bottom: 2px;
}

.cta {
    font-size: var(--font-normal);
    margin-top: 32px;
    color: var(--blue);
}

.content-container:hover .cta {
    color: var(--blue-dk);
    text-decoration: underline;
}
