.option {
    display: flex;
}

.favorite-icon {
    color: var(--yellow);
    margin-right: 8px;
}

.fx-icon {
    margin-right: 8px;
}

.label {
    flex-grow: 1;
    margin-right: 16px;
}

.loading-icon,
.count {
    fill: var(--grey-lt);
    color: var(--grey-lt);
    /* this avoids being hidden by scroll bar */
    padding-right: 8px;
}

.loading-icon {
    transform: translateX(4px);
}
