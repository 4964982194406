@import 'include.less';
@import 'my-account/_account-settings.less';
@import 'my-account/_email-settings.less';
@import 'my-account/_notifications.less';
@import 'my-account/_email.less';
@import 'my-account/_calendar.less';
@import 'my-account/_phone.less';
@import 'my-account/_sidebar.less';
@import 'my-account/_subheader.less';

#account-util() {
	.active-tab-styles {
		background-color: rgba(255, 255, 255, 0.7);
		a {
			color: @grey-dk;
			border-left: 5px solid @blue-bg;
		}
	}
}

.account-settings {
	.page-sidebar {
		float: left;
	}

	&.account-settings .myaccount-submenu li.tab-account-settings {
		#account-util > .active-tab-styles;
	}
	&.account-integrations .myaccount-submenu li.tab-integrations {
		#account-util > .active-tab-styles;
	}
	&.account-email .myaccount-submenu li.tab-account-email {
		#account-util > .active-tab-styles;
	}
	&.account-notifications .myaccount-submenu li.tab-notifications {
		#account-util > .active-tab-styles;
	}
	&.account-calendar .myaccount-submenu li.tab-calendar {
		#account-util > .active-tab-styles;
	}
	&.account-phone .myaccount-submenu li.tab-phone {
		#account-util > .active-tab-styles;
	}

	//disabled user styles for entire form, and integration container (integration page
	//doesn't have a form to submit, so it needs its own container to disable)
	&.user-disabled .account-settings-table tr:not(.emails),
	&.user-disabled .integration-header-container,
	&.user-disabled .notification-header-container {
		opacity: 0.3;
		position: relative;
		z-index: -100;
	}

	//This is used for the subheader, so that is extends the entire width of the container
	.my-account-margin-offset-container {
		//needs more bottom padding to offset visual shadow distance from header nav
		padding: 10px 25px 13px 25px;
		background-color: white;
	}

	//disable user button, custom heights and floats
	.user-status-action {
		margin-right: 5px;
	}

	.flash-message .user-status-action {
		text-decoration: none;
		line-height: 17px;
		margin: -5px -5px 0 0;
		&:hover {
			text-shadow: none;
		}
	}

	.button-setting.size-medium {
		float: right;
	}

	span.popover-opener-template {
		text-decoration: underline;
		cursor: pointer;
	}
}

.page#myaccount,
.page.manage {
	.page-flow-area {
		.header-underlined {
			border-bottom: 1px solid @off-white-dk;
			padding: 8px 16px;
			h1 {
				font-size: 21px;
				font-weight: 700;
				line-height: 22px;
				letter-spacing: 0.5px;
				background: none;
				margin: 0;
				padding: 0;
				span {
					font-size: @small;
					line-height: 20px;
					font-weight: 400;
					letter-spacing: 0;
					color: @grey;
					display: block;
					margin-top: 4px;
					max-width: 900px;
				}
			}
			.button-setting {
				font-size: @small;
				float: right;
				padding: 6px 24px;
				width: 91px;
				margin-top: -5px;
			}
		}
	}
}

#myaccount {
	.my-account-header {
		background: @off-white;
		border-bottom: 1px solid @off-white-dk;
		padding: 8px 16px;
		.avatar-container.action-file-pick {
			.avatar,
			img {
				width: 48px;
				height: 48px;
			}
			.avatar {
				background-size: 42px;
				.overlay {
					.fa-cloud-upload {
						font-size: 30px;
						margin-top: -15px;
						margin-left: -15px;
					}
					.remove {
						font-size: 15px;
						line-height: 19px;
						width: 20px;
						height: 20px;
					}
					.message {
						font-size: 30px;
						line-height: 46px;
					}
				}
			}
		}
	}
	.account-settings-table {
		margin: 0;
		padding: 0;
		tr {
			display: block;
			padding: 8px 16px;
		}
		tr.section-border {
			background: @off-white-dk;
			height: 1px;
			margin: 8px 0;
			padding: 0;
		}
	}

	.max-users-notice {
		color: @rose;
		font-size: 14px;
		margin-bottom: 10px;
	}
}
