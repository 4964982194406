.ui-setup-info-header {
	font-size: @normal;
	line-height: 21px;
	color: @grey-dk;
	background-color: @plum-lt;
	background-size: 36px 36px;
	background-position: 0 0;
	background-repeat: repeat-y;
	border-top: 2px solid @info;
	border-bottom: 2px solid @info;
	padding: 16px 16px 16px 64px;
	margin: 16px 0;
	position: relative;

	background-image: url('/include/images/info-header-bg-stripes_2x.png');
}

.ui-info-header__icon-container {
	background-size: 42px 42px;
	background-position: 0 0;
	background-repeat: no-repeat;
	width: 42px;
	height: 42px;
	position: absolute;
	left: 16px;
	top: 50%;
	margin-top: -21px;
	fill: @info;
}

.ui-info-header__content {
	display: inline-block;
	/* Ensure line length doesn't get too long */
	width: 80%;
	max-width: 800px;
}

body.import .ui-setup-info-header {
	margin: 0;
}
