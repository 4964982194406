.see-all-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.see-all-button .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 65px;
    width: 65px;
    border-radius: 65px;
    background-color: var(--off-white);
    padding: 0;
    margin-bottom: 16px;
}

.see-all-button svg {
    color: var(--icon-secondary);
}

.see-all-button:hover svg,
.see-all-button:focus svg {
    color: var(--icon-primary);
}

.see-all-button:hover span,
.see-all-button:focus span {
    color: var(--grey-dk);
}

.see-all-button span {
    color: var(--grey);
    font-size: var(--font-small);
}
