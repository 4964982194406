.button {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0 !important;
    min-height: 32px;
    border-radius: 3px;
}

.button-comparing {
    composes: button;
    margin-top: -8px;
    margin-bottom: -8px;
}

.date-container {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-align: right;
}

/* Match styles of filter capsule */
.date-container--filter-capsule {
    composes: date-container;
    font-weight: normal;
}

.date-comparison {
    font-weight: normal;
    font-size: 12px;
}

.chevron-down-icon {
    font-size: 11px;
    margin-left: 8px;
    fill: var(--grey-lt);
}

/* Match styles of filter capsule */
.chevron-down-icon--filter-capsule {
    composes: chevron-down-icon;
    font-size: 11px !important;
}
