.control-container {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--off-white);
    border-radius: 3px;
    padding: 4px;
}

.button-content {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.button-content--small {
    composes: button-content;
    padding: 8px 16px;
}

.button-content--large {
    composes: button-content;
    text-align: left;
    padding: 16px;
}

.title {
    font-weight: normal;
    color: var(--grey-dk);
    font-size: var(--font-small);
    line-height: 21px;
}

.title--selected {
    composes: title;
    font-weight: 500;
}

.title--large {
    composes: title--selected;
    padding-bottom: 4px;
    font-size: var(--font-normal);
}

.inner-text {
    font-weight: normal;
    color: var(--grey-lt);
    font-size: var(--font-small);
    line-height: 19px;
}
