/* Shared stylesheet for all banner-style components in the board */

.banner-container {
    margin: 8px 0;
}

.left-text {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    flex-grow: 1;
}
