.container {
    margin: 8px 0 0 6px;
    padding: 8px;
    border: 1px solid var(--ai-blue);
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--white);
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
