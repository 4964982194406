.arrow {
    margin-top: 2px;
}

.arrow-unchanged {
    composes: arrow;
    background-color: var(--off-white-md);
}

.arrow-down {
    composes: arrow;
}

.arrow-up {
    composes: arrow;
    transform: scaleY(-1);
}

.arrow-large {
    composes: arrow;
    width: 38px;
    height: 70px;
}

.arrow-large.arrow-unchanged {
    height: 30px;
    width: 30px;
    margin: 21px 4px;
    border-radius: 15px;
}

.arrow-small {
    composes: arrow;
    width: 19px;
    height: 35px;
}

.arrow-small.arrow-unchanged {
    width: 15px;
    height: 15px;
    margin: 10px 2px;
    border-radius: 8px;
}
