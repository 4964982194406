.field-with-icon {
    position: relative;
}

.field-with-icon input {
    /* 12px left margin + 15px icon + 8px right margin */
    padding-left: 35px;
}

.icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}

/* Uniform icon appearance */
.icon span,
.icon svg {
    display: flex;
    width: 15px;
    justify-content: center;
}

.icon--with-offset {
    composes: icon;
    padding-bottom: 1px;
}
