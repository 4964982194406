.filter-bar-container {
    /* The filter bar UI height is 60px and the component has a scroll listener to calculate if the
     * filter bar is a the top so it can switch to a 'fixed' position and remain visible while scrolling
     * down the timeline. But when an element position is 'fixed' it is "removed" from its initial position
     * in the DOM and its height becomes 0px. At certain page heights with shorter timeline lengths, that
     * 60px being removed is enough to cause the scroll handler to rapidly fire and swap the position from
     * 'fixed' to 'initial' creating a "jumping" effect in the UI. Setting the height on the container prevents
     * the 60px hole from being created when the position is 'fixed' and solves this issue. It also makes
     * the behavior feel smoother when the positioning is swapped as the bar no longer covers the first entry.
     */
    min-height: 60px;
}

.filter-bar {
    box-sizing: border-box;
    padding: 10px 16px;
    border-bottom: 1px solid var(--off-white-md);
    background-color: var(--white);
}

.filter-bar--fixed {
    composes: filter-bar;
    position: fixed;
    top: 0;
    /* z-index 11 to clear @mention list index of 10 (which is set at that to fix usage in another place) */
    z-index: 11;
}

.filter-bar-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    gap: 12px;
}

.filters-container {
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.filters {
    display: flex;
    flex-wrap: wrap;
}

.filter-capsule,
.buttons-container button {
    height: 31px;
    margin: 4px 0;
}

.date-filter {
    composes: filter-capsule;
    align-items: center;
    background-color: var(--off-white-md);
    border-radius: 3px;
    padding: 0 8px;
    /* position the badge correctly */
    position: relative;
    display: inline-block;
    user-select: none;
    cursor: pointer;
}

.more-button {
    /* position the badge correctly */
    position: relative;
    display: inline-block;
    user-select: none;
    cursor: pointer;
    padding: 3px 12px 0 12px; /* dial in positioning of cog icon */
    margin: 4px 0;
}

.more-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 8px;
    padding: 16px 8px;
    box-sizing: border-box;
    background-color: var(--white);
    border-top: 1px solid var(--off-white-md);
}

.more-options .info {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: var(--font-small);
    color: var(--grey-dk);
}

.more-options .info span {
    color: var(--grey-lt);
    margin-left: 4px;
    font-weight: normal;
}

.checkbox-container {
    display: flex;
}

.checkbox-container > * {
    padding-right: 16px;
}
