.container {
    display: flex;
    align-items: center;
}

.avatar-container {
    display: flex;
    flex-shrink: 0;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
}

.info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
