.ui-entity-list__map-wrapper {
	background-color: #eeffff; //color of antarctica, for tall screens
	.ui-marker {
		.ui-marker-icon {
			width: 100%;
			height: 100%;
			background-size: 100%;
			background-repeat: no-repeat;
		}

		&.ui-marker--type-contacts {
			.ui-marker-icon {
				background-image: url('/include/images/map/pin-blue.png');
			}
		}
		&.ui-marker--type-accounts {
			.ui-marker-icon {
				background-image: url('/include/images/map/pin-purple.png');
			}
		}
		&.ui-marker--type-leads {
			.ui-marker-icon {
				background-image: url('/include/images/map/pin-green.png');
			}
		}
		&.ui-marker--type-leads.hot {
			.ui-marker-icon {
				background-image: url('/include/images/map/pin-flame.svg');
			}
		}
		&.ui-marker--type-user {
			.ui-marker-icon {
				background-image: url('/include/images/map/pin-orange.png');
			}
		}
	}

	.leaflet-popup {
		font-family: @body-font;
		.ui-avatar-container {
			float: left;
			margin-right: 10px;
			height: 40px;
			width: 40px;

			img {
				width: 100%;
			}
			.initials {
				height: 40px;
			}
		}

		&.type-contacts {
			.ui-avatar-container svg.icon-contacts {
				display: block;
			}
		}
		&.type-accounts {
			.ui-avatar-container svg.icon-accounts {
				display: block;
			}
		}
		&.type-mcfxCompanies {
			.ui-avatar-container svg.icon-accounts {
				display: block;
			}
		}
		&.type-leads {
			.ui-avatar-container svg.icon-leads {
				display: block;
			}
		}
		&.user-popup {
			h1 {
				margin: 0;
				color: @grey;
				font-family: @body-font;
			}
		}
		&.hover-popup,
		&.user-popup {
			transition: none;
			-webkit-transition: none;

			.leaflet-popup-content-wrapper {
				border: none;
				background: none;
				box-shadow: none;

				.leaflet-popup-content {
					opacity: 0.9;
					background-color: white;

					p {
						width: 100%;
						font-size: 13px;
						line-height: 15px;

						&.type-contacts {
							color: @navy-dk;
						}
						&.type-accounts {
							color: @plum-dk;
						}
						&.type-mcfxCompanies {
							color: @plum-dk;
						}
						&.type-leads {
							color: @green-dk;
						}
					}
				}
			}

			.leaflet-popup-tip-container {
				height: 10px;
				.leaflet-popup-tip {
					position: absolute;
					top: -1px;
					right: 100%;
					margin-right: -14px;
					display: block;
					width: 0px;
					height: 0px;
					border: 6px solid white;
					border-bottom-color: transparent;
					border-right-color: transparent;
					border-left-color: transparent;
				}
			}
		}
		.avatar-container {
			.avatar {
				background-color: @off-white;
				width: 60px;
				height: 60px;
				margin-right: 10px;
				img {
					width: 100%;
				}
			}
			svg {
				width: 60px;
				height: 60px;
				&.icon-contacts {
					fill: @navy-dk;
				}
				&.icon-accounts {
					fill: @plum-dk;
				}
				&.icon-leads {
					fill: @green-dk;
				}
			}
		}
		.avatar-container .icon {
			display: none;
		}
		.leaflet-popup-tip {
			border-top: 10px solid @off-white;
		}
	}

	//override the close button styles
	.leaflet-popup-close-button + .leaflet-popup-content-wrapper .leaflet-popup-content {
		padding-top: 5px;
	}

	.leaflet-popup-content-wrapper {
		font-family: @body-font;
		box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
		border: 1px solid white;
		.leaflet-popup-content {
			background-color: @off-white;
			padding: 5px;

			a,
			a:hover {
				outline: none;
			}
			a:hover {
				text-decoration: underline;
			}
			p {
				width: 230px;
				#util > .excerpt-crop > .ellipsis;
				margin-bottom: 0;
			}
			.name a {
				color: @grey;
				font-size: @big;
				font-weight: 500;
			}
			.related-entity {
				height: 20px;
				&.type-contacts a {
					color: @navy-dk;
				}
				&.type-accounts a {
					color: @plum-dk;
				}
				&.type-mcfxCompanies a {
					color: @plum-dk;
				}
				&.type-none a {
					display: none;
				}
				a {
					font-size: @big;
				}
			}
			.action-bar a {
				font-size: @normal;
				color: @blue;
				&:hover {
					color: @blue-dk;
				}
			}
		}

		.ui-avatar {
			position: relative;
			z-index: 10;
			&:before,
			.initials,
			img,
			svg {
				border-radius: 40px;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
			img {
				z-index: 10;
			}

			.initials {
				border-radius: 40px;
				height: 40px;
				line-height: 40px;
				font-size: 17px;
				font-weight: 500;
				color: white;
				cursor: default;
				font-family: @body-font;
				text-align: center;
				text-transform: uppercase;
			}

			&.type-users {
				.initials {
					background-color: @orange-dk;
				}
			}

			&.type-contacts {
				.initials {
					background-color: @navy-dk;
				}
			}

			&.type-accounts {
				.initials {
					background-color: @plum-dk;
				}
			}
			&.type-mcfxCompanies {
				.initials {
					background-color: @plum-dk;
				}
			}
			&.type-leads {
				img {
					border-radius: unset;
				}
			}
		}
	}

	.map-popover {
		top: -67px !important;
		left: 250px;
		width: 170px;
		border: none;

		.nub {
			left: 35px;
			top: 108px;
			#util > .transform-180();
			margin-left: -7px;
		}
	}
}
