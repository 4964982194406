@value columnWidth: 310px;
@value columnGutter: 6px;

.container {
    display: flex;
    flex-direction: column;
    width: columnWidth;
    margin-right: columnGutter;
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: var(--off-white);
}

.container:first-child {
    /* Additional width because it has no left-margin */
    width: calc(columnWidth + 4px);
}

.container:last-child {
    margin-right: 0;
}

.container--highlighted {
    composes: container;
    background-color: var(--off-white-dk);
}

.container-draggable {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
}

.column-container {
    height: 100%;
    padding: 0 8px;
}

.column-container--drag-over {
    composes: column-container;
    background-color: var(--off-white-dk);
}

.empty-section {
}

.empty-section--hidden {
    opacity: 0;
}

.loading-container {
    fill: var(--off-white-dk);
    padding: 8px;
    padding-top: 10px;
    text-align: center;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    color: var(--rose);
    padding: 16px;
    line-height: 17px;
    text-align: center;
}

.retry {
    margin-top: 8px;
    color: var(--blue);
}

.retry:hover {
    color: var(--blue-dk);
    text-decoration: underline;
}
