/* This is used solely at the top of old-school activity modals where there
is an attached call recording / media element. */

/* filter bar defaults. see below for substantial changes */
.filter-bar {
	background: @grey url('@{img-root}/filter-bar/standard/filter-bar-bg.png') left top repeat-x; /* TODO: standardize color */
	border-bottom: 1px solid @grey-dk;
	padding: 4px 10px;
	overflow: hidden;
	text-align: left;
	-webkit-font-smoothing: antialiased;

	li {
		#util > .inline-block;
		margin: 3px 8px 3px 0;
		padding-left: 6px; // offset with <a> to account for icons
		#util > .rounded-corners();
		cursor: pointer;

		a {
			float: left;
			padding: 0 8px 0 2px;
			font-size: 14px;
			line-height: 24px;
			color: @off-white-dk;
			#util > .text-shadow > .dark-bg-grey;

			sup {
				font-size: 10px;
				vertical-align: baseline;
				position: relative;
				top: -5px;
			}
		}

		.icon {
			position: relative;
			float: left;
			top: 4px;
		}

		&:hover {
			background: @grey-dk;
			background: rgba(0, 0, 0, 0.2);
		}
		&.state-selected {
			background: @grey-dk;
			background: rgba(0, 0, 0, 0.4);
			#util > .shadow > .box('inset 0 3px 5px rgba(0,0,0,0.6)');
			a {
				color: @blue;
				font-weight: 500;
				#util > .text-shadow > .shadowed;
			}
		}
	}

	// non-modal icons
	.filter-type-emails .icon {
		#icon > .filter-bar-nonmodal-emails;
	}
	.filter-type-activities .icon {
		#icon > .filter-bar-nonmodal-activities;
	}
	.filter-type-notes .icon {
		#icon > .filter-bar-nonmodal-notes;
	}
}

.filter-bar.filter-hide-options li {
	display: none;
}

.filter-bar.state-disabled li a {
	color: @grey !important;
}

// modal-specific overrides

#modal {
	.filter-bar {
		background: white;
		border: 1px solid @off-white-dk;
		border-top: none;
		text-align: center;

		li {
			margin: 2px 4px 2px 0;

			.icon {
				top: 2px;
			}

			a {
				color: black;
				font-size: 12px;
				line-height: 20px;
				#util > .text-shadow > .very-light-bg;
				sup {
					top: -4px;
				}
			}

			&.state-selected a {
				color: @blue;
				#util > .text-shadow > .shadowed;
			}
		}
	}

	// icons
	.filter-type-accounts .icon {
		#icon > .filter-bar-accounts;
	}
	.filter-type-accounts.state-selected .icon {
		#icon > .filter-bar-accounts-active;
	}
	.filter-type-contacts .icon {
		#icon > .filter-bar-contacts;
	}
	.filter-type-contacts.state-selected .icon {
		#icon > .filter-bar-contacts-active;
	}
	.filter-type-both .icon {
		display: none !important;
	}
	.filter-type-emails .icon {
		#icon > .filter-bar-emails;
	}
	.filter-type-emails.state-selected .icon {
		#icon > .filter-bar-emails-active;
	}
	.filter-type-activities .icon {
		#icon > .filter-bar-activities;
	}
	.filter-type-activities.state-selected .icon {
		#icon > .filter-bar-activities-active;
	}
	.filter-type-changes .icon {
		#icon > .filter-bar-changes;
	}
	.filter-type-changes.state-selected .icon {
		#icon > .filter-bar-changes-active;
	}
	.filter-type-steps .icon {
		#icon > .filter-bar-steps;
	}
	.filter-type-steps.state-selected .icon {
		#icon > .filter-bar-steps-active;
	}
	.filter-type-sent .icon {
		#icon > .filter-bar-sent;
	}
	.filter-type-sent.state-selected .icon {
		#icon > .filter-bar-sent-active;
	}
	.filter-type-received .icon {
		#icon > .filter-bar-received;
	}
	.filter-type-received.state-selected .icon {
		#icon > .filter-bar-received-active;
	}
	.filter-type-logged .icon {
		#icon > .filter-bar-logged;
	}
	.filter-type-logged.state-selected .icon {
		#icon > .filter-bar-logged-active;
	}
	.filter-type-scheduled .icon {
		#icon > .filter-bar-scheduled;
	}
	.filter-type-scheduled.state-selected .icon {
		#icon > .filter-bar-scheduled-active;
	}
	.filter-type-notes .icon {
		#icon > .filter-bar-notes;
	}
	.filter-type-notes.state-selected .icon {
		#icon > .filter-bar-notes-active;
	}
}
