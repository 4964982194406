.container {
    padding: 4px;
    display: flex;
}

/* Set padding for buttons that are not the action menu (that one is special) */
.container button:not(:last-child) {
    margin-right: 4px;
}

.container button {
    border-radius: 3px;
    padding: 4px;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    justify-content: center;
}

/* Ensure that the colors of icons are consistent */
.container button svg {
    color: var(--grey);
    height: 15px;
    width: 15px;
}

.container button:hover svg {
    color: var(--grey-dk);
}
