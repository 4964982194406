.scroll-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;

    /* Bump scrollbar to edge of modal */
    margin: 0 -32px;
    padding: 0 32px;
}

.form-and-preview-container {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
}

.name-container {
    width: 416px;
}

.form-container {
    width: 416px;
    margin-right: 20px;
}

.preview-container {
    position: relative;
}

.preview-container:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
}

.preview-label {
    font-size: var(--font-normal);
    margin-bottom: 10px;
    font-weight: normal;
    color: var(--grey-lt);
    user-select: none;
}

.error-container {
    position: relative;
    height: 50px;
    overflow: hidden;
}

.warning {
    position: absolute;
    text-align: center;
    width: 100%;
}

.warning-shown {
    composes: warning;
    top: 8px;
    opacity: 1;
    transition: all 500ms;
}

.warning-hidden {
    composes: warning;
    top: -40px;
    opacity: 0;
    transition: all 500ms;
}

.submit-button {
    width: 100%;
}

.submit-button > button {
    width: 100%;
    justify-content: center;
}
