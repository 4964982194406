.container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-height: 220px;
    min-width: 0;
    font-size: var(--font-small);
}

.container--right {
    composes: container;
    text-align: right;
    flex-shrink: 0;
}

.column-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
}

.column-container :global(.ui-entity-list-row) {
    color: var(--grey);
    padding-right: 16px;
}

.container--right .cell-container {
    align-items: center;
}

.cell-container {
    box-sizing: border-box;
    height: 44px;
    display: flex;
    flex-shrink: 0;
}

.cell-container:nth-child(2n) {
    background-color: var(--zebra-stripe);
}

.container :global(.ui-entity-info) {
    width: 230px;
    padding: 5px 15px;
}

.container :global(.ui-entity-list-row) {
    width: 100%;
    min-width: 65px;
}

.container :global(.ui-entity-info .ui-entity-info__lead-number) {
    left: 5px;
    bottom: 2px;
    width: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.lead-value {
    height: 17px; /* Same as lead description */
    padding-top: 5px;
    padding-right: 16px;
}

.overdue-duration {
    font-size: 11px;
    color: var(--rose-dk);
    padding-right: 16px;
}
