.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 16px;
    color: var(--blue);
    cursor: pointer;
    user-select: none;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid var(--off-white-dk);
    background-color: var(--yellow-lt);
    flex-shrink: 0;
}

.container:hover {
    color: var(--blue-dk);
}

.container-open {
    composes: container;
    border-bottom: none;
}

.left-section {
    font-size: var(--font-small);
    cursor: pointer;
}

.left-section .chevron-icon {
    font-size: 11px;
    color: var(--grey);
    width: 15px; /* So the text doesn't jump when changing from chevron icon right to chevron icon down */
    display: inline-block;
}

.container-open .left-section .chevron-icon {
    position: relative;
    top: -1px;
}

.count {
    color: var(--grey);
    margin-left: 5px;
}
