.source-tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--off-white-dk);
    border-radius: 3px;
    padding: 8px;
    max-height: 50px;
    box-sizing: border-box;
}

.source-tag--short {
    composes: source-tag;
    padding: 0 8px;
}

.text-container > :last-child:not(:only-child) {
    /* Subtitle text, if present, should be smaller and lighter color */
    color: var(--grey-lt);
    font-size: var(--font-tiny);
}

.remove-button-container {
    margin-left: 8px;
}

.remove-button-container button {
    padding: 4px;
}

.remove-button-container button:not(:hover) svg {
    fill: var(--placeholder);
}
