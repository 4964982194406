.outer-container {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
}

.inner-container {
    flex-grow: 1;
    position: relative;
}

.details-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: var(--nm-sidebar-width);
}
