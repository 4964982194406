.container {
}

.container-open {
    composes: container;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.checkbox-outer-container {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
}

.checkbox-container {
    font-size: var(--font-small);
    margin-bottom: 2px;
}

.checkbox-container label {
    padding: 2px 16px 2px 0;
    display: flex;
    user-select: none;
    line-height: 17px;
}

.checkbox-container label:hover {
    color: var(--grey-dk);
}

.container-open .checkbox-container label {
    color: var(--grey-dk);
}

.container-open .checkbox-container label:hover {
    background-color: var(--off-white);
}

.checkbox-container input[type='checkbox'] {
    height: 19px;
    flex-shrink: 0;
}

.column-button-unselected svg {
    fill: var(--icon-secondary);
}

.column-button-unselected svg:hover {
    fill: var(--icon-secondary-dk);
}

.column-button-selected svg {
    fill: var(--icon-secondary-dk);
}

.form {
    position: relative;
    margin: 0 16px 8px 0;
    padding: 8px 8px 8px 12px;
    background-color: #fff;
    font-size: var(--font-small);
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

.form :global(.ui-radio-btn) {
    padding: 3px 0;
}
.form input[type='radio'] {
    margin-right: 6px;
}

.form label:hover {
    cursor: pointer;
    color: var(--grey-dk);
}

.form-multiple {
    composes: form;
    padding-bottom: 11px;
    padding-top: 10px;
    margin-top: -1px;
}

.form-multiple:first-child {
    margin-top: 0;
    padding-top: 8px;
}

.form-multiple:last-child {
    padding-bottom: 8px;
}

.multi-form-container {
    position: relative;
}

.close-button {
    position: absolute;
    z-index: 1;
    right: -2px;
    top: -2px;
    padding: 4px 8px;
}

.close-button :global(.svg-close) svg {
    fill: var(--grey-lt);
}

.close-button:hover :global(.svg-close) svg {
    fill: var(--grey);
}

.container-open .form {
    border-radius: 3px 3px 0 0;
    margin-bottom: 0;
}

.container-open .form-multiple:not(:first-child) {
    border-radius: 0;
}

.button-add-filter {
    color: var(--blue);
    background: var(--blue-lt);
    border: 1px solid var(--off-white-dk);
    border-top: none;
    border-radius: 0 0 3px 3px;
    width: calc(100% - 16px);
}

.button-add-filter:hover {
    color: var(--blue-dk);
    background: #fff;
}
