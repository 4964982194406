.container {
    padding: 8px;
    display: flex;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
}

.info {
    width: 80%;
    display: flex;
}

.remove-button {
    width: 22px;
    display: flex;
    align-self: center;
    justify-content: center;
}

.remove-button button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.status-badge {
    position: relative;
    z-index: 1;
    left: 60%;
    top: -10px;
    height: 0;
}

.edit-button {
    width: 44px;
    display: flex;
    justify-content: center;
    align-self: center;
}

.avatar {
    z-index: 0;
    margin-right: 8px;
}
