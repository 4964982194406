.empty-add-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px dashed var(--icon-secondary-dk);
    border-radius: 3px;
    user-select: none;
    color: var(--icon-secondary-dk);
}

.empty-add-card:hover {
    color: var(--icon-primary);
    background: #fff;
    border: 1px solid var(--icon-primary);
}

.empty-add-card:focus-visible {
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}

.off-white {
    background-color: var(--off-white);
}

.add-card-icon {
    line-height: 62px; /* Custom line height for this jank plus sign */
    font-size: 100px;
    margin-bottom: 16px;
}

.add-card-label {
    font-size: var(--font-normal);
}

.variant-small .add-card-icon {
    font-size: 50px;
    line-height: 31px; /* Custom line height for this jank plus sign */
}

.variant-small .add-card-label {
    font-size: var(--font-small);
}

.loading-placeholder {
    background-color: var(--off-white);
    display: flex;
    border-radius: 3px;
    width: 100%;
    height: 100%;
}

.loading-placeholder--white {
    composes: loading-placeholder;
    background-color: var(--white);
}
