.list {
    list-style-type: none;
}

.step {
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    /* We're doing this for progressive enhancement */
    /* stylelint-disable declaration-block-no-duplicate-properties */
    width: fit-content;
    display: flex;
    justify-content: center;
    /* stylelint-enable declaration-block-no-duplicate-properties */
}

.step--active {
    composes: step;
    font-weight: 500;
}

.step--skipped {
    composes: step;
    font-style: italic;
}

.strikethrough {
    position: absolute;
    left: -8px; /* Give a little extra */
    top: 12px;
    width: calc(100% + 16px);
}

.checkmark {
    /* Makes sure checkmark is aligned to the right of the related step */
    position: absolute;
    bottom: -9px;
    right: -38px;
}

.checkmark svg {
    width: 32px;
    height: 32px;
    stroke-width: 6px;
    stroke: #fff;
}

.checkmark :global(.check) {
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.x {
    /* Makes sure the 'x' is aligned with a skipped step. */
    position: absolute;
    top: 0;
    right: -26px;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
