@value loading-state-overlay-z-index: 10;

.fade-full ul > *:last-child,
.fade-with-scrollbar ul > *:last-child {
    margin-bottom: 64px;
}

.list-view-loading::before {
    content: '';
    z-index: loading-state-overlay-z-index;
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    opacity: 0.6;
    background-color: #fff;
    overflow: none;
}

.list-view-loading--no-background-color {
    composes: list-view-loading;
}

.list-view-loading--no-background-color::before {
    background-color: transparent;
}

.fade-full::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 64px;
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 0) 100%
    );
}

.fade-with-scrollbar::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 17px); /* Somewhat-standard width of browser scrollbars */
    height: 64px;
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 0) 100%
    );
}

.updating-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.544);
}
