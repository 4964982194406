:root {
    --first-column-width: 45%;
    --regular-column-width: 15%;
    --fourth-column-width: 10%;
}

.sequence-templates {
    padding: 16px 16px 0 16px;
    overflow: auto;
    flex: 1;
}

.group-list {
    margin-bottom: 32px;
    font-size: 15px;
    min-width: 830px;
}

.group-list th,
.group-list td {
    font-weight: normal;
    padding-left: 16px;
    padding-right: 16px;
}

.group-list tbody tr {
    height: 64px;
}

.group-header {
    height: 32px;
}

.group-list table {
    table-layout: fixed;
}

.column-header {
    height: 32px;
    line-height: 32px;
    user-select: none;
    width: var(--regular-column-width);
}

.column-header:nth-child(1) {
    width: var(--first-column-width);
}

.column-header:last-child() {
    width: auto;
}
.column-header-right-aligned {
    text-align: right;
}

.column-header > button:hover:not([disabled]),
.column-header > button:focus:not([disabled]) {
    color: var(--grey-dk);
}

.column-header > button {
    padding: 0;
    font-weight: 500;
    color: var(--grey);
}

.column-header-no-sort {
    composes: column-header;
}

.column-header-no-sort > button:hover:not([disabled]),
.column-header-no-sort > button:focus:not([disabled]) {
    color: var(--grey);
    text-decoration: none;
    cursor: default;
}

.action-column-header {
    width: var(--fourth-column-width);
}

.row-name-cell {
    display: flex;
    align-items: center;
    height: 64px; /* Redundant, except for IE11 */
}

.row {
}

.row--archived {
    /* Same color as disabled */
    color: var(--grey-lt);
}

.row-summary {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 13px;
    padding-top: 6px;
}

.row-summary-text {
    margin-left: 5px;
}

.right-aligned-cell {
    display: inline-block;
}
.right-aligned-cell-container {
    text-align: right;
    /* padding here to account for right-alignment plus sorting icon in header */
    padding-right: 32px;
}

.sort-button-contents {
    display: flex;
}

.sort-button-contents > *:last-child {
    width: 24px;
    margin-left: 8px;
}

.action-menu {
    display: flex;
    justify-content: flex-end;
}

.empty-icon-wrapper {
    width: 69px;
    height: 69px;
    border-radius: 50%;
    background: var(--icon-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-icon-wrapper > * {
    margin-top: 4px;
    margin-left: 1px;
}

.helper-text {
    font-style: italic;
    line-height: 21px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.loading-container {
    justify-content: center;
    display: flex;
}

.customize-icon {
    padding-right: 4px;
}
