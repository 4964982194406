.container {
    display: flex;
    padding: 8px 12px;
}

.container > * {
    fill: var(--grey);
}

.count {
    padding-left: 4px;
}

/* Force the IconButton in ZoomMeetingData to play nice */
.button > *:first-child {
    margin: 0;
    padding: 0;
    position: relative;
    bottom: 2px;
    left: 10px;
}
