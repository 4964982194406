.radio-group-slider,
.radio-group-slider--big {
    position: relative;
    display: inline-block;
    border: 2px solid var(--off-white-dk);
    border-radius: 20px;
    background-color: #fff;
}

/* Remove margin from normal radio group */
.radio-group-slider [role='radiogroup'] > *,
.radio-group-slider--big [role='radiogroup'] > * {
    margin-bottom: 0;
}

/* Animating slider element */
.radio-group-slider::before {
    position: absolute;
    pointer-events: none;
    z-index: 0;
    content: '';
    display: block;
    height: 100%;
    top: -2px;
    border: 2px solid var(--blue);
    border-radius: 20px;
    transition: all 0.25s ease-in-out;
}

/* Hide default radios */
.radio-group-slider input[type='radio'] {
    opacity: 0;
    height: 0;
    width: 0;
    margin-right: 0;
}

/* Bolded label for the selected content */
.radio-group-slider input[type='radio']:checked + span {
    font-weight: 500;
    color: var(--grey-dk);
}

/* Darkened label for the hovered content */
.radio-group-slider label:hover span,
.radio-group-slider
    input[type='radio']:focus
    + span
    .radio-group-slider
    input[type='radio']:active
    + span {
    color: var(--grey-dk);
}

.radio-group-slider label {
    color: var(--grey);
    border-width: 0;
    border-radius: 20px;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    padding: 3px 10px;
    font-size: var(--font-small);
}

.radio-group-slider label span {
    width: 100%;
}

.radio-group-slider--big label {
    padding: 5px 20px;
    min-width: 80px;
}

/* We use CSS to determine the selection property
and we're currently assuming just 2 elements */
.radio-group-slider--selected-0::before {
    left: -2px;
}

.radio-group-slider--selected-1.radio-group-slider--two-items::before {
    left: calc(50% - 2px);
}

.radio-group-slider--selected-1.radio-group-slider--three-items::before {
    left: calc(33.333333% - 2px);
}

.radio-group-slider--selected-2::before {
    left: calc(66.666666% - 2px);
}

.radio-group-slider--two-items::before,
.radio-group-slider--two-items label {
    width: 50%;
}

.radio-group-slider--three-items::before,
.radio-group-slider--three-items label {
    width: 33.333333%;
}
