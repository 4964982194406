.chevron {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    /* aligns with 8px lead-card gutter */
    padding: 0 16px;
    box-sizing: border-box;
    border-top: 1px solid var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
    height: 64px;
    color: var(--grey-dk);
    background-color: #fff;
    margin-left: 10px;
}

.shadow {
    position: absolute;
    bottom: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    height: 1px;
    width: calc(100% + 10px);
    left: -11px;
}
