.option-card-builder-field-list {
    padding-bottom: 8px;
}

.option-card-builder-field-list > li {
    position: relative;
}

.option-card-builder-field-list > li:not(:first-child) {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--off-white-dk);
}

.error {
    margin-top: 8px;
}
