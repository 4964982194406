.add-card-container {
    padding: 16px 0;
    min-height: fit-content;
}

.new-item-container {
    padding: 16px 0 0 42px; /* This lines up the add block button with the fields */
    box-sizing: border-box;
}

.new-item-container > * {
    height: 50px;
}

.card-container {
    position: relative;
    height: 545px; /* This fits the standard block content */
    margin-left: 28px;
}

.card-container--short {
    composes: card-container;
    height: 406px; /* This fits the standard fields content */
}

.card-container button {
    border: none;
    box-shadow: none;
}

.search {
    padding: 0 16px 16px 16px;
    box-sizing: border-box;
}

.grid-container {
    padding: 0 16px 16px 16px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.divider {
    border-top: 1px solid var(--off-white-dk);
    padding-bottom: 16px;
    width: calc(100% - 32px);
    margin: auto;
}

.all-fields {
    padding: 0 0 8px 8px;
}
