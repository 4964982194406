.modal-left-section {
    background-color: var(--plum-lt);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.done-button {
    position: absolute;
    right: 16px;
    bottom: 16px;
}
