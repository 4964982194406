.eip-button {
	font-family: "fontawesome";
	text-align: center;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 18px;
	height: 18px;
	font-size: @small !important;
	border-radius: 10px !important;
	line-height: 18px;
	color: @grey-lt;
	background-color: @off-white-md;
	padding: 0 !important;
	&:hover {
		color: @grey;
		background-color: @off-white-dk;
	}
	&.action-save {
		color: white;
		background-color: @green;
		&:hover { background-color: @green-dk; }
	}
}

.eip-count-1 .eip-field .star {
	display: none;
}

// valid add'l classes: type-addresses/phones/date/enum
.eip-field {
	position: relative;
	min-height: 48px; // this prevents snapping when entering edit mode
	border-bottom: 1px dotted @off-white-md;

	#util > .hide-safari-autofill();

	// keeping this override at the top, since it's mostly disabling hover stuff
	&.type-enum {
		cursor: default;
		&:hover {
			background-color: inherit !important; // important because this is only defined for peep pages, but we want to override it
		}
	}
	&:hover:not(.variant-readonly) {
		background-color: white;
		cursor: pointer;
		#util > .transition-none;

		.action-edit,
		.action-delete {
			opacity: .7;
			&:hover { opacity: 1; }
		}
		.star { opacity: 0.3; }
	}
	&.blink-success {
		background-color: @green-lt;
		.uniformSpan {
			color: @grey-lt !important; // this stops the label from changing to green
		}
	}


	.label {
		color: @grey;
		font-size: @tiny;
		font-weight: 500;
		min-height: 24px; // in case we don't have a select-mini in here, we still want it to be 24px tall
	}

	div.value {
		#util > .excerpt-crop > .ellipsis;
		width: 100%;
		a:not(.button-interaction) {
			text-decoration: underline; // note this is overridden for phone #s, to avoid underlining space between country code & number
			&:hover {
				background-color: @color-list-hovered;
			}
		}
	}
	.empty {
		display: none;
	}
	.editor {
		display: none;
		clear: both;
	}
	.action-save,
	.action-cancel {
		display: none;
	}
	.action-edit,
	.action-delete {
		position: absolute;
		top: 10px;
		right: 5px;
	}
	.action-delete {
		top: 8px;
		right: 0;
	}
	.eip-buttons {
		position: absolute;
		top: 10px;
		right: 4px;
	}

	.action-edit {
		opacity: 0;
		display: inline-block;
		width: 14px;
		height: 14px;
		background-image: url("@{img-root}/peep/eip-edit_2x.png");
		background-size: 14px 14px;
	}
	.action-delete {
		opacity: 0.1;
		width: 12px;
		height: 12px;
		line-height: 12px;
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		color: @grey;
		&:hover {
			color: @rose;
			opacity: 1;
		}
	}

	input[type=text],
	textarea,
	.value select {
		width: 100%;
		font-size: 13px;
	}

	.value select {
		margin-top: 8px;
	}

	.eip-textarea {
		white-space: pre;
	}

	.star {
		position: absolute;
		top: 12px;
		left: -16px;
		font-size: 9px;
		color: @grey-lt;
		opacity: 0; // hide star until hover
		&:hover {
			opacity: 1;
		}
	}
	&.type-primary .star {
		cursor: default;
		color: @orange;
		opacity: 1;
	}

	&.state-empty {
		.empty {
			font-style: italic !important; // this should ensures that the empty state is always italic
			color: @grey-lt !important; // this should ensures that the empty state is always @grey-lt
			display: block;
		}
	}
	&.state-editing {
		cursor: default;

		div.value { display: none; }
		.editor { display: block; }
		.action-edit { display: none; }
		// this is unforunate, but for now it helps solve the problem of the cancel / delete buttons looking similar
		.action-delete { display: none; }
		.action-save,
		.action-cancel {
			display: inline-block;
		}
		.empty { display: none; }
	}
	h1,
	h2 {
		// this is required, this prevents the background-color from blinking(flashing twice)
		background: none;
	}
	&.blink-success {
		h1,
		h2 {
		  transition: background-color 0.5s ease;
			background-color: @green-lt !important;
		}
		&.state-empty span {
			display: block;
			background: @green-lt !important;
			padding: 0;
		}
	}

	&.type-addresses {
		span.placeholder_overlay {
			font-size: 11px !important; // important to override inline injected by dumb placeholder.js from input style
		}
		input::-webkit-input-placeholder {
			font-size: 11px;
			line-height: 18px;
		}
		// can't combine these ^v
		input::-moz-placeholder {
			font-size: 11px;
			line-height: 18px;
		}
		div.region {
			color: @grey-lt;
			font-size: 11px;
		}
		textarea {
			width: 249px;
			height: 42px;
		}
		span.city,
		span.postalCode {
			&:after {
				content: ',';
			}
			&:empty {
				display: none !important; // important is to override fucking javascript hide() show() in EIP :(
			}
		}
		input[name=city] {
			width: 100px;
			margin-right: 3px;
		}
		input[name=state] {
			width: 36px;
			margin-right: 3px;
		}
		input[name=postalCode] {
			width: 71px;
			margin-right: 3px;
		}
		input[name=country] {
			width: 33px;
		}
	}
	&.type-phones {
		span.country {
			display: inline-block;
			color: @grey-lt;
			font-size: 11px;
		}
		span.phone {
			display: inline-block;
			max-width: 200px;
			text-decoration: underline;
		}
		span.extension {
			color: @grey-lt;
			font-size: 11px;
			&:before {
				content: 'Ext: ';
			}
			&:empty {
				display: none !important; // important is to override fucking javascript hide() show() in EIP :(
			}
		}
		input[name=countryCode] {
			width: 32px;
			color: @grey-lt;
			margin-right: 3px;
		}
		input[name=number] {
			width: 150px;
			margin-right: 3px;
		}
		input[name=extension] {
			width: 50px;
		}
		div.value {
			.click-action-bar {
				position: absolute;
				width: 100%;
				height: 30px;
				top: 29px;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				a:not(:first-child) {
					margin-left: 8px;
				}
			}

			.opted-out-pill,
			.click-to-text {
				svg {
					font-size: 11px;
					width: 11px;
					transform: translateY(2px);
				}
			}

			.opted-out-pill,
			.click-to-call,
			.click-to-text,
			.state-calling-msg {
				line-height: 20px;
				text-decoration: none;
			}

			.click-to-call,
			.click-to-text {
				padding: 0 10px;
				background-color: @green;
				border-radius: 10px;
				color: white;

				i, span { padding: 0 3px; }
				.fa-chevron-down {
					line-height: 19px;
					vertical-align: top;
				}

				&:hover,
				&.state-call-prompt { background-color: @green-dk; }
			}

			.opted-out-pill {
				padding: 0 10px;
				border-radius: 10px;
				background-color: var(--warning-bg);
    			color: var(--warning);

				i, span { padding: 0 3px; }
				.fa-chevron-down {
					line-height: 19px;
					vertical-align: top;
				}
			}

			.state-calling-msg {
				display: none;
				right: 2px;
				color: @green;
				pointer-events: none;
				#util.transition(all, 400ms, @customEasing);
				.fa {
					display: inline-block;
					margin-right: 3px;
				}
			}

			&.state-calling-active {
				.click-to-call { display: none; }
				.state-calling-msg { display: inline; }
			}
		}
	}
	&.type-date {
		.value a { text-decoration: none; }
		&.state-empty .button { display: none; }
	}
	&.type-string,
	&.type-text,
	&.type-currency,
	&.type-enum {
		.action-delete { display: none; }
	}
	&.type-currency {
		.value {
			overflow: visible;
		}
		.amount { // span + input
			display: inline-block;
			width: 25%;
			margin-top: -3px;
			margin-left: 6px;
		}
	}
	&.type-date,
	&.type-currency {
		&.state-editing {
			div.value { display: block; }
			.editor { display: none; }
		}
	}
	&.type-enum {
		.value { overflow: visible; }
		li.bsmListItem {
			margin-top: 8px;
		}
		div.bsmListItemRemove {
			cursor: pointer;
			display: inline-block;
			#icon > .subtract-circle;
			vertical-align: -20%;
			border: none;
			margin-right: 5px;
		}
	}
}
