.container {
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: nowrap;
}

.container button > *:first-child {
    margin-right: 4px;
}
