@value max-detail-width from '../../details-list/details-list.css';

.company-header-container {
    width: max-detail-width;
}
.company-name {
    color: var(--grey-dk);
    font-size: var(--font-big);
    line-height: 22px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.company-name a > :last-child {
    margin-left: 8px;
}

.company-name a:hover {
    text-decoration: underline;
}

.company-timestamp {
    display: flex;
    align-items: center;
    color: var(--grey-lt);
    font-size: var(--font-small);
    line-height: 17px;
}
