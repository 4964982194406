.spacer-bottom-2 {
    margin-bottom: 2px;
}

.spacer-bottom-4 {
    margin-bottom: 4px;
}

.spacer-bottom-8 {
    margin-bottom: 8px;
}

.spacer-bottom-12 {
    margin-bottom: 12px;
}

.spacer-bottom-16 {
    margin-bottom: 16px;
}

.spacer-bottom-24 {
    margin-bottom: 24px;
}

.spacer-bottom-32 {
    margin-bottom: 32px;
}
