.modal-body {
    height: calc(100% - 50px); /* 50px modal header */
    min-height: calc(100% - 50px);
}

.art {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80%;
    margin-left: 10%;
}

.art svg {
    width: 100%;
    height: 256px;
}
