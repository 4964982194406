.stage-guidance {
    max-width: 440px;
    flex-grow: 1;
    flex-basis: 0;
    line-height: 18px;
    padding-left: 21px;
    padding-right: 24px;
}

.stage-guidance h4 {
    line-height: 24px;
    margin-left: -21px;
}

.stage-guidance h4:before {
    content: '\f0eb'; /* Lightbulb */
    font-family: FontAwesome;
    font-size: 18px;
    color: var(--grey-lt);
    padding-right: 10px;
}

.stage-guidance-empty-non-admin {
    composes: stage-guidance;
    padding-left: 0;
    font-size: var(--font-normal);
}
