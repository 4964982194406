.hosting-section {
    padding: 16px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
}

.nutshell-link-text {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--grey-dk);
}

.nutshell-link-text > :first-child {
    flex-shrink: 0;
}

.nutshell-link-text > :last-child {
    /* 97px offset for "Also available at:" text*/
    width: calc(100% - 97px);
}
