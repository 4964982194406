img.feature-pro-badge {
	height: auto;
	width: 28px;
}

#myaccount #registerPhoneType img.feature-pro-badge { // Phone setup page
	position: absolute;
	top: 9px;
	right: 10px;
}

.column-header { // Setup page subheader
	position: relative;
	img.feature-pro-badge {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.report-nav a { // Report page subheader
	position: relative;
	img.feature-pro-badge {
		position: absolute;
		top: 10px;
		right: 0;
	}
}

.ui-modal--nutshell-plan-comparison {
	&.ui-modal--onboarding-phone .ui-modal-body {
		width: 698px;
	}

	&.ui-modal .ui-modal-body {
		overflow-y: scroll;
		padding: 0;
	}
	h1 {
		margin: 1.5em auto 0.75em auto;
		color: @grey-dk;
		font-size: 2.5em;
		font-family: @body-font;
		text-align: center;
	}
	h2 {
		width: 80%;
		margin: 1em auto;
		font-family: @body-font;
		font-weight: normal;
		line-height: 1.5;
		text-align: center;

		em {
			font-style: normal;
			font-weight: 500;
		}
	}

	.scroll-container {
		height: 100%;
		overflow: auto;
	}

	.plan-upgrade-button-container {
		display: none; // default hidden for non-admins
		padding: 15px 0;
		text-align: center;
		background-color: @off-white;
		height: 50%;

		.ui-btn--big {
			min-width: 210px;
			margin: 15px;
		}
	}

	.plan-upgrade-non-admin-container {
		padding: 15px 0;
		text-align: center;
		background-color: @off-white;
		height: 50%;
	}

	.days-left {
		display: block;
		padding: 15px 0;
		font-size: @big;
		color: @grey-lt;
	}

	.feature-links {
		display: flex;
		justify-content: space-around;
		padding: 40px 20px 0 20px;
		border-bottom: 2px solid @off-white-dk;
		text-align: center;

		a { flex-basis: 45%; }
		.link-title {
			display: inline-block;
			margin-bottom: .75em;
			font-size: 22px;
			font-weight: 600;
			letter-spacing: 0.5px;
			color: @blue;
		}
		.feature-image {
			display: block;
			width: 100%;
		}
		a:hover .link-title {
			color: @blue-dk;
			background-color: @yellow-lt;
		}
	}
}

.plan-comparison-chart-wrapper {
	table {
		font-size: @normal;

		.title {
			position: relative;

			h2 {
				position: absolute;
				top: 0;
				left: 0;
				font-weight: normal;
				color: @grey-dk;
			}
		}

		td, th {
			border-bottom: 2px solid @off-white-md;
			padding: 0 0 0 20px;
			background-color: white;
		}

		.check-column {
			padding: 0;
			text-align: center;
			width: 165px;

			> div {
				margin-right: 20px;
				padding: 1px 0;
				height: 32px;
			}
		}

		th.check-column {
			> div {
				min-height: 40px;
				box-sizing: border-box;
				padding: 11px 0 0 0;
				background-color: @grey-lt;
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
				font-family: @body-font;
				font-size: 13px;
				line-height: 18px;
				color: @off-white;
				position: relative;
				bottom: -2px;
				span {
					letter-spacing: 0.5px;
					font-weight: normal;
				}
				span.plan-name {
					font-weight: 500;
					display: block;
					font-size: 18px;
				}
			}

			&.current-plan {
				> div { padding: 3px 0 0 0; }
			}
			&.pro-plan {
				> div {
					background-color: @slate-blue;
					color: @slate-blue-lt;
				}
			}
		}

		tr:nth-of-type(2n) td { background-color: @off-white; }

		tr td.current-plan {
			> div { background-color: fade(@yellow-lt, 75%); }
		}

		.fa-check {
			font-size: 25px;
			line-height: 35px;
			color: @green;
		}
	}
}
