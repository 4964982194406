.notifications-message {
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
    display: block;
}

.notifications-message h1 {
    font-family: var(--body-font);
    margin: 0;
    text-align: center;
    font-size: var(--normal);
}

.notifications-message p {
    font-family: var(--body-font);
    font-size: var(--small);
    text-align: center;
    color: var(--grey-lt);
}

.notifications-message-empty {
    display: block;
}

.notifications-message-loading {
    display: flex;
    justify-content: center;
}

.empty-state-graphic {
    width: 70px;
    margin: 0 auto 20px auto;
    display: block;
}

.loading-icon {
    margin-top: 8px;
    fill: var(--off-white-dk);
}
