.container {
    padding: 4px 0;
    height: 200px;
}

.table-header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    box-sizing: border-box;
    height: 25px;
    color: var(--grey-dk);
    font-size: var(--font-small);
}

.loading {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.form-body {
    height: calc(100% - 70px);
}

.outcomes-container {
    margin-bottom: 16px;
}

.competitors-container {
    max-height: calc(100% - 16px);
    overflow-y: auto;
}

.buttons {
    text-align: right;
}

.buttons > button:first-child {
    margin-right: 4px;
}
