.container .add-on-container:nth-child(odd) {
    background-color: var(--zebra-stripe);
}

.add-on-container {
    padding: 16px;
}

.add-on-content {
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 90px;
    display: grid;
}
