.session-details {
    padding: 20px 24px;
    width: 100%;
    position: relative;
}

.session-details:hover,
.session-details:focus,
.session-details:active {
    background-color: var(--color-list-hovered);
}

.icon-container {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--blue);
}

.session-details:hover .icon-container,
.session-details:focus .icon-container,
.session-details:active .icon-container {
    color: var(--blue-dk);
}
