.container {
    padding: 5px 10px;
}

.container--light {
    composes: container;
}

/* Regular (Dark) variant */
.container :global(.svg-close) svg {
    fill: var(--grey);
}

.container:hover :global(.svg-close) svg,
.container:focus :global(.svg-close) svg {
    fill: var(--grey-dk);
}

/* Medium variant */
.container--medium :global(.svg-close) svg {
    fill: var(--button-secondary-dk);
}

.container--medium:hover :global(.svg-close) svg,
.container--medium:focus :global(.svg-close) svg {
    fill: var(--grey-lt);
}

/* Light variant*/
.container--light :global(.svg-close) svg {
    fill: var(--white);
}

.container--light:hover :global(.svg-close) svg,
.container--light:focus :global(.svg-close) svg {
    fill: var(--off-white-dk);
}
