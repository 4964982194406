.channel-icon-with-name {
    display: flex;
    align-items: center;
    padding: 0 8px;
}

.channel-icon-with-name > :first-child:not(:only-child) {
    fill: var(--grey);
    color: var(--grey);
    margin-right: 8px;
}

.channel-icon-with-name > :last-child {
    color: var(--grey-dk);
}

.channel-icon-with-name--small {
    composes: channel-icon-with-name;
    padding: 4px 0;
}

.channel-icon-with-name--small > :first-child:not(:only-child) {
    margin-right: 4px;
}

.channel-icon-with-name--small > :last-child {
    color: var(--grey);
}
