.timeline-summary {
    background-color: var(--off-white);
    padding: 16px;
    color: var(--grey);
    font-size: var(--font-small);
    line-height: 17px;
}

.header-title {
    font-size: var(--font-normal);
    font-weight: 500;
    line-height: 19px;
    color: var(--grey-dk);
}
