.container {
    position: relative;
    height: 100%;
    background-color: white;
    user-select: none;
}

.icon {
    fill: var(--icon-secondary);
    padding: 64px 0 16px 0;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.icon :global(.svg-activity-empty-state-icon .surface),
.icon :global(.svg-activity-error-state-icon .surface),
.icon :global(.svg-todo-empty-state-icon .surface),
.icon :global(.svg-todo-error-state-icon .surface) {
    fill: var(--off-white);
}

.text-container {
    text-align: center;
}

.header {
    font-size: var(--font-big);
    color: var(--grey);
    font-weight: 500;
    margin-bottom: 10px;
}

.subheader {
    margin: 0 auto;
    max-width: 90%;
    font-size: var(--font-normal);
    line-height: 21px;
    margin-bottom: 20px;
    color: var(--grey-lt);
}

.divider {
    margin: 32px auto;
    width: 50%;
}
