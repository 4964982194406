.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 24px; /* purposefully a bit extra, so it's distanced from the raw numbers */
    padding-left: 4px;
    width: 31px; /* ensures no percent text or 1000+% text doesn't jack up alignment */
}

.percent-text {
    margin-bottom: 6px; /* aligns text with raw values to the right */
    font-size: var(--font-big);
}
