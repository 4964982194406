.left {
    background-color: var(--blue-lt);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: fadeIn fadeDuration ease-in forwards;
}

.container {
    padding: 100px 16px 0 16px;
}

.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px;
}

.modal-image {
    width: 260px;
}
