.header {
    font-size: 15px;
    color: var(--grey-dk);
    padding: 10px;
    border-bottom: 1px solid var(--off-white-dk);
    margin-bottom: 5px;
}

.list ul {
    margin-bottom: 10px;
    max-height: 200px;
    overflow-y: auto;
}
