@value sectionGap: 6px;

.tooltip-table {
    width: auto;
}

.tooltip-table--full-width {
    width: 100%;
}

.tooltip-container {
    composes: tooltip from '../../shared.css';
    display: flex;
}

.tooltip-container--compare-mode {
    composes: tooltip from '../../shared.css';
    display: flex;
    width: 100%;
    padding: 0; /* Override shared styles */
}

.tooltip-table-container {
}

.row--padded td {
    padding-top: sectionGap;
}

.row--total {
    composes: row--padded;
    font-weight: 500;
}

.data-value {
    padding-left: 6px;
    text-align: right;
}

.total {
    font-weight: 500;
}

.tooltip-heading {
    margin-bottom: sectionGap;
    margin-left: 6px;
}

.tooltip-heading--labels {
    margin-bottom: sectionGap;
}

.labels-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.label-container {
    display: flex;
    align-items: baseline;
}

.label-container span {
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.legend-square {
    height: 8px;
    width: 8px;
    margin-right: 3px;
    border: 1px solid #fff;
}

.legend-dot {
    height: 6px;
    width: 6px;
    border-radius: 5px;
    margin-right: 3px;
    border: 2px solid #fff;
}

.data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.flex-container {
    display: flex;
}

.unit-label {
    padding-left: 24px;
    color: var(--grey-lt);
}

.tooltip-table-comparison-container--reference {
    display: flex;
    padding: 10px 14px;
}

.tooltip-table-comparison-container--current {
    display: flex;
    padding: 10px 14px;
    background-color: black;
    border-radius: 0 3px 3px 0;
}

.tooltip-table-comparison-container--current-empty {
    composes: tooltip-table-comparison-container--current;
    width: 200px;
}

.tooltip-table-comparison-container--reference .tooltip-table-container {
    color: var(--grey-lt);
}

.tooltip-deltas-container {
    margin-left: 12px;
}

.tooltip-delta-value {
    padding-left: 3px;
}

.tooltip-delta-value--positive {
    composes: tooltip-delta-value;
    color: var(--green);
}

.tooltip-delta-value--negative {
    composes: tooltip-delta-value;
    color: var(--rose);
}
