.container {
    margin-bottom: 16px;
}

/* remove 4px top and bottom margin on flash banner so this display nicely with tables */
.container > div:first-child {
    margin: 0;
}

.body-container {
    max-width: 80%;
}
