.section-header {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    width: 100%;
    margin-top: 4px;
}

.section-header:hover {
    background-color: var(--navy-bg-new);
    cursor: pointer;
}

.section-header:focus-visible:not([disabled]) {
    background-color: var(--navy-bg-new);
    outline: 2px solid var(--focus-blue);
    outline-offset: 0;
}

.section-header--active {
    background-color: var(--navy-bg-active-new);
}

.section-header--embedded {
    background-color: var(--rcfx-sidebar-primary-item);
}

.section-header--embedded:hover {
    background-color: var(--rcfx-sidebar-active);
}

.section-header--embedded:focus-visible:not([disabled]) {
    background-color: var(--rcfx-sidebar-active);
}

.section-header-icon {
    width: 16px;
    height: 14px;
    margin-right: 12px;
}

.section-header-label {
    color: var(--white);
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    flex: 1;
}

.section-header-chevron {
    margin-left: 12px;
}
