.control-bar {
    display: flex;
    flex-shrink: 0;
    padding: 16px;
    border-bottom: 1px solid var(--off-white-dk);
    background-color: white;
    justify-content: space-between;
    align-items: center;
}

.control-bar--short {
    height: 50px;
    padding: 0 16px;
}

.control-bar--with-archived {
    padding-right: 0;
}

.filter-controls {
    width: auto;
    display: flex;
    align-items: center;
}

.tabs {
    margin-left: auto; /* Justifies it to the left */
}
