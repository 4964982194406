.tooltip-container {
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    color: var(--white);
    max-width: 150px;
}

.tooltip-container > :first-child {
    margin-bottom: 4px;
}
