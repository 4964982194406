.floating-action-button {
    position: fixed;
    /* Set to 5 to clear overlapping avatars component - max displayed is 5 */
    z-index: 5;
    /* White background because disabled button uses opacity */
    background-color: var(--white);
}

.floating-action-button button {
    box-shadow:
        0 1px 3px 0 rgba(0, 0, 0, 0.3),
        0 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.floating-action-button button:hover:not([disabled]) {
    box-shadow:
        0 2px 3px 0 rgba(0, 0, 0, 0.3),
        0 6px 10px 4px rgba(0, 0, 0, 0.15);
}
