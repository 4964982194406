//ui-input + ui-box (siblings)
.ui-linked-select {
	.ui-input, input {
		width: 50px;
		margin-right: 4px;
	}
	.ui-select { margin-right: 5px; }

	//text after select box "{5 minutes} __ago__"
	span { color: @grey-lt; }

	&.ui-linked-select__string-form,
	&.ui-linked-select__number-form,
	&.ui-linked-select__integer-form {
		.ui-input, input {
			width: 100%;
			margin-right: 0;
			margin-top: 2px;
		}
	}

	&.ui-linked-select--inline {
		.ui-input, input { float: left; }
	}
}
