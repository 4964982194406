.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.required-label {
    margin-bottom: 10px;
    color: var(--grey-lt);
    position: relative;
    display: inline-block;
    font-weight: normal;
}

.required-label--invalid {
    composes: required-label;
    color: var(--rose);
}

.required-label:before,
.required-label--invalid:before {
    content: '*';
    position: absolute;
    right: 7px;
    color: var(--rose);
}

.assignee-and-quotatypes-container {
    display: flex;
    flex-direction: row;
}

.list-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.assignee-container {
    composes: list-container;
    margin-right: 10px;
}

.scroll-list {
    border: solid 1px var(--off-white-md);
}

/* extra specificity is required to override global style */
.list-container .scroll-list ul {
    height: 221px; /* 221px + dash header(35px) + warning container 50px = 306px (preview height) */
}

.quotatypes-label-container {
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.warning-container {
    position: relative;
    height: 50px;
    overflow: hidden;
}

.warning {
    position: absolute;
    text-align: center;
    width: 100%;
    color: var(--rose);
}

.warning-shown {
    composes: warning;
    top: 6px;
    opacity: 1;
    transition: all 500ms;
}

.warning-hidden {
    composes: warning;
    top: -40px;
    opacity: 0;
    transition: all 500ms;
}
