@import 'include';

// disabling animation for now since not even my browser can keep up :(
@stepHoverAnimation: 0;
@stepInternalWidth: 530px;

#process-builder {
	.page-sidebar {
		float: left;
	}

	.sidebar-box {
		.box-section-wrap {
			// Allow the last list items bottom-border to show
			padding-bottom: 32px;
		}
		.box-section {
			font-size: @normal;
			line-height: 19px;
			border-top: none;
			padding: 8px 8px 8px 0;
			margin: 0 0 0 16px;
			.icon {
				position: relative;
				top: 3px;
			}
			&.box-featured {
				margin: 0;
				padding: 16px 16px 0 16px;
				.button {
					display: block;
					margin-bottom: 6px;
				}
			}
			&.variant-divider {
				font-size: @small;
				font-weight: 500;
				color: @grey-dk;
				border-bottom: 1px solid @off-white-md;
				margin-top: 16px;
			}

			// Hide label when there is only one
			&.only-one-funnel {
				&.variant-divider {
					display: none;
				}
				&.variant-disclosure {
					background: none;
					border-top: 1px solid @off-white-md;
					margin-top: 16px;
					margin-left: 0;
					padding-left: 16px;
					.icon {
						display: none;
					}
				}
			}

			// Indent children
			&.variant-disclosure {
				border-bottom: 1px solid @off-white-md;
				margin-left: 28px;
				padding-left: 4px;
			}
			&.variant-disclosure.no-multiple-funnel {
				margin-left: 0;
				padding-left: 8px;
			}
		}
	}

	#process-builder-process {
		.box-header {
			.buttons {
				position: absolute;
				top: 4px;
				right: 4px;
			}
		}

		.action-cancel {
			display: none;
		}
		.action-save {
			display: none;
		}
		&.state-new {
			.action-saveas {
				display: none;
			}
		}

		&.state-dirty {
			.action-cancel {
				display: inline-block;
			}
			.action-save {
				display: inline-block;
			}
		}

		.process-builder-meta {
			background-color: @yellow-lt;
			padding: 12px;
		}

		.milestone {
			.section-header {
				position: relative;
				margin-bottom: -1px;
				.icon {
					cursor: pointer;
					float: left;
					margin-top: 6px;
					margin-right: 4px;
				}

				.action-manage-milestones {
					display: none;
					position: absolute;
					right: 10px;
					font-size: 11px;
					color: white;
					text-decoration: none;
					-webkit-transition-property: opacity;
					-webkit-transition-duration: @stepHoverAnimation;
				}
			}
			.section-header:hover .action-manage-milestones {
				display: inline;
			}
		}

		.milestone.state-open .section-header .icon {
			#icon > .spindown-open;
		}
		.milestone.state-closed {
			.section-header .icon {
				#icon > .spindown-closed;
			}
			.step {
				display: none;
			}
			.builder-empty-placeholder {
				display: none;
			}
		}
		.milestone.state-empty .builder-empty-placeholder {
			display: block;
		}

		.step.template {
			display: none;
		}

		.step,
		.step * {
			-webkit-transition-property: opacity;
			-webkit-transition-duration: @stepHoverAnimation;
		}

		.step {
			// see also .builder .block
			padding-left: 28px;
			min-height: 72px;

			.buttons-container {
				-webkit-transition-property: all;
				position: absolute;
				top: 0;
				left: 0;
				width: 27px;
				height: 100%;
				border-right: 1px solid @off-white-dk;
				.buttons {
					-webkit-transition-property: all;
					width: 100%;
					height: 100%;
					background: url('@{img-root}/process-builder/step-buttons-bg.png') repeat-y;

					.icon {
						cursor: pointer;
						#util > .inline-block;
						#icon > .pbuilder-button;
						margin-top: 4px;
						margin-left: 6px;
					}
					.icon.type-actions {
						#icon > .pbuilder-button-actions;
					}
					.icon.type-delays {
						#icon > .pbuilder-button-delays;
					}
					.icon.type-prereqs {
						#icon > .pbuilder-button-prereqs;
					}
				}
			}

			.popover {
				display: none;
			}

			.icon.action-handlebar {
			}

			.step-inner {
				float: left;
				padding: 8px;
				width: ~'calc(100% - 16px)';

				input.name {
					width: ~'calc(100% - 210px)';
					font-size: 15px;
					font-weight: 500;
				}

				.placeholders {
					padding-top: 6px;
					font-size: 12px;
					color: @grey; /* TODO: standardize color */
					label {
						padding-right: 10px;
					}
				}

				.assignee {
					float: right;
					margin-right: 30px;
					min-width: 150px;
				}

				.due,
				.description,
				.requirement {
					display: none;
					font-size: 12px;
					padding-top: 4px;
					clear: both;
				}

				.due {
					line-height: 24px;
					height: 24px;
					vertical-align: 0%;

					input.main-checkbox {
						vertical-align: top;
						margin-top: 8px;
					}
					input.due-duration {
						width: 18px;
						#util > .inline-block;
					}
					.select-mini {
						#util > .inline-block;
						vertical-align: -30%;
						float: none;
						.selector {
							background: none;
						}
					}
				}

				.description {
					display: none;
					textarea {
						width: @stepInternalWidth - 6 - 2;
					}
				}

				.requirement {
					white-space: nowrap;
					.tselect-opener {
						// Slightly reduce padding of .button-action so it aligns better with neighbouring text
						padding: 3px 9px;
					}
					.tselect-icon:before {
						top: 3px;
					}
					input.main-checkbox {
						vertical-align: top;
						margin-top: 6px;
					}

					.select-mini {
						#util > .inline-block;
						vertical-align: -30%;
					}

					.requirement-attach {
						margin-left: 5px;
						display: none;
						.select-mini {
							float: none;
						}
					}

					.requirement-lead-win {
						display: block;
					}

					.tselect-wrapper {
						z-index: 100;
					}
				}
			}
		}
		.block-wrapper:hover,
		.block-wrapper.state-hover-delay {
			.assignee .action-assign {
				display: block;
			}

			.buttons-container {
				border-right: 1px solid @off-white-dk;
				.buttons {
					background: url('@{img-root}/process-builder/step-buttons-hover-bg.png')
						repeat-y;

					.icon.type-actions {
						#icon > .pbuilder-button-actions.hover;
					}
					.icon.type-delays {
						#icon > .pbuilder-button-delays.hover;
					}
					.icon.type-prereqs {
						#icon > .pbuilder-button-prereqs.hover;
					}
				}
			}
		}

		.adder {
			left: -1px; // Allows for the width of the .buttons-container, .adder should display full width
		}

		// orange icons
		.step.has-actions.has-valid-config .icon.type-actions {
			#icon > .pbuilder-button-actions.selected;
		}
		.step.has-delays .icon.type-delays {
			#icon > .pbuilder-button-delays.selected;
		}
		.step.has-prereqs .icon.type-prereqs {
			#icon > .pbuilder-button-prereqs.selected;
		}

		.step.show-due .due {
			display: block;
		}
		.step.show-description .description {
			display: block;
		}
		.step.show-requirement .requirement {
			display: block;
		}

		.step.show-requirement-attach .requirement .requirement-attach {
			#util > .inline-block;
		}
		.step.has-delays .process-builder-popover-delays .hide-part {
			display: block;
		}
		.step.has-prereqs .process-builder-popover-prereqs .prereq-criteria {
			display: block;
		}
		.step.has-actions .process-builder-popover-actions .hide-part {
			display: block;
		}
		.step.has-actions-criteria .process-builder-popover-actions .actions-criteria {
			display: block;
		}
		.step.has-actions-criteria .process-builder-popover-actions .hide-part-else {
			display: block;
		}

		.step.has-actions .actions-type-config-active {
			display: block;
		}
	}
}

/* ============ */
/* = popovers = */
/* ============ */
.process-builder-popover .hide-part,
.process-builder-popover .hide-part-else {
	display: none;
}
.process-builder-popover-actions {
	.actions-criteria {
		display: none;
		.criteria {
			clear: both;
			margin: 8px 0;
			position: relative; // Allows for absolutely positioned remove link
			adding-right: 10px; // Allow space for remove icon
		}
		.button-remove-link {
			position: absolute;
			top: 0;
			right: 0;
			font-weight: 500;
			color: @grey-lt;
			&:hover {
				color: @rose;
			}
		}
		.popover-area {
			clear: both;
			padding: 10px 0 20px 0;
		}
		.operand-currency {
			width: 50px;
			position: relative;
			top: -4px;
		}
	}
	.popover-area {
		border-top: 1px solid @off-white-dk;
	}
	.or-else-section .popover-area {
		border-top: none;
	}
	.select-mini {
		float: none;
		#util > .inline-block;
	}
	.action-type-config {
		display: none;
	}
	.confidence-slider-widget-left {
		padding: 10px 0;
	}
}

.process-builder-popover-delays {
	select {
		margin: 12px 0;
		width: 100%;
	}
	li {
		line-height: 22px;

		div.bsmListItemRemove {
			cursor: pointer;
			#util > .inline-block;
			#icon > .subtract-circle;
			vertical-align: -20%;
		}
		a.bsmListItemRemove {
			display: none;
		} // hide the default link
		.timespan {
			float: right;
			color: @grey-lt;
			font-size: 12px;
			line-height: 22px;
			width: 100px;
		}
	}
}

.process-builder-popover-prereqs {
	select {
		margin: 12px 0;
		width: 100%;
	}
}
