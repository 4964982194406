.settings-card {
    height: fit-content;
}

.settings-card--disabled {
    composes: settings-card;
    opacity: 0.6;
}

.container {
    padding: 16px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}

.action {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    min-width: 100px;
}
