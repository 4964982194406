#modal .modal-entity-bulk-edit {
	.list-standard.bulk-edit-options {
		border: none;
		.column-label {
			font-weight: 500;
			line-height: 22px; // prevent capsule value column from bouncing heights
			width: 130px;
			text-align: right;
		}
		.column-value {
			padding-left: 10px;
			&.type-date {
				.selector .uniformSpan {
					position: relative;
				}
				.action-edit {
					top: 1px;
				}
			}
		}
		.outcomes {
			display: inline-block;
		}

		.bulk-edit-assignment .capsule-add-container {
			width: 140px;
		}

		tr {
			border-bottom: 1px solid @off-white-dk;
		}
		td {
			padding: 10px 0;
		}

		// Hide additional tag labels and links
		.tags-bar-label,
		.tags-bar-link {
			display: none;
		}

		.column-label-unsubscribe,
		.column-value-unsubscribe {
			vertical-align: text-top;
		}

		.unsubscribe-warning {
			color: @rose-dk;
			font-style: italic;
			margin-top: 1em;
			max-width: 400px;
		}
	}
}
