.special-state {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 10;
}

.error-state {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
