.added-contacts-list a > div {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    background-color: var(--blue-xlt);
}

.list-header {
    composes: detail-name from '../../details-list/details-list.css';
    margin-bottom: 12px;
}

.list-header span {
    font-weight: 400;
    color: var(--grey-lt);
}

.added-contacts-list a > div > :last-child {
    color: var(--blue);
}

.added-contacts-list a:hover > div {
    background-color: var(--blue-lt);
}

.added-contacts-list a:hover > div > :last-child,
.added-contacts-list a:focus > div > :last-child {
    color: var(--blue-dk);
}
