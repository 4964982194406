.peep-related-leads {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 68px;
    padding-bottom: 16px;
    gap: 16px;
}

.create-lead {
    position: absolute;
    top: 0;
    right: -10px; /* Offsets 10px padding for bigger click target */
}
