.power-ai-usage-popover-content {
    max-width: 300px;
    box-sizing: border-box;
    padding: 16px;
    color: var(--grey);
}

.no-credits {
    font-weight: 500;
    font-size: var(--font-small);
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.error-svg-container {
    width: 64px;
}

.title {
    font-size: var(--font-big);
    font-weight: 500;
    color: var(--grey-dk);
}

.branded {
    color: var(--ai-blue);
}
