.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
}

.container--small {
    composes: container;
    padding-right: 0;
}
