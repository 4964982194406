.main-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 5;
}

.main-content--narrow {
    composes: main-content;
    width: calc(100% - var(--page-sidebar-width));
}
