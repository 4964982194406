@value pageHeaderHeight: 83px;

.page-container {
    width: 100%;
    height: calc(100% - pageHeaderHeight);
    position: absolute;
    margin: 0;
    display: flex;
    overflow: hidden;
}

.integration-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 64px;
    box-sizing: border-box;
}
