.email-form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

/* This is the subject field */
.email-form-section > input {
    /* Overriding default padding of 4px 8px to align
    with `to` and `from` labels */
    padding: 4px 16px;
    /* Matches the from/to height */
    height: 31px;
    flex-shrink: 0;
    /* Line up borders with other fields */
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
    border-radius: 0;
    width: calc(100% + 2px);
    border: 1px solid var(--off-white-md);
}

.email-form-section > input:hover {
    z-index: 1;
    border: 1px solid var(--grey-lt);
}

.email-form-section > input:focus,
.email-form-section > input:active {
    z-index: 1;
    border: 1px solid var(--blue) !important;
}

.body {
    flex: 1;
    min-height: 0; /* Prevent from growing too large */
    position: relative;
}

.body:hover {
    border: 1px solid var(--grey-lt);
    margin: -1px;
}

.body--focused {
    composes: body;
    /* This should outweigh hover, but pseudo-selectors win out */
    border: 1px solid var(--blue) !important;
    margin: -1px;
}
