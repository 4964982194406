.a-b-tested-field {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: var(--off-white);
    margin-bottom: 16px;
    border-radius: 3px;
}

.a-b-tested-field--comparing {
    composes: a-b-tested-field;
    border: 1px solid var(--blue);
}
