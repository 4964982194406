.ui-popover__search-result {
	#util > .clearfix;
	position: relative;
	cursor: pointer;
	box-sizing: border-box;

	&:hover { background-color: @blue-lt; }
	span {
		margin-right: 10px;
		&.entity {
			font-weight: 500;
			em { font-style: normal; text-decoration: underline; }
		}
		em { font-style: normal; text-decoration: underline; }
	}

	&.selected { background-color: @blue-lt; }
}
