.timeline-container {
    background-color: var(--off-white);
    padding: 16px;
    height: 100%;
    min-height: 600px;
}

.timeline-container--dashboard {
    composes: timeline-container;
    overflow: auto;
    padding: 12px;
}
