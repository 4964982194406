@value footerHeight: 50px;

.footer-bar {
    height: footerHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    padding: 16px 0;
    /* Whaaaat?? We want 50px + 16px of padding, and this accomplishes that */
    box-sizing: content-box;
}

.footer-bar--bordered {
    composes: footer-bar;
    margin: 0 -32px;
    padding-left: 32px;
    padding-right: 32px;
    border-top: 1px solid var(--off-white-dk);
}

.footer-bar > *:first-child,
.footer-bar > *:last-child {
    flex-shrink: 0;
}

.footer-bar:not(.no-margin) > *:first-child {
    margin-right: 50px;
}

.footer-bar:not(.no-margin) > *:last-child {
    margin-left: 50px;
}

.absolute-position {
    position: absolute;
    bottom: 0;
    width: calc(100% - 64px);
}

.no-padding {
    padding: 0;
}

.fit-height {
    height: auto;
}
