.relative-container {
    position: relative;
    margin: 8px 0;
    height: 64px;
}

.relative-container--medium {
    composes: relative-container;
    height: 82px;
}

.relative-container--tall {
    composes: relative-container;
    height: 110px;
}
