.content-container {
    position: relative;
    height: 100%;
}

.content-container--disabled {
    composes: content-container;
}

.content-container--disabled > *:not(.close-button) {
    pointer-events: none;
}

.content-container > .close-button {
    position: absolute;
    z-index: 1;
    top: 24px;
    right: 24px;
}
