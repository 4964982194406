@value columnWidth from './app/card-containers/cards-container.css';

.container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
}

.chevron {
    height: 36px;
    width: 36px;
    margin-right: 8px;
}

.stage-name {
    flex-grow: 1;
    line-height: 20px; /* even number helps center text */
    margin-right: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    /* need room for default confidence */
    max-width: 220px;
}

.default-confidence {
    color: var(--grey-lt);
    font-size: var(--font-normal);
}

.description-icon {
    color: var(--grey-lt);
    display: inline-block;
    font-size: 11px;

    /* This icon's size is a POS, so this helps align it w/ the text */
    line-height: 11px;
    margin-top: 5px;
    margin-left: 8px;
}
