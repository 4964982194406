@value height: 38px;
@value spacing: 10px;

.info {
    min-height: height;
    padding-bottom: spacing;
}

.info:last-child {
    padding-bottom: 0;
}

.placeholder {
    height: height;
    margin-bottom: spacing;
    width: 100%;
    border-radius: 3px;
    background-color: var(--off-white);
}

.placeholder:last-child {
    margin-bottom: 0;
}

.label {
    padding-bottom: 2px;
}

.label--new {
    composes: label;
    color: var(--plum);
}

.value {
    font-size: var(--font-normal);
    font-weight: 500;
    padding-right: 4px;
}

.value--new {
    composes: value;
    color: var(--plum);
}
