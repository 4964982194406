.sticky-scroll-entity-list-container {
    display: flex;
    flex-direction: column;
}

.fixed-list-header-container {
    position: relative;
    width: 100%;
}

.list-header-container {
    position: relative;
    z-index: 50;
    width: 100%;
    height: 34px;
}
