.sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
}

.two-column-container {
    composes: sidebar-container;
    flex-direction: row;
}

.col-1-container {
    position: relative;
    flex-shrink: 0;
    flex-grow: 1;
    border-right: 1px solid var(--off-white-dk);
}

.col-2-container {
    flex-grow: 0;
    height: 100%;
    width: 500px;
    margin-left: 36px;
    border-left: 1px solid var(--off-white-dk);
}

.col-2-container--wide {
    composes: col-2-container;
    width: 800px;
}

.col-2-container--medium {
    composes: col-2-container;
    width: 650px;
}

.col-2-container label[for='feed-0'] {
    cursor: default;
}

.col-2-container label[for='feed-0']:hover {
    color: var(--grey) !important;
    border-color: var(--off-white-md) !important;
}

.active-container {
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    width: 100%;
    height: calc(100% - 36px);

    position: absolute;
    left: 0;
    top: 36px;
}

.active {
    width: 100%;
    height: 100%;
}

.inner-label-container {
    position: relative;
    display: inline-block;
}
