.message {
    padding: 8px;
    z-index: 10;
    background-color: var(--blue-xlt);
    color: var(--blue);
    border-top: 1px solid var(--blue-lt);
    border-bottom: 1px solid var(--blue-lt);
}

.alert {
    composes: message;
    background-color: var(--rose-xlt);
    color: var(--rose);
    border-color: var(--rose-lt);
    border-bottom: unset;
}

.alert--bottom-border {
    composes: alert;
    border-bottom: 1px solid var(--rose-lt);
}
