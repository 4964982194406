.ui-entity-list,
.ui-funnel-report {
	.ui-entity-list__filter-header,
	.ui-funnel-report__filters-container {
		#util > .clearfix();
		padding: 2px 16px 5px 16px;

		.ui-filter-header__add-filter {
			float: right;
			margin-top: 5px;
		}

		.ui-filter-header__selected-filters {
			float: left;

			> div {
				margin-top: 5px;
			}
		}

		.ui-filter-header__text-search-container {
			float: left;
			margin-top: 5px;
			margin-right: 5px;
			width: 152px;
			border: 1px solid @off-white-dk;

			.search-icon-container {
				top: 2px;
			}
			.ui-filter-header__text-search {
				line-height: 16px;
				height: 22px;
				padding-left: 8px;
			}
		}
	}

	.ui-entity-list__table-header {
		#util > .clearfix();
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding-top: 4px;

		.ui-entity-list__table-header__top-row,
		.ui-entity-list__table-header__bottom-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.svg-icon-container {
			float: left;
			margin-right: 5px;

			&.svg-map-pin {
				margin-top: -2px;
			}
			svg {
				fill: white;
			}
		}

		.ui-radio-group label,
		.ui-btn {
			padding: 7px 10px 8px 10px;
			font-size: 15px;
			font-weight: 500;
		}

		.ui-btn {
			padding: 8px 10px 9px 10px;
		}

		.ui-canned-filter-selector {
			display: block;
			position: relative;
			float: left;

			.ui-canned-filter-expand {
				cursor: pointer;
				position: absolute;
				top: 0;
				right: -33px;
				height: 34px;
				font-size: 22px;
				border-radius: 2px 2px 0 0;
				color: white;
				padding: 2px 8px;
			}

			&.ui-canned-filter-selector--status {
				li > label {
					min-width: 80px;
				}
			}

			li {
				position: relative;
				display: inline-block;
				float: none;
				margin-right: 4px;

				&:first-of-type {
					margin-left: 16px;
					label {
						padding-left: 12px;
						padding-right: 12px;
					}
				}

				.ui-canned-filter__type,
				.ui-canned-filter__count {
					display: block;
					text-align: left;
				}

				.ui-canned-filter__type {
					#util > .excerpt-crop > .ellipsis();
					font-size: 13px;
					line-height: 15px;
					max-width: 100px;
				}

				.ui-canned-filter__count {
					display: block;
					text-align: left;
					opacity: 0.8;
					font-size: 11px;
					line-height: 14px;
				}

				label {
					box-sizing: border-box;
					padding: 3px 8px 1px 8px;
					border-radius: 2px 2px 0 0;
					height: 38px;
					min-width: 50px;
				}
			}
		}

		.ui-entity-list__table-header__controls {
			#util > .clearfix();
			float: right;
		}

		.ui-list-selector {
			float: right;
			margin-right: 14px; // + 2px margin-right on .ui-radio-btn--type-div to give us a 16px gutter
			li {
				margin-right: 0;
				&.ui-list-selector--disabled label {
					cursor: default;
					color: @off-white-dk;
					background-color: @off-white-md;
				}
			}
		}

		.ui-column-picker__open {
			margin-right: 10px;
			margin-bottom: 4px; //to stop wrapping
			float: right;
		}

		.ui-entity-list__table-header__total-found {
			float: left;
			margin-left: 16px;
			margin-top: 9px;
			letter-spacing: 0.5px;
			color: white;
			font-weight: 500;
			font-size: 15px;
		}
	}

	.ui-entity-list__column-header {
		#util > .clearfix();
		color: @grey-dk;
		font-weight: 500;
		cursor: move;
		padding: 0;

		.ui-column-header__column-title {
			float: left;
			line-height: 33px;
			#util > .excerpt-crop > .ellipsis();
			.ui-column-header__column-subtitle {
				color: @grey-lt;
				font-size: @tiny;
				font-weight: normal;
				margin-left: 6px;
			}
		}

		.ui-column-header__ui-btn {
			cursor: pointer;
			height: 33px;
			padding: 5px 7px;
			margin-top: 1px;

			//svg coloring
			svg {
				fill: @icon-primary-action;
			}
			&:hover svg {
				fill: @icon-primary-action-hovered;
			}

			//font-awesome coloring
			.react-icon {
				color: @icon-primary-action;
			}
			&:hover .react-icon {
				color: @icon-primary-action-hovered;
			}

			&.ui-entity-list__column-sort {
				float: right;
				display: none;

				//svg containers need to float
				.svg-arrows-desc,
				.svg-arrows-asc {
					float: right;
					margin-top: -7px;
				}

				&:hover {
					display: block;
				}
				&.ui-entity-list__column-sort--active {
					display: block;
					svg {
						width: 9px;
					}

					&.ui-entity-list__column-sort--ascending {
						svg:last-child {
							fill: @icon-primary-action-selected;
						}
					}

					&.ui-entity-list__column-sort--descending {
						svg:first-child {
							fill: @icon-primary-action-selected;
						}
					}
				}
			}

			&.ui-column-header__filter {
				float: left;

				.svg-filter {
					margin-top: -6px;
				}
			}
			&.ui-column-header__move-left {
				float: left;
				padding: 5px 7px 5px 10px;
			}
			&.ui-column-header__move-right {
				float: right;
				padding: 5px 10px 5px 7px;
			}
		}

		&.ui-entity-list__column-header--filtered {
			.ui-column-header__filter {
				opacity: 1;
				svg {
					fill: @icon-primary-action-selected;
				}
			}
		}

		&.ui-entity-list__column-header--sortable {
			cursor: pointer;
		}
		&:not(.ui-entity-list__column-header--filterable) {
			padding-left: 15px;
			&.ui-entity-list__column-header--name {
				padding-left: 0;
			}
		}
		&:not(.ui-entity-list__column-header--sortable) {
			padding-right: 15px;
		}
		&.ui-entity-list__column-header--dragging {
			opacity: 0.3;
		}

		.ui-column-header__drop-target-indicator {
			position: absolute;
			top: 0;
			height: 33px;
			width: 3px;

			&--left {
				left: 0;
			}

			&--right {
				right: 0;
			}
		}
	}

	.ui-entity-list__table-footer {
		background-color: white;
		box-sizing: border-box;
		text-align: right;
		padding: 5px 16px;
		height: 45px;
		position: relative;

		.ui-entity-list-table-footer__num-selected {
			float: right;
			font-size: 15px;
			letter-spacing: 0.5px;
			margin-right: 20px;
			margin-top: 7px;
		}

		.ui-entity-list-table-footer__notifies {
			#util > .clearfix();
			float: right;
		}
	}
}

.ui-entity-list__table-wrapper,
.ui-entity-list__map-wrapper {
	position: relative;

	.ui-entity-list__empty-state,
	.ui-entity-list__no-results,
	.ui-entity-list__loading-state {
		z-index: 10;
		position: absolute;
		text-align: center;
		left: 50%;
		top: 100px;
		width: 600px;
		margin-left: -300px;

		// Make sure the loading icons will center using text-align rule above.
		.react-icon {
			display: inline-block;
		}
	}

	.ui-entity-list__empty-state {
		top: auto;
		bottom: 80px;

		img {
			width: 100%;
		}
		p {
			margin: 0;
			position: absolute;
			left: 225px;
			top: 142px;
			font-size: 17px;
			font-weight: 500;
			color: @grey-lt;
		}
		.ui-entity-list__empty-state__action-btns {
			position: absolute;
			left: 220px;
			top: 190px;

			a:first-child {
				margin: 0 10px;
			}
		}

		&.ui-entity-list__empty-state--type-leads {
			p {
				left: 213px;
			} //longer text, need less padding
		}
	}

	.ui-entity-list__error-state {
		position: absolute;
		z-index: 100;
		text-align: center;
		left: 50%;
		top: 100px;
		width: 600px;
		margin-left: -300px;

		.ui-entity-list__error-state__title {
			margin: 20px 0px 0px;
			line-height: 23px;
			font-size: 21px;
			font-weight: 500;
			color: @grey;
		}

		.ui-entity-list__error-state__message {
			font-size: 18px;
			margin: 8px 0px 10px 0px;
		}

		.ui-entity-list__error-state__refresh-button {
			line-height: 21px;
		}

		.ui-entity-list__error-state__links {
			margin: 10px 0;
			a {
				color: @blue;
				&:hover {
					text-decoration: underline;
					color: @blue-dk;
				}
			}
		}
	}
}

.fixedDataTableLayout_main.public_fixedDataTable_main,
.fixedDataTableLayout_main.public_fixedDataTable_main:focus {
	outline: none;
}

.fixedDataTableLayout_main.public_fixedDataTable_main:focus {
	box-shadow: -1px -2px 2px rgba(0, 0, 0, 0.1);
}

.fixedDataTableLayout_rowsContainer {
	.public_fixedDataTableRow_main:not(.fixedDataTableLayout_header) {
		cursor: default;
		&.cursor-pointer {
			cursor: pointer;
		}
		&.public_fixedDataTableRow_main--selected {
			background-color: @color-list-selected;
			.public_fixedDataTableCell_main {
				background-color: @color-list-selected;
			}
		}
		&:hover,
		&.public_fixedDataTableRow_main--selected:hover {
			background-color: @color-list-hovered;
			.public_fixedDataTableCell_main {
				background-color: @color-list-hovered;
			}
		}
	}
}
