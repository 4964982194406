.phone-container {
    background-image: url(/include/images/mobile-phone-frame.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
    height: 90%;
    aspect-ratio: 356 / 636;
}

.screen-container {
    display: flex;
    position: relative;
    /* Make this div size match the boundaries of the svg phone screen */
    height: 78.5%;
    width: 93%;
    margin-left: 3%;
    margin-top: 17%;
    overflow: hidden;
}

.message-container {
    width: 75%;
    margin-left: 4%;
    margin-top: 4%;
    position: absolute;
}
