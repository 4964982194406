.loading-view {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 160px;
    text-align: center;
    margin-top: 40px;
}

.heading {
    color: var(--off-white-dk);
}

.icon {
    margin-top: 50px;
}
