.border--bottom {
    border-bottom: var(--border-hairline);
}

.border--top {
    border-top: var(--border-hairline);
}

/* Border has to be applied to the first TC (not row) or everything will be shifted off by 1px */
.border--left > :first-child {
    border-left: var(--border-hairline);
}

.sticky {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.filter-icon {
    padding-left: 8px;
    height: 13px;
}

.filter-icon:hover svg {
    fill: var(--blue);
}

.sort-icon {
    padding-left: 8px;
    height: 17px;
}

.resizer {
    display: inline-block;
    width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    /* prevents from scrolling while dragging on touch devices */
    touch-action: none;
}

.resizer:hover,
.resizer:focus {
    background: var(--blue);
}
.resizer.isResizing {
    background: var(--blue-bg);
}

.action-button-spacer-cell {
    composes: action-button-cell from '../styles.css';
}

.expand-spacer-cell {
    composes: expand-cell from '../styles.css';
}

.truncated {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.background--off-white {
    background-color: var(--off-white);
}

.background--off-white-dk {
    background-color: var(--off-white-dk);
}

.extend-to-fit-placeholder-cell {
    width: 100%;
    border-right: var(--border-hairline);
}
