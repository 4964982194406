.ease-out {
    transition: all 450ms var(--custom-easing) 0ms;
}

.transition-slide-in {
    position: relative;
    overflow: hidden;
    vertical-align: top;
    height: 100%;
}
.transition-slide-in-child {
    composes: ease-out;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.enter {
    opacity: 0;
}
.enter-active {
    opacity: 1;
}
.leave {
    opacity: 1;
}
.leave-active {
    opacity: 0;
}

.transition-slide-in--left {
    composes: transition-slide-in;
}
.transition-slide-in--left .enter {
    transform: translate3d(100%, 0, 0);
}
.transition-slide-in--left .enter-active {
    transform: translate3d(0, 0, 0);
}
.transition-slide-in--left .leave {
    transform: translate3d(0, 0, 0);
}
.transition-slide-in--left .leave-active {
    transform: translate3d(-100%, 0, 0);
}

.transition-slide-in--right {
    composes: transition-slide-in;
}
.transition-slide-in--right .enter {
    transform: translate3d(-100%, 0, 0);
}
.transition-slide-in--right .enter-active {
    transform: translate3d(0, 0, 0);
}
.transition-slide-in--right .leave {
    transform: translate3d(0, 0, 0);
}
.transition-slide-in--right .leave-active {
    transform: translate3d(100%, 0, 0);
}

.transition-slide-in--up {
    composes: transition-slide-in;
}
.transition-slide-in--up .enter {
    transform: translate3d(0, 100%, 0);
}
.transition-slide-in--up .enter-active {
    transform: translate3d(0, 0, 0);
}
.transition-slide-in--up .leave {
    transform: translate3d(0, 0, 0);
}
.transition-slide-in--up .leave-active {
    transform: translate3d(0, -100%, 0);
}

.transition-slide-in--down {
    composes: transition-slide-in;
}
.transition-slide-in--down .enter {
    transform: translate3d(0, -100%, 0);
}
.transition-slide-in--down .enter-active {
    transform: translate3d(0, 0, 0);
}
.transition-slide-in--down .leave {
    transform: translate3d(0, 0, 0);
}
.transition-slide-in--down .leave-active {
    transform: translate3d(0, 100%, 0);
}
