.art {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.art img {
    width: 90%;
}
