.body {
    padding: 0 16px;
    font-size: var(--font-normal);
}

.text {
    padding: 16px 0;
}

.button-container {
    text-align: center;
    padding-bottom: 16px;
}

.form-info {
    height: fit-content;
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.form-info > *:last-child {
    margin-left: 4px;
}

.pad-bottom {
    padding-bottom: 16px;
}

.see-all {
    padding-bottom: 16px;
    text-align: right;
}
