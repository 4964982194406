.form {
    width: 600px;
    margin-bottom: 96px;
}

.title {
    margin-bottom: 24px;
}

.title > *:first-child {
    margin-bottom: 4px;
}

.title > *:last-child {
    color: var(--gray-lt);
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
