.setup-page {
    padding: 16px;
}

:global(.account-settings) .setup-page,
:global(.account-phone) .setup-page,
:global(.account-calendar) .setup-page,
:global(.account-tasks) .setup-page,
:global(.account-notifications) .setup-page,
:global(.account-email) .setup-page,
:global(.account-integrations) .setup-page,
:global(.account-email-settings) .setup-page {
    padding: 0;
}
