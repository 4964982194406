.container {
    padding-bottom: 16px;
}

.header-row,
.row {
    border-bottom: 1px solid var(--form-border);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.header-row {
    padding-bottom: 16px;
}

.row {
    padding: 24px 0;
}

.title {
    display: flex;
    align-items: center;
    color: var(--grey-dk);
}

.text {
    font-size: var(--font-normal);
    font-weight: 500;
}

.cell {
    width: 130px; /* needed to fit the fixed width of the table for 6 cells */
    display: flex;
    justify-content: center;
    align-items: center;
}

.green {
    padding: 4px 8px;
    background-color: var(--green-lt);
}

.orange {
    padding: 4px 8px;
    background-color: var(--orange-lt);
}
