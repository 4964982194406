.condition-custom-field {
    position: relative;
    margin-bottom: 16px;
}

.default-custom-field {
    color: var(--grey-dk);
}

.default-custom-field:hover {
    text-decoration: underline;
}
