.body {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    justify-content: space-between;
}

.lead-container {
    width: calc(50% - 16px);
    cursor: pointer;
    margin-top: 16px;
}

.merge-confirm {
    align-self: flex-end;
}
