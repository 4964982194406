/* Copied from shells border.css */
.border {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

.border:not(:disabled):hover {
    border-color: var(--grey-lt);
}

.border:not(:disabled):focus,
.border:not(:disabled):active {
    border-color: var(--blue);
}

.date-field-button {
    composes: border;
    color: var(--grey);
    font-size: var(--font-small);
    padding: 4px 8px;
    height: 34px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: color 200ms linear;
    text-align: left;
}

.success {
    color: var(--green);
}

.placeholder {
    color: var(--placeholder);
}
