.container {
    position: relative;
    display: inline-block;
    user-select: none;
    cursor: pointer;
    margin-right: 10px;
}

.container :global(.ui-funnel-report__filter-button__title) {
    margin-right: 5px;
}

.button-body {
    display: flex;
    align-items: center;
}

.chevron {
    margin-left: 8px;
    line-height: 13px;
    margin-bottom: 2px;
}
