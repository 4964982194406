.popover {
    /* Account for the 2px border this popover that is positioned under. */
    margin-top: -2px;
    animation: animate-enter-date-picker 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards;
}

@keyframes animate-enter-date-picker {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
