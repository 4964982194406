.form-table-container {
    display: flex;
    flex-grow: 1;
    /* Allow vertical scrolling, but not horizontal */
    overflow-y: auto;
    overflow-x: hidden;
}

.form-table-container > * {
    width: 100%;
}

.content {
    font-size: var(--font-normal);
    line-height: 21px;
}
