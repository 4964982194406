.border {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

.border:not(:disabled):hover {
    border-color: var(--grey-lt);
}

.border:not(:disabled):focus,
.border:not(:disabled):active {
    border-color: var(--blue);
}

.border--error,
.border--error:not(:disabled):focus,
.border--error:not(:disabled):active {
    border-color: var(--rose);
}
