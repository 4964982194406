.no-access-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 600px;
    text-align: center;
    padding: 0 16px;
}
