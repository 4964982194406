.numbered-list {
    margin-bottom: 16px;
}

.numbered-list--normal {
    composes: numbered-list;
    font-size: var(--font-normal);
}

.numbered-list--big {
    composes: numbered-list;
    font-size: var(--font-big);
}

.numbered-list li {
    position: relative;
    display: block;
    padding: 0 6px 0 12px;
    margin: 0 0 16px 4px; /* This aligns the bullets with the left-edge */
    color: var(--grey);
    line-height: 1.4;
}

.numbered-list li:last-child {
    margin-bottom: 0;
}
