.add-new-card {
    padding: 24px 16px;
    width: 235px;
}

.add-new-popover-container {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

@media (max-width: 1150px) {
    .add-new-popover-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .add-new-card {
        width: 200px;
    }
}

@media (max-width: 630px) {
    .add-new-popover-container {
        display: none;
    }
}
