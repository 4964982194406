.slideshow-container {
	&.activity-calendar {
		margin: 0 0 -10px 0;

		.top-section { padding: 20px 10px 0 10px; }
		h1, h2 {
			color: @grey;
			width: 330px;
			margin: 0 auto;
		}

		h2,
		h3 {
			color: @grey-lt;
		}

		h3 {
			font-weight: normal;
			font-size: 15px;
		}

		.ui-btn {
			min-width: 150px;
			&:not(.ui-btn--block) { margin: 0 10px; }
		}

		.slide-two, .slide-three {
			.top-section {
				margin-bottom: 30px;
				border-bottom: 1px solid @off-white-dk;
			}
		}

		.slide-one .top-section { padding-top: 30px; }
		.slide-two .top-section { margin-bottom: 15px; }
		.slide-three .top-section {
			margin-bottom: 25px;
			padding-bottom: 15px;
		}

		.calendar-icon {
			margin: 30px auto;
			width: 126px;
			height: 126px;
			background-size: 100% !important;
			.2x('@{img-root}/calendar/icon-activities-color.png', "no-repeat");
		}
		.calendar-screenshot {
			margin-top: 10px;
			.2x('@{img-root}/calendar/02.png', "no-repeat");
			width: 100%;
			height: 268px;
			background-size: 100% !important;
		}
		.activity-glance {
			margin: 10px;
			.2x('@{img-root}/calendar/03.png', "no-repeat");
			width: 100%;
			height: 225px;
			background-size: 100% !important;
		}
	}
}
