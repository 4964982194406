.body {
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
}

.offer-accepted-message {
    composes: body;
    color: var(--grey-lt);
}

.container {
    margin-top: 32px;
}
