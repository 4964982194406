.container--horizontal {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid var(--off-white-dk);
}

.container--vertical {
    width: 200px;
    height: 100%;
    border-right: 1px solid var(--off-white-dk);
}
