.container {
    background-color: var(--off-white);
    height: 100%;
    width: 100%;
    padding: 4px 8px;
    box-sizing: border-box;
}

/* LeadCard margin */
.container > div {
    margin-bottom: 4px;
}

.loading-overlay {
    position: absolute;
    display: flex;
    box-sizing: border-box;
    padding-top: 16px;
    justify-content: center;
    fill: var(--off-white-dk);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.overlay-background {
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    opacity: 0.6;
}
