.header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    flex-shrink: 0;
    width: 100%;
    border-bottom: solid 1px var(--off-white-md);
    cursor: move;
}

.header:hover {
    background-color: var(--off-white);
}

.left-side {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    width: calc(100% - 80px); /* Allow 80px for config button and refreshing spinner */
}

.left-side-wide {
    composes: left-side;
    width: 100%;
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 8px 0;
    min-width: 0; /* this is necessary for container ellipsis overflow behavior */
}

.dash-title {
    font-size: var(--font-normal);
    color: var(--grey-dk);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dash-title--errored {
    composes: dash-title;
}

.dash-subheader {
    font-size: var(--font-small);
    font-weight: normal;
    margin: 0;
    color: var(--grey);
    user-select: none;
}

.button-container {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.refreshing {
    margin-right: 15px;
}

.refreshing :global(.svg-icon-container) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.refreshing svg path,
.refreshing svg fill {
    fill: var(--orange);
}

.button {
    font-weight: 500;
    color: var(--icon-secondary);
}

.button:hover,
.button:focus {
    color: var(--icon-secondary-dk);
}

.config-button-container {
    width: 100%;
    height: 100%;
    padding: 12px 8px;
    box-sizing: border-box;
}

.button.button-config {
    font-size: 24px;
}

.button.button-close {
    line-height: 0;
}

.button.button-close svg {
    width: 12px;
    height: 12px;
}
