.subtitle {
    composes: second-line from '../sales-automation-card.css';
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 4px;
}

.row {
    composes: subtitle;
    color: var(--grey-lt);
    width: 100%;
}

.row > span {
    width: calc(100% - 24px);
}

.line-icon {
    min-width: 24px;
}

.line-icon > svg {
    color: var(--grey);
}
