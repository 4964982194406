.modal-scroll-container {
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
}

.button-container {
    display: flex;
}

.button-container button:first-child {
    margin-right: 16px;
}
