.button-icon {
    display: inline-block;
}

.button-icon > :first-child {
    padding-right: 8px;
    height: 17px;
    margin-bottom: -4px; /* smh inline-block i have no idea */
}

.button-icon > * {
    display: inline-block;
}
