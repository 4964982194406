.container {
    display: inline-block;
    position: relative;
    z-index: 10;
}

.container:before,
.container .initials,
.container img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.container img {
    z-index: 10;
}

.status-indicator {
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    border: 2px solid white;
}

.online {
    background-color: var(--success);
}

.offline {
    background-color: var(--grey-lt);
}

.initials {
    background-color: #fff;
    font-weight: 500;
    color: var(--sidebar-primary);
    cursor: default; /* no text bar */
    text-align: center;
    text-transform: uppercase;
}

.container-active {
    composes: container;
}

.container-active .initials {
    background-color: var(--sidebar-primary) !important;
    color: #fff !important;
}

.has-border {
    border: 2px solid var(--focus-blue);
    margin: -2px;
}
