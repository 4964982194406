.container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: var(--grey);
}

.container a {
    color: var(--grey);
}
