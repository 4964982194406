.stages-carousel-empty {
    position: relative;
    overflow: hidden;
    user-select: none;
    padding: 0 16px;
    margin: 0 -16px;
}

.stages-carousel-empty button {
    padding: 0 !important;
}

/* Stolen from LESS' big-empty-placeholder */
.big-empty-placeholder {
    display: block;
    position: relative;
    margin: 15px 10px 15px 0;
    padding-left: 61px;
}
