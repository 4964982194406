.timeline3-ribbon {
	margin-left: 35px;
	width: 90%;
	min-width: 270px;
	font-size: @small;
	line-height: 22px;

	&:first-child {
		margin-top: @timeline3-distance;

		.line {
			top: @timeline3-distance * -1;
			height: (@timeline3-distance * 2) + 23;
		}
	}

	&.type-instance {
		width: 90%;
		margin-left: 16px;
		margin-top: 16px;
		.line {
			height: 16px;
			margin-left: 14px;
			top: -16px;
		}
		.body {
			&:before, &:after { display: none; }
			background-color: @off-white;
			border: 0;
		}
		.content {
			background-color: white;
			border: 1px solid @blue;
			border-radius: 3px;
			padding-left: 8px;
			&:before, &:after { display: none; }

			a {
				&.type-users { color: @orange; }
				&.link-settings {
					color: @blue;
					&:hover { color: @blue-dk; }
				}
			}
		}
	}

	.line { left: 0 !important; } // since we're margin'd, the line needs to slide back over
	.line.dashboard-top {
		left: 14px;
		top: -17px;
		height: 15px;
	}

	.body {
		position: relative;
		background-color: @timeline-ribbon-background-color;
		border-top: 1px solid @timeline-ribbon-border-color;
		border-bottom: 1px solid @timeline-ribbon-border-color;
		z-index: 1; /* Keep on top of timeline line */
		padding: 0 2px;

		&:before,
		&:after {
			position: absolute;
			top: -1px;
			content: '';
			.sprite-helper('timeline3/ribbon-sprite.png?v=4', 15px, 108px);
		}
		&:before {
			width: 8px;
			left: -8px;
			height: 27px;
		}
		&:after {
			width: 7px;
			right: -7px;
			height: 24px;
			background-position: right top;
		}

		.content {
			width: calc(~'100% - 120px');
			#util > .excerpt-crop > .ellipsis;
		}

		.timestamp {
			position: absolute;
			top: 0;
			right: 10px;
			font-size: @small;
			color: @grey-lt;
			&:hover {
				cursor: default;
				text-decoration: none;
			}
		}
	}

	&.status-won {
		.body {
			background-color: @timeline-ribbon-lead-won-background;
			border-top: 1px solid @timeline-ribbon-lead-won-border;
			border-bottom: 1px solid @timeline-ribbon-lead-won-border;
			&:before { background-position: left -27px; }
			&:after { background-position: right -27px; }
		}
	}
	&.status-lost,
	&.status-cancelled {
		.body {
			background-color: @timeline-ribbon-lead-lost-background;
			border-top: 1px solid @timeline-ribbon-lead-lost-border; // @rose
			border-bottom: 1px solid @timeline-ribbon-lead-lost-border; // @rose
			&:before { background-position: left -54px; }
			&:after { background-position: right -54px; }
		}
	}
	&.type-origin {
		.body {
			background-color: @timeline-ribbon-lead-origin-background;
			border-top: 1px solid @timeline-ribbon-lead-origin-border; // @blue
			border-bottom: 1px solid @timeline-ribbon-lead-origin-border; // @blue
			&:before { background-position: left -81px; }
			&:after { background-position: right -81px; }
		}
	}
	&.type-mailchimp {
		.content {
			padding-left: 18px; // Allows for MailChimp icon below
		}
		.icon {
		  background-color: @timeline-ribbon-icon; // @peach
			.2x('@{img-root}/timeline3/icon-mailchimp-ribbon.png', 'no-repeat', 22px, 22px);
			background-position: 50% 50%;
		  width: 22px;
		  height: 22px;
		  position: absolute;
		  top: 0;
		  left: -7px;
		}
	}
	&.type-constant-contact {
		.content {
			padding-left: 18px; // Allows for MailChimp icon below
		}
		.icon {
		  background-color: @timeline-ribbon-icon;
			.2x('@{img-root}/timeline3/constant-contact.png', 'no-repeat', 22px, 22px);
			background-position: 50% 50%;
		  width: 22px;
		  height: 22px;
		  position: absolute;
		  top: 0;
		  left: -7px;
		}
	}
	&.type-marketing-email {
		.content {
			padding-left: 18px;
		}
		.icon {
			background-color: @timeline-ribbon-icon;
			  .2x('@{img-root}/timeline3/icon-outreach-email.png', 'no-repeat', 16px, 16px);
			  background-position: 50% 50%;
			// padding: 2px;
			width: 22px;
			height: 22px;
			position: absolute;
			top: 0;
			left: -7px;
		}
	}
	&.type-email-interaction {
		.content {
			padding-left: 18px;
		}
		.icon-opened {
			.2x('@{img-root}/timeline3/icon-email-opened.png', 'no-repeat', 16px, 16px);
		}
		.icon-clicked {
			.2x('@{img-root}/timeline3/icon-email-link-clicked.png', 'no-repeat', 18px, 18px);
		}
		.icon {
			background-color: @timeline-ribbon-icon;
			background-position: 50% 50%;
			// padding: 2px;
			width: 22px;
			height: 22px;
			position: absolute;
			top: 0;
			left: -7px;
		}
	}
	&.type-assignment {
		.content {
			padding-left: 18px; // Allows for Assignment icon below
		}
		.icon {
		  background-color: @timeline-ribbon-icon;
			.2x('@{img-root}/timeline3/icon-assignments-ribbon.png', 'no-repeat', 16px, 12px);
			background-position: 50% 50%;
		  width: 22px;
		  height: 22px;
		  position: absolute;
		  top: -1px;
		  left: -7px;
		}
	}
	&.type-submission {
		.content {
			padding-left: 18px; // Allows for Submission icon below
		}
		.icon {
		  background-color: @timeline-ribbon-icon;
			.2x('@{img-root}/timeline3/icon-submission-ribbon.png', 'no-repeat', 16px, 16px);
			background-position: 50% 50%;
		  width: 22px;
		  height: 22px;
		  position: absolute;
		  top: 0;
		  left: -7px;
		}
	}
	&.type-lead.status-stagechange {
		.content {
			padding-left: 18px; // Allows for stage icon below
		}
		.icon {
			.2x('@{img-root}/timeline3/icon-stage-ribbon.png?1', 'no-repeat', 22px, 22px);
			background-position: 50% 50%;
			width: 22px;
			height: 22px;
			position: absolute;
			top: 0;
			left: -5px;
		}
	}
	&.type-account.status-account-type-changed {
		.content {
			padding-left: 18px; // Allows for icon below
		}
		.icon {
			.2x('@{img-root}/timeline3/icon-account-type-ribbon.png?1', 'no-repeat', 22px, 22px);
			background-position: 50% 50%;
			width: 22px;
			height: 22px;
			position: absolute;
			top: 0;
			left: -5px;
		}
	}
	&.type-lead.status-lead-priority-hot {
		.content {
			padding-left: 18px; // Allows for icon below
		}
		.icon {
			.2x('@{img-root}/timeline3/icon-lead-priority-hot-ribbon.png?1', 'no-repeat', 22px, 22px);
			background-position: 50% 50%;
			width: 22px;
			height: 22px;
			position: absolute;
			top: 0;
			left: -5px;
		}
	}
	&.type-lead.status-lead-priority-not-hot {
		.content {
			padding-left: 18px; // Allows for icon below
		}
		.icon {
			.2x('@{img-root}/timeline3/icon-lead-priority-not-hot-ribbon.png?1', 'no-repeat', 22px, 22px);
			background-position: 50% 50%;
			width: 22px;
			height: 22px;
			position: absolute;
			top: 0;
			left: -5px;
		}
	}
	&.type-followup {
		.body {
			border-top: 1px solid @timeline-ribbon-follow-up-border;
			border-bottom: 1px solid @timeline-ribbon-follow-up-border;
			&:before { background-position: left -27px; }
			&:after { background-position: right -27px; }
		}
		.content { padding-left: 18px; }
		.icon {
		  background-color: @green-lt;
		  .2x('@{img-root}/timeline3/icon-followup.png', 'no-repeat');
		  background-position: 50% 50%;
		  background-size: 16px 16px !important;
		  width: 22px;
		  height: 22px;
		  position: absolute;
		  top: 0;
		  left: -7px;
		}
	}
	&.type-quickbooks {
		.content {
			padding-left: 18px; // Allows for Quickbooks icon below
		}
		.icon {
			background-color: @timeline-ribbon-icon;
							.2x('@{img-root}/timeline3/quickbooks.png', 'no-repeat', 22px, 22px);
			background-position: 50% 50%;
			width: 22px;
			height: 22px;
			position: absolute;
			top: 0;
			left: -7px;
		}
	}

	&.type-preview {
		.preview {
			display: block;
			background-color: @off-white;
			border-left: 2px solid @timeline3-bordercolor;
			border-bottom: 1px solid @timeline3-bordercolor;
			border-right: 1px solid @timeline3-bordercolor;
			#util > .excerpt-crop > .ellipsis;

			//5 pixels for text padding, 2 for border
			padding-left: 7px;
			padding-right: 57px;

			&:hover {
				text-decoration: none;
				&.type-notes { background-color: @timeline-ribbon-preview-note-background-color; }
			}
		}
	}

	&.variant-clickable { cursor: pointer; }

	span.mention-rendered a {
		color: @grey;
		&:hover { text-decoration: none; }
	}

	span.preview-deleted {
		text-decoration: line-through;
	}

	span.preview-processing {
		color: @grey;
		text-decoration: none;
	}
}
