/* Export this value for use in JS. */
@value animateInvalidDuration: 800ms;

.popover {
    border-radius: 3px !important; /* Yeah, that's right, immutable styles. */
    box-shadow:
        0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.12),
        0 2px 26px rgba(0, 0, 0, 0.12) !important;
}

.animate-in {
    animation: animate-in-picker 400ms var(--custom-easing);
}

.invalid-shake {
    animation-name: animate-invalid-shake;
    animation-timing-function: var(--custom-easing);
    animation-duration: animateInvalidDuration;
    animation-fill-mode: both;
}

.textfield {
    width: 100%;
    height: 50px;
    display: block;
    border: 0;
    box-sizing: border-box;
    outline: none;
    font-size: 20px;
    color: var(--grey-dk);
    padding: 0 14px;
}

.textfield--invalid {
    composes: textfield;
    color: var(--rose);
}

.textfield--invalid::-moz-selection {
    background-color: var(--off-white-md);
    color: var(--rose);
}
.textfield--invalid::selection {
    background-color: var(--off-white-md);
    color: var(--rose);
}

.actions-container {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 1;

    display: flex;
    align-items: center;

    transform-origin: left center;
    transform: translateX(25px);
    opacity: 0;
    animation: animate-in-action-buttons 200ms var(--custom-easing-bounce) 200ms forwards;
}

.button-confirm {
    margin-right: 4px;
    padding: 0;
    fill: var(--green);
    transition: opacity 200ms var(--custom-easing);
}

.button-confirm:hover,
.button-confirm:focus {
    fill: var(--green-dk);
}

.button-confirm:disabled {
    opacity: 0.35;
}

.button-confirm svg {
    pointer-events: none;
}

.button-cancel {
    padding: 0;
    /* This is a FA icon. Size is maintained by font-size for now :sponge:. */
    font-size: 24px;
    color: var(--grey-lt);
}

.button-cancel:hover,
.button-cancel:focus {
    color: var(--grey);
}

.button-confirm:focus:not(:hover),
.button-cancel:focus:not(:hover) {
    transform-origin: center;
    transform: scale(1.15);
}

@keyframes animate-in-picker {
    0% {
        opacity: 0;
    }
}

@keyframes animate-in-action-buttons {
    0% {
        transform: translateX(25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes animate-invalid-shake {
    10%,
    90% {
        transform: translateX(-1px);
    }
    20%,
    80% {
        transform: translateX(2px);
    }
    30%,
    50%,
    70% {
        transform: translateX(-4px);
    }
    40%,
    60% {
        transform: translateX(4px);
    }
}
