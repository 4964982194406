.scrollable-container-with-shadow {
    position: relative;
}

.scrollable-container {
    overflow: auto;
}

.gradient-shadow {
    content: '';
    position: absolute;
    z-index: 1;
}
