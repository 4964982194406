@value smallIconSizeOffset: 2px;
@value lastEntrySmallLineHeightOffset: 11px;
@value lastEntryLineHeightOffset: 15px;
@value lastEntrySmallLeftPaddingOffset: 14px;
@value lastEntryLeftPaddingOffset: 13px;

.timeline-icon {
    position: relative;
    min-width: 44px;
    width: 44px;
    height: 44px;
}

.timeline-icon--small {
    composes: timeline-icon;
    min-width: 40px;
    width: 40px;
    height: 40px;
}

/* Background color is defined in component as inline style */
.icon-container {
    width: 100%;
    height: 100%;
    border: 3px solid var(--off-white);
    border-radius: 50%;
    box-sizing: border-box;
}

/* Styles for the icon */
.icon-container > :first-child {
    /* Positioning of the icon */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Aligns and centers icon within size constraints */
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size needed for font-awesome icons */
    font-size: 19px;
}

.icon-container--clickable {
    composes: icon-container;
}

.icon-container--clickable:hover {
    cursor: pointer;
}

/* Background color is defined in component as inline style */
.sub-icon-container {
    composes: icon-container;
    width: 24px;
    height: 24px;
    /* Positioning of the sub-icon */
    position: absolute;
    bottom: -8px;
    left: 28px;
}

.sub-icon-container--small {
    composes: sub-icon-container;
    width: 20px;
    height: 20px;
    bottom: -6px;
    left: 26px;
}

/* Optional gutter line */
.gutter-line {
    border-left: 2px solid var(--button-secondary-dk);
    position: relative;
    left: 21px;
    z-index: 0;
}

.gutter-line--small {
    composes: gutter-line;
    left: 20px;
}

.end-circle {
    width: 17px;
    height: 17px;
    background-color: var(--button-secondary-dk);
    border-radius: 17px;
}

.end-circle--small {
    composes: end-circle;
    width: 13px;
    height: 13px;
}
