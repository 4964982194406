.header-container {
    width: 100%;
    margin-top: 2px;
}

.header-container--small {
    composes: header-container;
}

/* Timestamp */
.header-container--small > :first-child * {
    font-size: var(--font-tiny);
}

/* Action text */
.header-container--small > :last-child * {
    font-size: var(--font-small);
}

.action-text {
    font-size: var(--font-normal);
}

.actor:hover {
    cursor: default;
}

.actor,
.verb {
    min-width: fit-content;
}

.actor,
.participants,
.target {
    white-space: normal;
    font-weight: 500;
}

.participants {
    position: relative;
}

.pinned {
    padding-left: 4px;
}
