.onboarding-container {
    width: 400px;
    padding: 16px;
    display: flex;
    height: 230px;
}

.onboarding-body {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.container {
    width: 200px;
    display: flex;
    padding: 8px;
}

.textfield-container {
    margin-right: 4px;
    z-index: 10;
    display: flex;
}

.blur-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.placeholder-popover-container {
    background-color: white;
    position: absolute;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 var(--grey-lt);
    z-index: 1000000;
}
