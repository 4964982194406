.title {
    padding: 6px 8px;
    border-radius: 3px;
    border: 1px solid var(--off-white-dk);
    margin-bottom: 8px;
}

.calendar-icon {
    margin-bottom: -1px;
}

.popover-container {
    padding: 24px;
}

.button-container {
    display: flex;
    justify-content: center;
}
