.ui-input {
	display: inline-block;
	box-sizing: border-box;
	outline: none;
	vertical-align: middle;
	padding: 3px 10px 4px 10px;
	border: 2px solid @off-white-md;
	border-radius: 3px;
	background-color: white;
	color: @grey;

	&:focus,
	&.ui-input--active {
		color: @grey-dk;
	}

	&.ui-input--invalid {
		border-color: @rose;
		box-shadow: none;
	}

	&[type='radio'],
	&[type='checkbox'] {
		vertical-align: baseline;
	}

	&[type='radio']& + label,
	&[type='checkbox']& + label {
		margin: 0 8px 0 5px;
		line-height: 31px;
		font-weight: 500;
	}

	&[type='number'] {
		padding-right: 5px; //pushes the number-select arrows farther right
		-moz-appearance: textfield;
		&:hover,
		&:focus {
			-moz-appearance: number-input;
		}
	}

	&:focus:not(.ui-input--no-focus),
	&.ui-input--active:not(.ui-input--no-focus) {
		outline: none;
		background: white;
		border-color: @grey-lt;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 0 2px darken(@peach-lt, 10%);

		&.ui-input--invalid {
			border-color: @rose;
			box-shadow: none;
		}
	}

	&.ui-input--full-width {
		width: 100%;
	}
}

.ui-input-checkbox-label {
	cursor: pointer;
	.ui-input {
		margin-right: 8px;
	}
}

//not a modifier of ui-input, wrapper around it
.ui-input-date-range {
	.ui-input-date-picker {
		margin-bottom: 5px;
	}
}

.ui-location-radius-search {
	#util > .clearfix();
	margin-bottom: 20px;

	.ui-location-search {
		margin-bottom: 10px;
	}
	.ui-location-radius-search__radius {
		float: right;
	}
	.ui-location-radius-search__label {
		margin-right: 8px;
		font-weight: 500;
	}
}

.ui-input-search-box {
	position: relative;
	padding-left: 20px;
	border: 2px solid @off-white-dk;
	border-radius: 4px;
	box-sizing: border-box;
}
