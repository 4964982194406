.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin: 12px 8px;
}

.container:first-child {
    margin-left: 16px;
}

.container:last-child {
    margin-right: 16px;
}

.container-underlined {
    composes: container;
    flex-direction: column;
    padding-bottom: 4px;
    border-bottom: 2px solid var(--off-white-dk);
}

.loading-lines-container svg path,
.loading-lines-container svg rect {
    fill: var(--grey-lt);
}

.container:not(.container-underlined) .value {
    margin-right: 6px;
}

.container:not(.container-underlined) .label {
    line-height: 18px;
}

.value {
    font-weight: 500;
    font-size: var(--font-normal);
    color: var(--grey-dk);
    height: 17px;
}

.label {
    font-size: var(--font-small);
    color: var(--grey-lt);
}

@media (min-width: 1000px) {
    .container-underlined {
        padding-bottom: 6px;
    }
    .value {
        font-size: var(--font-big);
    }
    .label {
        font-size: var(--font-normal);
        line-height: 23px;
    }
}

@media (min-width: 1400px) {
    .container-underlined {
        flex-direction: row;
        line-height: 21px;
        padding-bottom: 8px;
    }
    .value {
        font-size: 20px;
        margin-right: 8px;
    }
    .label {
        font-size: var(--font-normal);
    }
}
