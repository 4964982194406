.file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.file-text {
    margin-top: 16px;
    color: var(--grey-lt);
    font-size: var(--font-normal);
}

.selected-file {
    composes: file-text;
    color: var(--grey-dk);
    display: flex;
    align-items: center;
    gap: 8px;
}
