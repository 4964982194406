.drip-sequence-thumbnail {
    display: flex;
    width: 100%;
}

/* Add styles to button card */
.drip-sequence-thumbnail > :first-child {
    padding: 8px;
    width: 100%;
}

.drip-sequence-thumbnail:hover {
    cursor: pointer;
}

.thumbnail-header {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 16px 16px 8px 16px;
    /* Needs a line height so long letters aren't clipped */
    line-height: 21px;
}

.thumbnail-header > *:not(:last-child) {
    margin-right: 16px;
}

.thumbnail-header > :first-child {
    border-right: 1px solid var(--off-white-dk);
    padding-right: 16px;
    font-size: var(--font-big);
    max-width: 250px;
}

.preview-container {
    display: flex;
    position: relative;
}

.preview-container::after {
    content: '';
    position: absolute;
    right: -8px;
    top: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.001), #fff);
}
