.membership-container {
    display: flex;
    background-color: var(--white);
}

.engagement-container {
    display: flex;
    background-color: var(--white);
    width: 100%;
}

.engagement-graph-container {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    flex-grow: 1;
    height: 340px;
}

:global(#audience-content-scroll-container) {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 11;
}

.app-messages-container {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 11;
}
