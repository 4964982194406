.header {
    z-index: 2; /* make sure this stays in front of the list of fields */
    box-sizing: border-box;
}

.top {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: var(--font-big);
    color: var(--grey-dk);
    font-weight: 500;
    margin: 0;
}

.title--create-new {
    composes: title;
    margin-right: 8px;
}

.top button {
    padding: 0;
}

.arrow {
    padding: 0 8px;
    font-weight: 500;
}
