.image {
    max-width: 100%;
    object-fit: contain;
}

.primary-text {
    height: 56px; /* Set height to ensure all cards look consistent */
    font-size: var(--font-normal);
}

.integration-card-wrapper {
    max-width: 400px;
    min-width: 200px;
}
