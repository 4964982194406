.avatar {
	position: relative;
	z-index: 10;
	background-color: @grey;

	.initials {
		cursor: default; // no text bar
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		color: white;
	}

	img {
		width: 80px;
		height: 80px;
		border-radius: 40px;
		overflow: hidden;
		position: absolute;
		top: 0;
	}

	&.type-users {
		.initials {
			background-color: @orange-dk;
		}
	}

	&.type-contacts {
		.initials {
			background-color: @navy-dk;
		}
	}

	&.type-accounts {
		.initials {
			background-color: @plum-dk;
		}
	}

	&.type-mcfxCompanies {
		.initials {
			background-color: @plum-dk;
		}
	}
}

.avatar-container {
	.avatar {
		background-color: @orange-dk;
		border-radius: 100px;
	}
}

// Used when uploading an avatar
// Currently used on peep, my account and users pages
.avatar-container.action-file-pick {
	.avatar {
		width: 80px;
		height: 80px;
		cursor: pointer;
		border-radius: 44px;
		border: 4px solid white;

		.overlay {
			display: none;
			position: relative;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.65);
			border-radius: 80px;

			.remove {
				cursor: pointer;
				font-size: @big;
				line-height: 23px;
				text-align: center;
				position: absolute;
				top: -8px;
				right: -8px;
				width: 24px;
				height: 24px;
				border-radius: 30px;
				color: white;
				background: rgba(250, 74, 98, 0.9);
				border: 2px solid white;
				&:hover {
					background: @rose;
				}
			}

			.fa-cloud-upload {
				font-size: 45px;
				color: rgba(255, 255, 255, 0.89);
				position: absolute;
				left: 50%;
				margin-left: -24px;
				top: 50%;
				margin-top: -22px;
			}

			&.success .message {
				background-color: rgba(31, 183, 144, 0.9);
			}

			.message {
				font-size: 50px;
				color: rgba(255, 255, 255, 0.89);
				text-align: center;
				line-height: 75px;
				top: 0;
				width: 100%;
				height: 100%;
				border-radius: 80px;
			}
		}

		&.success {
			.success.overlay {
				display: block;
			}

			&:hover .success.overlay {
				display: none;
			}
		}

		&:hover .overlay {
			&.upload {
				display: block;
				z-index: 10;
			}
		}
	}

	.child-overlay {
		img {
			position: absolute;
			top: -35px;
			left: 23px;
			z-index: 10;
		}
	}

	.child-count-overlay {
		position: absolute;
		top: 22px;
		left: 72px;
		z-index: 10;
		height: 32px;
		width: 32px;
		font-size: 16px;
		box-sizing: border-box;
		border-radius: 100%;
		background: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--company);
		color: var(--company);
	}

	.max-child-count {
		font-size: 13px;
	}

}
