.buttons {
    float: right;
}

.buttons button:not(:last-child) {
    margin-right: 4px;
}

/* This re-creates our Button shell, which we can't use due to wonky popover */
.mailchimp-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 60px;
    border-radius: 3px;
    text-align: center;
    outline: none;
    user-select: none;
    /* variant='secondary' */
    color: var(--grey);
    background-color: var(--off-white-md);
    /* size='big' */
    padding: 7px 12px;
    font-size: var(--font-small);
}

.mailchimp-button:focus:not([disabled]),
.mailchimp-button:hover:not([disabled]) {
    color: var(--grey-dk);
    background-color: var(--off-white-dk);
}

.mailchimp-button[disabled] {
    opacity: 0.6;
    cursor: default;
}
