@value buttonContainerHeight: 63px;

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: buttonContainerHeight;
    padding: 16px;
    box-sizing: border-box;
}

.message {
    padding: 8px;
    z-index: 10;
}

.alert {
    composes: message;
    background-color: var(--rose-xlt);
    color: var(--rose);
    border-top: 1px solid var(--rose-lt);
}

.status {
    composes: message;
    background-color: var(--blue-xlt);
    color: var(--blue);
    border-top: 1px solid var(--blue-lt);
    border-bottom: 1px solid var(--blue-lt);
}
