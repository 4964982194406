.create-newsletter {
    width: 100%;
    min-height: 276px; /* TODO, standardize */
    padding-top: 32px;
    box-sizing: border-box;
}

.empty-state-container {
    overflow: hidden;
}

.empty-state-box {
    background-color: var(--off-white);
    width: 100%;
    height: 100%;
    border-radius: 3px;
}
