.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: white;
}

.subtitle-container,
.subtitle-container--small {
    display: flex;
}

.subtitle-container button {
    font-size: var(--font-big);
}

.subtitle-container--small button {
    font-size: var(--font-normal);
}
