.sort-and-customize-button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--off-white-dk);
    background-color: white;
    font-size: var(--font-normal);
    color: var(--grey-dk);
    border-radius: 0 3px 0 0;
    white-space: nowrap;
    height: 40px;
    padding: 0 16px;
}

.sort-and-customize-button:hover {
    background-color: var(--off-white);
}

.sort-and-customize-button svg {
    fill: var(--grey-dk);
    margin-right: 8px;
}
