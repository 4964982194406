.reply-banner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    line-height: 40px;
    flex-shrink: 0;
    margin-bottom: 4px;
    background-color: var(--off-white);
}

.reply-banner svg {
    margin: 0 16px;
}
