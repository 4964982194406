@value columnWidth from '../card-containers/cards-container.css';
@value columnGutter from '../card-containers/cards-container.css';

.icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
}

.headers-container {
    display: flex;
    font-size: var(--font-big);
}

.outcome-header-body {
    display: flex;
    flex-grow: 1;
    padding-right: 16px;
    align-items: center;
}

.outcome-header-name {
    line-height: 20px; /* even number helps center text */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;

    /* need room for growing count & total */
    max-width: 160px;
}

.outcome-header {
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;
    color: var(--grey-dk);
    border: 1px solid var(--off-white-dk);
    height: 64px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: columnWidth;
    margin-right: columnGutter;
}

.shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    height: 1px;
    width: columnWidth;
}

.outcome-header:first-child {
    width: calc(columnWidth + 4px);
}
