.scroll-shadow-container {
    width: calc(100% + 64px);
    height: 1px;

    position: absolute;
    left: -32px;
    z-index: 0;

    opacity: 0;
    transition: opacity 400ms var(--custom-easing);
}

.scroll-shadow-container--active {
    composes: scroll-shadow-container;
    opacity: 1;
}

.scroll-shadow-container--top {
    composes: scroll-shadow-container;
    top: -1px;
}

.scroll-shadow-container--bottom {
    composes: scroll-shadow-container;
    bottom: 81px;
}

.shadow {
    position: absolute;
    left: 0;
    z-index: 0;
    width: 100%;

    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1);
    transition: opacity 400ms var(--custom-easing);
}

.scroll-shadow-container--top .shadow {
    top: -1px;
}

.scroll-shadow-container--bottom .shadow {
    bottom: -1px;
}

.shadow-blocker {
    position: absolute;
    height: 11px;
    width: 100%;
    background-color: white;
}

.scroll-shadow-container--top .shadow-blocker {
    top: -12px;
}

.scroll-shadow-container--bottom .shadow-blocker {
    bottom: -12px;
}
