.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
}

.input {
    padding: 3px 0 3px 24px;
    height: 35px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--off-white-dk);
    font-size: var(--font-normal);
}

.filter-icon-container {
    position: absolute;
    right: 8px;
    user-select: none;
    color: var(--blue);
}

.filter-icon-container:hover {
    color: var(--blue-dk);
}

.filter-icon-container:hover svg {
    fill: var(--grey-dk);
}

.filter-icon svg {
    fill: var(--grey-lt);
}

.filter-container {
    padding: 10px;
}

.search-container {
    font-size: 17px;
    line-height: 21px;
    top: 10px;
    left: 14px;
}

.input > .search-container {
    font-size: var(--font-big);
}
