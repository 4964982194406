.page-title-bar-title {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 205px; /* Just enough room for "All companies" */
}

/* Off-one for the company logo, which is an image, not an icon */
.page-title-bar-title > img {
    padding-right: 16px;
}

.title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50px;
    min-width: 0; /* set a min-width for truncation*/
}

.icon-and-title {
    display: flex;
    align-items: center;
}

.icon {
    float: left;
    height: 42px;
    padding-right: 16px;
    fill: var(--grey-dk);
    color: var(--grey-dk);
}

.title {
    font-size: 21px;
    font-weight: 500;
    line-height: 29px;
    height: 26px;
    color: var(--grey-dk);
    font-family:
        doyle,
        IBM Plex Sans;
    opacity: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 400px;
}

.title-header-container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 16px;
}

.title--no-truncate {
    max-width: none;
}

.title--editable {
    /* Allow the outline to expand outside of the title */
    overflow: unset;
    /* User-inputted text needs to be IBM Plex Sans, as Doyle
    is not quite international enough */
    font-family: 'IBM Plex Sans';
    /* This helps center the title in the input field */
    line-height: unset;
}

.title--editable ~ * {
    /* This helps center title badge, glossary buttons for editable titles */
    margin-top: 3px;
}

.subheader-container {
    font-size: var(--font-normal);
    position: relative;
    height: 20px;
    line-height: 20px;
    margin-top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.subheader--secondary {
    color: var(--grey-dk);
    float: left;
    margin-right: 8px;
    transition: all 300ms var(--custom-easing);
}

.modified {
    margin-left: 4px;
}
