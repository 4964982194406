.container {
    margin-bottom: 2px;
}

.template-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
    padding: 8px;
}

.template-row--selected {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
    padding: 8px;
    background-color: var(--off-white-dk);
}

.template-row:hover {
    background-color: var(--off-white-dk);
}
