.drip-sequences-stats {
    display: block;
    max-width: 100%;
    overflow-y: auto;
    height: 100%;
    position: relative;
}

.drip-sequences-stats table {
    /* 1px offset for border */
    width: calc(100% - 1px);
}
