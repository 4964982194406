@import '../../../../include';
@import '../../../global/_colors';

.ui-modal.ui-modal--plan-form {
	.ui-modal-body {
		padding: 30px 45px;
		width: 752px;
		height: auto;
		position: absolute;
		top: 100px;
		left: 50%;
		margin: 0 0 0 -376px;
	}

	.add-users-notice {
		border: 2px solid @yellow;
		background-color: @yellow-lt;
		color: @grey;
		padding: 15px;
		border-radius: 4px;
		margin: 20px 0;
	}
}

.plan-form-wrapper {
	margin-bottom: 50px;
	margin-top: 16px;
	display: flex;

	[data-is-signup='1'] &,
	[data-is-former-customer='1'] & {
		float: left;
	}

	form {
		animation: fadeIn 2s @customEasing 0s forwards;
		opacity: 0;
		margin-right: 16px;
	}

	.fields-container {
		margin-bottom: 15px;
		box-sizing: border-box;
		border-radius: 4px;
	}

	.header-container {
		overflow: hidden;

		h2 {
			float: left;

			.annual-cta {
				font-size: 12px;
				font-weight: normal;
				color: @grey-lt;
			}
		}

		> button {
			float: right;
			margin: 2px 0 0 0;
			min-width: 80px;
		}
	}

	.radio-group-container {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.statement {
		color: @grey-lt;
		font-size: @big;
		margin-bottom: 40px;

		.flexbox-row {
			display: flex;

			> .column {
				width: 50%;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:first-child {
					padding-left: 0;
				}
			}
		}

		.label {
			color: @grey-dk;
		}

		.total {
			width: 50%;
			border-bottom: 2px solid @off-white-md;
			display: flex;
			justify-content: space-between;
			padding-bottom: 8px;

			.cost {
				display: inline-block;
				margin-left: 5px;
				font-weight: 500;
				color: @grey-dk;
			}
		}

		.coupon-invalid {
			font-size: @small;
			color: @rose;
		}

		.coupon-invalid button {
			text-decoration: underline;
			padding-left: 2px;
		}

		.coupon-valid {
			font-size: @small;
			color: @green;
		}

		.cycle {
			padding-bottom: 8px;
		}

		.divider {
			.column {
				padding: 2px 10px;

				.row-divider {
					width: 100%;
					border-bottom: 2px solid @off-white-md;
				}
			}
		}
	}

	.section-divider {
		width: 100%;
		border-bottom: 2px solid @off-white-md;
	}

	.support-section {
		max-width: 500px;
		color: @grey-lt;
		margin-top: 32px;

		h3 {
			margin: 25px 0 10px 0;
			font-size: @big;
			line-height: @big;
			color: @grey-dk;
			font-weight: normal;
		}
		a {
			color: @blue;
		}
	}

	.invalid-plan-message {
		font-size: @big;
		line-height: 20px;

		a {
			color: @blue;
		}
	}
	.errors-container {
		border: 2px solid @rose;
		padding: 10px;
		border-radius: 4px;
		margin-top: 10px;
		color: @rose;
		font-weight: 500;

		div {
			margin-bottom: 5px;
			&:last-child,
			&:only-child {
				margin-bottom: 0;
			}
		}
	}
}
