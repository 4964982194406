.modal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 900px;
    height: 80vh;
}

.content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    min-height: 700px;
    background-color: white;
}

.email-content {
    position: relative;
    top: 120px;
    overflow-y: auto;
    background-color: white;
    /* match margin of header */
    padding: 20px 32px;
    margin: 0 auto;
}

.content-container > :last-child {
    position: absolute;
    bottom: 0;
}

.loading {
    position: relative;
    top: 120px;
    margin: 0 auto;
}
