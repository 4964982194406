.cards-error-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    background-color: var(--off-white-dk);
    color: var(--grey-dk);
    font-size: var(--font-normal);
}

.error-text {
    text-align: center;
    line-height: 19px;
    margin-left: 8px;
    margin-right: 8px;
}

.error-text a {
    color: var(--blue);
    text-decoration: underline;
}

.error-text a:hover {
    color: var(--blue-dk);
}
