.container,
.container--hoverable {
    background-color: var(--white);
}

.container--hoverable:hover {
    background-color: var(--off-white);
}

.loading {
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.fields {
    padding: 0 16px 8px 16px;
    text-align: left; /* override centering that occurs when this is inside a ButtonCard */
}
