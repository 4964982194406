.container {
    background-color: white;
    z-index: 10;
    width: 90px;
    height: 20px;
    position: absolute;
    top: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popover-content-container {
    padding: 12px;
    width: 350px;
}

.popover-stats-container {
    margin: 8px 0;
}

.badge-outer-container {
    width: 120px;
    margin-left: -15px;
    text-align: center;
    margin-bottom: 8px;
}

.badge-inner-container {
    display: flex;
    justify-content: center;
}
