.integration-form {
    background-color: var(--off-white);
    padding: 32px 64px;
    max-width: 512px;
    max-height: 512px;
}

.text-field {
    max-width: 256px;
}
