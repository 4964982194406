.content-container {
    display: flex;
    padding: 16px;
}

.avatar-container {
    position: relative;
    top: 0;
    margin-right: 8px;
    height: 24px;
}

.comment-container {
    position: relative;
    top: 4px;
}

.comment-container--multiline {
    composes: comment-container;
    top: 2px;
}
