@value gap: 16px;
@value max-detail-width: 328px;

.details-list {
    display: flex;
    gap: gap;
    flex-wrap: wrap;
}

.detail--full {
    flex: 1 0 100%;
    /* Needs a set width for text truncation */
    width: max-detail-width;
}

.detail--half {
    flex: 1 0 calc(50% - gap);
    /* Needs a set width for text truncation */
    width: calc(max-detail-width / 2);
}

.detail-name {
    font-size: var(--font-normal);
    line-height: 19px;
    color: var(--grey-dk);
    font-weight: 500;
    margin-bottom: 4px;
}

.detail-value {
    font-size: var(--font-normal);
    line-height: 19px;
    color: var(--grey);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.detail-value i {
    color: var(--grey-lt);
}
