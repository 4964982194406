#onboarding-phone-modal {
	position: fixed;
	display: none;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.85);

	&.state-active {
		display: block;
		opacity: 1;
		#util.animation(fadeIn, 1s, @customEasing);
	}

	.onboarding-phone-slideshow-wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		padding: 0;
		margin: -207px 0 0 -350px;
		text-align: center;
		background-color: white;
		border-radius: 3px;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
		overflow: hidden;
	}
}

.onboarding-phone-slideshow-wrapper {
	width: 698px;
	height: 502px;

	.header {
		text-align: center;
		font-size: 28px;
		color: @grey-dk;
		background-color: @off-white; // Same color as graphic
		padding: 30px;

		// Required for Pro plan preview modal
		// We're we inject the phone onboarding content into a different modal with additional styles attached
		margin: 0;

		p {
			font-family: @body-font;
			font-size: @big;
			color: @grey;
			margin-bottom: 0;
			padding: 4px 0;
		}
	}

	.slide-two .header {
		padding: 48px;
	}

	.slide-one-graphic {
		width: 698px;
		height: 181px;
		margin: 20px auto 0;
	}

	.slide-two-graphic {
		width: 698px;
		height: 181px;
		margin: 40px auto 0;
	}

	.slide-one-graphic {
		.2x('@{img-root}/modals/onboarding-phone/click-to-call_slide1_graphic.png', 'no-repeat', 698px, 181px);
	}

	.slide-two-graphic {
		.2x('@{img-root}/modals/onboarding-phone/click-to-call_slide2_graphic.png', 'no-repeat', 698px, 181px);
	}

	p {
		font-size: @big;
		line-height: 24px;
		font-family: inherit;
		font-weight: normal;
		color: @grey;
		padding: 0 40px;
		margin: 4px 0 15px 0;
	}

	.ui-btn { margin: 0 5px; }

	.cols {
		overflow: hidden;
		margin-bottom: 52px;
		> div {
			display: inline-block;
			width: 33.333333%;
			float: left;
			font-size: 16px;
			line-height: 21px;
		}
	}
}
