ul.bulleted-links {
	li {
		float: left;
		font-size: @tiny;
		line-height: @tiny;

		a {
			// for the :after bullet point
			position: relative;
			display: block;
			// lotsa padding for wider click target
			padding: 6px 6px 6px 8px;

			color: @grey;
			&:hover {
				color: @grey-dk;
				text-decoration: underline;
			}

			// since we target the <a>s instead of <li>s for display,
			// bullet point goes here so it gets hidden when the <a> link isn't visible
			&:after {
				position: absolute;
				right: -4px;
				content: '•';
				font-size: 11px;
				color: @grey-lt;
			}
		}

		&:last-child a:after {
			content: '';
		}
	}

	.timeline3-entry &,
	.actionable-entry & {
		opacity: 0.3;
	}
	.timeline3-entry:hover &,
	.actionable-entry:hover & {
		opacity: 1;
	}
}
