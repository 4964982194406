.container {
    display: flex;
}

.container-clearable {
    composes: container;
    display: flex;
    /* Enough padding for our remove button + 8px margin */
    padding-right: 18px;
}

.clickable-date {
    cursor: pointer;
}

.clickable-date:hover {
    text-decoration: underline;
}

.warning-date {
    color: var(--color-overdue);
}

.close-button-container {
    cursor: pointer;
    display: none;
    margin-left: 8px;
}

.close-button-container > button {
    padding: 0;
}

.container-clearable:hover {
    padding-right: 0;
}

.container-clearable:hover .close-button-container {
    display: block;
}
