.companies-map-wrapper {
    display: flex;
    gap: 32px;
    padding: 32px;
    align-items: center;
    position: relative;
}

.companies-map-wrapper > * {
    flex: 50%;
}

.map-svg-container {
    max-width: 700px;
}

@media screen and (max-width: 1200px) {
    .companies-map-wrapper {
        flex-flow: column-reverse;
    }

    .companies-map-wrapper > * {
        width: 100%;
    }

    .map-svg-container {
        max-width: 400px;
    }
}
