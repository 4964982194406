.two-line-option-card {
    width: 100%;
    padding: 16px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.two-line-option-card:hover,
.two-line-option-card:focus {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.2s ease;
}

.two-line-option-card--selected {
    composes: two-line-option-card;
    border-color: var(--blue);
}

.two-line-option-card--disabled {
    composes: two-line-option-card;
    opacity: 0.6;
}

.two-line-option-card--selected:hover,
.two-line-option-card--disabled:hover,
.two-line-option-card--selected:focus,
.two-line-option-card--disabled:focus {
    box-shadow: none;
}

.two-line-option-card--disabled:hover {
    cursor: default;
}
