.container {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
}

.container--outbound {
    composes: container;
    flex-direction: row-reverse;
}

.container--with-status {
    composes: container;
    margin-top: 40px; /* Leaves room for absolutely position status text above */
}

.message-container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px; /* For readability – don't want too wide of messages */
}

.message-container--outbound {
    composes: message-container;
    align-items: flex-end;
}

.message {
    border-radius: 8px;
    display: flex;
    padding: 8px;
    box-sizing: border-box;
    font-size: var(--font-size-small);
    background-color: var(--off-white);
    align-items: flex-start;
    width: fit-content;
    max-width: 100%;
    overflow-wrap: anywhere;
    white-space: pre-line;
}

.message--outbound {
    composes: message;
    background-color: var(--report-orange);
    color: var(--white);
}

.message--sending {
    composes: message;
    opacity: 0.7;
}

.message--scheduled {
    composes: message;
    background-color: var(--white);
    color: var(--grey);
    border: 1px dashed var(--grey);
}

.message--failed {
    composes: message;
    background-color: var(--white);
    color: var(--grey);
    border: 1px dashed var(--report-orange);
}

.buttons {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: flex-end;
}

.buttons > * {
    padding: 4px 0;
}

.empty-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--white);
}

.reaction {
    position: absolute;
    bottom: -8px;
    font-size: 12px;
    padding: 0 6px;
    border-radius: 12px;
    background-color: var(--off-white-dk);
    width: fit-content;
}

.timestamp--with-reaction-outbound {
    position: absolute;
    top: 42px;
    width: 100%;
    padding-bottom: 8px;
    margin-right: 12px;
}

.timestamp--with-reaction-inbound {
    position: absolute;
    top: 42px;
    width: 100%;
    padding-bottom: 8px;
}
