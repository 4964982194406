.copyable-link {
    display: flex;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    padding: 8px 16px 8px 8px; /* Less on left side to account for textfield */
}

.copyable-link--white {
    composes: copyable-link;
    background-color: var(--white);
}

.copyable-link input {
    border: none;
    padding: 0 8px 0 8px;
    margin-right: 8px;
    flex-grow: 1;
    background: transparent;
    color: var(--grey-dk);
}

.copyable-link a {
    padding: 0 8px;
}

.copyable-link button {
    flex-shrink: 0;
}
