@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.svg-loading {
    animation: spin 1s infinite linear;
    transform-origin: 50% 50%;
}
