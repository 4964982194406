.section {
    margin-top: 34px;
    margin-bottom: 14px;
}

.status-section-container {
    height: 70px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.peanut-embed {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    box-sizing: border-box;
    /* Peanut tries to set height to 100%, so we need to override here */
    height: fit-content !important;
}

.learn-more-container {
    float: right;
    /* Being inside section label makes weight bold - this undoes that */
    font-weight: 400;
}

.quote-contents-container {
    overflow-y: auto;
    height: 100%;
    padding: 0 32px 32px 32px;
}

.close-button-container {
    position: absolute;
    top: -24px;
    right: -2px;
}
