.form {
    position: relative;
    height: 100%;
}

.margin-left {
    margin-left: 20px;
    width: 100%;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.flex-row:last-child {
    margin-bottom: 0;
}

.flex-row > * {
    min-height: 75px;
    width: 100%;
}
