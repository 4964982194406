/* Importing entity color values defined in separate shared stylesheet */
@value person, company, product, competitor, source, quote from './entity-colors.css';

.section-item {
    border-radius: 3px;
}

.section-item--disabled {
    pointer-events: none;
    opacity: 0.7;
}

.item-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 28px;
    /* Mimic border */
    padding: 1px;
}

.item-header {
    flex: 1 1 100%;
}

.remove-button-container button {
    padding: 0 8px;
}

/* Darken the default color of remove button */
.remove-button-container button:not(:hover) svg {
    fill: var(--grey-lt);
}

.content-container {
    padding: 8px;
    box-sizing: border-box;
    /* Mimic border around content */
    background-color: var(--white);
    width: calc(100% - 2px);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    position: relative;
    bottom: 1px;
    left: 1px;
}
