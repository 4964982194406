.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.container svg {
    width: 80%;
}

.container :global(.slide-up) {
    animation: slideUp 500ms;
}

.container :global(.shadow) {
    animation: slideDown 500ms;
}

@keyframes slideDown {
    0% {
        transform: translate(3px, -15px);
    } /* The shadow is inside of the 'slide-up' group, so have to counter that 10px */
    30% {
        transform: translate(3px, -15px);
    } /* Give a little time for the modal to fade in */
    100% {
        transform: translate(0, 0);
    }
}

@keyframes slideUp {
    0% {
        transform: translate(2px, 10px);
    }
    30% {
        transform: translate(2px, 10px);
    } /* Give a little time for the modal to fade in */
    100% {
        transform: translate(2px, 0);
    }
}
