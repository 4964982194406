.input-container {
    display: flex;
    gap: 54px;
    width: 100%;
    box-sizing: border-box;
}

.text-container {
    display: flex;
    gap: 48px;
    justify-content: space-between;
    min-height: 200px;
}

.text-input {
    width: 230px;
    box-sizing: border-box;
    height: 170px;
}

.logo-container {
    width: 200px;
    height: 100%;
}

.image {
    width: fit-content;
    position: relative;
    max-width: 100%;
}

.image img {
    max-height: 180px;
    max-width: 100%;
    object-fit: contain;
}
