.wrapping-container {
    padding: 16px;
    box-sizing: border-box;
    /* Provides for the height of the top bar and the delete button */
    height: calc(100% - 144px);
}

.form-description {
    color: var(--grey-lt);
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--off-white-dk);
}

.option-title {
}

.option-subtitle {
    font-size: 12px;
    color: var(--grey-lt);
}

.empty-option {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-option :first-child {
    margin-bottom: 4px;
}

.cancel-button {
    composes: cancel-button from '../../../core/form/fields/forms.css';
}
