@import '../../../../include';
@import '../../../global/_colors';

.billing-form-wrapper {
	// Fix unintended box-model overflow of the floating child elements.
	overflow: hidden;
	margin-top: 16px;

	[data-is-signup='1'] &,
	[data-is-former-customer='1'] & {
		min-width: 310px;
		width: 350px;
		float: left;
	}

	.header-container {
		overflow: hidden;

		h2 {
			float: left;
		}

		> button {
			float: right;
			margin: 2px 0 0 0;
			min-width: 80px;
		}
	}

	.sub-header {
		color: @grey-dk;
		font-size: 16px;
		line-height: 16px;
		margin-bottom: 12px;
	}

	.fields-container {
		margin-bottom: 20px;

		&.state-error {
			border-color: @rose;
		}

		.error-text {
			color: @rose;
			margin-top: 8px;
		}
	}

	.radio-group-container {
		margin-bottom: 14px;
	}

	.success-msg {
		border: 2px solid @green;
		background-color: lighten(@green, 50%);
		color: @green;
		font-weight: 500;
		padding: 15px;
		border-radius: 4px;
		margin: 0 0 20px 0;
	}

	.form-container {
		animation: fadeIn 2s @customEasing 0s forwards;
		opacity: 0;
		min-width: 310px;
		width: 350px;
		float: left;

		input:not([type='checkbox']):not([type='radio']) {
			width: 100%;
			margin-bottom: 4px;
		}

		.billing-form__ui-select--country {
			width: 100%;
			select {
				border-color: @off-white-md;
				color: @grey-lt;
			}
		}

		.bank-account-type {
			text-align: right;
			margin-top: 1px;

			.ui-radio-btn label {
				min-width: 70px;
			}
		}

		.ui-input-checkbox-label {
			display: block;
		}

		.ui-input,
		.ui-select {
			&.state-error {
				border-color: @rose;
			}
		}
	}

	.us-customers-warning {
		padding: 8px 10px;
		margin-bottom: 5px;
		background-color: @blue-lt;
		border-radius: 4px;
		color: black;
	}

	.errors-container {
		border: 2px solid @rose;
		padding: 10px;
		border-radius: 4px;
		margin-top: 10px;
		color: @rose;
		font-weight: 500;

		div {
			margin-bottom: 5px;
			&:last-child,
			&:only-child {
				margin-bottom: 0;
			}
		}
	}

	.payment-method-container {
		animation: fadeIn 2s @customEasing 0s forwards;
		opacity: 0;

		width: 310px;
		float: right;
		margin-left: 40px;

		> div {
			position: relative;
			padding: 10px 15px;
			margin-bottom: 15px;
			border: 2px solid @off-white-md;
			box-sizing: border-box;
			border-radius: 4px;
			font-size: @small;
			color: @grey-lt;
		}

		h3 {
			font-size: inherit;
			font-weight: normal;
			color: lighten(@grey-lt, 25%);
		}

		.name {
			font-size: @normal;
		}

		.fa-lock {
			float: right;
			margin-top: 1px;
			color: lighten(@grey-lt, 25%);
		}
	}

	.bank-info {
		position: relative;
		> div {
			height: 135px;
		}
		.name {
			margin-bottom: 15px;
			display: inline-block;
		}
		.type {
			float: right;
		}

		.number {
			position: absolute;
			bottom: 10px;
			left: 15px;
			font-family: monospace;
			.routing {
				margin-right: 10px;
			}
		}

		.check-line {
			width: 100%;
			box-sizing: border-box;
			display: inline-block;
			height: 15px;
			border-bottom: 1px solid fade(@off-white-md, 50%);

			&.pay-to {
				width: 195px;
				float: left;
				border-right: 1px solid fade(@off-white-md, 50%);
			}
			&.num-amount {
				float: right;
				width: 65px;
				border: 1px solid fade(@off-white-md, 50%);
			}
			&.sign {
				width: 45%;
				float: left;
				&.right {
					float: right;
				}
			}
		}
	}

	.card-info {
		.number,
		.expiry > div span {
			display: block;
			box-sizing: border-box;
			margin: 5px 0 10px 0;
			padding: 5px 10px 4px 10px;
			border: 2px solid fade(@off-white-md, 50%);
			border-radius: 4px;
			font-family: monospace;
		}
		.expiry {
			float: right;
			display: inline-block;
			h3 {
				text-align: left;
			}
			> div {
				overflow: hidden;
				span {
					text-align: center;
					float: left;
					margin-right: 5px;
				}
			}
		}

		.name {
			margin-top: 4px;
		}

		h3 {
			clear: both;
		}
	}
}
