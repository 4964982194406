@import 'include';
@import 'peep/_peep-header.less';
@import 'peep/_peep-subheader.less';
@import 'peep/_peep-sidebar.less';

.peep-sprite() {
	.sprite-helper('sprites/peep-sprite.png', 200px, 150px);
}

.weather-sprite() {
	.sprite-helper('sprites/weather-sprite.png', 240px, 80px);
}

.peep-container {
	background-color: white;
	border-radius: 8px 0 0 0;

	.action-new {
		float: right;
		text-align: right; // in case we're not floated (i.e. omnifield)
		color: @blue;
		text-decoration: underline;
		font-size: @tiny;
		&:hover {
			color: @blue-dk;
		}
	}

	.add-person {
		float: right;
	}
	&.action-new {
		text-align: right; // in case we're not floated (i.e. omnifield)
		color: @blue;
		text-decoration: underline;
		font-size: @tiny;
		&:hover {
			color: @blue-dk;
		}
	}

	.action-pop-lead-modal {
		float: right;
		text-align: right; // in case we're not floated (i.e. omnifield)
		color: @blue;
		text-decoration: underline;
		font-size: @tiny;
		padding: 0;
		&:hover {
			color: @blue-dk;
		}
	}

	.notify-message,
	.flash-message {
		margin: 20px;
	}

	.peep-cards {
		&.contacts-container {
			position: relative;
		}

		.account-card-container {
			.contact-enclosure {
				display: none;
			}
		}

		.float-area .empty-placeholder {
			// this is displayed by JS, legacy UI, but it's what triggers the new card
			display: none !important;
		}
		.float-area {
			margin: 15px 0 20px 0;
		}
	}

	.peep-body {
		#util > .border-box;
		padding: 0;
		display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
		display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
		display: -ms-flexbox; /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Chrome */
		display: flex;
		flex-direction: row;
		-webkit-flex-direction: row;
		flex-wrap: nowrap;
		-webkit-flex-wrap: nowrap;
		align-items: stretch;
		-webkit-align-items: stretch;
		border-top: 1px solid @off-white-dk;
		overflow: hidden;
		border-radius: 8px 0 0 0;

		.peep-column {
			display: block;
			position: relative;
		}

		.peep-main {
			max-width: none;
			display: block;
			width: auto;

			-webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
			-moz-box-flex: 1; /* OLD - Firefox 19- */
			width: 600px; /* For old syntax, otherwise collapses. */
			-webkit-flex: 1; /* Chrome */
			-ms-flex: 1; /* IE 10 */
			flex: 1;

			-webkit-box-ordinal-group: 1;
			-moz-box-ordinal-group: 1;
			-ms-flex-order: 1;
			-webkit-order: 1;
			order: 1;

			//this is needed to override some dumb width
			//nested within this thing that was jacking
			//everything up
			min-width: 1px;

			> div:not(.peep-subheader-container) {
				#util > .border-box;
				width: 100%;
				padding: 0 16px;

				&.peep-margin-offset-container {
					padding: 0;
					margin: 0;
				}
			}

			> .full-width {
				padding: 0;
				width: 100%;
			}
		}

		.state-hasmore h2 .action-viewmore {
			display: inline;
		}
		.action-viewmore {
			display: none;
			font-size: 11px;
			color: @grey;
			padding-left: 12px;

			span {
				color: @grey-lt;
				text-decoration: underline;
			}
		}
	}

	.activities-container {
		margin-bottom: 25px;
	}

	.timeline-upcoming {
		.timeline-entry {
			cursor: pointer;
			height: 36px;
			padding-bottom: 0;
			border-bottom: 1px solid @off-white-dk;

			&:hover {
				background: white;
			}

			.first-line {
				position: absolute;
				top: 10px;
				// swap the right and left sides (lol semantics)
				.left-side {
					left: 300px;
					width: 270px; // a bit narrower, since we're not as wide as a full timeline
				}
				.right-side {
					left: 0;
					.subject {
						font-size: 16px;
					}
				}
			}

			.gutter {
				.dot,
				.line {
					display: none;
				}
				.gutter-content {
					margin-top: 6px;
					background-color: transparent;
				}
				.date,
				.time,
				.year {
					text-align: left;
					padding-left: 8px;
				}
			}

			.icon,
			.content {
				display: none;
			}
		}
	}

	.timeline-container.timeline-past {
		width: 100%;
		margin-top: 0;
		.big-empty-placeholder {
			margin-left: 20px;
		} // offset the timeline-container -20px margin

		.timeline-creator {
			.toggle .icon {
				display: none;
				@media (min-width: 1087px) {
					display: inline-block;
				}
			}
		}
	}

	&.entity-accounts .accounts-container {
		display: none;
	}
	&.entity-contacts .contacts-container {
		display: none;
	}
}

.keep-in-touch-controls {
	width: 130px;
	z-index: 10;

	.plant-btn {
		position: relative;
		top: 11px;
		display: inline-block;
		width: 25px;
		margin-right: 7px;

		.ui-btn--icon {
			position: absolute;
			bottom: 10px;
			margin: 0;
			padding: 0;
			border: 0;
		}
	}

	.ui-btn--icon.ndtb-remove {
		display: inline-block;
		margin: 12px 10px 0 4px;
		padding: 0;
		opacity: 0.3;
		font-size: 17px;

		&:hover {
			opacity: 1;
		}
	}
}
