@value footer-height: 17px;

.summary-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: footer-height;
    color: var(--grey-lt);
}

.action-buttons {
    display: flex;
    align-items: center;
    gap: 12px;
}

.action-buttons button,
.action-buttons > div {
    padding: 0;
    height: footer-height;
}
