.container {
    display: flex;
    justify-content: flex-end;
}

.container input[type='radio'] {
    display: none;
}

.label-container {
    display: flex;
}

.label {
    margin-left: 6px;
}
