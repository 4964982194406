@value label from '../../../client_modules/shells/react/components/form/label.css';

.container {
    position: relative;
    padding-top: 16px;
    box-sizing: border-box;
    /* Height of header */
    height: calc(100% - 65px);
}

.container--deleteable {
    composes: container;
    padding-bottom: 48px;
}

.form-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Header */
.form-container > *:first-child {
    flex-shrink: 0;
}

.form-body {
    overflow-y: auto;
}

.form-input {
    margin-bottom: 32px;
}

.form-input-goal-type-select {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.input-wrapper {
    margin: 8px 0;
    padding: 24px 0;
    flex: 1;
}

.nested-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.nested-label > label:first-child {
    line-height: 31px;
    margin-bottom: 0;
}

.nested-label > *:last-child {
    width: calc(100% - 60px);
}

.nested-label > input:last-child {
    height: 31px;
}

.value-container {
    composes: nested-label;
}

.value-container > *:last-child {
    width: calc(100% - 28px);
}

.subheader {
    color: var(--grey-lt);
    line-height: 19px;
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 24px;
}

.subheader--with-divider {
    composes: subheader;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--off-white-dk);
    margin-bottom: 12px;
}

.description {
    font-size: var(--font-normal);
    line-height: 1.4;
    color: var(--grey-lt);
    margin: 0 16px 16px 16px; /* for border styling */
    padding: 16px 0;
    border-bottom: 1px solid var(--off-white-dk);
}

.checkbox {
    font-size: var(--font-normal);
    color: var(--grey-dk);
    margin-bottom: 8px;
}

.confidence-textfield {
    display: flex;
    align-items: center;
    width: 100px;
}

.confidence-textfield > input {
    text-align: right;
}

.percent {
    font-size: var(--font-big);
    color: var(--grey-dk);
    margin-left: 8px;
}

.label {
    display: block;
    color: var(--grey-lt);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 16px;
}

/* Same as shells floating-label-textfield.css styles */
.label--required {
    composes: label;
    left: -7px;
}

.label--required::before {
    content: '*';
    color: var(--rose);
    padding-right: 2px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 16px;
    font-size: var(--font-big);
    border-bottom: 1px solid var(--off-white-dk);
    cursor: pointer;
}

.delete-button {
    color: var(--red);
    font-size: var(--font-normal);
    position: absolute;
    bottom: 16px;
    right: 16px;
    cursor: pointer;
}

.delete-button:hover {
    color: var(--rose-dk);
}

.row:hover {
    color: var(--grey-dk);
}

.row:first-child {
    border-top: 1px solid var(--off-white-dk);
}

.loading-container {
    display: flex;
    align-items: center;
    margin-top: 32px;
    flex-direction: column;
    fill: var(--grey-lt);
}

.delay-container {
    height: 31px;
}

.delay-container > div > input {
    height: 31px; /* override the height set in the textfield shell */
}

.delay-description {
    font-size: var(--font-normal);
    padding-top: 8px;
    margin: 0;
}

.email-sequence-template-link {
    composes: label;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.email-sequence-template-link div {
    margin-bottom: 4px;
}

.email-sequence-template-link a {
    width: fit-content;
}
