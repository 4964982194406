.outer-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
}

.left-container {
    width: 70%;
    display: flex;
    justify-content: center;
    overflow: auto;
}

.right-container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-shrink: 0;
}

.right-container > div {
    margin: 16px 16px 16px 0;
    height: auto;
    flex-shrink: 0;
}

.right-container > div:not(:first-child) {
    margin-top: 0;
}

.right-container > div:last-child {
    flex-grow: 1;
}
