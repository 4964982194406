.container {
    display: flex;
    height: 100%;
}

.left-container {
    width: 40%;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.grid-container {
    display: grid;
    grid-gap: 16px;
    flex: 1 1 100%;
    grid-template-rows: 1fr 1fr;
}

.divider {
    height: 100%;
}

.divider > div:first-child {
    height: 100%;
}

/* roundabout way of selecting the illustration to override the height */
.grid-container > button > div > div:first-child {
    height: 40px;
}

.right-container {
    background-color: var(--off-white);
    width: 60%;
    max-height: 100%;
    overflow: auto;
}

.description {
    font-size: var(--font-normal);
    padding-top: 16px;
    line-height: 19px;
    max-width: 160px;
    text-align: center;
}

.background {
    background-color: var(--plum-bg);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
