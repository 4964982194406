.content {
    position: relative;
    padding: 16px 6px 16px 16px;
    width: 340px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.title {
    padding-right: 16px;
}

.info {
    composes: info from 'unlock-popover.css';
    margin-top: 16px;
}
