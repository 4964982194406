.ui-input-slider {
	width: 120px;
	display: inline-block;
	.ui-slider {
		height: 2px;
		background-color: @off-white-md;
		background-image: none;
		border: 0;
		border-radius: 2px;
	}
	.ui-slider-handle {
		height: 26px;
		width: 26px;
		border-radius: 13px;
		background-color: white;
		background-image: none;
		top: 50%;
		left: 0;
		margin-top: -14px;
		box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.15);
		border: 1px solid @off-white-md;
		cursor: pointer;
		#util.transition(box-shadow, 250ms, @customEasing);
		&.ui-state-active { box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.15); }
	}
	.ui-slider-bar-percentage {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: @blue;
		border-radius: 2px;
	}
	.ui-slider-ticks {
		font-size: 11px;
		color: @grey;
		opacity: 0.5;
		margin-top: 3px;
		> span {
			display: inline-block;
			text-align: left;
			// &:first-of-type { text-align: left; }
			&:last-of-type { text-align: right; }
		}
		&.ui-slider-ticks--5-items > span {
			width: 20%;
		}
	}
}
