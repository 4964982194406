.details-container {
    padding: 16px 0 24px 0;
    margin: 8px 24px 8px 24px;
}

.header {
    font-weight: 500;
    margin-bottom: 4px;
    font-size: var(--font-normal);
}

.table-title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.table-title > *:last-child {
    margin-left: 12px;
}

.table-container {
    border: var(--border-hairline);
    border-radius: 3px;
    margin-bottom: 16px;
}
