#myaccount .notifications-table {
	@distance-between-checkboxes: 120px;
	@column-label-width: 340px;

	font-size: @normal;
	min-width: 692px;
	clear: both;
	box-sizing: border-box;

	.column-notification {
		position: relative;
	}
	.all-on {
		position: relative;
	}
	.placeholder-0,
	.placeholder-2,
	.placeholder-3 {
		display: none;
		visibility: hidden;
		cursor: default;
	}

	&.all-checked-type-0 {
		.action-mass-notification-switch.type-0 {
			display: none;
		}
		.placeholder-0 {
			display: block;
		}
	}

	&.all-checked-type-2 {
		.action-mass-notification-switch.type-2 {
			display: none;
		}
		.placeholder-2 {
			display: block;
		}
	}
	&.all-checked-type-3 {
		.action-mass-notification-switch.type-3 {
			display: none;
		}
		.placeholder-3 {
			display: block;
		}
	}

	.notification-table-subheader {
		padding-left: @column-label-width;
		background: white;
		h2 {
			font-size: @small;
			letter-spacing: 1px;
			text-transform: uppercase;
			width: @distance-between-checkboxes;
			padding: 10px 0;
			display: inline-block;

			.action-mass-notification-switch {
				font-size: @small;
				font-weight: normal;
				text-transform: none;
				color: @blue;
				&:hover {
					text-decoration: underline;
					color: @blue-dk;
				}
			}
		}
	}

	.notification-table-body {
		position: relative;
		padding: 0 16px 64px 16px;

		.section-border {
			margin-top: 10px;
			border-top: 1px dashed @off-white-dk;
			.notification-section {
				padding: 6px 0;
				margin-top: 10px;
				font-weight: 500;
				color: @grey-dk;
			}
			&:first-of-type {
				margin: 0 -16px;
				padding: 0 16px;
			}
		}
		.column-label {
			color: @grey-lt;
			display: inline-block;
			padding: 5px 0;
			width: @column-label-width;
		}
		.column-notification {
			display: inline-block;
			width: @distance-between-checkboxes;
		}
		.configure {
			margin-bottom: 8px;
			a {
				color: @blue;
				&:hover {
					color: @blue-dk;
				}
			}
		}

	}
}
