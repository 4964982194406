.tokenized-wrapper {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    color: var(--grey);
    margin: 1px 3px 1px 0;
}

.tokenized-content {
    background-color: var(--off-white-md);
    padding: 0 4px;
    border-radius: 3px;
}
