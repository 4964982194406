.container {
    padding: 10px 12px 3px 10px;
    border-bottom: 1px solid var(--off-white-dk);
    display: flex;
    flex-wrap: wrap;
}

.container > * {
    margin-bottom: 8px;
    margin-right: 10px;
}
