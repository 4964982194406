.card-container {
    margin-left: 8px;
    flex: 1;
    min-width: 0;
}

.card {
    width: 100%;
}

.row-text {
    padding: 8px;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.title-container,
.subtitle-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.subtitle-container {
    margin-top: 4px;
}

.error-icon {
    color: var(--color-error);
}

.title {
    flex-grow: 1;
    font-size: var(--font-small);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--grey-lt);
}

.trouble-icons > * {
    padding-left: 4px;
}

.tracked-links-count {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
}

.tracked-links-count svg {
    margin-right: 4px;
    fill: var(--icon-primary);
}
