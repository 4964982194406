.wallboard-link {
    display: block;
    margin: 0 16px 16px 16px;
    text-align: center;
    font-size: 12px;
    color: var(--blue);
    padding: 8px 16px;
    background: white;
    box-sizing: border-box;
    border: 1px solid var(--off-white-dk);
    border-radius: 5px;
    cursor: pointer;
}

.wallboard-link:hover,
.wallboard-link:focus {
    color: var(--blue-dk);
    border-color: var(--grey-lt);
}

.wallboard-link-new {
    composes: wallboard-link;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: var(--secondary-sidebar-hover-blue);
}

.wallboard-link-new:hover,
.wallboard-link-new:focus {
    background: var(--navy-bg-active-new);
    border-color: rgba(255, 255, 255, 0.1);
}
