.row {
    margin-left: 16px;
    display: flex;
    flex-grow: 1;
    align-items: stretch;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    padding-top: 8px;
    padding-bottom: 8px;
    user-select: none;
}

.top-row {
    font-size: var(--font-normal);
}

.bottom-row {
    color: var(--grey-lt);
    font-size: var(--font-small);
}

.buttons {
    width: 24px;
    margin-right: 10px;
    flex-grow: 0;
    display: flex;
    align-items: center;
}
