.sticky-scroll-entity-table-wrapper {
    overflow: hidden;
    min-height: calc(100vh - 235px);
}

/* Some conflict with outer less files, just go with these important tags, for now */
.sticky-scroll-entity-table-wrapper :global(.ui-entity-list__column-header) {
    background-color: white;
    border-right: 1px solid var(--off-white-dk) !important;
    color: var(--grey-dk);
    padding: 0 8px !important;
}

.sticky-scroll-entity-table-wrapper
    :global(.ui-fixed-column-header .ui-entity-list__column-header) {
    border-right: none !important;
}

.sticky-scroll-entity-table-wrapper
    :global(.fixedDataTableLayout_header .public_fixedDataTableRow_fixedColumnsDivider) {
    border-color: var(--off-white-dk) !important;
}

.sticky-scroll-entity-table-wrapper :global(.public_fixedDataTable_header) {
    background-color: white;
}

.sticky-scroll-entity-table-wrapper :global(.ui-entity-list__column-header:hover) {
    background-color: var(--off-white-bg) !important;
    color: var(--grey-dk);
}

/* Remove hover from first column as this isn't draggable/reorderable */
.sticky-scroll-entity-table-wrapper
    :global(.ui-fixed-column-header .ui-entity-list__column-header:hover) {
    cursor: default;
    background-color: white;
}

.sticky-scroll-entity-table-wrapper
    :global(.ui-entity-list__column-header--filtered)
    :global(.svg-filter) {
    float: left;
    fill: var(--grey-lt);
    padding-top: 7px;
    padding-right: 7px;
}
