.option-card-chooser {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
}

.title {
    font-size: var(--font-big);
    color: var(--blue);
    /* In case title wraps, use margin instead of large line-height */
    line-height: 1.2;
    margin-bottom: 8px;
    margin-top: 6px;
    font-weight: 500;
}

.subtitle {
    font-size: var(--font-normal);
    color: var(--grey);
    line-height: 1.2;
}

.illustration {
    height: auto;
    margin-bottom: 8px;
}
