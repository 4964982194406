.configurations-column {
    width: 250px;
    flex-shrink: 0;
    background-color: var(--off-white);
    border-right: 1px solid var(--off-white-dk);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 3px;
    /* shadow should only be on right side */
    clip-path: inset(0 -3px 0 0);
}

.configurations-column-cell {
    composes: column-cell from './group-configuration-table.css';
}
