.button-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 89px;
    font-weight: 500;
    color: var(--white);
}

.button-text--active {
    composes: button-text;
    color: var(--sidebar-primary);
}

.add-inbox-line {
    position: absolute;
    top: -4px;
    left: -8px;
    width: 2px;
    height: calc(100% + 8px);
    background-color: var(--navy-dk-new);
    flex-shrink: 0;
}

.add-inbox-line--embedded {
    background-color: var(--rcfx-sidebar-hover);
}

.add-inbox-line--hover {
    background-color: var(--navy-lt-new);
}

.add-inbox-line--active {
    background-color: var(--navy-xlt-new);
}

.avatar-image {
    width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 10px;
    box-shadow: none;
    flex-shrink: 0;
    border-radius: 17px;
    z-index: 10;
}

.initials {
    composes: avatar-image;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 8px;
    left: 12px;
    font-weight: 500;
    cursor: default;
    text-align: center;
    text-transform: uppercase;
    font-size: 9px;
    color: var(--sidebar-primary);
    background-color: var(--white);
    z-index: 1;
}

.initials--active {
    composes: initials;
    color: var(--white);
    background-color: var(--sidebar-primary);
}

.add-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 60px;
    border-radius: 3px;
    text-align: center;
    outline: none;
    user-select: none;
    padding: 8px 12px;
    font-size: var(--font-small);
    background-color: transparent;
}

.add-button:focus-visible:not([disabled]) {
    background-color: var(--navy-dk-new);
    outline: var(--focus-blue) solid 2px;
}

.add-button:hover:not([disabled]) {
    background-color: var(--navy-dk-new);
}

.add-button--embedded {
    background-color: var(--rcfx-sidebar);
}

.add-button--embedded:hover:not([disabled]) {
    background-color: var(--rcfx-sidebar-hover);
}

.add-button--embedded:focus-visible:not([disabled]) {
    background-color: var(--rcfx-sidebar-hover);
    outline: var(--focus-blue) solid 2px;
}

.add-button--embedded:focus-visible:not([disabled]) .add-inbox-line--embedded {
    background-color: var(--navy-lt-new);
}

.add-button--active {
    composes: add-button;
    background-color: var(--white);
}

.add-button--active:hover:not([disabled]) {
    background-color: var(--white);
}

.add-button--active:focus-visible:not([disabled]) {
    background-color: var(--white);
}

.add-button--active:focus:not([disabled]) {
    background-color: var(--white);
}

.text-add-button {
    border-radius: 3px;
}

.text-add-button:focus-visible:not([disabled]) {
    outline: var(--focus-blue) solid 2px !important;
}
