.peep-two-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-small);
    padding: 12px;
    box-sizing: border-box;
}

.avatar {
    display: flex;
    flex-shrink: 0;
    margin-right: 12px;
}

.avatar--hidden {
    composes: avatar;
    margin-right: 10px;
}

.peep-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 8px;
    height: 33px;
    overflow: hidden;
}

.top-row {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    color: var(--grey-dk);
}

.top-row--align-single {
    composes: top-row;
}

.top-row--align-single:is(:only-child) {
    align-items: center;
}

.top-row--hidden {
    composes: top-row;
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding-left: 4px;
    user-select: none;
}

/* Don't want special "visited" styling in Google inbox extension */
.top-row:visited {
    color: inherit;
}

.top-row > a {
    min-width: 0;
    margin-right: 8px;
}

.top-row > a > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    font-size: var(--font-normal, 15px);
}

.bottom-row {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    justify-content: space-between;
}

.associated {
    color: var(--grey);
    overflow: hidden;
    text-overflow: ellipsis;
}
