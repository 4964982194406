.download-icon {
    height: 17px;
    display: block;
    position: relative;
    padding: 0;
    font-size: var(--font-big);
    color: var(--off-white-dk);
    transition: color 250ms var(--custom-easing);
}
.download-icon:hover {
    color: var(--green);
}

.download-icon:hover:after {
    opacity: 1;
    visibility: visible;
}
.download-icon:after {
    opacity: 0;
    visibility: hidden;
    content: attr(data-title);
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 3px;
    padding: 4px 8px;
    font-size: var(--font-small);
    color: var(--off-white);
    white-space: nowrap;
    transition: opacity 250ms var(--custom-easing);
}

.tooltip {
    padding: 10px 14px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    line-height: 1.5;
}
