.fuel-gauge-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.svg-container {
    width: 90%;
    display: flex;
    justify-content: center;
}

/*
This will transition the stroke color if needed (red/green).
The duration is a guess, and it feels okay.  The duration of the arc movement
is not specified directly, so there's no way to directly sync this with
that movement.
 */
.arc {
    transition: stroke 500ms;
    fill: none;
}

.arc-background {
    composes: arc;
    stroke: var(--icon-secondary);
}

.arc-outline {
    composes: arc;
    stroke: var(--icon-secondary);
}
