.activities-section {
    margin-bottom: 25px;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.activity-name {
    width: 295px;
    flex-shrink: 0;
    display: flex;
    gap: 8px;
    align-items: center;
}

.action-menu-section {
    position: absolute;
    right: 0;
}

.activity-time {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    width: 42px;
}
