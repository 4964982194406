.more-options-button {
    color: var(--grey);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 3px 3px 0;
}

.more-options-button[disabled] {
    opacity: 0.6;
    cursor: default;
}

.primary {
    composes: more-options-button;
    color: #fff;
    background-color: var(--blue);
}

/* Backup for browsers that do not support :focus-visible */
.primary:focus:not([disabled]) {
    background-color: var(--blue-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.primary:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.primary:focus-visible:not([disabled]) {
    background-color: var(--blue-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.primary:hover:not([disabled]) {
    background-color: var(--blue-dk);
}

.secondary {
    composes: more-options-button;
    color: var(--grey);
    background-color: var(--button-secondary);
}

/* Backup for browsers that do not support :focus-visible */
.secondary:focus:not([disabled]) {
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.secondary:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.secondary:focus-visible:not([disabled]) {
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.secondary:hover:not([disabled]) {
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
}

.small {
    font-size: var(--font-small);
}

.big {
    font-size: var(--font-normal);
    padding: 7px 8px;
}
