.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 8px 16px;
    box-sizing: border-box;
    height: 30px; /* Set height so it looks the same with/without the action menu */
}

.left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.left--with-action-menu {
    composes: left;
    width: calc(100% - 35px); /* 35px is the width of the action menu */
}

.title {
    width: calc(100% - 50px); /* Spacing for the value */
    display: flex;
    align-items: center;
}

.avatar {
    margin-right: 8px;
}

.name {
    max-width: 100%;
    overflow: hidden;
    margin-right: 8px;
    text-align: left; /* Align text to the left in lead merge modal */
}

.value {
    font-weight: 500;
    display: flex;
    align-items: flex-end;
}
