.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.type-container {
    flex-direction: column;
    display: flex;
    flex: 1;
    justify-content: center;
    margin-bottom: 20px;
}

.label {
    font-size: var(--font-normal);
    color: var(--grey-lt);
    margin-bottom: 10px;
    user-select: none;
}

.selectable-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.selectable-filters > div {
    margin-bottom: 4px;
}

.filter {
    display: block;
    width: 100%;
}

.filter :global(.ui-btn) {
    min-width: 220px;
}
