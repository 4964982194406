.tfa-code {
    width: 54px;
    height: 69px;
    color: var(--grey);
    text-align: center;
    font-weight: 500;
    font-size: 21px;
    line-height: 29px;
    composes: border from '../border/border.css';
}

.tfa-code:not(:last-child) {
    margin-right: 8px;
}

.tfa-code:nth-child(4) {
    margin-left: 8px;
}

.tfa-code::placeholder {
    color: var(--placeholder);
}

.mfa-row {
    display: flex;
    margin-bottom: 8px;
}

.loadingIcon {
    align-self: center;
    margin-left: 2px;
}
