@import "include.less";

.integrations-row {
	float: left;
	width: 100%;
	border-top: 1px solid @off-white-dk;
	border-left: 1px solid @off-white-dk;
	border-right: 1px solid @off-white-dk;
	&:first-of-type {
		border-top: none;
	}
	&:last-of-type {
		border-bottom: 1px solid @off-white-dk;
		margin-bottom: 12px;
	}

	label {
		font-size: @normal;
		color: @grey-lt;
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 8px;
		&:hover {
			cursor: pointer;
			color: @blue;
		}
		input[type="radio"] {
			margin-right: 3px;
		}
	}

	.enable-container.state-dirty a { margin-top: 10px; }
	.state-enabled-container {
		display: none;
		position: relative;
		font-size: @small;
		color: @grey-lt;
		strong { margin-left: 3px; }
	}
	.state-saving-container {
		display: none;
		position: relative;
	}

	.sync-options { position: relative; }
}
.integrations-row .column-left {
	width: 160px;
	position: relative;
	.logo {
		position: absolute;
		top: 16px;
		left: 16px;
		z-index: 2;
		width: 150px;
		height: 75px;
		border: 1px solid @off-white-dk;
		border-radius: 2px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
		background-size: 100% !important;
		background-color: @off-white;

		&.google-apps {
			.2x('@{img-root}/integration/google-apps.png?v=1', "no-repeat");
		}
		&.microsoft {
			.2x('@{img-root}/integration/microsoft.png?v=1', "no-repeat");
		}
		&.office365 {
			.2x('@{img-root}/integration/office365.png', "no-repeat");
		}
		&.twitter {
			.2x('@{img-root}/integration/twitter.png', "no-repeat");
		}
		&.exchange {
			.2x('@{img-root}/integration/microsoft-exchange.png', "no-repeat");
		}
		&.olark {
			.2x('@{img-root}/integration/olark.png?v=1', "no-repeat");
		}
		&.zendesk {
			.2x('@{img-root}/integration/zendesk.png?v=1', "no-repeat");
		}
		&.mailchimp {
			.2x('@{img-root}/integration/mailchimp.png?v=1', "no-repeat");
		}
		&.constant-contact {
			.2x('@{img-root}/integration/constant-contact.png?v=1', "no-repeat");
		}
		&.uservoice {
			.2x('@{img-root}/integration/uservoice.png?v=1', "no-repeat");
		}
		&.krispcall {
			.2x('@{img-root}/integration/krispcall.png?v=1', "no-repeat");
		}
		&.wufoo {
			.2x('@{img-root}/integration/wufoo.png?v=1', "no-repeat");
		}
		&.wordpress {
			.2x('@{img-root}/integration/wordpress.png', "no-repeat");
		}
		&.unbounce {
			.2x('@{img-root}/integration/unbounce.png', "no-repeat");
		}
		&.intercom {
			.2x('@{img-root}/integration/intercom.png', "no-repeat");
		}
		&.right-signature {
			.2x('@{img-root}/integration/right-signature.png', "no-repeat");
		}
		&.pandadoc {
			.2x('@{img-root}/integration/pandadoc.png', "no-repeat");
		}
		&.quoteroller {
			.2x('@{img-root}/integration/quoteroller.png', "no-repeat");
		}
		&.nutshell-mail {
			.2x('@{img-root}/integration/nutshell-mail.png', "no-repeat");
		}
		&.slack {
			.2x('@{img-root}/integration/slack.png', "no-repeat");
		}
		&.quickbooks {
			.2x('@{img-root}/integration/quickbooks.png', "no-repeat");
		}
		&.nutshell-marketing {
			.2x('@{img-root}/integration/nutshell-marketing.png?v=1', "no-repeat");
		}
		&.nutshell-forms {
			.2x('@{img-root}/integration/forms.png', "no-repeat");
		}
		&.nutshell-scheduler {
			.2x('@{img-root}/integration/nutshell-scheduler.png', "no-repeat");
		}
		&.sendto {
			line-height: 75px;
		}
		&.sendto:before {
			display: block;
			width: 150px;
			height: 75px;
			font-family: 'FontAwesome';
			content: '\f1d9'; // All I wanna do is 💥💥💥💥
			font-size: 45px;
			color: var(--grey);
			text-align: center;
			vertical-align: middle;
		}
	}
}
.integrations-row .column-right {
	width: 100%;
	float: left;

	.row-integration {
		position: relative;
		float: left;
		width: 100%;
		#util > .border-box;
		background-position: 0 100%;
		border-right: 6px solid transparent;
		padding: 16px 0 16px 160px;
		min-height: 110px;
		&:first-child {
			margin-left: 0; // allows for the white enabled background to stretch full width
			padding-left: 170px;
		}
		&.state-enabled {
			background-color: rgba(255,255,255, 0.7);
			border-right: 6px solid @green-lt;
			.enable-container.state-disconnected { display: none; }
			.enable-container.state-connected { display: block; }
			.state-enabled-container { display: block; }
			.state-saving-container { display: block; }
		}
		&.state-saving {
			.enable-container {
				&.state-saving { display: block; }
				&.state-disconnected { display: none; }
			}
			.state-enabled-container { display: none; }
			.state-saving-container { display: block;}
			span.example { margin-left: 8px; }
		}
		&.state-dirty {
			.enable-container.state-dirty {
				display: block;
				position: relative;
				left: 0;
				opacity: 1;
				transition: opacity 1s linear;
			}
		}
		.enable-container {
			width: 133px;
			float: right;
			text-align: center;
			&.state-disconnected { display: block; }
			&.state-saving { display: none; }
			&.state-connected { display: none; }
			&.state-dirty {
				//allows for simple transition animation to work.
				position: absolute;
				left: -1000px;
				opacity: 0;
			}
			.button-setting {
				width: 120px;
			}
		}

		.option-container {
			position: relative;
			.secondary-saving-container { display: none; }
			&.state-enabled {
				.secondary-saving-container { display: block; }
				label > span { background-color: @green-lt; }
			}
			&:last-child { margin-bottom: 30px; }

			.secondary-saving-container.sync-options-calendars {
				margin-top: 8px;
			}
			span.calendar-color {
				border-radius: 1px;
				border: 1px solid @off-white-dk;
				display: inline-block;
				height: 11px;
				margin-right: 4px;
				width: 11px;
			}
		}

		.content {
			font-size: @normal;
			line-height: 22px;
			color: @grey-lt;
			width: ~"calc(100% - 133px)";
			max-width: 800px;
			padding-right: 8px;
			padding-left: 16px;
			box-sizing: border-box;
			float: left;
			.error { color: @rose; }
		}

		.extra-info {
			position: absolute;
			top: -17px;
			left: -165px;
			width: 130px;
			margin-top: 20px;
			margin-left: 0;
			a {
				display: block;
				color: @blue;
				font-size: @small;
				line-height: 17px;
				margin-bottom: 10px;
				&:hover { text-decoration: underline; }
			}
		}

		h1 {
			font-size: @big;
			color: @grey-dk;
			margin: 0 0 8px 0;
			sup {
				color: @grey;
				font-weight: normal;
			}
		}
		p {
			margin: 0 0 6px 0;
		}
		p.not-connected { color: lighten(@grey-lt, 20%); }
		p.connected {
			display: inline-block;
			background: @green-lt;
			color: @green;
		}
		input[type="checkbox"] { margin-right: 5px; }
	}
}

#send-email {
	position: relative;
	&.state-enabled {
		.state-enabled-container {
			display: block;
		}
		background-color: rgba(255,255,255, 0.7);
		.row-integration { border-right: 6px solid @green-lt; }
		.enable-container.state-disconnected { display: none; }
		.enable-container.state-connected { display: block; }
	}
}

#slack-test-message-container {
  .Select {
	float: left;
	width: 250px;
	margin-right: 20px;
  }
}
