.goals-goal {
    position: relative;
    display: block;
    color: var(--grey-dk);
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: normal;
}

.goals-goal:before {
    position: absolute;
    content: '\f0da'; /* caret right */
    font-family: FontAwesome;
    font-size: 22px;
    color: var(--off-white-md);
    left: -16px;
}

.goals-goal--complete {
    composes: goals-goal;
    color: var(--green);
}

.goals-goal--complete:before {
    color: var(--green);
}

.base-condition {
    margin-bottom: 16px;
}

.warning-message {
    margin-top: -12px;
}

.warning-message span {
    font-size: 13px;
}
