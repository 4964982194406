.container {
    position: relative;
}

.label,
.input {
    font-size: 20px;
}

.label {
    position: absolute;
    left: 1px;
    top: 14px;
    transition: all 250ms var(--custom-easing-bounce-short);
    color: var(--label);
    /* Pass all pointer events under the label to the input. */
    pointer-events: none;
}

.label--required {
    composes: label;
    right: -7px;
}

.label--required::after {
    content: '*';
    color: var(--rose);
    padding-left: 2px;
}

.input {
    display: block;
    width: 100%;
    height: 46px;
    margin-top: 22px;
    color: var(--grey-dk);
    border: 0;
    border-bottom: 2px solid var(--off-white-dk);
    box-sizing: border-box;
    outline: none;
    transition: border 250ms var(--custom-easing-bounce-short);
}

.input:disabled {
    border-color: transparent;
    background-color: transparent; /* Firefox. */
}

.input::-ms-clear {
    display: none;
}

.input:focus {
    border-color: var(--blue);
}

.input--active {
    composes: input;
}

.input--invalid {
    border-color: var(--rose);
}

.input--active + .label {
    transform-origin: left top;
    transform: translateY(-30px) scale(0.8);
}

.textarea {
    composes: input;

    padding-top: 10px;
    resize: none;
}

.textarea .label {
    top: 40px;
}

.textarea--active {
    composes: textarea;
    composes: input--active;
}

.actions-container {
    position: absolute;
    bottom: 10px;
    right: 0;

    display: flex;
    align-items: center;

    transform-origin: left center;
    transform: translateX(25px);
    opacity: 0;
    visibility: hidden;
    transition: all 200ms var(--custom-easing-bounce);
}

.actions-container--active {
    composes: actions-container;

    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.button-confirm {
    margin-right: 4px;
    padding: 0;
    fill: var(--green);
}

.button-confirm svg {
    pointer-events: none;
}

.button-cancel {
    padding: 0;
    /* This is a FA icon. Size is maintained by font-size for now :sponge:. */
    font-size: 24px;
}
