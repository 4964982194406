.list-view-infinite-loading {
    display: flex;
    justify-content: center;
    padding: 40px 0;
}

.list-view-infinite-loading svg path,
.list-view-infinite-loading svg rect {
    fill: var(--orange);
}
