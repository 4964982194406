.container {
    padding: 32px 32px 32px 32px;
}

.header {
    font-size: var(--font-big);
    color: var(--grey-dk);
    font-weight: 500;
    margin-bottom: 16px;
}

.description {
    font-size: var(--font-small);
}

.explanation {
    margin-top: 16px;
    font-style: italic;
}

.type-selector {
    display: flex;
    overflow: visible;
}

.selector {
    width: 129px; /* This sets the width to be just large enough for the text to look right */
}

.field-type {
    font-size: var(--font-normal);
    margin-left: 8px;
    align-self: center;
}

.title-link {
    padding-left: 8px;
}

.cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px 16px;
    padding-bottom: 16px;
}

.card {
    width: 100%;
}

.icon {
    margin-right: 8px;
    align-self: center;
}

.field-mapping-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-normal);
    height: 80px;
    width: 100%;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}
