.container {
    font-weight: 500;
    display: flex;
    justify-content: center;
    height: 25px; /* Ensures everything is vertically aligned */
}

.control {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey);
    cursor: pointer;
    border-radius: 3px;
    padding: 0 8px;
}

.control-disabled {
    composes: control;
    color: var(--off-white-dk);
    cursor: default;
    pointer-events: none;
}

.control-disabled .chevron {
    color: var(--off-white-dk);
}

.control:hover {
    background-color: var(--off-white-md);
}

.control:hover .control-text {
    color: var(--grey-dk);
}

.chevron {
    font-size: 12px;
    color: var(--grey);
    margin-top: 2px;
}

.prev {
    composes: control-text;
    margin-left: 8px;
}

.next {
    composes: control-text;
    margin-right: 8px;
}

.control:hover .chevron {
    color: var(--grey-dk);
}

.page-controls {
    display: flex;
    padding: 0 10px;
}

.page-control {
    text-align: center;
    color: var(--grey);
    height: 24px;
    width: 24px;
    line-height: 24px;
    border-radius: 50%;
    margin: 0 2px;
    cursor: pointer;
}

.page-control-selected {
    composes: page-control;
    cursor: default;
    color: var(--grey-dk);
    background: var(--off-white-md);
}

.page-control:hover {
    color: var(--grey-dk);
    background: var(--off-white-md);
}

.control:focus,
.page-control-selected:focus,
.page-control:focus {
    outline: 2px solid var(--focus-blue);
    outline-offset: -3px; /* This makes the outline visible inside the column header */
}
