.container {
    width: 140px;
    display: flex;
    justify-content: center;
    padding: 5px;
    border-bottom: 1px solid var(--off-white-dk);
}

.container > * {
    flex-grow: 1;
}

.option-container {
    display: flex;
}

.option-container svg {
    margin-right: 6px;
    margin-top: 2px;
}

.reply-all-container svg {
    width: 15px;
}
