@import 'include';
#list-stage.state-dirty {
	.action-save {
		display: inline-block;
	}
	.action-edit {
		display: none;
	}
}

#list-stage {
	.action-save {
		display: none;
	}
	.action-edit {
		display: inline-block;
	}
}

.pipeline-delete-disabled {
	position: relative;
	float: left;

	.pipeline-delete-tooltip {
		display: none;
		z-index: 10;
		position: absolute;
		top: 30px;
		width: 80px;
		right: 10px;
		padding: 8px 16px;
		background-color: white;
		color: @grey-dk;
		font-size: 13px;
		border-radius: 3px;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
	}

	&.state-hover-delay .pipeline-delete-tooltip {
		display: block;
	}

	button {
		cursor: pointer;
		opacity: 0.3;
		pointer-events: none;
	}
}
