.error-list li {
    position: relative;
    display: block;
    margin-bottom: 8px;
    line-height: 1.5;
}

.error-list li:last-child {
    margin-bottom: 0;
}
