.details-container {
    display: flex;
    height: 100%;
}

.details-container-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin: 16px 0 16px 16px;
    overflow: auto;
}

.details-container-right {
    width: 30%;
    padding: 24px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    margin: 16px;
}
