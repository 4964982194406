.container {
    line-height: 1.4;
    display: flex;
    height: 100%;
    margin-bottom: 4px;
}

.input,
.button,
.button--required {
    width: 100%;
    height: fit-content;
    padding: 0;
}

.input {
    line-height: 1.4;
}

.input:focus {
    box-sizing: border-box;
    border: none;
    outline: none;
}

.button {
    white-space: pre-wrap;
    text-align: unset; /* This overrides out default button style */
    padding-bottom: 0.5px; /* This prevents a tiny jump when the input is selected */
}

.button--required {
    white-space: pre-wrap;
    text-align: unset; /* This overrides out default button style */
    padding-bottom: 0.5px; /* This prevents a tiny jump when the input is selected */
    right: -7px;
}

.button--required::after {
    content: '*';
    font-weight: 700;
    padding-left: 2px;
}

.button:hover,
.button--required:hover {
    background-color: white;
}
