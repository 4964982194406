.nav-container {
    margin-left: 0;
    margin-top: 0;
    display: flex;
    justify-content: center;
}

:global(#settings-nav),
:global(#settings-nav-disabled) {
    float: left;
    display: flex;
    flex-grow: 1;
    width: 168px;
    min-height: 100vh;
    height: 100%;
}

.settings-button {
    display: flex;
    justify-content: center;
    width: 146px;
    right: 3px;
}

.shortcuts-button {
    display: block;
    margin-bottom: 16px;
    justify-self: center;
    padding: 6px 8px;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: var(--secondary-sidebar-hover-blue);
}

.shortcuts-button:hover,
.shortcuts-button:focus {
    background: var(--navy-bg-active-new);
    border-color: rgba(255, 255, 255, 0.1);
}
