.add-a-comment {
    position: relative;
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--off-white-dk);
}

.button-container {
    position: relative;
    right: 16px;
    top: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container > *:last-child {
    margin-left: 8px;
}

.saving {
    width: 18px;
    height: 18px;
    color: var(--white);
    background-color: var(--green-dk);
    border-radius: 10px;
}

.textarea {
    padding: 4px 8px;
    width: 100%;
}

.add-a-comment:focus-within {
    outline: 2px solid var(--focus-blue);
    outline-offset: -2px;
}
