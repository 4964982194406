.link-card {
    composes: card from './card.css';
    position: relative;
    padding: 0;
}

.link-card--bordered {
    composes: card--shadowed from './card.css';
    composes: border from '../border/border.css';
}

.link-card--shadowed {
    composes: card--shadowed from './card.css';
}

.link-shadowed::after {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

.link-card::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.link-card:focus-within,
.link-card:focus-visible {
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}

.link-card:hover::after,
.link-card:focus::after {
    opacity: 1;
}

.link-card--fitHeight {
    height: auto;
}

.link-card--fullWidth {
    width: 100%;
}

.link-card--flex {
    flex: 1;
}
