.container {
    display: flex;
    flex-grow: 0;
}

.container--no-padding {
    padding: 0;
}

.circle-background {
    fill: none;
    stroke: var(--off-white-dk);
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.percentage {
    text-anchor: middle;
    font-size: 8px;
}
