

table.chat-table {

	tr {
		&.message-operator td.column-person,
		&.message-operator td.column-message, {
			color: @grey-dk;
		}
		&.message-visitor:first-child td.column-message { padding-right: 42px; }
		&.more td {
			text-align: center;
			color: @grey-lt;
			font-style: italic;
			padding-bottom: 0;
		}
	}

	td {
		font-size: 12px;
		padding: 3px 0;
	}
	td.column-person {
		vertical-align: top;
		width: 120px;
		padding: 3px 15px 3px 6px;
		font-weight: 500;
		text-align: right;
		div {
			padding-right: 2px;
			width: 120px;
			#util > .excerpt-crop > .ellipsis;
		}

	}
	td.column-message {
		min-width: 310px;
		max-width: 497px;
		white-space: normal;

		a { word-wrap: break-word; }
	}
	td.column-time {
		text-align: right;
		white-space: nowrap;
		color: @grey-lt;
		font-size: 11px;
		padding: 3px 0px 3px 6px;
	}
}
