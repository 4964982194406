.container {
    min-width: 88px;
    max-width: 100%;
}

.container--inline {
    composes: container;
    display: inline-block;
}

.container label {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
    color: var(--grey-dk);
}

.container label,
.container select {
    margin: 0;
    font: inherit;
    vertical-align: baseline;
}

.select-container {
    font-weight: normal;
    position: relative;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
}

.select {
    composes: border from '../border/border.css';
    -moz-appearance: none;
    -webkit-appearance: none;
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    padding: 3px 28px 3px 9px;
    font-size: var(--font-small);
    background: #fff;
    color: var(--grey);
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    height: 34px;
    margin-bottom: 8px;
}

.arrow {
    display: block;
    position: absolute;
    z-index: 2;
    right: 12px;
    width: 8px;
    font-size: 11px;
    pointer-events: none;
    color: var(--grey);
}

.arrowUp {
    composes: arrow;
    top: 8px;
}

.arrowDown {
    composes: arrow;
    bottom: 8px;
}

.select--tiny {
    height: 24px;
}

.arrowUp--tiny {
    top: 2px;
    z-index: unset;
}

.arrowDown--tiny {
    bottom: 2px;
    z-index: unset;
}
