.chat-preview-container {
    display: flex;
    flex-direction: column;
    width: 305px;
    height: 400px;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.chat-header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.chat-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 12px;
    background-color: var(--white);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.icon-avatar-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    /* match width of chat-preview-container */
    width: 305px;
}

.popover-text {
    max-width: 150px;
    padding: 16px;
}

.footer-content {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15);
}

.chat-bubble {
    padding: 8px;
    border-radius: 8px;
    width: fit-content;
    text-align: left;
}

.chat-bubble--incoming {
    composes: chat-bubble;
    background-color: var(--button-secondary);
}

.chat-bubble--outgoing {
    composes: chat-bubble;
    background-color: var(--off-white);
}

.offline-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    background-color: var(--off-white);
    height: 160px;
    margin-bottom: 16px;
}

.icon-avatar {
    font-size: 30px;
}
