.menu {
    position: fixed;
    left: 64px;
    top: 0;
    width: calc(100% - 64px);
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 150;
}

.menu--with-trial-status-bar {
    top: 45px;
    height: calc(100% - 45px);
}

.menu--widebar {
    left: 200px;
    width: calc(100% - 200px);
}

.menu--visible {
    pointer-events: auto;
}

.app-menu {
    background-color: #fff;
    position: relative;
    width: 300px;
    height: 100vh;
    transform: translateX(-103%);
    display: flex;
    flex-direction: column;
    will-change: transform;
    z-index: 160;
    pointer-events: auto;
}

.app-menu--with-trial-status-bar {
    height: calc(100vh - 45px);
}

.app-menu--wide {
    composes: app-menu;
    width: 368px;
}

.menu--visible .app-menu {
    transform: none;
}

.menu--animatable .app-menu {
    transition: all 150ms ease-in;
}

.menu--visible.menu--animatable .app-menu {
    transition: all 220ms ease-out;
}

.menu:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-modal-overlay);
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.menu--visible.menu:after {
    opacity: 1;
    pointer-events: auto;
}
