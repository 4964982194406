.grid {
    display: grid;
    grid-template-columns: 200px 1fr;
    row-gap: 4px;
}

.container {
    composes: grid;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    grid-column: 1 / 3;
}

.textfield {
    border-left: 1px solid var(--off-white-dk);
}

.error-message {
    grid-column: 2 / 3;
    padding-left: 8px;
}
