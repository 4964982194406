.done-container {
    margin-bottom: 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.done-button {
    margin-top: auto;
}
