.chart-error-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 16px;
}

.title {
    font-size: var(--font-huge);
}

.subtitle {
    margin-top: 24px;
    font-size: var(--font-normal);
    line-height: 21px;
    text-align: center;
}
