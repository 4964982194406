.newsletter-container {
    padding: 16px 16px 0 16px;
}

.container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    min-height: 250px;
}
