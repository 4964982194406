.prepare-to-activate-container {
    height: 100%;
    padding: 32px;
    box-sizing: border-box;
}

.drip-sequence-name {
    font-style: italic;
    font-weight: 600;
}

.content-container {
    margin-top: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.left-container {
    flex: 0 45%;
    padding: 16px;
    margin-right: 16px;
}

.right-container {
    flex: 0 55%;
    padding: 16px 0 16px 16px;
}
