.row-container {
    padding: 16px;
    margin: 16px 0 32px 0;
    border: 1px solid var(--off-white-dk);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: var(--grey-dk);
    border-radius: 3px;
    min-height: 41px; /* same as integration widget */
}

.spacer {
    height: 64px;
}

.connection-group {
    display: flex;
    align-items: center;
}

.logo {
    width: 28px;
}

.text-container {
    margin-left: 16px;
}

.bold-text {
    font-weight: 500;
    margin-bottom: 4px;
    font-size: var(--font-big);
}

.subtitle {
    font-size: var(--font-normal);
}

.saving-button-container {
    display: flex;
}
