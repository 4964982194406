.automated-email-paused-banner {
    width: 100%;
    background-color: #fff;
    padding: 16px 16px 0;
    box-sizing: border-box;
    display: flex;
}

.automated-email-paused-banner > * {
    width: 100%;
}

.text-container {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 16px;
}
