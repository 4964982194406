.pagination-controls-bar {
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
    background: white;
    border-bottom: 1px solid var(--off-white-dk);
    box-sizing: border-box;
}
