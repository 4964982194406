.container {
    position: relative;
    padding: 40px 24px 24px 24px;
}

.minimize {
    position: absolute;
    top: 12px;
    right: 26px;
}

.close {
    position: absolute;
    top: 18px;
    right: 20px;
}

.minimize > button,
.close > button {
    padding-right: 0;
}

.bookmark {
    position: absolute;
    top: 0;
    left: 24px;
}

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
}

.cards--column {
    margin-bottom: 8px;
}

.cards--column > * {
    width: 100%;
}

.cards--column > *:not(:first-child) {
    margin-top: 16px;
}

@media screen and (max-width: 915px) {
    .cards {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
    }
}
