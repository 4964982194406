.distribution-bar-container {
    display: flex;
    height: 72px;
    width: 100%;
    background-color: var(--white);
    overflow: hidden; /* Ensures the inner bars don't spill out of the container's rounded corners */
    border-radius: 3px;
    border: 1px solid var(--off-white-dk);
}

.bar-section {
    transition: width 0.3s ease;
    padding: 12px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
