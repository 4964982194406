.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-text {
    max-width: 400px;
    text-align: center;
}
