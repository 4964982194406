.sub-sidebar-header {
    position: relative;
    padding: 16px 24px;
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.15);
    text-align: center;
    height: 64px;
    box-sizing: border-box;
}

.sub-sidebar-header--no-shadow {
    composes: sub-sidebar-header;
    box-shadow: none;
}

.sub-sidebar-header button {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.header-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title {
    color: var(--grey-dk);
    font-size: var(--font-big);
    line-height: 22px;
    font-weight: 500;
}

.subtitle {
    color: var(--grey-lt);
    font-size: var(--font-small);
    line-height: 17px;
}
