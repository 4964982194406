.modal-content {
    height: 100%;
    /* Footer component adds 20px to bottom */
    padding: 20px 16px 0 16px;
    width: 400px;
    position: relative;
}

.helper-text {
    padding-top: 8px;
    color: var(--grey-lt);
}

/* The footer bar */
.modal-content > :last-child {
    height: unset;
    padding: 20px 0;
}

.error-text {
    padding-top: 8px;
    color: var(--rose-dk);
}
