.status-label {
    width: fit-content;
    min-width: 102px;
    display: flex;
    gap: 4px;
    position: relative;
    height: 32px;
}

.status-label:hover {
    cursor: pointer;
}

.status-label-content {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    z-index: 1;
    padding: 0 12px;
}

.background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    z-index: 0;
}

.background-wrapper--grey {
    color: var(--grey-lt);
}

.background-wrapper--blue {
    color: var(--blue);
}

.background-wrapper--green {
    color: var(--green);
}

.background-wrapper--disabled {
    color: var(--off-white-dk);
}
