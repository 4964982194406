.table-wrapper {
    border-radius: 3px;
}

.table-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--off-white-dk);
    padding-left: 8px;
    border: 1px solid var(--form-border);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.table-header--short {
    composes: table-header;
    padding: 0 0 0 12px;
}

.header-left {
    display: flex;
}

.title {
    font-weight: 500;
    font-size: var(--font-normal);
}

.count {
    color: var(--grey-lt);
    padding-left: 4px;
}

.help-button {
    display: flex;
    align-items: center;
}

.help-button > *:first-child {
    padding: 0 0 0 6px;
}

.action-menu {
    display: flex;
    align-items: center;
}
