.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid var(--off-white-dk);
    flex-shrink: 0;
}

.container-underlined {
    composes: container;
    border-bottom: none;
    /* Allow border radius to show in reports */
    /* This feels wrong but I don't know another way */
    background: none;
}
