.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    min-width: 180px;
}

.container > div {
    flex: 1 100%;
}

.container input {
    width: 42px;
    margin-right: 4px;
}
