.intro {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.intro svg {
    width: 80%;
}
