.text {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.text > div {
    white-space: nowrap;
    padding-left: 28px;
    width: max-content;
}

.text--closed {
    display: none;
}

.text--expand {
    animation: expand 0.7s forwards;
    overflow: hidden;
}

.text--closing {
    animation: contract 0.7s forwards;
    overflow: hidden;
}

@keyframes expand {
    from {
        clip-path: inset(0 0 0 30px);
        transform: translateX(-100%);
    }
    to {
        clip-path: inset(0 0 0 0);
        transform: translateX(0);
    }
}

@keyframes contract {
    from {
        clip-path: inset(0 0 0 0);
        transform: translateX(0);
    }
    to {
        clip-path: inset(0 0 0 30px);
        transform: translateX(-100%);
    }
}
