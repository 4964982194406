.container {
    height: calc(100% - 80px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.container > *:not(:first-child) {
    margin-top: 16px;
    box-sizing: border-box;
}

.table-container {
    overflow-y: auto;
    flex: 1;
    border: var(--border-hairline);
}

.table-container--loading {
    composes: table-container;
    border: none;
}
