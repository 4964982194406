.done-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
}

.left {
    background-color: var(--plum-lt);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
