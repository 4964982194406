.email-signature-container {
    display: flex;
    flex-direction: column;
    max-width: 685px;
}

.contents-container {
    border: 1px solid var(--off-white-md);
    height: 200px;
}

.save-button-container {
    margin-top: 16px;
    display: flex;
    align-self: flex-end;
}
