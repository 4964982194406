.add-inbox-group {
    margin-left: 28px;
    margin-bottom: 8px;
}

.status-dot-container {
    position: absolute;
    top: -4px;
    right: -4px;
}
