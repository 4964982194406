.list-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.list-header {
    composes: detail-name from '../../details-list/details-list.css';
    margin-bottom: 0;
}

.empty-state {
    width: 300px;
    margin: 24px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.empty-state .illustration {
    width: 60px;
}
