.container {
    position: relative;
    display: flex;
    align-items: center;
}

.percent {
    position: absolute;
    right: 8px;
}
