@value leadBarHeight: 100px;

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 10000;
    width: 100%;
    height: leadBarHeight;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
    border-top: 1px solid var(--off-white-dk);
    transform: translateY(leadBarHeight);
    box-shadow: 0 -1px 3px 1px rgba(0, 0, 0, 0.09);

    transition: transform 0.3s var(--custom-easing);
}

.container-open {
    composes: container;
    transform: translateY(0);
}

.button-section-right {
    display: flex;
    height: 100%;
}

.button-section-right > div:first-child {
    margin-right: 16px;
}
