.menu-styles {
    background-color: white;
    border-radius: 3px;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.1),
        0 4px 11px rgba(0, 0, 0, 0.1);
    margin-top: 8px;
    position: absolute;
    width: 275px;
    z-index: 10;
    padding: 16px;
}
