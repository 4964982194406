// allow chrome extensions (namely google voice) on top of the modal
iframe[src^='chrome-extension'] {
	z-index: 9005 !important;
}

.modal-opener-activity {
	cursor: pointer;
}

#modal-container {
	display: none;
	position: fixed;
	background-color: hsla(234, 18%, 25%, 0.67);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9000;

	#modal-loading {
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -21px 0 0 -21px;
		width: 42px;
		height: 42px;
		background: url('@{img-root}/loading/white-42.gif') center center no-repeat;
		display: none;
		opacity: 0;
		z-index: 9001;
	}

	#modal-wrapper {
		position: absolute;
		top: 50%;
		right: 50%;
		bottom: 50%;
		left: 50%;
		z-index: 9001;

		#modal {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			#modal-cards {
				position: relative;
				left: 0;
				height: 100%;

				.modal-card {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 100%;

					.modal-card-tab-body-wrapper {
						position: absolute;
						top: 30px;
						bottom: 0;
						left: 0;
						right: 0;
						display: none;

						.modal-card-tab-body {
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;
						}

						&.state-selected {
							display: block;
						}
					}
				}

				// loading card
				.modal-card-blank {
					.modal-card-tab-body-wrapper {
						display: block;
					}
				}
			}
		}
	}
}
.modal-card-body {
	background: white;
	padding: 16px 25px;
	overflow: auto;
	position: relative;
	border-top: none;
	border-bottom: none;
}

.modal-card-buttonbar {
	color: @grey;
	background-color: @off-white;
	border-top: 1px solid @off-white-dk;
	#util > .rounded-corners(2px, bottom);
	padding: 6px 12px;
	min-height: 34px;
	#util > .clearfix;

	.small-left {
		display: inline-block;
		margin-right: 5px;
		font-size: @small;
		max-width: 290px;
		#util > .excerpt-crop > .ellipsis;

		&.needs-admin {
			padding: 6px 0;
		}
	}
	a.action-undelete,
	a.action-delete {
		color: @rose;
		&:hover {
			text-decoration: underline;
		}
	}
	.status-deleted {
		color: @rose;
		font-weight: 500;
		font-size: 12px;
	}

	a {
		font-size: @small;
		display: inline-block;
	}

	div.buttons-left {
		text-align: left;
		vertical-align: top;
		display: inline-block;
		a.button-rounder {
			margin: 4px 5px 0 0;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}

	div.buttons-right {
		float: right;
		vertical-align: middle;
		display: inline-block;
		a.button-rounder {
			margin: 4px 0 0 5px;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
}

.modal-card-header {
	color: @grey;
	#util > .clearfix;
	background-color: @table-header-background;
	border-bottom: 1px solid @table-header-border;
	#util > .rounded-corners(3px, top);
	position: relative;
	z-index: 1;
	min-height: 54px;
	max-height: 54px;
	padding: 0 16px;

	&.tall {
		max-height: 72px;
		.activity-name {
			height: 72px;
		}
	}

	.modal-close {
		display: flex;
		align-items: center;
		position: absolute;
		right: 16px;
		width: 25px;
		font-weight: 500;
		font-size: 35px;
		line-height: 12px;
		color: @grey;
		cursor: pointer;
		text-align: center;

		&:hover {
			color: @grey-dk;
		}
	}

	.control.behavior-dupecheck {
		top: -12px;
		z-index: 4; // above upblock
		right: 25px;
		color: white;
		display: none;
		font-size: 13px;
		font-weight: 500;
		line-height: 13px;
		margin-right: 9px;
		position: absolute;
		background: url('@{img-root}/dupe/dupe-big-left.png') left top no-repeat;

		span {
			display: block;
			padding: 5px 10px;
			margin-right: -9px;
			background: url('@{img-root}/dupe/dupe-big-right.png') right top no-repeat;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.title-container {
		display: flex;
		align-items: center;
	}

	// h2
	.modal-card-title {
		cursor: default;
		font-weight: 500;
		color: @grey-dk;
		max-width: 460px;
		padding: 16px 0;
		#util > .inline-block;

		&.no-padding {
			padding: 0;
		}

		span {
			#util > .excerpt-crop > .ellipsis;
			display: block;
			padding: 3px 0;
			max-width: 450px;
		}
	}

	.modal-card-header-info {
		padding: 15px 15px 0 0;
		color: @grey;
		float: right;
	}

	.modal-card-header-upblock {
		display: flex;
		align-items: center;
		border-left: 1px solid @off-white-dk;
		width: 200px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 3; // above account tiles 2, so that dropdown isn't obscured by them

		label {
			display: block;
			padding-top: 4px;
			padding-bottom: 2px;
			height: 11px;
			font-size: 11px;
			color: @grey; /* TODO: standardize color */
		}
		div.value,
		div.select-mini span.uniformSpan {
			color: black;
			font-weight: 500;
			max-width: 145px;
			display: block;
			#util > .excerpt-crop > .ellipsis;
		}
	}

	.modal-card-subtitle {
		width: 520px;
		font-size: 12px;
		color: @grey; // same as select-mini /* TODO: standardize color */

		.description {
			position: absolute;
			font-size: 11px;
			width: 510px;
			padding: 2px;
			#util > .excerpt-crop > .ellipsis;
			&:hover {
				background: @yellow-lt;
			}
			input {
				width: 510px;
			}

			&.description-half {
				width: 300px;
				input {
					width: 300px;
				}
			}
			.empty {
				font-style: italic;
				color: @grey-lt;
			}
		}
		.right-minis {
			white-space: nowrap;
			float: right;
			width: 220px;
			text-align: right;
			.select-mini {
				#util > .inline-block;
				max-width: 130px;
				div.selector {
					font-size: 11px;
				}
			}
		}
	}

	.eip-container {
		.modal-card-title {
			cursor: text;

			&:hover span {
				background: @yellow-lt;
			}

			input {
				position: relative;
				top: -3px;
				font-size: @normal;
				background: rgba(255, 255, 255, 0.9);
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(255, 255, 255, 0.9);
				border: 2px solid lighten(@off-white-dk, 5%);
				border-radius: 3px;
				margin: 0;
				width: 380px;
				padding: 3px 6px;
				color: @grey;
				margin: 0;
				&:hover {
					border: 2px solid @off-white-dk;
				}
				&:focus {
					outline: none;
					background: white;
					border: 2px solid @grey-lt;
					box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08),
						0 0 0 2px darken(@peach-lt, 10%);
				}
			}
		}
		.eip-buttons {
			position: absolute;
			top: 8px;
			left: 430px;
			width: 110px;
			line-height: normal;
			text-align: left;

			.button-interaction {
				margin: 0 4px 0 0;
			}
		}
		.icon {
			#util > .inline-block;
			vertical-align: -4px;
		}
		.icon.action-edit {
			margin-left: 6px;
			#icon > .modal-name-edit;
			width: 16px;
			height: 16px;
			vertical-align: -1px;
			&:hover {
				#icon > .modal-name-edit.state-hover;
			}
			&:active {
				#icon > .modal-name-edit.state-active;
			}
		}
		&.state-editing {
			.modal-card-title:hover {
				background-color: transparent !important;
			}
			.icon.action-edit,
			.icon.action-delete {
				display: none;
			}
		}
		&.blink-success,
		&.blink-success .modal-card-title {
			color: @green;
		}
		&.state-hover .modal-card-title span {
			background-color: @yellow-lt;
		}
	}
}
.browser-ie .modal-card-header {
	background: url('@{img-root}/modal-header-gradient.png') repeat-x;
}

.modal-card-tab-body-wrapper.state-deleted {
	.modal-card-header .modal-card-title span {
		text-decoration: line-through;
	}
	.modal-card-buttonbar span.creator {
		display: none;
	}
}

/* common front-tab sections (modalCore.phtml) */
.modal-tilearea {
	float: left;
	width: 325px;

	ul.fieldblock {
		// moved to field.less
		li.fieldrow {
			width: 325px;
		}
	}

	.button-add-link {
		// also a .button-add-link
		line-height: 150%;
		margin-left: 25%;
		padding-left: 10px;
		color: @grey-lt;
		text-shadow: none;
	}
	.empty-placeholder {
		margin: 0 auto;
	}
}

.modal-tilearea-right {
	float: right;
}

.modal-address-area {
	clear: left;
}
