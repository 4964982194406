@value item-spacing: 12px;

.categorized-select-list {
    height: 100%;
    display: flex;
    gap: 16px;
}

.list-selection {
    position: relative;
    height: 100%;
    flex: 0 0 280px;
    overflow-y: auto;
}

.list-label {
    margin-top: 32px;
    color: var(--grey-dk);
    font-size: var(--font-normal);
}

.list-item:not(:last-child),
.list-item > *:not(:last-child) {
    margin-bottom: item-spacing;
}

.list-item-selection {
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    background-color: var(--off-white);
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    overflow-y: auto;
}
