.modal-container {
    position: fixed;
    top: 56px;
    left: 50%;
    width: 470px;
    margin-left: -260px;
    padding: 16px;
    background-color: var(--off-white);
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.outer-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}

.zoom-calls-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.note-textarea-container {
    height: 100px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid var(--off-white-dk);
    padding: 12px;
    transition:
        border-color 0.2s ease,
        box-shadow 0.2s ease;
}

.note-textarea-container:focus-within {
    border-color: var(--blue);
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.3);
    outline: none;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.title-container {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--off-white-dk);
    width: calc(100% - 24px);
    padding: 12px;
    align-items: center;
    display: flex;
    border-radius: 3px 3px 0 0;
    z-index: 1;
    justify-content: space-between;
}

.title-button-container {
    margin-left: 8px;
    text-wrap: nowrap;
}

.sticky-footer {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    padding: 12px 16px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.15);
}

.content-container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: calc(100% - 60px);
    padding: 16px;
}
