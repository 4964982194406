.container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.content {
    border: none;
    width: 100%;
    height: 100%;
}
