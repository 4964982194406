.container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: calc(100% - 82px);
}

.left-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
}

.right-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
}

.right-container > * {
    width: 100%;
}

.footer-container {
    z-index: 10;
}
