.kibana-link {
  &:not(:last-of-type) {
    margin-right: 7px;
  }

  img {
    padding: 3px;
    border: 2px solid gray;
  }

  &.errors {
    img {
      border: 2px solid red;
    }
  }
}