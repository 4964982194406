.container {
    display: flex;
    padding: 16px;
}

.container--ignored {
    composes: container;
    opacity: 0.6;
}

.container > * {
    margin-right: 8px;
}

.container > *:last-child {
    margin-right: 0;
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    width: 100%;
    background-color: var(--off-white);
    color: var(--grey);
    font-style: italic;
}

.loading {
    composes: empty;
    background-color: var(--white);
}
