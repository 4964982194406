body {
	font-family: @body-font;
	font-size: @small;
	line-height: 17px;
	color: @grey;

	&.no-scroll {
		overflow: hidden !important;
	}
}

// these need to be two separate declarations, because of css parsing idiosyncracies https://developer.mozilla.org/en-US/docs/Web/CSS/::selection
::-moz-selection {
	background: @blue-lt;
	color: @blue-dk;
	text-shadow: none;
}
::selection {
	background: @blue-lt;
	color: @blue-dk;
	text-shadow: none;
}

a:focus,
a:active,
a:hover {
	outline: 0;
}

// reset-esque stuff that isn't in normalize.css
ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

// nobody wants your fucking spinners, HTML5 number inputs
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}

/* ============== */
/* = typography = */
/* ============== */
a {
	color: inherit;
	text-decoration: none;
}
.underline {
	text-decoration: underline;
}
.hover-underline:hover {
	text-decoration: underline;
}
.center-content {
	margin: 0 auto;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.clearfix {
	#util > .clearfix();
}

.link-blue-standard {
	color: @blue;
	&:hover {
		text-decoration: underline;
		color: @blue-dk;
	}
}

.link-grey-standard {
	color: @grey;
	&:hover {
		text-decoration: underline;
		color: @grey-dk;
	}
}

.link-small-underlined {
	font-size: @tiny;
	color: @grey-lt;
	text-decoration: underline;

	&:hover {
		color: @grey;
	}
}

h1.styled {
	font-size: 42px;
	font-family: @headline-font;
	line-height: 50px;
	font-weight: normal;
	letter-spacing: 1px;
	text-shadow: 1px 1px 0 @off-white, 2px 3px 0 @peach; /* TODO: standardize color */
	color: @grey-dk;
	margin: 12px 0 10px 0;
}
h2.styled {
	font-size: 22px;
	line-height: 31px;
	font-weight: normal;
	letter-spacing: 1px;
	color: @grey;
	margin: 10px 0 8px 0;
}
// Section headline label
h2.section-label {
	font-size: 12px;
	line-height: 21px;
	font-weight: 500;
	padding-bottom: 6px;
	color: @grey;
	border-bottom: 1px solid @off-white-md;
	text-transform: uppercase;
	&.variant-small {
		// e.g. when in sidebars
		// used to be smaller font-size, nothing different these days
	}

	a {
		text-transform: none;
	}
}
// these were previously in reset.css, and we've come to depend on them :\
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
	font-weight: 500;
}

// not very centralized yet :(
h6 {
	// just a bold, block element
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
	padding-top: 8px;
}

button {
	color: inherit; /* 1 */
	font: inherit; /* 2 */
	margin: 0; /* 3 */
	overflow: visible;
	text-transform: none;
	cursor: pointer;
	border: 0;
	background: none;
	outline: none;
}

/* all icons */
.avatar {
	width: 48px;
	height: 48px;
}
.avatar.avatar-assignee {
	#icon > .avatar-assignee;
}
.avatar.avatar-user {
	#icon > .avatar-user;
}
.avatar.avatar-team {
	#icon > .avatar-team;
}
.avatar.avatar-product {
	#icon > .avatar-product;
}
.avatar.avatar-competitor {
	#icon > .avatar-competitor;
}
.avatar.avatar-source {
	#icon > .avatar-source;
}

.hidden,
.js-confirm,
.repeatable-blank,
.l10n {
	display: none;
}

.clearer {
	clear: both;
	height: 0;
}

.icon {
	background-repeat: no-repeat;
}

div.hr {
	margin: 12px 0;
	width: 100%;
	height: 1px;
	border-top: 1px solid @off-white-dk;
	hr {
		display: none;
	}
}

hr.hr {
	float: left;
	width: 100%;
	height: 6px;
	border: none;
	border-top: 1px solid @off-white-dk;
	margin: 8px 0 8px 0;
	padding: 0;
}

.icon.icon-help {
	.layout-sprite;
	background-position: -29px -233px;
	display: inline-block;
	width: 22px;
	height: 22px;
	margin: 0 4px -5px 4px;
	opacity: 0.5;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
	&.help-per-row {
		margin: -5px 4px -6px 5px;
	}
}

/* ========================== */
/* = green / red percentage = */
/* ========================== */
.percentage {
}
.percentage-increase,
.percentage-over {
	color: @green;
}
.percentage-decrease,
.percentage-under {
	color: @rose;
}
.percentage-zero {
	color: @grey;
}

.excerpt-crop {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* ================ */
/* = content tabs = */
/* ================ */
nav.content-tabs {
	li {
		float: left;
		margin: 0 5px 0 0;

		a {
			font-size: @normal;
			font-weight: 500;
			color: @grey;
			background-color: @off-white-md;
			border-radius: 3px 3px 0 0;
			position: relative;
			display: block;
			padding: 8px 15px;
			&:hover {
				color: @grey-dk;
				background: @off-white-dk;
			}
		}
		&.state-selected {
			a {
				color: white;
				background: @grey;
				z-index: 21; // so it can sit over the border of the interactive-table-header
			}
		}
	}
}

/* =================== */
/* = section headers = */
/* =================== */

div.section-header,
tr.section-header th,
tr.section-header td {
	background: @grey-lt;
	border-top: 1px solid @grey;
	border-bottom: 1px solid @grey !important;
	color: white;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
	padding: 0 15px;
	height: 30px;
	line-height: 30px;

	.percentage {
		text-shadow: none;
		font-weight: normal;
	}
}
div.section-header.state-highlighted,
tr.section-header.state-highlighted th {
	// currently ugly.
	background: -webkit-gradient(linear, left top, left bottom, from(@grey-dk), to(@grey));
}

// indicator-percent
// tightly coupled to nut_view_helper_indicatorpercent

.indicator-percent {
	width: 100%;
	height: 5px;
	.under100,
	.at100,
	.over100,
	.mercury {
		float: left;
		height: 5px;
	}
	.under100 {
		width: 80%;
	}
	.at100 {
		background-color: white;
		width: 1px;
	}
	.over100 {
		width: 19%;
	}
}
.indicator-percent.color-red {
	.under100 {
		background-color: @off-white-md;
	}
	.mercury {
		background-color: @rose;
	}
}
.indicator-percent.color-green {
	.under100 {
		background-color: @off-white-md;
		.mercury {
			background-color: @green;
		}
	}
	.over100 .mercury {
		background-color: @green-dk;
	}
}
.indicator-percent.percent-10 .under100 .mercury {
	width: 10%;
}
.indicator-percent.percent-20 .under100 .mercury {
	width: 20%;
}
.indicator-percent.percent-30 .under100 .mercury {
	width: 30%;
}
.indicator-percent.percent-40 .under100 .mercury {
	width: 40%;
}
.indicator-percent.percent-50 .under100 .mercury {
	width: 50%;
}
.indicator-percent.percent-60 .under100 .mercury {
	width: 60%;
}
.indicator-percent.percent-70 .under100 .mercury {
	width: 70%;
}
.indicator-percent.percent-80 .under100 .mercury {
	width: 80%;
}
.indicator-percent.percent-90 .under100 .mercury {
	width: 90%;
}
.indicator-percent.percent-100 .under100 .mercury {
	width: 100%;
}
.indicator-percent.percent-110,
.indicator-percent.percent-120,
.indicator-percent.percent-130,
.indicator-percent.percent-140,
.indicator-percent.percent-150 {
	.under100 .mercury {
		width: 100%;
	}
}
.indicator-percent.percent-110 .over100 .mercury {
	width: 20%;
}
.indicator-percent.percent-120 .over100 .mercury {
	width: 40%;
}
.indicator-percent.percent-130 .over100 .mercury {
	width: 60%;
}
.indicator-percent.percent-140 .over100 .mercury {
	width: 80%;
}
.indicator-percent.percent-150 .over100 .mercury {
	width: 100%;
}

// loading sprite

#loading-hud {
	z-index: 10000; // above modals
	display: none;
	position: fixed;
	top: 40%;
	left: 45%;
	background: url('@{img-root}/loading/background.png') center center no-repeat;
	width: 92px;
	height: 92px;
	padding-left: 28px;
	padding-top: 28px;
}

.loading-sprite {
	// also appears in popover loaders
	background: url('@{img-root}/loading/white-66-sprite.png') 0 0 no-repeat;
	background-position: 0px 0px;
	width: 66px;
	height: 66px;
}

// empty-placeholder (adding cards, modal fields)

div.empty-placeholder {
	cursor: pointer;
	background-color: @blue-bg;
	border-radius: 3px;
	text-align: center;
	color: @blue;
	#util > .excerpt-crop > .ellipsis;
	padding: 4px 0;
	&:hover {
		background-color: darken(@blue-bg, 3%);
	}
	.plus {
		margin-right: 4px;
	}
	&.type-account {
		color: @plum-dk;
		background-color: @plum-bg;
		&:hover {
			background-color: darken(@plum-bg, 3%);
		}
	}
	&.type-contact {
		color: @navy-dk;
		background-color: @navy-bg;
		&:hover {
			background-color: darken(@navy-bg, 3%);
		}
	}
	&.type-product {
		color: @grey-dk;
		background-color: @brown-lt;
		&:hover {
			background-color: darken(@brown-lt, 5%);
		}
	}
	&.type-competitor {
		color: @grey-dk;
		background-color: @rose-lt;
		&:hover {
			background-color: darken(@rose-lt, 6%);
		}
	}
}

.browser-ff,
.browser-ie {
	div.empty-placeholder .plus {
		margin-left: 6px;
	}
}

// entity lists
.peep-list-item {
	p {
		margin: 0 0 0 6px;
		color: @grey-lt;
		line-height: 15px;
		#util > .excerpt-crop > .ellipsis;
	}
	div {
		float: left;
	}

	.avatar {
		width: 36px;
		height: 36px;
		border-radius: 18px;
		background: @navy url('@{img-root}/layout/avatar-person.svg') no-repeat 50% 100%;
		background-size: 90%;
		img {
			width: 36px;
			height: 36px;
		}
	}

	.primary-info {
		max-width: 160px;
	}
	.name {
		color: @navy-dk;
	}
	.description {
		padding-left: 0.5em;
		color: @grey;
	}

	.secondary-info {
		width: 110px;
	}
	.line-1 {
	}
	.line-2 {
	}

	.extended-info {
	}
	.activity {
	}
	.timestamp {
	}
}

// flash errors / successes
div.flash-message {
	font-size: @normal;
	color: @green-dk;
	background-color: @green-lt;
	border-radius: 3px;
	margin-bottom: 12px;
	padding: 8px 10px;

	&.flash-message-error {
		color: @rose;
		background-color: @rose-lt;
	}
	&.flash-message-info {
		background-color: @blue-lt;
		color: @blue-dk;
	}

	a {
		color: @blue;
		text-decoration: underline;
	}

	h3 {
		margin-bottom: 8px;
	}
}

#timeline-index {
	.timeline-error-container {
		text-align: center;
		position: absolute;
		top: 30px;
		width: 100%;
	}
	.timeline-error {
		font-family: @headline-font;
		color: @grey;
		font-size: 30px;
		line-height: 45px;
	}
	.error-graphic {
		padding-top: 135px;
		text-align: center;
	}
}

div.notify-message {
	position: relative;
	padding: 10px;
	margin-bottom: 20px;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
	border: 1px solid @green;
	#util > .rounded-corners(2px);
	background-color: @green-lt;
	box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.85), 0 1px 1px rgba(0, 0, 0, 0.08);
	p,
	ul {
		margin: 0 0 6px 0;
	}
	.activity-sync-buttons {
		margin: 8px 0 2px 0;

		// a
		.button-action {
			margin: 0 4px 0 0;
		}
	}

	&.notify-error {
		border: 1px solid @rose;
		background-color: @rose-lt;
	}

	&.notify-exports-queued {
		border: 1px solid @off-white-dk;
		background-color: @off-white;
	}

	a {
		font-weight: normal;
		color: @blue;
		text-decoration: underline;
		&:hover {
			color: @blue-dk;
		}
	}
	a.hide-message {
		position: absolute;
		right: 20px;
		top: 8px;
		text-decoration: none;
		color: @blue;
		opacity: 0.9;
		font-size: 12px;
		font-weight: normal;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

// modal geometry trickery + ui datepicker's shitty built-in positioning code crush each other
#ui-datepicker-div {
	position: absolute !important;
}

ul.arrow-list {
	li {
		line-height: 20px;
		&:before {
			content: '› \0020';
			font-weight: 500;
			padding-right: 2px;
		}
		a:hover {
			text-decoration: underline;
		}
	}
}

.entity-relationship {
	// see entityrelationships view helper
	display: block;
	max-width: 180px;
	#util > .excerpt-crop > .ellipsis;
	line-height: 20px;
	color: @grey-dk;

	.icon {
		img {
			height: 16px;
			width: 16px;
		}
		height: 16px;
		width: 16px;
		vertical-align: -15%;
		border-radius: 8px;
	}
	.pie {
		vertical-align: top;
	}
	.icon,
	.pie {
		#util > .inline-block;
		margin-right: 2px;
	}

	&.contact {
		.icon {
			#icon > .generic-contact;
		}
		color: @navy-dk;
	}
	&.account {
		.icon {
			#icon > .generic-account;
		}
		color: @plum-dk;
	}
	&.lead {
		color: @green-dk;
	}
	&.user {
		color: @orange-dk;
	}
}
a.entity-relationship {
	&:hover,
	&:focus {
		span {
			text-decoration: underline;
		}
	}
}

.uv-popover {
	position: fixed !important;
}

#dancing-ian {
	display: none;
}
