.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    color: var(--grey);
}

.container > * {
    display: flex;
    align-items: center;
    gap: 6px;
}

.container > * > :first-child {
    color: var(--color-success);
}

.container .missing,
.container .missing > :first-child {
    color: var(--icon-primary-action);
}
