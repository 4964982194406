@value column: './visual-pipeline-column.css';
@value columnHeaderHeight, columnWidth from column;

.drop-target {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    position: relative;
}

.drop-target-hover-overlay {
    position: absolute;
    height: calc(100% - 8px);
    box-sizing: border-box;
    border-radius: 3px;
    z-index: 100;
    top: 4px;
    left: 8px;
    width: calc(100% - 16px);
    background-color: var(--off-white);
    border: solid 2px var(--blue);
}
