#myaccount .myaccount-submenu {
	background-color: @off-white-bg;
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1), 0 1px 1px 1px white;
	border: 1px solid @grey-lt;
	border-radius: 3px;
	margin-bottom: 30px;
	position: relative;
	overflow: hidden;

	li {
		position: relative;
		border-top: 1px solid @grey-lt;

		&:first-child {
			border-top: none;
		}
		&:hover {
			background-color: rgba(255, 255, 255, 0.7);
		}
		> a {
			#util > .border-box;
			border-left: 5px solid transparent;
			display: block;
			padding: 15px 20px;
			width: 100%;
			font-size: @normal;
			font-weight: 500;
			color: @grey-lt;
			&:hover {
				color: @blue;
				border-left: 5px solid @blue-bg;
			}
		}
	}
}
