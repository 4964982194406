.row-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--grey);
    width: 100%;
}

.row-container--disabled {
    composes: row-container;
    color: var(--grey-lt);
}

.title {
    font-size: var(--font-normal);
}

.icon {
    margin-right: 6px;
    display: flex;
    align-items: center;
}

.created-time {
    font-size: var(--font-small);
    color: var(--grey);
    flex-shrink: 0;
}
