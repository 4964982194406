.drip-sequence-preview {
    display: flex;
    flex-direction: row;
    padding: 8px 16px 24px 16px;
}

.drip-sequence-preview > * {
    margin-right: 8px;
}

/* Want no right margin for last child */
.drip-sequence-preview > *:last-child {
    margin-right: 0;
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    width: 100%;
    background-color: var(--off-white);
    color: var(--grey);
    font-style: italic;
}
