.portraits {
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
}

.active-now {
    background: var(--green);
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    width: 8px;
}

@media screen and (max-width: 1300px) {
    .have-questions {
        display: none;
    }
}
