.modal-content {
    position: relative;
    height: 100%;
    width: 100%;
}

/* The option cards list */
.options-container {
    margin: 16px 0;
}

.options-container > * {
    margin-bottom: 16px;
    width: 100%;
}

.option-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    text-align: left;
}

/* Option card icon, title, subtitle */
.option-card > :first-child {
    display: flex;
    align-items: center;
}

.icon-container {
    padding-right: 20px;
}

.error {
    color: var(--rose);
}

/* The modal footer */
.modal-content > :last-child {
    position: absolute;
    bottom: 0;
    width: 100%;
}
