@import 'include';
@import 'ui/global/_colors';

.report-page-container {
	display: flex;
	height: 100%;
	width: 100%;
	padding-left: 0;
	padding-top: 0;
	box-sizing: border-box;
}

.routed-report-content,
.report-page-content-container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	min-width: 700px;

	.interactive-table {
		margin-top: 0;
	}

	.interactive-table-table-container {
		overflow: auto;
		height: 100%;
	}
}

.routed-report-inner-container {
	background-color: @off-white;
	height: ~'calc(100% - 145px)';
	position: relative;
}

.routed-report-inner-container--funnel {
	height: ~'calc(100% - 74px)';
	position: relative;
	overflow-y: auto;
	background-color: @off-white-bg;
	border-top: 1px solid @off-white-bg;
}

#reports-analytics {
	width: 100%;
	height: 100%;

	.page-flow-area {
		float: none;
		padding-left: 0;
		width: 100%;
		height: 100%;
	}

	#report-header-toolbar {
		display: flex;
		justify-content: space-between;

		border-top: none;
		border-left: none;
		border-bottom: 1px solid @off-white-dk;
		border-radius: 4px 0 0 0;
		padding: 8px 16px;
		background-color: white;
		h3 {
			line-height: 26px;
		}
	}
	.title-container {
		display: flex;
		flex-grow: 1;
		align-items: center;

		.report-description {
			font-size: @small;
			line-height: 21px;
			font-weight: normal;
			font-style: italic;
			color: @grey;
		}

		svg {
			float: left;
			at: left;
			height: 42px;
			fill: @grey-dk;
			padding-right: 10px;
		}
	}
	#report-header-options {
		background: none;
		display: inline-block;
		float: right;
		padding: 0;
		margin: 10px 0;
		.select2-wrapper {
			display: inline-block;
			width: 200px;
		}
		.group {
			#util > .inline-block;
			margin: 0 0 0 8px;
			&:last-child {
				margin-right: 0;
			}
			.select2-container .select2-choice {
				// Tweak select2 to match neighbouring buttons
				padding: 5px 10px;
				position: relative;
				top: -1px;
				.select2-search-choice-close {
					top: 6px;
				}
			}
		}
	}
}

// header toolbar / options
#report-header-toolbar {
	#util > .clearfix;
	padding: 10px 16px;
	border-bottom: 2px solid @off-white-md;

	h3 {
		font-size: 21px;
		line-height: 32px;
		color: @grey-dk;
		float: left;
		max-width: 70%;
	}
}
