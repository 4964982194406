.column-header-reorderable {
    font-weight: 500;
    position: relative;
    width: 100%;
    text-align: center;
}

.text {
    padding: 0 16px;
}

.icon {
    width: fit-content;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.column-header-reorderable button {
    padding: 0;
}

.column-header-reorderable .icon {
    display: none;
    padding: 2px 4px;
}

.column-header-reorderable:hover .icon {
    display: initial;
    color: var(--icon-primary-action);
}

.column-header-reorderable:hover .icon:hover {
    color: var(--grey);
}

.left-icon {
    composes: icon;
    left: 0;
}

.right-icon,
.lock-icon {
    composes: icon;
}

.left-icon,
.right-icon {
    margin-top: 1px;
}
