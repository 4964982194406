.container {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

.container--overflow-visible {
    overflow: visible;
}

.left-container {
    width: 60%;
    height: 100%;
    padding: 16px 15px 16px 16px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
}

.left-container--without-outer-padding {
    composes: left-container;
    padding: 0 15px 0 0;
}

.left-container--overflow-visible {
    composes: left-container;
    overflow: visible;
}

.right-container {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* Since both the left and right have padding, this makes it so we don't
    have a ton of padding between them */
    padding: 16px 16px 16px 1px; /* 1px left padding to avoid dropdown clipping */
    overflow: auto;
}

.right-container--without-outer-padding {
    composes: right-container;
    padding: 0 0 0 1px; /* 1px left padding to avoid dropdown clipping */
}

.right-container--overflow-visible {
    composes: right-container;
    overflow: visible;
}
