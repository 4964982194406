/* sucky styles to make the anchor tag take up the entire action menu div */
.action-menu-item {
    width: 100%;
    margin: -11.5px -16px;
    padding: 11.5px 16px;
}

.export-download-container {
    position: fixed;
    bottom: 16px;
    right: 16px;
    margin-right: 0;
    z-index: 1000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.export-download-container > div {
    margin-right: 0;
}
