.meta {
    display: flex;
    flex: 1;
}

.avatar {
    margin-right: 16px;
}

.text {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
}

.title {
    font-size: var(--font-normal);
    line-height: 19px;
    color: var(--grey-dk);
    margin-bottom: 4px;
}

.description {
    font-size: var(--font-small);
    line-height: 19px;
    color: var(--grey-lt);
}
