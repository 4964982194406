.form-field {
    margin-bottom: 24px;
}

.form-field--with-footer {
    composes: form-field;
}

.form-field--with-footer > *:nth-last-child(2) {
    margin-bottom: 8px;
}

.form-field--with-footer--no-label > *:nth-child(1) {
    margin-bottom: 8px;
}

.form-field--no-margin {
    margin: 0;
}

.form-field--full-width {
    width: 100%;
}
