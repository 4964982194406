.lead-chevron-container {
    position: relative;
    background-color: var(--lead);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--white);
}

.lead-chevron-container:hover {
    cursor: default;
}

.count-container {
    position: absolute;
    left: 0;
    top: 0;
    color: var(--lead);
    display: flex;
    align-items: center;
    justify-content: center;
}
