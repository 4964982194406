.container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.image {
    max-width: 200px;
    object-fit: contain;
}
