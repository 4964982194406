.formatted-value {
    display: flex;
    align-items: center;
}

.prefix {
}

.value {
    padding-left: 0.1em;
    padding-right: 0.1em;
}

.suffix {
}
