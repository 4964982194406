.container {
    font-size: var(--font-normal);
}

.empty-state-container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.empty-state-container > * {
    margin-bottom: 48px;
}
