.ui-radio-group {
	display: inline-block;
	> li {
		float: left;
		list-style-type: none;
		margin-right: 2px;

		&:first-of-type label { border-radius: 3px 0 0 3px; }
		&:last-of-type label { border-radius: 0 3px 3px 0; }

		input[type=radio] {
			display: none;
			&:checked + label {
				color: white;
				background-color: @grey-lt;
			}
		}
		label {
			display: block;
			min-width: 44px;
			cursor: pointer;
			border-radius: 0;
			border: 1px solid transparent;
			background-color: @off-white-md;
			color: @grey-lt;
			text-align: center;
			box-shadow: none;
			padding: 4px 10px;
			#util.transition(all, 250ms, @customEasing);
		}
	}
}

.ui-radio-group {
	.ui-radio-btn--type-standard {
		cursor: pointer;
		margin-bottom: 8px;

		input[type=radio] {
			cursor: pointer;
			vertical-align: baseline;
		}

		label {
			cursor: pointer;
			padding-left: 10px;
		}

		input[type=radio]:checked + label { color: @grey-dk; }
	}

	&.ui-radio-group--inline {
		.ui-radio-btn--type-standard {
			display: inline;
			label { padding-left: 6px; }
		}
	}
}

.ui-radio-group {
	display: inline-block;
	.ui-radio-btn--type-div {
		float: left;
		margin-right: 2px;

		&:first-of-type label { border-radius: 3px 0 0 3px; }
		&:last-of-type label { border-radius: 0 3px 3px 0; }

		input[type=radio] {
			display: none;
			&:checked + label {
				color: white;
				background-color: @grey-lt;
			}
		}
		label {
			display: block;
			min-width: 44px;
			cursor: pointer;
			border-radius: 0;
			border: 1px solid transparent;
			background-color: @off-white-md;
			color: @grey-lt;
			text-align: center;
			box-shadow: none;
			padding: 5px 10px 4px 10px;
			margin: 0;
			line-height: inherit;
			font-weight: normal;
			#util.transition(all, 250ms, @customEasing);
		}
	}
}
