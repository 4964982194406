@header-height: 50px;
@onboarding-footer-height: 90px;
@onboard-progress-height-lg: 60px;
@onboard-progress-height-md: 55px;
@onboard-progress-height-sm: 34px;

#page-content {
	position: relative;
	z-index: 4;
	width: 100%;
	flex: 1 1 auto;
	min-width: 900px;
	margin: 0 auto;
	padding: 20px 16px;
	background-color: white;
	border-left: @sidebar-width solid transparent;
	border-left-width: @sidebar-width !important;
	#util > .border-box();
	#util > .clearfix();
}

body.browser-ie {
	#page-content {
		box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);
	}
}

body.fixed-width {
	#page-content {
		min-width: @hide-sidebar-width;
	}
}

body.no-scroll {
	#page-content {
		overflow: hidden;
	}
}

body.variant-nopadding {
	#page-content {
		padding: 0;
	}
}

body.variant-noborder {
	#page-content {
		border-width: 0;
	}
}

body[data-is-demo-silo] {
	padding-bottom: @onboarding-footer-height;
}

body.loading {
	#page-content {
		border-left: none;

		.nutshell-brand-symbol {
			position: absolute;
			top: 32px;
			left: 32px;
			width: 40px;
			height: 56px;
			fill: var(--off-white-dk);
		}
	}
}

body#entity-list-leads,
body#entity-list-people,
body#entity-list-companies,
body#dashboard,
body#report-ondemand,
body#recycle-bin,
body#pipelines {
	overflow: hidden; //prevents body bounce scrolling, as nothing ever leaves this page.
	position: fixed;

	.flash-message {
		margin-bottom: 30px;
	}
	.flash-messages-placeholder {
		display: none;
	}
	.notify-messages {
		display: none;
	}

	//don't need an `overflow-y: auto` as it creates a dead scrollbar
	//when we add the footer
	#page-content {
		overflow-y: visible;
		height: 100%;
	}
}

body#report-2017,
body#report-analytics-2017 {
	overflow-y: hidden;
	overflow-x: auto;

	.flash-message {
		margin-bottom: 30px;
	}
	.flash-messages-placeholder {
		display: none;
	}
	.notify-messages {
		display: none;
	}

	//don't need an `overflow-y: auto` as it creates a dead scrollbar
	//when we add the footer
	#page-content {
		min-width: 960px;
		overflow-y: visible;
		height: 100%;
	}

	@media (min-width: 960px) {
		overflow-x: hidden;
	}
}

//@TODO FEATURE_FLAG
#onboarding-toggle {
	display: block;
	margin: 8px 16px;
	padding: 2px 16px;
	border-radius: 4px;
	background-color: white;
	color: white;
	cursor: pointer;
}

#walkthrough-bar {
	background: @info;
	height: 90px;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 64px;
	width: ~'calc(100% - 64px)';
	z-index: 10003;

	/* see http://stackoverflow.com/questions/12969228/chrome-slow-scrolling-with-fixed-position-elements */
	transform: translateZ(0);
	backface-visibility: hidden;

	&.state-hidden {
		height: 0;
	}
}

#settings-page-title-bar.title-bar-container {
	display: flex;
	flex-shrink: 0;
	padding: 16px 16px 16px 16px;
	background-color: white;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid @off-white-dk;

	.container {
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}

	.title-container {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 50px;
	}

	.header-icon {
		float: left;
		height: 42px;
		width: 42px;
		padding-right: 10px;
		fill: var(--grey-dk);
	}

	.title {
		font-size: 21px;
		font-weight: 500;
		font-family: doyle, IBM Plex Sans;
		line-height: 26px;
		height: 26px;
		color: var(--grey-dk);
		opacity: 1;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 400px;
		padding-right: 16px;
	}

	.subheader-container {
		font-size: var(--font-small);
		position: relative;
		height: 20px;
	}
}

#walkthrough-bar.first-demo {
	transform: translateZ(0) translateY(0);
	animation: slide-in 4s @customEasing;
}

@keyframes slide-in {
	0% {
		transform: translateY(90px);
	}
	75% {
		transform: translateY(90px);
	} // Delay by three seconds, before animating in
	100% {
		transform: translateY(0);
	}
}

.ReactVirtualized__Grid {
	outline: none;
}

@keyframes text-animate-left {
	0% {
		transform: translateX(96px);
		opacity: 0;
	}
	30% {
		opacity: 1;
	}
	100% {
		transform: translateX(-32px);
	}
}

// @ccain changed this to add #user until we have unified headers
#myaccount .title-bar-container,
#user .title-bar-container {
	.avatar-container.action-file-pick {
		.avatar,
		img {
			width: 48px;
			height: 48px;
		}
		.avatar {
			background-size: 42px;
			.overlay {
				.fa-cloud-upload {
					font-size: 30px;
					margin-top: -15px;
					margin-left: -15px;
				}
				.remove {
					font-size: 15px;
					line-height: 19px;
					width: 20px;
					height: 20px;
				}
				.message {
					font-size: 30px;
					line-height: 46px;
				}
			}
		}
	}

	.avatar-container {
		float: left;
		margin-right: 12px;
		.avatar {
			border: none;
			background: @orange-dk url('@{img-root}/layout/avatar-person.svg') no-repeat 50% 110%;
			background-size: 66px 66px;
		}
	}

	.meta {
		a {
			font-size: @normal;
			color: @blue;
			margin-left: 12px;
			&:hover {
				color: @blue-dk;
			}
			.icon {
				margin: 0 2px;
			}
		}
	}
}
