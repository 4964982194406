.other-steps {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 110%;
    margin-left: -5%;
    overflow-y: hidden;
}

.other-steps svg {
    width: 100%;
    position: absolute;
    top: 34%;
}

.fade {
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(255, 201, 3, 0), rgba(255, 201, 3, 1));
}

.other-steps :global(.main) {
    animation: slideUp 300ms;
}

.other-steps :global(.shadow) {
    animation: slideDown 300ms;
}

@keyframes slideDown {
    0% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}
