.engagement-stats {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 32px;
    margin-bottom: 32px;
}

.two-line-info-wrapper {
    margin-bottom: 24px;
}

.addButton button {
    font-size: 12px;
    padding: 0;
}

.help-container {
    position: absolute;
    margin-top: 1px;
    display: inline-block;
}
