.container {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid var(--off-white-dk);
    background-color: white;
}

.top-row {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-row-left-group {
    display: flex;
    align-items: center;
    height: 30px;
}

/* Prevent filter button from shrinking */
.top-row-left-group > div:first-child {
    flex-shrink: 0;
}

/* Give a default spacing between children */
.top-row-left-group > div:not(:first-child),
.remove-filters {
    margin-left: 8px;
}

/* Style button like text link */
.remove-filters {
    cursor: pointer;
    color: var(--blue);
}

.remove-filters:hover {
    text-decoration: underline;
    color: var(--blue-dk);
}

/* Signficant margin after filter button, to allow for badge */
.top-row-left-group > div:nth-child(2) {
    margin-left: 16px;
}

/* Narrow margin between graduated filters and search box */
.top-row-left-group > div:nth-child(3) {
    margin-left: 4px;
}

.top-row-right-group {
    flex-grow: 1;
}
