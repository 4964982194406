.container {
    padding: 16px;
    max-width: 800px;
}

.loading-container {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
