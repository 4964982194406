.scrollable-section {
    position: relative; /* needed for react-select to scroll correctly */
    overflow-y: auto;
    min-height: 120px;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.scrollable-section > * {
    margin-left: 16px;
    margin-right: 16px;
}

.border-top {
    border-top: 2px solid var(--off-white-dk);
    padding-top: 8px;
}

.border-bottom {
    border-bottom: 2px solid var(--off-white-dk);
    padding-bottom: 8px;
}
