.container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey-lt);
    font-size: var(--font-normal);
    margin-top: 2px;
    padding: 4px 0;
    user-select: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.container:hover {
    border-color: var(--off-white-dk);
    color: var(--grey-dk);
}

.container span {
    padding-right: 4px;
}

.container:focus-visible {
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}
