.row {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
}

.row--disabled {
    composes: row;
    opacity: 0.6;
    cursor: unset;
}

.avatar {
    margin-right: 16px;
    display: flex;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}
