.summary-bar-container {
    margin-top: 16px;
}

.summary-bar {
    display: flex;
    overflow: hidden;
    height: 16px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    border-radius: 3px;
    background-color: var(--off-white);
}

.summary-bar-label-container {
    display: flex;
    padding-top: 4px;
}

.legend-container {
    display: flex;
    margin: 16px 0;
}
