.hero-chart-segments {
    position: absolute;
    display: flex;
    align-items: center;
    right: 8px;
    top: 16px;
    z-index: 1;
    font-size: var(--font-normal);
    color: var(--grey);
}

.title {
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    display: none; /* This is toggled to 'block' when downloading */
}

.extra-content-container {
    position: absolute;
    display: flex;
    align-items: center;
    left: 16px;
    top: 16px;
    z-index: 1;
}
