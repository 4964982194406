.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: absolute;
    background-color: white;
    z-index: 1;
}

.container-loading {
    composes: container;
    opacity: 0.5;
}

.empty-text {
    margin: 32px 0;
    font-size: var(--font-big);
    color: var(--grey-dk);
    font-weight: normal;
}

.loading-icon svg path,
.loading-icon svg rect {
    fill: var(--grey-lt);
}
