.list {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
    padding: 16px 0;
}

.required {
    margin-left: 4px;
    color: var(--rose);
}
