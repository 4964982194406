.container {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
}

.left {
    width: 50%;
    height: 100%;
}

.right {
    width: 50%;
    height: 100%;
    padding: 24px;
    box-sizing: border-box;
    background-color: #fff;
}
