.special-cases label:not(:first-child) {
    margin-top: 16px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-bottom: 16px;
}

.buttons > *:not(:last-child) {
    margin-right: 4px;
}

.round-robin {
    margin-top: 16px;
}
