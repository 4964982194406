.ui-entity-info {
	position: relative;
	#util > .clearfix();
	padding: 10px;

	.ui-entity-info__avatar,
	.ui-entity-info__avatar__placeholder {
		float: left;
		margin-right: 10px;
		text-align: center;
	}
	.ui-entity-info__lead-number {
		position: absolute;
		left: 0px;
		bottom: 0px;
		width: 30px;
		font-size: 11px;
		text-align: center;

		em {
			text-decoration: underline;
			font-style: normal;
		}

	}
	.ui-entity-info__content {
		float: left;
		width: ~"calc(100% - 55px)";

		.ui-entity-info__row {
			#util > .excerpt-crop > .ellipsis;
			width: 100%;
		}
	}
	.ui-entity-info__tertiary {
		position: absolute;
		right: 10px;
		font-weight: 500;
	}

	&.ui-entity-info--linkable {
		.ui-entity-info__content {
			.ui-entity-info__row--top {
				a:hover { text-decoration-line: underline; }
			}
		}
	}
}

.entity {
	&.type-users { color: @orange-dk; }
	&.type-contacts { color: @navy-dk; }
	&.type-accounts { color: @plum-dk; }
	&.type-mcfxCompanies { color: @plum-dk; }
	&.type-leads { color: @green-dk; }

	&.type-linked {
		display: inline-block;
		margin-right: 7px;
	}
}
