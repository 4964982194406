.container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.container-new {
    padding-top: 8px;
}

.container-new .search {
    padding: 8px 16px 8px 16px;
}

.container-new .select-none {
    margin-bottom: 10px;
}

.container-simple .list-view,
.container-new .list-view {
    border-top: none;
}

.list-view {
    border-top: 1px solid var(--off-white-dk);
    overflow-y: auto;
    flex-basis: auto;
    flex-shrink: 0;
}

.empty-container {
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 32px;
}

.empty-container > li {
    padding: 16px 8px;
    text-align: center;
    line-height: 19px;
}
