.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.dashboard-timeline {
    height: 100%;
    overflow: auto;
}
