.tags-bar {
	cursor: default;
	position: relative;
	font-size: @tiny;
	line-height: 20px;

	> input.tags {
		visibility: hidden;
		height: 24px;
	}

	.tags-bar-placeholder {
		left: 80px;
		color: @grey-lt;
		font-size: 13px;
		position: absolute;
		font-style: italic;
		padding: 7px 5px 5px 10px;
	}

	.tags-bar-label {
		color: @grey-lt;
		float: left;
		z-index: 102;
		font-weight: 500;
		line-height: 28px;
		position: relative;
		margin: 0 5px 0 20px;
	}
	.tags-operator {
		z-index: 2;
		display: none;
		float: right;
		position: relative;
		margin: 5px 20px 0 5px;

		label {
			margin-left: 5px;
		}
	}
	.tags-bar-link {
		float: right;
		margin: 0 20px 0 5px;
	}

	hr {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 2px;
		border: none;
		position: absolute;
		border-top: 1px solid @off-white-dk;
		background: url('@{img-root}/modal-hr.png') no-repeat center bottom;
	}
}
.browser-ff .tags-bar hr {
	height: 3px;
}

// styling for results list
.tags-result .tags-count {
	float: right;
	margin: 0;
	color: @grey-lt;
}

// mainly for places where we draw tags without the full select2 system (columns)
ul.select2-choices {
	li.select2-search-choice {
		float: left;
	}
}

// warning: this is used by some autocomplete code, too (sfilters). need to consolidate w/ select2 styling
div.token-input-dropdown-nutshell {
	margin-top: 1px;
	font-size: 13px;
	border-top: none;
	background: white;
	border: 1px solid @grey-lt;
	border-radius: 0 0 5px 5px;
	max-height: 220px;
	overflow-x: auto;
	overflow-y: auto;

	width: 788px;

	&.variant-narrow {
		width: 200px;
	}

	z-index: 9002 !important; // above modals

	p {
		color: @grey-lt;
		padding: 5px;
		font-style: italic;
	}
	ul {
	}
	li {
		list-style: none;
		padding: 5px 10px;
		cursor: pointer;

		span.count {
			float: right;
			color: @grey;
		}
		b {
			font-weight: normal;
		}

		&:last-child {
			border-radius: 0 0 5px 5px;
		}

		&.token-input-selected-dropdown-item-nutshell,
		&.autocomplete-state-selected {
			background: @color-list-hovered;
			font-weight: 500;

			b {
				font-weight: 500;
			}
		}
		&:hover {
			background: @color-list-hovered;
		}
	}
}
