.container {
    display: inline-block;
    min-width: 88px;
    max-width: 100%;
}

.container label {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
    color: var(--grey-dk);
}

.container label,
.container select {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

.container select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    padding: 8px 30px 7px 8px;
    font-size: var(--font-small);
    background: #fff;
    color: var(--grey-dk);
    outline: none;
    border: 1px solid var(--off-white-md);
    border-radius: 3px;
    box-sizing: border-box;

    height: 34px;
    line-height: 17px;
    cursor: default;
}

.container select:hover:not(:disabled) {
    border-color: var(--grey-dk);
}

.container select:active:not(:disabled),
.container select:focus:not(:disabled) {
    border-color: var(--blue);
}

.container-disabled {
    composes: container;
    cursor: default;
    opacity: 0.5;
}

.container-full-width {
    composes: container;
    width: 100%;
}

.container-errored {
    composes: container;
}

.select-container {
    position: relative;
    margin-bottom: 2px;
}

.select-container .arrow {
    display: block;
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    width: 8px;
    height: 8px;
    font-size: 11px;
    pointer-events: none;
    color: var(--grey-lt);
}

.filled--grey select {
    border-color: var(--off-white-md);
    background-color: var(--off-white-md);
    color: var(--grey);
}
.filled--grey select:active:not(:disabled),
.filled--grey select:focus:not(:disabled),
.filled--grey select:hover:not(:disabled) {
    border-color: var(--off-white-dk);
    background-color: var(--off-white-dk);
    color: var(--grey-dk);
}
.filled--grey-darken select:active:not(:disabled),
.filled--grey-darken select:focus:not(:disabled),
.filled--grey-darken select:hover:not(:disabled) {
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
    border-color: var(--button-secondary-dk);
    cursor: pointer;
}

.filled--blue select {
    border-color: var(--blue);
    background-color: var(--blue);
    color: #fff;
}
.filled--blue select:active:not(:disabled),
.filled--blue select:focus:not(:disabled),
.filled--blue select:hover:not(:disabled) {
    border-color: var(--blue-dk);
    background-color: var(--blue-dk);
    color: #fff;
}
.filled--blue .arrow {
    color: #fff;
}

.container-errored select {
    border-color: var(--rose);
    color: var(--grey-dk);
}
