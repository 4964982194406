.input {
    text-align: center;
    font-weight: 500;
    width: 46px;
    color: var(--grey-dk);
    font-size: 13px;
    margin-left: 8px;
    box-sizing: border-box;
    padding: 2px 0;
    outline: none;
    border-radius: 3px;
    border: none;
}

.button {
    line-height: 14px;
    min-width: 40px !important;
    margin-left: 8px;
    cursor: text;
    padding: 3px 8px !important;
    margin-right: 0 !important;
    color: var(--grey);
    background-color: var(--off-white-md);
}

.button:hover {
    background-color: #fff !important;
}
