.container {
    padding: 16px;
    display: flex;
    height: 100%;
}

.container > div:first-child {
    width: 200px;
}

.container > div:last-child {
    flex: 1;
    margin-left: 16px;
    height: 100%;
}
