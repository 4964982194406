.container {
    position: relative;
    width: 100%;
}

.container-truncate {
    composes: container;
    display: flex;
}

.container > span,
.shown > span {
    margin-right: 2px;
}

.shown-truncate {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shown,
.hidden {
    position: relative;
    display: inline;
}

.hidden {
    flex-shrink: 0;
    margin-left: 2px;
}

.hidden--hoverable {
    composes: hidden;
    text-decoration: underline;
}

.hidden--hoverable:hover {
    color: var(--grey-dk);
}

.hidden:hover .hidden-models {
    display: block;
}

.hidden-models {
    display: none;
}
