.lead-field-field {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

.lead-field-field > *:not(:first-child) {
    margin-top: 8px;
}

.enum-option {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.enum-option > label:first-child {
    margin-top: 8px;
}

.enum-option > div:last-child {
    width: 100%;
    margin-left: 8px;
}

.field-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.field-actions > *:not(:first-child) {
    margin-left: 8px;
}

.card-container {
    padding-bottom: 100px;
}
