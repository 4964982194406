.button-container {
    position: relative;
    display: flex;
    width: fit-content;
    border-radius: 3px;
    text-align: center;
    outline: none;
    user-select: none;
    color: var(--white);
    background-color: var(--ai-blue);
    padding: 6px 0;
    animation: width 0.5s ease-in-out;
}

.button-container svg {
    padding: 0 8px;
}

.start-button,
.stop-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
    animation: width 0.5s ease-in-out;
    padding: 0;
}
