.timeline-entry-content {
    padding: 16px 16px 12px 16px;
    font-size: var(--font-small);
}

.timeline-entry-content--row-view {
    composes: timeline-entry-content;
    display: flex;
    flex-direction: row-reverse;
}

.timeline-entry-content--expandable {
    composes: timeline-entry-content;
    cursor: pointer;
}

.content-heading-container {
    display: flex;
    justify-content: space-between;
}

/* Adds margin between heading and content (if there is content) */
.content-heading-container:not(:only-child) {
    margin-bottom: 8px;
}

/* Negative margin so that status bar appears aligned with content */
.status-bar-container {
    margin-left: 0;
}

.subject {
    font-size: var(--font-normal);
    color: var(--grey-dk);
    font-weight: 500;
    /* Needs bigger line height so that bottom of long letters are not cut off */
    line-height: 21px;
    height: fit-content;
    margin-left: 8px;
}

/* To add spacing if there is a status bar */
.subject:not(:only-child) {
    margin-top: 8px;
}

.participant-actions-container {
    display: flex;
    align-items: center;
    height: fit-content;
    margin-left: 16px;
    justify-content: flex-end;
}

/* Make body hyperlinks look clickable */
.entry-html-body > a {
    color: var(--blue);
}

.entry-html-body > a:hover,
.entry-html-body > a:focus {
    color: var(--blue-dk);
}

.message {
    height: fit-content;
    max-height: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 17px;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: left;
    align-items: flex-start;
}
