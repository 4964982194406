.breadcrumbs {
    display: flex;
    height: 20px; /* Matches subtitle container */
    margin-top: 2px; /* Matches subtitle container */
}

.breadcrumb {
    color: var(--grey-lt);
    font-style: italic;
    font-size: var(--font-normal);
    flex-shrink: 0;
}

.breadcrumb:last-child {
    color: var(--grey-dk);
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 1;
}

.breadcrumb a {
    text-decoration: underline;
}

.breadcrumb a:hover {
    color: var(--grey-dk);
    cursor: pointer;
}

.divider {
    margin: 0 8px;
}
