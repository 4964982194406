.indicator {
    background-color: var(--green);
}

.percentage-container {
    margin-left: 8px;
    flex: 34px; /* needs to be big enough to fit full percentage */
}

.progress-container {
    margin-left: 8px;
    flex: 20px;
}

.bar {
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
}
