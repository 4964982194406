.logo {
    composes: icon from '../action-box/action-box.css';
    margin-top: 0;
}

.button-container {
    margin-top: 8px;
    margin-left: -12px; /* counteract padding of button, align text */
}

.switch-group {
    display: flex;
    align-items: center;
}

.title {
    width: 150px;
}

/* Need to do this for a unique case where the component is being used for a smaller width but needs to be same size */
/* couldn't think of a better way to do this without adding extra props or risk breaking it in other places */
.disconnect-button-small {
    margin-right: -16px;
}
