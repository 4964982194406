.sales-automation-app-shell {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.main-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.main-scroll-container {
    height: calc(100vh - 78px);
    margin-top: 16px;
    position: relative;
    overflow: auto;
    background-color: #fff;
}

.board-container {
    display: table;
    table-layout: fixed;
    margin-left: 16px;
    height: calc(100% - 64px);
    min-width: calc(100% - 16px);
}

.board-container > div:first-child {
    padding-left: 0;
}

.board-container > div:last-child,
.board-container > div:nth-last-child(2) {
    padding-right: 0;
}

.board-headers {
    width: 100%;
    top: 0;
    z-index: 4;
    position: sticky;
    padding: 0 16px;
    box-sizing: border-box;
    background-color: white;
}

.board-obscure-overlay {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: white;
    opacity: 0.6;
}
