.badge {
    display: flex;
    align-items: center;
    margin-right: 8px;
    background-color: var(--blue-lt);
    color: var(--blue);
    border-radius: 20px;
    padding: 0 8px;
    text-transform: lowercase !important; /* Override style from less */
    position: absolute;
    right: 90px;
    /* Position animation in from the right */
    animation: expand 1s;
    cursor: pointer;
}

@keyframes expand {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
