.container {
    position: relative;
    margin-bottom: 20px;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.flex-item {
    width: 100%;
    margin-left: 20px;
}

.flex-item:first-child {
    margin: 0;
}

.checkbox-container {
    position: absolute;
    top: 70px; /* Puts the checkbox 8px below the time selects */
}
