@import "include";

#list-integration {
	.page-flow-area {
		box-sizing: border-box;
		float: left;
		height: 100%;
		width: 75%;
		max-width: 1200px;
		@media (max-width: 1200px) {
			width: 100%;
		}
	}

	.setup-integrations-container {

		dd { margin: 0 0 10px 0; }
		dt { font-size: 13px; }
		input[type=text],
		input[type=password] {
			padding: 3px 8px;
			width: 80%;
		}
	}

	.integration-list {
		background-color: @off-white-bg;
		box-shadow: inset 0 0 3px rgba(0,0,0, 0.1), 0 1px 1px 1px white;
		border: 1px solid @grey-lt;
		border-radius: 3px;
		margin-bottom: 30px;
		position: relative;
		overflow: hidden;
		li {
			position: relative;
			border-top: 1px solid @grey-lt;

			&:first-child { border-top: none; }
			&:hover {
				background-color: rgba(255,255,255, 0.7);
				.background { display: block; }
			}
			&.state-enabled {
				background-color: @green-lt;
				.background { display: block; }
				.enabled-checkmark { display: block; }
			}
			.enabled-checkmark {
				display: none;
				position: absolute;
				height: 20px;
				width: 30px;
				right: 0;
				top: 8px;
				.2x('@{img-root}/icons/actionable-sprite.png',"no-repeat");
				background-size: 100% !important;
				background-position: 0 -35px;
			}
			a {
				display: block;
				padding: 10px 15px;
				color: @grey-lt;
				font-weight: 700;
			}
		}
	}
}

// mobile apps CTA graphic
.mobile-device-graphic {
	position: absolute;
	top: 20px;
	left: -10px;
	width: 168px;
	height: 218px;
	.2x('@{img-root}/integration-logos/mobile-app-cta.png', "no-repeat", 168px, 218px);
}

#mobile-apps {
	height: 235px; /* Allow for full height of graphic */
	overflow: hidden;

	.row-integration {
		padding-top: 50px;
		.content { width: 90%; }
	}
}


ul.links-app-store { // appstore and googleplay store badges
	margin-top: 16px;
	li.badge {
		margin: 0 10px 0 0;
		&:last-child { margin-right: 0; }
		display: inline-block;
		a {
			line-height: 0;
			border: 2px solid @off-white-dk;
			border-radius: 8px;
			display: block;
			width: 135px;
			height: 40px;
			&:hover {
				background: white;
				border-color: @blue;
			}
		}
		&.appstore a { .2x('@{img-root}/integration-logos/badge-appstore.png',"no-repeat", 135px, 40px); }
		&.android a { .2x('@{img-root}/integration-logos/badge-googleplay.png',"no-repeat", 135px, 40px); }
	}
}
