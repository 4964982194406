.container {
    display: flex;
    gap: 6px;
}

.container > button {
    border: 1px solid var(--button-secondary);
    border-radius: 4px;
}

.container > button,
.number-input {
    width: 24px;
    height: 24px;
}

.number-input {
    width: 25px;
    text-align: center;
}

.container .number-input > input {
    text-align: center;
}
