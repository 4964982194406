.cell {
    box-sizing: border-box;
    flex: 150 0 auto;
    min-width: 130px;
    width: 150px;
}

.cell:hover,
.cell:focus {
    cursor: pointer;
}

.cell--hover {
    composes: cell;
}

.cell--hover:hover .cell-inactive,
.cell--hover:focus .cell-inactive {
    color: var(--grey-dk);
    background-color: var(--color-list-hovered);
    cursor: pointer;
}

.cell--hover:focus {
    outline: 0;
}

.cell-inactive {
    padding: 8px 16px;
    height: 34px; /* Matches textfield */
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
