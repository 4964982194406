.share {
    display: flex;
    align-items: center;
}

.share-icon {
    display: none;
    color: var(--grey);
    margin-right: 8px;
}

.share:hover .share-icon {
    color: var(--grey-dk);
}

@media (min-width: 1100px) {
    .share-icon {
        display: block;
    }
}
