.textfield-with-character-limit {
    border: 1px solid var(--form-border);
    border-radius: 3px;
}

.textfield-with-character-limit--no-side-borders {
    border-top: 1px solid var(--form-border);
    border-bottom: 1px solid var(--form-border);
}

.textfield-with-character-limit:focus-within,
.textfield-with-character-limit--no-side-borders:focus-within {
    border-color: var(--focus-blue);
}

.character-limit {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 8px;
}

.character-limit--disabled {
    background-color: var(--off-white);
    opacity: 0.6;
}
