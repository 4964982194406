.header-underlined {
	position: relative;
	h1 {
		font-weight: normal;
		font-size: 28px;
		line-height: 36px;
		color: @grey-dk;
		font-family: @headline-font;
		letter-spacing: 1px;
		margin-bottom: 20px;
		padding-bottom: 8px;
	}
}
