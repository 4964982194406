.container {
    height: calc(100vh - 180px);
    overflow: hidden;
    width: 100%;
}

.cell-container {
    font-size: var(--font-normal);
    display: flex;
    align-items: center;
}

.cell-container:nth-child(5n) {
    justify-content: flex-end;
}

.entity-container {
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
}

.entity {
    display: flex;
    align-items: center;
}

.column-headers {
    font-size: var(--font-small);
    display: flex;
    border-bottom: 1px solid var(--off-white-dk);
    background-color: var(--off-white);
}

.column-header {
    padding: 4px 0;
    box-sizing: border-box;
    font-weight: 500;
    color: var(--grey-dk);
}

.column-header:first-child {
    padding-left: 16px;
}

.column-header-sortable {
    composes: column-header;
    display: flex;
    cursor: pointer;
}

.column-header-sortable:hover {
    color: var(--blue);
}

.sort-icon {
    margin-left: 5px;
    margin-top: -1px;
    font-size: 11px;
}

.undelete-link {
    cursor: pointer;
    color: var(--blue);
    padding-right: 32px;
}

.undelete-link:hover {
    color: var(--blue-dk);
    text-decoration: underline;
}

.redelete-link {
    padding-right: 32px;
    color: var(--green);
    font-size: var(--font-small);
    font-weight: 500;
}

.options-header {
    text-align: center;
}

.options-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 32px;
}
