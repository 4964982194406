.container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.container--no-header {
    composes: container;
    top: 80px;
    height: calc(100% - 80px);
}

.container-inner {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--off-white);
}

.container-inner--no-scroll {
    composes: container-inner;
    overflow: visible;
}

.container-inner--white {
    composes: container-inner;
    background-color: white;
}

.container-inner--dark {
    composes: container-inner;
    background-color: var(--sidebar-primary);
}
