.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 64px);
}
