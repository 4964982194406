.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 270px;
    text-align: center;
}

.img {
    width: 150px;
    margin-bottom: 8px;
}

.title {
    font-size: var(--font-big);
    font-weight: 500;
    color: var(--grey-dk);
    margin-bottom: 8px;
    line-height: 25px;
}

.body {
    font-size: var(--font-normal);
    color: var(--grey-lt);
    line-height: 19px;
    margin-bottom: 16px;
}
