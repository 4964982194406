.textfield-wrapper {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    margin-right: 8px;
}

.create-button {
    border: 1px solid var(--off-white-dk);
    border-top: none;
    width: 100%;
    box-sizing: border-box;
}

.create-button button {
    padding: 8px 16px;
    text-align: left;
}

.create-button button:hover,
.create-button button:focus,
.create-button button:active {
    background-color: var(--blue-xlt);
}

.avatar {
    z-index: 0;
    margin-right: 8px;
}

.error {
    border: 1px solid var(--red);
}

.error-list {
    margin-left: 56px;
}
