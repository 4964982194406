@value size-large: 18px;
@value offset-large: -10px;
@value transition-time: 150ms;
@value scale-small: 0.5;
@value scale-hidden: 0;

.container {
    display: block;
    position: absolute;
    top: offset-large;
    right: offset-large;
    width: size-large;
    height: size-large;
    border: 2px solid var(--off-white);
    border-radius: 50%;
    animation: expand transition-time;
}

.container--small {
    composes: container;
    transform: scale(scale-small);
    animation: shrink-small transition-time;
}

.container--small-initial {
    composes: container;
    transform: scale(scale-small);
    animation: grow-small transition-time;
}

.container--hidden {
    composes: container;
    transform: scale(scale-hidden);
    animation: hide transition-time;
}

.contents {
    display: block;
    color: #fff !important; /* override hover state for spans */
    text-align: center;
    width: size-large;
    line-height: size-large;
    font-size: var(--font-tiny);
    font-weight: 500;
    animation: expand transition-time;
}

.container--small .contents {
    animation: shrink-small transition-time;
}

.container--hidden .contents {
    transform: scale(scale-hidden);
}

.container:not(.clickable) {
    cursor: default;
}

.grey {
    background-color: var(--grey);
}

.clickable:hover.grey {
    background-color: var(--grey-dk);
}

.blue {
    background-color: var(--blue);
}

.clickable:hover.blue {
    background-color: var(--blue-dk);
}

@keyframes expand {
    0% {
        transform: scale(0.5);
    }
    75% {
        transform: scale(1.08);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes shrink-small {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(0.4);
    }
    100% {
        transform: scale(0.5);
    }
}

@keyframes grow-small {
    0% {
        transform: scale(0);
    }
    75% {
        transform: scale(0.55);
    }
    100% {
        transform: scale(0.5);
    }
}

@keyframes hide {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
