.comparison-engagement-stats {
    border-radius: 3px;
}

.comparison-test-stats {
    margin-top: 16px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid var(--form-border);
}
