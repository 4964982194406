.quote-template-container-outer {
    width: 910px;
    margin: 16px;
    background-color: white;
}

.quote-template-container-inner {
    border: 1px solid var(--off-white-dk);
    border-radius: 0 0 3px 3px;
    padding: 16px;
}

.quote-template-header {
    background-color: var(--off-white-dk);
    border-radius: 3px 3px 0 0;
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 16px;
}

.cue-card-container {
    height: 133px;
    margin-bottom: 16px;
}
