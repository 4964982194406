.reaction-button {
    padding: 2px 4px;
    margin: 0 2px;
    display: flex;
    align-items: center;
    color: var(--grey-lt);
    border-radius: 3px;
    border: 1px solid var(--off-white-md);
    font-size: 13px;
}

.reaction-button--small {
    composes: reaction-button;
    padding: 2px 6px;
}

/* Spacing between reaction number and emoji */
.reaction-button > :first-child {
    margin-right: 4px;
}

.reaction-button:hover {
    background-color: var(--off-white-md);
    color: var(--grey-dk);
}

.reaction-button--reacted {
    composes: reaction-button;
    color: var(--gray);
    background-color: var(--off-white);
    border-color: var(--button-secondary-dk);
}
