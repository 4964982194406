.btn {
    padding: 5px 10px;
}

.light svg {
    fill: var(--off-white-dk);
}

.dark svg {
    fill: var(--grey);
}

.btn svg {
    vertical-align: middle;
}

.btn:hover svg,
.btn:focus svg {
    fill: var(--rose);
}

.btn:active svg {
    fill: var(--rose-dk);
}
