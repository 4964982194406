.container {
    align-items: baseline;
    user-select: none;
    display: none;
}

.container--disabled {
    composes: container;
    cursor: default;
}

.container > label {
    padding: 7px 8px;
    line-height: 15px;
}

.container:not(.container--disabled) > label:hover,
.container:not(.container--disabled) > label:active {
    color: var(--grey-dk);
}

@media (min-width: 1100px) {
    .container {
        display: flex;
        align-items: center;
    }
}
