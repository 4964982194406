.button {
    height: 100%;
    box-sizing: border-box;
    padding: 12px 16px;
    background-color: var(--off-white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-big);
    font-weight: 500;
    border: 2px dashed transparent;
    border-radius: 3px;
    width: 300px;
}

.button img {
    margin-right: 8px;
    height: 100%;
}

.button-cancel {
    composes: button;
    color: var(--grey-lt);
    border-color: var(--off-white-dk);
}

.button-cancel--hovered {
    composes: button-cancel;
    background-color: var(--off-white-dk);
    border-style: solid;
}

.button-lose {
    composes: button;
    color: var(--grey-lt);
    border-color: var(--rose);
}

.button-lose--hovered {
    composes: button-lose;
    background-color: var(--rose-lt);
    border-style: solid;
}

.button-win {
    composes: button;
    color: var(--green);
    border-color: var(--green);
}

.button-win--hovered {
    composes: button-win;
    background-color: var(--green-lt);
    border-style: solid;
}
