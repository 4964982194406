.container {
    position: relative;
    height: calc(100% - 54px); /* full height minus header */
}

.custom-field {
    padding: 0 16px;
    overflow: auto;
    height: calc(100% - 57px); /* full height minus footer */
}

/* Set position for flash banner rendered in error container */
.error-container > :first-child {
    top: unset;
    bottom: 57px; /* To show up above the footer */
}

.footer-buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
}
