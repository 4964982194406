.dots-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
}

.dots-container--tiny {
    composes: dots-container;
    padding: 4px 16px;
}

.dots-container--hover {
    composes: dots-container;
}

.dots-container--hover:hover {
    background-color: var(--off-white);
    border-radius: 3px;
}

.dots-container--less-padding {
    composes: dots-container;
    padding: 4px 8px;
}

.dots-container--no-padding {
    composes: dots-container;
    padding: 0;
}

.dots-container:hover .dot--grey,
.dots-container:focus-visible .dot--grey {
    background-color: var(--grey-dk);
}

.dots-container:hover .dot--off-white,
.dots-container:focus-visible .dot--off-white {
    background-color: var(--white);
}

.dots-container:focus-visible {
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 2px 0;
    flex-shrink: 0; /* Necessary in Safari/Firefox */
}

.dot--grey {
    background-color: var(--grey);
}

.dot--off-white {
    background-color: var(--off-white);
}

.dot--small {
    composes: dot;
    width: 4px;
    height: 4px;
    margin: 1px 0;
}

.dot--tiny {
    composes: dot;
    width: 3px;
    height: 3px;
    margin: 1px 0;
}
