.container {
    position: relative;
    padding: 10px 12px 3px 10px;
    border-bottom: 1px solid var(--off-white-dk);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-wrap: wrap;
}

.container > div {
    margin-bottom: 8px;
}
