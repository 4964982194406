.loading-lines-container {
    display: flex;
    flex: 1;
    height: 258px;
    justify-content: center;
    align-items: center;
}

.loading-lines-container svg path,
.loading-lines-container svg rect {
    fill: var(--grey-lt);
}
