/* Export this value for use in JS.
* e.g. height = require('./card-wrapper.css').height;
*/
@value height: 306px;

.container {
    height: height;
    width: calc(50% - 20px);
    margin: 10px;
    position: relative;
    display: inline-block;
    perspective: 1000px;
}

.card,
.card--empty {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-color: white;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);

    transform-origin: center center 0;
    transform-style: preserve-3d;
    transform: scale(1.1) rotateY(-30deg);
    backface-visibility: hidden;
    opacity: 0;
    transition: box-shadow 250ms var(--custom-easing);
    animation: animate 800ms var(--custom-easing) 0s forwards;
}

.card:not(.card--empty):hover {
    box-shadow: 0 8px 35px rgba(0, 0, 0, 0.15);
}

/*Targeting svg because of a rechart wrapper inline cursor:default style. */
.card :global(.recharts-wrapper) svg {
    cursor: pointer;
}

.container:nth-child(2) .card {
    animation-delay: 100ms;
}
.container:nth-child(3) .card {
    animation-delay: 200ms;
}
.container:nth-child(4) .card {
    animation-delay: 300ms;
}
.container:nth-child(5) .card {
    animation-delay: 400ms;
}
.container:nth-child(6) .card {
    animation-delay: 500ms;
}
.container:nth-child(7) .card {
    animation-delay: 600ms;
}
.container:nth-child(8) .card {
    animation-delay: 700ms;
}
.container:nth-child(9) .card {
    animation-delay: 800ms;
}
.container:nth-child(10) .card {
    animation-delay: 900ms;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: scale(1.1) rotateY(-30deg);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: scale(1) rotateY(0deg);
    }
}
