.design-form {
    padding: 32px 32px 32px 32px;
}

.design-form-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 32px;
    text-align: center;
}

.header {
    font-size: var(--font-big);
    color: var(--grey-dk);
    font-weight: 500;
    margin-bottom: 24px;
}

.header > * {
    padding: 0;
    font-size: var(--font-big) !important; /* overriding button styles */
    color: var(--grey-dk) !important; /* overriding button styles */
}

.arrow {
    padding: 0 8px;
    color: var(--grey-lt);
}

.design-fields-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.number-with-unit > * {
    text-align: left !important; /* This overrides the normal pattern of right-justifying numbers because in this case, we have a unit on the right */
}

.image-field-container {
    width: 100%;
    height: 100%;
}

.image-field-container button {
    text-align: unset;
}

.image-styles {
    width: 100%;
    height: 100%;
}

.image-field-image-container {
    position: relative;
}

/* Overlay + edit button is hidden by default */
.image-field-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 4px);
    background-color: rgba(12, 15, 51, 0.08);
    display: none;
    visibility: hidden;
    cursor: pointer;
}

/* When the preview container is hovered over, show the overlay + edit button */
.image-field-image-container:hover > .image-field-overlay {
    display: initial;
    visibility: visible;
}

/* The edit button */
.image-field-overlay > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
