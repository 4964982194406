.ui-modal__bulk-edit {
	.modal-card-tab-body {
		border: 1px solid @off-white-dk;
		box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		background-color: white;
		padding: 10px 15px;
	}

	.modal-card-header {
		min-height: 36px;
		background: white;
		box-sizing: border-box;
		box-shadow: none;
		padding: 0;
		text-shadow: none;
		border: 0;
		&:before,
		&:after {
			display: none;
		}
	}

	.modal-card-body {
		padding: 20px 0 0 0;
		margin: -10px 0 0 0;
		box-sizing: border-box;
		border: none;
		box-shadow: none;

		table.list-standard {
			border: none;
		}
	}

	.modal-card-buttonbar {
		background: white;
		box-shadow: none;
		border: none;
		padding: 0;
		box-sizing: border-box;

		a.action-delete {
			display: none; //feels like the right flow here
			font-size: 15px;
			padding: 8px 12px 7px 12px;
			background-color: lighten(@rose, 10%);
			color: white;
			border-radius: 3px;
			text-shadow: none;
			box-shadow: none;
			border-color: transparent;

			&:hover {
				background-color: @rose;
				text-decoration: none;
			}
		}
	}
}
