.container {
    display: flex;
    padding: 8px 16px;
    border-bottom: 1px solid var(--off-white-md);
    /* These colors are the same colors we use in the subject when it cant be
    edited. */
    background-color: var(--off-white);
    color: var(--grey-lt);
}

.label {
    width: 48px;
}
