.initial-sync-ctas > * {
    margin-top: 16px;
    width: 350px;
}

.email-widget-container > *:not(:last-child) {
    margin-bottom: 8px;
}

.link-container {
    margin-top: 16px;
}
