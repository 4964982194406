.no-padding {
    padding: 0;
}

.pad-4 {
    padding: 4px;
}

.pad-8 {
    padding: 8px;
}

.pad-12 {
    padding: 12px;
}

.pad-16 {
    padding: 16px;
}

.pad-24 {
    padding: 24px;
}

.pad-32 {
    padding: 32px;
}

.pad-64 {
    padding: 64px;
}

.pt-4 {
    padding-top: 4px;
}

.pt-8 {
    padding-top: 8px;
}

.pt-16 {
    padding-top: 16px;
}

.pt-24 {
    padding-top: 24px;
}

.pt-32 {
    padding-top: 32px;
}

.pt-64 {
    padding-top: 64px;
}

.px-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.px-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.px-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.px-16 {
    padding-left: 16px;
    padding-right: 16px;
}

.px-32 {
    padding-left: 32px;
    padding-right: 32px;
}

.py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.py-6 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
}

.py-64 {
    padding-top: 64px;
    padding-bottom: 64px;
}

.pb-8 {
    padding-bottom: 8px;
}

.pb-16 {
    padding-bottom: 16px;
}

.pb-24 {
    padding-bottom: 24px;
}

.pb-32 {
    padding-bottom: 32px;
}

.pl-8 {
    padding-left: 8px;
}

.pr-4 {
    padding-right: 4px;
}

.pr-8 {
    padding-right: 8px;
}

.pr-16 {
    padding-right: 16px;
}

.my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
}

.mt-2 {
    margin-top: 2px;
}

.mt-4 {
    margin-top: 4px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-64 {
    margin-top: 64px;
}

.mb-64 {
    margin-bottom: 64px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-14 {
    margin-bottom: 14px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-2 {
    margin-bottom: 2px;
}

.mr-4 {
    margin-right: 4px;
}

.mr-8 {
    margin-right: 8px;
}

.mr-16 {
    margin-right: 16px;
}

.mr-32 {
    margin-right: 32px;
}

.mr-64 {
    margin-right: 64px;
}

.mx-4 {
    margin-right: 4px;
    margin-left: 4px;
}

.mx-8 {
    margin-right: 8px;
    margin-left: 8px;
}

.mx-12 {
    margin-right: 12px;
    margin-left: 12px;
}

.mx-16 {
    margin-right: 16px;
    margin-left: 16px;
}

.mx-24 {
    margin-right: 24px;
    margin-left: 24px;
}

.mx-32 {
    margin-right: 32px;
    margin-left: 32px;
}

.gap-2 {
    gap: 2px;
}

.gap-4 {
    gap: 4px;
}

.gap-6 {
    gap: 6px;
}

.gap-8 {
    gap: 8px;
}

.gap-12 {
    gap: 12px;
}

.gap-16 {
    gap: 16px;
}

.gap-24 {
    gap: 24px;
}

.gap-32 {
    gap: 32px;
}

.gap-48 {
    gap: 48px;
}

.border-box {
    box-sizing: border-box;
}

.border {
    border: 1px solid var(--off-white-dk);
}

.inactive-border {
    border: 1px solid var(--off-white-dk);
}

.border-dk {
    border: 1px solid var(--form-border);
}

.hover-border {
    border: 1px solid var(--grey-lt);
}

.active-border {
    border: 1px solid var(--blue);
}

.border-top {
    border-top: 1px solid var(--off-white-dk);
}

.border-right {
    border-right: 1px solid var(--off-white-dk);
}

.border-left {
    border-left: 1px solid var(--off-white-dk);
}

.border-bottom {
    border-bottom: 1px solid var(--off-white-dk);
}

.br-3 {
    border-radius: 3px;
}

.border-top-left-8 {
    border-top-left-radius: 8px;
}

.grid {
    display: grid;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.inline-block {
    display: inline-block;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-self-start {
    align-self: start;
}

.align-self-end {
    align-self: end;
}

.align-self-center {
    align-self: center;
}

.align-self-stretch {
    align-self: stretch;
}

.justify-sb {
    justify-content: space-between;
}

.justify-even {
    justify-content: space-evenly;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-start {
    justify-content: flex-start;
}

.justify-self-start {
    justify-self: start;
}

.justify-self-center {
    justify-self: center;
}

.flex-dir-col {
    flex-direction: column;
}

.flex-grow {
    flex-grow: 1;
}

.flex-shrink {
    flex-shrink: 0;
}

.flex-wrap {
    flex-wrap: wrap;
}

.full-height {
    height: 100%;
}

.width-fit-content {
    width: fit-content;
}

.height-fit-content {
    height: fit-content;
}

.full-width {
    width: 100%;
}

.max-full-width {
    max-width: 100%;
}

.text-nowrap {
    text-wrap: nowrap;
}

.text-wrap {
    text-wrap: wrap;
}

.overflow-auto {
    overflow: auto;
}

.overflow-visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.font-size-normal {
    font-size: var(--font-normal);
}

.font-size-small {
    font-size: var(--font-small);
}

.font-bold {
    font-weight: 500;
}

.underline {
    text-decoration: underline;
}

.light-text {
    color: var(--grey-lt);
    font-weight: normal;
}

.color-primary {
    color: var(--blue);
}

.user-select-none {
    user-select: none;
}

.nowrap {
    white-space: nowrap;
}

.truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.background-white {
    background-color: white;
}

.background-offwhite {
    background-color: var(--off-white);
}

.background-offwhite-dk {
    background-color: var(--off-white-dk);
}

.background-xlt-blue {
    background-color: var(--blue-bg-lt);
}

.absolutely-center-and-fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pos-relative {
    position: relative;
}

.pos-absolute {
    position: absolute;
}

.pos-sticky {
    position: sticky;
}

.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.display-none {
    display: none;
}

.strikethrough {
    text-decoration: line-through;
}

.pointer-cursor {
    cursor: pointer;
}

.italic {
    font-style: italic;
}

.word-break {
    word-break: break-word;
}

.disabled {
    opacity: 0.6;
}

.no-click {
    pointer-events: none;
}

.overlay-click-to-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
