.truncated-content-container {
    position: relative;
    min-height: 21px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    overflow-wrap: anywhere;
}

/* For chat entries, we want to remove margins from chat items */
.truncated-content-container ul > li,
.truncated-content-container ul > li > div {
    margin: 0;
}

.truncated-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.truncated-content > div > span[data-entity-name='Users'],
.truncated-content > div > span[data-entity-name='Teams'] {
    background-color: var(--orange-xlt);
}

.truncated-content > div > span[data-entity-name='Contacts'] {
    background-color: var(--blue-xlt);
}

.truncated-content > div > span[data-entity-name='Accounts'] {
    background-color: var(--plum-xlt);
}

.truncated-content > div > span[data-entity-name='Leads'] {
    background-color: var(--green-xlt);
}

.expand-button {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 2px;
    right: 2px;
    padding: 4px;
    border-radius: 3px;
    background-color: var(--off-white);
    outline: 4px solid var(--white);
    color: var(--grey);
}

.expand-button:hover {
    color: var(--icon-primary-hovered);
}
