.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Guarantee this is above everything else, including an open sidebar. */
    z-index: 10001;

    opacity: 0;
    animation: container-animate-in 250ms ease 0s forwards;
}
.container--animate-out {
    composes: container;
    animation: container-animate-out 250ms ease 0s forwards;
}
.container--centered {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    /* Below modal body. */
    z-index: 0;
    background-color: var(--color-modal-overlay);
}

.focus-trap-zone {
    height: 100%;
    width: 100%;
}

.body {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1; /* Above overlay. */
    box-sizing: border-box;

    height: calc(100% - 100px);
    max-height: 800px;

    width: 800px;
    margin: 50px auto;
    /* We'll give 32px padding to the header and
    main-content so it doesn't suffer from the
    overflow-x here when we want to break out of
    this container */
    padding: 32px 0 0 0;

    background-color: white;
    border-radius: 4px;
    overflow-x: hidden; /* Hide border-radius overflow. */
    overflow-y: auto;

    transform-origin: top center 0;
    animation: body-animate-in 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s forwards;
    box-shadow: 0 3px 15px rgba(120, 128, 142, 0.55);
}

@media (max-height: 900px) {
    .body {
        max-height: 600px;
    }
}

.body--visible-overflow {
    overflow: visible;
}

.body .close-button-container {
    position: absolute;
    top: 27px; /* 32px - 5px padding-top */
    right: 22px; /* 32px - 10px padding-right */
    z-index: 10;
}

.body .close-button-container > *:not(:last-child) {
    margin-right: 8px;
}

.body--no-top-padding {
    composes: body;
    padding-top: 0;
}

/* Used for our onboarding modals that have a solid color on one side */
.body--no-padding {
    composes: body;
    padding: 0;
}

.body--no-padding .main-section {
    padding: 0;
}

.body--wide {
    composes: body;
    width: calc(100% - 64px);
    max-width: 1000px;
}

.body--left-aligned {
    margin: 50px auto 50px 50px;
}

.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    /* 22px, not 32px, because close button has 10px right padding */
    padding: 0 22px 16px 32px;
    box-sizing: border-box;
    color: var(--grey-dk);
}

.header-bar .close-button-container {
    position: static;
    z-index: 11;
    padding: 0;
}

.header-bar .close-button :global(.svg-close svg) {
    fill: var(--off-white-dk);
}

.header-bar .close-button :global(.svg-close:hover svg),
.header-bar .close-button:focus :global(.svg-close svg) {
    fill: var(--grey-lt);
}

.header-text {
    z-index: 100;
}

/* Center text if there's a back button */
.header-text:nth-child(2) {
    text-align: center;
}

.header-text > *:first-child {
    font-weight: 500;
    font-size: var(--font-huge);
    line-height: 1.5;
    color: var(--grey-dk);
}

.header-text * + * {
    font-size: var(--font-small);
    font-style: italic;
    color: var(--grey);
}

.main-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;
    padding: 0 32px;
}

.main-section--visible-overflow {
    composes: main-section;
    overflow: visible;
}

@keyframes body-animate-in {
    0% {
        transform: translateY(-50px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes container-animate-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes container-animate-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
