@import 'include.less';

.import.page {
	.page-flow-area {
		border: none !important;
	}
	.importer-logo {
		float: left;
		width: 200px;
		height: 130px;
	}
	.importer-content {
		float: left;
		width: 400px;
		height: 130px;
		h1 {
			color: @grey-dk; /* TODO: standardize color */
			font-size: 18px;
			margin: 12px 0;
		}
		dd {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		input {
			width: 180px;
		}
	}

	.importer {
		display: none;
	}

	.importer-highrise {
		.importer-logo {
			background: url('@{img-root}/import/highrise.png') 30px 0px no-repeat;
		}
	}
	.importer-google-contacts {
		.importer-logo {
			background: url('@{img-root}/import/icons/icon-gsuite_2x.png') 30px 0px no-repeat;
			background-size: 118px 118px;
		}
	}
	.importer-account-contact-csv {
		.importer-logo {
			background: url('@{img-root}/import/accounts-contacts.svg') 30px 0px no-repeat;
		}
		input {
			width: 100%;
			border: none;
		}
	}
	.importer-completed {
		display: block;
	}

	.import-type-vcard .importer-vcard {
		display: block;
	}
	.import-type-apple-contacts .importer-apple-contacts {
		display: block;
	}
	.import-type-highrise .importer-highrise {
		display: block;
	}
	.import-type-salesforcezip .importer-salesforcezip {
		display: block;
	}
	.import-type-google-contacts .importer-google-contacts {
		display: block;
	}
	.import-type-account-contact-csv .importer-account-contact-csv {
		display: block;
	}
	.import-type-office365-contacts .importer-office365-contacts {
		display: block;
	}
	.import-type-quickbooks .importer-quickbooks {
		display: block;
	}

	.importer-completed {
		.importer-logo {
			background: url('@{img-root}/import/complete.png') 30px 0px no-repeat;
		}
	}

	.export-instructions {
		font-size: @normal;
		h3 {
			margin: 1em 0;
		}
		ol {
			list-style-type: decimal;
			padding-left: 25px;
		}
		a {
			color: @blue;
			&:hover {
				color: @blue-dk;
			}
		}
		em {
			font-style: normal;
			font-weight: 500;
		}
		.screenshot {
			display: block;
			margin: 1em auto;
			max-width: 100%;
		}
	}

	//import form - so next button isn't in the boonies
	form {
		max-width: 1000px;
	}

	// mapping
	dl.import-mapper {
		margin-top: 18px;
		float: left;
		overflow: visible;
		dt {
			float: left;
			clear: left;
			width: 200px;
			font-size: 12px;
			color: @grey; /* TODO: standardize color */
			font-weight: 500;
			text-align: right;
			margin: 6px 6px 18px 0;
		}

		dd {
			white-space: nowrap;
			margin: 2px 0 8px 0;
			float: left;
			width: 260px;

			small {
				#util > .excerpt-crop > .ellipsis;
				font-size: 11px;
				font-style: italic;
				color: @grey-lt; /* TODO: standardize color */
				display: block;
				padding: 2px 0 0 5px;
				width: 260px;
			}
		}

		.tselect {
			max-width: none;
			.tselect-tier {
				width: 150px;
			}
		}
	}

	div.import-preview {
		float: right;
		margin-top: 18px;
		width: 220px;
		padding-left: 15px;
		border-left: 1px solid @off-white-dk; /* TODO: standardize color */

		.controls {
			position: relative;
			height: 18px;
			padding-bottom: 15px;
			border-bottom: 1px solid @off-white-dk; /* TODO: standardize color */
			margin-bottom: 15px;
			.action-previous {
				position: absolute;
				left: 0;
				z-index: 10;
			}
			strong {
				text-align: center;
				width: 100%;
				position: absolute;
			}
			.action-next {
				position: absolute;
				right: 0;
			}
		}

		.info {
			margin-bottom: 8px;
			color: @grey-lt;
			font-size: 12px;
			font-style: italic;
		}

		.preview {
			margin-bottom: 20px;
			h4 {
				font-size: 14px;
				margin-bottom: 4px;
				.icon {
					display: inline-block;
					vertical-align: -2px;
				}
			}
			.card-pseudo {
				padding: 6px;
				font-size: 12px;
				line-height: 16px;
				border: 1px solid white;
				span.name {
					font-weight: 500;
				}
				span {
					display: block;
				}
			}
		}

		.preview-account {
			h4 .icon {
				#icon > .card-lead-account;
			}

			.card-pseudo {
				background-color: @plum-lt;
				outline: 1px solid @plum-dk;
				#util > .gradient > .no-ie(@plum-lt, @plum);
			}
		}

		.preview-contact {
			h4 .icon {
				#icon > .card-contact-contact;
			}

			.card-pseudo {
				background-color: @navy-lt;
				outline: 1px solid @navy-dk;
				#util > .gradient > .no-ie(@navy-lt, @navy);
			}
		}
	}

	.import-status {
		padding-bottom: 64px;

		.import-buttons {
			float: right;
			width: 240px;
			text-align: right;

			.button-rounder {
				margin-bottom: 10px;
			}
		}

		h1.styled {
			font-size: 32px;
			line-height: 40px;
		}
		h2 {
			clear: both;
		}

		ol.list-big-green {
			li {
				padding: 4px 0;
				list-style-type: none;
				line-height: 20px;
				font-size: 14px;

				div.icon {
					float: left;
					width: 26px;
					height: 20px;
					margin-right: 8px;
					background: url('@{img-root}/buttons/choose-sprite.png') no-repeat left top;
				}

				&.state-failure {
					font-weight: 500;
					color: black;
					div.icon {
						background-image: url('@{img-root}/buttons/failure-sprite.png');
					}
				}

				&.state-completed {
					color: black;
					div.icon {
						background-position: 0 -40px;
					}
				}

				&.state-inprogress {
					font-weight: 500;
					color: black;
					div.icon {
						background-position: 0 -20px;
					}
				}

				&.state-future {
					color: @grey-lt;
				}
			}
		}
		ol.import-list {
			li {
				width: 400px;

				span {
					display: block;
					float: right;
					font-size: 11px;
					font-style: italic;
					color: @grey-lt;
					font-weight: normal;
				}
			}
		}
		dl.import-mapper {
			margin-top: 0;
			dt {
				width: 140px;
			}
		}

		.import-error,
		.import-error-excerpt {
			font-size: 11px;
		}
		.import-error {
			display: none;
		}
	}

	dl.form-import-options,
	dl.form-import-summary {
		dt {
			width: 200px;
		}
		dd {
			margin-left: 202px;
		}
	}

	.import-subheader {
		padding: 8px 0;

		h3 {
			line-height: 21px;
			color: @grey-dk;
			font-size: @big;
		}

		p {
			color: @grey;
			font-size: 15px;
			line-height: 19px;
		}
	}

	#onboarding-header {
		margin: 0;
	}

	.import-selection-main {
		margin: 0 -8px; //negative margin offsets 8px margin of cards
		display: flex;
		flex-wrap: wrap;

		.jumping-off-card {
			flex: 1 0 385px; //385 allows two column layout until sidebar collapse

			&:empty {
				height: 0;
				border: none;
				margin: 0 8px;
			}
		}
	}

	.previous-imports {
		margin-top: 16px;
		// standard list resets
		.list-standard-topper {
			background: none;
			border: none;
			box-shadow: none;
			background-color: @grey;
			padding: 16px;
			color: white;
			border-radius: 3px 3px 0 0;

			h3 {
				font-size: 17px;
			}
		}
	}

	.white-glove-confirmation {
		p {
			font-size: 14px;
		}
	}
}
