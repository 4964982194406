.portal .ui-modal--onboarding-funnel {
	height: 600px;

	.slide-graphic {
		background-size: 90% !important;
    background-position: 50% 100%;
		width: 579px;
		height: 317px;
		margin: 0 auto;
	}

	.slide-one-graphic {
		.2x('@{img-root}/modals/onboarding-funnel/funnel_slide1_graphic.png', 'no-repeat', 579px, 317px);
	}

	.slide-two-graphic {
		.2x('@{img-root}/modals/onboarding-funnel/funnel_slide2_graphic.png', 'no-repeat', 579px, 317px);
	}

	.slide-three-graphic {
		.2x('@{img-root}/modals/onboarding-funnel/funnel_slide3_graphic.png', 'no-repeat', 579px, 317px);
	}

	.slide-four-graphic {
		.2x('@{img-root}/modals/onboarding-funnel/funnel_slide4_graphic.png', 'no-repeat', 579px, 317px);
	}

	h1 {
		font-size: 28px;
		line-height: 36px;
		text-align: center;
		color: @grey-dk;
		border-top: 2px solid @off-white-dk;
		width: 80%;
		margin: 0 auto;
		padding: 20px 0 10px 0;
	}

	p {
		font-size: 21px;
		line-height: 30px;
		text-align: center;
		width: 60%;
		margin: 0 auto;
		height: 80px; // Add height to <p> so buttons don't jump when number of lines changes
	}

	.ui-btn--xlarge {
		margin-right: 20px;
	}
}
