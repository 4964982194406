// when in the email table of
.state-email .mentions {
	display: none;
}

.mentions-input-box {
	.mentions {
		padding: 0px !important;
		& > div {
			line-height: 15px;
			& > strong {
				background: @peach;
				border-bottom: none;
			}
		}

		.timeline-creator & {
			padding: 0 16px !important;
		}
	}

	.mentions-autocomplete-list {
		margin: 0;
		overflow: auto;
		max-height: 200px;

		li {
			display: block;
			border-bottom: 1px dotted @off-white-md;
			font-size: @small;
			cursor: pointer;
			height: auto;
			padding: 8px;

			&:last-child {
				border-bottom: none;
			}
			&:nth-child(odd) {
				background: @off-white;
			}
			&:hover {
				background: @color-list-selected !important;
			}
			&.active {
				background: @color-list-hovered !important;
			}

			.extended-info {
				display: none;
			}

			&.autocomplete-item-type-account {
				.avatar {
					background: @plum url('@{img-root}/layout/avatar-company.svg') no-repeat 50% 50%;
					background-size: 60%;
				}
				.name {
					color: @plum-dk !important;
				}
			}

			&.autocomplete-item-type-user,
			&.autocomplete-item-type-team {
				.avatar {
					background-color: @orange-dk; // Use the default person icon but just change the bg color
					background-size: 90%;
				}
				.name {
					color: @orange-dk !important;
				}

				&.type-disabled {
					opacity: 0.5;
					.name {
						color: @grey-lt !important;
					}
				}
			}

			&.autocomplete-item-type-team {
				.avatar {
					background: @orange-dk url('@{img-root}/layout/avatar-team.svg') no-repeat 50%
						100%;
				}
				img {
					display: none;
				} // As we don't have images for teams
			}

			&.autocomplete-item-type-lead {
				.avatar {
					background-color: @green;
				}
				.name {
					color: @green-dk;
				}
				.line-1 {
					color: @plum-dk;
				} // primary account name
				.line-2 {
					color: @navy-dk;
				} // primary contact name
			}

			.primary-info {
				width: 249px;
			}
			.secondary-info {
				font-size: @tiny;
				float: right;
				width: 200px;
				color: @grey-lt;
			}
			p {
				line-height: 16px;
				margin: 0 0 0 8px;
			}
			.details {
				font-size: @tiny;
			}
		}
	}
}
