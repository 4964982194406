.drip-sequence-engagement {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
}

.engagement-container {
    padding-right: 4px; /* Not sure why this padding is missing */
}

.performance-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
}

.performance-container > :first-child {
    margin: 0; /* Removing margin from label */
}

.performance-stats span {
    font-weight: 500;
}

.performance-stats > :first-child {
    padding-right: 16px;
    border-right: 2px solid var(--off-white-dk);
    display: inline;
}

.performance-stats > :last-child {
    padding-left: 16px;
    display: inline;
}

.message-list-container {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    margin-top: 8px;
}

.message-list-container ul > li:not(:last-child) {
    border-bottom: 1px solid var(--off-white-dk);
}
