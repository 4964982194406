.modal-body {
    overflow-y: auto;
    padding: 26px 48px 16px 32px;
    position: relative;
}

.modal-body--fullheight {
    composes: modal-body;
    height: 100%;
}

.right-frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
}

.left-frame {
    position: relative;
    flex-basis: 32%;
    height: 100%;
    background-color: var(--onboarding-help);
    color: var(--off-white);
    flex-shrink: 0;
    padding: 26px 48px 48px 32px;
    box-sizing: border-box;
}

.step-header {
    font-size: var(--font-huge);
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 20px;
}

.left-frame > svg {
    position: absolute;
    height: 68px;
    left: 38px;
    bottom: 100px;
}

.nav-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 24px 32px;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin: 24px 32px;
}
