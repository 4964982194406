.sidebar-form {
    position: relative;
    padding-top: 16px;
    box-sizing: border-box;
    /* Height of header */
    height: calc(100% - 65px);
    padding-bottom: 0;
}

.sidebar-form--deleteable {
    composes: sidebar-form;
    padding-bottom: 48px;
}

.form-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    /* Needed for react-select to expand correctly */
    position: relative;
}

.form-container > * {
    margin-left: 16px;
    margin-right: 16px;
}

.delete-button-container {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 16px;
}
