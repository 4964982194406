.cta-video-banner {
    display: flex;
    align-items: center;
    background-color: #fff;
    opacity: 1;
    transition: 300ms opacity ease;
    padding: 16px 24px;
    position: relative;
}

.message-container {
    padding-right: 16px;
}

.play-button {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    z-index: 10;
    transition: 300ms ease-in-out opacity;
}

.thumbnail {
    position: relative;
    width: 100px;
    height: 55px;
    margin-right: 16px;
    cursor: pointer;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

.thumbnail:hover .play-button {
    opacity: 1;
}

.thumbnail > img {
    width: 100%;
    height: 100%;
}

.closing {
    composes: cta-video-banner;
    opacity: 0;
}

.primary-action {
    color: var(--blue);
}

.primary-action button {
    padding: 0;
}

.remove-button {
    position: absolute;
    right: 24px;
}
