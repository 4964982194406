.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.container > *:not(:last-child) {
    margin-bottom: 16px;
}

.flash-banner-cta-container {
    /* This makes sure out link won't wrap to another line */
    width: 126px;
    display: flex;
    align-items: center;
}
