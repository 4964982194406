.peanut-preview-container {
    width: 100%;
    height: 100%;
}

.error-state {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    /* This is so that the error state will be above edit button */
    z-index: 2;
}

/* This adds padding around the form preview rendered by Peanut */
.peanut-form > :first-child {
    padding: 64px 32px;
    box-sizing: border-box;
    max-height: 100%;
}

.peanut-form--thumbnail {
    composes: peanut-form;
    user-select: none;
    pointer-events: none;
}

.peanut-form--thumbnail > :first-child {
    padding: 0;
}
