.report-date-range-picker button {
    background-color: var(--blue) !important;
    color: #fff !important;
    font-weight: normal !important;
    font-size: var(--font-small) !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.report-date-range-picker button:hover,
.report-date-range-picker button:active {
    background-color: var(--blue-dk) !important;
}
