.email-template-designer-form-component {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.bottom-icon {
    display: inline-block;
    margin-right: 3px;
    margin-left: 3px;
}

.disabled-notice > a {
    font-size: 13px;
}

.bottom-icon > svg {
    margin-bottom: -2px;
}

.footer-bar {
    z-index: 10;
}
