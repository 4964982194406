@value swatch-size: 30px;

.color-swatch-button {
    width: swatch-size;
    height: swatch-size;
    background-color: currentColor;
    border: 1px solid var(--white);
    border-radius: 3px;
}

.color-swatch-button--rounded {
    composes: color-swatch-button;
    border-color: transparent;
    border-radius: 50%;
}

.color-swatch-button--selected {
    composes: color-swatch-button;
}

.color-swatch-button:hover,
.color-swatch-button:focus {
    border-color: currentColor;
}

.color-swatch-button--selected,
.color-swatch-button--selected:hover,
.color-swatch-button--rounded:hover {
    border-color: var(--grey-lt);
}
