.cta-container {
    padding: 32px;
    width: 320px;
    display: flex;
    flex-direction: column;
}

.cta-container > * + * {
    margin-top: 16px;
}

.logo-title {
    display: flex;
    align-items: center;
}
