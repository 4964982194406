.table-header-cell {
    composes: cell from '../styles.css';
    position: relative;
    color: var(--grey-dk);
    white-space: nowrap;
    font-weight: 500;
}

.compact {
    padding: 8px;
}

.bordered {
    border-right: var(--border-hairline);
}

.dk-cell-border {
    border-right: 1px solid var(--form-border);
}

.grey-cell-text {
    color: var(--grey);
}

.sortable:hover,
.sortable:focus {
    text-decoration: underline;
    background-color: #fbf9f6;
}

.right-align {
    justify-content: flex-end;
}

.center-align {
    justify-content: center;
}

.left-align-header {
    justify-content: flex-start;
}
