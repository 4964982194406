.sequence-list-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* Name and sequence progression */
.left-container {
    display: flex;
    align-items: center;
    min-width: fit-content;
}

.left-container > :last-child {
    margin-left: 8px;
    position: relative;
    top: 2px;
}

/* Scheduled/last sent time */
.right-container {
    display: flex;
    align-items: center;
    text-align: right;
}

.detail-container {
    display: flex;
    justify-content: flex-end;
}

/* This makes the delete x turn red when the detail container is hovered */
.detail-container:hover span svg {
    fill: var(--rose);
}

.detail-container button {
    padding: 0;
}

.detail {
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

.button-container {
    position: relative;
    bottom: 1px; /* Button just barely isn't centered with text */
}

/* Remove padding around the remove button */
.button-container > :first-child {
    padding: 0 8px;
}
