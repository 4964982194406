.container {
    height: 100%;
    overflow: auto;
}

.content-container {
    display: inline-block;
    box-sizing: border-box;
    width: 33.33%; /* IE only keeps two decimal points, so can't use calc(100% / 3) */
}

.dash-container {
    box-sizing: border-box;
    padding: 16px;
    border-radius: 6px;
    cursor: pointer;
}

.dash-container-disabled {
    composes: dash-container;
    z-index: -1;
}

.dash-container-disabled .dash-label,
.dash-container-disabled .dash-byline {
    color: var(--grey-lt);
}

.dash-container-disabled .dash-image-container:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.7;
}

.dash-image-container {
    position: relative;
    width: 144px;
    height: 106px;
    margin: 0 auto;
}

.dash-image {
    width: auto;
    height: 100%;
    opacity: 0.9;
}

.dash-label {
    display: flex;
    justify-content: center;
    font-size: var(--font-big);
    font-weight: 500;
    color: var(--blue);
    margin: 16px 0 8px 0;
}

.dash-byline {
    font-size: var(--font-normal);
    color: var(--grey);
    line-height: 21px;
    text-align: center;
}

.upgrade-arrow {
    position: relative;
    left: 12px;
}

.dash-container:hover {
    background: var(--off-white);
}

.dash-container:hover .dash-image {
    opacity: 1;
}

.dash-container:hover .dash-label-text {
    color: var(--blue-dk);
}

.dash-container:hover .dash-byline {
    color: var(--grey-dk);
}

/* No hover states for disabled buttons */
.dash-container-disabled:hover {
    background: unset;
    opacity: unset;
}

.dash-container-disabled:hover .dash-label-text {
    color: unset;
}

.dash-container-disabled:hover .dash-byline {
    color: var(--off-white-dk);
}
