@value thumbnailCardWidth: 300px;
@value thumbnailCardHeight: 290px;

.thumbnail-preview-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.thumbnail-preview-list > * {
    flex: 0 0 thumbnailCardWidth;
    margin: 8px;
}

.thumbnail-preview-list--single-row {
    composes: thumbnail-preview-list;
    flex-wrap: nowrap;
    overflow: hidden;
}

.thumbnail-preview-list--single-row > * {
    margin: 0;
}
.thumbnail-preview-list--single-row > *:not(:last-child) {
    margin-right: 16px;
}

.see-all-button {
    display: flex;
    padding-left: 16px;
}

.placeholder {
    background-color: var(--off-white);
    border-radius: 3px;
    height: thumbnailCardHeight;
}

.placeholder--white-background {
    composes: placeholder;
    background-color: var(--white);
}

.type-icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.disabled-tooltip {
    padding: 16px;
    max-width: 250px;
}
