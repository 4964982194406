.display-text {
    color: var(--grey);
}

.display-text-active {
    composes: display-text;
    color: #fff;
}

.display-text-active :global(.num-hidden) {
    color: #fff;
}

.text-container {
    display: flex;
    flex-direction: row;
    max-width: 275px;
}
