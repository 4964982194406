.newsletter-container {
    display: flex;
    width: calc(100% - 96px);
    padding: 32px;
    margin: 16px;
    border: 1px solid var(--off-white-dk);
}

.reporting-container {
    padding: 32px;
    border: 1px solid var(--off-white-dk);
    margin: 16px;
    display: flex;
    justify-content: center;
}

.preview-container {
    padding: 32px;
    border: 1px solid var(--off-white-dk);
    margin: 16px;
    display: flex;
    justify-content: center;
}

.next-sending-container {
    display: flex;
}

.next-sending-container span {
    /* Align the icon */
    margin-top: 1px;
    margin-right: 12px;
}

.expanded-filter-checkbox {
    height: 31px;
    min-width: 110px;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.expanded-filter-checkbox * {
    height: 100%;
}
