.contents {
    white-space: nowrap;
    display: flex;
    line-height: normal;
    align-items: center;
}

.contents > *:not(:last-child) {
    margin-right: 4px;
    flex-shrink: 0;
}

.name {
    white-space: nowrap;
}
