.row-container {
    border-bottom: 1px solid var(--off-white-md);
    list-style: none;
}

.row-container:last-child {
    border-bottom: none;
}

.link {
    display: flex;
    height: 87px;
    box-sizing: border-box;
    padding: 5px 15px;
    min-width: 0;
}

.link:hover {
    background-color: var(--color-list-hovered);
}

.link:hover .title {
    text-decoration: underline;
}

.arrow {
    width: 48px;
    height: 48px;
    margin-top: 14px;
}

.arrow-unchanged {
    composes: arrow;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    background-color: var(--off-white-md);
    margin-top: 14px;
}

.arrow-down {
    composes: arrow;
}

.arrow-up {
    composes: arrow;
    transform: scaleY(-1);
}

.details-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-width: 0;
    line-height: 24px;
    padding-left: 16px;
}

.title {
    font-size: var(--font-normal);
    color: var(--grey-dk);
}

.current-value-container {
}

.current-value {
    font-size: 26px;
    color: var(--grey-dk);
}

.current-value .currency-prefix {
    font-size: 24px; /* Currency symbol feels over sized if we don't slightly reduce it */
    color: var(--grey-lt);
    padding-right: 4px;
    position: relative;
    top: -1px;
}

.current-value .currency-suffix {
    color: var(--grey-lt);
    padding-left: 4px;
}

.previous-value {
    padding: 0 3px;
}

.currency-value {
}

.delta-description-container {
    font-size: var(--font-small);
    color: var(--grey-lt);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
