.container {
    position: relative;
    padding: 40px 24px 0 24px;
    overflow: auto;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
}

.close > button {
    padding-right: 0;
}

.explore {
    margin-top: 32px;
}
