.field-wrapper-container {
    width: 100%;
    position: relative;
}

.badge-container {
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    margin: 0 16px;
    border-bottom: 1px solid var(--off-white-dk);
    color: var(--grey-dk);
    line-height: 13px;
    font-family: IBM Plex Sans; /* this overrides the user's selected font with our standard one for menu items */
}

.title {
    margin-bottom: 4px;
}

.display-selector {
    width: 25%;
    margin-left: 8px;
    font-family: IBM Plex Sans; /* this overrides the user's selected font with our standard one for menu items */
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin: 0 16px 8px;
    font-family: IBM Plex Sans; /* this overrides the user's selected font with our standard one for menu items */
}

.required {
    padding-bottom: 4px;
}
