#entity-list-people,
#entity-list-companies,
#entity-list-leads {
	.popover.mailchimp-popover {
		font-family: IBM Plex Sans;
		border: none;

		.popover-hat {
			background: none;
			box-shadow: none;
			border: none;
			text-shadow: none;
			padding: 10px;
			margin: 0;
			box-sizing: border-box;
			border-bottom: 1px solid @off-white-md;

			&:after, &:before { display: none; }
		}

		.popover-x { font-size: 24px; }

		.popover-area {
			border-top: none;
			margin: 0;
			padding: 0 10px;

			p.info {
				font-size: 15px;
				line-height: 21px;
				margin: 0 0 30px 0;
				padding: 5px 15px;
			}
		}

		.popover-buttons {
			border: none;
			padding: 10px;
			margin: 0;
			background: none;
			box-shadow: none;
			text-shadow: none;
			font-size: 15px;

			&:after, &:before { display: none; }
			a.action-cancel { display: none; }

			a.action-export-mailchimp {
				background-color: @blue;
				color: white;
				padding: 8px 16px;
				bottom: 5px;
				font-size: 15px;
				border-radius: 2px;

				&:hover {
					background-color: @blue-dk;
					color: white;
					text-decoration: none;
				}
			}
		}
	}
}
