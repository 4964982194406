.edition-sending-state {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin: 64px auto 0 auto;
    max-width: 400px;
    text-align: center;
}

.edition-sending-state > * {
    margin-bottom: 8px;
}

.edition-sending-state > a {
    margin-top: 32px;
}

.svg-container {
    width: 200px;
    margin-bottom: 16px;
}
