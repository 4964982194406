.upsell-button {
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 8px 12px;
    justify-content: center;
    font-size: var(--font-small);
    border: 1px solid var(--blue);
    color: var(--blue);
    background-color: var(--white);
    width: 100%;
}

.upsell-button:hover,
.upsell-button:focus {
    background-color: var(--off-white);
    color: var(--blue-dk);
}

.upsell-button svg {
    margin-left: 8px;
}
