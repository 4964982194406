.content {
    display: none;
}

.content--expanded {
    display: block;
}

.image {
    width: 40px;
    padding-right: 12px;
}

.intro {
    color: var(--grey-dk);
    font-size: var(--font-normal);
    line-height: 20px;
    font-weight: 500;
}

.fun {
    font-weight: 500;
    color: var(--grey-dk);
}

.fact {
    padding: 12px;
    background-color: var(--blue-lt);
    border-radius: 3px;
}
