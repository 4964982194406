.result {
    width: 100%;
    box-sizing: border-box;
}

.result button:hover,
.result button:focus,
.result button:active {
    background-color: var(--blue-lt);
}
