.calendar-container {
    width: 40%;
    border: 1px solid var(--off-white-dk);
}

.right-container {
    flex-grow: 1;
    margin-left: 16px;
}

.form-container {
    display: flex;
}

.form-container > * {
    flex-grow: 1;
    padding: 8px;
}

.date-container {
    border: 1px solid var(--form-border);
    border-radius: 3px;
    padding: 6px;
    height: 17px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    cursor: pointer;
}

.send-immediately-container {
    height: 48px;
}
