.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.selectable-list-container {
    position: relative;
    border: solid 1px var(--off-white-dk);
}

.selectable-list-container ul {
    height: 169px;
}

.label {
    font-size: var(--font-normal);
    color: var(--grey-lt);
    margin-bottom: 10px;
    font-weight: normal;
}

.type-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.my-lists-checkbox {
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 10px;
}

.create-new-list-link {
    margin-top: 10px;
    color: var(--blue);
    padding-left: 11px; /* 10px in selectable list + border for alignment */
}

.create-new-list-link:hover {
    color: var(--blue-dk);
    text-decoration: underline;
}

.empty-state {
    margin-top: 100px;
    font-size: var(--font-normal);
    color: var(--grey);
}
