.smtp-form {
    display: flex;
    flex-direction: column;
}

.form-body {
    display: flex;
    flex-direction: column;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.form-row > div {
    width: calc(50% - 8px);
    box-sizing: border-box;
}

.smtp-form > button {
    display: flex;
    align-self: flex-end;
}

.smtp-error-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.smtp-error-container strong {
    margin: 8px 0;
}
