.outer-container {
    margin: 8px 12px;
    width: 100%;
}

.inner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
    min-height: 100%;
}

.fields-container {
    /* 529px is 500px for the field, plus 29px for the chrome around the field */
    width: 529px;
    margin-right: 28px; /* Centers container by adding a margin the same width as field drag handles */
    display: flex;
    flex-direction: column;
    padding: 100px 0;
}

.header-container {
    margin-left: 45px; /* Left aligns header with fields */
    padding-bottom: 32px;
}

.draggable-list-container * {
    overflow: visible; /* This overrides the overflow: hidden so the remove badge shows properly */
}

.field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 16px 0 16px;
    width: calc(100% - 32px); /* Full width minus padding */
}

.header-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;
    padding: 0 16px;
    width: calc(100% - 32px); /* Full width minus padding */
}

.loading {
    margin: 3px 0 32px 29px; /* Set to match the placement of our actual field */
}

.submit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    width: calc(100% - 60px); /* Full width minus padding and border */
    margin: 24px 0 0 26px; /* This lines up the submit button with the fields */
}

.submit-container:hover {
    background-color: var(--off-white);
}

.spacer {
    height: 16px;
}
