.email-template-list {
    padding: 0 16px;
}

.body {
    width: calc(100% - 16px);
}

.error-text {
    color: var(--rose);
}
