.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--off-white);
    padding: 0 8px;
}

.container-week {
    composes: container;
    background-color: #fff;
}

.text {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: var(--grey-lt);
    font-weight: 500;
    height: 30px;
    user-select: none;
}

.container-week .text {
    align-items: flex-end;
    text-transform: uppercase;
    height: 25px;
}
