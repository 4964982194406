#ui-datepicker-div {
	font: 12px/14px 'IBM Plex Sans', Helvetica, Arial, sans-serif;
	width: 175px;
	padding: 0;
	z-index: 9100;
	.ui-datepicker-calendar-wrapper {
		margin: 6px 12px 12px 12px;
	}
	.ui-corner-all {
		border-radius: 0px;
		-moz-border-radius: 0px;
	}

	&.popover.orientation-south .popover-nub {
		.timeline-sprite();
		background-position: -280px top;
	}

	.ui-datepicker-header {
		background: none;
		border: none;
		height: 29px;

		.ui-datepicker-title {
			color: black;
			font-size: 14px !important;
			line-height: 24px !important;
			text-align: left;
			margin: auto;
			border-bottom: 1px dotted gray;
		}
		.ui-datepicker-prev {
			right: 32px;
			left: auto;
		}
		.ui-datepicker-current {
			position: absolute;
			right: 12px;
			left: auto;
			top: 4px;
		}
		.ui-datepicker-prev,
		.ui-datepicker-current,
		.ui-datepicker-next {
			span {
				margin-left: auto;
			}
			margin-top: 1px;
			border-radius: 5px;
			cursor: pointer;
			.ui-icon {
				background-image: url(/include/jquery-ui-theme/images/ui-icons_888888_256x240.png) !important;
			}
			&.ui-state-hover {
				.ui-icon {
					background-image: url(/include/jquery-ui-theme/images/ui-icons_222222_256x240.png) !important;
				}
				border-color: transparent;
				background: transparent;
			}
		}
	}
	table.ui-datepicker-calendar {
		padding: 0 6px;
		margin-top: -15px;
		border-spacing: 1px;

		thead {
			border-bottom: 1px solid gray;
		}

		td,
		th {
			a,
			span {
				font-size: 11px !important;
				border: none;
				// padding: 2px;
				margin: 0;
				background: none;
				text-align: center;
			}
		}

		th {
			width: 11px !important;
			padding-bottom: 2px;
			text-transform: uppercase;
			text-align: center;
			color: @grey-lt;
		}

		td {
			width: 11px !important;
			padding: 0;
			margin: 0px;
			border: none;

			&.ui-datepicker-week-end a {
				&:hover {
					background: @off-white-dk !important;
				}
				&:active {
					background: @off-white-dk !important;
				}
			}

			.ui-state-highlight {
				color: @orange;
			}
			.ui-state-active {
				background: @blue !important;
				color: white;
			}
			&:hover {
				background: @blue-lt !important;
			}
			&:active {
				background: @blue !important;
			}
		}
	}
}

.browser-wk {
	#ui-datepicker-div .ui-datepicker-header {
		margin-bottom: 7px;
	}
}
