.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--off-white-md);
}

.tabs {
    align-self: flex-end;
}

.tab {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
}
