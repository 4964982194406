#lead {
	.lead-columns {
		display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
		display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
		display: -ms-flexbox; /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Chrome */
		display: flex;
		flex-direction: row;
		-webkit-flex-direction: row;
		flex-wrap: nowrap;
		-webkit-flex-wrap: nowrap;
		align-items: stretch;
		-webkit-align-items: stretch;

		> .lead-column {
			padding: 16px 0 16px 0;
		}
		> .lead-column.lead-column-left {
			background-color: white;
			display: block;

			//growing and shrinking is allowed
			-webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
			-moz-box-flex: 1; /* OLD - Firefox 19- */
			width: 600px; /* For old syntax, otherwise collapses. */
			-webkit-flex: 1; /* Chrome */
			-ms-flex: 1; /* IE 10 */
			flex: 1;

			min-width: 600px;
			background-color: white;
			padding-left: 16px;
			padding-right: 16px;
		}

		> .lead-column.lead-column-right {
			position: relative;
			width: 381px;
			flex-shrink: 0;

			-webkit-box-ordinal-group: 2;
			-moz-box-ordinal-group: 2;
			-ms-flex-order: 2;
			-webkit-order: 2;
			order: 2;

			background-color: white;
			border-left: 1px solid @off-white-dk;
			padding-left: 16px;
			padding-right: 16px;

			.lead-sidebar {
				width: 381px;
			}
		}
	}

	.top-custom-fields {
		.type-currency.eip-container {
			.eip-input.amount {
				width: 200px;
			}
		}
	}

	// these are a global style, but were only recently introduced to leads, where we use them for spacing, too :\
	.section-label {
		margin: 34px 0 14px 0;
		span.box-header-count {
			font-size: @small;
			padding-left: 4px;
		}
	}

	#app-react-mount {
		.lead-column-right {
			padding-top: 0;
		}
	}

	#app-react-loading-content {
		position: absolute;
		height: 100%;
		width: 100%;
		box-sizing: border-box;
		border-left: 200px solid transparent;
		z-index: -1;
	}

	#app-react-loading-content.widebar {
		border-left: 200px solid transparent;
	}
}

// Fields in center of lead
.custom-fields {
	#util > .clearfix();
	margin-bottom: 20px;
	> div {
		float: left;
		width: 100%/3;
		padding-right: 15px;
		box-sizing: border-box;
		&:last-child {
			padding-right: 0;
		}
	}
	.top,
	.body {
		#util > .border-box();
	}

	.top h3 {
		font-size: @normal;
		color: @grey-dk;
		background: @off-white-md;
		border-radius: 3px 3px 0 0;
		padding: 4px 8px;
	}

	.body {
		height: 100px;
		overflow-y: scroll;
		padding: 6px;
		background: white;
		border: 2px solid @off-white-md;
		border-radius: 0 0 3px 3px;
		.fieldrow {
			margin: 0;
			&:hover,
			&.state-editing {
				background-color: @yellow-lt;

				input.type-text,
				textarea.type-textarea {
					width: ~'calc(100% - 24px)';
				}
			}
			div.value {
				width: 100%;
				white-space: normal;
				line-height: 20px;
				select {
					width: 100%;
					max-width: none;
					margin-bottom: 6px;
				}
				span.empty {
					font-style: italic;
					padding: 0 0 5px 2px;
				}
				span.value {
					width: 100%;
				}
			}

			&.type-multiple,
			&.type-enum {
				&:hover {
					// These aren't editable so no need for hover
					background: none;
				}
				// Hide the delete button on multiple choice custom fields as you can't delete them
				.action-delete {
					display: none;
				}
			}
			&.type-text,
			&.type-email,
			&.type-date,
			&.type-string,
			&.type-currency {
				.action-delete {
					top: -2px;
					right: 0;
				}
				.action-cancel {
					// Hide the undo button for now as I don't think we need an undo
					display: none;
				}
				.action-edit {
					cursor: pointer;
					right: 15px;
				}
			}
			&.type-date {
				.action-edit {
					right: 17px;
					top: 2px;
				}
				.value a {
					//make sure not to hide this element - it acts as the datepicker's anchor
					text-decoration: none;
				}
			}
			&.type-location .buttons {
				display: none;
			}
		}
	}
}

.sidebar-sources {
	box-shadow: none;
	border-bottom: 1px solid @off-white-md;
	border-radius: 0;
	h2 {
		margin-bottom: 0 !important;
	}
	.select2-container {
		&:hover,
		&.select2-container-active {
			background: white;
		}
		ul.select2-choices {
			padding-top: 0;
			.select2-search-choice {
				cursor: pointer;
				&:hover {
					color: @grey-dk;
					background: @off-white-dk;
				}
			}
			li.select2-search-choice {
				line-height: 26px;
				color: @grey;
				background: @off-white-md;
				border: none;
				box-shadow: none;
				margin: 5px 9px 0 0;
				padding: 0 8px 0 20px;
				div {
					padding: 0;
					border: none;
				}
				a.select2-search-choice-close {
					top: 7px;
					left: 6px;
				}
			}
			li.select2-search-field {
				margin-top: 7px;
			}
			&:hover {
				background: white;
			}
		}
	}
}

.sidebar-notes {
	.box-header .icon {
		#icon > .box-header-notes;
	}

	textarea {
		#util > .rounded-corners();
		border: 1px solid @off-white-dk; /* TODO: standardize color */
		padding: 4px;
		width: @page-sidebar-width - 28;
		outline: 0;
		resize: vertical;
	}

	.note-add {
		&.state-selected .buttons {
			display: block;
		}

		.buttons {
			display: none;
			text-align: right;
		}
	}

	.note {
		padding: 0;
		position: relative;

		.eip-buttons {
			text-align: right;
			padding-right: 8px;
		}

		&.state-editing {
			.box-meta {
				display: none;
			}
		}
		&.blink-success {
			background-color: @green-lt;
		}
	}
}

.sidebar-files {
	.icon {
		#icon > .box-header-files;
	}

	.file {
		padding: 0;
		position: relative;

		.file-body {
			padding: 5px 8px;
			position: relative;
			background-color: @off-white-dk;
			overflow: hidden;
			text-overflow: ellipsis;

			&.processing {
				color: @grey-lt;
			}
		}
	}

	.behavior-upload {
		position: relative;
	}
	.sidebar-file-upload {
		display: none;
	}
}
