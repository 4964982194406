.person-card-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: var(--font-small);
    text-align: left;
    line-height: 17px;
    padding: 16px;
    gap: 16px;
}

.details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    color: var(--grey);
}

.details > :first-child {
    color: var(--grey-dk);
    font-weight: 500;
}

.name-button-container {
    position: relative;
    width: 100%;
    height: 17px; /* match line height */
}

.name-button-container > div {
    padding: 0;
    color: var(--person);
    /* needs to be absolute because cannot have a button "inside" a button */
    position: absolute;
    top: 0;
    left: 0;
}

.name-button-container > div:hover,
.name-button-container > div:focus,
.name-button-container > div:active {
    text-decoration: underline;
}

.has-phone-email {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    color: var(--grey);
}

.has-phone-email > * {
    display: flex;
    align-items: center;
    gap: 6px;
}

.has-phone-email > * > :first-child {
    color: var(--color-success);
}

.has-phone-email .missing,
.has-phone-email .missing > :first-child {
    color: var(--icon-primary-action);
}
