.search-bar-popover-container {
    padding: 16px 16px 0 16px;
    width: calc(100% - 32px);
    height: calc(100% - 54px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    overflow: auto;
}

.search-results-grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
    justify-content: center;
}

.search-section {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

@media (max-width: 820px) {
    .search-results-grid {
        grid-template-columns: 1fr;
    }
    .search-section {
        max-width: 100%;
    }
}

.footer-divider {
    position: absolute;
    bottom: 36px;
    width: calc(100% - 32px);
}

.footer-container {
    position: absolute;
    bottom: 0;
    right: 16px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    width: 100%;
    padding: 8px 0;
}

.keyboard-key {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    padding: 4px;
    background-color: var(--off-white);
    border-radius: 3px;
}
