.pill-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--tag-grey-lt);
    border-radius: 15px;
    padding-left: 6px;
    padding-right: 6px;
    gap: 4px;
}
