// Group all old button classes
.button,
.button-action,
.button-rounder,
.button-setting {
	position: relative; // Allowing for absolute positioned icons
	cursor: pointer;
	text-align: center; // not normally needed, but in cases like integration page where we fix a bunch of button widths, we want text centered
	text-decoration: none !important;
	font-size: @normal;
	font-weight: 400;
	line-height: 20px;
	color: @grey;
	background-color: @button-secondary;
	display: inline-block;
	border-radius: 3px;
	box-sizing: border-box;
	padding: 7px 10px;
	margin: 0;
	white-space: nowrap;
	&:hover {
		color: @grey-dk;
		background-color: @button-secondary-dk;
		.icon-container::before { // FontAwesome icons
			color: @grey !important;
		}
	}
	&:focus {
		outline: none; // removed default dotted border
	}
	&.variant-grey {
		color: white;
		background-color: @grey-lt;
		&:hover {
			background-color: @grey;
		}
	}
	&.tint-blue,
	&.variant-blue {
		color: white;
		background-color: @blue;
		&:hover {
			background-color: @blue-dk;
		}
	}
	&.action-save,
	&.action-saveas,
	&.tint-green,
	&.variant-green {
		color: white;
		background-color: @green;
		&:hover {
			background-color: @green-dk;
		}
	}
	&.tint-red,
	&.variant-red {
		color: white;
		background-color: @rose;
		&:hover {
			background-color: @rose-dk;
		}
	}
	&.state-disabled,
	&.state-disabled:hover {
		color: white;
		background: @off-white-dk;
		cursor: default;
	}
	&.size-small {
		font-size: @small;
		padding: 3px 9px;
	}
	&.size-large {
		font-size: @big;
		padding: 10px 16px;
	}
	.icon {
		display: inline-block;
	}
	&.variant-icon {
		padding-left: 35px;
		span:before {
			content: "";
			position: absolute;
			top: 5px;
			left: 8px;
			width: 20px;
			height: 20px;
			.2x('@{img-root}/icons/buttons/rounder-icon-sprite.png', "no-repeat", 100px, 20px);
			background-position: 0 top;
		}
		&.action-followup span:before, &.action-schedule span:before { background-position: -20px top; }
		&.action-newlead span:before { background-position: -40px top; }
		&.action-revert span:before { background-position: -60px top; }
		&.action-redo span:before { background-position: -80px top; }
	}

	&.action-rightsignature {
		padding-top: 3px;
		padding-bottom: 0px;
	}

	&.state-selected,
	&.state-popover-opened {
		// !important so they override hover styles
		color: @grey-dk !important;
		background-color: @button-secondary-dk !important;
	}
}

// ===========
//
//	Settings buttons - currently only used on '/my-account' and 'setup/integration' pages.
//
// ===========
.button-setting {
	span.saving { display: none; }

	&.state-disabled {
		span { display: none; }
		span.saving { display: inline; }
	}
}

/* add-link buttons */
.button-add-link,
.button-subtract-link {
	cursor: pointer;
	color: @blue;
	padding-left: 22px !important; // Allows for width of icon
	&:hover {
		text-decoration: underline;
		color: @blue-dk;
	}
}
.button-add-link {
	background: url("@{img-root}/buttons/icon-add-link.svg") no-repeat left center;
  background-size: 16px 16px;
}
.button-subtract-link {
	background: url("@{img-root}/buttons/icon-subtract-link.svg") no-repeat left center;
  background-size: 16px 16px;
}


/* report-link 'buttons' */
a.link-report {
	color: @blue;
	.icon {
		vertical-align: -3px;
		width: 16px;
		height: 16px;
		display: inline-block;
		background: url("@{img-root}/icons/report-link-sprite.png") -48px 0 no-repeat;
		margin-right: 3px;
	}

	// span
	.count {
		font-size: 11px;
		color: @grey-lt;
	}

	&:hover {
		text-decoration: none;
		color: @blue-dk;

		.icon {
			background-position: -48px -16px;
		}

		&.link-report-leads .icon { background-position: -32px -16px; }
		&.link-report-accounts .icon { background-position: -16px -16px; }
		&.link-report-contacts .icon { background-position: 0px -16px; }
	}

	&.link-report-leads .icon { background-position: -32px 0px; }
	&.link-report-accounts .icon { background-position: -16px 0px; }
	&.link-report-contacts .icon { background-position: 0px 0px; }
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
	a.link-report .icon {
		background: url("@{img-root}/icons/report-link-sprite_2x.png") -48px 0 no-repeat;
		background-size: 64px 32px;
	}
}
