.container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 264px;
}

.empty-image-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 40px;
    left: 0;
}

.empty-image {
    height: 154px;
}

.empty-text {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    bottom: 28px;
    font-size: var(--font-big);
}
