.and-or-picker {
    position: absolute;
    left: calc(50% - 25px);
    top: -11px; /* technically 8px is centered, this looks better */
    width: 50px;
    height: 18px;
    background-color: #fff;
    padding: 1px 5px;
    box-sizing: border-box;
    text-align: center;
    color: var(--grey-lt);
    font-weight: 500;
    z-index: 1;
}

.btn {
    background-color: transparent !important;
    padding: 1px 4px !important;
    margin: 0 !important;
    min-width: 40px !important;
    color: var(--grey) !important;
}

.btn--hovered {
    composes: btn;
    background-color: var(--off-white-dk) !important;
    color: var(--grey-dk) !important;
}
