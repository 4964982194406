.container {
    max-width: 900px;
    display: grid;
    grid-template-columns: 250px auto;
    grid-gap: 16px;
}

.nevermind {
    margin-top: 16px;
    text-align: center;
}

@media (max-width: 1300px) {
    .left-side {
        display: none;
    }
}
