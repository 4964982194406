#lead {
	.lead-sidebar {
		.other-contacts {
			width: auto;
		}
	}

	.empty-placeholder.type-account {
		margin-bottom: 5px;
	}
	.account-card-container {
		.tile-account-top .account-name {
			#util > .excerpt-crop > .ellipsis;
			// Allow for x remove icon
			width: ~'calc(100% - 20px)';
			span {
				display: inline;
			}
		}
	}
	.contact-card-container {
		.tile-contact-top .first-line {
			margin-right: 20px; // Allow room for X button
			display: flex;
		}
		.contact-name {
			min-width: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.job-title {
			min-width: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.account-card-container.tile-empty .second-line .description {
		width: 61%;
	}
	.product-card-container,
	.competitor-card-container {
		font-size: @small;
		color: @grey-dk;
		width: 100%;
		.float-object-note {
			font-size: @small;
			color: @grey;
			background: white;
			border: 2px solid @brown-lt;
			border-top: none;
			border-radius: 0 0 4px 4px;
			margin: 0;
			padding: 6px 7px;
			textarea {
				width: 100%;
			}
			.fa {
				width: 14px; // Stops icon from snapping in on page load
			}
			&.state-editing {
				.fa {
					display: none;
				}
			}
		}
	}
	.competitor-card-container {
		.float-object-note {
			border-color: darken(@rose-lt, 3%);
		}
	}
	h2.section-label.custom-fields {
		margin-bottom: 0;
	}
	.eip-field {
		margin: 0;
		padding: 12px 8px;
		&:hover {
			background: none;
		}
	}

	.select-mini-market {
		max-width: 180px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		.selector {
			background: none;
			margin: 0;
			padding: 0;

			&:before {
				// Hide sort icon
				display: none;
			}

			&:hover {
				background: none;

				.uniformSpan {
					color: @blue-dk;
				}
			}

			.uniformSpan {
				cursor: pointer;
				color: @blue;
				background: none;
			}
		}
	}

	.marked-sidebar-label {
		float: right;
		font-weight: 500;
	}
}
