.minimized-email {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    right: 32px;
    width: 400px;
    height: 64px;
    box-sizing: border-box;
    padding: 0 16px;
    font-size: var(--font-normal);
    display: flex;
    align-items: center;
    background-color: white;
    border-top: 1px solid var(--off-white-dk);
    border-left: 1px solid var(--off-white-dk);
    border-right: 1px solid var(--off-white-dk);
    border-radius: 3px 3px 0 0;
    justify-content: space-between;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    user-select: none;
    cursor: pointer;
}

.minimized-email:hover,
.minimized-email:focus {
    background-color: var(--off-white);
}

.title {
    color: var(--grey-dk);
    font-size: var(--font-normal);
}

.buttons {
    flex-shrink: 0;
    padding-left: 16px;
}

/* Flipping minimize button to be an enlarge button */
.buttons > *:first-child {
    transform: rotate(180deg);
}

.summary {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 16px;
    overflow: hidden;
    text-align: left;
}

.subject {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    color: var(--grey-dk);
    margin-bottom: 4px;
}
