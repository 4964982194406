@value fadeDuration: 150ms;

.onboarding-page {
    max-height: 600px;
    height: 100%;
}

.left {
    width: 50%;
    height: 100%;
    float: left;
    animation: fadeIn fadeDuration ease-in forwards;
}

.right {
    width: 50%;
    height: 100%;
    padding: 24px;
    position: relative;
    float: left;
    box-sizing: border-box;
    background-color: #fff;
}

.controls {
    width: calc(100% - 32px);
    position: absolute;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.body {
    animation: fadeIn fadeDuration ease-in forwards;
}

.is-fading-out {
    animation: fadeOut fadeDuration ease-out forwards;
}

.links > *:not(:last-child) {
    margin-right: 8px;
}

.links > *:last-child {
    min-width: 75px;
    margin-right: 16px;
}

.links--spaced {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
    width: 100%;
}

.links--spaced > *:first-child {
    padding-left: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
