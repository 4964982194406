.draggable-row {
    display: flex;
    min-height: 32px;
    overflow: hidden;
    width: 100%;
}

.drag-handle {
    width: 21px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding-left: 7px;
    color: var(--grey-lt);
    flex-grow: 0;
    background-color: var(--off-white-dk);
}

.drag-handle > :first-child {
    height: 14px;
}

.drag-handle:hover,
.drag-handle:focus {
    color: var(--grey-dk);
}

.content {
    width: calc(100% - 28px); /* This is full width minus the drag handle and an 8px margin */
}

.show-overflow {
    overflow: visible;
}
