.popmini,
#popmini {
	position: absolute;
	top: 0;
	left: 0;
	min-width: 260px;
	max-width: 380px;
	z-index: 10001;
	display: none;
	background-color: rgba(64, 61, 56, 0.85);
	border-radius: 4px;
	color: white;

	.popmini-content {
		padding: 15px;
		display: flex;
		flex-direction: column;
		.message {
			font-weight: 500;
			overflow-wrap: break-word;
		}
		.buttons {
			line-height: 16px;
			white-space: nowrap;
			display: block;

			// overriding .fieldblock styling for buttons
			position: relative;
			width: inherit;
			top: inherit;
			padding-right: 0;
		}
		.button-interaction {
			min-width: 45px;
			line-height: 24px;
			text-align: center;
			border-radius: 2px;
			text-shadow: none;
			text-decoration: none;
		}
		.action-yes {
			margin-right: 10px;
			background-color: @green-lt;
			color: @green !important;
			&:hover {
				background-color: white;
				color: @green-dk !important;
			}
		}
		.action-no {
			background-color: @off-white-md;
			color: @grey !important;
			&:hover {
				background-color: white;
				color: @grey-dk !important;
			}
		}
	}
	.popmini-content > *:not(:first-child) {
		margin-top: 8px;
	}
	// nub
	&:before {
		top: 100%;
		left: 50%;
		border: solid transparent;
		border-top-color: rgba(64, 61, 56, 0.85);
		border-width: 9px;
		margin-left: -9px;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
	}
	// nub positioned left
	&.orientation-north-east,
	&.orientation-south-east {
		&:before {
			left: 10%;
		}
	}
	// nub positioned right
	&.orientation-north-west,
	&.orientation-south-west {
		&:before {
			left: 90%;
		}
	}
	// nub positioned on the side
	&.orientation-east,
	&.orientation-west {
		&:before {
			border-top-color: transparent;
			top: 50%;
			left: auto;
			right: 100%;
			margin-top: -9px;
			border-right-color: rgba(64, 61, 56, 0.85);
		}
	}
	&.orientation-west {
		&:before {
			left: 100%;
			right: auto;
			margin-left: 0;
			border-right-color: transparent;
			border-left-color: rgba(64, 61, 56, 0.85);
		}
	}
	// nub positioned top
	&.orientation-south,
	&.orientation-south-east,
	&.orientation-south-west {
		&:before {
			top: auto;
			bottom: 100%;
			border-top-color: transparent;
			border-bottom-color: rgba(64, 61, 56, 0.85);
		}
	}

	&.type-message {
		.button-interaction {
			display: none;
		}
		.action-close {
			display: none;
		}
	}

	&.type-question {
		.button-interaction {
			color: black;
		}
		.action-yes {
			margin-right: 10px;
		}
		.action-close {
			display: none;
		}
	}
	&.action-destructive {
		.action-yes {
			background-color: @rose;
			color: white !important;
			&:hover {
				background-color: @rose-dk;
			}
		}
	}

	&.type-popover {
		min-width: 150px;
		.button-interaction {
			display: block;
			margin: 5px 0;
		}
		.popmini-content {
			.buttons {
				margin-top: 0;
			}
			.action-yes {
				margin-right: 0;
			}
		}
	}

	&.type-error {
		background-color: rgba(250, 74, 98, 0.85);
		// nub error style
		&:before {
			border-top-color: rgba(250, 74, 98, 0.85);
		}
		// nub error positioned top
		&.orientation-south,
		&.orientation-south-east,
		&.orientation-south-west {
			&:before {
				border-top-color: transparent;
				border-bottom-color: rgba(250, 74, 98, 0.85);
			}
		}
		a {
			text-decoration: underline;
		}
		.buttons {
			display: none;
		}
	}
}
