.container {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    background-color: var(--blue);
    height: 30px; /* The same height as a standard button */
    width: 30px;
}

.container-open {
    composes: container;
    background-color: var(--blue-dk);
}

.filter-icon {
    fill: var(--off-white);
    margin: 6px auto 0;
}

.container:hover {
    background-color: var(--blue-dk);
}

.container:hover .filter-icon,
.container-open .filter-icon {
    fill: white;
}

.container:focus {
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}
