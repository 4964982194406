.chevronBorder(@bgColor: white, @borderColor: @off-white-md) {
	pointer-events: none;

	&:before,
	&:after {
		pointer-events: none;
		content: ' ';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		height: 50%;
		width: 100%;
		background-color: @bgColor;
		border-right: 2px solid @borderColor;
		opacity: 1;
		transform: skew(20deg, 0deg);
	}
	&:after {
		top: 50%;
		transform: skew(-20deg, 0deg);
	}
}

.stages-carousel-wrapper {
	width: 100%;
	margin: 12px 0 0 0;
	min-height: 96px;

	&.state-empty-milestones {
		display: none;
	}

	.ui-section-label {
		margin: 0;
		padding: 0 16px;
		// grep bait: this is just like a h2.section-label
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		line-height: 18px;
		color: @grey;

		.icon-help {
			float: right;
		}
	}

	.state-empty {
		padding: 0 16px;

		.ui-section-label {
			transform: translateX(0px);
			opacity: 1;
			animation: none;
			border-bottom: 2px solid @off-white-md;
			padding: 0 0 6px 0;
		}

		.ui-create-new--stageset {
			display: block;
			#util > .clearfix();
			margin-top: 10px;
			padding: 3px 10px;
			line-height: 25px;
			background-color: @off-white-md;
			border-radius: 3px;

			.ui-create-new__text {
				font-weight: 500;
				font-size: 13px;
				color: @grey;

				.plus {
					margin: 0 5px;
				}
			}

			&:hover {
				background-color: darken(@off-white-md, 5%);
			}
		}
	}
}

.ui-stages-carousel {
	#util > .no-select();
	margin: 0 -16px 0 -16px;
	padding-bottom: 5px; // Fix broken arrow buttons being chopped at bottom.
	position: relative;
	// hide horizontal scrollbars for scroll-container below
	overflow: hidden;

	// slightly animate left/right on .ui-stage-scroll-button:hover to indicate
	// what will happen when the scroll buttons are clicked
	&.state-hover-scroll-left .ui-lead-stage {
		transform: translateX(40px);
		transition: transform 400ms @customEasing;
	}
	&.state-hover-scroll-right .ui-lead-stage {
		transform: translateX(-40px);
		transition: transform 400ms @customEasing;
	}

	.inner-wrapper {
		position: relative;
	}

	.ui-stages-carousel__scroll-container {
		white-space: nowrap;
		overflow-x: scroll;

		// hide horizontal scrollbars, offset btm of this container by 16px
		margin-bottom: -16px;
		padding: 8px 0 36px 0;

		// force hide horizontal scrollbars for chrome's latest udpate
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.ui-lead-stage {
		transform-origin: center center;
		transition: transform 800ms @customEasing;
	}
}
// Overriding sales process styles
// TODO: clean this up so that we dont have to override anything and move it out
// of this file
.processes.is-stepless-stages {
	position: relative;

	&.state-onload {
		overflow: hidden;
	}

	.process-milestone-container {
		border: 0;
		position: absolute;
		top: 20px;
		left: 0;
		z-index: 1;
		width: 100%;
		padding-bottom: 20px;
		visibility: hidden;
		pointer-events: none;
		opacity: 0;

		&.state-active {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			z-index: 2;
		}
	}

	.process .step {
		border-top: 1px solid @off-white-md;
		border-bottom: none;
		padding: 6px 8px 6px 40px;
	}

	.process-separator {
		color: @grey-dk;
		background-color: @off-white-md;
		padding: 3px 8px 2px 8px;
	}

	.empty-divider {
		display: block;
		min-height: 2px;
	}

	.action-editprocesses {
		position: absolute;
		top: 0;
		right: 0;
		color: @blue;
		font-size: @small;
		&:hover {
			text-decoration: underline;
			color: @blue-dk;
		}
	}
}

.goals-guidance.state-empty {
	padding-left: 0;
}
