.content-container {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    height: 62px;
    box-sizing: border-box;
}

.link-container {
    /* 40px offset for Edit button */
    width: calc(100% - 40px);
}

.link a {
    color: var(--grey-dk);
}

.label {
    font-size: var(--font-tiny);
    color: var(--grey);
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 2px;
}

.label a {
    font-size: var(--font-tiny);
}
