.email-list-container > div {
    margin-bottom: 12px;
    line-height: 1.5;
}

.email-list-container ul {
    margin-bottom: 12px;
}

.adder {
    margin-left: 18px;
}
