.cta-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    opacity: 1;
    transition: 300ms opacity ease;
}

.closing {
    composes: cta-banner;
    opacity: 0;
}

.hover-trap {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    width: 100%;
    cursor: pointer;
}

.hover-trap:hover .primary-action,
.hover-trap:focus .primary-action,
.hover-trap:focus .primary-action button,
.hover-trap:hover .primary-action button {
    text-decoration: underline;
    color: var(--blue-dk);
}

.primary-action {
    color: var(--blue);
}

.primary-action button {
    padding: 0;
}

.remove-button {
    padding-right: 24px;
}
