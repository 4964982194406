.timeline-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* Reduce padding of close button */
.timeline-modal > :first-child {
    padding-right: 0;
}

.modal-content-container {
    width: 660px;
    background-color: var(--white);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    flex-direction: column;
}

.loading-container {
    min-height: 150px;
    padding: 64px;
}
