.container {
    position: relative;
}

.header {
    display: flex;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    border-bottom: 1px solid var(--form-border);
    margin-bottom: 8px;
}

.title {
    cursor: pointer;
    font-weight: 500;
}

.header > *:last-child {
    color: var(--form-border);
}

.content {
    display: none;
}

.content--expanded {
    display: block;
}
