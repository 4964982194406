@value overlay-z-index: 10001;

:root {
    --popover-nub-size: 7px;

    --popover-nub-border-color-light: var(--off-white-dk);
    --popover-nub-fill-color-light: #fff;

    --popover-nub-border-color-dark: var(--grey-dk);
    --popover-nub-fill-color-dark: var(--grey-dk);

    --color-err: rgba(250, 74, 98, 0.95);
}

.container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: overlay-z-index;
    pointer-events: none;
}

.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 0; /* Below popover body. */
    pointer-events: all;
}

.anchor-mask-overlay {
    position: fixed;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    pointer-events: none;
    opacity: 0;
    transform-origin: center center;
    transform: scale(1.1);
    animation: fade-in 400ms var(--custom-easing) 0s forwards;
}

.body {
    position: fixed;
    z-index: 1; /* Above popover overlay. */
    background-color: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    pointer-events: all;
}

.body--dark {
    composes: body;
    background-color: var(--grey-dk);
    color: #fff;
}

.body--error {
    composes: body;
    background-color: var(--color-err);
}

.body--dialog {
    box-sizing: border-box;
    min-width: 160px;
    max-width: 300px;
    padding: 15px;
    text-align: center;
}

.body--dark.body--dialog,
.body--error.body--dialog {
    color: white;
}

.body.no-default-styling {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
}

.body.no-default-styling:before,
.body.no-default-styling:after {
    display: none;
}

.body:before,
.body:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
}

.body:before {
    border-width: var(--popover-nub-size);
}
.body:after {
    border-width: calc(var(--popover-nub-size) - 1px);
}

/* LOCATION-LEFT */
.body.location-left:before,
.body.location-left:after {
    border-right: 0;
    top: 50%;
}

/* light styles location-left */
.body.location-left:before {
    right: calc(var(--popover-nub-size) * -1);
    margin-top: calc(var(--popover-nub-size) * -1);
    border-left-color: var(--popover-nub-border-color-light);
}
.body.location-left:after {
    right: calc((var(--popover-nub-size) * -1) + 1px);
    margin-top: calc((var(--popover-nub-size) * -1) + 1px);
    border-left-color: var(--popover-nub-fill-color-light);
}

/* dark styles location-left */
.body--dark.location-left:before,
.body--nub-dark.location-left:before {
    border-left-color: var(--popover-nub-border-color-dark);
}
.body--dark.location-left:after,
.body--nub-dark.location-left:after {
    border-left-color: var(--popover-nub-border-color-dark);
}

/* error styles location-left */
.body--error.location-left:before {
    border-left-color: var(--color-err);
}
.body--error.location-left:after {
    border-left-color: var(--color-err);
}

/* LOCATION-RIGHT */
.body.location-right:before,
.body.location-right:after {
    border-left: 0;
    top: 50%;
}

/* light styles location-right */
.body.location-right:before {
    left: calc(var(--popover-nub-size) * -1);
    margin-top: calc(var(--popover-nub-size) * -1);
    border-right-color: var(--popover-nub-border-color-light);
}
.body.location-right:after {
    left: calc((var(--popover-nub-size) * -1) + 1px);
    margin-top: calc((var(--popover-nub-size) * -1) + 1px);
    border-right-color: var(--popover-nub-fill-color-light);
}

/* dark styles location-left */
.body--dark.location-right:before,
.body--nub-dark.location-right:before {
    border-right-color: var(--popover-nub-border-color-dark);
}
.body--dark.location-right:after,
.body--nub-dark.location-right:after {
    border-right-color: var(--popover-nub-fill-color-dark);
}

/* error styles location-right */
.body--error.location-right:before {
    border-right-color: var(--color-err);
}
.body--error.location-right:after {
    border-right-color: var(--color-err);
}

/* LOCATION-TOP */
.body.location-top:before,
.body.location-top:after {
    border-bottom: 0;
    left: 50%;
}

/* light styles location-top */
.body.location-top:before {
    border-top-color: var(--popover-nub-border-color-light);
    bottom: calc(var(--popover-nub-size) * -1);
    margin-left: calc(var(--popover-nub-size) * -1);
}
.body.location-top:after {
    border-top-color: var(--popover-nub-fill-color-light);
    bottom: calc((var(--popover-nub-size) * -1) + 1px);
    margin-left: calc((var(--popover-nub-size) * -1) + 1px);
}

/* dark styles location-top */
.body--dark.location-top:before,
.body--nub-dark.location-top:before {
    border-top-color: var(--popover-nub-border-color-dark);
}
.body--dark.location-top:after,
.body--nub-dark.location-top:after {
    border-top-color: var(--popover-nub-fill-color-dark);
}

/* error styles location-top */
.body--error.location-top:before {
    border-top-color: var(--color-err);
}
.body--error.location-top:after {
    border-top-color: var(--color-err);
}

/* LOCATION-BOTTOM */
.body.location-bottom:before,
.body.location-bottom:after {
    border-top: 0;
    left: 50%;
}

/* light styles location-bottom */
.body.location-bottom:before {
    border-bottom-color: var(--popover-nub-border-color-light);
    top: calc(var(--popover-nub-size) * -1);
    margin-left: calc(var(--popover-nub-size) * -1);
}
.body.location-bottom:after {
    border-bottom-color: var(--popover-nub-fill-color-light);
    top: calc((var(--popover-nub-size) * -1) + 1px);
    margin-left: calc((var(--popover-nub-size) * -1) + 1px);
}

/* dark styles location-bottom */
.body--dark.location-bottom:before,
.body--nub-dark.location-bottom:before {
    border-bottom-color: var(--popover-nub-border-color-dark);
}
.body--dark.location-bottom:after,
.body--nub-dark.location-bottom:after {
    border-bottom-color: var(--popover-nub-fill-color-dark);
}

/* error styles location-bottom */
.body--error.location-bottom:before {
    border-bottom-color: var(--color-err);
}
.body--error.location-bottom:after {
    border-bottom-color: var(--color-err);
}

/* LOCATION-TOP-LEFT */
.body.location-top-left:before,
.body.location-top-left:after {
    border-bottom: 0;
    left: auto;
    right: calc(var(--popover-nub-size) * 2);
}

/* light styles location-top-left */
.body.location-top-left:before {
    border-top-color: var(--popover-nub-border-color-light);
    bottom: calc(var(--popover-nub-size) * -1);
}
.body.location-top-left:after {
    border-top-color: var(--popover-nub-fill-color-light);
    bottom: calc((var(--popover-nub-size) * -1) + 1px);
}

/* dark styles location-top-left */
.body--dark.location-top-left:before,
.body--nub-dark.location-top-left:before {
    border-top-color: var(--popover-nub-border-color-dark);
}
.body--dark.location-top-left:after,
.body--nub-dark.location-top-left:after {
    border-top-color: var(--popover-nub-fill-color-dark);
}

/* error styles location-top-left */
.body--error.location-top-left:before {
    border-top-color: var(--color-err);
}
.body--error.location-top-left:after {
    border-top-color: var(--color-err);
}

/* LOCATION-TOP-RIGHT */
.body.location-top-right:before,
.body.location-top-right:after {
    border-bottom: 0;
    left: calc(var(--popover-nub-size) * 2);
}

/* light styles location-top-right */
.body.location-top-right:before {
    border-top-color: var(--popover-nub-border-color-light);
    bottom: calc(var(--popover-nub-size) * -1);
}
.body.location-top-right:after {
    border-top-color: var(--popover-nub-fill-color-light);
    bottom: calc((var(--popover-nub-size) * -1) + 1px);
}

/* dark styles location-top-right */
.body--dark.location-top-right:before,
.body--nub-dark.location-top-right:before {
    border-top-color: var(--popover-nub-border-color-dark);
}
.body--dark.location-top-right:after,
.body--nub-dark.location-top-right:after {
    border-top-color: var(--popover-nub-fill-color-dark);
}

/* error styles location-top-right */
.body--error.location-top-right:before {
    border-top-color: var(--color-err);
}
.body--error.location-top-right:after {
    border-top-color: var(--color-err);
}

/* LOCATION-BOTTOM-LEFT */
.body.location-bottom-left:before,
.body.location-bottom-left:after {
    border-top: 0;
    left: auto;
    right: calc(var(--popover-nub-size) * 2);
}

/* light styles location-bottom-left */
.body.location-bottom-left:before {
    border-bottom-color: var(--popover-nub-border-color-light);
    top: calc(var(--popover-nub-size) * -1);
}
.body.location-bottom-left:after {
    border-bottom-color: var(--popover-nub-fill-color-light);
    top: calc((var(--popover-nub-size) * -1) + 1px);
    border-width: var(--popover-nub-size);
}

/* dark styles location-bottom-left */
.body--dark.location-bottom-left:before,
.body--nub-dark.location-bottom-left:before {
    border-bottom-color: var(--popover-nub-border-color-dark);
}
.body--dark.location-bottom-left:after,
.body--nub-dark.location-bottom-left:after {
    border-bottom-color: var(--popover-nub-fill-color-dark);
}

/* error styles location-bottom-left */
.body--error.location-bottom-left:before {
    border-bottom-color: var(--color-err);
}
.body--error.location-bottom-left:after {
    border-bottom-color: var(--color-err);
}

/* LOCATION-BOTTOM-RIGHT */
.body.location-bottom-right:before,
.body.location-bottom-right:after {
    border-top: 0;
    left: calc(var(--popover-nub-size) * 2);
}

/* light styles location-bottom-right */
.body.location-bottom-right:before {
    border-bottom-color: var(--popover-nub-border-color-light);
    top: calc(var(--popover-nub-size) * -1);
}
.body.location-bottom-right:after {
    border-bottom-color: var(--popover-nub-fill-color-light);
    top: calc((var(--popover-nub-size) * -1) + 1px);
    border-width: var(--popover-nub-size);
}

/* dark styles location-bottom-right */
.body--dark.location-bottom-right:before,
.body--nub-dark.location-bottom-right:before {
    border-bottom-color: var(--popover-nub-border-color-dark);
}
.body--dark.location-bottom-right:after,
.body--nub-dark.location-bottom-right:after {
    border-bottom-color: var(--popover-nub-fill-color-dark);
}

/* error styles location-bottom-right */
.body--error.location-bottom-right:before {
    border-bottom-color: var(--color-err);
}
.body--error.location-bottom-right:after {
    border-bottom-color: var(--color-err);
}

/* An empty style to cover all of the location types and prevent CSSM errors. */
.body.location-custom {
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.body--hide-nub:before,
.body--hide-nub:after {
    display: none;
}
