.status-badge {
    border-radius: 4px;
    overflow: hidden;
    padding: 2px 8px;
    flex-shrink: 0;
    text-align: center;
    user-select: none;
    font-size: var(--font-normal);
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
}

.status-badge--interactive:focus,
.status-badge--interactive:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.status-badge > *:not(:last-child) {
    margin-right: 4px;
}

.status-badge--small {
    font-size: var(--font-small);
}

.status-badge--success {
    composes: status-badge;
    background-color: var(--success-bg);
    color: var(--success);
}

.status-badge--info {
    composes: status-badge;
    background-color: var(--info-bg);
    color: var(--info);
}

.status-badge--warning {
    composes: status-badge;
    background-color: var(--warning-bg);
    color: var(--warning);
}

.status-badge--alert {
    composes: status-badge;
    background-color: var(--alert-bg);
    color: var(--alert);
}

.status-badge--alert-text {
    composes: status-badge;
    background-color: transparent;
    color: var(--alert);
    padding: 0;
}

.status-badge--hidden {
    composes: status-badge;
    background-color: var(--off-white-dk);
    color: var(--grey-lt);
}

.status-badge--info-navy {
    composes: status-badge;
    background-color: #3d405c1a;
    color: var(--report-navy);
}

.status-badge--info-purple {
    composes: status-badge;
    background-color: #f7f7fc;
    color: var(--plum-dk);
}

.status-badge--info-grey {
    composes: status-badge;
    background-color: #f2f0f0;
    color: var(--grey);
}
