.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 258px;
    line-height: 21px;
    box-sizing: border-box;
    user-select: none;
}

.container p {
    font-size: var(--font-big);
    font-weight: 500;
    color: var(--grey-dk);
    margin: 4px 0;
}

.container span {
    font-size: var(--font-normal);
}
