.container {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 16px;
    background-color: var(--off-white);
}

.left-controls > * {
    margin-right: 16px;
}

.left-controls {
    display: flex;
    justify-content: flex-start;
}

/* This places the Remove filters button 8px from the facet picker */
.facet-picker-container {
    margin-right: 0;
}

.remove-button-container {
    align-self: center;
}

/* Hide the Remove filters button on smaller screens to preserve spacing */
.remove-button-container__hidden {
    display: none;
}

/* One-off select styling! Eek! */
.left-controls select {
    height: 30px !important;
    padding: 6px 30px 5px 8px !important;
}

.left-controls :global(.fa-chevron-down) {
    top: 10px !important;
}
/* End one-off select styling */
