.table-row {
    background-color: #fff;
    align-items: stretch;
}

.bordered {
    border-bottom: var(--border-hairline);
}

.hoverable:hover,
.hoverable:focus,
.selected {
    background-color: var(
        --color-list-hovered
    ) !important; /* ensures we have a hover state for striped table rows */
    cursor: pointer;
}

.hoverable:focus,
.hoverable:active {
    outline: 2px solid var(--focus-blue);
    outline-offset: -2px;
}

.extend-to-fit-placeholder-cell {
    width: 100%;
    border-right: var(--border-hairline);
}

.action-button-cell {
    composes: action-button-cell from '../styles.css';
}

.expand-cell {
    composes: expand-cell from '../styles.css';
}

.expanded-content {
    padding: 0 8px 8px 8px;
}

.expanded-content--with-borders {
    composes: expanded-content;
    border-bottom: var(--border-hairline);
    border-right: var(--border-hairline);
    border-left: var(--border-hairline);
}
