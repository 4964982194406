.add-trigger-container {
    width: 100%;
    height: 61px; /* matches height of goal card */
}

.uneditable-text {
    align-self: center;
    font-size: normal;
    margin: 0;
}

.empty-start-trigger {
    padding: 12px 16px;
}
