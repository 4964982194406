.content {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    height: 100%;
}

.main-content {
    flex: 1;
}

.content > *:last-child {
    width: 200px;
    height: fit-content;
}
