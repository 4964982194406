.container {
    composes: timeline-container from '../timeline.css';
}

.title {
    font-size: 19px;
    font-weight: 500;
    line-height: 24px;
    color: var(--grey-dk);
}

.entity {
    margin-left: 3px;
}
