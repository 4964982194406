.container {
    display: flex;
    flex-direction: column;
    font-size: var(--font-small, 13px);
}

.container--hoverable {
    background-color: var(--off-white);
}

.container--hoverable:hover {
    background-color: #fff;
}

.header {
    padding: 12px 12px 0 12px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
}

.header-border-container {
    composes: header-container;
    border-bottom: 1px solid var(--off-white-md);
}

.avatar {
    flex-shrink: 0;
    margin-right: 8px;
}

.header-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 8px;
    height: 36px;
    overflow: hidden;
}

.top-row {
    font-weight: 500;
    display: flex;
    color: var(--grey-dk);
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Don't want special "visited" styling in Google inbox extension */
.top-row:visited {
    color: inherit;
}

.container--linkable .top-row:hover span {
    text-decoration: underline;
}

.top-row > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 190px;
    font-size: var(--font-normal, 15px);
}

.bottom-row {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}

.spacer {
    color: var(--off-white-dk);
    margin: 0 5px;
    font-size: 9px;
}

.assignee {
    color: var(--grey);
    overflow: hidden;
    text-overflow: ellipsis;
}

.body {
    padding: 8px 12px;
    display: flex;
}

.related-entity-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.related-entity {
    height: 21px;
    display: flex;
    align-items: center;
}

.related-entity > i {
    color: var(--grey-lt);
}

.related-person,
.related-person:visited,
.related-person:hover {
    color: var(--navy-dk);
}

.container--linkable .related-person {
    cursor: pointer;
}

.container--linkable .related-person:hover {
    text-decoration: underline;
}

.related-company,
.related-company:visited,
.related-company:hover {
    color: var(--plum-dk);
}

.container--linkable .related-company {
    cursor: pointer;
}

.container--linkable .related-company:hover {
    text-decoration: underline;
}

.next-activity-start-time {
    font-size: var(--font-tiny, 12px);

    /* Maintain position, but increase size of tooltip hover target */
    padding: 4px 8px 4px 0;
    margin-top: -4px;
    margin-right: -8px;
    height: 17px;
}

/* sucky styles to make the anchor tag take up the entire action menu div */
.link {
    width: 100%;
    margin: -11.5px -16px;
    padding: 11.5px 16px;
}
