.participant {
    display: flex;
}

.with-space {
    margin-right: 4px;
}

.integration-widget-container {
    min-width: 550px;
    max-width: 50%;
}

@media (max-width: 850px) {
    .integration-widget-container {
        min-width: 100%;
        max-width: 100%;
    }
}
