.container {
    min-height: 42px;
    display: flex;
    margin-bottom: 8px;
    margin: 8px;
    align-items: center;
    justify-content: space-between;
}

.container > *:not(:last-child) {
    margin-right: 16px;
}

.left-container {
    display: flex;
    align-items: center;
}

.left-container > *:not(:last-child) {
    margin-right: 16px;
}

.card-container:not(:last-child) {
    margin-bottom: 8px;
}

/* Add a space before timestamps */
.container :global(.timestamp)::before {
    content: '\a0';
}

.title {
    font-weight: 500;
}

.green-description {
    color: var(--color-success);
}

.error-icon {
    color: var(--color-error);
    margin-right: 4px;
}

.warn-icon {
    color: var(--yellow);
    margin-right: 4px;
}

.buttons {
    flex-shrink: 0;
}

.buttons > *:not(:last-child) {
    margin-right: 6px; /* added to 10px from button, gives 16px */
}
