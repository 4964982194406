.editor-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.bee-plugin-wrapper iframe {
    min-width: 0 !important;
}

.bee-plugin-wrapper {
    display: flex;
    height: 100%;
}
