.container {
    display: flex;
    align-items: flex-start;
    position: relative;
    font-size: var(--font-normal);
    line-height: 19px;
}

.strikethrough {
    text-decoration: line-through;
}

.bold {
    font-weight: 500;
}

.decimal {
    font-size: 8px;
    line-height: 16px;
}

.light {
    color: var(--grey-lt);
}

.medium {
    color: var(--grey);
}

.dark {
    color: var(--grey-dk);
}

.green {
    color: var(--success);
}

.marketing {
    font-family: doyle, 'IBM Plex Sans';
}

.small {
    font-size: var(--font-small);
    line-height: 17px;
}

.small .decimal {
    font-size: 6px;
    line-height: 14px;
}

.large {
    font-size: var(--font-big);
    line-height: 22px;
}

.large .decimal {
    font-size: 9px;
    line-height: 18px;
}

.extra-large {
    font-size: 27px;
    line-height: 31px;
}

.extra-large .decimal {
    font-size: 13px;
    line-height: 24px;
}

.extra-large.marketing .decimal {
    line-height: 24px;
}

.flex-end {
    align-self: flex-end;
}
