.timeline-container { margin: 15px 0 3px 0; }
.timeline-list {
	overflow: hidden; // address overflowing on peep activities list
	.action-more {
		width: 110px;
		margin: 10px auto 20px auto;
		line-height: 24px;
		background-color: @off-white-dk;
		color: @grey-lt;
		border: 1px solid @off-white-dk;
		border-radius: 2px;
		font-style: italic;
		text-align: center;

		.loading {
			display: none;
		}
	}

	&.variant-mini {
		margin-top: 0;
		.action-more { display: none; }
	}

	.state-loading & {
		.action-more {
			.loading { display: block; }
			.more { display: none; }
		}
	}

	// timeline empty state
	.row-empty-placeholder {
		text-align: center;
		margin: 20px;
	}
}

.timeline-past.state-empty:after { display: none; }

.timeline-entry {
	position: relative;
	line-height: 18px;
	text-shadow: 0 1px 1px rgba(255,255,255, 0.5);
	color: @grey;
	padding-bottom: 25px;

	.gutter {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 60px;
		height: 100%;

		.dot {
			margin-top: 4px;
			margin-left: 25px;
			width: 8px;
			height: 8px;
			border-radius: 8px;
			background-color: @off-white-dk;
		}

		.line {
			width: 2px;
			background-color: @off-white-dk;
			position: absolute;
			top: 6px;
			bottom: -4px;
			left: 28px;
		}

		.gutter-content {
			font-size: 10px;
			line-height: 13px;
			position: absolute;
			width: 100%;
			padding-bottom: 4px;
			background-color: @off-white;
		}

		.icon {
			margin-left: 15px;
			height: 26px;
			width: 30px;
			.timeline-sprite();
			background-position: -120px 0;
		}
		.date {
			font-weight: 500;
			letter-spacing: 1px;
			text-align: center;
			text-transform: uppercase;
		}
		.time, .year {
			text-align: center;
			color: @grey-lt;
		}
	}
	&.state-overdue {
		.gutter .date {
			color: @rose;
		}
	}
	.first-line {
		margin-left: 60px;
		height: 18px;
		line-height: 18px;
		position: relative;

		.left-side,
		.right-side {
			position: absolute;
			top: 0;
			#util > .excerpt-crop > .ellipsis;

			.content-mini { display: none; }
		}
		.left-side {
			color: @grey-lt;
			width: 284px;
			padding-right: 6px;
			.actor {
				color: @grey;
			}
		}
		.right-side {
			left: 290px;
			width: 295px;

			&.modal-opener-activity:hover .subject {
				text-decoration: underline;
			}
		}

		.email-error {
			font-size: 11px;
			color: @rose-dk;
		}

		.extras {
			padding-left: 4px;
			font-size: 11px;
			color: @grey-lt;
			a {
				text-decoration: underline;
				&:hover {
					color: @grey;
				}
			}
		}
	}
	.content {
		clear: both;
		margin-top: 8px;
		margin-left: 60px;
		padding: 14px 20px;
		width: 583px - 40;
		border: 1px solid @off-white-dk;
		border-bottom: 1px solid darken(@off-white-dk, 6%);
		border-radius: 2px;
		box-shadow: inset 0 0 2px 2px rgba(255,255,255, 0.25), 0 1px 2px rgba(0,0,0, 0.1);
		position: relative;
		word-wrap: break-word;
		min-height: 18px;
		.text {
			white-space: normal;
		}
		&.modal-opener-activity {

			&:hover {
				opacity: 0.8;
				box-shadow: inset 0 0 2px 2px rgba(255,255,255, 0.25), 0 1px 1px rgba(0,0,0, 0.05);
				p,
				.text {
					color: @grey-dk;
				}
			}
		}

		&:before { // pseudo element for inner white border
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-top: 1px solid rgba(255,255,255,0.5);
			border-bottom: 1px solid rgba(255,255,255,0.5);
			#util > .rounded-corners(2px);
			pointer-events: none; // @TODO fixme for IE
		}

		.nub {
			position: absolute;
			top: -5px;
			left: 20px;
			width: 8px;
			height: 6px;
			.timeline-sprite();
		}
		.ellipsis {
			position: absolute;
			bottom: 10px;
			right: 10px;
			width: 21px;
			height: 9px;
			.timeline-sprite();
			background-position: -250px top;
			opacity: 0.5;
			&:hover {
				opacity: 1;
			}
		}
		// p - empty placeholder text
		p {
			margin: 0 0 8px 0;
		}
		a { text-decoration: underline; }
		span.mention-rendered a,
		span.mention-rendered-self a { text-decoration: none; }
		.no-description-placeholder {
			font-style: italic;
			color: @grey-lt;
			margin: 0; // remove margin as we know they will only be one paragraph
		}
		.note-edit-buttons();
	}
	.date-mini {
		display: none;
	}

	&.type-activity,
	&.type-note {
		.content {
			span.mention-rendered {
				background: @peach;
				border-bottom: 1px solid @orange-lt;
				&:hover { background: none; }
			}
			span.mention-rendered-self {
				background: @blue-bg;
				&:hover { background: none; }
			}
		}
	}

	&.type-activity {
		.content {
			.2x('@{img-root}/timeline2/activity-bg.png', "repeat", 593px, 6px);
			.nub { background-position: -220px 0; }
		}
	}
	&.type-activity-phone {
		.gutter .icon {
			background-position: 0px 0;
		}
	}
	&.type-email {
		.content {
			.2x('@{img-root}/timeline2/email-bg.png', "repeat", 593px, 33px);
			.nub { background-position: -200px 0; }
		}
		.gutter .icon {
			background-position: -60px 0;
		}
		&.state-expanded {
			.content span.excerpt { display: none; }
		}
		&.state-collapsed {
			cursor: pointer;
			.content {

				span.body { display: none; }

				&:hover {
					opacity: 0.8;
					box-shadow: inset 0 0 2px 2px rgba(255,255,255, 0.25), 0 1px 1px rgba(0,0,0, 0.05);
					p,
					.text {
						color: @grey-dk;
					}
				}
			}
		}
	}
	&.type-email-zendesk {
		.gutter .icon {
			background-position: -90px 0;
			margin-left: 14px;
		}
	}
	&.type-note {
		.content {
			.2x('@{img-root}/timeline2/note-bg.jpg', "repeat", 583px, 65px);
			.nub { background-position: -210px 0; }

			textarea {
				#util > .input-rounded;
				outline: 0;
				width: 100%;
				resize: vertical;
			}

			div.mentions {
				margin: 1px;
				padding: 3px;
				outline: 0;
			}
		}
		.gutter .icon {
			background-position: -150px 0;
		}
	}
	&.type-olark {
		.content {
			&:hover {
				opacity: 0.8;
				box-shadow: inset 0 0 2px 2px rgba(255,255,255, 0.25), 0 1px 1px rgba(0,0,0, 0.05);
				.chat-table {
					tr.message-visitor td.column-person,
					tr.message-visitor td.column-message {
						color: @grey-dk;
					}
					tr.message-operator td.column-person,
					tr.message-operator td.column-message {
						color: @plum-dk;
					}
				}
			}
			padding: 15px 20px;
			.2x('@{img-root}/timeline2/olark-bg.png', "repeat", 740px, 12px);
			.nub { background-position: -230px 0; }
		}
		.gutter .icon {
			background-position: -30px 0;
		}
	}
	&.state-collapsed .content .ellipsis {
		display: block;
	}

	.variant-mini & {
		padding: 0;
		line-height: 24px;
		height: 24px;
		overflow: hidden;
		border-top: 1px solid @off-white-dk;

		&:hover {
			cursor: pointer;
			.right-side {
				text-decoration: underline;
			}
		}

		&:first-child {
			border-top: none;
		}

		.line,
		.dot,
		.content,
		span.extras,
		.left-side,
		.date,
		.time,
		.year {
			display: none;
		}
		.gutter {
			width: 30px;
			.icon {
				margin: -4px 0 0 0;
			}
			.gutter-content {
				position: relative;
				top: inherit;
				margin: 0;
				background: transparent;

			}
		}
		.first-line {
			font-size: 11px;
			line-height: 24px;
			margin-left: 30px;
		}
		.right-side {
			left: 0;
			width: 210px;

			.content-mini { display: inline; }
		}
		.date-mini {
			display: block;
			position: absolute;
			top: 0px;
			right: 4px;
			font-size: 11px;
			color: @grey-lt;
		}
	}
}
