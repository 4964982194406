.container {
    padding: 8px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: var(--off-white);
}

.container--static {
    composes: container;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    margin-bottom: 6px;
}
