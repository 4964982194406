// shared elements
.times-remove {
	position: absolute;
	right: 6px;
	top: 6px;
	width: 14px;
	height: 14px;
	text-shadow: none;
	line-height: 12px;
	text-align: center;
	font-size: 19px;
	font-weight: 500;
	color: @grey-lt;
	background: none;
	&:hover {
		color: @rose;
		background: none;
	}
	span.confirm {
		display: none;
	}
}

.other-contacts {
	width: 460px;
}

.contact-card-container .avatar,
.account-card-container .avatar {
	width: 54px;
	height: 54px;
	background: url('@{img-root}/icons/card-sprite.png?v=1') no-repeat;
	background-position: 0 50%;
	box-shadow: none;
	&:before {
		// Hide standard _avatar.less white border
		display: none;
	}
	img {
		width: 50px;
		height: 50px;
		border-radius: 0;
	}
}

.contact-card-container .avatar {
	background-position: -46px 50%;
}

@media (-webkit-min-device-pixel-ratio: 1.5),
	(min--moz-device-pixel-ratio: 1.5),
	(-o-min-device-pixel-ratio: 3/2),
	(min-resolution: 1.5dppx) {
	.contact-card-container .avatar,
	.account-card-container .avatar {
		background-image: url('@{img-root}/icons/card-sprite_2x.png?v=1');
		background-size: 100px 48px;
	}
}

.tile-container {
	z-index: 2;
	position: relative;

	.second-line .description,
	.third-line .description {
		max-width: 500px;
		font-style: italic;
		color: @grey;
		#util > .excerpt-crop > .ellipsis;
		vertical-align: top;
		padding-right: 8px;
	}
	&.tile-empty {
		z-index: 10;
		.tile-account,
		.tile-contact {
			cursor: default;
		}
	}

	.job-title {
		display: inline-block;
	}
	&.tile-filled .job-title {
		cursor: text;
		padding: 0 2px;
		font-style: italic;
		&:hover {
			.empty,
			.value {
				background: @yellow-lt;
			}
		}
		.eip-input {
			background: @yellow-lt;
			width: 210px;
			padding: 2px !important; // Needed to override dumb .minimal class
		}
		.eip-buttons {
			line-height: 15px;
			display: inline-block;
			position: relative;
			top: -1px;
			a {
				font-size: 11px;
				font-style: normal;
				margin: 0 2px 0 0;
				padding: 0 5px;
			}
		}
	}

	&.tile-starrable {
		margin-left: 24px;
		position: relative;

		.star-container {
			position: absolute;
			left: -24px;
			width: 24px;
			height: 100%;
			display: flex;
			align-items: center;

			.action-star-contact {
				position: relative;
				height: 24px;
				width: 24px;
				font-size: 17px;
				color: @off-white-dk;

				.fa-star {
					color: @yellow;
				}

				.primary-tooltip {
					color: #fff;
					background-color: #222;
					pointer-events: none;
					display: block;
					position: absolute;
					color: white;
					padding: 4px 8px;
					bottom: 28px;
					z-index: 10001;
					white-space: nowrap;
					font-size: 13px;
					line-height: 17px;
					opacity: 0;
					border-radius: 2px;
					font-family: 'IBM Plex Sans';
					left: -16px;
					margin-bottom: 10px;
					transition: opacity 200ms ease-out;

					// Tooltip styles
					&::before,
					&::after {
						content: '';
						width: 0;
						height: 0;
						position: absolute;
					}

					&::before {
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						bottom: -8px;
						left: 24px;
						margin-left: -10px;
					}

					&::after {
						border-top-color: #222;
						border-top-style: solid;
						border-top-width: 6px;
						border-left: 8px solid transparent;
						border-right: 8px solid transparent;
						bottom: -6px;
						left: 24px;
						margin-left: -8px;
					}
				}

				&.state-active {
					color: @yellow;

					i::before {
						content: '\f005';
						opacity: 0.6;
					}

					.primary-tooltip {
						opacity: 0.9;
					}
				}
			}
		}
	}

	.control.behavior-dupecheck {
		display: none;
		position: absolute;
		top: -8px;
		z-index: 3;
		right: 15px;
		color: white;
		font-size: 11px;
		line-height: 12px;
		margin-right: 9px;
		text-decoration: none;
		background-color: @rose;
		border-radius: 8px;
		cursor: pointer;

		span {
			display: block;
			padding: 2px 6px;
		}

		&:hover {
			background-color: @rose-dk;
		}
	}

	&.state-deleted {
		opacity: 0.8;
	}
}

.load-more-pager-container {
	display: block;
	height: 48px;
	margin-bottom: 12px;
	text-align: center;
	&.state-default {
		a[data-load-action='prev'] {
			pointer-events: none;
			opacity: 0.5;
		}
		a[data-load-action='next'] {
			pointer-events: all;
			opacity: 1;
		}
	}
	&.state-has-prev a[data-load-action='prev'] {
		pointer-events: all;
		opacity: 1;
	}
	&.state-has-next a[data-load-action='next'] {
		pointer-events: all;
		opacity: 1;
	}
	&.state-loading a[data-load-action],
	&.state-show-all a[data-load-action] {
		pointer-events: none;
		opacity: 0.5;
	}

	a {
		&[data-load-action='all'] {
			margin: 0 5px;
			span {
				padding: 3px 9px;
			}
		}
		&[data-load-action='prev'],
		&[data-load-action='next'] {
			pointer-events: none;
			opacity: 0.5;
		}
		&.state-loading {
			.load-more-msg {
				display: none;
			}
			.state-loading-msg {
				display: block;
			}
		}
		span {
			min-width: 73px;
			padding: 3px 0;
			display: block;
			&.state-loading-msg {
				display: none;
				font-style: italic;
			}
		}
	}
}

#people-iq-account-card-react-mount {
	position: relative;
	bottom: 10px;
	left: 68px;
	z-index: 1000; // Show on top of the card container
}

.account-card-container {
	margin-bottom: 12px;
	background: @plum-bg;
	border-radius: 3px 3px 4px 4px;
	&:hover {
		background: darken(@plum-bg, 5%);
	}
	.tile-account {
		position: relative;
	}
	.tile-link {
		display: block;
	}
	.tile-account-top {
		cursor: pointer;
		min-height: 54px;
		.icon-account {
			position: absolute;
			width: 50px;
			height: 50px;
			overflow: hidden;
			top: 2px;
			left: 2px;
			.avatar {
				width: 50px;
				height: 50px;
			}
		}
		.first-line,
		.second-line {
			margin-left: 62px !important; // Allow for account icon
		}
		.first-line {
			line-height: 20px;
			padding-top: 5px;
		}
		.account-name {
			font-size: @normal;
			font-weight: 500;
			color: @grey-dk;
			display: inline-block;
			span {
				display: block;
			}
		}

		.second-line {
			font-size: @small;
			color: @grey;

			.primary-phone {
				float: right;
				padding-right: 6px;
				.divide {
					padding: 0 3px;
				}
			}

			.location {
				#util > .excerpt-crop > .ellipsis;
				float: right;
				padding-right: 6px; // a lil nudge to make it look nicer against the X control
				text-align: right;
			}
		}
	}

	.contact-enclosure {
		background: white;
		border: 2px solid darken(@plum-bg, 3%);
		border-top: none;
		border-radius: 0 0 4px 4px;
		padding: 10px;
		.contact-card-container {
			margin-bottom: 0;
			&.tile-filled {
				margin-bottom: 12px;
			}
			.job-title {
				cursor: pointer;
				&:hover {
					background: none;
				}
			}
		}
	}

	// styles that only apply to form tiles
	&.tile-empty {
		cursor: default;
		&:hover {
			background: darken(@plum-bg, 3%);
		} // Removes :hover effect
		.tile-account {
			z-index: 2;
			position: relative;
		}
		.first-line,
		.second-line,
		.third-line {
			margin: 0 0 2px 0;
		}
		.first-line {
			padding-top: 6px;
			line-height: 15px;
			.account-name {
				width: 65%;
				span {
					font-size: @small;
				}
				&.no-job-field {
					width: 92%;
				}
			}
			.job-title {
				padding: 0;
				width: 30%;
			}
		}
		.second-line {
			.description {
				font-style: normal;
				display: inline-block;
				width: 65%;
				padding-right: 0;
			}
			.location {
				display: inline-block;
				float: none;
				text-align: left;
				width: 30%;
			}
		}
	}
	&.tile-internal-contact-card-is-editing {
		z-index: 999;
	}
	&.state-deleted .account-name span {
		text-decoration: line-through;
		text-shadow: none;
	}
	&.state-duplicate {
		.tile-account-top .control.behavior-dupecheck {
			display: block;
		}
	}
}

// contact
.contact-card-container {
	margin-bottom: 12px;
	background: @navy-bg;
	border-radius: 3px 3px 4px 4px;
	&:hover {
		background: darken(@navy-bg, 5%);
	}
	.tile-contact {
		position: relative;
	}
	.tile-link {
		display: block;
	}
	.tile-contact-top {
		cursor: pointer;
		min-height: 54px;
		overflow: hidden;
	}

	.icon-contact {
		position: absolute;
		width: 54px;
		height: 54px;
		float: left;
		overflow: hidden;
		.avatar {
			img {
				border: none;
				width: 54px;
				height: 54px;
			}
		}
	}

	.first-line {
		line-height: 15px;
		#util > .excerpt-crop > .ellipsis;

		min-height: 16px;
		padding: 3px 0 0 63px;

		.contact-name {
			font-weight: 500;
			color: @grey-dk;
			display: inline-block;
		}

		.copy-primary-name {
			padding: 0 2px;
			margin: 0;
			fill: @grey-lt;

			&:hover {
				fill: @grey-dk;
			}
		}

		.job-title {
			font-size: @small;
		}
	}

	.second-line,
	.third-line {
		font-size: @small;
		line-height: 16px;
		padding-left: 63px;

		.email,
		.phone,
		.extension {
			display: inline-block;
		}
		.extension {
			padding-left: 5px;
			font-style: italic;
		}
		.last-contacted {
			text-align: right;
			color: @grey;
			float: right;
			padding: 0 4px;
			strong {
				font-weight: normal;
			}
		}
	}

	&.tile-empty {
		overflow: visible; // Allows .float-object-list to show
		&:hover {
			background: darken(@navy-bg, 3%);
		} // Removes :hover effect
		.tile-contact {
			height: 80px;
			z-index: 1000;
			.icon-contact {
				height: 70px;
			}
		}
		.first-line,
		.second-line,
		.third-line {
			margin: 0 0 2px 0;
		}
		.contact-name {
			width: 93%;
		}
		.job-title {
			padding: 0;
		}
		.description {
			font-style: normal;
			width: 99%;
		}

		.phone {
			width: 60%;
		}
		.email {
			width: 38%;
		}

		&.with-parent-account {
			.contact-name {
				width: 60%;
			}
			.job-title {
				width: 31%;
			}
		}
	}
	&.state-deleted .contact-name {
		text-decoration: line-through;
		text-shadow: none;
	}
	&.state-duplicate {
		.control.behavior-dupecheck {
			display: block;
		}
	}
}

/*
 * old-style cards (products, competitors)
 */
.float-object-container {
	margin-bottom: 10px;
	width: 100%;
	position: relative;
	z-index: 2;

	.float-object-wrapper {
		border-width: 1px;
		border-style: solid;
		position: relative;
		z-index: 1;
		border-radius: 2px;
	}
	.float-object {
		overflow: hidden;
	}
	.float-object.popover-opener {
		cursor: pointer;
	}
	.icon {
		float: left;
	}

	.deleted-notice {
		color: @rose; /* TODO: standardize color */
		position: absolute;
		bottom: 10px;
		right: 10px;
		display: none;
	}

	&.version-small {
		font-size: @tiny !important;
		.float-object-list ul li {
			font-size: 11px;
		}
	}
	// half-baked cards, aka chooser cards
	&.state-half-baked {
		.float-object-wrapper {
			z-index: 1000;
		}
		.control.behavior-remove {
			display: block;
		}
	}

	&.state-deleted {
		opacity: 0.6;
		.name {
			text-decoration: line-through;
		}
		.float-object {
			text-shadow: none;
		}
	}
}

.float-object-note {
	border: 1px solid @off-white-dk;
	border-radius: 0 0 2px 2px;
	border-top: 0;
	background: @yellow-lt; /* TODO: standardize color */
	margin: 0 6px;
	padding: 3px 6px 1px 16px;
	font-size: @tiny;
	word-wrap: break-word;

	.empty {
		font-style: italic;
	}
	.icon {
		position: absolute;
		left: 7px;
		#icon > .card-note;
		margin-right: 2px;
	}

	span.value:hover {
		color: @grey-dk;
		background-color: @yellow-lt;
		cursor: text;
	}
	textarea {
		// widths handled per-type
		float: left;
		color: @grey;
		margin-bottom: 4px;
		height: 16px;
	}
	.eip-buttons {
		clear: both;
		overflow: hidden;
		text-align: right;
		a.button {
			margin-left: 4px;
		}
	}
}
.version-small .float-object-note textarea {
	width: 80%;
}

.float-object-note.blink-success {
	border-color: @green;
	background-color: @green-lt;
}

.float-object-template {
	display: none;
}

// autocomplete things
.float-object-container.autocomplete-state-focus .float-object {
	z-index: 1000;
}
.float-object-list {
	color: @grey;
	border-bottom: none;
	position: relative;
	z-index: 1;
	.icon {
		float: left;
		margin-right: 4px;
	}

	ul {
		display: none;
		max-height: 220px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		position: absolute;
		z-index: 999;
		background: rgba(255, 255, 255, 0.95);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

		li {
			height: 17px; // so that if the right-floating email address wraps, it disappears
			background-position: 3px center;
			background-repeat: no-repeat;
			background-color: white;
			padding: 4px 8px;
			font-size: @small;
			cursor: pointer;
			#util > .excerpt-crop > .ellipsis;
			color: @blue;
			&:hover {
				color: @blue-dk;
				background-color: @color-list-hovered;
			}

			span.right-side {
				float: right;
				color: @grey-lt;
			}
			span.account-name {
				color: @grey-lt;
				padding-left: 8px;
			}
		}
		li.autocomplete-state-selected {
			color: @grey-dk;
			background-color: @color-list-selected;
		}
		li.float-object-list-metaitem {
			background-color: @off-white;
			border-bottom: 1px solid @off-white-md;
			color: @blue;
			span {
				color: @grey-dk;
			}
			&:hover {
				color: @blue-dk;
				background-color: @color-list-hovered;
			}
		}
	}
}
.browser-ie .card-enclosure {
	border-collapse: separate;
}

// forms on cards
.float-object-search-field,
.float-object-inactive-field {
	background-color: white;
	border: 1px solid @off-white-dk;
	border-radius: 3px;
	margin: 0;
	padding: 2px 2px;
	font-style: normal;
	font-size: @small;
}

.float-object-inactive-field,
.float-object-inactive-field:hover {
	#util > .opacity(0.33);
}

.float-object-search-field.state-focused {
	opacity: 1;
	border-color: @grey-lt;
}
.float-object-search-field input {
	font-size: inherit;
	width: 95%;
	cursor: text;
}
.modal-tab-contact .float-object-search-field input {
	width: 280px;
}

/* cards */
.product-card-container {
	width: 315px;
	z-index: 3;
	box-shadow: none;

	.float-object-note textarea {
		width: 160px - 50;
	}

	.control.behavior-remove {
		border-color: @brown-dk;
	}
	.product-card-wrapper {
		border: none;
		background: @brown-lt;
		border-radius: 3px 3px 0 0;
		&:hover {
			background: darken(@brown-lt, 5%);
		}

		.product-card {
			margin-right: 15px;
		}

		.cost-original {
			text-decoration: line-through;
		}
		.excerpt-crop {
			text-overflow: clip;
		}
		.name {
			margin: 7px 6px 2px 11px;
			font-weight: 500;
		}
		.sku-readonly {
			font-size: 10px;
			line-height: 12px;
		}
		.price,
		.sku {
			margin: 0 6px 8px 11px;
		}
		.sku {
			margin-bottom: 2px;
		}
	}
	&.state-deleted .product-card-wrapper .name .icon {
		#icon > .card-product-deleted;
	}
}

.competitor-card-container {
	width: 315px;
	box-shadow: none;

	.competitor-card-wrapper {
		border: none;
		background: darken(@rose-lt, 3%);
		border-radius: 3px 3px 0 0;
		min-height: 28px;
		&:hover {
			background: darken(@rose-lt, 6%);
		}

		.competitor-card {
			margin-right: 15px;
		}

		.excerpt-crop {
			text-overflow: clip;
		}
		.name {
			border-color: @grey-lt;
			margin: 5px 6px 2px 9px;
			font-weight: 500;
			&.float-object-search-field {
				input {
					width: 100%;
				}
			}
		}
	}
	&.state-empty {
		.competitor-card-wrapper .name {
			padding: 2px 3px 3px;
			margin: 3px 18px 0px 4px;
		}
	}
	&.state-deleted .competitor-card-wrapper .icon {
		#icon > .card-competitor-deleted;
	}
}
