#myaccount .account-settings-table {
	color: @grey;
	margin: 5px 0 0 0;

	tbody {
		display: block;
		padding-top: 8px;
	}

	tr.password {
		display: none;
	}

	td {
		padding: 8px 0;
		&.column-label {
			vertical-align: top;
			padding: 8px 0;
			font-weight: 500;
			width: 40%;
			font-size: @normal;
			span {
				color: @grey-lt;
				font-size: @small;
				margin-left: 3px;
				font-weight: normal;
			}
		}
		&.column-value {
			font-size: @normal;
			width: 400px;
			padding: 0;
			select {
				width: 300px;
				overflow: hidden;
			}
		}
		.status-badge {
			background-color: #f2f0f0;
			border-radius: 3px;
			padding: 2px 8px;
		}
		label {
			margin: 0 7px 0 0;
		}
		input[type='checkbox'],
		input[type='radio'] {
			margin: 0 2px 0 0;
		}
	}

	.email-inputs > div {
		position: relative;
	}

	input.type-text.type-email {
		margin-bottom: 16px;
	}

	.email-display {
		margin-bottom: 12px;
		display: block;
	}

	select.type-role {
		margin-bottom: 16px;
	}

	a.action-removeEmail {
		transform-origin: center center 0px;
		transform: scale(1.2);
		position: absolute;
		top: 8px;
		left: -22px;
		color: @icon-secondary;
		&:hover {
			color: @rose;
		}
	}

	a.account-action {
		display: block;
		color: @blue;
		&:hover {
			color: @blue-dk;
			text-decoration: underline;
		}
	}

	a.action-changeMfa {
		display: inline;
		margin-left: 10px;
	}

	a.action-removeMfa {
		display: block;
		margin-top: 25px;
		padding: 25px 0 10px;
		border-top: 1px solid @off-white-dk;
		color: @rose;
	}

	div.twitter-description {
		margin-bottom: 16px;
	}

	div.twitter-label {
		display: flex;
		align-items: center;
	}

	.twitter-icon {
		margin-left: 8px;
		fill: @twitter-blue;
	}
}
