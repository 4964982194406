.license-switch-container {
    width: 70px;
}

.disabled {
    opacity: 0.6;
}

.info-icon {
    opacity: 0.5;
}

.info-icon:hover {
    opacity: 1;
}
