.tab {
    cursor: pointer;
    position: relative;
    font-size: var(--font-normal);
    color: var(--grey);
    display: inline-block;
    background-color: var(--off-white-dk);
    border-radius: 3px 3px 0 0;
    margin: 0 16px 0 0;
    padding: 8px 16px;
    /* Add min-width so tabs don't jump when selected bold text is applied */
    min-width: 70px;
    text-align: center;
}

/* Set fill for icon on the inactive tab */
.tab > div > div > svg {
    fill: var(--grey);
}

.tab:hover:not(.tab--active) {
    color: var(--white);
    background-color: var(--grey-dk);
}

/* Set hover fill for icon on the inactive tab */
.tab:hover:not(.tab--active) > div > div > svg {
    fill: var(--white);
}

.tab--active {
    composes: tab;
    cursor: default;
    font-weight: 500;
    color: #fff;
    background-color: var(--grey-dk);
}

/* Set fill for icon on the active tab */
.tab--active > div > div > svg {
    fill: var(--white);
}
