.stages-empty {
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    justify-content: space-around;
    align-items: center;
    max-width: 1250px;
    margin: 0 auto;
}

.stages-empty > * {
    width: 475px;
    height: 240px;
    margin: 16px;
}

.stages-empty-svg > svg {
    height: 194px;
    margin-top: 6px;
    margin-right: 6px;
}

.group {
    display: flex;
    padding: 16px;
}

.left {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.right {
    height: 100%;
    width: 220px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (min-width: 1105px) {
    .stages-empty {
        flex-direction: row;
    }
}
