.container {
    height: 100%;
}

.search-header {
    height: 40px;
    border-bottom: 1px solid var(--off-white-dk);
}

.bucket-list {
    width: calc(40% - 1px);
    float: left;
    height: calc(100% - 41px);
}

.bucket-list > div {
    border-left: 1px solid var(--off-white-dk);
}

.column-list {
    width: calc(60% - 1px);
    position: relative;
    float: left;
    border-right: 1px solid var(--off-white-dk);
    height: calc(100% - 60px);
}

.column-list > div {
    height: 100%;
}

.column-list ul {
    height: 100%;
    overflow-y: auto;
    border-left: 1px solid var(--off-white-dk);
}

.container :global(.ui-row) {
    font-size: var(--font-normal);
    position: relative;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    font-weight: 500;
    color: var(--grey-lt);
    background-color: white;
    border-bottom: 1px solid var(--off-white-dk);
}

.container :global(.ui-row .ui-field-subtitle) {
    color: var(--grey-lt);
    font-weight: normal;
    font-size: var(--font-small);
    margin-left: 6px;
}

.container :global(.ui-field-checkbox) {
    float: left;
    margin-right: 10px;
    margin-top: 15px;
}

.container :global(.ui-row__header) {
    cursor: pointer;
}

.container :global(.ui-header-count) {
    position: absolute;
    top: 9px;
    right: 40px;
    padding: 0 10px;
    text-align: center;
    background-color: var(--grey-lt);
    color: white;
    font-size: var(--font-small);
    line-height: 20px;
    border-radius: 20px;
}

.container :global(.ui-header-icon) {
    color: var(--grey-lt);
    position: absolute;
    padding: 0;
    right: 10px;
}

.container :global(.ui-row--selectable) {
    cursor: pointer;
}

.container :global(.ui-row--selectable:hover),
.container :global(.ui-row__header:hover),
.container :global(.ui-row--selected.ui-row__field:hover),
.container :global(.ui-row--selected.ui-row__header:hover) {
    background-color: var(--color-list-hovered);
}

.container :global(.ui-row--selectable:hover .ui-field-name),
.container :global(.ui-row__header:hover .ui-header-name) {
    color: var(--grey-dk);
}

.container :global(.ui-row--selected.ui-row__field) {
    background-color: var(--color-list-selected);
}

.container :global(.ui-row--selected .ui-field-name) {
    color: var(--grey-dk);
}

.container :global(.ui-row--selected.ui-row__header) {
    background-color: var(--color-list-selected);
    cursor: default;
}

.container :global(.ui-row--selected) {
    background: none;
    cursor: default;
}

.container :global(.ui-row--selected .ui-header-name) {
    color: var(--grey-dk);
}

.container :global(.ui-row--selected .ui-header-icon > .react-icon) {
    color: var(--grey-dk);
}

.container :global(.ui-row__header-custom-fields:not(:first-child)) {
    margin-top: 24px;
    background: none;
    cursor: default;
}
