.flash-messages {
    position: absolute;
    bottom: 81px;
    left: 0;
    z-index: 1;
    width: calc(100% + 64px);
    margin-left: -32px;
}

.flash-message-undo-button {
    position: absolute;
    right: 10px;
}
