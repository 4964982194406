@value image-container-width: 320px;
@value padding-size: 20px;
@value total-padding: 40px; /* 2 * padding-size */

.content-container {
    /* container-name & container-type are needed for @container query */
    container-name: content-wrapper;
    container-type: inline-size;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    text-align: left;
    height: 100%;
}

.thumbnail-container {
    flex: 1 1 image-container-width;
    min-height: 128px;
    height: auto;
    position: relative;
}

.thumbnail-container--centered {
    composes: thumbnail-container;
}

.thumbnail-container--full {
    composes: thumbnail-container;
}

.thumbnail-container--no-resize {
    composes: thumbnail-container;
}

.play-button {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    z-index: 10;
    transition: 300ms ease-in-out opacity;
    cursor: pointer;
}

.bookmark-icon-container {
    position: absolute;
    top: 0;
    left: calc(padding-size - 4px);
}

/* When there's no thumbnail, add extra margin to text container to space the bookmark icon out */
.bookmark-icon-container:first-child ~ .text-container {
    margin-top: 16px;
}

.complete {
    position: absolute;
    top: 16px;
    right: 16px;
}

.thumbnail-container--centered > :first-child {
    max-width: calc(100% - total-padding);
    max-height: calc(100% - total-padding);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.thumbnail-container--full > :first-child {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.thumbnail-container--no-resize > :first-child {
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text-container {
    flex: 1 0 calc(100% - image-container-width);
    min-width: image-container-width;
    padding: padding-size;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 8px;
}

.minimize-button {
    position: absolute;
    padding: 4px;
    top: calc(padding-size - 12px);
    right: calc(padding-size - 6px);
    color: var(--icon-secondary);
}

.minimize-button:hover,
.minimize-button:focus {
    color: var(--icon-secondary-dk);
}

/* When there's a minimize button, add a margin to title to avoid collision */
.minimize-button ~ .text-container > :first-child {
    margin-right: 12px;
}

.cta-text {
    display: flex;
    align-items: center;
    gap: 6px;
}

/* 2 * image-container-width */
@container content-wrapper (max-width: 640px) {
    .thumbnail-container {
        min-height: 176px;
    }

    .thumbnail-container--centered > :first-child {
        max-width: calc(80% - total-padding);
        max-height: calc(80% - total-padding);
    }

    .text-container {
        min-width: 100%;
    }
}
