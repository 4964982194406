/* Merge with forms.css */
.next-steps-form {
    padding: 16px;
    box-sizing: border-box;
    /* Provides for the height of the top bar and the delete button */
    height: calc(100% - 144px);
    overflow-y: auto;
}

.form-description {
    color: var(--grey-lt);
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--off-white-dk);
}
