.name-cell {
    display: flex;
    /* Make name cell full height to ensure checkbox is full height */
    height: 45px;
    position: relative;
    top: -4px;
}

.name-cell > :last-child {
    padding: 0;
    width: calc(100% - 28px);
}

.name-cell--no-checkbox > :last-child {
    padding: 0 0 0 16px;
    width: calc(100% - 32px);
}

.checkbox {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.checkbox input[type='checkbox'] {
    margin: 12px 12px 12px 16px;
}
