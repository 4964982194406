.container {
    position: absolute;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 2;
}

.container--horizontal {
    composes: container;
    max-width: 70%;
    max-height: 50%;
    left: 15%;
}

.container--horizontal-bottom {
    composes: container;
    max-width: 100%;
    max-height: 50%;
    left: 0%;
    right: 0%;
    bottom: 0%;
}

.container--vertical {
    composes: container;
    position: relative;
    max-width: 450px;
    min-width: 300px;
    flex-direction: column;
}

.image {
    max-height: 70%;
    max-width: 70%;
}

.image-container {
    height: 100%;
    width: 100%;
    background-color: var(--plum-bg);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-container {
    padding: 32px 16px 16px 16px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
}

.footer--horizontal {
    composes: footer;
    left: calc(50% + 8px);
}

@media screen and (max-width: 1250px) {
    .container--horizontal {
        max-height: 55%;
    }
}

@media screen and (max-width: 1090px) {
    .container--horizontal {
        max-height: 65%;
    }
}

@media screen and (max-width: 1000px) {
    .container--horizontal {
        max-height: 75%;
    }
}
