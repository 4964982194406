.custom-domain-sidebar {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--grey-dk);
    position: relative;
}

.error-text {
    color: var(--rose-dk);
}
