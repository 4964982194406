/* Export this value for use in JS.
* e.g. height = require('./styles.css').height;
*/
@value animateOutTiming: 250ms;

.container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    box-sizing: border-box;
    background-color: var(--off-white);
    padding: 16px 16px 16px 16px;
    overflow: auto;
    opacity: 0.5;
    transform: translateY(200px);
    animation: animate 400ms var(--custom-easing) 0s forwards;
}

.container-animate-out {
    composes: container;
    animation-name: animate-out;
    animation-timing-function: var(--custom-easing);
    animation-duration: animateOutTiming;
    animation-fill-mode: forwards;
}

.chart-wrapper {
    position: relative;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.header {
    /* ⚠️ .col-left composes these styles. ⚠️ */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.col-left {
    composes: header;
    width: 280px;
    margin-bottom: 0;
}

.loading-icon {
    fill: var(--orange-lt);
}

.close-button {
    padding: 0;
    z-index: 1;
}

.close-button :global(.svg-close svg) {
    fill: var(--grey);
}

.close-button :global(.svg-close:hover svg) {
    fill: var(--grey-dk);
}

@keyframes animate-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100px);
    }
}

@keyframes animate {
    0% {
        opacity: 0.5;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
