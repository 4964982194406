.stages-empty-graphic {
    display: flex;
    position: relative;
    width: 100%;
}

.stages-empty-chevron-container {
    display: flex;
    opacity: 0.4;
}

.stages-empty-graphic-text {
    position: absolute;
    top: 10px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: var(--grey-dk);
    box-sizing: border-box;
    left: calc(50% - 183px);
    padding: 0 16px;
}
