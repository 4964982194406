.pre-auth-layout {
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
}

.left-column {
    display: none;
}

/* don't get too clever with this: it switches between the right and left column for mobile */
.logo {
    width: 200px;
    padding-bottom: 32px;
}

@media screen and (min-width: 700px) {
    @supports (grid-gap: 0) {
        .pre-auth-layout {
            grid-template-columns: 1fr 1fr;
        }

        .left-column {
            display: block;
            position: sticky;
            top: 0;
            height: 100vh;
            overflow: hidden; /* Hides some strange 5px white bottom section */
        }

        .logo {
            position: fixed;
            top: 24px;
            left: 24px;
            padding: 0;
            z-index: 1;
        }
    }
}

.pre-auth-layout h1 {
    margin-bottom: 4px;
}

.left-column > * {
    height: 100%;
    width: 100%;
    animation: fadein 0.5s;
    object-fit: cover;
}

.left-column--with-background {
    composes: left-column;
    height: 100vh;
    background-size: 150%;
}
.left-column--with-background img {
    width: 450px;
    display: flex;
    object-fit: contain; /* Fixes janky img in edge */
    margin: 0 auto;
}

.right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 64px 0;
}

.right-column > *:not(.logo) {
    width: calc(100% - 64px);
    max-width: 400px;
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: scale(1.1);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
