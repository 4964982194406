.container {
    display: flex;
    padding-bottom: 2px; /* needed so bottom-border (selected) displays */
}

.label {
    margin: 0 4px -2px 4px;
    font-size: 15px;
    padding: 9px 8px 8px 8px;
    text-align: center;
    border-bottom: 2px solid transparent;
    transition: all 250ms var(--custom-easing);
}

.label--icon-only {
    position: relative;
    bottom: 8px;
    padding: 0 12px;
    transition: all 250ms var(--custom-easing);
}

.container input:enabled + .label,
.container input:enabled + .label--icon-only {
    color: var(--blue);
    cursor: pointer;
}

.container input:disabled + .label,
.container input:disabled + .label--icon-only {
    color: var(--grey);
}

.container input:enabled + .label:hover,
.container input:active + .label {
    color: var(--grey-dk);
    border-bottom: 2px solid var(--off-white-md);
}

.container input:enabled + .label--icon-only:hover,
.container input:active + .label--icon-only {
    color: var(--grey-dk);
}

.container input[type='radio'] {
    opacity: 0; /* Allows keyboarding */
    width: 0;
}

.container input[type='radio']:checked + .label {
    color: var(--grey-dk);
    font-weight: 500;
    border-bottom: 2px solid var(--off-white-md);
}
