.container {
    background-color: var(--success-bg);
    color: var(--success);
    padding: 2px 8px;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
}

/* remove built-in padding from the info button */
.container button {
    padding-right: 0;
}

.info {
    width: 160px;
}
