.stat-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 36px;
}

.progress-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.progress-bar-wrapper > :last-child {
    text-align: right;
    width: 50px;
}

.progress-bar-wrapper > :first-child {
    min-width: 220px;
    margin-right: 8px;
    align-items: center;
}

.stat-item > :last-child {
    margin-left: 24px;
    text-align: right;
}
