.footer-add-buttons {
    padding: 16px 24px;
    border-top: 1px solid var(--off-white-dk);
}

.credits-info {
    margin-top: 4px;
    text-align: center;
    color: var(--grey-lt);
}
