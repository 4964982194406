.audience-membership-report {
    position: relative;
    width: 100%;
}

.audience-membership-report > div:first-child {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 10;
}

.audience-membership-show-membership-checkbox input {
    margin-right: 4px !important; /* Matches legend checkbox distance */
}
