.divider {
    position: absolute;
    left: calc(50% - 25px);
    top: -11px; /* technically 8px is centered, this looks better */
    width: 50px;
    height: 18px;
    background-color: #fff;
    padding: 1px 5px;
    box-sizing: border-box;
    text-align: center;
    color: var(--grey-lt);
    font-weight: 500;
    z-index: 1;
}

.container {
    display: flex;
    align-items: center;
}

.container--disabled {
    composes: container;
    opacity: 0.6;
}

.divider-line {
    height: 1px;
    background-color: var(--off-white-dk);
    display: flex;
    flex-grow: 1;
}
.divider-text {
    margin: 0 8px;
    vertical-align: middle;
}
