.admin-popover-info {
    display: inline;
    position: relative;
}

.admin-popover-info > div {
    position: absolute;
    left: -16px;
    bottom: -1px;
}
