//Variables
@white: #fff;

//Mixins
.border-radius(@radius: 5px) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}

.opacity(@opacity: 100) {
  filter: e(%("alpha(opacity=%d)", @opacity));
    -moz-opacity: @opacity / 100;
         opacity: @opacity / 100;
}

//Styles
.mentions-input-box {
  position: relative;
  background: @white;

  textarea{
    width: 100%;
    display: block;
    height: 18px;
    padding: 9px;
    border: 1px solid #dcdcdc;
    border-radius:3px;
    overflow: hidden;
    background: transparent;
    position: relative;
    outline: 0;
    resize: none;

    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .mentions{
    position: absolute;
    left: 1px;
    right: 0;
    top: 1px;
    bottom: 0;
    padding: 9px;
    color: @white;
    overflow: hidden;

    white-space: pre-wrap;
    word-wrap: break-word;

    & > div{
      color: @white;
      white-space: pre-wrap;
      width: 100%;

      & > strong{
        font-weight:normal;
        background: #d8dfea;

        & > span{
          .opacity(0);
        }
      }
    }
  }
}

.mentions-input-box .mentions-autocomplete-list {
  display: none;
  background: @white;
  border: 1px solid #b2b2b2;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10000;
  margin-top: -2px;

  .border-radius();
  border-top-right-radius:0;
  border-top-left-radius:0;

  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.148438);
     -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.148438);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.148438);

  ul{
    margin: 0;
    padding: 0;
  }

  li{
    background-color: @white;
    padding: 0 5px;
    margin: 0;
    width: auto;
    border-bottom: 1px solid #eee;
    height: 26px;
    line-height: 26px;
    overflow: hidden;
    cursor: pointer;
    list-style: none;
    white-space: nowrap;

    &:last-child{
      .border-radius();
    }

    & > img,
    & > div.icon{
      width: 16px;
      height: 16px;
      float: left;
      margin-top:5px;
      margin-right: 5px;
      -moz-background-origin:3px;

      .border-radius(3px);
    }

    em{
      font-weight: bold;
      font-style: none;
    }

    &:hover,
    &.active{
      background-color: #f2f2f2;
    }

    b{
      background: #ffff99;
      font-weight: normal;
    }
  }
}
