.fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 16px;
}

.footer {
    display: flex;
    justify-content: flex-end;
}
