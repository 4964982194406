.title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--grey-dk);
}

.title-container--disabled {
    composes: title-container;
    color: var(--grey);
}

.title {
    font-size: var(--font-normal);
    padding-bottom: 4px;
}

.type-container {
    display: flex;
    align-items: center;
    font-size: var(--font-small);
    color: var(--grey);
}

.icon {
    margin-right: 6px;
}

.icon,
.icon > div {
    /* Some icons, like the lead icon are weird and contain another div inside that needs alignment */
    display: flex;
    align-items: center;
}
