.timeline-creator {
    border-top: 1px solid var(--off-white-md);
    border-bottom: 1px solid var(--off-white-md);
}

.content-container {
    height: 100%;
}

.text-area-container {
    position: relative;
    min-height: 120px;
}

.tabs > * {
    height: 60px;
}

.tabs > * > button {
    display: flex;
    align-items: center;
}

.transcribe {
    position: absolute;
    bottom: 16px;
    right: 16px;
    color: white;
    fill: white;
}
