.scroll-button {
    top: -8px;
    height: 80px;
    width: 40px;
    opacity: 0;
    transform: translateX(-50px);
    pointer-events: none;
}

.scroll-button-left {
    composes: scroll-button;
    left: -7px;
    border-radius: 0 70px 70px 0;
}

.scroll-button-right {
    composes: scroll-button;
    right: -8px;
    border-radius: 70px 0 0 70px;
    left: auto;
    transform: translateX(50px);
}

.active {
    pointer-events: all;
    opacity: 0.4;
    transform: translateX(0);
}

.scroll-button-right-active {
    composes: scroll-button-right;
    composes: active;
}

.scroll-button-left-active {
    composes: scroll-button-left;
    composes: active;
}

.scroll-button-left-active:hover {
    opacity: 0.5;
    left: 0;
}

.scroll-button-right-active:hover {
    opacity: 0.5;
    right: 0;
}

.scroll-button-left-active:hover .chevron-left {
    margin: 0 8px 0 0;
    color: white;
}

.scroll-button-right-active:hover .chevron-right {
    margin: 0 0 0 14px;
    color: white;
}

.chevron {
    display: inline-block;
    font-size: 35px;
    color: var(--grey);
    transition: all 400ms var(--custom-easing);
}

.chevron-left {
    composes: chevron;
    margin: 0 2px 0 0;
}

.chevron-right {
    composes: chevron;
    margin: 0 0 0 5px;
}
