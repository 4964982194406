.two-line-button-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 12px 16px;
}

.two-line-button-card:hover {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25) !important;
    transition: box-shadow 0.2s ease;
}

.two-line-button-card:focus-visible {
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}
