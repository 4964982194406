.sending-confirmation-page {
    position: relative;
    height: 100%;
    text-align: center;
}

.content-container {
    width: 300px;
    margin: 64px auto 8px;
}

.illustration-container {
    margin: 0 auto 32px auto;
    max-width: 200px;
}

.email-illustration {
    /* Needs extra padding to account for how big the drip sequence one is */
    padding-top: 48px;
}

.title-text {
    font-size: var(--font-big);
    font-weight: 500;
    color: var(--grey-dk);
}

.description-text {
    color: var(--grey-lt);
}

/* The footer */
.sending-confirmation-page > :last-child {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.text-container {
    margin-bottom: 20px;
}
