.container {
    width: 288px;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
}

.option {
    padding: 16px;
    text-align: left;
}

.option:hover {
    background: var(--color-list-hovered);
}

.option--selected {
    background: var(--color-list-selected);
}

.option--compact {
    padding: 16px;
}

.option:last-child {
    border-radius: 3px;
}

.status-dot {
    border-radius: 50%;
}
