.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.row:hover {
    background-color: var(--off-white);
}

.image {
    max-width: 100px;
    object-fit: contain;
    margin-right: 16px;
    padding-left: 8px;
}
