// note: z-indices in JS
@popover-width: 312px;

.popover-opener, .popover-opener-ajax { cursor: pointer; }

.popover {
	z-index: 9100;
	position: absolute;
	top: 0;
	left: 0;
	width: @popover-width;
	border-radius: 3px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.3);
	background-color: white;

	.popover-top,
	.popover-bottom {
		display: none;
	}
	.popover-nub { // more positioning in orientations
		position: absolute;
		.2x('@{img-root}/responsive-layout/popover/nub-white.png', "no-repeat", 14px 7px);
		width: 14px;
		height: 7px;
	}
	.popover-x {
		cursor: pointer;
		position: absolute;
		top: 4px;
		right: 12px;
		color: @grey-lt;
		font-size: 28px;
		&:hover {
			color: @grey-dk;
		}
	}
	.popover-hat {
		position: relative;
		font-size: @big;
		font-weight: 500;
		color: @grey-dk;
		#util > .border-box;
		margin-bottom: 10px;
		padding: 12px;
		line-height: initial;
		.tint {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0.3;
		}

		strong { font-size: 18px; }

		.eip-container:hover {
			.value {
				background: @yellow-lt;
			}
		}
		.image {
			margin-right: 16px;
			z-index: 10;
		}
		.initials {
			font-family: IBM Plex Sans;
			width: 40px;
			height: 40px;
			color: #fff;
			line-height: 40px;
			font-size: 15px;
			box-shadow: none;
			flex-shrink: 0;
			background-color: @orange-dk;
			border-radius: 40px;
			position: absolute;
			top: 12px;
			left: 12px;
			bottom: 0;
			right: 0;
			text-align: center;
			text-transform: uppercase;
		}
	}
	&.tint-red {
		.popover-hat {
			color: @rose;
		}
	}
	&.tint-green {
		.popover-hat {
			color: @green;
		}
	}
	&.tint-purple {
		.popover-hat {
			color: @plum;
		}
	}
	&.tint-blue {
		.popover-hat {
			color: @navy-dk;
		}
	}

	.popover-area.popover-subheader {
		.avatar {
			float: left;
			border: 1px solid @grey;
			/* TODO: standardize color */
			margin-right: 10px;
		}
		.name {
			padding-top: 2px;
			padding-bottom: 4px;
			font-size: 15px;
			font-weight: 500;
		}
		.meta {
			color: @grey-dk;
		}
	}
	// i.e. the mini "Timeline" label.
	// the background is same as the top, but tinted lighter with a pseudo-element
	.popover-area.popover-subtitle {
		position: relative;
		font-size: 12px;
		line-height: 18px;
		height: 18px;
		padding: 0 8px;
		background: @off-white-md url("@{img-root}/popovers/top-bg.png"); /* TODO: standardize color */
		color: @grey-dk;
		border-top: 1px solid @off-white-dk;
		border-bottom: 1px solid @off-white-dk;
		text-transform: uppercase;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: @off-white-dk; /* TODO: standardize color */
			opacity: 0.4;
		}
	}

	.popover-area {
		padding: 8px 12px;
		h3 {
			padding-bottom: 8px;
		}

		img {
			width: 100%;
			margin-bottom: 10px;
			border: 1px solid @off-white-dk;
			border-radius: 2px;
		}
		.avatar img { border: none; }

		p.info {
			padding: 4px;
			color: @grey; /* TODO: standardize color */
			font-size: 12px;
		}

		&.behavior-collapsible {
			max-height: 170px !important;
			overflow: auto;
		}

		a {
			&.action-delete { color: @rose; }
		}

		select {
			max-width: 100%;
			margin-bottom:10px;
		}

		.delay-note {
			width: 100%;
		}

		.link-list {
			display: flex;
			flex-direction: column;
			line-height: 20px;
		}
	}

	.popover-area-report {
		padding: 4px 8px;
	}

	.popover-buttons {
		text-align: right;
		border-top: none;
		margin-top: 10px;
		padding: 12px;
		clear: both;

		a {
			margin: 0 0 0 5px;
			&.float-left {
				margin: 0 5px 0 0;
			}
			&.action-delete,
			&.action-cancel {
				float: left;
				margin: 0 5px 0 0;
				
				&.singular {
					margin: 0 5px 13px 0;
				}
			}
			&.action-delete { color: @rose; }
		}

		select {
			position: relative;
			margin-top: 4px;
		}
	}
}

/* set popover-area.behavior-collapsible max-height for IE */
.browser-ie .popover .popover-area.behavior-collapsible {
	height: ~'expression( this.scrollHeight > 171 ? "170px" : "auto" ) !important';
}

.popover.orientation-north .popover-nub {
	bottom: -7px;
	left: 49%;
  transform: rotate(180deg);
}
.popover.orientation-south .popover-nub,
.popover.orientation-south-west .popover-nub,
.popover.orientation-south-east .popover-nub {
	top: -7px;
	left: 49%;
}
.popover.orientation-south-east .popover-nub {
	left: 15px;
}
.popover.orientation-south-west .popover-nub {
	left: 280px;
}
.popover.orientation-east .popover-nub,
.popover.orientation-west .popover-nub {
	top: 49%;
}
.popover.orientation-east .popover-nub {
	transform: rotate(270deg);
	// Hack needed for hoverviews
	left: -10px !important;
	right: auto !important;
}
.popover.orientation-west .popover-nub {
	transform: rotate(90deg);
	// Hack needed for hoverviews
	right: -10px !important;
	left: auto !important;
}

/* ===================== */
/* = loading indicator = */
/* ===================== */
.popover-loading {
	width: 80px;
	height: 91px;
	background: url("@{img-root}/popovers/loading-bg-over.png") no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9100;
	div {
		display: block;
		position: absolute;
		top: 7px;
		left: 8px;
	}
}
.popover-loading.orientation-south {
	background: url("@{img-root}/popovers/loading-bg-under.png") no-repeat;
	div { top: 18px; }
}
.popover-loading.orientation-east {
	width: 91px;
	height: 80px;
	background: url("@{img-root}/popovers/loading-bg-east.png") no-repeat;
	div { left: 18px; }
}


/* =============================== */
/* = specific, sitewide popovers = */
/* =============================== */
.popover-list-user {
	width: 350px;

	.delete-user {
		#util > .inline-block;
		margin-bottom: -4px;
		cursor: pointer;
		.icon { #icon > .modal-name-delete }
		&:hover .icon  { #icon > .modal-name-delete.state-hover }
		&:active .icon { #icon > .modal-name-delete.state-active }
	}

	dd {
		min-height: 28px;
	}
}
.popover-user {
	.popover-username {
		font-size:15px;
		color: @grey-dk;
		font-weight: normal;
	}
	.meta {
		margin-top: 4px;
		a { margin-right: 6px; }
	}
}

.popover-list-email-security {
	width: 350px;
}
.popover-list-email-security dl.form-standard dt {
	width: 90px;
	margin-right: 12px;
}

.popover-duplicate {
	.action-remove, .contact-enclosure {
		display: none;
	}
}

.popover-errors .popover-area .errors {
	height: 300px;
	overflow-y: auto;
	li {
		list-style: disc outside;
		margin-left: 15px;
	}
}

.popover-list-market .currency-other { display: none }

.popover-hoverview {

	.fieldblock-container {
		position: relative;

		.hoverview-hr {
			border-bottom: 1px solid @off-white-dk;
			font-size: @tiny;
	    margin-bottom: 5px;
		}

		.empty-placeholder {
			clear: both;
			margin: 10px 0 20px 0;
		}
	}

	ul.fieldblock {
		padding-left: 14px;
		.fieldrow {
			width: 274px;
			padding: 0 4px;

			.buttons { display: none; }

			.label {

				.mode-read { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

				&:hover {
					.mode-read {
						overflow: visible;

						span {
							position: relative;
							margin: 0 -3px;
							padding: 1px 3px;
							border-radius: 3px;
							background: @grey-lt;
							color: white;
							text-shadow: 0 1px 2px rgba(0,0,0,0.5);
						}
					}
				}
			}

			.value { float: left; }
		}
	}
}

.peep-popover-leads {

	.popover-middle {
		//overflow must be addressed here, so the hat doesn't get cut off
		overflow-y: scroll;
		//35px accounts for the header
		max-height: calc(~'100% - 35px');
	}
	.popover-hat {
		.pie {
	    float: left;
			margin: -2px 4px 0 0;
		}
		span.estimated-value-container {
			float: right;
			margin: -11px 16px 0 0;
		}
	}

	.leads-list {
		min-height: 65px; // height of two leads

		li {
			line-height: 20px;
			border-bottom: 1px dotted @off-white-dk;
			#util > .clearfix;
			&:last-child { border-bottom: none; }
			&:first-child { border-top: 1px dotted @off-white-dk; }
			a {
				height: 20px;
				display: block;
				padding: 6px 8px;
				&:hover {
					background: @color-list-hovered;
					span { color: @grey-dk; }
				}
			}
			.pie {
				float: left;
				margin: 0 6px 0 0;
			}
			span {
				#util > .excerpt-crop > .ellipsis;
				display: inline-block;
			}
			span.lead-id {
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
				max-width: 80px;
			}
			span.description {
				max-width: 120px;
				margin: 0 2px;
			}
			span.lead-value {
				font-weight: 500;
				float: right;
				max-width: 62px;
			}
		}
	}
}

.popover-help {
	border: 1px solid @grey;
	border-bottom: 1px solid @grey-dk;
	.popover-area {
		background: none; // background-color bleeds over border-radius
		padding: 14px 16px;
	}
	.popover-close {
		font-size: @big;
		position: absolute;
		line-height: 8px;
		font-weight: 500;
		color: @grey-lt;
		cursor: pointer;
		top: 9px;
		right: 9px;
		&:hover {
			color: @grey;
		}
	}
	p {
		margin: 0 0 6px 0;
	}
	a {
		color: @blue;
		&:hover {
			text-decoration: underline;
			color: @blue-dk;
		}
	}
}

/* product popover only on lead pages */
.lead-popover-product {
	.sku {
		font-size: 11px;
		line-height: 14px;
		padding-bottom: 2px;
	}
	td.quantity {
		width: 50px;
		input {
			width: 20px;
		}
	}
	td.price-per {
		width: 100px;
		input {
			width: 60px;
		}
	}
	td.discount {
		width:70px;
		input {
			width: 25px;
		}
	}
}

/* close popover only on lead pages */
.lead-popover-close { // anchored in lead-header-wrapper
	table {
		table-layout: fixed;

		input { text-align: right; }
		td, th { padding: 1px 1px; }
		.icon {
			display: none;
		}
		.quantity {
			padding-right: 8px;
			width: 35px;
			input { width: 20px; }
		}
		.price {
			text-align: right;
			width: 70px;
			input { width: 50px;}
		}
		.label {
			font-weight: 500;
		}
		.excerpt-crop {
			padding-right: 7px;
		}
		.price-final { font-weight: 500; }
		.price-final, th.price { // shunt non-input parts to compensate for <input> padding
			padding-right: 5px;
		}
	}
	table.subtotal { text-align: right; }

	select {
		width: 100%;
	}

	a.manage-outcomes {
		color: @grey-lt;
		text-decoration: underline;
		font-size: 11px;
		line-height: 18px;
		float: right;
	}
}

// Only used on the lead screen
.popover-process-edit {

	.popover-area {
		.buttons {
			float: right;
		}
	}

	.popover-buttons {
		select {
			float: left;
			width: 80%;
		}
	}
}
