@value columnWidth from '../../card-containers/cards-container.css';
@value columnGutter from '../../card-containers/cards-container.css';

.absolute-container {
    composes: card from '../sales-automation-card.css';

    /* Absolutely positioned on top of relative container so it can
    extend multiple columns */
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    /* Custom padding-top so the border doesn't affect text centering */
    padding-top: 14px;

    /* Remove margin - that's covered by the relative-container */
    margin: 0;

    /* Two lines, align center */
    align-items: center;

    /* Needed for border-radius overflow */
    overflow: hidden;

    /* Need this so that cards are clickable/hoverable across stages */
    z-index: 3;
}

.absolute-container--disabled {
    composes: absolute-container;
}

.card-border {
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    width: 100%;
    opacity: 0.5;
    background-color: var(--blue);
}

.card-border--striped {
    composes: card-border;
    background-image: repeating-linear-gradient(
        100deg,
        var(--blue),
        var(--blue) 16px,
        var(--blue-lt) 16px,
        var(--blue-lt) 32px
    );
}

.mailchimp-logo {
    width: 32px;
    height: 32px;
    margin-right: 12px;
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.title {
    composes: title from '../sales-automation-card.css';
    line-height: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.missing-list {
    color: var(--rose);
}

.subtitle {
    composes: second-line from '../sales-automation-card.css';
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 4px;
}

.subsubtitle {
    composes: subtitle;
    color: var(--grey-lt);
    width: 100%;
}

.subsubtitle > span {
    width: calc(100% - 24px);
}

/* Closed-lead specific */

.closed-lead-relative-container {
    display: flex;
    align-items: center;
}

.list-name {
    composes: title from '../sales-automation-card.css';
    width: 222px;
    display: flex;
}

.list-name > * {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.line-icon {
    min-width: 24px;
}

.line-icon > svg {
    color: var(--grey);
}
