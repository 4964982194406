@import 'include';

.page-billing {
	a.cancellation {
		color: @rose;
	}
	.page-main-content {
		padding-right: 0 !important;

		#settings-page-title-bar.title-bar-container {
			margin-right: 0;
		}
	}
	.page-flow-area {
		h1.styled {
			font-size: 32px;
			line-height: 40px;
		}
		.action-submit {
			float: right;
			margin-top: 2px;
		}

		div.billing-section {
			float: left;
			width: 310px;
			margin: 0;
			&.left {
				margin-right: 40px;
			}
		}

		form.validate-billing {
			input.input-big {
				font-size: 18px;
				padding: 3px;
				margin: 0 0px 10px 0;
				border: 1px solid @off-white-dk; /* TODO: standardize color */
				border-radius: 3px;
				&.input-inline {
					margin-right: 0px;
				}
			}

			.quantity-error {
				display: none;
				color: @rose-dk;
			}
			&.state-quantity-error {
				input[name='num_users'] {
					border-color: @rose-dk;
				}
				label.billing-users {
					color: @rose-dk;
				}
				div.total {
					display: none;
				}
				.quantity-error {
					display: block;
				}
			}
		}

		div.billing-section-plan {
			div.billing-plan-legacy {
				margin-bottom: 15px;
				opacity: 0.7;
				label {
					display: block;
					margin: 16px 0 0 0;
				}
			}

			&.state-legacy {
				div.billing-plan-legacy {
					opacity: 1;
				}
				table.billing-statement {
					opacity: 0.2;
				}
			}

			input.input-num_users {
				float: left;
				width: 90px;
				margin-bottom: 0;
				font-weight: 500;
			}
			label.billing-users {
				display: block;
				float: left;
				font-weight: 500;
				font-size: 16px;
				color: black;
				margin: 4px 0 0 6px;
				line-height: 12px;
			}
			span.billing-current-users {
				font-size: 11px;
				font-weight: normal;
				color: @grey-lt;
			}
			table.billing-statement {
				margin-top: -10px;
				width: 300px;

				td {
					padding: 8px 0 8px 0;
					vertical-align: bottom;
				}
				td.label {
					color: @grey-dk;
				}
				td.value {
					font-weight: 500;
					text-align: right;
					padding-right: 14px;
				}

				tr.total {
					font-size: 18px;
					td {
						border-top: 1px solid @off-white-dk;
						&.value {
							color: @green;
							vertical-align: top;
						}
					}
					span.monthly {
						font-size: 11px;
						color: @grey-dk;
					}
				}
			}
		}

		input.input-cc_name {
			width: 310px - 12;
		}
		input.input-cc_num {
			width: 214px;
		}
		input.input-cc_cvv {
			width: 70px;
		}

		label.billing-terms {
			display: block;
			margin-top: 20px;
		}

		.input-cc_address_street1,
		.input-cc_address_street2,
		.input-billing_email,
		.input-billing_phone {
			width: 310px - 12;
		}
		.input-cc_address_city {
			width: 124px;
		}
		.input-cc_address_state {
			width: 48px;
		}
		.input-cc_address_pcode {
			width: 102px;
		}
		.select-cc_address_country {
			width: 310px - 6;
		}

		a.link {
			text-decoration: underline;
		}

		.plan-actions {
			.button-action {
				margin-right: 8px;
			}
		}
	}
}

