.container {
    display: flex;
    z-index: 1; /* Ensure it stays on top of the empty overlay, otherwise it becomes an unclickable button */
    width: 100%;
    height: 100%;
}

.sort-button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.sort-button svg {
    fill: var(--grey);
}

.sort-button:hover {
}

.sort-button:hover svg {
    fill: var(--grey-dk);
}

.inner-wrapper {
    height: 120px;
    padding: 10px 20px;
    user-select: none;
}

.inner-wrapper [role='radiogroup'] {
    padding-top: 8px;
}

.submit-button {
    position: absolute;
    right: 5px;
    bottom: 10px;
}
