.search-results {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.create-button {
    border-bottom: 1px solid var(--off-white-dk);
}

.create-button,
.result {
    width: 100%;
    box-sizing: border-box;
}

.create-button > button {
    padding: 8px 16px;
    text-align: left;
}

.result:hover,
.result:focus,
.result:active,
.create-button:hover,
.create-button:focus,
.create-button:active {
    background-color: var(--blue-lt);
}
