.icon-button {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    height: 100%;
    color: var(--grey);
}

.icon-button--small {
    margin: 8px 0;
    padding: 8px;
}

/* Styles for button background hover state */
.icon-button:hover {
    background-color: var(--off-white);
    color: var(--grey-dk);
}

.icon-button.no-hover-bg:hover {
    background-color: transparent;
}

/* Styles for both types of icons */
.icon-button > svg {
    height: 17px;
    width: 21px;
    margin-right: 4px;
    fill: var(--grey);
}

/* Styles for both types of icons */
.icon-button.small > svg {
    height: 15px;
    width: 17px;
    margin-right: 4px;
    fill: var(--grey);
}

.icon-button > span:first-child {
    color: var(--grey);
    margin-right: 8px;
    font-size: 16px;
}

/* Remove margin after icon for buttons with no text */
.icon-button > svg:only-child,
.icon-button > span:first-child:only-child {
    margin-right: 0;
}

.icon-button:hover > :first-child {
    fill: var(--grey-dk);
    color: var(--grey-dk);
}

/* Button text variants */
.icon-button.danger .button-text {
    color: var(--rose);
}

.icon-button.primary .button-text {
    color: var(--blue);
}

.icon-button.primary:hover .button-text {
    color: var(--blue-dk);
}

/* Styles for disabled buttons */
.icon-button[disabled]:hover,
.disabled:hover {
    background-color: white;
    cursor: default;
}

.icon-button[disabled],
.icon-button[disabled] > :first-child,
.icon-button[disabled]:hover > :first-child,
.disabled,
.disabled > :first-child,
.disabled:hover > :first-child {
    fill: var(--grey-lt);
    color: var(--grey-lt);
}

.icon-button[disabled].danger .button-text,
.disabled.danger .button-text {
    opacity: 0.6;
}

.icon-button[disabled].primary .button-text,
.icon-button[disabled].primary:hover .button-text,
.disabled.primary .button-text,
.disabled.primary:hover .button-text {
    color: var(--blue);
    opacity: 0.6;
}

.no-padding {
    padding: 0;
}
