.container {
    background-color: white;
    border-right: 1px solid var(--off-white-dk);
    display: flex;
    flex-direction: column;
    width: var(--filter-drawer-width);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    float: left;
    padding: 16px 0 16px 16px;
}

.container--closed {
    composes: container;
    overflow-y: hidden;
}

.container--borderTop {
    border-top: 1px solid var(--off-white-dk);
}

.search {
    width: calc(100% - 18px); /* 2px of box protrudes from right */
    margin-bottom: 12px;
}
