.input {
    color: var(--grey);
    font-size: var(--font-small);
    padding: 4px 8px;
    height: 34px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: color 200ms linear;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
}

.input:disabled {
    background-color: var(--off-white);
    opacity: 0.6;
}

.input:hover {
    border-color: var(--grey-lt) !important; /* This overrides inline styles */
}

.input::-ms-clear {
    display: none;
}

.input:focus {
    border-color: var(--blue);
}

.input--active {
    composes: input;
}

.input--invalid {
    border-color: var(--rose);
}

.textarea {
    composes: input;

    padding-top: 10px;
    resize: none;
}

.textarea--active {
    composes: textarea;
    composes: input--active;
}

.actions-container {
    position: absolute;
    bottom: 10px;
    right: 0;

    display: flex;
    align-items: center;

    transform-origin: left center;
    transform: translateX(25px);
    opacity: 0;
    visibility: hidden;
    transition: all 200ms var(--custom-easing-bounce);
}

.actions-container--active {
    composes: actions-container;

    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.button-confirm {
    margin-right: 4px;
    padding: 0;
    fill: var(--green);
}

.button-confirm svg {
    pointer-events: none;
}

.button-cancel {
    padding: 0;
    /* This is a FA icon. Size is maintained by font-size for now :sponge:. */
    font-size: 24px;
}
