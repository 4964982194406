.table {
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    width: 100%;
    height: fit-content;
}

.header {
    background-color: var(--off-white);
    font-weight: 500;
}

.left-border {
    border-left: 3px solid var(--off-white-dk);
    padding-left: 16px;
}

.header td {
    padding: 8px 16px;
}

.body td,
.footer td {
    padding: 16px;
}

.header td:not(:first-child),
.body td:not(:first-child),
.footer td:not(:first-child) {
    text-align: right;
}

.body td:not(:first-child) > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.footer td {
    border-top: 1px solid var(--off-white-dk);
}

.section-header {
    background-color: var(--off-white-dk);
    padding: 0;
}

.email-marketing-description {
    padding-left: 8px;
    margin-top: -16px;
}

.email-marketing-row {
    vertical-align: top;
}

.info-pill {
    composes: container from './coupon-pill.css';
    /* Importants added because for some reason the color wasn't overriding coupon's green */
    background-color: var(--info-bg) !important;
    color: var(--info) !important;
}
