.ui-column-picker {
	#util > .clearfix();
	padding: 0 16px;
	height: ~"calc(100% - 50px)";

	.ui-column {
		float: left;
		height: 100%;
	}

	.ui-column.ui-column-headers {
		width: 40%;
	}

	.ui-column.ui-column-fields {
		width: 60%;

		.scroll-container {
			height: ~"calc(100% - 40px)";
			overflow-y: auto;
			border-left: 1px solid @off-white-dk;
		}
	}

	.ui-column-picker__search-header {
		.ui-input-ajax-search {
			border: 0px;
			border-radius: 0;
			border-bottom: 1px solid @off-white-dk;

			.ui-input { height: 40px; }
			.search-icon-container {
				font-size: 14px;
				top: 10px;
			}
			.clear-icon-container {
				top: 5px;
				right: -5px;
				padding: 10px;
			}
		}
	}

	.ui-column .ui-row {
		position: relative;
		height: 40px;
		line-height: 40px;
		padding: 0 10px;
		font-weight: 500;
		color: @grey-lt;
		background-color: @off-white;
		border-bottom: 1px solid @off-white-dk;

		&.ui-row__field {
			.ui-field-checkbox {
				float: left;
				margin-right: 10px;
				margin-top: 15px;
			}
		}
		&.ui-row__header {
			cursor: pointer;
			.ui-header-count {
				position: absolute;
				top: 9px;
				right: 40px;
				padding: 0 10px;
				text-align: center;
				background-color: @grey-lt;
				color: white;
				font-size: 12px; //looks better than 13px
				line-height: 20px;
				border-radius: 20px;
			}

			.ui-header-icon {
				color: @grey-lt;
				position: absolute;
				padding: 0;
				right: 10px;
			}
		}

		&.ui-row--selectable {
			cursor: pointer;
			&:hover {
				background-color: @color-list-hovered;
				.ui-field-name { color: @grey-dk; }
			}
		}
	}

	.ui-column .ui-row.ui-row--selected {
		&.ui-row__field {
			background-color: @color-list-selected;
			.ui-field-name { color: @grey-dk; }
		}

		&.ui-row__header {
			background-color: @color-list-selected;
			cursor: default;
			.ui-header-name { color: @grey-dk; }
			.ui-header-icon > .react-icon { color: @grey-dk; }
		}
	}
}
