.help-box-visible {
	font-size: @small;
	color: @grey-lt;
	background-color: @yellow-lt;
	border: 2px solid @off-white-dk;
	border-radius: 4px;
	margin: 20px 0;
	padding: 15px;

	h2.title {
		font-size: @normal;
		color: @grey;
		margin-bottom: 5px;
	}
	.help-box-hide {
		float: right;
	}
	a {
		color: @blue;
		&:hover {
			color: @blue-dk;
			text-transform: underline;
		}
	}
	.highlight-shortcode {
		letter-spacing: 1px;
		background: @peach;
		border-radius: 3px;
		color: @orange-dk;
		padding: 1px 2px 2px 2px;
	}
}

.help-box-collapsed {
	float: right;
}
