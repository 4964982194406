.account-buttons {
    width: 350px;
}

.account-buttons button {
    width: 100%;
}

.messages {
    margin-right: 16px;
}
