.container {
    padding: 4px 0;
    height: 200px;
}

.table-header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    box-sizing: border-box;
    height: 25px;
    color: var(--grey-dk);
    font-size: var(--font-small);
}

.product-column {
    display: flex;
    flex-grow: 1;
}

.qty-column,
.price-column {
    padding: 0 2px;
}

.qty-column {
    width: 40px;
    text-align: right;
}

.price-column {
    width: 80px;
    text-align: right;
}

.loading {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 25px);
}

.product-maps-container {
    height: calc(100% - 70px);
}

.product-maps {
    max-height: 100%;
    overflow-y: auto;
}

.total {
    text-align: right;
    width: 100%;
    font-weight: 500;
    color: var(--grey-dk);
    line-height: 32px;
    padding: 0 4px;
    box-sizing: border-box;
}

.buttons {
    text-align: right;
}

.buttons > button:first-child {
    margin-right: 4px;
}
