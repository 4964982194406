.navigation-tab {
    color: var(--grey-lt);
    font-size: var(--font-normal);
    padding: 8px;
    border-bottom: 3px solid transparent;
    user-select: none;
}

.navigation-tab:not(:last-child) {
    margin-right: 8px;
}

.navigation-tab:hover:not(.navigation-tab--active) {
    border-color: var(--off-white-dk);
    color: var(--grey-lt);
}

.navigation-tab--active {
    composes: navigation-tab;
    color: var(--grey-dk);
    border-color: var(--blue);
}

.subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
}
