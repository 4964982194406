.container {
    min-width: 0;
}

.container:hover a,
.container a:focus {
    text-decoration-line: underline;
}

.container-users,
.container-teams {
    composes: container;
    color: var(--color-text-users);
}

.container-contacts {
    composes: container;
    color: var(--color-text-contacts);
}

.container-accounts {
    composes: container;
    color: var(--color-text-accounts);
}

.container-leads {
    composes: container;
    color: var(--grey-dk);
}

.container-leads--green {
    composes: container;
    color: var(--lead);
}

.container-leads > .lead-link {
    display: flex;
    align-items: center;
    float: left;
    max-width: 100%;
}

.container-leads svg {
    margin-top: -2px;
    margin-left: 2px;
}

.container-default {
    composes: container;
    color: var(--grey-dk);
}

.nameless {
    font-style: italic;
}

.icon-image {
    width: 1em;
    height: 1em;
    margin-left: 8px;
    margin-bottom: 2px;
}

.container.hot:hover a,
.container.hot a:focus {
    text-decoration-color: var(--color-text-hot);
}

.lead-name--hot {
    color: var(--color-text-hot);
}

.hot svg {
    flex-shrink: 0;
    height: 1em;
    width: 1em;
    margin-right: 4px;
}

.truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
