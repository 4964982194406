.user-assignment,
.task,
.goal,
.email {
}

.label {
    text-align: center;
    width: 100%;
    font-size: 17px;
    line-height: 16px; /* Keeps overall height to 50px, same as loading card */
    color: var(--grey-lt);
}

.user-assignment:hover .label::after,
.user-assignment button:focus .label::after {
    content: ' Add user assignment';
    font-size: var(--font-normal);
}

.account-assignment:hover .label::after,
.account-assignment button:focus .label::after {
    content: ' Change company type';
    font-size: var(--font-normal);
}

.task:hover .label::after,
.task button:focus .label::after {
    content: ' Add task';
    font-size: var(--font-normal);
}

.goal:hover .label::after,
.goal button:focus .label::after {
    content: ' Add goal';
    font-size: var(--font-normal);
}

.email:hover .label::after,
.email button:focus .label::after {
    content: ' Add email';
    font-size: var(--font-normal);
}

.slack:hover .label::after,
.slack button:focus .label::after {
    content: ' Add Slack message';
    font-size: var(--font-normal);
}
