.container {
    height: 32px;
    display: inline-block;
    border-radius: 8px;
    border: 1px solid var(--off-white-dk);
    position: relative;
    cursor: pointer;
}

.container--disabled {
    composes: container;
    color: var(--off-white-dk) !important;
    cursor: default;
    pointer-events: none;
}

.container--round {
    composes: container;
    border-radius: 16px;
    height: 20px;
}

.container--small {
    composes: container;
    border-radius: 16px;
    height: 17px;
}

.inner-container {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 12px;
}

.label {
    padding: 0 8px;
    width: 40px;
    display: flex;
    justify-content: center;
}

.slider {
    width: 48px;
    height: 25px;
    position: absolute;
    z-index: 1;
    background-color: white;
    top: 3px;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.slider--round {
    composes: slider;
    border-radius: 12px;
}
