.scroll-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    height: 100%;
    position: absolute;
    top: 0;
}

.analytics-report {
    position: relative;
    width: 100%;
}

.top {
    display: flex;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
}

/* Position info on top of report, in line with the report key */
.info {
    display: flex;
    font-style: italic;
}

.period-picker {
    padding-left: 16px;
}
