.peep-related-lead-stat {
    display: flex;
    align-items: center;
}

.value {
    margin-right: 16px;
    font-size: var(--font-big);
}

.count {
    margin-right: 4px;
    color: var(--grey-lt);
    font-size: var(--font-small);
}

.status {
    color: var(--grey-lt);
    font-size: var(--font-small);
}
