@value columnWidth: 300px;
@value columnHeaderHeight: 40px;

.container {
    display: table-cell;
    vertical-align: top;
    width: columnWidth;
    min-width: columnWidth;
    max-width: columnWidth;
    height: 100%;
    padding: 0 1px 0 4px;
    box-sizing: border-box;
}
