.form-container {
    height: 70px;
    padding-bottom: 8px;
}

.timestamp > *:last-child {
    display: inline;
}

.info {
    font-size: var(--font-normal);
    padding-bottom: 16px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
}
