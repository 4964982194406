.container {
    margin: 16px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    height: 100%;
}

.tabs {
    width: 400px;
}

.content-container {
    padding: 16px;
}

.filter {
    width: 300px;
    padding-bottom: 16px;
}
