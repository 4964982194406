.trial-onboarding-bar {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: -45px;
    width: 100%;
    height: 45px;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid var(--off-white-dk);
}

.trial-onboarding-bar--warning {
    composes: trial-onboarding-bar;
}

.trial-onboarding-bar--warning .info > :first-child {
    color: var(--rose-dk);
}

.info {
    display: flex;
    align-items: center;
    margin-right: 16px;
    gap: 8px;
}

.contact-button {
    margin: 0 12px;
}

.upgrade-link a {
    line-height: 19px;
}
