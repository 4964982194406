.container {
    padding: 24px 24px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.pill {
    position: absolute;
    top: -16px;
}

.switch {
    height: 33px;
    width: 110px;
}
