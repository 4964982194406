.container {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.done {
    font-size: var(--font-small);
    line-height: 17px;
    color: var(--onboarding-help);
    padding-top: 4px;
}

.party {
    margin-right: 8px;
    font-size: 18px;
}

.arrow {
    display: flex;
    justify-content: flex-end;
    width: 15px;
    margin-left: 8px;
    padding: 3px;
}

.arrow:hover {
    background-color: var(--off-white);
}
