.container {
    display: flex;
}

.container > * {
    flex: 1;
}

.container > *:first-child {
    margin-right: 16px;
}
