.lead-two-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-small);
    padding: 12px;
    box-sizing: border-box;
}

.less-padding {
    padding: 4px;
}

.is-small {
    padding: 8px 12px;
}

.lead-two-line--with-number {
    composes: lead-two-line;
}

/* Need to make room for lead number */
.lead-two-line--with-number .lead-info {
    height: 36px;
}

.lead-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 4px;
    overflow: hidden;
}

.lead-info--two-rows {
    composes: lead-info;
    height: 32px;
}

.lead-info--two-rows-small {
    composes: lead-info;
    height: 32px !important;
}

.top-row {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    color: var(--grey-dk);
}

.top-row > *:first-child {
    margin-right: 4px;
}

.top-row:is(:only-child) {
    align-items: center;
}

.top-row > a {
    min-width: 0;
    margin-right: 8px;
}

.top-row > a > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    font-size: var(--font-normal, 15px);
}

.bottom-row {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    justify-content: space-between;
}

.overdue-duration {
    color: var(--color-overdue);
}

.associated {
    color: var(--grey);
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
}
