@import "include";
@import "_internal.less";

.header {
  	margin-bottom: 20px;
	h3 { margin-bottom: 4px; }
}

.ui-support-controller__quick-links {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 16px;
}

.ui-support-controller__quick-links--section {
    display: inline-flex;
    align-items: center;
	border: 1px solid @off-white-dk;
    border-radius: 5px;
    padding: 8px 16px;
    margin-right: 16px;
	margin-bottom: 8px;
	box-sizing: border-box;

	label, input {
		margin-right: 4px;
	}
}

.ui-support-controller__user {
	display: flex;
	flex-wrap: wrap;
}

.ui-support-controller--section {
	width: ~"calc(50% - 8px)";
	padding: 16px;
	box-sizing: border-box;
	border: 1px solid @off-white;
	border-top: 2px solid @orange;
	border-radius: 3px;
	margin-bottom: 16px;
	box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);

	&.full-section {
		width: 100%;
	}

	&:nth-child(2n - 1) {
		margin-right: 16px;
	}
}

.ui-support-controller--section-header {
	margin-bottom: 16px;
	font-size: 17px;
	color: @grey-dk;
}

.ui-support-controller--section-sub-header {
	margin-bottom: 8px;
}

.error-message {
	color: @rose;
}

table {
	.last-error {
		word-break: break-word;
	}

	.red {
		color: @rose;
	}
}
.ui-support-controller--section .section-header th:not(:first-child) {
	padding: 0 6px;
}

.review-pages {
	font-size: 15px;
	line-height: 150%;

	.review-table {
		border: 1px solid @off-white-dk;
		tr:hover {
			cursor: pointer;
			background-color: var(--blue-lt) !important;
		}
		td {
			padding: 10px 20px;
			color: @blue-dk;
		}
	}

	.fraud-score {
		border: 1px solid @off-white-dk;
		td {
			padding: 5px 15px;
		}
		.pass {
			color: @green;
		}
		.fail {
			color: @rose;
		}
	}
}

.sa-migration {
	.success {
		color: @green;
		display: none;
	}
	.error {
		color: @rose;
		display: none;
	}
}

.ui-support-controller--section .disabled-content {
	opacity: .4;
	pointer-events: none;
	user-select: none;
}

.save-pending-support-coupon {
	margin-left: 8px;
}
