.dot {
    cursor: pointer;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot--noclick {
    composes: dot;
    cursor: default;
}

.dot:not(.dot--noclick):hover svg .outer-circle,
.dot:not(.dot--noclick):focus svg .outer-circle {
    stroke: var(--blue);
}

.dot:not(.dot--noclick):hover svg .center-dot,
.dot:not(.dot--noclick):focus svg .center-dot {
    fill: var(--blue);
}
