.paginated-table-wrapper {
    display: block;
    overflow: auto;
    max-width: 100%;
    height: 100%;
}

.paginated-table-wrapper-with-header {
    height: calc(100% - 69px);
}
