.title-container {
    composes: title from './sales-automation-card.css';
    width: auto;
    max-width: 100%;
    display: flex;
    align-items: center;
}

.name {
    padding-right: 8px;
}

.description-icon {
    color: var(--grey-lt);
}

.section-icon {
    padding-right: 8px;
}

.clock-icon {
    composes: section-icon;
    color: var(--grey-lt);
    padding-left: 1px;
}

.assignee-icon {
    composes: section-icon;
}

.section {
    display: flex;
    font-size: 13px;
    line-height: 17px;
    margin-top: 8px;
    text-align: left;
}

.due-date {
    composes: section;
    align-items: baseline;
}

.assignee {
    composes: section;
    align-items: center;
}
