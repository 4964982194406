.container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.empty-image-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 52px;
    left: 0;
}

.empty-image {
    height: 120px;
}

.empty-text {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    bottom: -50px;
    font-size: var(--font-normal);
}

.empty-row {
    height: 44px;
}
.empty-row:nth-child(2n) {
    background-color: var(--zebra-stripe);
}
