.timeline-summary {
    background-color: var(--off-white);
    padding: 16px;
    color: var(--grey-lt);
    font-size: var(--font-small);
    line-height: 17px;
}

.timeline-summary svg {
    color: var(--ai-blue);
}

.timeline-summary-content {
    padding: 16px;
}

.header-title {
    font-size: var(--font-normal);
    font-weight: 500;
    line-height: 19px;
    color: var(--grey-dk);
}

.popover-content {
    width: 200px;
    padding: 8px;
}
