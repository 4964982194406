.container {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.header-text {
    font-weight: 500;
    font-size: var(--font-normal);
    color: var(--grey-dk);
}

.close-button {
    fill: var(--grey-lt);
    cursor: pointer;
}

.close-button:hover {
    fill: var(--grey-dk);
}

.card-container {
    background-color: var(--off-white);
    box-sizing: border-box;
    padding: 8px;
    width: 100%;
}

.error-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
}

.confirmation-form {
    position: relative;
    padding: 8px;
}
