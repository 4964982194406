.app-error {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: var(--off-white);
}

.app-error img {
    margin-top: 100px;
    width: 400px;
}

.message {
    font-size: 42px;
    line-height: 60px;
    margin-top: 30px;
}

.secondary {
    color: var(--grey);
    font-size: 22px;
    line-height: 50px;
}

.secondary a {
    text-decoration: underline;
    color: var(--blue);
}
