.cell {
    box-sizing: border-box;
    padding: 13px 16px;
    /*
     * This item is aligned "stretch" by the row so borders go full height if one element
     * in the row is taller than others.  So we need to center up its own contents.
     */
    display: flex;
    align-items: center;
}

.cell input[type='checkbox'] {
    margin: -1px 16px 0 8px; /* Aligns better with font kerning */
}

.action-button-cell {
    composes: cell;
    padding: 0 16px 0 0;
    width: 36px;
}

.expand-cell {
    composes: cell;
    padding: 0 16px 0 0;
    width: 92px;
}
