.container {
    background-color: var(--white);
    border-radius: 3px;
    padding: 16px;
}

.container-new-nav {
    margin-bottom: 48px;
}

.container-collapsed {
    margin-bottom: 4px;
}

.undo {
    display: flex;
    align-items: center;
    color: var(--blue);
    margin-top: 4px;
    padding: 0;
}

.undo-collapsed {
    margin-top: 0;
}

.undo:hover,
.undo:focus {
    color: var(--blue-dk);
}
