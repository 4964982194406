@import "include";


.page.territories {
	
	.builder {
		.block {
			.regions {
				margin-top: 10px;
				
				.region-picker {
					clear: both;
				}
				
				a.action-addlocation {
					clear: both;
					display: block;
				}
			}
		}
	}
}
