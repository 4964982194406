.pie-size-large {
	width: 80px;
	height: 80px;
}

.pie-size-medium-large {
	width: 60px;
	height: 60px;
}

.pie-size-mini {
	width: 20px;
	height: 20px;
}
