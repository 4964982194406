.chevron-border {
    pointer-events: none;
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    height: 100%;
    width: 11px;
    opacity: 1;
}

.before,
.after {
    pointer-events: none;
    content: ' ';
    position: absolute;
    z-index: 0;
    left: 4px;
    height: 50%;
    width: 100%;
    opacity: 1;
    transform: skew(20deg, 0deg);
}

.before {
    top: -1px;
    border-top: 1px solid;
}
.after {
    top: 50%;
    transform: skew(-20deg, 0deg);
    border-bottom: 1px solid;
}

.straight {
    border-top: 1px solid;
    border-bottom: 1px solid;
    top: -1px;
}

.left {
    composes: chevron-border;
    left: -10px;
}
.left:not(.straight) {
    background-color: transparent;
}

.left .before,
.left .after,
.left.straight {
    border-left: 1px solid;
}

.right {
    composes: chevron-border;
    right: -1px;
}

.right .before,
.right .after,
.right.straight {
    border-right: 1px solid;
}

.blue .before,
.blue .after,
.blue.straight {
    border-color: var(--blue);
}

.grey .before,
.grey .after,
.grey.straight {
    border-color: var(--off-white-dk);
}
