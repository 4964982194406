.left {
    background-color: var(--off-white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: fadeIn fadeDuration ease-in forwards;
}

.container {
    padding: 16px;
}

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.button-content {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.done-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
}

.error {
    color: var(--red);
}
