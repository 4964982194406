#myaccount .my-account-header {

	.avatar-container {
		float: left;
		margin-right: 12px;
		.avatar {
			border: none;
			background: @orange url("@{img-root}/layout/avatar-person.svg") no-repeat 50% 105%;
			background-size: 66px 66px;
		}
	}

	h1.username {
		font-size: @big;
		color: @grey-dk;
		display: inline-block;
		line-height: 21px;
	    margin: 3px 0;

		span.email {
			font-weight: normal;
			font-size: @normal;
			color: @grey-lt;
			display: block;
		}
	}

	.meta {
		float: right;
		padding-top: 16px;
		a {
			font-size: @normal;
			color: @blue;
			margin-left: 12px;
			&:hover { color: @blue-dk; }
			.icon { margin: 0 2px; }
		}
	}
}
