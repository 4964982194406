.tselect-container {
	position: relative; // Allows for absolute position of popover
	display: inline-block; // Allows button to float next to label

	// Stateful stuff
	&.state-closed {
		.tselect {
			display: none;
		}
	}

	// The actual button that opens the tier select popover
	.tselect-opener {
		font-size: @small;
		line-height: 13px;
		.tselect-value,
		.tselect-separator {
			#util > .inline-block;
		}
		.tselect-value {
			padding-right: 6px;
		}
		.tselect-separator {
			margin-left: 6px;
			border-left: 1px solid @grey-lt;
			padding-left: 6px;
		}
		.tselect-icon {
			width: 12px;
			height: 100%;
			display: inline-block;
			&:before {
				content: '\f078';
				font-size: 11px;
				font-family: FontAwesome;
				color: @grey-lt;
				position: absolute;
				top: 7px;
				right: 8px;
			}
		}
	}

	.tselect-wrapper {
		// Popover wrapper
		position: absolute;
		z-index: 5000; // Make sure the popover sits above everything else
	}

	// Give button active state when popover is open
	&.state-open {
		.tselect-opener {
			color: @grey-dk;
			background-color: @off-white-dk;
			border-radius: 3px 3px 0 0; // Remove border-radius on bottom of button when popover is open
		}
		// Adjust tselect styles when it opens above button
		.tselect-flipped-vert {
			.tselect {
				box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.1);
				border-radius: 4px 4px 4px 0;
			}
		}
	}

	&.triple-tiered {
		.tselect {
			max-width: 450px;
		} //slightly widen triple tiered select
		.tselect-tier:first-child {
			width: 25%;
		}
		.tselect-tier:nth-child(2) {
			width: 40%;
		}
		.tselect-tier:nth-child(3) {
			width: 35%;
		}
	}
}

// Popover styles
.tselect {
	text-align: left;
	border: solid 1px @off-white-dk;
	background: white;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	border-radius: 0 4px 4px 4px;
	overflow: hidden;
	max-width: 400px; // Stops really long names from making the popover stupidly wide

	.tselect-tier {
		float: left;
		position: relative;
		border-left: 1px solid @off-white-dk;
		width: 65%;
		box-sizing: border-box;
		&.first-child {
			width: 35%;
			background: @off-white;
			border-left: none;
		}
		ul {
			padding: 0;
			display: none;
			&.state-selected {
				display: block;
			}

			> li {
				border-top: 1px solid @off-white-dk;
				&:first-child {
					border-top: none;
				}
				&.hr {
					display: none;
				}
				&:hover {
					background-color: @color-list-hovered;
					a {
						color: @grey-dk;
					}
				}
				&.state-selected {
					background-color: @color-list-selected;
					background-image: none;
					a {
						color: @grey-dk;
					}
				}
				&.state-selected.state-hovered {
					background-color: @color-list-hovered;
				}
				&.state-disabled {
					a {
						color: @off-white-dk;
					}
				}
				&:not(.tselect-terminal-item) a {
					background: url('@{img-root}/tiered_select/chevron.png') 95% center no-repeat;
				}

				a {
					font-size: @normal;
					line-height: 23px;
					color: @grey;
					white-space: nowrap;
					display: block;
					padding: 3px 20px 3px 10px; // Allow for chevron icon
				}
				input[type='checkbox'] {
					float: left;
					margin: 8px;
				}
			}
		}
	}
	&.tselect-access-top-right {
		// Tweak border-radius when popover opens top right
		border-radius: 4px 0 4px 4px;
	}
}

// IE specific
.browser-ie .tselect {
	// hack to keep columns from wrapping
	.tselect-tier {
		margin-left: -1px;
	}
}
