.stages-deleted-container {
    padding: 0 16px;
    box-sizing: border-box;
}

.stage-deleted-container {
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-weight: 500;
}
