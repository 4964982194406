.create-choice-wrapper {
    display: flex;
    align-items: center;
}

/* Set margin after textfield before checkmark button */
.create-choice-wrapper > :first-child {
    margin-right: 8px;
}

/* List of choices */
.choice-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    color: var(--grey-lt);
}

/* Remove padding around the remove button */
.choice-list li > :last-child {
    padding: 0;
}

/* No margin below last list item */
.choice-list > li:last-child {
    margin-bottom: 0;
}
