.container {
    display: inline-block;
    min-width: 88px;
    max-width: 100%;
    width: 100%;
}

.container label {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
    color: var(--grey-dk);
}

.container label,
.container select {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

.container select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    padding: 3px 28px 3px 9px;
    font-size: var(--font-small);
    background: #fff;
    color: var(--grey);
    outline: none;
    border: 2px solid var(--off-white-dk);
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;
    height: 38px;
    margin-bottom: 8px;
}

.container select:hover {
    border-color: #a1a1a1; /* TODO: standardize color */
}

.select-container {
    font-weight: normal;
    position: relative;
    margin-bottom: 2px;
    width: 100%;
}

.option-title {
}

.option-subtitle {
    font-size: 12px;
    color: var(--grey-lt);
}
