.default-footer {
    /* Position above scroll shadow. */
    position: relative;
    z-index: 1;
}

.right-group-container {
    display: flex;
    align-items: center;
    /* This margin is applied in the Footer component and causes the save button to be cut off */
    margin-left: 0 !important;
}

.right-group-container > :first-child {
    margin-right: 16px;
    max-width: 200px;
}

.task-footer {
    composes: default-footer;
}

.right-btn-group {
    display: flex;
}

.right-btn-group > *:not(:last-child) {
    margin-right: 8px;
}

/* prevent disabled button from disappearing as grey on grey */
.delete-btn button[disabled] {
    color: var(--rose);
}
