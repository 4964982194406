.email-page-main {
    position: relative;
    flex: 1;
    min-height: 0;
    height: 100%;
    background-color: white;
}

.cta-banner-container {
    display: flex;
    position: relative;
    color: var(--sidebar-red);
    flex-shrink: 0;
    width: calc(100% - 64px);
    justify-content: center;
    padding: 32px;
    background-color: white;
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 736px;
    height: 442px;
}
