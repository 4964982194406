.feature-usage-box {
    display: flex;
    justify-content: center;
    height: 200px;
    min-width: 200px;
    border-radius: 3px;
    padding: 32px 32px 48px 32px;
    border: 1px solid var(--off-white-dk);
}

.feature-usage-box--next-plan {
    composes: feature-usage-box;
    border: 2px solid var(--color-upgrade);
}

.usage-bars {
    display: flex;
    padding: 0 16px;
    border-bottom: 1px solid var(--off-white-dk);
    height: 200px;
    align-items: center;
    justify-content: baseline;
}

.usage-bars > :first-child {
    margin-right: 16px;
}

.bar-container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    color: var(--grey-dk);
    font-size: var(--font-normal);
    font-weight: 500;
}

.bar {
    margin-top: 8px;
    width: 100px;
    border-radius: 3px 3px 0 0;
}

.subtext {
    position: absolute;
    bottom: -20px;
    color: var(--grey-lt);
    font-size: var(--font-small);
    margin-top: 4px;
    font-weight: normal;
}
