.left {
    background-color: var(--plum-lt);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: fadeIn fadeDuration ease-in forwards;
}

.container {
    padding: 140px 16px 0 16px;
}

.footer {
    position: absolute;
    bottom: 0;
    right: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0;
}
