.message-container {
    display: flex;
    flex-direction: column;
    /* Matches JS-based transition in FlashBanner */
    transition: margin-bottom 500ms ease-in-out;
    margin-bottom: 0;
}

.message-container:not(:empty) {
    margin-bottom: 16px;
}

.message-container--reverse {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
}
