.container {
    width: 100%;
    min-height: 36px;
    display: flex;
    cursor: pointer;
}

/* Styled to match the SelectInline label */
.label {
    padding: 4px 0 0 16px;
    color: var(--placeholder);
    height: 26px;
    font-size: var(--font-small);
    line-height: 26px;
    min-width: fit-content; /* Allows label to display on one line */
}

.pills {
    display: flex;
    flex-wrap: wrap;
    max-height: 60px;
    overflow: auto;
    padding: 6px 8px 4px 8px; /* Match padding in To field above */
}
