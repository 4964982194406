@import 'include';

#activity-benchmarks {
	.list-activity-benchmarks,
	.list-activity-benchmarks-users {
		td {
			line-height: 23px;
			padding: 3px 15px;

			input {
				#util > .table-form > .input;
				width: 100px;
			}
			span {
				color: @grey-lt;
				padding-left: 5px;
				float: right;
			}
		}
		td.type-description {
			font-weight: 500;
		}

		td.type-secondary {
			color: @grey-lt;
		}

		tr.user-template {
			display: none;
		}

		.capsule-add-container {
			display: none;
			padding: 3px 12px;
			height: 22px;
			width: 200px;

			ul {
				width: 196px;
				margin-left: 12px;
			}
		}
	}
}
