.container {
    display: inline-flex;
    position: absolute;
}

.shown {
    display: inline-flex;
}

.hidden {
    position: relative;
    top: 1px; /* This aligns the +[number] with rest of text */
}

.hidden:hover {
    /* Removes the text cursor that appears on hover */
    cursor: default;
}
