.timeline3-list,
.modal-entity-activity {
	@avatar-size: 26px;
	@initial-size: 16px;
	@container-margins: -1px 0 12px 0;
	@line-height: 18px;
	@textarea-padding: 5px 10px;
	.comment-css();

	.timeline-comment {
		.right-side {
			padding-left: 0;
			.content {
				border-left: none;
			}
		}
	}
}

.timeline3-list {
	.comments {
		margin-left: 0;
		.timeline-comment {
			border-top: 1px solid @off-white-dk;
			border-left: none;
			border-right: none;
		}
		&.state-focused {
			.timeline-comment.variant-placeholder {
				border-left: none;
				border-right: none;
			}
		}
	}
}

.modal-entity-activity .activity-comments-list {
	clear: both;
	margin-top: 10px;
}

.timeline3-list.variant-full {
	@avatar-size: 34px;
	@initial-size: 18px;
	@container-margins: -15px 0 24px 0;
	@line-height: 18px;
	@textarea-padding: 8px 10px 8px 10px;
	.comment-css();
}

@comment-border-color: @off-white-dk;

.comment-css() {
	.comments {
		position: relative;
		margin: @container-margins;

		.line {
			position: absolute;
			left: 48px;
			width: 2px;
			background-color: @comment-border-color;

			&.comments {
				bottom: -24px;
			}
		}

		&.state-focused {
			.timeline-comment.variant-placeholder {
				border: 1px solid @blue;
			}
		}
	}

	.timeline-comment {
		background-color: white;
		position: relative;
		box-sizing: border-box;
		border: 1px solid @comment-border-color;
		border-bottom: none;

		.left-side {
			position: absolute;
			box-sizing: border-box;
			height: @avatar-size + 2px;
			width: @avatar-size + 2px;

			.avatar {
				display: inline-block;
				width: @avatar-size;
				height: @avatar-size;

				.initials {
					line-height: @avatar-size;
					font-size: @initial-size;
				}
				img {
					border-radius: 0;
					width: @avatar-size;
					height: @avatar-size;
				}
			}
		}
		.right-side {
			min-height: @avatar-size;
			padding-left: @avatar-size;
			box-sizing: border-box;
			background-color: @off-white-bg;

			.content {
				line-height: @line-height;
				min-height: @line-height;
				padding: @textarea-padding;
				position: relative;
				border-left: 1px solid @comment-border-color;
				background-color: white;

				//allows space for timestamp + action-delete button
				.body {
					padding-right: 100px;
				}
				.actorname {
					color: @orange-dk;
					margin-right: 5px;
				}
				.action-delete {
					color: @grey;
					top: 1px;
					&:hover {
						text-decoration: none;
						color: @rose;
					}
				}
				a {
					color: @blue;
					&:hover {
						color: @blue-dk;
						text-decoration: underline;
					}
				}
				span.mention-rendered {
					a {
						color: @grey;
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
			.timestamp {
				position: absolute;
				right: 25px;
				top: 5px;
				font-size: @tiny;
				color: lighten(@grey-lt, 25%);
			}
		}

		&.variant-placeholder {
			border-bottom: 1px solid @comment-border-color;

			//padding delegated to textarea so click target is larger
			.content {
				padding: 0;
			}
			.textarea-container {
				padding: @textarea-padding;
				min-height: @line-height;
				line-height: @line-height;

				textarea {
					line-height: @line-height;
					height: @line-height;
					box-sizing: content-box; //overrides mention styling
					color: @grey-dk;
					width: 100%;
					padding: 0;
					resize: none;
					border: none;
					outline: none;
				}
			}

			.left-side .avatar {
				border-bottom: none;
			}
			.right-side {
				border-bottom: none;
			}
		}

		&.blink-success {
			.content {
				background-color: @green-lt;
			}
		}
		&.variant-list-comment {
			border-top: 1px solid @comment-border-color;
		}
	}
}
