@value column: './visual-pipeline-column.css';
@value columnHeaderHeight from column;

.container {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    /* aligns with 8px lead-card gutter */
    padding: 0 16px 0 20px;
    box-sizing: border-box;
    border: 1px solid var(--off-white-dk);
    height: columnHeaderHeight;
    font-size: var(--font-normal);
    color: var(--grey-dk);
}

.container-chevron {
    composes: container;
    height: 50px;
    cursor: pointer;
}

.container-chevron:hover {
    background-color: var(--off-white);
}

.container:first-child {
    padding-left: 16px;
    border-top-left-radius: 3px;
}

.container:last-child {
    border-top-right-radius: 3px;
}

.left-container {
    display: flex;
    flex-grow: 1;
    padding-right: 16px;
    align-items: center;
}

.chevron {
    height: 36px;
    width: 36px;
    margin-right: 8px;
}

.right-container {
}

.stage-name {
    font-weight: 500;
    line-height: 20px; /* even number helps center text */
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    /* need room for growing count & total */
    max-width: 160px;
}

.stage-count,
.stage-value {
    line-height: 20px; /* even number helps center text */
    font-size: var(--font-normal);
}

.stage-count {
    color: var(--grey);
}

.stage-value {
    color: var(--grey-dk);
}
