.ui-funnel-report {
	.ui-funnel-report__filters-container {
		.ui-funnel-report__meta {
			font-size: @normal;
			color: @grey-lt;
		}
	}
	.ui-funnel-report__funnel {
		.ui-funnel-report__funnel-sections {
			.ui-funnel-report__funnel-section {
				.ui-funnel-section__transition {
					color: @grey-dk;

					.ui-funnel-section__percent-text {
						font-weight: 500;
						font-size: 26px;
						color: @grey-dk;
					}

					.ui-funnel-number-label__primary-text {
						font-size: 19px;
						color: @grey-dk;
					}

					.ui-funnel-number-label__secondary-text {
						font-size: @big;
						color: @grey-dk;
					}

					.ui-funnel-section__arrow-label {
						font-size: @normal;
						color: @grey-dk;
						position: relative;
						z-index: 1;
						top: -2px;
					}
				}

				.ui-funnel-section__header {
					.ui-funnel-section__header__stage-name {
						font-weight: 500;
						font-size: @normal;
						color: @grey-lt;
					}

					.ui-funnel-section__header__advanced {
						font-size: 18px;
						color: @grey-lt;
					}
				}

				.ui-funnel-section__rectangle__container {
					.ui-funnel-number-label__primary-text {
						font-weight: 500;
						font-size: 24px;
						color: @grey-dk;
					}

					.ui-funnel-number-label__secondary-text {
						font-size: 18px;
					}
				}
				.ui-funnel-section__currentLeads__container {
					.ui-funnel-section__currentLeads {
						font-size: 24px;
						color: white;
					}
				}

				.ui-funnel-section__lead-movement {
					.ui-funnel-section__lead-movement-item {
						font-size: 16px;
						color: @grey;
						font-weight: 500;
					}
				}
			}
		}
	}
}

.ui-funnel-report__close__percent-text {
	font-weight: 500;
	font-size: @big;
}

.ui-funnel-report__close__text-container--empty {
	color: @off-white-dk;
	font-size: @big;
}

.ui-funnel-report__close__count-name-container {
	font-size: @normal;
}

.ui-funnel-report__empty-title-container {
	font-size: @big;
	font-weight: 500;
}

.ui-funnel-report__total-regressed__value {
	font-size: @normal;
	color: @grey;
}

// Hack for reports overhaul
// Feature flagging these styles and overriding existing
body#report-2017 {
	.ui-funnel-report {
		.ui-funnel-report__filters-container {
			.ui-funnel-report__meta {
				color: @grey;
			}
		}
	}
	.ui-funnel-report__funnel {
		.ui-funnel-section__header {
			.ui-funnel-section__header__stage-name {
				color: @grey-dk;
			}
		}
	}
	.ui-funnel-report__close__count-name-container {
		font-size: @small;
	}
}
