.inline-tab {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    color: var(--grey);
    font-size: var(--font-normal);
    background-color: #fff;
    padding: 9px 8px 8px 8px;
    border-bottom: 2px solid var(--off-white-md);
    margin-bottom: -2px;
    outline: none;
}

.inline-tab:not(:disabled):hover,
.inline-tab:not(:disabled):focus {
    color: var(--grey-dk);
    border-color: var(--button-secondary-dk);
    background-color: var(--off-white);
    cursor: pointer;
}

.inline-tab[data-selected],
.inline-tab[data-selected]:focus,
.inline-tab[data-selected]:hover {
    color: var(--grey-dk);
    font-weight: 500;
    border-color: var(--blue);
    background-color: var(--off-white);
}

/* These styles make the inline tabs look like the report tabs */
.tab-dark {
    composes: tab from '../tab.css';
    display: flex;
    justify-content: center;
    padding: 8px 12px;
}

.tab-dark--active {
    composes: tab--active from '../tab.css';
    display: flex;
    justify-content: center;
    padding: 8px 12px;
}
