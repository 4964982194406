.peep-related-lead-stats {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: flex-start;
    padding: 8px 12px 0 12px;
    margin: -8px -12px 0 -12px;
}

.peep-related-lead-stats--clickable {
    composes: peep-related-lead-stats;
    cursor: pointer;
}

.peep-related-lead-stats--clickable:hover {
    background-color: var(--off-white);
}

.peep-related-lead-stats--clickable:hover > div {
    color: var(--grey-dk);
}

.peep-related-lead-stats > div:not(.empty-state) {
    margin-bottom: 4px;
    width: 50%;
}

.empty-state {
    display: flex;
    align-items: center;
}

.empty-state svg {
    margin-right: 8px;
}
