.timeline-modal-header {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    background-color: var(--off-white);
}

.timeline-modal-header > :last-child {
    position: relative;
    right: -6px;
    margin-left: 8px;
}
