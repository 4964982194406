@dialog-bg: rgba(64, 61, 56, 0.85);
@dialog-error-bg: rgba(250, 74, 98, .95); // semi-transparent @rose

@import 'popover/_popover-form.less';
@import 'popover/_search-result.less';

.ui-popover {
	position: fixed;
	z-index: 10001;
	background-color: white;
	border: none;
	border-radius: 3px;
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
	.popover-nub(7px, @off-white-dk, white);

	.toolbar {
		text-align: center;
		.ui-header {
			line-height: 20px;
			padding: 10px 35px;
			#util > .excerpt-crop > .ellipsis();
		}
	}

	.ui-btn__popover-close {
		position: absolute;
		top: 5px;
		right: 0;
	}

	&--type-dialog, &--type-error {
		box-sizing: border-box;
		color: white;
		min-width: 160px;
		max-width: 300px;
		padding: 15px;
		text-align:center;
		border-radius: 4px;
		box-shadow: none; //overrides old popover
		background-color: @dialog-bg;

		.buttons {
			text-align: right;
			margin-top: 10px;
			line-height: 16px;
			white-space: nowrap;
			display: block;

			.ui-btn {
				margin-right: 0;
				margin-left: 4px;
			}
		}

		.button-interaction {
			color: black;
			min-width: 45px;
			line-height: 24px;
			text-align: center;
			border-radius: 2px;
			text-shadow: none;
		}
		.action-yes {
			margin-right: 10px;
			background-color: @green-lt;
			color: @green !important;
		}
	}

	&.fit-small {
		max-width: auto;
		min-width: 0;
		width: auto;
		padding: 5px 10px;
	}

	&.ui-popover--md {
		padding: 5px 10px;
		max-width: 200px;
	}

	&--type-error {
		color: white;
		background-color: @dialog-error-bg;
		.popover-nub(7px, @dialog-error-bg, transparent);
	}

	&--type-dialog {
		color: white;
		background-color: @dialog-bg;
		.popover-nub(7px, @dialog-bg, transparent);
	}

	&.has-error-banner {
	  .popover-nub(7px, @dialog-error-bg, transparent);
	}

	&.ui-popover--date-range {
		.popover-nub(7px, @grey-dk, transparent);

		//centers popover and nub in the middle of button
		margin-left: -8px;

		// ensure 3px border-radius shows and isn't hidden by inner element background colors
		overflow: hidden;
		&.location-bottom-left:before,
		&.location-bottom-left:after {
			right: 120px;
		}
	}

	&.ui-popover--list {
		width: 400px;
		padding: 6px 10px;

		h1 {
			text-align: center;
			margin: 10px 0;
		}

		.ui-popover__scrollable-list__section-header {
			display: inline-block;
			color: @grey-dk;
			font-weight: 500;
			margin: 0 0 10px 0;
			background-color: @yellow-lt;
			padding: 0 3px;
		}

		.ui-popover__scrollable-list {
			height: 450px;
			overflow-y: scroll;

			ul { margin-bottom: 15px; }
			li {
				color: @grey-dk;
				list-style: none outside none;
				border: none;
				.2x('@{img-root}/entity-lists/bullet-orange.png', "no-repeat", 18px, 18px);
				margin: 6px 0 8px 0;
				padding-left: 20px;
			}
		}
	}

	&.ui-popover--chopped-filters {
		margin-top: 9px;
		max-height: 200px;
		overflow-y: auto;

		li {
			list-style-type: none;
			input[type=radio] {
				display: none;
			}
		}

		.ui-canned-filter__type,
		.ui-canned-filter__count {
			display: block;
			text-align: left;
		}

		.ui-canned-filter__type {
			#util > .excerpt-crop > .ellipsis();
			font-size: 13px;
			color: @grey;
			line-height: 15px;
			max-width: 130px;
		}

		.ui-canned-filter__count {
			color: @grey-lt;
			display: block;
			text-align: left;
			opacity: 0.8;
			font-size: 11px;
			line-height: 14px;
		}

		label {
			display: block;
			box-sizing: border-box;
			padding: 5px 10px;
			width: 150px;
			cursor: pointer;
			border-bottom: 1px solid @off-white-md;

			&:hover { background-color: @peach-lt; }
		}
	}
}
