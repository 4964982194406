.disabled-until-tomorrow-container {
    display: flex;
    padding: 2px 0;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
    background: var(--sidebar-secondary);
    width: 179px;
    margin-left: 20px;
}
