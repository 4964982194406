.lead-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: column;
}

.lead-buttons > div {
    flex-grow: 1;
    justify-content: flex-end;
}

.outcome-text {
    margin-top: 8px;
    font-size: var(--font-small);
}

.delete {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.button {
    composes: delete;
    color: var(--grey);
}

.button:hover {
    color: var(--grey-dk);
}

.lead-buttons button:last-child {
    margin-right: -12px;
}

.logo {
    width: 24px;
    max-height: 24px;
    display: flex !important;
    justify-content: center;
}

.logo > svg {
    width: 24px;
    max-height: 24px;
}
