.cancel-container {
    height: 600px;
    display: flex;
    flex-direction: column;
}

.options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 16px 0;
}

.options-container > * {
    margin: 8px 0;
}
