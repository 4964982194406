@value gap from '../../peep-details-section.css';

.two-column-fields {
    display: flex;
    gap: gap;
}

.two-column-fields > * {
    flex: calc(50% - gap / 2);
}
