.empty-circle {
    width: 16px;
    flex-shrink: 0;
}

.empty-circle svg {
    stroke: var(--grey-dk);
}

.removable-empty-circle {
    composes: empty-circle;
    position: relative;
}

/* Change the z-index on hover so the remove icon is above the active dot */
.removable-empty-circle:hover {
    z-index: 1;
    cursor: pointer;
}
