:global(.recurly-element-card) {
    background-color: #fff;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    box-sizing: border-box;
    padding: 4px 8px;
    height: 54px;
    width: 100%;
}

:global(.recurly-element-card):hover {
    border-color: var(--grey-lt);
}

.card-element-focused :global(.recurly-element-card) {
    border-color: var(--blue);
}

.hidden {
    display: none;
}

.credit-card-fields {
    display: none;
}
.payment-form-credit-card .credit-card-fields {
    display: block;
}

.bank-form-fields {
    display: none;
}
.payment-form-bank .bank-form-fields {
    display: block;
}

.us-customers-warning {
    padding: 8px 10px;
    margin-bottom: 5px;
    background-color: var(--blue-lt);
    border-radius: 3px;
    color: black;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.hidden-field {
    position: absolute;
    opacity: 0;
    height: 0;
    pointer-events: none;
}
