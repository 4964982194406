@import 'include.less';

#security {
	.page-flow-area {
		border-top: none;
		border-left: none;
        border-right: none;
	}
}

#security .page-flow-area .form-standard {
	dt {
		width: 180px;
	}
	dd {
		margin-left: 200px;
		input[type='text'] {
			width: 300px;
			padding-left: 5px;
		}
	}

}

#security .disabled {
	pointer-events: none;
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}