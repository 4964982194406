.row {
    display: flex;
    align-items: center;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
}

.left-section {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.row-type {
    margin-top: 4px;
}

.row-name {
    font-size: var(--font-normal);
}

.row-name-clickable {
    color: var(--blue);
    cursor: pointer;
}

.row-name-clickable:hover {
    text-decoration: underline;
    color: var(--blue-dk);
}

.right-section {
    width: 50%;
    justify-content: flex-end;
    gap: 48px;
    display: flex;
    align-items: center;
}

.icon-and-number {
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.icon {
    margin-top: 3px;
}

.description-icon {
    margin-left: 6px;
}

.popover-content {
    display: flex;
    flex-direction: column;
}

/* Need to override IconButton styles */
.popover-content button[class*='icon-button'] {
    margin: 0;
    width: 180px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.popover-content button[class*='icon-button'] > :first-child {
    margin-right: 8px;
    display: flex;
    justify-content: center;
}

.popover-content button[class*='icon-button']:hover {
    background-color: var(--blue-lt);
}

.popover-content button[class*='icon-button']:hover span {
    color: var(--grey-dk);
}
