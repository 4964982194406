/* @flow */

.cell {
    padding: 2px 8px;
}

.cell--hidden {
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding-left: 4px; /* override cell padding of 8px to align blur with text in other rows */
}

.cell--hidden * {
    user-select: none;
    padding-left: 4px; /* align blur with text in other rows */
}

.cell--hidden-blue {
    padding-left: 4px; /* override cell padding of 8px to align blur with text in other rows */
}

.cell--hidden-blue * {
    color: transparent;
    text-shadow: 0 0 5px var(--blue);
    user-select: none;
}

.cell--hidden-blue > * > a {
    padding-left: 4px; /* align blur with text in other rows */
}

.cell--hidden-blue *:hover {
    color: transparent;
    text-shadow: 0 0 5px var(--blue);
    cursor: unset;
}

.cell-content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cell-content--user {
    color: var(--orange-dk);
}

.loading-bar {
    position: absolute;
    top: 15px;
    height: 12px;
    width: calc(100% - 15px);
    background-color: var(--off-white-dk);
    margin-bottom: 3px;
}
