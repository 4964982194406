.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.icon-container {
    display: flex;
    padding: 16px;
    cursor: pointer;
    flex-shrink: 0;
}

.icon-container:hover {
    background-color: var(--color-list-hovered);
}

.icon {
    margin-right: 16px;
}

.content-container {
    display: flex;
    flex-direction: column;
    flex: 1; /* for IE11 */
}

.name {
    display: flex;
    font-size: 17px;
    font-weight: 500;
    color: var(--grey-dk);
    margin-bottom: 8px;
}

.description {
    font-size: 13px;
    color: var(--grey);
}

.new-container {
    position: relative;
    top: -2px; /* Vertically center the 'new' badge */
}
