.meeting-page-button-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.popover-content-container {
    max-width: 600px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
}

.popover-content-text {
    font-size: var(--font-normal);
    line-height: 19px;
}
