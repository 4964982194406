.list-container {
    width: 330px;
    flex-shrink: 0;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    margin-right: 32px;
}

.header {
    margin-left: 16px;
    margin-top: 16px;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

.header-text {
    display: flex;
    justify-content: space-between;
}

.sort-button-container {
    margin-left: 8px;
    width: 24px;
    height: 20px;
}
