.container {
    padding: 0 16px;
    height: 100%;
}

.empty-state {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: var(--border-hairline);
    border-left: var(--border-hairline);
    border-right: var(--border-hairline);
}
