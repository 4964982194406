.button {
    display: flex;
}

.button:focus,
.button:active {
    outline: 2px solid var(--focus-blue);
}

.button:hover {
    cursor: pointer;
}
