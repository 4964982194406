.single-input-modal {
    display: flex;
    margin: 8px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.labels {
    height: 64px;
    display: flex;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: space-around;
}

.buttons {
    margin-top: 27px;
    display: flex;
    align-self: flex-end;
}

.buttons > button {
    margin-left: 16px;
}

.modal-container {
    background-color: var(--white);
    padding: 12px;
}
