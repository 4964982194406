.left-column {
    min-width: 400px;
    max-width: 800px;
}

.right-column {
    margin-left: 16px;
    min-width: 300px;
}

.icon-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 300px;
}

.bullets-wrapper {
    max-width: 450px;
}
