.email-preview-header {
    display: flex;
    width: 100%;
    margin: 32px 0;
    position: absolute;
    top: 0;
    left: 32px;
}

.header-right {
    margin-left: 20px;
}

.badge {
    margin: -2px 0 0 10px;
}
