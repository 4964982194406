#dashboard,
#user {
	.timeline3-list {
	    display: flex;
	    flex-direction: column;
	    flex: 1 1 auto;
	    min-height: 0;
	    overflow-y: auto;

		.loading-container { padding-bottom: 30px; }
		.entries {
			height: auto;
			flex-shrink: 0;
		}
		.react-icon { margin: 0 auto; }
	}

	.timeline3-list.timeline3-list--responsive {
		.timeline3-ribbon {
			margin-left: 25px; //accounts for left ribbon
			margin-right: 20px; //accounts for right ribbon
			width: ~"calc(100% - 45px)";
			.line {
				height: 13px;
			}
		}
		.timeline3-entry {
			.line {
				left: 25px;
			}
		}

		//.timeline3-entry, .comments { display: none !important; }
	}
}

#user {
    .timeline3-list.timeline3-list--responsive {
        // one-off, no idea why, allows to scroll
        height: 100%;
    }
}
