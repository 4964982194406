.title {
    margin-top: 16px;
}

.main-content {
    display: flex;
    flex-grow: 1;
    height: 100%;
    border-top: 1px solid var(--off-white-dk);
    margin-top: 8px;
    overflow-y: auto;
}

.main-content > * {
    overflow-y: auto;
    overflow-x: hidden;
}

.main-content > :first-child {
    margin-right: 32px;
    width: 40%;
}
