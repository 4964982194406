.actionable-header {
    padding: 10px 16px 0 16px; /* The weird 10px is to fix some visual padding */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.actionable-header--no-padding {
    composes: actionable-header;
    padding: 0;
}

.header-group {
    display: flex;
    align-items: center;
}

.header-group > * {
    margin-right: 16px;
}

.edit-container {
    display: flex;
}

.name--archived {
    font-size: var(--font-big);
    color: var(--grey-lt);
}

.name:hover {
    text-decoration: underline;
    color: var(--blue-dk);
    cursor: pointer;
}

.divider {
    height: 20px;
    width: 1px;
    background-color: var(--off-white-dk);
}

.second {
    margin-right: 0;
}

.metadata {
    font-size: var(--font-small);
    color: var(--grey-lt);
    margin-right: 0;
}

.small-spacer {
    margin-right: 8px;
    margin-left: 8px;
}

.action-menu {
    margin-right: -16px;
}

.header-group > button {
    padding-right: 0;
}
