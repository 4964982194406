.sources-section-content {
    padding: 4px;
}

.sources-section-content--no-channel {
    padding: 0;
}

.sources-container {
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px 8px;
}

.sources-container--no-channel {
    composes: sources-container;
    margin-bottom: 0;
}
