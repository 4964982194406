.editable-label {
    color: var(--grey-dk);
    cursor: pointer;
    transition: color linear 100ms;
    margin-left: -4px;
    margin-right: 4px;
    padding: 4px;
    text-align: left; /* Overrides default button styling of `text-align: center;` */
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.editable-label-container {
    display: flex;
    /* The height of the text, plus the padding */
    height: calc(1em + 8px);
}

.editable-label:hover,
.editable-label:focus {
    box-sizing: border-box;
    outline: 1px solid var(--off-white-dk);
}

.editable-label--failure {
    color: var(--rose);
}

.editable-label--saving {
    color: var(--grey-lt);
}

.editable-label-input {
    composes: editable-label;
    outline: none;
    border: none;
    transition: color linear 100ms;
    background-color: unset;
    font-weight: inherit;
    line-height: inherit;
}

.editable-label-input::-webkit-input-placeholder {
    color: var(--grey-lt);
}

.editable-label--required {
    right: -7px;
}

.editable-label--required::after {
    content: '*';
    color: var(--rose);
    padding-left: 2px;
}

.editable-label--white:hover,
.editable-label--white:focus {
    background-color: white;
    outline: none;
    padding-left: 0;
    margin-left: 0;
}

.editable-label-input--white {
    composes: editable-label;
    outline: none;
    border: none;
    transition: color linear 100ms;
    background-color: white;
    font-weight: inherit;
    line-height: inherit;
    padding-left: 0; /* This ensures the input is in the same position as the label */
    margin-left: 0; /* This ensures the input is in the same position as the label */
}
