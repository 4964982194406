.direction {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: -50%;
}

.direction svg {
    width: 80%;
}

.fade {
    height: 100%;
    width: 150px;
    position: absolute;
    top: 0;
    background: linear-gradient(to right, rgba(255, 201, 3, 1), rgba(255, 201, 3, 0));
    left: 0;
}

.direction :global(#main-element) {
    animation: slideRight 300ms;
    transform: translate(210px, 67px);
}

@keyframes slideRight {
    0% {
        transform: translate(205px, 67px);
    }
    100% {
        transform: translate(210px, 67px);
    }
}
