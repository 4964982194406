.container {
    height: 100%;
    box-sizing: border-box;
    padding: 4px 8px;
    text-align: center;
    background-color: var(--off-white);
}

.loading-icon {
    fill: var(--blue);
}

.card-container > *:not(:first-child) {
    margin-top: 4px;
}

.confirmation-message-container {
    background-color: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.error-message-container {
    font-size: var(--font-normal);
    padding: 16px;
    background-color: var(--rose-lt);
}

.confirmation-message {
    margin-bottom: 8px;
    font-size: var(--font-normal);
    color: var(--grey-dk);
}

.button {
    width: 100%;
}

.error-message {
    color: var(--grey-dk);
}

.error-buttons,
.confirmation-buttons {
    display: flex;
    margin-top: 16px;
    text-align: center;
    justify-content: space-between;
}

.error-buttons > button,
.confirmation-buttons > button {
    width: 48%;
}
