@import 'include';

#quota-manager {
	.team-quota-box,
	.users-quota-box {
		margin-bottom: 20px;

		.box-header {
			.team-quota-previous,
			.team-quota-next {
				width: 22px;
				height: 24px;
				margin-top: -3px;
				float: left;
				opacity: 1;
				&:hover {
					opacity: 0.95;
				}
			}
			.team-quota-previous {
				background: url(/include/images/process-builder/back-fwd-sprite.png) left top;
				&:active {
					background: url(/include/images/process-builder/back-fwd-sprite.png) left bottom;
				}
			}
			.team-quota-next {
				margin-left: -1px;
				margin-right: 10px;
				background: url(/include/images/process-builder/back-fwd-sprite.png) right top;
				&:active {
					background: url(/include/images/process-builder/back-fwd-sprite.png) right
						bottom;
				}
			}

			.yearly-quota-container {
				width: 150px;
				span.currency {
					padding: 3px 6px;
					float: right;
				}
				input {
					#util > .table-form > .input;
					width: 110px;
				}
			}
		}
	}
	.users-quota-box {
		.box-header {
			input {
				float: left;
				margin: 3px 7px 0 0;
			}
		}
		table.quota-table {
			td {
				padding: 5px 15px !important;
				vertical-align: middle;
			}
		}
	}

	table.quota-table {
		border: 1px solid @off-white-dk;
		border-top: none;
		th,
		td {
			padding: 3px 15px !important;
			text-align: left;
			font-weight: 500;
			overflow: hidden;
			line-height: 23px;
			height: auto !important;

			input {
				#util > .table-form > .input;
			}

			a.toggle {
				display: block;
				position: relative;
				padding-left: 20px;
				margin-left: -4px;

				.icon {
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -8px;
					float: left;
					#icon > .spindown-closed;
				}
			}

			.quota-slider {
				margin: -6px 0;
				padding: 0px 20px;
				position: relative;

				.icon.quota-slider-minus,
				.icon.quota-slider-plus {
					position: absolute;
					top: 0;
					bottom: 0;
					width: 15px;
				}
				.icon.quota-slider-plus {
					right: 0;
					background: url(/include/images/process-builder/slider-plus.png) center center
						no-repeat;
				}
				.icon.quota-slider-minus {
					left: 0;
					background: url(/include/images/process-builder/slider-minus.png) center center
						no-repeat;
				}
				.quota-slider-widget-left {
					background: url(/include/images/process-builder/slider-left.png) left center
						no-repeat;
					margin-right: 4px;
				}
				.quota-slider-widget-right {
					background: url(/include/images/process-builder/slider-right.png) right center
						no-repeat;
					margin-right: -4px;
				}
				.quota-slider-widget.ui-slider {
					height: 19px;
					border: none;
					background: none;

					.ui-slider-handle {
						border: none;
						background: url(/include/images/process-builder/slider-thumb.png) center
							center no-repeat;
						height: 24px;
						margin-top: 1px;
					}
				}
			}
		}
		td {
			background: white;
		}

		// rows
		.section-header th {
			line-height: 24px;
			border-left: 1px solid @grey;
		}
		tr.column-header th {
			border: 1px solid @off-white-dk;
			border-right: none;
			&:first-child {
				border-left: none;
			}
		}
		tr:nth-child(odd) td {
			background: @off-white-dk;
		}
		tr.hover > td {
			background: @off-white;
		}
		tr:nth-child(odd).hover > td {
			background: @off-white-dk;
		}
		// end rows

		// columns
		th.column-slider,
		td.column-slider {
			width: 300px;
		}
		td.column-next {
			font-weight: normal;
		}

		td.expanded-content {
			padding: 0 !important;
		}
		tr.state-expanded {
			td {
				color: @grey-dk;
				background-color: @off-white-dk;
				a {
					color: @grey-dk;
				}
				a.toggle .icon {
					#icon > .spindown-open;
				}
			}
		}

		tr {
			.state-past,
			.state-calculated,
			.state-overridden {
				.reset {
					text-indent: 1000px;
					background: url(/include/images/icons/process-builder/reset.png) 2px center
						no-repeat;
					top: 3px;
				}
				.currency {
					cursor: text;
				}
				.icon {
					width: 16px;
					height: 16px;
					padding: 1px 0;
					text-decoration: none;
					float: right;
					position: relative;
					left: 16px;
					display: block;
					text-align: center;
					overflow: hidden;
					font-weight: normal;
					text-shadow: none !important;
					color: @grey-lt !important;
				}
				input {
					background: transparent;
					border-color: transparent;
				}
			}
			.state-past input {
				color: black;
				cursor: text;
			}
			.state-calculated input {
				background: url(/include/images/general/white_50.png);
				border-color: @grey-lt;
			}
			.state-overridden input {
				background: white;
				border-color: black;
			}
		}
	}

	h2 {
		.name {
			line-height: 40px;
		}
		.button-action {
			margin: 0 0 0 4px;
			float: right;
		}
	}
}
