.recent-submissions-container {
    display: flex;
    /* Wrap values rather than overflowing or cutting off */
    flex-wrap: wrap;
}

.recent-submissions-container--vertical {
    composes: recent-submissions-container;
    flex-direction: column;
}

.recent-submissions {
    margin-right: 4px;
    width: fit-content;
}

.recent-submissions i {
    color: var(--grey-lt);
}
