.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
}

.sticky-header {
    z-index: 100;
    width: 100%;
    flex-shrink: 0;
    min-height: 25px; /* TODO (@ianvs): change this when the height of date headers changes */
    height: 25px; /* TODO (@ianvs): change this when the height of date headers changes */
    padding: 4px 32px 4px 16px; /* HACK (@ianvs): increase right padding to account for scroll bar */
    box-sizing: border-box;
    color: var(--grey-dk);
    user-select: none;
    background-color: var(--off-white);
}

.filters {
    display: flex;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--off-white-dk);
}

.activity-lists-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    overflow: hidden; /* This stops the box-shadow from Unlogged Activities from overflowing */
}

.calendar-container {
    margin-bottom: 10px;
}

.calendar-icon-container {
    position: absolute;
    top: 10px;
    right: 8px; /* Keeps the 16px clean gutter on the right of the list */
    padding: 3px 8px;
}

.calendar-icon-container-open {
    composes: calendar-icon-container;
}

.calendar-icon-container-open .calendar-icon svg {
    fill: var(--grey);
}

.calendar-icon svg {
    fill: var(--grey-lt);
}

.load-more-container {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 30px;
    user-select: none;
}

.load-more {
    font-size: var(--font-normal);
    border-bottom: 2px solid var(--blue);
}
