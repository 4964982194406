.info-icon {
    opacity: 0.5;
}

.info-icon:hover {
    opacity: 1;
}

.view-restricted-radio {
    width: 160px;
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.session-timeout-select {
    width: 100px;
}

.sidebar-container {
    width: 200px;
}

.table-wrapper {
    min-width: 500px;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
    box-shadow: none;
}

.sso-input-container {
    width: 280px;
}

.sso-input-container > input::placeholder {
    color: var(--grey-lt) !important;
}

.sso-exempt-user-card {
    width: 160px;
}

/* SVG container is slightly too tall regardless of size, so we need to offset
it to be centered */
.sso-icon-in-button {
    margin-bottom: -3px;
}
