.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

.content {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.incomplete-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid var(--off-white-dk);
}

.titles {
    text-align: left;
}

.subtitle {
    font-size: 13px;
    line-height: 17px;
    margin-top: 4px;
    color: var(--grey-lt);
}

.arrow {
    color: var(--off-white-dk);
    font-size: 17px;
}

.container:hover .arrow {
    color: var(--grey-lt);
}
