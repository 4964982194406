.rich-text-container {
    height: 100%;
}

.rich-text-container ul {
    list-style-type: disc;
    padding-left: 1.5em;
    height: 100%;
    word-break: break-word;
}

.rich-text-container a {
    color: var(--blue);
    text-decoration: underline;
}

.rich-text-container a:hover {
    color: var(--blue-dk);
}

.normal-font {
    font-size: var(--font-normal);
    line-height: 21.3px; /* matches line height of quill editor */
    color: var(--black);
}

.small-font {
    font-size: var(--font-small);
    line-height: 21.3px; /* matches line height of quill editor */
    color: var(--black);
}

.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.placeholder-text {
    color: var(--placeholder);
}
