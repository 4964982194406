.rating-container {
    cursor: default;
    user-select: none;
}

.rating-container i[data-fill='filled']::after {
    font-style: normal;
    content: '★';
    color: var(--orange);
}

.rating-container i[data-fill='empty']::after {
    font-style: normal;
    content: '☆';
    color: var(--form-border);
}
