.template-create-form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
}

.template-create-form > *:first-child {
    margin-bottom: 16px;
}
