.legend {
    display: flex;
    align-items: center;
    margin-right: 32px;
}

.legend > *:last-child {
    margin-right: 0;
    margin-left: 4px;
}

.key-section {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.label {
    vertical-align: baseline;
    line-height: 0;
}
