.card {
    background: #fff;
    border-radius: 3px;
    overflow: hidden; /* Stops background color from bleeding into border-radius */
    height: 100%;
    box-sizing: border-box;
}

.card--bordered {
    composes: card;
    border-width: 1px;
    border-style: solid;
}

.card--shadowed {
    composes: card;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.fit-content {
    display: table;
}

.fit-height {
    height: auto;
}

.card--button {
    cursor: pointer;
}

.overflow-visible {
    overflow: visible;
}
