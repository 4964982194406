.two-factor-button {
    display: block;
    padding: 0;
    margin: 0;
    line-height: 24px;
}

.mfa-container {
    margin-top: 8px;
}

.mfa-button {
    padding: 0;
}

.separator {
    color: var(--off-white-dk);
    margin: auto 12px;
}

.mfa-buttons {
    display: flex;
}

.mfa-disable-container {
    display: flex;
    flex-direction: row;
}

.disabled-text {
    color: var(--grey-lt);
}
