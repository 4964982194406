.sync-status-container {
    margin-bottom: 24px;
}

.sync-status-container button {
    font-size: 12px;
    padding: 0;
}

.status {
    font-style: italic;
    display: flex;
}

.status > :global(.timestamp) {
    margin-left: 2px;
}
