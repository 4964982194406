.wrapper {
    position: relative;
    z-index: 21; /* Above entity list table and loading screen. */
    margin-top: -1px;
}

.animate {
    opacity: 0.5;
    transform: translateY(200px);
    animation: animate 400ms var(--custom-easing) 0s forwards;
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--off-white);
    padding: 10px;
    box-sizing: border-box;
}

.hero-container {
    min-height: 400px;
    height: 400px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    margin: 10px 10px 20px 10px;
    position: relative;
}

.hero-container-containerless {
    composes: hero-container;
    margin: 0;
    padding: 10px 12px 20px 6px;
    box-shadow: none;
}

.header {
    padding: 10px;
    font-size: var(--font-big);
    color: var(--grey-dk);
}

/* Position legend icon. */
.hero-container :global(.recharts-legend-item) svg {
    margin: -4px 4px 0 0;
}

.sticky-header-wrapper {
    /* Maintain our height so our container scroll height doesn't change
     * when we flip on sticky mode. */
    height: 53px;
}

.sticky-header {
    composes: header;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: calc(100% - 20px);
    padding-left: 20px;
    background-color: var(--off-white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    fill: var(--orange-lt);
    opacity: 0;
    animation: fade-in 2000ms var(--custom-easing) 0s forwards;
}

.flash-banner {
    border-radius: 3px 3px 0 0;
    z-index: 3;
}

.error-retry-button {
    position: absolute;
    top: 15px;
    right: 15px;
}

:global(svg.recharts-surface .recharts-bar-cursor) {
    fill: transparent;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animate {
    0% {
        opacity: 0.5;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
