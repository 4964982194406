.container--labeled {
    position: relative;
}

.container--labeled > label {
    position: absolute;
    user-select: none;
    top: 4px;
    left: 16px;
    color: var(--placeholder);
    height: 26px;
    width: 40px;
    font-size: var(--font-small);
    line-height: 26px;
}

.container--labeled > svg {
    position: absolute;
    user-select: none;
    top: 0;
    height: 100%;
    font-size: var(--font-small);
}

.untokenized {
    color: var(--grey);
    margin-bottom: 4px;
    margin-right: 6px;
    line-height: 21px;
    display: inline-block;
}

.right-contents {
    position: absolute;
    right: 8px;
    top: 4px;
}

.option {
    display: flex;
    padding: 4px;
    align-items: center;
}

.option--selected {
    composes: option;
}

.label-container {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: var(--font-small);
    color: var(--grey-dk);
}

.sublabel {
    font-size: var(--font-small);
    color: var(--grey-lt);
}
