.badge-info {
    background-color: var(--info-bg);
    color: var(--info);
}

.badge-success {
    background-color: var(--success-bg);
    color: var(--success);
}

.badge-deprecated {
    color: var(--warning);
    background-color: var(--warning-bg);
}
