.popover-container {
    width: 338px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
}

.notification-list-container {
    border-radius: 3px;
    max-height: calc(100vh - 120px);
    overflow: auto;
}
