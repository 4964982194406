.container {
    position: relative;
}

.select {
    min-height: 46px;
    margin: 22px 0 8px 0;
}

.label {
    position: absolute;
    left: 1px;
    top: 14px;

    transition: transform 250ms var(--custom-easing-bounce-short);
    /* Pass all pointer events under the label to the input. */
    pointer-events: none;

    font-size: 20px;
    color: var(--label);
}

.label--active {
    transform-origin: left top;
    transform: translateY(-30px) scale(0.8);
}

.option-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label--required {
    left: -7px;
}

.label--required:before {
    content: '*';
    color: var(--rose);
    padding-right: 2px;
}

.option-avatar {
    margin-right: 10px;
}

.option-label-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.option-label {
    height: 30px;
    padding-left: 12px;
    overflow: hidden;
    font-size: 17px;
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.empty-label {
    composes: option-label;
    color: var(--grey-lt);
    height: 46px;
    line-height: 46px;
}

.check-icon {
    fill: var(--off-white-dk);
    opacity: 0;
    transition: opacity 200ms ease;
}
