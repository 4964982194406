.container {
    width: 100%;
    box-sizing: border-box;
    height: 34px;
    background-color: #fff;
    border-bottom: 1px solid var(--off-white-dk);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
}

/* Prevents the middle section from jumping, as it's centered aligned and the
content of the num-dispalyed section is variable width */
.num-displayed,
.column-picker {
    width: 150px;
}

.column-picker {
    color: var(--blue);
    font-weight: normal;
    padding: 0;
    text-align: right;
}

.column-picker:hover {
    color: var(--blue-dk);
}
