.user-assignment {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    margin-top: 20%;
}

.user-assignment svg {
    width: 70%;
}

.user-assignment :global(.slide-up) {
    animation: slideUp 500ms;
}

.user-assignment :global(.shadow) {
    animation: slideDown 500ms;
}

@keyframes slideDown {
    0% {
        transform: translate(3px, -5px);
    }
    30% {
        transform: translate(3px, -5px);
    } /* Give a little time for the modal to fade in */
    100% {
        transform: translate(0, 0);
    }
}

@keyframes slideUp {
    0% {
        transform: translate(16px, 10px);
    }
    30% {
        transform: translate(16px, 10px);
    } /* Give a little time for the modal to fade in */
    100% {
        transform: translate(16px, 0);
    }
}
