.container {
    composes: container from '../home.css';
}

.title {
    margin-top: 12px;
}

.scroll-container {
    overflow: auto;
    height: calc(100% - 67px); /* full height minus the footer */
}

.footer {
    /* Required so it renders above add-on slider popover */
    z-index: 10;
    padding: 8px 16px;
    border-top: 1px solid var(--off-white-dk);
    background-color: var(--white);
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: calc(100% - 200px); /* full width minus the sidebar */
    height: 67px;
}

.longer-width {
    width: calc(100% - 64px); /* full width minus the collapsed sidebar */
}

@media screen and (max-width: 1325px) {
    .info {
        display: none;
    }
}

.breadcrumbs-container {
    min-width: 300px;
    max-width: 100%;
    width: 65%;
    overflow-x: auto;
    white-space: nowrap;
    flex-shrink: 1;
}
