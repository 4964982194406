.illustration {
    min-width: 250px;
}

.title {
    font-size: 21px;
    font-weight: 500;
    line-height: 29px;
}

.container {
    max-width: 800px;
    margin: 0 auto;
}
