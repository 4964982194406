.calendar-page {
    max-width: 685px;
}

.calendar-page > * + div {
    width: 100%;
}

.divider {
    margin: 64px 0;
}

.ics-links {
    padding: 16px 0;
}

.ics-links > div:first-of-type {
    margin-bottom: 24px;
}
