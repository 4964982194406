.header {
    padding: 16px;
    background-color: #242854;
    border-radius: 2px 2px 0 0;
}

.popover-container {
    width: 300px;
}

.menu-items {
    overflow: auto;
    max-height: calc(100vh - 120px);
}

.menu-items:focus-visible {
    outline: none;
}

.menu-item-button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.menu-item-button:hover,
.menu-item-button:focus,
.menu-item-button:focus-visible {
    background-color: var(--color-list-hovered);
}
