body[data-twitter-connected] {
	.twitter-connect {
		display: none;
	}
}
body:not([data-twitter-connected]) {
	.twitter-disconnect {
		display: none;
	}

	.eip-field {
		&.type-twitter .twitter-connect {
			display: block;
			font-size: @tiny;
			color: @blue;
			margin-left: 4px;
			&:hover {
				text-decoration: underline;
				color: @blue-dk;
			}
		}
		.twitter-connect {
			display: none;
		}
	}
}
