.ui-entity-list--leads {
	.table-header-entity-colors(@green, white);
	.table-row-loading-entity-colors(@green, @plum-lt, @blue-bg);
}

.ui-entity-list--contacts {
	.table-header-entity-colors(@navy, white);
	.table-row-loading-entity-colors(@navy, @plum-lt, @off-white-md);
}

.ui-entity-list--accounts {
	.table-header-entity-colors(@plum, white);
	.table-row-loading-entity-colors(@plum, @blue-bg, @off-white-md);
}

.table-header-entity-colors(@primary, @primary-bg) {
	.public_fixedDataTable_header,
	.public_fixedDataTable_header .public_fixedDataTableCell_main {
		background-color: @primary-bg;

		.ui-entity-list__column-header {
			&:hover {
				background-color: darken(@primary-bg, 2%);
			}

			.ui-column-header__drop-target-indicator {
				background-color: @primary;
			}
		}
	}

	.ui-entity-list__table-wrapper,
	.ui-entity-list__map-wrapper {
		.ui-entity-list__loading-state {
			svg path,
			svg rect {
				fill: @primary;
			}
		}
	}
}

.table-row-loading-entity-colors(@avatar, @primary, @related) {
	.ui-entity-list-row.ui-entity-list-row--name {
		.ui-entity-list-row--name--loading__avatar {
			background-color: @avatar;
		}
		.ui-entity-list-row--name--loading__bar-top {
			background-color: @primary;
		}
		.ui-entity-list-row--name--loading__bar-bottom {
			background-color: @related;
		}
	}
}
