/* ============================ */
/* = Help box hero wizard = */
/* ============================ */
// help box containers positioned at the top of every parent page and occassionally in the sidebar (info/help wizard)
// Identified by blue horizontal-ruled paper background and red "margin lines" on the left side
.help-box-container {
	border: 1px solid @off-white-dk;
	#util > .rounded-corners;
	margin-bottom: 16px;
	position: relative;
	background-color: white;
	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);

	// Specifically for help box on reports parent page
	&.welcome-reports {
		margin: 16px;
	}

	.help-box {
		position: relative;
		z-index: 1;
		overflow: hidden;
		padding: 25px 40px;

		// These are the paper vertical margin and horizonal rules
		&:after {
			content: '';
			position: absolute;
			display: block;
			z-index: -1;
		}
		&:after {
			top: 0;
			left: 14px;
			height: 36px;
			width: 20px;
			background: url('@{img-root}/layout/help-bookmark.svg');
		}
	}

	&.variant-warning {
		border: 1px solid @rose-dk;
		background-color: @rose-lt;

		h4 {
			color: @rose-dk;
		}
	}

	&.variant-grey {
		border: 1px solid @off-white-dk;
		background-color: @off-white;
	}

	.feature-img-wrap {
		text-align: center;
	}

	// a
	.feature-image {
		display: inline-block;
		margin: 10px 10px 0px;

		img {
			background-color: white;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
			border: 1px solid @off-white-dk;
			#util > .rounded-corners;
			padding: 1px;
		}

		&:hover {
			img {
				opacity: 0.85;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
			}
		}
	}

	p,
	li {
		color: @grey;
		font-size: @small;
	}
	p {
		margin: 0 0 8px 0;
	}

	h1 {
		font-family: @headline-font;
		font-size: 22px;
		font-weight: normal;
		line-height: 26px;
		text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
		color: @grey;
		position: relative;
	}

	h4 {
		font-family: @headline-font;
		font-size: @normal;
		font-weight: normal;
		line-height: 19px;
		margin-bottom: 8px;
		color: @grey-dk;
	}

	h5 {
		font-size: 15px;
		font-weight: normal;
		line-height: 22px;
		color: @grey-lt;
		margin: 0 0 6px 0;

		a {
			text-decoration: underline;
		}
	}

	&.help-box-sidebar {
		.help-box {
			padding: 24px 12px 8px 48px;

			&:before {
				top: 0;
				bottom: 50%;
				left: 20px;
				width: 6px;
				z-index: -1;
				#util > .gradient >
					.directionTop(rgba(255, 255, 255, 0), 50%, rgba(255, 255, 255, 1), 100%);
			}
			&:after {
				z-index: -2;
				bottom: 50%;
			}

			.icon-help {
				position: absolute;
				top: 0;
				right: 0;
				width: 50px;
				height: 40px;
				.layout-sprite-help-box();
				background-position: -50px 0px;
				opacity: 0.5;
			}
		}
	}

	.help-box-section {
		margin: 0 0 18px 0;

		h4 {
			display: inline-block;
			font-weight: 500;
			margin-bottom: 4px;
		}

		a {
			color: @blue;
			&:hover {
				text-decoration: underline;
				color: @blue-dk;
			}
		}

		li {
			line-height: 18px;
			margin-bottom: 6px;
			.icon {
				vertical-align: -4px;
				padding-right: 5px;

				#util > .inline-block;
				#icon > .help-setup; // eventually might have help for non-setup pages
			}
			a {
				text-decoration: underline;
			}
		}
	}

	hr {
		margin-bottom: 18px;
		height: 0;
		border: none;
		border-bottom: 1px solid @off-white-dk;
	}

	.action-hide-link();
}
