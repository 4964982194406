.react-phone-number {
	position: relative;
	#util > .clearfix();
	.country {
		float: left;
		color: @grey-lt;
		font-size: 11px;
		margin-right: 4px;
	}
	.phone {
		float: left;
		max-width: 200px;
	}
	.extension {
		float: left;
		color: @grey-lt;
		font-size: 11px;
		&:before { content: 'Ext: '; }
		&:empty { display: none; }
	}
	.click-to-call,
	.state-calling-msg {
		position: absolute;
		right: 0;
		bottom: -2px;
		line-height: 20px;
		text-decoration: none;
	}
	.click-to-call {
		padding: 0 10px;
		background-color: @green;
		border-radius: 10px;
		font-size: @small;
		color: white;

		i, span { padding: 0 3px; }
		.fa-chevron-down {
			line-height: 19px;
			vertical-align: top;
		}

		&:hover,
		&.state-call-prompt { background-color: @green-dk; }
	}
	.state-calling-msg {
		display: none;
		right: 2px;
		color: @green;
		pointer-events: none;
		#util.transition(all, 400ms, @customEasing);
		.fa {
			display: inline-block;
			margin-right: 3px;
		}
	}

	&.state-calling-active {
		.click-to-call { display: none; }
		.state-calling-msg { display: inline; }
	}
}

.react-email-address {
	display: block;
	#util > .excerpt-crop > .ellipsis;
}

.svg-close {
	svg { fill: @off-white-dk; }
	&:hover {
		svg { fill: @grey-lt; }
	}
}

.svg-confirm {
	svg { fill: @green; }
	&:hover {
		svg { fill: @green-dk; }
	}
}

.ui-link-standard {
	color: @blue;
	&:hover {
		color: @blue-dk;
		text-decoration: underline;
	}
}
