.container {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.container > :global(#chart-hero) {
    padding: 0 16px 32px 16px;
}

.section-container {
    background-color: #fff;
    height: calc(100% - 41px);
}

.legacy-table-container {
    height: calc(100% - 42px);
}

/* hacks necessary to get interactive table to be full-height and scroll */
.legacy-container {
    height: 100%;
}

.legacy-container > div {
    height: 100%;
}

.legacy-container > div > div {
    height: 100%;
}

.flash-banner {
    border-radius: 3px 3px 0 0;
}

.pagination-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: calc(100% - 16px);
    background-color: #fff;
    padding-left: 16px;
    z-index: 1;
}

.error-retry-button {
    position: absolute;
    top: 15px;
    right: 15px;
}

.report-filter-checkbox {
    margin-right: 10px;
    display: inline;
}
