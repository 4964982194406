.save-complete {
    color: var(--color-success);
}

.spinner:not(:first-child) {
    margin-left: 8px;
}

.spinner svg {
    height: 0.8em;
}
