.question {
    text-align: left;
}

.buttons {
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
}

.left-buttons {
    display: flex;
    margin-right: 10px;
    margin-top: 10px;
}

.right-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    flex-grow: 1;
}

.right-buttons > *:not(:first-child) {
    margin-left: 4px;
}
