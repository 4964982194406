/* Importing entity color values defined in separate shared stylesheet */
@value person, company, product, competitor, source from './entity-colors.css';

.adding-item-container {
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    padding: 4px;
    min-height: 34px;
}

.add-item-component {
    flex: 1 1 100%;
    align-self: center;
    /* Space before the remove button */
    margin-right: 4px;
}

.remove-button-container button {
    flex: 0 0 12px;
    padding: 4px;
    position: relative;
    top: 4px;
}

/* Darken the default color of remove button */
.remove-button-container button:not(:hover) svg {
    fill: var(--grey-lt);
}
