.container {
    display: flex;
    flex-direction: row;
    height: 44px;
    align-items: center;
    padding: 0 15px;
}

.container:nth-child(2n + 0) {
    background-color: var(--zebra-stripe);
}

.container:hover {
    cursor: pointer;
    background-color: var(--color-list-hovered);
}

.name,
.count,
.value {
    font-size: var(--font-normal);
}

.name {
    flex: 3;
    color: var(--grey-dk);
    margin-right: 10px;
}

.count {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: var(--grey-lt);
}

.value {
    color: var(--grey);
    flex: 2;
    display: flex;
    justify-content: flex-end;
}
