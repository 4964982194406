.main-content {
    position: relative;
    height: 100%;
}

.slider {
    position: absolute;
    top: 16px;
    right: calc(30% + 32px); /* This keeps the slider 32px from the 30% width right section */
    z-index: 3; /* Setting this keeps the slider on top of the fields */
}
