.email-template-designer-editor {
    display: flex;
    height: calc(100% - 82px);
}

.loading-overlay {
    position: absolute;
    height: calc(100% - 100px); /* Subtract out header and footer */
    width: 100%;
    z-index: 11; /* gotta cover the 10 from the quill toolbar */
    background-color: rgba(255, 255, 255, 0.6);
}

.user-sequence-list {
    flex-basis: 160px;
    flex-shrink: 0;
    margin-right: 16px;
    height: 100%;
    /* Make this line up with the modal title */
    margin-left: -16px;
}

.sequence-title-container {
    /* Make this line up with the modal title, given the margin change above */
    margin-left: 16px;
    margin-bottom: -8px;
}

.divider {
    height: 100%;
    width: 2px;
    margin-right: 4px;
    background-color: var(--off-white-dk);
}

.current-user-sequence {
    margin-bottom: 16px;
}

.current-user-sequence button {
    padding-left: 16px;
    text-align: left;
    width: 100%;
    font-size: var(--font-small) !important;
    text-decoration: none !important;
    color: var(--grey);
}

.current-user-sequence button:hover {
    background-color: var(--blue-bg);
    color: var(--grey-dk) !important;
}

.current-user-sequence--selected button {
    background-color: var(--blue-lt);
    color: var(--grey-dk) !important;
}

.current-user-sequence--blue button {
    color: var(--blue) !important;
}

.current-user-sequence--blue button:hover {
    color: var(--blue-dk) !important;
}

.current-user-sequence--blue-selected button {
    color: var(--blue-dk) !important;
}

.email-template-list {
    flex-basis: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    /* Offsets the 8px of margin for each list item */
    margin-top: -8px;
    margin-right: 16px;
    height: 100%;
}

.email-template-list-item {
    width: calc(100% - 16px);
}

.email-composer-area {
    position: relative;
    width: 100%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: -2px;
}

.body {
    display: flex;
    height: 100%;
    min-height: 0; /* For firefox */
    margin-top: 8px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid var(--off-white-dk);
}

.body--read-only {
    composes: body;
    background-color: var(--off-white);
    opacity: 0.6;
    border: 1px solid rgb(225, 225, 225);
}

.body--read-only * {
    color: var(--grey) !important;
}

.body:not(.body--focused):not(.body--read-only):hover {
    border-color: var(--grey-lt);
}

.body--focused {
    composes: body;
    border-color: var(--blue);
}

.subject-container {
    margin-top: 2px;
    display: flex;
    width: 100%;
    align-items: center;
    color: var(--grey-lt);
}

.subject-container > :first-child {
    width: 100%;
}

.separator-bar {
    margin-left: 8px;
    height: 20px;
    background-color: var(--off-white-dk);
}

.count-container {
    display: flex;
    align-items: center;
    color: var(--icon-primary);
}

.count-container--clickable {
    composes: count-container;
    cursor: pointer;
}

.count-container--clickable:hover {
    color: var(--icon-primary-hovered); /* text */
}

.view-icon-container {
    margin: 0 8px;
}

.view-icon-container > div:first-child {
    align-content: center;
    display: flex;
}

.send-as-reply {
    position: absolute;
    display: flex;
    align-items: center;
    right: 16px;
    top: 0;
    bottom: 0;
}

.template-message-adder-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: -4px;
}

.row-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 7px;
}

.row-title-container > div:first-child {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
