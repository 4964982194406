.mini-list-popover {
    padding: 8px;
    position: relative;
}

.loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.02);
}

.header {
    font-weight: 500;
    font-size: 15px;
    padding: 8px 0 4px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
