.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 5px;
    min-height: 220px;
}

.column-container {
    display: flex;
    flex-direction: row;
    max-height: 220px;
    overflow-y: auto;
}

.empty-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    user-select: none;
}

.error-container {
    composes: empty-container;
}

.error-container .empty-text {
    bottom: -6px;
}

.empty-image-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.empty-image {
    height: 90px;
    margin-bottom: 16px;
}

.empty-text {
    width: 100%;
    text-align: center;
    font-size: var(--font-normal);
}

.cell-container {
    height: 44px;
    flex-shrink: 0;
}

.footer {
    line-height: 41px;
    height: 44px;
    background-color: white;
    font-size: var(--font-small);
    padding: 0 16px;
    border-top: 1px solid var(--off-white-md);
    user-select: none;
    box-sizing: border-box;
}

.count {
    color: var(--grey);
    display: inline-block;
}

.more-link {
    color: var(--blue);
}

.more-link:hover {
    color: var(--blue-dk);
    text-decoration: underline;
}
