.newsletter-container {
    display: flex;
    width: calc(100% - 96px);
    padding: 32px;
    margin: 16px;
    border: 1px solid var(--off-white-dk);
}

.reporting-container {
    padding: 32px;
    border: 1px solid var(--off-white-dk);
    margin: 16px;
    display: flex;
    justify-content: center;
}

.preview-container {
    padding: 32px;
    border: 1px solid var(--off-white-dk);
    margin: 16px;
    display: flex;
    justify-content: center;
}

.right-container {
    flex-grow: 1;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    width: var(--nm-sidebar-width);
}
