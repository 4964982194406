.company-types {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.company-types svg {
    width: 80%;
}

.company-types :global(.slide-up) {
    animation: slideUp 500ms;
}

.company-types :global(.shadow) {
    animation: slideDown 500ms;
}

@keyframes slideDown {
    0% {
        transform: translateY(-5px);
    }
    30% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        transform: translate(80px, 10px);
    }
    30% {
        transform: translate(80px, 10px);
    }
    100% {
        transform: translate(80px, 0);
    }
}
