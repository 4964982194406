.container {
    height: 100%;
    position: relative;
    opacity: 0;
    animation: fade-in 1000ms var(--custom-easing) 0s forwards;
}

.refreshing-icon {
    composes: loading-icon from '../loading-chart.css';
    top: 35%;
}

.timeseries-container {
}

.timeseries-container--loading {
}

.timeseries-container--loading :global(.recharts-y-axis),
.timeseries-container--loading :global(.recharts-bar),
.timeseries-container--loading :global(.recharts-x-axis),
.timeseries-container--loading :global(.recharts-line) {
    opacity: 0.3;
}

.timeseries-container :global(.recharts-legend-item):hover {
    color: var(--grey-dk);
    cursor: pointer;
}

.timeseries-container :global(.recharts-legend-item):last-child,
.timeseries-container--loading :global(.recharts-legend-item):last-child {
    margin-right: 0 !important;
}

.timeseries-container :global(.recharts-legend-item),
.timeseries-container--loading :global(.recharts-legend-item) {
    padding-bottom: 6px;
}

.timeseries-container :global(.recharts-legend-item .recharts-surface),
.timeseries-container--loading :global(.recharts-legend-item .recharts-surface) {
    margin-top: -2px;
}

.tooltip {
    padding: 5px 8px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

.chart-actions {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 1;
    animation: fade-in 1000ms var(--custom-easing) 0s forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

:global(.recharts-tooltip-cursor) {
    cursor: default;
}

.clickable {
}

.clickable :global(.recharts-tooltip-cursor),
.clickable :global(.recharts-bar-rectangles) {
    cursor: pointer; /* IE11 does not support zoom-in, so fall back to pointer */
    cursor: zoom-in; /* stylelint-disable-line declaration-block-no-duplicate-properties */
}
