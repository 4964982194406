.container {
    display: flex;
    padding: 8px;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
}

.rows {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px); /* 40px is the width of the avatar and left padding */
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}

.name {
    width: calc(100% - 30px); /* Leave some space for the remove button */
}

.remove-button svg {
    align-self: flex-start;
}

.remove-button button {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
