@value gap: 12px;

.peep-details-section {
    display: flex;
    flex-direction: column;
    gap: gap;
    padding: 24px 32px 12px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.special-state-container {
    display: flex;
    justify-content: center;
    width: calc(100% - 16px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.special-state-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 12px;
    background-color: rgba(0, 0, 0, 0.04);
    z-index: 2;
}

.disabled {
    color: var(--grey-lt);
}
