.bar {
    display: flex;
    width: 2px;
    height: 5px;
    margin: 0 2px;
    background-color: white;
    animation: bar 0.5s infinite;
    overflow: hidden;
}

.bar-container {
    display: flex;
    padding: 0 8px;
    align-items: center;
    max-height: 15px;
    justify-content: center;
}
