.container {
    display: flex;
    flex-direction: column;
    width: 250px;
}

.form-container {
    padding: 16px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid var(--off-white-dk);
}

.compare-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px 0;
}

.compare-container > *:not(:last-child) {
    margin-bottom: 8px;
}

.form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.form-submit {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
}

.date-range-inputs {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
}

.date-range-inputs > *:first-child {
    margin-right: 8px;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey);
    color: #fff;
    font-size: var(--font-normal);
    height: 30px;
    border-left: 1px solid var(--grey);
}
