.message {
    composes: message from '../inbox/messages/message-bubble/message-bubble.css';
    border-radius: 16px;
    padding: 10px;
    text-align: left;
    position: relative;
    height: 100%;
    display: flex;
    gap: 8px;
    align-items: flex-start;
}

.message--full-width {
    composes: message;
    width: 100%;
}

.white .message {
    background-color: var(--white);
}

.off-white .message {
    background-color: var(--off-white);
}

/* make icon aligned with text */
.icon {
    transform: translateY(3px);
}

.message-tail::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 8px solid transparent;
    border-top: 8px solid transparent;
    left: -3px;
    bottom: 1px;
    transform: rotate(-18deg);
}

.white .message-tail::before {
    border-right: 5px solid var(--white);
    border-bottom: 8px solid var(--white);
}

.off-white .message-tail::before {
    border-right: 5px solid var(--off-white);
    border-bottom: 8px solid var(--off-white);
}

.message-content {
    display: flex;
    gap: 8px;
    align-items: flex-start;
}

.message-content--truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    overflow: hidden;
    text-overflow: ellipsis;
}
