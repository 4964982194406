.pipelines-container {
    padding: 16px;
    padding-bottom: 300px; /* allow enough room for template download overlay */
    background-color: white;
}

.pipeline-list {
    margin-top: 16px;
}

.container {
    display: flex;
    align-items: center;
    margin-top: 32px;
    flex-direction: column;
}

.container svg {
    fill: var(--grey-lt);
}

.error-image {
    max-width: 100px;
    margin-bottom: 16px;
}

.error-text {
    font-size: var(--font-normal);
    color: var(--grey);
    margin-bottom: 16px;
}

.template-container--shown {
    position: fixed;
    bottom: 16px;
    right: 16px;
    animation: slide-in 3000ms ease-out;
}

.template-container--hidden {
    position: fixed;
    bottom: 16px;
    right: -600px;
    animation: slide-out 500ms ease-in;
}

@keyframes slide-in {
    0% {
        right: -600px;
    }
    75% {
        right: -600px;
    }
    100% {
        right: 16px;
    }
}

@keyframes slide-out {
    0% {
        right: 16px;
    }
    100% {
        right: -600px;
    }
}
