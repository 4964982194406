.page-container {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.content-wrapper {
    overflow: hidden;
    height: 100%;
    background-color: var(--sidebar-primary);
}

.app-messages-container {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 2;
}
