.option-wrapper {
    display: flex;
}

.option-label {
    display: flex;
    align-items: center;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
