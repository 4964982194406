.date-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 16px 0 16px;
    background-color: #fff;
    color: var(--grey-dk);
    user-select: none;
    width: 100%;
    min-height: 40px;
    border-bottom: solid 2px var(--off-white-md);
    font-size: 15px;
    font-weight: 500;
    box-sizing: border-box;
}
