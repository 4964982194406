.container {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid var(--off-white-dk);
    background-color: white;
}

.top-row {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-row-left-group {
    display: flex;
    align-items: center;
    height: 30px;
}

/* Prevent filter button from shrinking */
.top-row-left-group > .filter-button {
    flex-shrink: 0;
}

/* Signficant margin after filter button, to allow for badge */
.top-row-left-group > .graduated-filters {
    margin-left: 16px;
}

/* Give a default spacing between children except for search, which has a larger left margin */
.top-row-left-group > .graduated-filters > div:not(:first-child),
.count,
.remove-filters {
    margin-left: 8px;
}

/* Wider between graduated filters and search box */
.top-row-left-group > .search {
    margin-left: 12px;
}

.top-row-right-group {
    flex-grow: 1;
}
