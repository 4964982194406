.ui-entity-list {
	//main table border
	.fixedDataTableLayout_main {
		border: none;
		z-index: 1;
	}

	//main cell borders
	.fixedDataTableCellLayout_main {
		border-right: none;
	}

	//header borders, need to remove the one for the fixed column
	//because that border is handled on the entire column
	.public_fixedDataTable_header .public_fixedDataTableCell_main {
		border-right: none;

		.ui-fixed-column-header {
			.ui-entity-list__column-header {
				border-right: none;
			}
		}

		.ui-entity-list__column-header {
			border-right: 1px solid @off-white-dk;
		}
	}

	//fixed column borders
	.public_fixedDataTableRow_fixedColumnsDivider {
		border-color: @off-white-dk;
	}

	//so when you scroll there ain't no shadow
	.public_fixedDataTableRow_columnsShadow {
		width: 4px;
	}

	.public_fixedDataTable_header,
	.public_fixedDataTable_header .public_fixedDataTableCell_main,
	.public_fixedDataTable_hasBottomBorder {
		background-image: none;
		border-color: @off-white-dk;
	}

	//remove stupid hover growing
	.ScrollbarLayout_mainHorizontal.public_Scrollbar_mainActive,
	.ScrollbarLayout_mainHorizontal:hover {
		height: 15px;
	}

	.fixedDataTableLayout_horizontalScrollbar {
		border-bottom: 1px solid @off-white-dk;
	}

	.public_fixedDataTableRow_highlighted,
	.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
		background-color: @table-zebra-stripes; /* TODO: standardize color */
	}

	.public_fixedDataTableCell_cellContent {
		padding: 0;
	}

	.fixedDataTableCellLayout_wrap1 {
		table-layout: fixed;
	}
}
