.icon-avatar {
    position: relative;
}

.icon-avatar > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: currentColor;
}
