.textfield-with-apply {
    display: flex;
}

.textfield-with-apply > input {
    margin-right: 16px;
}

.textfield-with-apply > button {
    flex-shrink: 0;
}
