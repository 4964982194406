@value button-icon-size: 20px;

.glossary-button {
    border-radius: button-icon-size;
    color: var(--white);
    padding: 0;
    display: flex;
    /* Hides button-text animating in from the left */
    clip-path: border-box;
}

.icon-wrapper {
    background-color: var(--onboarding-help);
    border-radius: button-icon-size;
}

.glossary-icon-container {
    width: button-icon-size;
    height: button-icon-size;
    border-radius: button-icon-size;
    background-color: var(--white);
    opacity: 0.6;
    position: relative;
}

.glossary-icon-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button-text--hidden {
    /* Text is hidden until button animation is triggered */
    display: none;
    visibility: hidden;
}

.button-text {
    position: relative;
    height: button-icon-size;
    border-radius: button-icon-size;
    background-color: var(--onboarding-help);
    /* Text is behind icon */
    z-index: -1;
    /* Padding space for icon before & after text*/
    padding-left: 24px;
    padding-right: 10px;
    /* Adjust placement to offset icon size */
    left: calc(button-icon-size * -1);
    margin-right: calc(button-icon-size * -1);
}

/* Align the text within the container */
.button-text > :first-child {
    padding-top: 1px;
}

.button-text--closed {
    composes: button-text;
    display: none;
}

.button-text--expand {
    composes: button-text;
    /* Position animation in from the left */
    animation: expand 1s;
}

.button-text--closing {
    composes: button-text;
    /* Position animation out to the left */
    animation: contract 1s;
}

@keyframes expand {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes contract {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}
