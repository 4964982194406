.floating-button {
    border: 1px solid var(--off-white-dk);
    background-color: white;
    border-radius: 30px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 83px; /* gets us 16px above the footer */
    left: 51.2%; /* centers the button for this exact width */
    z-index: 10;
    padding: 6px 12px;
}

.floating-button:hover {
    background-color: var(--off-white);
}
