.form {
    padding: 8px;
    display: flex;
    gap: 8px;
}

.fields-row {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
}

.fields-row:first-child {
    margin-bottom: 4px;
}

.fields-row > input {
    height: 30px;
}

.fields-row > button {
    padding: 0 0 0 4px;
}
