.container {
    margin-top: 8px; /* align content with help sidebar */
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    position: relative;
    cursor: pointer;
    font-size: var(--font-huge);
    color: var(--blue);
}

.content:hover {
    color: var(--blue-dk);
    font-weight: 500;
    text-decoration: none;
}

.image {
    max-width: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
