.banner-container {
    margin-bottom: 32px;
}

.banner-container--last-entry {
    margin-bottom: 56px; /* This gives extra margin for the end circle */
}

.content {
    margin-left: 16px;
}

.target {
    font-weight: 500;
    overflow-wrap: anywhere;
}

.target--button {
    composes: target;
    color: var(--blue);
}

.target--button:hover {
    text-decoration: underline;
    cursor: pointer;
}

.target--button:focus {
    text-decoration: underline;
}

.submission-data {
    padding: 16px 32px;
}

.banner-body {
    position: relative;
    margin-top: 4px;
    margin-left: 60px;
}

.banner-body--small {
    composes: banner-body;
    margin-left: 0;
}

/* left-align the reaction bar/button */
.reactions {
    width: fit-content;
    position: relative;
    left: -8px;
}

.reaction-bar-container {
    composes: reactions;
    /* 8px spacing for the reaction bar */
    top: 8px;
}

.reaction-button-container {
    composes: reactions;
    /* No top spacing when just showing the button*/
    top: 0;
}

.reactions > div {
    padding: 0 0 4px 4px;
}

.body {
    padding: 32px;
}
