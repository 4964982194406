.ui-popover.ui-popover-form {
	width: 220px;

	.inner-wrapper { padding: 10px 14px; }
	.ui-popover-form__form-container {
		max-height: 300px;
		overflow-y: auto;
		padding: 0 5px;
		margin-bottom: 50px;
	}

	//"X" button
	.ui-btn__popover-close {
		position: absolute;
		right: 5px;
		top: 8px;
	}

	//checkmark button
	.ui-popover__submit-button {
		position: absolute;
		right: 5px;
		bottom: 10px;
	}

	.ui-btn__popover-back {
		position: absolute;
		left: 0;
		top: 6px;
		font-size: 18px;
		color: @off-white-dk;

		&:hover { color: @grey-lt; }
	}

	.ui-popover__remove-filter { bottom: 5px; }

	&.ui-popover__filter-list {
		height: 440px;

		.ui-global-filter-list { padding: 10px 0; }
		.ui-global-filter-list > ul {
			height: 290px;
			overflow-y: scroll;

			.ui-filter-list-item {
				border-top: 1px solid @off-white-md;
				&.ui-filter-list-item--selected,
				&:hover {
					.ui-global-filter-list-item {
						color: @grey-dk;
						background-color: @yellow-lt;
					}
				}

				&.ui-filter-list-item--last-custom {
					.ui-global-filter-list-item {
						margin-bottom: 5px;
					}

					border-bottom: 1px solid @off-white-md;
					margin-bottom: 5px;
				}
			}
		}
		.inner-wrapper {
			height: 360px;
			box-sizing: border-box;
		}

		.ui-global-filter-list-item {
			display: block;
			font-size: 15px;
			padding: 6px 10px;
			color: @grey;
		}

		.ui-searchable-list {
			.searchable ul { height: 220px; }
			&.ui-searchable-list--count-1 .searchable ul { height: 207px; }
			&.ui-searchable-list--count-2 .searchable ul { height: 177px; }
			&.ui-searchable-list--count-3 .searchable ul { height: 161px; }
		}
	}

	&.ui-popover--saved-filters {
		@saved-filter-popover-width: 300px;
		width: @saved-filter-popover-width;
		height: 400px;

		.ui-saved-filter-list ul {
			height: 260px;
			overflow-y: scroll;
		}

		.inner-wrapper {
			height: 310px;
			padding: 10px 14px;
			box-sizing: border-box;
		}

		.ui-saved-filter-list-item {
			.ui-saved-filter-list-item__wrapper {
				#util > .clearfix();
				display: block;
				font-size: 15px;
				padding: 5px 0 5px 10px; //icon has its own padding
				color: @grey;
				border-top: 1px solid @off-white-md;
			}

			&:hover,
			&.ui-saved-filter-list-item--selected {
				.ui-saved-filter-list-item__wrapper {
					background-color: @yellow-lt;
					cursor: pointer;
				}

				.ui-saved-filter-list-item__name {
					color: @grey-dk;
				}
			}

			&.ui-saved-filter-list-item--shared {
				.ui-btn__update-saved-filter {
					color: @blue;
					&:hover { color: @blue-dk; }
				}
			}

			&.ui-saved-filter-list-item--last-immutable {
				border-bottom: 1px solid @off-white-md;
			}

			.ui-saved-filter-list-item__name {
				float: left;
				padding: 5px 0;
				max-width: ~"calc(@{saved-filter-popover-width} - 80px)";
				#util > .excerpt-crop > .ellipsis;
			}

			.ui-btn__update-saved-filter {
				color: @off-white-dk;
				float: right;
				line-height: 13px;
				padding: 5px 7px;

				&:hover { color: @grey-lt; }
			}

			.ui-saved-filter-list-item__shared-filter-icon {
				color: @off-white-md;
				float: right;
				line-height: 13px;
				padding: 5px 9px;

				&:hover { color: @grey-lt; }
			}

			.ui-btn__delete-saved-filter {
				float: right;
				padding: 5px 10px 5px 7px;
				svg { fill: @off-white-md; }
				&:hover svg { fill: @rose; }
			}
		}

		.ui-saved-filter-list {
			.ui-saved-filter-list__search {
				padding: 0 0 10px 0;
			}

			.ui-saved-filter-list__shared-filter {
				text-align: center;
				margin-top: -8px;
				margin-bottom: 8px;

				label {
					min-width: auto;
					padding: 3px 20px 3px 22px;
				}
			}

			.ui-saved-filter-list--empty {
				margin-top: 50px;
				text-align: center;

				h1 {
				    font-weight: 500;
				    font-size: 17px;
				    line-height: 21px;
				    color: @grey-dk;
				    margin-bottom: 45px;
				}

				h3 {
					font-size: 13px;
					color: @grey-lt;
				}
			}
		}

		.ui-popover--saved-filters__reset-container {
			position: absolute;
			bottom: 15px;
			text-align: center;
			width: 100%;
		}

		.ui-popover--saved-filters__reset-filters-btn {
			width: 200px;
			margin-right: 0;
		}
	}

	&.ui-popover--filter-save {
		width: 350px;

		.ui-filter-save__share-label {
			padding: 0 2px;
			font-weight: 500;
			margin-bottom: 8px;
		}

		.ui-radio-group .ui-radio-btn {
			label {
				padding: 2px 10px;
			}
		}

		.save-button {
			float: right;
			padding: 16px 0;
		}
	}
}

.ui-popover {
	&.ui-popover-form__header-filter {
		&.ui-popover-form__header-filter--date-time { width: 220px; }
		&.ui-popover-form__header-filter--string { width: 200px; }
		&.ui-popover-form__header-filter--enum { width: 300px; }
		&.ui-popover-form__header-filter--relationship { width: 300px; }
	}
}

.ui-form {
	.ui-location-form__of { margin-left: 10px; font-weight: 500; }
}

.ui-form {
	&.ui-form--relationship {
		margin-bottom: 0;
		.ui-form--relationship__form-controls {
			text-align: center;
			margin-bottom: 15px;
		}

		.ui-form--relationship__form-controls__control {
			margin: 0 10px;
		}
	}
}
