.table-container {
    width: 100%;
    height: 100%;
}

.loading-container {
    padding-top: 24px;
    width: calc(100% - 8px);
}

.button-container {
    width: 100%;
    background-color: var(--blue-lt);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.button-container > *:first-child {
    height: 100%;
}
