.quota-container {
    display: flex;
    flex-direction: column;
    height: 29px;
    padding: 7px 15px;
}

.quota-container--full-height {
    composes: quota-container;
    height: 250px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-normal);
    color: var(--grey-dk);
}

.status-container {
    flex-shrink: 0;
}

.status-container--no-quota {
    font-size: var(--font-small);
    white-space: nowrap;
}

.title-container {
    text-overflow: ellipsis;
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 8px;
}

.assignee {
    font-size: var(--font-small);
    color: var(--grey-lt);
    padding-left: 6px;
}

.current-value {
    float: left;
    color: var(--grey-dk);
}

.status-divider {
    float: left;
    padding: 0 4px;
}

.quota-value {
    float: left;
    color: var(--grey-lt);
}

.setup-prompt-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--font-big);
    line-height: 21px;
}

.setup-prompt-container .quota-edit-link {
    font-size: var(--font-small);
}

.quota-edit-link {
    color: var(--blue);
    position: relative;
    top: 1px;
}

.quota-edit-link:hover {
    text-decoration: underline;
    color: var(--blue-dk);
}

.currency-prefix {
}
.currency-value {
}
.currency-suffix {
}
