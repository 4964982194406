.stage-guidance-and-goals-container {
    margin-top: 24px;
}

.stage-guidance-and-goals {
    display: flex;
}

.goals-container {
    padding-left: 16px; /* Required for when guidance isn't rendered to offset label */
    margin-top: 8px; /* Aligns with old-school "Guidance" label on the left */
    flex-basis: 0;
    flex-grow: 1;
    max-width: 440px;
}
