.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7a737033;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
}
