.email-control-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--off-white-dk);
    padding: 8px 16px;
    background-color: #fff;
}

.control-bar-left-section {
    display: flex;
    margin-right: 16px;
    align-items: center;
    color: var(--grey-dk);
    font-weight: 500;
}
