.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    border-left: 1px solid var(--off-white-dk);
    border-bottom: 1px solid var(--off-white-dk);
    border-right: 1px solid var(--off-white-dk);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
}

.button-container > *:first-child {
    height: 100%;
}
