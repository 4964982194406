.section-container {
    margin: 32px 0;
    position: relative;
}

.section-container > *:first-child {
    /* Match spacing in other lead page sections */
    margin-bottom: 14px;
}

.content-container {
    /* Match spacing in other lead page sections */
    margin-bottom: 10px;
}

.button-container {
    /* Make button appear aligned with section label content */
    position: absolute;
    right: -9px;
    top: -4px;
    /* Being inside section label makes weight bold - this undoes that */
    font-weight: 400;
}
