.container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 20;
    justify-content: center;
    align-items: center;
}

.container-opaque {
    composes: container;
    background-color: white;
}
