.container {
    display: inline-flex;
    transform: translateY(0);
    height: 16px;
    width: fit-content;
    margin-left: 5px;
    color: white;
    background-color: var(--orange);
    font-weight: normal;
    font-size: var(--font-small);
    line-height: 15px;
    padding: 1px 2px 0 2px; /* Weird #s to perfectly center */
    border-radius: 2px;
}
