.automated-email-table {
    font-size: var(--font-normal);
    margin-bottom: 32px;
}

.automated-email-table > * {
    width: 100%;
}

.lead-name,
.email-sequence {
    min-width: 0;
}

.lead-name > * {
    padding: 0;
}
