.fixed-footer {
    bottom: 0;
    background-color: var(--white);
    padding: 12px;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    color: var(--blue);
}

.fixed-footer:hover {
    background-color: var(--color-list-hovered);
}
