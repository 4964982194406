.pathname-field {
    position: relative;
}

.domain-name-text {
    position: absolute;
    top: 9px;
    left: 8px;
    padding-right: 8px;
    border-right: 1px solid var(--off-white-dk);
}

.pathname-field-with-buttons {
    width: 100%;
    display: grid;
    grid-template-columns: auto minmax(50%, 1fr);
    border: 1px solid var(--off-white-dk);
}

.domain-name-text-with-buttons {
    padding-right: 8px;
    padding-left: 8px;
    border-right: 1px solid var(--off-white-dk);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    text-align: right;
    line-height: 32px;
    direction: rtl;
}

.domain-name-text-with-buttons--disabled {
    composes: domain-name-text-with-buttons;
    opacity: 0.6;
    background-color: var(--off-white);
}

.domain-name-text-ltr {
    direction: ltr;
    unicode-bidi: bidi-override;
}
