.notify-item {
    display: flex;
    padding: 5px;
    margin-right: 16px;
}

.notify-complete {
    composes: notify-item;
}

.text-container {
    display: block;
    flex-grow: 1;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 200ms ease-in-out;
}

.complete-text-container {
    composes: text-container;
    background-color: var(--green);
}

.complete-text-container:hover {
    background-color: var(--green-dk);
}

.icon {
    float: right;
    line-height: 21px;
    color: #fff;
}

.notify-text {
    max-width: 230px;
    float: left;
    color: #fff;
    font-size: 15px;
    line-height: 21px;
}
