

table {
	width: 100%;
}

th {
	text-align: left;
}

div.list-standard-wrapper {
	margin-bottom: 30px;

	// see layout.less for .list-standard-topper
}

div.list-standard-featured, tr.list-standard-featured {
	background: @blue-bg;
	border: 1px solid @off-white-dk;
	border-top: none;
	padding: 5px 8px;
}
tr.list-standard-featured { border: none; }

div.list-standard-action,
tr.list-standard-action {
	opacity: 0.9;
	background: @color-list-selected;
	padding: 5px 8px;
}
div.list-standard-action.state-selected,
div.list-standard-action:hover td,
tr.list-standard-action.state-selected,
tr.list-standard-action:hover {
	opacity: 1;
	background: darken(@blue-bg, 2%);
	a {
		color: @blue-dk;
		&:hover { text-decoration: none; }
	}
}

// Replacement for behavior-innerlink.  Going to let the interior anchor element get the padding
td.link-container {
	padding: 0;
}

a.full-table-cell {
	display: flex;
    padding: 6px;
}

// standard table headers (glassy mid-gradient), background image has a burned-in left border
.standard-table-header, // for use as mixin
tr.list-standard-header,
tr.column-header {
	 .th, // for use as mixin
	 th {
		border-left: 1px solid @off-white-dk;
		border-bottom: 1px solid @off-white-dk;
		padding: 8px 10px;
		background-color: @off-white;
		color: @grey-dk;
		white-space: nowrap;

		div.title {
			font-size: 11px;
			line-height: 14px;
		}
		div.subtitle {
			font-size: 11px;
			line-height: 11px;
			height: 11px;
			color: @grey;
			font-weight: normal;
			margin: 3px 0;
			.left { float: left; }
			.right { float: right; }
		}
	}

	.th:first-child, // for use as mixin
	th:first-child {
		padding-left: 15px;
		border-left: 0px; // no left border on first cell
	}
	th:last-child { border-right: 0px; } // no right border on last cell
}

// special case for when we have section headers (one of which is probably the first row)
table, div.interactive-table {
	&.has-section-headers {
		tr.list-standard-header th, tr.column-header th, .column-header-pre, .column-header-post {
			border-bottom-width: 0;
		}
	}
}

table.list-standard {
	border: 1px solid @off-white-dk;
	border-top: none;
	border-radius: 0 0 3px 3px;
	tr {
		td:not(.link-container) { padding: 6px 6px; }

		td {
			vertical-align: middle;
			background-color: transparent;

			.behavior-remove { // ugh. these push the cell taller than default, and i can't think of any other way.
				float: left;
				cursor: pointer;
				width: 19px;
				height: 19px;
				margin-left: -36px;
				border: 1px solid @off-white-dk;
				background-color: white;
				.2x('@{img-root}/process-builder/icon-remove.png', "repeat", 19px, 19px);
				#util > .opacity(0);
				position: relative;
				z-index: 2;
				&:hover { #util > .opacity(1); }
			}

			&.blink-success {
				background-color: @green-lt;
			}

			&:first-child {
				padding-left: 15px;
			}

			a:hover, a:focus { text-decoration: underline; }

			input.tunable-setting-value {
				width: 150px;
			}

			i.fa-check {
				color: @green;
			}
			i.fa-warning {
				color: @warning;
			}
			.grey {
				color: @grey-lt
			}
		}

		&.hoverable-row td {
			padding: 0;
		}

		&.hoverable-row a:not(.kibana-link) {
			display: flex;
			height: 100%;
			padding: 12px 6px;
		}
		&.hoverable-row a:not(.underline) {
			text-decoration: none;
		}

		&.hoverable-row:hover {
			background-color: @off-white-dk;
		}
	}
	tr:hover, tr.state-hover-delay {
		.behavior-remove { #util > .opacity(0.8); }
	}
	tr.state-selected {
		background-color: @off-white-dk;
	}
}

// zebra striping for tables
.behavior-zebra > tr:nth-child(odd) {
	background-color: white;
}
.behavior-zebra > tr:nth-child(even) {
	background-color: @table-zebra-stripes;
}

tr.row-empty-placeholder {
	border-bottom: none !important;
}

li.row-empty-placeholder,
tbody tr.row-empty-placeholder td, { // importants to override non-list-standard (esp. talk tab)
	color: @grey-lt !important;
	background-color: white;
	text-align: center;
	padding: 24px 0 !important;
	cursor: default;

	h1 {
		font-size: 19px;
		line-height: 28px;
	}
	h2 {
		font-size: 14px;
		line-height: 22px;
	}

	small {
		font-size: 12px;
		line-height: 28px;
	}
	a {
		color: @grey-lt;
		text-decoration: underline;
	}
	h1, h2 { font-weight: normal; }
}

th.behavior-sortable {
	cursor: pointer;

	.sorter {
		display: inline;
		vertical-align: 1px;
		margin-left: 5px;
		width: 10px;
		height: 6px;
		.2x('@{img-root}/tables/sort-sprite.png',"no-repeat", 20px 6px);
		display: none;
	}
	&.state-sorted {
		.sorter {
			#util > .inline-block;
		}
	}
	&.state-sorted-ascending .sorter {
		background-position: 0 0;
	}
	&.state-sorted-descending .sorter {
		background-position: -10px 0;
	}
}

th.behavior-reorderable {
	.header-contents {
		padding-right: 24px;
		position: relative;
	}

	.icon.action-handlebar {
		#util > .inline-block;
		position: absolute;
		top: 7px;
		right: 0;
		vertical-align: middle;
		margin-left: 5px;
		cursor: move;
		width: 24px;
		#icon > .pbuilder-handlebar;
		visibility: hidden;
	}
	&:hover .icon.action-handlebar {
		visibility: visible;
	}
}

th.header-empty-placeholder {
	min-width: 30px;
	background-image: none !important;
}

/* interactive tables (scripts in table.js) */

.interactive-table {
	position: relative;
	font-size: @small;
	line-height: 29px;
	border: 1px solid @grey;
	border-radius: 3px;
	background: white;
	margin-top: 30px;
	left: 0;

	.interactive-table-header {
		background: @grey;
		border-bottom: 1px solid @grey-dk;
		z-index: 20;
		padding: 5px;
		color: white;
		text-align: center;

		.action-expand {
			display: block;
			position: absolute;
			top: 10px;
			left: 8px;
			z-index: 2;
			opacity: 0.75;
			#icon > .custom-expand;
			background-position: center center;

			&:hover {
				opacity: 1.0;
			}
		}
		.state-expanded {
			#icon > .custom-contract;
		}
		.tselect-opener {
			float: right;
			margin-left: 5px;
		}
		.buttons {
			float: right;
	    margin-top: -2px;
			.button-action,
			.button {
				font-size: @small;
			}
		}
		.button-action,
		.tselect-options,
		.selected-count-message {
			float: left;
		}
	}

	.interactive-table-footer {
		background: @grey;
		border-top: 1px solid @grey-dk;
		height: 30px;
		overflow: hidden;
		text-align: center;
		position: relative;
		z-index: 8;
		line-height: 17px;

		.interactive-table-footer-inner {
			display: none; /* To be removed via js once pagination is updated */
		}

		ol.pages {
			display: inline;
			li {
				display: inline-block;
			}
			li.state-selected a {
				opacity: 1;
			}
			.more-pages {
				display: none;
				margin: 0 5px;
			}
		}

		a {
			background: white;
			color: @grey;
			#util > .inline-block;
			padding: 0 5px;
			margin: 6px 0;
			#util > .rounded-corners(3px);
			opacity: 0.4;
			&:hover, a:focus {
				opacity: 1;
			}
		}
	}

	.interactive-table-table-container {
		overflow: hidden;
		position: relative;
	}
	.interactive-table-table, // for use as mixin
	table.interactive-table-table,
	table.interactive-table-sticky-column-table {
		position: relative;
		left: 0;
		z-index: 0;

		.th, // for use as mixin
		th {
			border-bottom: 1px solid @off-white-dk;
			padding: 0px 8px;
			text-align: left;
			#util > .excerpt-crop > .ellipsis;
		}
		td {
			padding: 0px 10px;
			#util > .excerpt-crop > .ellipsis;
		}
		.th.column-header-pre, // for use as mixin
		.th.column-header-post, // for use as mixin
		th.column-header-pre,
		th.column-header-post {
			width: 24px;
			font-weight: normal;
			font-style: italic;
			padding-left: 1px;
			padding-right: 1px;
			text-align: center;
		}
		tr.column-header th {
		}
		tr.section-header th {
			// see global.less
		}
		tr.section-header th div {
			position: relative;
		}
		tr.row-empty-placeholder td {
			color: @grey-lt;
			background-color: white;
			font-size: 18px;
			text-align: center;
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	// "subclasses" table.interactive-table-table
	table.interactive-table-sticky-column-table {
		z-index: 5;
		margin-top: 0;
		border: 1px solid transparent;
		border-top: none;

		th { padding-bottom: 1px; }
		td.hidden_cell { visibility: hidden; }
		tr.section-header th { visibility: hidden; }
	}
}

// BROWSER HACKS to tweak tselect popup positioning.
.browser-ff .interactive-table .interactive-table-header .tselect-opener .action-options {
	top: -1px;
	right: -3px !important;
}

.interactive-table {
	div.column-header-pre {
		.standard-table-header > .th;
		.interactive-table > .interactive-table-table > .th;
		.interactive-table > .interactive-table-table > .th.column-header-pre;
		position: absolute;
		z-index: 8;
		left: 0px;
		top: 40px;
	}
	div.column-header-post {
		.standard-table-header > .th;
		.interactive-table > .interactive-table-table > .th;
		.interactive-table > .interactive-table-table > .th.column-header-post;
		position: absolute;
		z-index: 8;
		right: 0px;
		top: 40px;
	}

}
.interactive-table.drag-enabled th.drag-handle {
	cursor: move;
}
a.interactive-table-pan {
	position: absolute;
	z-index: 6;
	top: 30px;
	bottom: 0px;
	width: 25px;
	font-size: 24px;
	line-height: 30px;
	background: url("@{img-root}/tables/interactive-table-pan-bg.png") 0 top repeat-y; // 80%
	vertical-align: middle;
	text-align: center;
	#util > .shadow > .box("0 0 5px rgba(0, 0, 0, 0.25)");
	text-decoration: none;

	span {
		height: 24px;
		top: 50%;
		left: 0;
		right: 0;
		margin-top: -12px;
		position: absolute;
	}
	&:hover {
		background: url("@{img-root}/tables/interactive-table-pan-bg.png") -50px top repeat-y; // 90%
	}
	&.disabled {
		display: none;
	}
}
a.interactive-table-pan-right {
	right: 0px;
	border-left: 1px solid @off-white-dk;
	span {
		background: url("@{img-root}/tables/pan-sprite.png") 0 0 no-repeat;
	}
}
a.interactive-table-pan-left {
	left: 0px;
	border-right: 1px solid @off-white-dk;
	span {
		background: url("@{img-root}/tables/pan-sprite.png") 0 -24px no-repeat;
	}
}

.interactive-table.panning-disabled {
	a.interactive-table-pan-left,
	a.interactive-table-pan-right,
	div.column-header-pre,
	div.column-header-post {
		display: none;
	}
}

// column types
table, table.interactive-table-table {
	th.type-number,     td.type-number,
	th.type-currency,   td.type-currency,
	th.type-percentage, td.type-percentage {
		text-align: right;

		.percentage { padding-right: 10px; }
	}
	td.type-empty {
		color: @grey-lt;
	}
	th.type-graph,      td.type-graph {
		width: 50%;
	}
	td.column-tags {
		padding: 4px 4px 0px 4px;
		vertical-align: middle;
	}
	th.column-grid, td.column-grid {
		text-align: right;
		width: 80px;
		div.title {
			width: 80px;
			#util > .excerpt-crop > .ellipsis;
		}
	}
	th.column-grid-small, td.column-grid-small, th.column-grid-small div.title {
		width: 40px;
	}
	td.column-grid {
		border-left: 1px solid @off-white-dk; /* TODO: standardize color */
	}
}

// Hack for new activity report overview tab
// Hopefully we rip this out once we move the tab to react
.interactive-table.tab-overview {
	border-top: 1px solid @off-white-dk !important;
	.interactive-table-header {
		display: none;
	}
	tr.section-header {
		th {
			color: @grey-dk;
		}
		th,
		td.column-grid {
			color: @grey-dk;
			text-shadow: none;
			background-color: white;
			border-top: none !important;
			border-bottom: 1px solid @off-white-dk !important;
			padding: 48px 16px 2px 16px !important;
		}
		th,
		td.column-grid {
			border-left: none;
		}
		&:first-of-type th,
		&:first-of-type td {
			border-top: none !important;
			padding-top: 2px !important;
		}
	}
	tr.column-header {
		th {
			color: @grey-dk;
			background-color: @off-white-bg;
			border-bottom: 1px solid @off-white-dk !important;
			padding: 8px 16px !important;
		}
		.title {
			text-align: center;
		}
	}
	th.column-grid-small {
		.subtitle {
			span {
				text-align: center;
				width: 100%;
			}
		}
	}
	td.column-grid-small {
		text-align: center;
	}
	td.type-empty {
		color: @grey-lt !important;
	}
	td {
		padding: 8px 16px !important;
		.activity-time,
		.activity-count {
			width: 40px;
			display: inline-block;
		}
		.activity-count {
			text-align: left;
		}
	}
}

// Hack for new activity report detail tab
// we need to hide the html from the server, and we'll grab it and show it where
// we want in react-land.
.detail-table-activity-count {
	display: none;
}
.interactive-table.tab-detail {
	border-top: 1px solid @off-white-dk !important;
	thead#detail-header {
		th {
			line-height: 30px;
			color: @grey-dk;
			background: @off-white;
			border-left: 1px solid @off-white-dk;
			border-bottom: 1px solid @off-white-dk;
			padding: 0 16px;
			height: 34px;
			&:first-child {
				border-left: none;
			}
		}
	}
	tr.section-header {
		th {
			text-shadow: none;
			color: @grey-dk;
			background-color: @off-white-bg;
			border-top: 1px solid @off-white-dk !important;
			border-bottom: 1px solid @off-white-dk !important;
			padding: 8px 16px !important;
		}
		&:first-of-type th {
			border-top: none !important;
		}
	}
	tr.activity-detail {
		td.column-activity {
			padding: 0 16px !important;
		}
		.gutter {
			top: -3px;
		}
		.gutter-content {
			background: none;
		}
		.dot,
		.line {
			display: none;
		}
		td.column-relationships {
			padding: 0 !important;
			.activity-relationships {
			}
		}
		.entity-relationship {
			line-height: 22px;
			.avatar {
				background-position: 50%;
				background-color: transparent;
				width: 20px;
				height: 20px;
				img {
					width: 20px;
					height: 20px;
				}
			}
			.avatar,
			.pie {
				position: relative;
				top: 2px;
			}
		}
		span.entity-relationship.user {
			// users are the only entity without an icon in this view,
			// this just makes things align
			padding-left: 24px;
		}
	}
}

// Hack for new activity report overview and detail table footer
.interactive-table.tab-overview,
.interactive-table.tab-detail {
	.interactive-table-footer {
		background: white;
		border-top: 1px solid @off-white-dk;
		height: 100%;
		padding-top: 8px;
		// Shared
		a {
			opacity: 1;
			font-size: @small;
			font-weight: 500;
			color: @grey;
			&:hover {
				color: @grey-dk;
				background: @off-white-md;
			}
		}
		ol.pages {
			display: inline-block;
			li {
				float: left;
			}
			a {
				height: 24px;
			    width: 24px;
			    line-height: 24px;
				border-radius: 50%;
			    margin: 0 2px;
				padding: 0;
			}
		}
		a.prev-page-link,
		a.next-page-link {
			border-radius: 3px;
			line-height: 24px;
			height: 24px;
			padding: 0 10px;
			// Hack to get text baselines to align
			position: relative;
			top: -7px;
		}
		li.state-selected a {
			cursor: default;
			color: @grey-dk;
			background: @off-white-md;
		}
		span.more-pages {
			float: left;
		    line-height: 24px;
		}
	}
}
