.modal-toolbar {
    justify-content: space-between;
    height: 36px;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    font-size: var(--font-normal);
}

.attachments-container {
    padding: 2px;
    width: 250px;
}

.file {
    border-bottom: 1px solid var(--off-white-dk);
}

.file:last-child {
    border-bottom: none;
}
