.link {
    position: relative;
}

.link a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-small);
    padding: 8px 16px 8px 8px;
    margin: 0;
}

.nav-item-name {
    max-width: 105px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.count {
    position: absolute;
    top: 10px;
    right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-menu-container {
    position: absolute;
    top: 7px;
    right: 3px;
}

.action-menu-container > *:first-child {
    padding: 2px 4px;
    border-radius: 3px;
}

.action-menu-container--active > *:first-child:hover {
    background-color: #e9eaf1;
}

.action-menu-container--not-active > *:first-child:hover {
    background-color: rgba(85, 88, 135);
}
