.delete-button {
    position: relative;
    right: 8px;
    cursor: pointer;
    width: 19px;
    height: 19px;
    border: 1px solid var(--off-white-dk);
    background: url(/include/images/process-builder/icon-remove.png);
    background-color: var(--white);
}

.delete-button:focus,
.delete-button:active {
    outline: 2px solid var(--focus-blue);
    outline-offset: -2px;
}

.delete-button--hidden {
    display: none;
}
