.flash-mini {
	position: absolute;
	opacity: 0;
	color: @green;
	letter-spacing: 1px;
	font-size: @small;
	text-transform: initial;
	font-weight: normal;
}

.flash-success {
	opacity: 0;
	position: absolute;
	top: -12px;
	text-align: center;
	border-radius: 3px;
	padding: 4px 20px;
	width: 30%;
	left: 35%;
	right: 35%;

	&.enabled,
	&.updated,
	&.saved {
		background-color: @green-lt;
		color: @green;
		border: 2px solid lighten(@green, 50%);
	}
	&.disabled {
		background-color: @rose-lt;
		color: @rose;
		border: 2px solid @rose;
	}
}
