.add-message-container {
    margin-top: 8px;
    display: flex;
    position: relative;
    z-index: 2;
    /* Specific height to match cards in sidebar */
    height: 183px;
    width: 100%;
}

.add-message-container > * {
    flex-grow: 1;
}
