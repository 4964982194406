.header {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 2; /* make sure this stays in front of the list of fields */
    font-weight: 500;
    font-size: var(--font-huge);
    line-height: 1.5;
    color: var(--grey-dk);
}

.title {
    font-size: var(--font-huge);
    color: var(--grey-dk);
    font-weight: 500;
    font-style: normal;
    margin: 0;
}

.title--breadcrumb {
    composes: title;
    font-style: italic;
    color: var(--grey);
}

/* Important used to override modal-header styles */
.arrow {
    padding: 0 8px;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: var(--font-normal) !important;
}
