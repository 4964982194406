.peanut-scheduler-preview {
    /* no top padding because of meeting text above preview on this page */
    padding: 0 32px 32px;
    box-sizing: border-box;
}

.peanut-scheduler-preview--static {
    padding: 64px 32px;
    box-sizing: border-box;
}

@media screen and (max-width: 1620px) {
    .peanut-scheduler-preview--static {
        padding: 32px 16px;
    }
}
