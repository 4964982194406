@value headerHeight: 43px;
@value footerHeight: 74px;
@value addFooterHeight: 139px;

.container {
    height: 100%;
    padding: 16px;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.scroll-container {
    height: calc(
        100% - headerHeight - footerHeight - 39px
    ); /* 39px makes the content display right above footer */
    overflow: auto;
    padding-bottom: 24px;
}

.scroll-container-with-add-footer {
    composes: scroll-container;
    height: calc(
        100% - headerHeight - footerHeight - addFooterHeight
    ); /* 39px makes the content display right above footer */
}

.footer {
    background-color: var(--white);
    position: absolute;
    bottom: 32px;
    left: 0;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
}
