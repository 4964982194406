.container {
    padding: 12px;
    border-top: 1px solid var(--off-white-dk);
}

.container > *:not(:first-child) {
    margin-top: 8px;
}

.header {
    font-size: var(--font-small);
}

.font-super-tiny {
    font-size: 11px;
}

.container a {
    /* We don't have a link size for super tiny,
    so we'll just do it custom here */
    font-size: 11px !important;
}
