.container {
    display: flex;
}

.stageset {
    flex-grow: 1;
}

.priority {
    margin-left: 16px;
    position: relative;
    top: 25px;
}
