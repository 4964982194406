.header--hero {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
    padding: 0;
    font-size: var(--font-big);
    color: var(--grey);
    animation: fade-in 1000ms var(--custom-easing) 0s forwards;
}

.select {
    display: inline-block;
    margin: 0 8px;
    min-width: 0;
}

.select select {
    font-size: var(--font-big);
    font-weight: 500;
}
