.drip-editions-details-form {
    flex-grow: 1;
    padding: 24px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    background-color: var(--white);
}

.empty-field {
    font-style: italic;
    color: var(--grey-lt);
}
