.dashboard-cards-container {
    margin: 0 18px; /* Cards are all 18px on the sides */
    min-width: 380px;
}

.dashboard-cards-container > div {
    margin-bottom: 16px;
    height: unset;
}

.dashboard-cards-container > div:first-child {
    margin-top: 16px;
}

.dashboard-cards-container > div:last-child {
    margin-bottom: 0;
}
