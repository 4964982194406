@value gradientHeight: 102px;

.gradient-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.gradient-wrapper::before {
    content: ' ';
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: gradientHeight;
    background: linear-gradient(to bottom, var(--off-white) 0%, var(--off-white-transparent) 100%);
}

.gradient-wrapper::after {
    content: ' ';
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    height: gradientHeight;
    background: linear-gradient(to top, var(--off-white) 0%, var(--off-white-transparent) 100%);
}

.gradient-wrapper > :first-child {
    display: grid;
    grid-gap: 16px;
    padding: gradientHeight 0;
    overflow: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

/* In a grid-based container, the actual container itself can't have margin,
so we have to add margin-bottom to the last child. This is usually the recommended
approach on stackoverflow */
.gradient-wrapper > :first-child > *:last-child {
    margin-bottom: gradientHeight;
}
