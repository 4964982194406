.popover-sort-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    min-width: 250px;
    border: 1px solid var(--off-white-dk);
    border-radius: 3px;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.row-item {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    font-size: var(--font-normal);
    color: var(--grey);
}

.row-item:hover,
.row-item:focus {
    background-color: var(--color-list-hovered);
    color: var(--grey-dk);
}

.row-item svg {
    width: 11px; /* Gets the arrows closer together */
}

.footer-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    font-size: var(--font-normal);
    border-top: 1px solid var(--off-white-dk);
    color: var(--blue);
}

.footer-button:hover {
    background-color: var(--color-list-hovered);
}

.footer-button--upgrade {
    composes: footer-button;
    color: var(--grey-lt);
}

.footer-button--upgrade:hover {
    background-color: var(--off-white);
}
