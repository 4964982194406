.ghost {
    background-color: var(--skeleton-grey);
}

.profile {
    composes: ghost;
    border-radius: 100%;
    flex: 0 0 auto;
    height: 44px;
    width: 44px;
}

.content {
    composes: ghost;
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.line {
    border-left: 2px solid var(--skeleton-grey);
    margin-right: 44px;
    margin-left: 20px;
}

.top-line {
    composes: line;
    height: 20px;
}

.entryHeader {
    width: 90%;
    margin-left: 22px;
}

.row {
    composes: ghost;
    height: 17px;
}
