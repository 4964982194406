.move-up-4 {
    margin-top: -4px;
}

.move-up-7 {
    margin-top: -7px;
}

.move-up-12 {
    margin-top: -12px;
}

.move-down-3 {
    margin-bottom: -3px;
}

.move-down-4 {
    margin-bottom: -4px;
}

.move-down-7 {
    margin-bottom: -7px;
}

.move-down-12 {
    margin-bottom: -12px;
}

.move-left-10 {
    margin-left: -10px;
}

.move-left-12 {
    margin-left: -12px;
}

.move-left-24 {
    margin-left: -24px;
}

.move-right-10 {
    margin-right: -10px;
}

.move-right-12 {
    margin-right: -12px;
}

.move-right-24 {
    margin-right: -24px;
}
