.reaction-bar {
    font-size: var(--font-normal);
    display: flex;
    align-items: center;
    height: 27px;
}

.reaction-bar--small {
    composes: reaction-bar;
    font-size: var(--font-small);
    height: 23px;
}

/* Styling the 'Add reaction' button */
.reaction-bar button:last-child {
    margin-left: 2px;
    height: 100%;
}
