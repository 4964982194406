.calendar-options {
    position: relative;
}

.disabled-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -8px; /* Otherwise a bit of the radio buttons stick out */
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
}

.setting-section {
    margin-bottom: 32px;
}

.illustration-container {
    height: 20px;
    flex-shrink: 0;
    margin-left: 16px;
    margin-top: -38px; /* Visually centered with title above */
    padding-right: 40px;
}

.illustration-container > svg {
    height: 100%;
}

.illustration-container > *:last-child {
    margin-left: 6px;
}
