.feature-usage {
    display: flex;
}

.feature-usage > :first-child {
    margin-right: 16px;
}

.feature-usage > * {
    width: calc(50% - 8px);
}
