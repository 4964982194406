.option {
    display: flex;
}

.icon {
    margin-right: 8px;
    height: 20px;
    align-self: center;
}

.label {
    margin-right: 16px;
    align-self: center;
}
