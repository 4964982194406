.mapping-form {
    padding: 16px;
    box-sizing: border-box;
    /* Provides for the height of the top bar and the delete button */
    height: calc(100% - 144px);
    overflow-y: auto;
}

.available-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    padding-bottom: 16px;
}

.available-fields:last-child {
    padding-bottom: 0;
}

.disabled-text {
    opacity: 0.6;
}
