.container {
    position: relative;
    height: 100%;
}

.content {
    overflow: auto;
    max-height: calc(100% - 153px); /* Full height minus header and banner */
}

.banner {
    position: absolute;
    bottom: 32px;
    width: 100%;
}

.image {
    width: 60px;
    height: 60px;
    padding: 0 16px 8px 16px;
}
