@value column: './visual-pipeline-column.css';
@value columnWidth from column;

.container {
    width: columnWidth;
    min-width: columnWidth;
    max-width: columnWidth;
    height: 100%;
    padding: 0 1px 0 4px;
    box-sizing: border-box;
}

.container:first-child {
    padding-left: 0;
}

.container-inner {
    background-color: var(--off-white);
    height: 100%;
    width: 100%;
    padding: 4px 8px;
    box-sizing: border-box;
}
