.complete-task-button {
}

.complete {
    display: flex;
    align-items: center;
}

.complete :global(.svg-icon-container) {
    height: 15px;
    width: 15px;
    margin-right: 7px;
    position: relative;
}

/* Green circle unchecked state. */
.complete :global(.svg-icon-container:before) {
    content: '';
    height: 15px;
    width: 15px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    box-sizing: border-box;
    border: 1px solid var(--green);
    transition: opacity 150ms var(--custom-easing);
}

.complete :global(.svg-icon-container svg) {
    opacity: 0;
    fill: var(--green);
    transition: opacity 150ms var(--custom-easing);
}

/* green looks clickable, so make the circle grey */
.complete-task-button button[disabled] :global(.svg-icon-container:before) {
    border-color: var(--off-white-dk);
}

.complete--active {
    composes: complete;
    color: var(--grey-dk);
}

.complete-task-button button:not([disabled]):hover :global(.svg-icon-container:before),
.complete--active :global(.svg-icon-container:before) {
    opacity: 0;
}

.complete-task-button button:not([disabled]):hover :global(.svg-icon-container svg),
.complete--active :global(.svg-icon-container svg) {
    opacity: 1;
}
